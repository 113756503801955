import { useFormik } from 'formik';
import {
  useDeiStageActionUnarchiveMutation,
  useDeiStageActionUpsertMutation,
} from 'generated/graphql';
import * as yup from 'yup';
import {
  Button,
  CheckboxGroup,
  CheckboxGroupItem,
  InlineNotification,
  NotificationType,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  deiCheckboxOptions,
  getDeiFields,
  updateCacheHelper,
} from './dei.helpers';
import styles from './DeiActionForm.module.scss';

const validationSchema = yup.object().shape({
  deiValues: yup
    .array(yup.string().required())
    .min(1, 'Please select at least one question.'),
});

const DeiActionForm = ({
  stageId,
  projectId,
  onClose,
  type,
  initialValues,
  completedCount,
  hasArchivedDeiForm = false,
}: {
  stageId: number;
  projectId: number;
  onClose: () => void;
  type: 'create' | 'edit';
  initialValues: {
    deiValues: string[];
  };
  completedCount?: number;
  hasArchivedDeiForm?: boolean;
}) => {
  const { handleMsgType } = useNotification();
  const buttonText = type === 'create' ? 'Add action' : 'Save';
  const notificationVerb = type === 'create' ? 'created' : 'updated';

  const handleOnCompleted = () => {
    onClose();
    handleMsgType({
      type: TNotification.success,
      title: `Diversity, equity & inclusion action ${notificationVerb}`,
    });
  };

  const handleOnError = () => {
    handleMsgType({
      type: TNotification.error,
      title: `Diversity, equity & inclusion action could not be ${notificationVerb}.`,
      message: 'Something went wrong. Please try again.',
    });
  };

  const [upsertDeiForm, { loading }] = useDeiStageActionUpsertMutation({
    onCompleted: handleOnCompleted,
    onError: handleOnError,
    update(cache, { data }) {
      if (data?.DeiStageActionUpsert) {
        updateCacheHelper(cache, data.DeiStageActionUpsert, projectId, stageId);
      }
    },
  });

  const [archiveDeiForm, { loading: loadingUnarchive }] =
    useDeiStageActionUnarchiveMutation({
      onCompleted: handleOnCompleted,
      onError: handleOnError,
      update(cache, { data }) {
        if (data?.DeiStageActionUnarchive) {
          updateCacheHelper(
            cache,
            data.DeiStageActionUnarchive,
            projectId,
            stageId
          );
        }
      },
    });

  const handleDeiSubmit = () => {
    if (Boolean(errors.deiValues)) return;

    const payload = {
      variables: {
        stageId,
        fields: getDeiFields(values.deiValues),
      },
    };

    if (hasArchivedDeiForm) {
      archiveDeiForm(payload);
      return;
    }
    upsertDeiForm(payload);
  };

  const { setFieldValue, values, errors, dirty } = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    validateOnChange: true,
    onSubmit: handleDeiSubmit,
  });

  const showPreviouslyArchivedNotification = hasArchivedDeiForm;
  const showCompletedCountNotification =
    type === 'edit' && Boolean(completedCount) && dirty;

  const handleChange = (value: string[]) => {
    setFieldValue('deiValues', value);
  };

  return (
    <div className={styles.container} data-testid="edit-stage__dei-action-form">
      <div>
        <p className={styles.description}>
          This action checks for adverse impact for this specific client and
          contributes to broader adverse impact studies for our product.
        </p>
        <br />
        <p className={styles.description}>
          Questions marked with * are required for calculating score fairness.
          Questions can be modified if the client does not wish to collect
          certain types of information.
        </p>
      </div>
      {showPreviouslyArchivedNotification && (
        <InlineNotification
          notificationType={NotificationType.NEUTRAL}
          className={styles.archivedInlineNotification}
        >
          This project had a diversity, equity, and inclusion action in the
          past.
        </InlineNotification>
      )}
      <div className={styles.formContainer}>
        <p className={styles.formTitle}> Questions to include</p>
        <CheckboxGroup
          id="dei-checkbox-group"
          value={values.deiValues}
          onChange={handleChange}
          hasError={Boolean(errors.deiValues)}
          error={`${errors.deiValues ? errors.deiValues : ''}`}
        >
          {deiCheckboxOptions.map(({ label, value }, index) => {
            return (
              <CheckboxGroupItem
                key={index}
                data-testid={`dei-form__dei-option-${value}`}
                id={value}
                label={label}
                name={value}
              />
            );
          })}
        </CheckboxGroup>
      </div>
      {showCompletedCountNotification && (
        <div className={styles.completedInlineNotificationWrapper}>
          <InlineNotification
            notificationType={NotificationType.NEUTRAL}
            className={styles.completedInlineNotification}
          >
            Adding or removing questions will create inconsistencies in data
            collected between past and future candidates for internal users and
            or clients.
            <p className={styles.description}>
              Number of completed DEI questionnaires{' '}
              <span className={styles.completedForms}>{completedCount}</span>
            </p>
          </InlineNotification>
        </div>
      )}
      <div className={styles.actionsContainer}>
        <Button
          size="medium"
          type="submit"
          loading={loading || loadingUnarchive}
          onClick={handleDeiSubmit}
          data-testid="edit-stage__dei-action-form-save-button"
        >
          {buttonText}
        </Button>
        <Button onClick={onClose} size="medium" variant="secondary">
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default DeiActionForm;
