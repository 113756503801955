import { Button } from '@spotted-zebra-uk/ui-components';
import DeleteStage from '../StageActionButtons/DeleteStage';
import styles from './StageHeader.module.scss';

const StageHeader = ({
  primaryButton,
  secondaryButton,
  deleteButton,
}: {
  primaryButton: { label: string; onClick: () => void; isLoading?: boolean };
  secondaryButton?: { label: string; onClick: () => void };
  deleteButton?: { projectId: number; stageId: number };
}) => {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.title}>Stage</div>
        <div className={styles.actions}>
          {secondaryButton && (
            <Button
              onClick={secondaryButton.onClick}
              variant="secondary"
              size="medium"
              data-testid="stage-header__secondary-button"
            >
              {secondaryButton.label}
            </Button>
          )}
          {deleteButton && (
            <DeleteStage
              projectId={deleteButton.projectId}
              id={deleteButton.stageId}
            />
          )}
          {primaryButton && (
            <Button
              onClick={primaryButton.onClick}
              size="medium"
              loading={primaryButton.isLoading}
              type="submit"
              data-testid="stage-header__primary-button"
            >
              {primaryButton.label}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default StageHeader;
