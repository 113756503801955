import Button from 'components/atoms/Button/Button';
import Modal from 'components/molecules/Modal/Modal';
import { ModalTypes } from 'enums/modalTypes';
import {
  NormGroupType,
  TestCandidateFindManyByCandidateDocument,
  TestSubType,
  useTestCandidateUpdateOneMutation,
} from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { startCase } from 'lodash';
import { ChangeEvent, FC, useMemo, useState } from 'react';
import { useReportModalStyle } from 'views/ModalView/ReportsModal/ReportsModalStyle';
import { IFilteredTestCandidate } from 'views/User/UserDetails/UserMainResets/UserReset/UserResets.interface';
import { Grid, Typography } from '@mui/material';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export interface IAssessmentData {
  allowedTimePerSection?: number | null;
  testCandidateId: number;
  createdAt: string;
  id: number;
  nQuestionsPerTrait: number;
  manuallyCreated: boolean;
  normGroupType: NormGroupType;
  startingAbilityLowerBound: number;
  startingAbilityUpperBound: number;
  traitIds?: (number | null | undefined)[] | null;
}

interface IMitigateCircumstanceModal {
  isDefault: boolean;
  testCandidate: IFilteredTestCandidate;
  candidateId: number;
  getCurrentAssessmentId: (assesementId: number) => void;
}

const INDUCTIVE_TEST_DEFAULT_TIME = 60;
const OTHER_TESTS_DEFAULT_TIME = 180;

const MitigateCircumstanceModal: FC<IMitigateCircumstanceModal> = ({
  testCandidate,
  candidateId,
}) => {
  const { handleMsgType } = useNotification();
  const classes = useReportModalStyle();

  const { subType } = testCandidate.test;
  const timeModifierPercentage = testCandidate.timeModifierPercentage || 0;

  const defaultTestTime = useMemo(() => {
    if (subType === TestSubType.Inductive) {
      return INDUCTIVE_TEST_DEFAULT_TIME;
    }
    return OTHER_TESTS_DEFAULT_TIME;
  }, [subType]);

  const calculateTimeModifierFromPercentage = (modifier: number) => {
    return Math.round(defaultTestTime * (1 + modifier));
  };

  const getTimePercentage = () => {
    return Math.round(timeModifierPercentage * 100);
  };

  const [displayTimeValue, setDisplayTimeValue] = useState(
    calculateTimeModifierFromPercentage(timeModifierPercentage)
  );

  const [extraPercentage, setExtraPercentage] = useState(
    getTimePercentage() || 0
  );

  const [customValue, setCustomValue] = useState(extraPercentage);

  const [testCandidateUpdateOne] = useTestCandidateUpdateOneMutation({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    refetchQueries: [
      {
        query: TestCandidateFindManyByCandidateDocument,
        variables: {
          candidateId: candidateId,
        },
      },
    ],
    onCompleted: () => {
      closeModal(ModalTypes.MITIGATE_CIRCUMSTANCE_MODAL);
    },
  });

  const handleCustomValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    // Remove leading zeros
    const formatValue = parseFloat(value);
    const newValue = value === '' ? 0 : formatValue;

    if (!isNaN(formatValue) || value === '') {
      setCustomValue(newValue);
      setExtraPercentage(newValue);
      setDisplayTimeValue(calculateTimeModifierFromPercentage(newValue / 100));
    }
  };

  const handleCloseModal = () => {
    closeModal(ModalTypes.MITIGATE_CIRCUMSTANCE_MODAL);
  };

  const handleSubmit = () => {
    testCandidateUpdateOne({
      variables: {
        id: testCandidate.id,
        timeModifierPercentage: extraPercentage / 100,
      },
    });
  };

  return (
    <Modal className={classes.modalPaper} onClose={handleCloseModal}>
      <Grid container direction="column" spacing={1}>
        <Grid>
          <Typography
            sx={{ fontWeight: '500', fontSize: '1.2rem' }}
          >{`${startCase(subType?.toLowerCase())} test`}</Typography>
        </Grid>
        <Grid sx={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}>
          <p>
            Extend testlet time by{' '}
            <input
              type="text"
              style={{
                border: '1px solid grey',
                outline: 'none',
                width: '50px',
                padding: '10px',
                marginRight: '10px',
                borderRadius: '5px',
                marginLeft: '8px',
              }}
              value={customValue}
              onChange={handleCustomValueChange}
            />
          </p>
          <p>percent</p>
        </Grid>
        <Grid>
          <Typography
            sx={{ fontSize: '14px', paddingTop: '8px', color: '#62748c' }}
          >
            Default time: {defaultTestTime} seconds
          </Typography>
          <Typography
            sx={{ fontSize: '14px', paddingTop: '8px', color: '#62748c' }}
          >
            Adjusted time: {displayTimeValue} seconds
          </Typography>
        </Grid>

        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          className={classes.btnContainer}
          style={{ marginTop: '20px', gap: '16px' }}
        >
          <Button
            color="inherit"
            className={classes.btn}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className={classes.btn}
            onClick={handleSubmit}
          >
            Update
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default MitigateCircumstanceModal;
