import { useField } from 'formik';
import {
  CompanyUser,
  CompanyUsersFindManyPaginatedDocument,
  CompanyUsersFindManyPaginatedQuery,
  CompanyUserType,
} from 'generated/graphql';
import { debounce, stubTrue } from 'lodash';
import { FC, SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { components, NoticeProps } from 'react-select';
import { useQuery } from '@apollo/client';
import { FormikMultiselectFormField } from '@spotted-zebra-uk/ui-components';
import { IFormikMultiselectFormFieldWithQuery } from './FormikMultiselectFormFieldWithQuery';

interface ICompanyUsersFindManyFormikMultiselectFormField
  extends Omit<
    IFormikMultiselectFormFieldWithQuery<CompanyUsersFindManyPaginatedQuery>,
    'query' | 'queryOptions' | 'getOptionsFromResponse'
  > {
  companyId: number;
  queryArgs?: {
    search?: string;
    userGroupId?: number;
    stageCandidateId?: number;
    projectId?: number;
    userType?: CompanyUserType;
  };
  getOptions?: (users: CompanyUser[]) => Array<{
    label: string;
    value: string;
    labelDescription?: string;
    labelNote?: string;
  }>;
}

const stopEvents = (event: SyntheticEvent) => {
  event.stopPropagation();
  event.preventDefault();
};

const defaultGetOptions = (users: Array<CompanyUser>) => {
  return users.map(user => ({
    label: user.user
      ? `${user.user.firstName} ${user.user.lastName}`
      : user.group
      ? `${user.group.name}`
      : '',
    value: user.user
      ? `user:${user.user.id}`
      : user.group
      ? `group:${user.group?.id}`
      : '-1',
  }));
};

export const CompanyUsersFindManyFormikMultiselectFormField: FC<ICompanyUsersFindManyFormikMultiselectFormField> =
  ({
    companyId,
    queryArgs,
    getOptions,
    isSearchable,
    ...selectElementProps
  }) => {
    const [page, setPage] = useState<number>(1);
    const [pageTotal, setPageTotal] = useState<number>(1);
    const [search, setSearch] = useState<string>(queryArgs?.search || '');
    const [users, setUsers] = useState<CompanyUser[]>([]);
    const loadMore = pageTotal > page;

    //eslint-disable-next-line
    const debouncedSetSearch = useCallback(
      debounce(search => {
        setPage(1);
        setPageTotal(1);
        setUsers([]);
        setSearch(search);
      }, 500),
      []
    );

    const processOptions = getOptions || defaultGetOptions;

    const handleInputChange = (value: string) => {
      if (value === search) return;
      if (isSearchable === false) return;
      debouncedSetSearch(value);
    };

    const handleLoadMore = (event: SyntheticEvent) => {
      event.stopPropagation();
      event.preventDefault();
      setPage(page + 1);
    };

    const { error, loading, refetch } =
      useQuery<CompanyUsersFindManyPaginatedQuery>(
        CompanyUsersFindManyPaginatedDocument,
        {
          onError: () => {},
          onCompleted: data => {
            if (!data.companyUsers.data) return;

            setPageTotal(data.companyUsers.pageInfo?.pageTotal || 1);

            const totalUsers = [
              ...users,
              ...(data.companyUsers.data as Array<CompanyUser>),
            ];

            setUsers(totalUsers);
          },
          skip: !companyId,
          variables: {
            args: { ...queryArgs, search, companyId },
            order: { direction: 'ASC', field: 'NAME' },
            paginate: { page, size: 100 },
          },
          fetchPolicy: 'network-only',
        }
      );

    useEffect(() => {
      setUsers([]);
      setPage(1);
      setPageTotal(1);
      setSearch('');
      refetch();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const NoOptionsMessage = (props: NoticeProps) => {
      return (
        <components.NoOptionsMessage {...props}>
          <span className="custom-css-class">
            {loading ? 'Loading' : 'No results'}
          </span>
        </components.NoOptionsMessage>
      );
    };

    return (
      <div onClick={stopEvents}>
        <FormikMultiselectFormField
          {...selectElementProps}
          components={{ NoOptionsMessage }}
          onInputChange={handleInputChange}
          onLoadMore={handleLoadMore}
          async={loadMore}
          loadMore={loadMore}
          loadMoreButtonText="Load more results"
          loadMoreDisabled={loading}
          options={processOptions(users)}
          isLoading={loading}
          isDisabled={Boolean(error || loading)}
          useFormikField={useField}
          filterOption={stubTrue}
          isSearchable={isSearchable}
        />
      </div>
    );
  };
