import Icon from 'components/atoms/Icon';
import {
  DeiStageActionModel,
  SmStageModel,
  StageFindManyDocument,
  useRssAssessmentFindManyQuery,
  useTmTestFindManyQuery,
} from 'generated/graphql';
import { FC, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import {
  Button,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import StageActionModal from './AddStageActionModal';
import EditSkillsSelfAssessmentActionModal from './EditSkillsSelfAssessmentActionModal';
import StageDeiFormAction from './StageDeiFormAction';
import StageRoleSkillsSelfAssessmentAction from './StageRoleSkillsSelfAssessmentAction';
import StageTestAction from './StageTestAction';

interface IStageActions {
  stageId: number;
  projectId: number;
}

const StageActions: FC<IStageActions> = ({ stageId, projectId }) => {
  const { handleMsgType } = useNotification();
  const [stageActionModalOpen, setStageActionModalOpen] = useState(false);
  const [
    editSkillsSelfAssessmentActionModalOpen,
    setEditSkillsSelfAssessmentActionModalOpen,
  ] = useState(false);

  const getStageTestsQueryResponse = useTmTestFindManyQuery({
    variables: { stageId },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const getStageRssAssessmentQueryResponse = useRssAssessmentFindManyQuery({
    variables: { stageId },
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
  });

  const client = useApolloClient();
  const stageData = client.readQuery({
    query: StageFindManyDocument,
    variables: { projectId: projectId },
  });

  const deiStageAction = stageData?.Stages.find(
    (stage: SmStageModel) => stage.id === stageId
  ).deiStageAction as DeiStageActionModel;

  const hasDeiForm = deiStageAction && !deiStageAction.deletedAt;

  const sortedTests = getStageTestsQueryResponse.data
    ? [...getStageTestsQueryResponse.data.tests].sort((testA, testB) => {
        return testA.type > testB.type ? 1 : -1;
      })
    : [];

  return (
    <>
      <div className="stage-actions">
        <div className="stage-actions__header">
          <div className="stage-actions__title">Actions</div>
          <div className="stage-actions__header-right">
            <Button
              className="stage-actions__add-action-button"
              onClick={() => setStageActionModalOpen(true)}
              size="medium"
              leftIcon={<Icon icon="add" />}
            >
              Add action
            </Button>
          </div>
        </div>
        <div className="stage-actions__tests-list">
          {sortedTests.map(stageTest => (
            <StageTestAction key={stageTest.id} stageTest={stageTest} />
          ))}
          {getStageRssAssessmentQueryResponse.data?.RssAssessmentFindMany
            .length ? (
            <StageRoleSkillsSelfAssessmentAction
              rssAssessments={
                getStageRssAssessmentQueryResponse.data.RssAssessmentFindMany
              }
              stageId={stageId}
              onEditButtonClick={() =>
                setEditSkillsSelfAssessmentActionModalOpen(true)
              }
            />
          ) : null}
          {hasDeiForm && (
            <StageDeiFormAction
              stageId={stageId}
              projectId={projectId}
              fields={deiStageAction?.fields}
              completedCount={deiStageAction?.submittedCount}
            />
          )}
        </div>
      </div>
      {stageActionModalOpen && (
        <StageActionModal
          stageId={stageId}
          projectId={projectId}
          deiData={deiStageAction}
          onClose={() => setStageActionModalOpen(false)}
        />
      )}
      {editSkillsSelfAssessmentActionModalOpen && (
        <EditSkillsSelfAssessmentActionModal
          stageId={stageId}
          onClose={() => setEditSkillsSelfAssessmentActionModalOpen(false)}
        />
      )}
    </>
  );
};

export default StageActions;
