import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export enum Acl {
  Public = 'PUBLIC',
  Secure = 'SECURE'
}

export enum ActorRole {
  AssessorOrInterviewer = 'ASSESSOR_OR_INTERVIEWER',
  EarlyCareerTeamMember = 'EARLY_CAREER_TEAM_MEMBER',
  HeadOfPeopleOrTalent = 'HEAD_OF_PEOPLE_OR_TALENT',
  HiringManager = 'HIRING_MANAGER',
  Interviewer = 'INTERVIEWER',
  LineManager = 'LINE_MANAGER',
  RecruiterOrTalentAcquisitionPartner = 'RECRUITER_OR_TALENT_ACQUISITION_PARTNER',
  ReskillingTeamMember = 'RESKILLING_TEAM_MEMBER'
}

export enum AdjustmentQuestionnaireResponse {
  AssistiveTechnology = 'ASSISTIVE_TECHNOLOGY',
  AuditoryProcessing = 'AUDITORY_PROCESSING',
  DifficultyMaintainingFocus = 'DIFFICULTY_MAINTAINING_FOCUS',
  DifficultyManagingAnxiety = 'DIFFICULTY_MANAGING_ANXIETY',
  DifficultyManagingFatigue = 'DIFFICULTY_MANAGING_FATIGUE',
  DifficultyProcessingInformation = 'DIFFICULTY_PROCESSING_INFORMATION',
  PhysicalChallenges = 'PHYSICAL_CHALLENGES'
}

export type AmApiKeyModel = {
  __typename?: 'AmApiKeyModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  hasClientSecret: Scalars['Boolean'];
  id: Scalars['Int'];
  key: Scalars['String'];
  ownerId: Scalars['Int'];
  ownerType: ApiKeyOwnerType;
  puppetUserId: Scalars['Int'];
  role?: Maybe<IdentityPermissionRole>;
  updatedAt: Scalars['Date'];
  usageRecord: Array<ApiKeyUsageRecordModel>;
};

export type Answer = {
  __typename?: 'Answer';
  field?: Maybe<Field>;
  fieldId?: Maybe<Scalars['Int']>;
  form?: Maybe<Form>;
  formId: Scalars['Int'];
  id: Scalars['Int'];
  respondantId: Scalars['Int'];
  response?: Maybe<Scalars['String']>;
  responseType: ResponseType;
};

export enum ApiKeyOwnerType {
  Company = 'COMPANY',
  User = 'USER'
}

export type ApiKeyUsageRecordModel = {
  __typename?: 'ApiKeyUsageRecordModel';
  count: Scalars['Int'];
  route: Scalars['String'];
};

export type ApiUserModel = {
  __typename?: 'ApiUserModel';
  clientId: Scalars['String'];
  clientSecret?: Maybe<Scalars['String']>;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  hasClientSecret: Scalars['Boolean'];
  id: Scalars['Int'];
  ownerId: Scalars['Int'];
  ownerType: ApiUserOwnerType;
  puppetUserId: Scalars['Int'];
  role?: Maybe<IdentityPermissionRole>;
  updatedAt: Scalars['Date'];
};

export enum ApiUserOwnerType {
  Company = 'COMPANY',
  User = 'USER'
}

export enum AssessmentBrowserSwitchCandidateTrackingEvent {
  AssessmentTabSwitchEndCandidateTrackingEvent = 'AssessmentTabSwitchEndCandidateTrackingEvent',
  AssessmentTabSwitchStartCandidateTrackingEvent = 'AssessmentTabSwitchStartCandidateTrackingEvent',
  AssessmentWindowOutOfFocusEndCandidateTrackingEvent = 'AssessmentWindowOutOfFocusEndCandidateTrackingEvent',
  AssessmentWindowOutOfFocusStartCandidateTrackingEvent = 'AssessmentWindowOutOfFocusStartCandidateTrackingEvent'
}

export type AssessmentCandidateBrowserSwitchTrackingEventModel = {
  __typename?: 'AssessmentCandidateBrowserSwitchTrackingEventModel';
  assessment: AssessmentDataModel;
  assessmentId: Scalars['Int'];
  assessmentTimeSeconds?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  invokedAt: Scalars['Date'];
  subId: Scalars['String'];
  type: AssessmentBrowserSwitchCandidateTrackingEvent;
  updatedAt: Scalars['Date'];
};

export type AssessmentDataAnswerGqlModel = {
  optionSubId?: InputMaybe<Scalars['String']>;
  questionSubId: Scalars['String'];
};

export type AssessmentDataAnswerModel = {
  __typename?: 'AssessmentDataAnswerModel';
  inTime?: Maybe<Scalars['Boolean']>;
  isCorrect: Scalars['Boolean'];
  timestamp: Scalars['Date'];
};

export type AssessmentDataModel = {
  __typename?: 'AssessmentDataModel';
  allowedTimePerSection?: Maybe<Scalars['Int']>;
  answers: Array<Maybe<AssessmentDataAnswerModel>>;
  completedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  locale: Scalars['String'];
  manuallyCreated: Scalars['Boolean'];
  nQuestionsPerTrait?: Maybe<Scalars['Int']>;
  normGroupType: NormGroupType;
  questions: Array<Maybe<AssessmentDataQuestionModel>>;
  roleLevel?: Maybe<ContentRoleLevel>;
  startingAbilityLowerBound: Scalars['Float'];
  startingAbilityUpperBound: Scalars['Float'];
  startingQuestions?: Maybe<Array<Maybe<AssessmentDataQuestionModel>>>;
  testCandidateDoneBy?: Maybe<Scalars['Int']>;
  testCandidateId: Scalars['Int'];
  testType?: Maybe<TestFullType>;
  traitIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  updatedAt: Scalars['Date'];
};

export type AssessmentDataQuestionModel = {
  __typename?: 'AssessmentDataQuestionModel';
  questionSubId: Scalars['String'];
  timestamp: Scalars['Date'];
  traitId?: Maybe<Scalars['Int']>;
};

export type AssignmentStatus = {
  __typename?: 'AssignmentStatus';
  asGroupMember?: Maybe<Scalars['Boolean']>;
  asIndividual?: Maybe<Scalars['Boolean']>;
};

export enum AtsProvider {
  Greenhouse = 'GREENHOUSE',
  Icims = 'ICIMS',
  PeopleScout = 'PEOPLE_SCOUT',
  StackOne = 'STACK_ONE'
}

export type AuthCodeResult = {
  __typename?: 'AuthCodeResult';
  authCode: Scalars['String'];
};

export type AuthCodeResultCookie = {
  __typename?: 'AuthCodeResultCookie';
  authCode: Scalars['String'];
  mfaCookie: Scalars['String'];
};

export type AuthenticationMethodModel = {
  __typename?: 'AuthenticationMethodModel';
  authInfoSubId?: Maybe<Scalars['String']>;
  identityProvider?: Maybe<IdentityProviderModel>;
  type: AuthenticationMethodType;
};

export enum AuthenticationMethodType {
  EmailPassword = 'EMAIL_PASSWORD',
  Saml = 'SAML'
}

export type AuthenticationResult = {
  __typename?: 'AuthenticationResult';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AuthenticationResultCookie = {
  __typename?: 'AuthenticationResultCookie';
  accessToken: Scalars['String'];
  mfaCookie: Scalars['String'];
  refreshToken: Scalars['String'];
};

export type AuthenticationResultE2eCookie = {
  __typename?: 'AuthenticationResultE2eCookie';
  mfaCookie: Scalars['String'];
};

export type BaseMethodTest = {
  __typename?: 'BaseMethodTest';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  score: Scalars['Float'];
  someEnum: BaseMethodTestSomeEnum;
  someFlag: Scalars['Boolean'];
  subId: Scalars['String'];
};

export type BaseMethodTestCreateOneArgs = {
  name?: InputMaybe<Scalars['String']>;
  someEnum: BaseMethodTestSomeEnum;
  someFlag: Scalars['Boolean'];
};

export enum BaseMethodTestSomeEnum {
  Arsalan = 'ARSALAN',
  Ian = 'IAN',
  Sufyan = 'SUFYAN',
  Tamas = 'TAMAS',
  Victor = 'VICTOR'
}

export type BaseMethodTestUpdateOneArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
};

export type BasicScoreModel = {
  __typename?: 'BasicScoreModel';
  id: Scalars['Int'];
  score: ScoreModel;
  type: BasicScoreType;
};

export enum BasicScoreType {
  SoftSkill = 'SOFT_SKILL',
  SuccessProfile = 'SUCCESS_PROFILE',
  Trait = 'TRAIT'
}

export type BehaviourContentModel = {
  __typename?: 'BehaviourContentModel';
  behaviourId: Scalars['Int'];
  id: Scalars['Int'];
  negativeSummary: Scalars['String'];
  positiveSummary: Scalars['String'];
  roleLevel?: Maybe<ContentRoleLevel>;
};

export type BehaviourFromCsvRowsCreateManyArgs = {
  internalName: Scalars['String'];
  level: Scalars['String'];
  negativeSummary: Scalars['String'];
  positiveSummary: Scalars['String'];
  softSkillId: Scalars['String'];
  traitId: Scalars['String'];
};

export type BehaviourModel = {
  __typename?: 'BehaviourModel';
  content?: Maybe<BehaviourContentModel>;
  contents: Array<BehaviourContentModel>;
  contexts: Array<TraitSoftSkillContextModel>;
  id: Scalars['Int'];
  internalName: Scalars['String'];
};

export type Blob = {
  __typename?: 'Blob';
  acl: Acl;
  bucket: Scalars['String'];
  contentEncoding?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  extension: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  ownerService: Scalars['String'];
  publishedName: Scalars['String'];
  updatedAt: Scalars['String'];
  url: Scalars['String'];
};

export type BlobModel = {
  __typename?: 'BlobModel';
  acl: Acl;
  bucket: Scalars['String'];
  contentEncoding?: Maybe<Scalars['String']>;
  contentType?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  extension: Scalars['String'];
  id: Scalars['ID'];
  key: Scalars['String'];
  ownerService: Scalars['String'];
  publishedName: Scalars['String'];
  updatedAt: Scalars['Date'];
  url: Scalars['String'];
};

export type BulkUpdateSoftSkillTraitArgsSingle = {
  addEmptyContent?: InputMaybe<Scalars['Boolean']>;
  isAdded?: InputMaybe<Scalars['Boolean']>;
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isUpdated?: InputMaybe<Scalars['Boolean']>;
  orientation: Orientation;
  softSkillId: Scalars['Int'];
  traitId: Scalars['Int'];
  weight: Scalars['Float'];
};

export enum CalculationMethod {
  PercentileWeightedAverage = 'PERCENTILE_WEIGHTED_AVERAGE',
  RoleSkillsProfile = 'ROLE_SKILLS_PROFILE',
  StenWeightedAverage = 'STEN_WEIGHTED_AVERAGE',
  StenWeightedAverageWithAdjustemt = 'STEN_WEIGHTED_AVERAGE_WITH_ADJUSTEMT',
  TrCustomCalculator = 'TR_CUSTOM_CALCULATOR',
  ZWeightedAverageWithAdjustment = 'Z_WEIGHTED_AVERAGE_WITH_ADJUSTMENT',
  ZWeightedAverageWithSmarterAdjustment = 'Z_WEIGHTED_AVERAGE_WITH_SMARTER_ADJUSTMENT',
  ZWeightedAverageWithSmarterAdjustmentAndLimit = 'Z_WEIGHTED_AVERAGE_WITH_SMARTER_ADJUSTMENT_AND_LIMIT'
}

export type CalibrationCandidateModel = {
  __typename?: 'CalibrationCandidateModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  lastAdjustedById?: Maybe<Scalars['Int']>;
  stageCandidate: SmStageCandidateModel;
  stageCandidateId: Scalars['Int'];
  status: CalibrationCandidateStatus;
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum CalibrationCandidateStatus {
  HrSubmitted = 'HR_SUBMITTED',
  ManagerSubmitted = 'MANAGER_SUBMITTED',
  NotStarted = 'NOT_STARTED',
  SignedOff = 'SIGNED_OFF',
  TalentTeamSubmitted = 'TALENT_TEAM_SUBMITTED'
}

export type CalibrationConfigExtendedModel = {
  __typename?: 'CalibrationConfigExtendedModel';
  gradeBands: Array<GradeBandUnion>;
  softSkillEntries: Array<CalibrationConfigSoftSkillEntryModel>;
  technicalSkillEntries: Array<CalibrationConfigTechnicalSkillEntryModel>;
  totalEntry: CalibrationConfigTotalEntryModel;
};

export type CalibrationConfigModel = {
  __typename?: 'CalibrationConfigModel';
  gradeBands: Array<GradeBandUnion>;
  softSkillIds: Array<Scalars['Int']>;
  successProfileId: Scalars['Int'];
};

export type CalibrationConfigSoftSkillEntryModel = {
  __typename?: 'CalibrationConfigSoftSkillEntryModel';
  latestResult?: Maybe<ResultModel>;
  originalResult?: Maybe<ResultModel>;
  softSkill: SoftSkill;
  softSkillId: Scalars['Int'];
};

export type CalibrationConfigTechnicalSkillEntryModel = {
  __typename?: 'CalibrationConfigTechnicalSkillEntryModel';
  latestResult?: Maybe<ResultModel>;
  originalResult?: Maybe<ResultModel>;
  technicalSkill: SoftSkill;
  technicalSkillId: Scalars['Int'];
};

export type CalibrationConfigTotalEntryModel = {
  __typename?: 'CalibrationConfigTotalEntryModel';
  latestResult?: Maybe<ResultModel>;
  originalResult?: Maybe<ResultModel>;
  successProfileId: Scalars['Int'];
};

export type CalibrationFileUploadArgs = {
  projectId: Scalars['Int'];
  signOff: Scalars['Boolean'];
};

export type CalibrationFileUploadLineModel = {
  __typename?: 'CalibrationFileUploadLineModel';
  directReportEmployeeId: Scalars['String'];
  directReportName: Scalars['String'];
  managerEmployeeId: Scalars['String'];
  managerName: Scalars['String'];
  overallResult: TrCustomEvaluation;
  overallResultAdjustment: TrCustomEvaluation;
  overallResultId: Scalars['Int'];
  softSkillResult1: TrCustomEvaluation;
  softSkillResult1Adjustment: TrCustomEvaluation;
  softSkillResult1PreviousId: Scalars['Int'];
  softSkillResult2: TrCustomEvaluation;
  softSkillResult2Adjustment: TrCustomEvaluation;
  softSkillResult2PreviousId: Scalars['Int'];
  softSkillResult3: TrCustomEvaluation;
  softSkillResult3Adjustment: TrCustomEvaluation;
  softSkillResult3PreviousId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};

export type CalibrationFileUploadModel = {
  __typename?: 'CalibrationFileUploadModel';
  apply: Scalars['Boolean'];
  creates?: Maybe<Array<CalibrationFileUploadLineModel>>;
  deletes?: Maybe<Array<CalibrationFileUploadLineModel>>;
  errorMessage?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<CalibrationFileUploadLineModel>>;
  noChanges?: Maybe<Array<CalibrationFileUploadLineModel>>;
  projectId: Scalars['Int'];
  signOff: Scalars['Boolean'];
  updates?: Maybe<Array<CalibrationFileUploadLineModel>>;
};

export enum CandidateResultsExportLevel {
  AllData = 'ALL_DATA',
  SummaryAndScoreDetails = 'SUMMARY_AND_SCORE_DETAILS',
  SummaryOfScores = 'SUMMARY_OF_SCORES'
}

export enum ClientDomainType {
  AdminAppDomain = 'ADMIN_APP_DOMAIN',
  CandidateAppDomain = 'CANDIDATE_APP_DOMAIN',
  CompanyAppDomain = 'COMPANY_APP_DOMAIN',
  PdfAppDomain = 'PDF_APP_DOMAIN'
}

export enum ClientType {
  Admin = 'ADMIN',
  Candidate = 'CANDIDATE',
  Company = 'COMPANY',
  Pdf = 'PDF'
}

export type CmAllowedArea = {
  __typename?: 'CmAllowedArea';
  allowed?: Maybe<Array<CmAllowedAreaType>>;
  defaultArea?: Maybe<CmAllowedAreaType>;
};

export enum CmAllowedAreaType {
  CompanyEmployee = 'COMPANY_EMPLOYEE',
  Hiring = 'HIRING',
  TalentReview = 'TALENT_REVIEW'
}

export type CmAllowedCompanyModel = {
  __typename?: 'CmAllowedCompanyModel';
  allowedAreas: CmAllowedArea;
  company: Company;
};

export type CmAllowedProject = {
  __typename?: 'CmAllowedProject';
  allowed?: Maybe<Array<CmAllowedProjectType>>;
};

export enum CmAllowedProjectType {
  CreateReviewRequest = 'CREATE_REVIEW_REQUEST',
  ExportView = 'EXPORT_VIEW',
  FullAccess = 'FULL_ACCESS',
  RequestTalentReview = 'REQUEST_TALENT_REVIEW',
  UploadCalibrations = 'UPLOAD_CALIBRATIONS',
  UploadReviewRequests = 'UPLOAD_REVIEW_REQUESTS'
}

export type CmAllowedResultActionsHiringModel = {
  __typename?: 'CmAllowedResultActionsHiringModel';
  hiring?: Maybe<Array<CmAllowedResultActionsHiringType>>;
};

export enum CmAllowedResultActionsHiringType {
  DeleteAssessment = 'DELETE_ASSESSMENT',
  ReopenRssAssessment = 'REOPEN_RSS_ASSESSMENT',
  ResendInvite = 'RESEND_INVITE',
  ResetAssessment = 'RESET_ASSESSMENT',
  ViewExternalAssessmentReport = 'VIEW_EXTERNAL_ASSESSMENT_REPORT',
  ViewReport = 'VIEW_REPORT'
}

export type CmAllowedResultActionsTalentReviewModel = {
  __typename?: 'CmAllowedResultActionsTalentReviewModel';
  tr?: Maybe<Array<CmAllowedResultActionsTalentReviewType>>;
};

export enum CmAllowedResultActionsTalentReviewType {
  Calibrate = 'CALIBRATE',
  CancelReview = 'CANCEL_REVIEW',
  ContinueReview = 'CONTINUE_REVIEW',
  DeleteReview = 'DELETE_REVIEW',
  ResendInvite = 'RESEND_INVITE',
  ResetReview = 'RESET_REVIEW',
  StartReview = 'START_REVIEW',
  ViewForm = 'VIEW_FORM'
}

export type CmCandidateDetailedScoreModel = {
  __typename?: 'CmCandidateDetailedScoreModel';
  resultName: Scalars['String'];
  resultScore?: Maybe<Scalars['String']>;
};

export type CmCandidateFormResponeModel = {
  __typename?: 'CmCandidateFormResponeModel';
  associatedId?: Maybe<Scalars['Int']>;
  fieldName: Scalars['String'];
  formType: FormType;
  value?: Maybe<Scalars['String']>;
};

export type CmCandidateResult = {
  allowedResultActions?: Maybe<AllowedResultActions>;
  /** @deprecated Please use stageCandidate.doneForUser.avatarUrl instead! */
  avatarUrl?: Maybe<Scalars['String']>;
  candidateDetailedScores?: Maybe<Array<CmCandidateDetailedScoreModel>>;
  candidateFormResponses?: Maybe<Array<CmCandidateFormResponeModel>>;
  /** @deprecated Please use stageCandidate.doneForUser.id instead! */
  candidateId: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneForUser.subId instead! */
  candidateSub: Scalars['String'];
  /** @deprecated Please use project.companyId instead! */
  companyId: Scalars['Int'];
  displayResultScores?: Maybe<Array<Maybe<DisplayResultScoreModel>>>;
  /** @deprecated Please use stageCandidate.doneBy instead! */
  doneBy: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneFor instead! */
  doneFor: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneForUser.email instead! */
  email: Scalars['String'];
  /** @deprecated Please use stageCandidate.doneForUser.firstName instead! */
  firstName: Scalars['String'];
  interviewGuideId?: Maybe<Scalars['Int']>;
  invitedDate?: Maybe<Scalars['Date']>;
  invitedStatus?: Maybe<InvitationStatusCode>;
  invitedType: InviteType;
  /** @deprecated Please use stageCandidate.lastDateOfCandidateAction instead! */
  lastDateOfCandidateAction?: Maybe<Scalars['Date']>;
  /** @deprecated Please use stageCandidate.doneForUser.lastName instead! */
  lastName: Scalars['String'];
  moduleType: ProjectModuleType;
  project: Project;
  projectCandidateGroupedStatus: ProjectCandidateGroupedStatus;
  projectCandidateStatus: ProjectCandidateStatus;
  projectCandidateStatusMessage?: Maybe<Scalars['String']>;
  /** @deprecated Please use project.id instead! */
  projectId: Scalars['Int'];
  projectJobRole: ProjectJobRoleModel;
  /** @deprecated Please use project.name instead! */
  projectName?: Maybe<Scalars['String']>;
  reports?: Maybe<Array<CmReportModel>>;
  resultScore?: Maybe<ResultScoreUnion>;
  stageCandidate: SmStageCandidateModel;
  /** @deprecated Please use stageCandidate.id instead! */
  stageCandidateId: Scalars['Int'];
  /** @deprecated Please use stageCandidate.status instead! */
  stageCandidateStatus: StageCandidateStatus;
  /** @deprecated Please use stageCandidate.subId instead! */
  stageCandidateSub: Scalars['String'];
  /** @deprecated Please use stageCandidate.stageId OR stageCandidate.stage.id instead! */
  stageId: Scalars['Int'];
};

export type CmCandidateResultModel = CmCandidateResult & {
  __typename?: 'CmCandidateResultModel';
  allowedResultActions?: Maybe<AllowedResultActions>;
  /** @deprecated Please use stageCandidate.doneForUser.avatarUrl instead! */
  avatarUrl?: Maybe<Scalars['String']>;
  candidateDetailedScores?: Maybe<Array<CmCandidateDetailedScoreModel>>;
  candidateFormResponses?: Maybe<Array<CmCandidateFormResponeModel>>;
  /** @deprecated Please use stageCandidate.doneForUser.id instead! */
  candidateId: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneForUser.subId instead! */
  candidateSub: Scalars['String'];
  /** @deprecated Please use project.companyId instead! */
  companyId: Scalars['Int'];
  displayResultScores?: Maybe<Array<Maybe<DisplayResultScoreModel>>>;
  /** @deprecated Please use stageCandidate.doneBy instead! */
  doneBy: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneFor instead! */
  doneFor: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneForUser.email instead! */
  email: Scalars['String'];
  /** @deprecated Please use stageCandidate.doneForUser.firstName instead! */
  firstName: Scalars['String'];
  interviewGuideId?: Maybe<Scalars['Int']>;
  invitedDate?: Maybe<Scalars['Date']>;
  invitedStatus?: Maybe<InvitationStatusCode>;
  invitedType: InviteType;
  /** @deprecated Please use stageCandidate.lastDateOfCandidateAction instead! */
  lastDateOfCandidateAction?: Maybe<Scalars['Date']>;
  /** @deprecated Please use stageCandidate.doneForUser.lastName instead! */
  lastName: Scalars['String'];
  moduleType: ProjectModuleType;
  project: Project;
  projectCandidateGroupedStatus: ProjectCandidateGroupedStatus;
  projectCandidateStatus: ProjectCandidateStatus;
  projectCandidateStatusMessage?: Maybe<Scalars['String']>;
  /** @deprecated Please use project.id instead! */
  projectId: Scalars['Int'];
  projectJobRole: ProjectJobRoleModel;
  /** @deprecated Please use project.name instead! */
  projectName?: Maybe<Scalars['String']>;
  reports?: Maybe<Array<CmReportModel>>;
  resultScore?: Maybe<ResultScoreUnion>;
  stageCandidate: SmStageCandidateModel;
  /** @deprecated Please use stageCandidate.id instead! */
  stageCandidateId: Scalars['Int'];
  /** @deprecated Please use stageCandidate.status instead! */
  stageCandidateStatus: StageCandidateStatus;
  /** @deprecated Please use stageCandidate.subId instead! */
  stageCandidateSub: Scalars['String'];
  /** @deprecated Please use stageCandidate.stageId OR stageCandidate.stage.id instead! */
  stageId: Scalars['Int'];
};

export type CmCandidateResultModelPaginated = {
  __typename?: 'CmCandidateResultModelPaginated';
  data?: Maybe<Array<CmCandidateResult>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type CmCandidateResultOrderByArgs = {
  candidateId?: InputMaybe<OrderByArgs>;
  department?: InputMaybe<OrderByArgs>;
  email?: InputMaybe<OrderByArgs>;
  firstName?: InputMaybe<OrderByArgs>;
  hrManagerEmail?: InputMaybe<OrderByArgs>;
  hrManagerFirstName?: InputMaybe<OrderByArgs>;
  hrManagerLastName?: InputMaybe<OrderByArgs>;
  invitedDate?: InputMaybe<OrderByArgs>;
  lastDateOfCandidateAction?: InputMaybe<OrderByArgs>;
  lastName?: InputMaybe<OrderByArgs>;
  managerEmail?: InputMaybe<OrderByArgs>;
  managerFirstName?: InputMaybe<OrderByArgs>;
  managerLastName?: InputMaybe<OrderByArgs>;
  resultScore?: InputMaybe<OrderByArgs>;
};

export type CmCandidateResultsExportCsvModel = {
  __typename?: 'CmCandidateResultsExportCsvModel';
  subId: Scalars['String'];
};

export type CmHiringResultsModel = CmCandidateResult & {
  __typename?: 'CmHiringResultsModel';
  allowedResultActions?: Maybe<AllowedResultActions>;
  /** @deprecated Please use stageCandidate.doneForUser.avatarUrl instead! */
  avatarUrl?: Maybe<Scalars['String']>;
  candidateDetailedScores?: Maybe<Array<CmCandidateDetailedScoreModel>>;
  candidateFormResponses?: Maybe<Array<CmCandidateFormResponeModel>>;
  /** @deprecated Please use stageCandidate.doneForUser.id instead! */
  candidateId: Scalars['Int'];
  candidateRank?: Maybe<Scalars['Int']>;
  /** @deprecated Please use stageCandidate.doneForUser.subId instead! */
  candidateSub: Scalars['String'];
  /** @deprecated Please use project.companyId instead! */
  companyId: Scalars['Int'];
  displayResultScores?: Maybe<Array<Maybe<DisplayResultScoreModel>>>;
  /** @deprecated Please use stageCandidate.doneBy instead! */
  doneBy: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneFor instead! */
  doneFor: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneForUser.email instead! */
  email: Scalars['String'];
  /** @deprecated Please use stageCandidate.doneForUser.firstName instead! */
  firstName: Scalars['String'];
  interviewGuideId?: Maybe<Scalars['Int']>;
  invitedDate?: Maybe<Scalars['Date']>;
  invitedStatus?: Maybe<InvitationStatusCode>;
  invitedType: InviteType;
  /** @deprecated Please use stageCandidate.lastDateOfCandidateAction instead! */
  lastDateOfCandidateAction?: Maybe<Scalars['Date']>;
  /** @deprecated Please use stageCandidate.doneForUser.lastName instead! */
  lastName: Scalars['String'];
  managers?: Maybe<Array<CmProjectCandidateManagerModel>>;
  moduleType: ProjectModuleType;
  project: Project;
  projectCandidateGroupedStatus: ProjectCandidateGroupedStatus;
  projectCandidateStatus: ProjectCandidateStatus;
  projectCandidateStatusMessage?: Maybe<Scalars['String']>;
  /** @deprecated Please use project.id instead! */
  projectId: Scalars['Int'];
  projectJobRole: ProjectJobRoleModel;
  /** @deprecated Please use project.name instead! */
  projectName?: Maybe<Scalars['String']>;
  reports?: Maybe<Array<CmReportModel>>;
  resultScore?: Maybe<ResultScoreUnion>;
  roleRank?: Maybe<Scalars['Int']>;
  stageCandidate: SmStageCandidateModel;
  /** @deprecated Please use stageCandidate.id instead! */
  stageCandidateId: Scalars['Int'];
  /** @deprecated Please use stageCandidate.status instead! */
  stageCandidateStatus: StageCandidateStatus;
  /** @deprecated Please use stageCandidate.subId instead! */
  stageCandidateSub: Scalars['String'];
  /** @deprecated Please use stageCandidate.stageId OR stageCandidate.stage.id instead! */
  stageId: Scalars['Int'];
};

export type CmProject = {
  __typename?: 'CmProject';
  companyId: Scalars['Int'];
  createdDate: Scalars['Date'];
  endDate?: Maybe<Scalars['Date']>;
  isArchived: Scalars['Boolean'];
  isStopped: Scalars['Boolean'];
  lastActivityDate: Scalars['Date'];
  managers?: Maybe<Array<Maybe<ProjectManager>>>;
  moduleType: ProjectModuleType;
  name: Scalars['String'];
  productSolution?: Maybe<ProductSolution>;
  projectId: Scalars['Int'];
  projectSubId: Scalars['String'];
  stageId: Scalars['Int'];
};

export type CmProjectCandidateManagerModel = {
  __typename?: 'CmProjectCandidateManagerModel';
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  managerId: Scalars['Int'];
};

export type CmProjectManagerModel = {
  __typename?: 'CmProjectManagerModel';
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  managerId: Scalars['Int'];
  projectRole?: Maybe<IdentityPermissionRole>;
};

export type CmProjectModelPaginated = {
  __typename?: 'CmProjectModelPaginated';
  data?: Maybe<Array<CmProject>>;
  pageInfo?: Maybe<Pageinfo>;
};

/** Sorting direction in candidate manager project */
export enum CmProjectSortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sorting options in candidate manager project */
export enum CmProjectSortingOptions {
  CreatedDate = 'CREATED_DATE',
  EndDate = 'END_DATE',
  LastUsedDate = 'LAST_USED_DATE',
  Name = 'NAME'
}

export type CmReportModel = {
  __typename?: 'CmReportModel';
  blob?: Maybe<Blob>;
  blobId: Scalars['Int'];
  reportId: Scalars['Int'];
  subType?: Maybe<ReportSubType>;
  type: ReportType;
};

export enum CmTrGridChartAxisEnum {
  Criticality = 'CRITICALITY',
  Impact = 'IMPACT',
  NumberSuccessors = 'NUMBER_SUCCESSORS',
  Risk = 'RISK',
  Score = 'SCORE'
}

export type CmTalentReviewGridChartBasicModel = {
  __typename?: 'CmTalentReviewGridChartBasicModel';
  cells?: Maybe<Array<Array<CmTalentReviewResultsCellModel>>>;
  xAxis: CmTrGridChartAxisEnum;
  xCount: Scalars['Int'];
  yAxis: CmTrGridChartAxisEnum;
  yCount: Scalars['Int'];
  zAxis?: Maybe<CmTrGridChartAxisEnum>;
  zCount?: Maybe<Scalars['Int']>;
};

export type CmTalentReviewGridChartFilterOptionsModel = {
  __typename?: 'CmTalentReviewGridChartFilterOptionsModel';
  divisions: Array<Scalars['String']>;
  hrBps: Array<User>;
  managers: Array<User>;
  projects: Array<Project>;
};

export type CmTalentReviewGridChartModel = {
  __typename?: 'CmTalentReviewGridChartModel';
  fields?: Maybe<Array<Array<Array<CmTalentReviewResultsDetailedModel>>>>;
  xAxis: CmTrGridChartAxisEnum;
  xCount: Scalars['Int'];
  yAxis: CmTrGridChartAxisEnum;
  yCount: Scalars['Int'];
  zAxis: CmTrGridChartAxisEnum;
  zCount: Scalars['Int'];
};

export type CmTalentReviewResultsCellItemModel = {
  __typename?: 'CmTalentReviewResultsCellItemModel';
  doneForUser: User;
  values: CmValuesModel;
};

export type CmTalentReviewResultsCellModel = {
  __typename?: 'CmTalentReviewResultsCellModel';
  additionalCount: Scalars['Int'];
  fields: Array<CmTalentReviewResultsCellItemModel>;
  hidden: Scalars['Boolean'];
};

export type CmTalentReviewResultsDetailedModel = {
  __typename?: 'CmTalentReviewResultsDetailedModel';
  doneForCompanyInfo: UserCompanyInfo;
  doneForUser: User;
  hrBp?: Maybe<User>;
  manager: User;
  project: Project;
  stageCandidate: SmStageCandidateModel;
  values: CmValuesModel;
};

export type CmTalentReviewResultsModel = CmCandidateResult & {
  __typename?: 'CmTalentReviewResultsModel';
  allowedResultActions?: Maybe<AllowedResultActions>;
  /** @deprecated Please use stageCandidate.doneForUser.avatarUrl instead! */
  avatarUrl?: Maybe<Scalars['String']>;
  candidateDetailedScores?: Maybe<Array<CmCandidateDetailedScoreModel>>;
  candidateFormResponses?: Maybe<Array<CmCandidateFormResponeModel>>;
  /** @deprecated Please use stageCandidate.doneForUser.id instead! */
  candidateId: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneForUser.subId instead! */
  candidateSub: Scalars['String'];
  /** @deprecated Please use project.companyId instead! */
  companyId: Scalars['Int'];
  directReportCompanyInfo?: Maybe<UserCompanyInfo>;
  displayResultScores?: Maybe<Array<Maybe<DisplayResultScoreModel>>>;
  /** @deprecated Please use stageCandidate.doneBy instead! */
  doneBy: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneFor instead! */
  doneFor: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneForUser.email instead! */
  email: Scalars['String'];
  endDate?: Maybe<Scalars['Date']>;
  /** @deprecated Please use stageCandidate.doneForUser.firstName instead! */
  firstName: Scalars['String'];
  hrCompanyInfo?: Maybe<UserCompanyInfo>;
  hrManager?: Maybe<User>;
  /** @deprecated Please use hrManager.email instead! */
  hrManagerEmail?: Maybe<Scalars['String']>;
  /** @deprecated Please use hrManager.firstName instead! */
  hrManagerFirstName?: Maybe<Scalars['String']>;
  /** @deprecated Please use hrManager.id instead! */
  hrManagerId?: Maybe<Scalars['Int']>;
  /** @deprecated Please use hrManager.lastName instead! */
  hrManagerLastName?: Maybe<Scalars['String']>;
  interviewGuideId?: Maybe<Scalars['Int']>;
  invitedDate?: Maybe<Scalars['Date']>;
  invitedStatus?: Maybe<InvitationStatusCode>;
  invitedType: InviteType;
  /** @deprecated Please use stageCandidate.lastDateOfCandidateAction instead! */
  lastDateOfCandidateAction?: Maybe<Scalars['Date']>;
  /** @deprecated Please use stageCandidate.doneForUser.lastName instead! */
  lastName: Scalars['String'];
  managerCompanyInfo?: Maybe<UserCompanyInfo>;
  /** @deprecated Please use stageCandidate.doneByUser.email instead! */
  managerEmail: Scalars['String'];
  /** @deprecated Please use stageCandidate.doneByUser.firstName instead! */
  managerFirstName: Scalars['String'];
  /** @deprecated Please use stageCandidate.doneBy OR stageCandidate.doneByUser.id instead! */
  managerId: Scalars['Int'];
  /** @deprecated Please use stageCandidate.doneByUser.lastName instead! */
  managerLastName: Scalars['String'];
  moduleType: ProjectModuleType;
  project: Project;
  projectCandidateGroupedStatus: ProjectCandidateGroupedStatus;
  projectCandidateStatus: ProjectCandidateStatus;
  projectCandidateStatusMessage?: Maybe<Scalars['String']>;
  /** @deprecated Please use project.id instead! */
  projectId: Scalars['Int'];
  projectJobRole: ProjectJobRoleModel;
  /** @deprecated Please use project.name instead! */
  projectName?: Maybe<Scalars['String']>;
  reports?: Maybe<Array<CmReportModel>>;
  resultAccess?: Maybe<ResultAccessModel>;
  resultScore?: Maybe<ResultScoreUnion>;
  stageCandidate: SmStageCandidateModel;
  /** @deprecated Please use stageCandidate.id instead! */
  stageCandidateId: Scalars['Int'];
  /** @deprecated Please use stageCandidate.status instead! */
  stageCandidateStatus: StageCandidateStatus;
  /** @deprecated Please use stageCandidate.subId instead! */
  stageCandidateSub: Scalars['String'];
  /** @deprecated Please use stageCandidate.stageId OR stageCandidate.stage.id instead! */
  stageId: Scalars['Int'];
};

export type CmValuesModel = {
  __typename?: 'CmValuesModel';
  criticality?: Maybe<TrCriticalityEnum>;
  impact?: Maybe<TrImpactEnum>;
  numberSuccessors?: Maybe<TrNumberSuccessorsEnum>;
  risk?: Maybe<TrRiskEnum>;
  score?: Maybe<TrCustomEvaluation>;
  successors?: Maybe<Array<Scalars['String']>>;
};

export type Company = {
  __typename?: 'Company';
  allowedEmailDomains?: Maybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  logoUrl?: Maybe<Scalars['String']>;
  managerReportEmailLinks: Scalars['Boolean'];
  name: Scalars['String'];
  uuid?: Maybe<Scalars['String']>;
};

export type CompanyEmployeeFileUploadArgs = {
  companyId: Scalars['Int'];
};

export type CompanyEmployeeFileUploadLineModel = {
  __typename?: 'CompanyEmployeeFileUploadLineModel';
  companyId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Date']>;
  customFields?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Date']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  hrManagerEmployeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  szUserId?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
};

export type CompanyEmployeeFileUploadModel = {
  __typename?: 'CompanyEmployeeFileUploadModel';
  apply: Scalars['Boolean'];
  companyId: Scalars['Int'];
  creates?: Maybe<Array<CompanyEmployeeFileUploadLineModel>>;
  deletes?: Maybe<Array<CompanyEmployeeFileUploadLineModel>>;
  errorMessage?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<CompanyEmployeeFileUploadLineModel>>;
  noChanges?: Maybe<Array<CompanyEmployeeFileUploadLineModel>>;
  updates?: Maybe<Array<CompanyEmployeeFileUploadLineModel>>;
};

export type CompanyEmployeeModel = {
  __typename?: 'CompanyEmployeeModel';
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  customFields?: Maybe<Scalars['JSON']>;
  deletedAt?: Maybe<Scalars['Date']>;
  department?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  employeeId: Scalars['String'];
  endDate?: Maybe<Scalars['Date']>;
  firstName: Scalars['String'];
  hrManagerEmployeeId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  isArchived?: Maybe<Scalars['Boolean']>;
  jobTitle?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  startDate?: Maybe<Scalars['Date']>;
  szUserId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Date'];
};

export type CompanyEmployeeModelPaginated = {
  __typename?: 'CompanyEmployeeModelPaginated';
  data?: Maybe<Array<CompanyEmployeeModel>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type CompanyEmployeeOrderByArgs = {
  email?: InputMaybe<OrderByArgs>;
  employeeId?: InputMaybe<OrderByArgs>;
  endDate?: InputMaybe<OrderByArgs>;
  firstName?: InputMaybe<OrderByArgs>;
  lastName?: InputMaybe<OrderByArgs>;
  startDate?: InputMaybe<OrderByArgs>;
  szUserId?: InputMaybe<OrderByArgs>;
};

export type CompanyEmployeeSelectField = Field & {
  __typename?: 'CompanyEmployeeSelectField';
  dynamicSelectOptions?: Maybe<Scalars['String']>;
  fieldInstanceType: FieldInstanceType;
  fieldOwnerId?: Maybe<Scalars['Int']>;
  fieldOwnerName?: Maybe<Scalars['String']>;
  fieldOwnerType?: Maybe<FieldOwnerType>;
  fieldType: FieldType;
  formManagerType: FormManagerType;
  hint?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  isFreetextField?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  settings?: Maybe<FieldSettingsModel>;
  txtData?: Maybe<Scalars['String']>;
};

export type CompanyMaybeUserEmployeeModel = {
  __typename?: 'CompanyMaybeUserEmployeeModel';
  email: Scalars['String'];
  employeeId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  isCompanyEmployee: Scalars['Boolean'];
  isUser: Scalars['Boolean'];
  lastName: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type CompanyModel = {
  allowedEmailDomains?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['Int'];
  logoUrl?: InputMaybe<Scalars['String']>;
  managerReportEmailLinks: Scalars['Boolean'];
  name: Scalars['String'];
  uuid?: InputMaybe<Scalars['String']>;
};

export type CompanyPaginatedModel = {
  __typename?: 'CompanyPaginatedModel';
  companies: Array<Company>;
  total: Scalars['Int'];
};

export type CompanyProjects = {
  __typename?: 'CompanyProjects';
  company: Company;
  id: Scalars['Int'];
  numberOfCompletedInTimeFrameStageCandidates: Scalars['Int'];
  numberOfCompletedStageCandidates: Scalars['Int'];
  numberOfStageCandidates: Scalars['Int'];
  percentageOfCompletedStageCandidates: Scalars['Float'];
  projects: Array<ProjectWithStageCandidates>;
};

export type CompanyRole = {
  __typename?: 'CompanyRole';
  companyId: Scalars['Int'];
  companyUuid: Scalars['String'];
  id: Scalars['Int'];
  isPasswordDisabled?: Maybe<Scalars['Boolean']>;
  role: Role;
  roleId: Scalars['Int'];
  samlAuthInfos?: Maybe<Array<SamlAuthInfoModel>>;
  twoFactorEnabled: Scalars['Boolean'];
};

export type CompanySignatureModel = {
  __typename?: 'CompanySignatureModel';
  company?: Maybe<Company>;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  signature: Scalars['String'];
  signatureEmail: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type CompanyUser = {
  __typename?: 'CompanyUser';
  group?: Maybe<UserGroupModel>;
  inProjectTeam?: Maybe<AssignmentStatus>;
  inStageCandidate?: Maybe<AssignmentStatus>;
  inUserGroup?: Maybe<AssignmentStatus>;
  user?: Maybe<User>;
};

export type CompanyUserInfo = {
  __typename?: 'CompanyUserInfo';
  company: Company;
  companyUserAcrossAllRoles: CompanyUsersCount;
  companyUserAcrossEachRole: Array<CompanyUsersWithRolesCount>;
};

export type CompanyUserInfoPaginated = {
  __typename?: 'CompanyUserInfoPaginated';
  companyUserInfo: Array<CompanyUserInfo>;
  total: Scalars['Int'];
};

export type CompanyUserModelPaginated = {
  __typename?: 'CompanyUserModelPaginated';
  data?: Maybe<Array<CompanyUser>>;
  pageInfo?: Maybe<Pageinfo>;
};

export enum CompanyUserType {
  Group = 'GROUP',
  User = 'USER'
}

export type CompanyUsersArgs = {
  /** The company id */
  companyId: Scalars['Int'];
  membersPreviewCount?: InputMaybe<Scalars['Int']>;
  /** Optional project id for filtering */
  projectId?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  /** Optional stage candidate id for filtering */
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  /** Optional user group id for filtering */
  userGroupId?: InputMaybe<Scalars['Int']>;
  userType?: InputMaybe<CompanyUserType>;
};

export type CompanyUsersCount = {
  __typename?: 'CompanyUsersCount';
  companyId: Scalars['Int'];
  totalUsers: Scalars['Int'];
  totalUsersWithPassword: Scalars['Int'];
};

export enum CompanyUsersOrderField {
  Name = 'NAME'
}

export type CompanyUsersOrderOptions = {
  direction?: InputMaybe<SortOption>;
  field?: InputMaybe<CompanyUsersOrderField>;
};

export type CompanyUsersWithRolesCount = {
  __typename?: 'CompanyUsersWithRolesCount';
  companyId: Scalars['Int'];
  roleName: IdentityPermissionRole;
  totalUsers: Scalars['Int'];
  totalUsersWithPassword: Scalars['Int'];
};

export enum ContentClassification {
  Negative = 'NEGATIVE',
  Neutral = 'NEUTRAL',
  Positive = 'POSITIVE'
}

export enum ContentRoleLevel {
  Apprentice = 'APPRENTICE',
  IndividualContributor = 'INDIVIDUAL_CONTRIBUTOR',
  Leader = 'LEADER',
  Manager = 'MANAGER',
  ManagerOfManagers = 'MANAGER_OF_MANAGERS',
  TechnicalExpert = 'TECHNICAL_EXPERT'
}

export type ContentValidatiorPieceModel = {
  __typename?: 'ContentValidatiorPieceModel';
  numOfIqContent: Scalars['String'];
  numOfSsContent: Scalars['String'];
  numOfSsSpContent: Scalars['String'];
  numOfTraitContent: Scalars['String'];
  softSkillId?: Maybe<Scalars['Int']>;
  softSkillName?: Maybe<Scalars['String']>;
  traitId?: Maybe<Scalars['Int']>;
  traitName?: Maybe<Scalars['String']>;
};

export type ContentValidator = {
  __typename?: 'ContentValidator';
  content: Array<ContentValidatiorPieceModel>;
  count?: Maybe<Scalars['Int']>;
};

export type ContentViewerViewStageCandidateManagerReportModel = {
  __typename?: 'ContentViewerViewStageCandidateManagerReportModel';
  pollingStatus?: Maybe<PollingStatus>;
  pollingStatusMessage?: Maybe<Scalars['String']>;
  pollingSubStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type ContentViewerViewStageCandidateProjectJobRoleModel = {
  __typename?: 'ContentViewerViewStageCandidateProjectJobRoleModel';
  id: Scalars['Int'];
  jobRoleId: Scalars['Int'];
  jobRoleName: Scalars['String'];
  projectId: Scalars['Int'];
  projectName: Scalars['String'];
  reports: Array<ContentViewerViewStageCandidateProjectJobRoleReportModel>;
  skillsProfileId: Scalars['Int'];
};

export type ContentViewerViewStageCandidateProjectJobRoleReportModel = {
  __typename?: 'ContentViewerViewStageCandidateProjectJobRoleReportModel';
  subType?: Maybe<ReportSubType>;
  type: ReportType;
  url: Scalars['String'];
};

export type ContentViewerViewStageCandidateReportModel = {
  __typename?: 'ContentViewerViewStageCandidateReportModel';
  pollingStatus?: Maybe<PollingStatus>;
  pollingStatusMessage?: Maybe<Scalars['String']>;
  pollingSubStatus?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export enum ContextualScorePositionType {
  Strength = 'STRENGTH',
  Weakness = 'WEAKNESS'
}

export type CreateFormFieldByFieldIdArgs = {
  displayOrder?: InputMaybe<Scalars['Int']>;
  fieldId: Scalars['Int'];
  isOptional: Scalars['Boolean'];
};

export type CreateProjectManagerInput = {
  actorRole?: InputMaybe<ActorRole>;
  userId: Scalars['Int'];
};

export enum DataSourceType {
  EmployeeAssessment = 'EMPLOYEE_ASSESSMENT',
  EmployeeSurvey = 'EMPLOYEE_SURVEY',
  Industry = 'INDUSTRY',
  JdReview = 'JD_REVIEW',
  ManagerSurvey = 'MANAGER_SURVEY'
}

export type DateField = Field & {
  __typename?: 'DateField';
  fieldInstanceType: FieldInstanceType;
  fieldOwnerId?: Maybe<Scalars['Int']>;
  fieldOwnerName?: Maybe<Scalars['String']>;
  fieldOwnerType?: Maybe<FieldOwnerType>;
  fieldType: FieldType;
  formManagerType: FormManagerType;
  hint?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  settings?: Maybe<FieldSettingsModel>;
  txtData?: Maybe<Scalars['String']>;
};

export enum DeiAgeRange {
  N18_30 = 'N18_30',
  N31_40 = 'N31_40',
  N41_50 = 'N41_50',
  N51_65 = 'N51_65',
  N65Plus = 'N65_PLUS',
  PreferNotToSay = 'PREFER_NOT_TO_SAY'
}

export enum DeiEthnicity {
  Asian = 'ASIAN',
  Black = 'BLACK',
  Hispanic = 'HISPANIC',
  IndigenousPeoples = 'INDIGENOUS_PEOPLES',
  MiddleEasternNorthAfrican = 'MIDDLE_EASTERN_NORTH_AFRICAN',
  Mixed = 'MIXED',
  Other = 'OTHER',
  PacificIslander = 'PACIFIC_ISLANDER',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  White = 'WHITE'
}

export enum DeiGender {
  Female = 'FEMALE',
  GenderQueer = 'GENDER_QUEER',
  Intersex = 'INTERSEX',
  Male = 'MALE',
  NonBinary = 'NON_BINARY',
  Other = 'OTHER',
  PreferNotToSay = 'PREFER_NOT_TO_SAY'
}

export enum DeiReligiousBelief {
  Agnosticism = 'AGNOSTICISM',
  Buddhism = 'BUDDHISM',
  Christianity = 'CHRISTIANITY',
  Hinduism = 'HINDUISM',
  Islam = 'ISLAM',
  Judaism = 'JUDAISM',
  Other = 'OTHER',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Secular = 'SECULAR',
  Sikhism = 'SIKHISM',
  Spiritual = 'SPIRITUAL'
}

export enum DeiSexualOrientation {
  Asexual = 'ASEXUAL',
  Bisexual = 'BISEXUAL',
  Gay = 'GAY',
  Lesbian = 'LESBIAN',
  Other = 'OTHER',
  Pansexual = 'PANSEXUAL',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Queer = 'QUEER',
  Straight = 'STRAIGHT'
}

export type DeiStageActionCandidateModel = {
  __typename?: 'DeiStageActionCandidateModel';
  createdAt: Scalars['Date'];
  pastResponses?: Maybe<DeiStageActionCandidateResponsesModel>;
  responses: DeiStageActionCandidateResponsesModel;
  stageAction: DeiStageActionModel;
  stageCandidate: SmStageCandidateModel;
  status: DeiStageActionCandidateStatus;
  updatedAt: Scalars['Date'];
};

export type DeiStageActionCandidateResponsesInput = {
  ageRange?: InputMaybe<DeiAgeRange>;
  ethnicity?: InputMaybe<DeiEthnicity>;
  genderIdentity?: InputMaybe<DeiGender>;
  hasDisability?: InputMaybe<DeiYesNo>;
  hasNeurodiversity?: InputMaybe<DeiYesNo>;
  hasTransgenderIdentity?: InputMaybe<DeiYesNo>;
  religiousBelief?: InputMaybe<DeiReligiousBelief>;
  sexualOrientation?: InputMaybe<DeiSexualOrientation>;
};

export type DeiStageActionCandidateResponsesModel = {
  __typename?: 'DeiStageActionCandidateResponsesModel';
  ageRange?: Maybe<DeiAgeRange>;
  ethnicity?: Maybe<DeiEthnicity>;
  genderIdentity?: Maybe<DeiGender>;
  hasDisability?: Maybe<DeiYesNo>;
  hasNeurodiversity?: Maybe<DeiYesNo>;
  hasTransgenderIdentity?: Maybe<DeiYesNo>;
  religiousBelief?: Maybe<DeiReligiousBelief>;
  sexualOrientation?: Maybe<DeiSexualOrientation>;
};

export enum DeiStageActionCandidateStatus {
  Editable = 'EDITABLE',
  Submitted = 'SUBMITTED'
}

export type DeiStageActionFieldsInput = {
  hasAgeRangeField: Scalars['Boolean'];
  hasDisabilityField: Scalars['Boolean'];
  hasEthnicityField: Scalars['Boolean'];
  hasGenderIdentityField: Scalars['Boolean'];
  hasNeurodiversityField: Scalars['Boolean'];
  hasReligiousBeliefsField: Scalars['Boolean'];
  hasSexualOrientationField: Scalars['Boolean'];
};

export type DeiStageActionFieldsModel = {
  __typename?: 'DeiStageActionFieldsModel';
  hasAgeRangeField: Scalars['Boolean'];
  hasDisabilityField: Scalars['Boolean'];
  hasEthnicityField: Scalars['Boolean'];
  hasGenderIdentityField: Scalars['Boolean'];
  hasNeurodiversityField: Scalars['Boolean'];
  hasReligiousBeliefsField: Scalars['Boolean'];
  hasSexualOrientationField: Scalars['Boolean'];
};

export type DeiStageActionModel = {
  __typename?: 'DeiStageActionModel';
  /** @deprecated Please use submittedCount instead! */
  completedCount: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  fields: DeiStageActionFieldsModel;
  stageId: Scalars['Int'];
  submittedCount: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type DeiStageActionTemplateFieldsInput = {
  hasAgeRangeField: Scalars['Boolean'];
  hasDisabilityField: Scalars['Boolean'];
  hasEthnicityField: Scalars['Boolean'];
  hasGenderIdentityField: Scalars['Boolean'];
  hasNeurodiversityField: Scalars['Boolean'];
  hasReligiousBeliefsField: Scalars['Boolean'];
  hasSexualOrientationField: Scalars['Boolean'];
};

export type DeiStageActionTemplateFieldsModel = {
  __typename?: 'DeiStageActionTemplateFieldsModel';
  hasAgeRangeField: Scalars['Boolean'];
  hasDisabilityField: Scalars['Boolean'];
  hasEthnicityField: Scalars['Boolean'];
  hasGenderIdentityField: Scalars['Boolean'];
  hasNeurodiversityField: Scalars['Boolean'];
  hasReligiousBeliefsField: Scalars['Boolean'];
  hasSexualOrientationField: Scalars['Boolean'];
};

export type DeiStageActionTemplateModel = {
  __typename?: 'DeiStageActionTemplateModel';
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  fields: DeiStageActionTemplateFieldsModel;
  updatedAt: Scalars['Date'];
};

export enum DeiYesNo {
  No = 'NO',
  PreferNotToSay = 'PREFER_NOT_TO_SAY',
  Yes = 'YES'
}

export type DeleteByIdArgs = {
  id: Scalars['Int'];
};

export type DeleteFormFieldByFieldIdArgs = {
  fieldId: Scalars['Int'];
};

export type DeleteResult = {
  __typename?: 'DeleteResult';
  affected?: Maybe<Scalars['Int']>;
};

export type Department = {
  __typename?: 'Department';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type DisplayResultScoreModel = {
  __typename?: 'DisplayResultScoreModel';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export enum DisplayScoreType {
  Grade = 'GRADE',
  None = 'NONE',
  Percentile = 'PERCENTILE',
  StenScore = 'STEN_SCORE',
  ZScore = 'Z_SCORE'
}

export type DlxRejectedEvent = {
  __typename?: 'DlxRejectedEvent';
  createdAt: Scalars['String'];
  deathQueue: Scalars['String'];
  deletedAt: Scalars['String'];
  event: Scalars['String'];
  id: Scalars['Int'];
  rejectedCount: Scalars['Int'];
  rejectedStatus: Scalars['String'];
  updatedAt: Scalars['String'];
};

export type EaCompanyPlatformCreateOneArgs = {
  /** The company id which is being linked with the external platform assessment */
  companyId: Scalars['Int'];
  /** The platform type, for each platform company must have specific platform link if you want to make specific assessments for the company on that platform */
  externalPlatformType: ExternalPlatformType;
};

/** Joining table between company and external assessments. External assessments point to this entity, if the company doesn't have any it will be automatically created when first time assigning an assessment to a company. Also, it combines the platform type and the company id so we can have multiple company platforms.This does not limit a company to use any of the freely available assessments from the available platforms. */
export type EaCompanyPlatformModel = {
  __typename?: 'EACompanyPlatformModel';
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  externalPlatformType: ExternalPlatformType;
  id: Scalars['Int'];
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type EaPlatformAssessmentCreateOneArgs = {
  /** The ID of the external assessment on the external platform. */
  eaPlatformId: Scalars['String'];
  /** Modifier this assessment represents on our platform. You must have the DEFAULT modifier for each external assessment as a minimum. */
  modifier: ExternalPlatformModifier;
};

/** This is a child model of external_assessment that links any of the ExternalPlatformModifier with a specific external_platform_assessment and our own external_assessment model. */
export type EaPlatformAssessmentModel = {
  __typename?: 'EAPlatformAssessmentModel';
  /** Some ID for the assessment on the external platform */
  eaPlatformId: Scalars['String'];
  id: Scalars['Int'];
  /** Defines if the platform assessment is a default or is offering some type of modification. Each external_assessment can have only one of each modifiers which is ensured by unique index. */
  modifier: ExternalPlatformModifier;
  /** The URL that leads to the assessment on the platform (if available) */
  platformUrl?: Maybe<Scalars['String']>;
  subId: Scalars['String'];
};

export type EaPlatformAssessmentUpsertOneArgs = {
  /** The ID of the external assessment on the external platform. */
  eaPlatformId: Scalars['String'];
  id?: InputMaybe<Scalars['Int']>;
  /** Modifier this assessment represents on our platform. You must have the DEFAULT modifier for each external assessment as a minimum. */
  modifier: ExternalPlatformModifier;
};

export type EaResultCheatingDetailModel = {
  __typename?: 'EAResultCheatingDetailModel';
  pasted_code: Scalars['String'];
  plagiarism: Scalars['String'];
  tab_leaving: Scalars['Int'];
};

export type EaResultCodingChallengeDetailModel = {
  __typename?: 'EAResultCodingChallengeDetailModel';
  date: Scalars['String'];
  language: Scalars['String'];
  score: Scalars['Int'];
  time_taken: Scalars['Int'];
  title: Scalars['String'];
};

export type EaResultMcqDetailModel = {
  __typename?: 'EAResultMcqDetailModel';
  correct: Scalars['Boolean'];
  id: Scalars['String'];
  question: Scalars['String'];
};

/** Received raw results from the platform about the candidates results and assessment completion */
export type EaResultUniversalModel = {
  __typename?: 'EAResultUniversalModel';
  cheatingDetails?: Maybe<EaResultCheatingDetailModel>;
  cheatingFlag?: Maybe<Scalars['String']>;
  codeScore?: Maybe<Scalars['Int']>;
  /** Candidates coding challenge score from the raw platform data in percentage (if available) */
  codeScorePercentage?: Maybe<Scalars['Float']>;
  codingChallengeDetails?: Maybe<Array<EaResultCodingChallengeDetailModel>>;
  createdAt: Scalars['Date'];
  dateJoined?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['Date']>;
  displayName?: Maybe<Scalars['String']>;
  /** The candidate for the external assessment action that this result belongs to */
  eaStageActionCandidate: EaStageActionCandidateModel;
  eaStageActionCandidateId: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  /** Candidates final score from the raw platform data in percentage */
  finalScorePercentage: Scalars['Float'];
  id: Scalars['Int'];
  invitationUuid: Scalars['String'];
  mcqDetails?: Maybe<Array<EaResultMcqDetailModel>>;
  mcqScore?: Maybe<Scalars['Int']>;
  /** Candidates multi-choice questions score from the raw platform data in percentage (if available) */
  mcqScorePercentage?: Maybe<Scalars['Float']>;
  overallScore?: Maybe<Scalars['Int']>;
  platformType: ExternalPlatformType;
  qualified?: Maybe<Scalars['Boolean']>;
  /** Report URL with the detailed results of the assessment (if platform supports it) */
  reportUrl: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  subId: Scalars['String'];
  testId?: Maybe<Scalars['String']>;
  timeTaken: Scalars['Int'];
  /** Amount of time the candidate took to complete the challenge based on the data from the platform (if available) */
  timeTakenMinutes?: Maybe<Scalars['Int']>;
  totalChallenges?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Date'];
  username?: Maybe<Scalars['String']>;
  voteDecision?: Maybe<Scalars['String']>;
};

export type EaSkillCalculateInstructionsArgs = {
  /** The weight of the coding challenge's score in the final result */
  challengeWeight?: InputMaybe<Scalars['Int']>;
  /** Flag if the skill has a written part that needs to be calculated */
  hasMcq: Scalars['Boolean'];
  /** The weight of the MCQ's score in the final result */
  mcqWeight?: InputMaybe<Scalars['Int']>;
};

/** Instructions that define how the skill will combine the results and calculate the score. It also includes the information that helps us link our skills with the skills on the platform since they might be named differently. */
export type EaSkillCalculateInstructionsModel = {
  __typename?: 'EASkillCalculateInstructionsModel';
  /** The weight of the coding challenge's score in the final result */
  challengeWeight?: Maybe<Scalars['Int']>;
  hasMcq: Scalars['Boolean'];
  /** The weight of the MCQ's score in the final result */
  mcqWeight?: Maybe<Scalars['Int']>;
};

export type EaSkillCreateOneArgs = {
  /** The calculation instructions that will be used when receiving the results from the platform to match them with our internal skills */
  calculationInstructions?: InputMaybe<EaSkillCalculateInstructionsArgs>;
  /** Technical skill ID which is being linked with the external assessment */
  technicalSkillId: Scalars['Int'];
};

/** Table for linking Spotted Zebra skills with the external_assessment. This linking will hold necessary information for the EAResultService to successfully match the results to skills. (Eg. Technical SQL skill must match MCQ SQL from the platform results) */
export type EaSkillModel = {
  __typename?: 'EASkillModel';
  calculationInstructions: EaSkillCalculateInstructionsModel;
  id: Scalars['Int'];
  subId: Scalars['String'];
  technicalSkillId: Scalars['Int'];
};

export type EaSkillUpsertOneArgs = {
  /** The calculation instructions that will be used when receiving the results from the platform to match them with our internal skills */
  calculationInstructions?: InputMaybe<EaSkillCalculateInstructionsArgs>;
  id?: InputMaybe<Scalars['Int']>;
  /** Technical skill ID which is being linked with the external assessment */
  technicalSkillId: Scalars['Int'];
};

/** Sorting direction in external assessments */
export enum EaSortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Sorting options in external assessments */
export enum EaSortingOptions {
  Company = 'COMPANY',
  CreatedAt = 'CREATED_AT',
  EstimatedTime = 'ESTIMATED_TIME',
  LastUsedAt = 'LAST_USED_AT',
  Name = 'NAME',
  TimeLimit = 'TIME_LIMIT',
  UpdatedAt = 'UPDATED_AT',
  Vendor = 'VENDOR'
}

/** This model is returned when trying to check the availability of the external assessment for the given stage action. Or it returns already created stage actions if they exist. */
export type EaStageActionAvailabilityModel = {
  __typename?: 'EAStageActionAvailabilityModel';
  /** Tells you if there are external assessments matching the given skills, if the stage action exists, this will be automatically true */
  available: Scalars['Boolean'];
  /** Returns the already existing stage actions (if it exists) */
  eaStageActions: Array<EaStageActionModel>;
};

/** This is a link between stage candidate and external assessment (through ea_stage_action) and is a product of inviting a candidate to stage.When the StageCandidate is created, the EAStageActionCandidate is created for each EAStageAction. */
export type EaStageActionCandidateModel = {
  __typename?: 'EAStageActionCandidateModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  eaStageAction?: Maybe<EaStageActionModel>;
  eaStageActionId: Scalars['Int'];
  /** Randomly generated email for the candidate to be used on the external platform. Email is generated with format: `${this.stageCandidateId}-${this.id}-${randomUuid()}@spottedzebra.co.uk` */
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  invitationLink?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  modifier?: Maybe<ExternalPlatformModifier>;
  stageCandidateId: Scalars['Int'];
  status: EaStageActionCandidateStatus;
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
  userId: Scalars['Int'];
};

/** Status of the candidate in the external assessment process. */
export enum EaStageActionCandidateStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  ResultPending = 'RESULT_PENDING'
}

/** Links the stage action with external assessment, this is a product of adding the external assessment into stage. */
export type EaStageActionModel = {
  __typename?: 'EAStageActionModel';
  /** The company id where the stage project belongs */
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  /** The external assessment that is linked for this stage action */
  externalAssessment: ExternalAssessmentModel;
  /** The external assessment id that is linked for this stage action */
  externalAssessmentId: Scalars['Int'];
  id: Scalars['Int'];
  /** The stage id where this action belongs */
  stageId: Scalars['Int'];
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type EaIntegration = {
  __typename?: 'EaIntegration';
  companyId: Scalars['Int'];
  provider: Scalars['Int'];
};

export type EaIntegrationModel = {
  __typename?: 'EaIntegrationModel';
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  provider: AtsProvider;
  updatedAt: Scalars['Date'];
};

export type EmailTemplateModel = {
  __typename?: 'EmailTemplateModel';
  id: Scalars['Int'];
  /** @deprecated Please use "locale" instead! */
  language: Language;
  locale: SupportedLocale;
  templateId: Scalars['String'];
  type: EmailTemplateType;
  version: Scalars['Int'];
};

export enum EmailTemplateType {
  DeleteTalentReviewRequest = 'DELETE_TALENT_REVIEW_REQUEST',
  IgRecipientNotification = 'IG_RECIPIENT_NOTIFICATION',
  InvitationCandidate = 'INVITATION_CANDIDATE',
  InvitationCompany = 'INVITATION_COMPANY',
  InvitationTalentReview = 'INVITATION_TALENT_REVIEW',
  JobSpecification = 'JOB_SPECIFICATION',
  ManagerRequestSurvey = 'MANAGER_REQUEST_SURVEY',
  MfaCode = 'MFA_CODE',
  PasswordRecovery = 'PASSWORD_RECOVERY',
  PasswordRecoverySaml = 'PASSWORD_RECOVERY_SAML',
  ReportCandidate = 'REPORT_CANDIDATE',
  ReportLinksManager = 'REPORT_LINKS_MANAGER',
  ReportManager = 'REPORT_MANAGER',
  ReportSzAdmin = 'REPORT_SZ_ADMIN',
  RequestTalentReview = 'REQUEST_TALENT_REVIEW',
  SuccessProfileCompletion = 'SUCCESS_PROFILE_COMPLETION'
}

export type EntityLanguageModel = {
  __typename?: 'EntityLanguageModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  entityId: Scalars['Int'];
  entityType: EntityType;
  id: Scalars['Int'];
  language: Language;
  updatedAt: Scalars['Date'];
};

export enum EntityType {
  Project = 'PROJECT'
}

export type ExternalAssessmentCodeScoreModel = {
  __typename?: 'ExternalAssessmentCodeScoreModel';
  /** Candidates coding challenge score from the raw platform data in percentage (if available) */
  codeScorePercentage: Scalars['Float'];
  /** Platform type where this assessment was taken */
  externalPlatformType: ExternalPlatformType;
  /** Name of the assessment candidate took */
  name: Scalars['String'];
  /** Report URL with the detailed results of the assessment (if platform supports it) */
  reportUrl: Scalars['String'];
  /** Amount of time the candidate took to complete the challenge based on the data from the platform (if available) */
  timeTakenMinutes: Scalars['Int'];
};

export type ExternalAssessmentFinalScoreModel = {
  __typename?: 'ExternalAssessmentFinalScoreModel';
  /** Platform type where this assessment was taken */
  externalPlatformType: ExternalPlatformType;
  /** Candidates final score from the raw platform data in percentage */
  finalScorePercentage: Scalars['Float'];
  /** Name of the assessment candidate took */
  name: Scalars['String'];
  /** Report URL with the detailed results of the assessment (if platform supports it) */
  reportUrl: Scalars['String'];
  /** Amount of time the candidate took to complete the challenge based on the data from the platform (if available) */
  timeTakenMinutes: Scalars['Int'];
};

export type ExternalAssessmentMcqScoreModel = {
  __typename?: 'ExternalAssessmentMcqScoreModel';
  /** Platform type where this assessment was taken */
  externalPlatformType: ExternalPlatformType;
  /** Candidates multi-choice questions score from the raw platform data in percentage (if available) */
  mcqScorePercentage: Scalars['Float'];
  /** Name of the assessment candidate took */
  name: Scalars['String'];
  /** Report URL with the detailed results of the assessment (if platform supports it) */
  reportUrl: Scalars['String'];
  /** Amount of time the candidate took to complete the challenge based on the data from the platform (if available) */
  timeTakenMinutes: Scalars['Int'];
};

/** Any type of assessment representing a third-party integration assessment from any provider containing the data and links required for us to operate with it through our ecosystem. */
export type ExternalAssessmentModel = {
  __typename?: 'ExternalAssessmentModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  eaCompanyPlatform?: Maybe<EaCompanyPlatformModel>;
  eaCompanyPlatformId?: Maybe<Scalars['Int']>;
  eaPlatformAssessments?: Maybe<Array<EaPlatformAssessmentModel>>;
  eaSkills?: Maybe<Array<EaSkillModel>>;
  estimatedDurationInMinutes?: Maybe<Scalars['Int']>;
  /** Defines the platform type (adapter) where this assessment is */
  externalPlatformType: ExternalPlatformType;
  id: Scalars['Int'];
  isArchived?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  subId: Scalars['String'];
  timeLimitInMinutes?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Date'];
};

/** Representation of each platform's adapter */
export type ExternalPlatformAdapterModel = {
  __typename?: 'ExternalPlatformAdapterModel';
  name: Scalars['String'];
  /** Type of the platform this adapter is for */
  type: ExternalPlatformType;
};

/** Internal representation of ANY kind of external platform assessment object. */
export type ExternalPlatformAssessmentModel = {
  __typename?: 'ExternalPlatformAssessmentModel';
  active: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  /** The ID of the assessment on the platform, this is the ID we will be using when pointing to it. (together with platform type attribute) */
  id: Scalars['String'];
  name: Scalars['String'];
  /** Original JSON object that was gathered from the platform */
  original?: Maybe<RawPlatformAssessmentModel>;
};

/** Our external assessments can link to multiple assessments on the platform. The linked assessments from the platform can have a different modification meaning that is defined by this enum. */
export enum ExternalPlatformModifier {
  Default = 'DEFAULT',
  DurationPlus_25 = 'DURATION_PLUS_25',
  DurationPlus_50 = 'DURATION_PLUS_50',
  DurationPlus_75 = 'DURATION_PLUS_75',
  DurationPlus_100 = 'DURATION_PLUS_100'
}

export enum ExternalPlatformType {
  Coderbyte = 'CODERBYTE'
}

export type Field = {
  fieldInstanceType: FieldInstanceType;
  fieldOwnerId?: Maybe<Scalars['Int']>;
  fieldOwnerName?: Maybe<Scalars['String']>;
  fieldOwnerType?: Maybe<FieldOwnerType>;
  fieldType: FieldType;
  formManagerType: FormManagerType;
  hint?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  settings?: Maybe<FieldSettingsModel>;
  txtData?: Maybe<Scalars['String']>;
};

export enum FieldInstanceType {
  /** Multiple - Information is not shared between forms */
  Multiple = 'MULTIPLE',
  /** Singleton - All forms share the same information */
  Singleton = 'SINGLETON'
}

export enum FieldOwnerType {
  CompanyFieldOwner = 'COMPANY_FIELD_OWNER',
  SzFieldOwner = 'SZ_FIELD_OWNER'
}

export type FieldSettingsArgs = {
  allowFreeText?: InputMaybe<Scalars['Boolean']>;
  searchable?: InputMaybe<Scalars['Boolean']>;
};

export type FieldSettingsModel = {
  __typename?: 'FieldSettingsModel';
  allowFreeText?: Maybe<Scalars['Boolean']>;
  searchable?: Maybe<Scalars['Boolean']>;
};

export enum FieldType {
  CompanyEmployeeSelectField = 'COMPANY_EMPLOYEE_SELECT_FIELD',
  /** Date */
  DateField = 'DATE_FIELD',
  /** Long text */
  LongTextField = 'LONG_TEXT_FIELD',
  /** Multiple select */
  MultipleSelectField = 'MULTIPLE_SELECT_FIELD',
  /** Short text */
  ShortTextField = 'SHORT_TEXT_FIELD',
  /** Single select */
  SingleSelectField = 'SINGLE_SELECT_FIELD'
}

export type FileExportScheduledTask = ScheduledTask & {
  __typename?: 'FileExportScheduledTask';
  args: ScheduledTaskArgs;
  blob?: Maybe<Blob>;
  error?: Maybe<Scalars['String']>;
  kind: ScheduledTaskKind;
  status: ScheduledTaskStatus;
  subId: Scalars['String'];
};

export type FilterMomentRange = {
  from?: InputMaybe<Scalars['Date']>;
  to?: InputMaybe<Scalars['Date']>;
};

export type Form = {
  __typename?: 'Form';
  formFields?: Maybe<Array<FormField>>;
  formManagerType: FormManagerType;
  formOwner: FormOwner;
  formOwnerName: Scalars['String'];
  formType: FormType;
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type FormBuilder = {
  __typename?: 'FormBuilder';
  availableFields?: Maybe<Array<Field>>;
  form?: Maybe<Form>;
};

export type FormCandidateModel = {
  __typename?: 'FormCandidateModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  form: Form;
  formId: Scalars['Int'];
  id: Scalars['Int'];
  stageCandidate?: Maybe<SmStageCandidateModel>;
  stageCandidateId: Scalars['Int'];
  status: FormCandidateStatus;
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum FormCandidateStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type FormField = {
  __typename?: 'FormField';
  displayOrder: Scalars['Int'];
  field: Field;
  form: Form;
  id: Scalars['Int'];
  isOptional: Scalars['Boolean'];
};

export enum FormManagerType {
  Apprentice = 'APPRENTICE',
  /** Candidate Information */
  Ci = 'CI',
  /** Global - all forms can use this */
  Global = 'GLOBAL',
  /** Manager survey */
  ManagerSurvey = 'MANAGER_SURVEY',
  /** Quality of hire */
  QualityOfHire = 'QUALITY_OF_HIRE',
  Rs = 'RS',
  /** Talent reveiw */
  TalentReview = 'TALENT_REVIEW'
}

export type FormOwner = {
  __typename?: 'FormOwner';
  form: Form;
  formId: Scalars['Int'];
  formOwnerId: Scalars['Int'];
  id: Scalars['Int'];
};

export type FormReportFormOwners = {
  __typename?: 'FormReportFormOwners';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type FormReportIndividualQueries = {
  __typename?: 'FormReportIndividualQueries';
  associatedIds?: Maybe<Array<Scalars['Int']>>;
  formManagerTypes?: Maybe<Array<FormManagerType>>;
  formTypes?: Maybe<Array<FormType>>;
};

export enum FormType {
  ApprenticeForm = 'APPRENTICE_FORM',
  CdForm = 'CD_FORM',
  CiForm = 'CI_FORM',
  GradForm = 'GRAD_FORM',
  MsEmployeeForm = 'MS_EMPLOYEE_FORM',
  MsManagerForm = 'MS_MANAGER_FORM',
  RsForm = 'RS_FORM',
  TrForm = 'TR_FORM',
  UndergradForm = 'UNDERGRAD_FORM'
}

export type GheaIntegrationModel = {
  __typename?: 'GHEAIntegrationModel';
  clientId: Scalars['String'];
  clientSecret?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  eaIntegration?: Maybe<EaIntegrationModel>;
  eaIntegrationId: Scalars['Int'];
  id: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type GenerateCandidateFeedbackResponseModel = {
  __typename?: 'GenerateCandidateFeedbackResponseModel';
  correlationId: Scalars['String'];
  isOk: Scalars['Boolean'];
};

export type GenerateStageIndirectInvitationUrlModel = {
  __typename?: 'GenerateStageIndirectInvitationUrlModel';
  url: Scalars['String'];
};

export enum GetOption {
  OrDefault = 'OR_DEFAULT',
  OrEmptyArray = 'OR_EMPTY_ARRAY',
  OrNull = 'OR_NULL',
  OrThrow = 'OR_THROW'
}

export enum Grade {
  A = 'A',
  APlus = 'A_PLUS',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E'
}

export type GradeBandUnion = IgBehaviourGradeBandModel | InterviewGuideCustomGradeBandModel | InterviewGuideFinalDecisionGradeBandModel | SoftSkillSelfAssessmentGradeBandModel | StatisticalGradeBandModel | TechnicalSkillSelfAssessmentGradeBandModel | TrCustomGradeBandModel;

export enum GradingMethod {
  Percentile = 'PERCENTILE',
  Sten = 'STEN'
}

export type HiringDecisionModel = {
  __typename?: 'HiringDecisionModel';
  decision: HiringDecisionValue;
  id: Scalars['Int'];
  projectJobRole: ProjectJobRoleModel;
  projectJobRoleId: Scalars['Int'];
  stageCandidate: SmStageCandidateModel;
  stageCandidateId: Scalars['Int'];
  startDate?: Maybe<Scalars['Date']>;
};

export enum HiringDecisionSkillReviewCategory {
  GeneralSkills = 'GENERAL_SKILLS',
  RightForTheFuture = 'RIGHT_FOR_THE_FUTURE',
  RightForTheRole = 'RIGHT_FOR_THE_ROLE',
  RightForUs = 'RIGHT_FOR_US'
}

export enum HiringDecisionSkillReviewEvaluation {
  Exceptional = 'EXCEPTIONAL',
  Good = 'GOOD',
  Poor = 'POOR',
  Reasonable = 'REASONABLE',
  VeryGood = 'VERY_GOOD',
  VeryPoor = 'VERY_POOR'
}

export type HiringDecisionSkillReviewModel = {
  __typename?: 'HiringDecisionSkillReviewModel';
  evaluation: HiringDecisionSkillReviewEvaluation;
  id: Scalars['Int'];
  projectJobRoleId: Scalars['Int'];
  skillId?: Maybe<Scalars['Int']>;
  stageCandidateId: Scalars['Int'];
  type: HiringDecisionSkillReviewType;
};

export enum HiringDecisionSkillReviewType {
  GeneralCategory = 'GENERAL_CATEGORY',
  Overall = 'OVERALL',
  RightForTheFutureCategory = 'RIGHT_FOR_THE_FUTURE_CATEGORY',
  RightForTheRoleCategory = 'RIGHT_FOR_THE_ROLE_CATEGORY',
  RightForUsCategory = 'RIGHT_FOR_US_CATEGORY',
  SoftSkill = 'SOFT_SKILL',
  TechnicalSkills = 'TECHNICAL_SKILLS'
}

export type HiringDecisionSkillReviewsCategoryModel = {
  __typename?: 'HiringDecisionSkillReviewsCategoryModel';
  evaluation?: Maybe<HiringDecisionSkillReviewEvaluation>;
  name: HiringDecisionSkillReviewCategory;
  skills: Array<HiringDecisionSkillReviewsSkillModel>;
};

export type HiringDecisionSkillReviewsModel = {
  __typename?: 'HiringDecisionSkillReviewsModel';
  categories: Array<HiringDecisionSkillReviewsCategoryModel>;
  evaluation?: Maybe<HiringDecisionSkillReviewEvaluation>;
  projectJobRoleId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};

export type HiringDecisionSkillReviewsSkillModel = {
  __typename?: 'HiringDecisionSkillReviewsSkillModel';
  evaluation?: Maybe<HiringDecisionSkillReviewEvaluation>;
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  type: SkillType;
};

export enum HiringDecisionValue {
  Awaiting = 'AWAITING',
  Hired = 'HIRED',
  HiredForAnotherRole = 'HIRED_FOR_ANOTHER_ROLE',
  Offered = 'OFFERED',
  TalentPool = 'TALENT_POOL',
  Unsuccessful = 'UNSUCCESSFUL'
}

export type HiringInviteFileUploadArgs = {
  endTime?: InputMaybe<Scalars['Date']>;
  includeDeadlineInEmail?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
  sendEmail: Scalars['Boolean'];
};

export type HiringInviteFileUploadLineModel = {
  __typename?: 'HiringInviteFileUploadLineModel';
  candidateEmail?: Maybe<Scalars['String']>;
  candidateFirstName?: Maybe<Scalars['String']>;
  candidateLastName?: Maybe<Scalars['String']>;
  managerEmail?: Maybe<Scalars['String']>;
};

export type HiringInviteFileUploadModel = {
  __typename?: 'HiringInviteFileUploadModel';
  apply: Scalars['Boolean'];
  creates?: Maybe<Array<HiringInviteFileUploadLineModel>>;
  deletes?: Maybe<Array<HiringInviteFileUploadLineModel>>;
  endTime?: Maybe<Scalars['Date']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<HiringInviteFileUploadLineModel>>;
  includeDeadlineInEmail?: Maybe<Scalars['Boolean']>;
  noChanges?: Maybe<Array<HiringInviteFileUploadLineModel>>;
  projectId: Scalars['Int'];
  sendEmail: Scalars['Boolean'];
  updates?: Maybe<Array<HiringInviteFileUploadLineModel>>;
};

export type HiringManager = {
  externalId: Scalars['String'];
};

export type IAssessmentDataQuestionModel = {
  questionSubId: Scalars['String'];
  timestamp: Scalars['Date'];
  traitId?: InputMaybe<Scalars['Int']>;
};

export type IcimsIntegration = {
  __typename?: 'ICIMSIntegration';
  createdAt: Scalars['Date'];
  customerId: Scalars['String'];
  eaIntegration: EaIntegration;
  eaIntegrationId: Scalars['Int'];
  hostUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type IcimsIntegrationPaginated = {
  __typename?: 'ICIMSIntegrationPaginated';
  data?: Maybe<Array<IcimsIntegration>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type IIgBehaviourResultScoreModel = {
  igEvaluation: IgBehaviourEvaluation;
};

export type IIgCustomResultScoreModel = {
  igCustomEvaluation: InterviewGuideCustomEvaluation;
};

export type IIgFinalDecisionResultScoreModel = {
  igFinalDecisionEvaluation: InterviewGuideFinalDecisionEvaluation;
};

export type IProjectRequestUserApprovalCreateOneArgs = {
  projectRequestUserId: Scalars['Int'];
};

export type IProjectRequestUserApprovalUpdateOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  approvalStatus?: InputMaybe<ProjectRequestUserApprovalStatus>;
  id: Scalars['Int'];
};

export type IProjectRequestUserCreateOneWithEmployeeCheckArgs = {
  approvalId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  projectRequestId: Scalars['Int'];
  type: ProjectRequestUserType;
};

export type ISoftSkillSelfAssessmentScoreModel = {
  proficiency: SoftSkillSelfAssessmentProficiencyLevel;
};

export type IStatisticalResultScoreModel = {
  grade: Grade;
  percentile: Scalars['Float'];
  stenScore: Scalars['Float'];
  zScore: Scalars['Float'];
};

export type ITechnicalSkillSelfAssessmentScoreModel = {
  technicalProficiency: TechnicalSkillSelfAssessmentProficiencyLevel;
};

export type ITrCustomResultScoreModel = {
  evaluation: TrCustomEvaluation;
  score: Scalars['Float'];
};

export enum IdentityPermissionRole {
  Admin = 'ADMIN',
  AdminE2ETest = 'ADMIN_E2E_TEST',
  AllModules = 'ALL_MODULES',
  ApiManager = 'API_MANAGER',
  Authenticator = 'AUTHENTICATOR',
  Authorizer = 'AUTHORIZER',
  Candidate = 'CANDIDATE',
  CompanyAdmin = 'COMPANY_ADMIN',
  CompanyCandidate = 'COMPANY_CANDIDATE',
  CompanyHiringManager = 'COMPANY_HIRING_MANAGER',
  CompanyHr = 'COMPANY_HR',
  CompanyMember = 'COMPANY_MEMBER',
  CompanyTalentTeam = 'COMPANY_TALENT_TEAM',
  HiringModule = 'HIRING_MODULE',
  Migrator = 'MIGRATOR',
  Scheduler = 'SCHEDULER',
  SzApi = 'SZ_API',
  SzSuperAdmin = 'SZ_SUPER_ADMIN',
  TrModule = 'TR_MODULE',
  WebhookHandler = 'WEBHOOK_HANDLER'
}

export type IdentityProviderModel = {
  __typename?: 'IdentityProviderModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum IgBehaviourEvaluation {
  Good = 'GOOD',
  Na = 'NA',
  Poor = 'POOR'
}

export type IgBehaviourGradeBandModel = {
  __typename?: 'IgBehaviourGradeBandModel';
  displayText: Scalars['String'];
  igEvaluation: IgBehaviourEvaluation;
  position: Scalars['Int'];
};

export type IgBehaviourResultScoreModel = {
  __typename?: 'IgBehaviourResultScoreModel';
  igEvaluation: IgBehaviourEvaluation;
};

export type IgCategory = {
  __typename?: 'IgCategory';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  internalName: Scalars['String'];
  interviewGuideCategories?: Maybe<Array<IgInterviewGuideCategory>>;
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum IgCategoryColour {
  Colour1 = 'COLOUR1',
  Colour2 = 'COLOUR2',
  Colour3 = 'COLOUR3',
  Colour4 = 'COLOUR4'
}

export type IgContent = {
  __typename?: 'IgContent';
  contentType: IgContentType;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  interviewGuide: IgInterviewGuide;
  interviewGuideId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  text: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum IgContentType {
  /** How to use the guide instructions  */
  HowToUse = 'HOW_TO_USE',
  /** Introduction on summary page */
  Introduction = 'INTRODUCTION'
}

export type IgCustomResultScoreModel = {
  __typename?: 'IgCustomResultScoreModel';
  igCustomEvaluation: InterviewGuideCustomEvaluation;
};

export enum IgFeedbackRating {
  Happy = 'HAPPY',
  Okay = 'OKAY',
  Sad = 'SAD'
}

export type IgFinalDecisionResultScoreModel = {
  __typename?: 'IgFinalDecisionResultScoreModel';
  igFinalDecisionEvaluation: InterviewGuideFinalDecisionEvaluation;
};

export type IgGeneratedFeedbackModel = {
  __typename?: 'IgGeneratedFeedbackModel';
  /** This will be the uuid sent by rabbitmq message */
  correlationId: Scalars['String'];
  data?: Maybe<InterviewGuideFeedbackModel>;
};

export type IgInterviewGuide = {
  __typename?: 'IgInterviewGuide';
  contents?: Maybe<Array<IgContent>>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  interviewGuideCategories?: Maybe<Array<IgInterviewGuideCategory>>;
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  ownerId: Scalars['Int'];
  steps?: Maybe<Array<IgStep>>;
  updatedAt: Scalars['Date'];
};

export type IgInterviewGuideCategory = {
  __typename?: 'IgInterviewGuideCategory';
  category: IgCategory;
  categoryId: Scalars['Int'];
  colour?: Maybe<IgCategoryColour>;
  id: Scalars['Int'];
  interviewGuide: IgInterviewGuide;
  interviewGuideId: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
};

export enum IgQuestionType {
  Dynamic = 'DYNAMIC',
  None = 'NONE',
  Static = 'STATIC'
}

export enum IgRenderBehaviourClassification {
  Development = 'DEVELOPMENT',
  Strength = 'STRENGTH'
}

export type IgRenderModel = {
  __typename?: 'IgRenderModel';
  author: User;
  authorId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  interviewGuideId: Scalars['Int'];
  lastStatusChange: Scalars['Date'];
  pageStatus: IgRenderPageStatus;
  potentialRecipients: Array<User>;
  recipients: Array<IgRenderRecipientModel>;
  secondPageOpened: Scalars['Boolean'];
  status: IgRenderStatus;
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum IgRenderPageStatus {
  OnPageOne = 'ON_PAGE_ONE',
  OnPageTwo = 'ON_PAGE_TWO'
}

export type IgRenderRecipientCreateOneArgs = {
  recipientId: Scalars['Int'];
  renderId: Scalars['Int'];
};

export type IgRenderRecipientModel = {
  __typename?: 'IgRenderRecipientModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  recipient: User;
  recipientId: Scalars['Int'];
  render: IgRenderModel;
  renderId: Scalars['Int'];
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum IgRenderStatus {
  InProgress = 'IN_PROGRESS',
  Opened = 'OPENED',
  Published = 'PUBLISHED'
}

export type IgSoftSkillDescriptionModel = {
  __typename?: 'IgSoftSkillDescriptionModel';
  header?: Maybe<InterviewGuideHeaderModel>;
  sections: Array<IgSoftSkillSectionModel>;
};

export type IgSoftSkillModel = {
  __typename?: 'IgSoftSkillModel';
  categoryId: Scalars['Int'];
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  score: Scalars['String'];
};

export type IgSoftSkillSectionModel = {
  __typename?: 'IgSoftSkillSectionModel';
  name: Scalars['String'];
  score: Scalars['String'];
  softSkills: Array<IgSoftSkillModel>;
};

export type IgStep = {
  __typename?: 'IgStep';
  action?: Maybe<Scalars['String']>;
  colour?: Maybe<IgCategoryColour>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  durationInSeconds?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  internalName: Scalars['String'];
  interviewGuide: IgInterviewGuide;
  interviewGuideId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  questionType: IgQuestionType;
  staticQuestionName?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Date'];
};

export type InductiveItemModel = {
  __typename?: 'InductiveItemModel';
  isCompleted: Scalars['Boolean'];
  question?: Maybe<InductiveQuestionModel>;
  remainingQuestions: Scalars['Int'];
  remainingTime: Scalars['Int'];
  /** @deprecated Please use new property `isCompleted` if you wish to know whether the assessment is completed or not! */
  testCandidateStatus: TestStatus;
  totalQuestions: Scalars['Int'];
};

export type InductiveOptionModel = {
  __typename?: 'InductiveOptionModel';
  imageUrl: Scalars['String'];
  key: Scalars['Int'];
  subId: Scalars['String'];
};

export type InductiveQuestionModel = {
  __typename?: 'InductiveQuestionModel';
  difficulty: Scalars['Float'];
  imageUrls: Array<Maybe<Scalars['String']>>;
  options: Array<InductiveOptionModel>;
  subId: Scalars['String'];
  text: Scalars['String'];
  timeLimit: Scalars['Int'];
};

export type InputScoreModel = {
  customEvaluation?: InputMaybe<Scalars['String']>;
  customScore?: InputMaybe<Scalars['Float']>;
  grade?: InputMaybe<Grade>;
  percentile?: InputMaybe<Scalars['Float']>;
  stenScore?: InputMaybe<Scalars['Float']>;
  zScore?: InputMaybe<Scalars['Float']>;
};

export type InterviewFeedbackSummaryModel = {
  __typename?: 'InterviewFeedbackSummaryModel';
  interviewer: User;
  recommendation?: Maybe<InterviewGuideFinalDecisionEvaluation>;
  render: IgRenderModel;
};

export type InterviewGuideBehaviourAssessmentEntryModel = {
  __typename?: 'InterviewGuideBehaviourAssessmentEntryModel';
  behaviour: BehaviourModel;
  id: Scalars['Int'];
  result?: Maybe<ResultModel>;
  selected: InterviewGuideSelectedModel;
  textField: InterviewGuideTextFieldModel;
};

export type InterviewGuideBehaviourAssessmentModel = {
  __typename?: 'InterviewGuideBehaviourAssessmentModel';
  assessments: Array<InterviewGuideBehaviourAssessmentEntryModel>;
  gradeBands: Array<IgBehaviourGradeBandModel>;
  selectedAssessments: Array<Scalars['Int']>;
};

export type InterviewGuideBehaviourModel = {
  __typename?: 'InterviewGuideBehaviourModel';
  behaviour: BehaviourModel;
  behaviourId: Scalars['Int'];
  classification?: Maybe<IgRenderBehaviourClassification>;
  doneById: Scalars['Int'];
  id: Scalars['Int'];
  interviewGuideId: Scalars['Int'];
};

export type InterviewGuideBodyModel = {
  __typename?: 'InterviewGuideBodyModel';
  sections: Array<InterviewGuideSectionUnion>;
};

export enum InterviewGuideCustomEvaluation {
  Exceptional = 'EXCEPTIONAL',
  Good = 'GOOD',
  Poor = 'POOR',
  Reasonable = 'REASONABLE',
  VeryGood = 'VERY_GOOD',
  VeryPoor = 'VERY_POOR'
}

export type InterviewGuideCustomGradeBandModel = {
  __typename?: 'InterviewGuideCustomGradeBandModel';
  displayText: Scalars['String'];
  igCustomEvaluation: InterviewGuideCustomEvaluation;
  position: Scalars['Int'];
};

export type InterviewGuideFeedbackModel = {
  __typename?: 'InterviewGuideFeedbackModel';
  doneById: Scalars['Int'];
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  interviewGuideId: Scalars['Int'];
};

export enum InterviewGuideFinalDecisionEvaluation {
  Hire = 'HIRE',
  Reject = 'REJECT',
  TalentPool = 'TALENT_POOL'
}

export type InterviewGuideFinalDecisionGradeBandModel = {
  __typename?: 'InterviewGuideFinalDecisionGradeBandModel';
  displayText: Scalars['String'];
  igFinalDecisionEvaluation: InterviewGuideFinalDecisionEvaluation;
  position: Scalars['Int'];
};

export type InterviewGuideFreeTextSectionTabModel = {
  __typename?: 'InterviewGuideFreeTextSectionTabModel';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  sectionId: Scalars['Int'];
  selected: InterviewGuideSelectedModel;
  /** @deprecated Please use InterviewGuideFreeTextSectionTabModel.textFields instead! */
  textField: InterviewGuideTextFieldModel;
  textFields: Array<InterviewGuideTextFieldModel>;
  title?: Maybe<Scalars['String']>;
};

export type InterviewGuideHeaderModel = {
  __typename?: 'InterviewGuideHeaderModel';
  candidateCompletionDate?: Maybe<Scalars['DateTime']>;
  companyName: Scalars['String'];
  currentDate: Scalars['DateTime'];
  doneForFirstName: Scalars['String'];
  doneForLastName: Scalars['String'];
  featuredSoftSkills: Array<Scalars['String']>;
  projectName: Scalars['String'];
  totalResultScore: StatisticalResultScoreModel;
};

export type InterviewGuideModel = {
  __typename?: 'InterviewGuideModel';
  body?: Maybe<InterviewGuideBodyModel>;
  header?: Maybe<InterviewGuideHeaderModel>;
  id: Scalars['Int'];
  projectJobRole: ProjectJobRoleModel;
  projectJobRoleId: Scalars['Int'];
  readonly: Scalars['Boolean'];
  render: IgRenderModel;
  renders: Array<IgRenderModel>;
  stageCandidate: SmStageCandidateModel;
  stageCandidateId: Scalars['Int'];
};

export type InterviewGuideModifiedQuestionModel = {
  __typename?: 'InterviewGuideModifiedQuestionModel';
  isRefined: Scalars['Boolean'];
  modifiedQuestion: Scalars['String'];
  remainingGenerationsCount: Scalars['Int'];
  tabId: Scalars['Int'];
};

export type InterviewGuideModifiedQuestionResponseModel = {
  __typename?: 'InterviewGuideModifiedQuestionResponseModel';
  /** This will be the uuid sent by rabbitmq message */
  correlationId: Scalars['String'];
  data?: Maybe<InterviewGuideModifiedQuestionModel>;
};

export type InterviewGuideNonPersistentSectionModel = {
  __typename?: 'InterviewGuideNonPersistentSectionModel';
  info?: Maybe<Scalars['String']>;
  tabs: Array<InterviewGuideNonPersistentSectionTabModel>;
  timeRequirementInMinutes?: Maybe<Scalars['Int']>;
  type: InterviewGuideSectionType;
};

export type InterviewGuideNonPersistentSectionTabModel = {
  __typename?: 'InterviewGuideNonPersistentSectionTabModel';
  description?: Maybe<Scalars['String']>;
  selected: InterviewGuideSelectedModel;
  title?: Maybe<Scalars['String']>;
};

export type InterviewGuidePageStatusModel = {
  __typename?: 'InterviewGuidePageStatusModel';
  doneById: Scalars['Int'];
  id: Scalars['Int'];
  interviewGuideId: Scalars['Int'];
  secondPageOpened: Scalars['Boolean'];
  status: IgRenderPageStatus;
};

export type InterviewGuideSectionModel = {
  __typename?: 'InterviewGuideSectionModel';
  id: Scalars['Int'];
  info?: Maybe<Scalars['String']>;
  interviewGuideId: Scalars['Int'];
  tabs: Array<InterviewGuideFreeTextSectionTabModel>;
  timeRequirementInMinutes?: Maybe<Scalars['Int']>;
  type: InterviewGuideSectionType;
};

export enum InterviewGuideSectionType {
  Intro = 'INTRO',
  Outro = 'OUTRO',
  SoftSkill = 'SOFT_SKILL',
  Technical = 'TECHNICAL'
}

export type InterviewGuideSectionUnion = InterviewGuideNonPersistentSectionModel | InterviewGuideSectionModel | InterviewGuideSoftSkillAssessmentSectionModel;

export type InterviewGuideSelectedModel = {
  __typename?: 'InterviewGuideSelectedModel';
  doneById: Scalars['Int'];
  id: Scalars['Float'];
  select: Scalars['Boolean'];
};

export type InterviewGuideSoftSkillAssessmentSectionModel = {
  __typename?: 'InterviewGuideSoftSkillAssessmentSectionModel';
  groupTitle?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  info?: Maybe<Scalars['String']>;
  interviewGuideId: Scalars['Int'];
  softSkillGroupId: Scalars['Int'];
  tabs: Array<InterviewGuideSoftSkillAssessmentSectionTabModel>;
  timeRequirementInMinutes?: Maybe<Scalars['Int']>;
  type: InterviewGuideSectionType;
};

export type InterviewGuideSoftSkillAssessmentSectionTabModel = {
  __typename?: 'InterviewGuideSoftSkillAssessmentSectionTabModel';
  behaviourAssessment?: Maybe<InterviewGuideBehaviourAssessmentModel>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isRefined: Scalars['Boolean'];
  mainQuestion: Scalars['String'];
  remainingGenerationsCount?: Maybe<Scalars['Int']>;
  sectionId: Scalars['Int'];
  selected: InterviewGuideSelectedModel;
  softSkillId: Scalars['Int'];
  subQuestions: Array<Scalars['String']>;
  /** @deprecated Please use InterviewGuideFreeTextSectionTabModel.textFields instead! */
  textField: InterviewGuideTextFieldModel;
  textFields: Array<InterviewGuideTextFieldModel>;
  title?: Maybe<Scalars['String']>;
};

export type InterviewGuideTextFieldModel = {
  __typename?: 'InterviewGuideTextFieldModel';
  doneById: Scalars['Int'];
  id: Scalars['Int'];
  prefillText?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  type: InterviewGuideTextFieldType;
};

export enum InterviewGuideTextFieldType {
  Editable = 'EDITABLE',
  Regular = 'REGULAR'
}

export type InterviewQuestionContentModel = {
  __typename?: 'InterviewQuestionContentModel';
  contextId: Scalars['Int'];
  id: Scalars['Int'];
  mainQuestion: Scalars['String'];
  subQuestions: Array<Scalars['String']>;
};

export type InterviewQuestionReasonContentModel = {
  __typename?: 'InterviewQuestionReasonContentModel';
  contextId: Scalars['Int'];
  id: Scalars['Int'];
  numLowerBound: Scalars['Int'];
  roleLevel: ContentRoleLevel;
};

export type InterviewQuestionRoleLevel = {
  __typename?: 'InterviewQuestionRoleLevel';
  classification: ContentClassification;
  feedback?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  mainQuestion?: Maybe<Scalars['String']>;
  reasonForQuestion?: Maybe<Scalars['String']>;
  roleLevel: ContentRoleLevel;
  softSkill?: Maybe<SoftSkill>;
  softSkillId: Scalars['Float'];
  subQuestions?: Maybe<Array<Scalars['String']>>;
  summary?: Maybe<Scalars['String']>;
  trait?: Maybe<Trait>;
  traitId: Scalars['Float'];
};

export type InterviewQuestionRoleLevelPaginatedModel = {
  __typename?: 'InterviewQuestionRoleLevelPaginatedModel';
  count?: Maybe<Scalars['Float']>;
  interviewQuestionContent?: Maybe<Array<InterviewQuestionRoleLevel>>;
};

export enum InvitationLastActionType {
  Accepted = 'ACCEPTED',
  Bounced = 'BOUNCED',
  Clicked = 'CLICKED',
  Deferred = 'DEFERRED',
  Delivered = 'DELIVERED',
  DispatchedToSendgrid = 'DISPATCHED_TO_SENDGRID',
  Dropped = 'DROPPED',
  NotSent = 'NOT_SENT',
  Opened = 'OPENED',
  Processed = 'PROCESSED'
}

export type InvitationModel = {
  __typename?: 'InvitationModel';
  companyId: Scalars['Float'];
  email: Scalars['String'];
  expiresAt?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  invitationToken: Scalars['String'];
  issuedAt: Scalars['Date'];
  lastActionType?: Maybe<InvitationLastActionType>;
  lastName?: Maybe<Scalars['String']>;
  status: InvitationStatusCode;
  targetId: Scalars['Float'];
  updatedAt: Scalars['Date'];
  url: Scalars['String'];
};

/** Entity that holds the invitation session */
export type InvitationSession = {
  __typename?: 'InvitationSession';
  candidates?: Maybe<Array<InvitationSessionCandidate>>;
  companyId: Scalars['String'];
  completedAt?: Maybe<Scalars['Date']>;
  completedCandidates?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  defaultProjectId?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Date']>;
  failedCandidates?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  name: Scalars['String'];
  sendEmail: Scalars['Boolean'];
  skippedCandidates?: Maybe<Scalars['Int']>;
  source: InvitationSessionSource;
  status: InvitationSessionStatus;
  totalCandidates?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Date'];
  userEmail?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** Child entity of the invitation session that holds the candidate that has to be invited */
export type InvitationSessionCandidate = {
  __typename?: 'InvitationSessionCandidate';
  applicationDate?: Maybe<Scalars['Date']>;
  applicationId?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  externalUserId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  invitationSession: InvitationSession;
  lastName: Scalars['String'];
  locale?: Maybe<SupportedLocale>;
  locationId?: Maybe<Scalars['String']>;
  locationName?: Maybe<Scalars['String']>;
  managerEmail?: Maybe<Scalars['String']>;
  managerUserId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['String']>;
  requisitionId?: Maybe<Scalars['String']>;
  requisitionName?: Maybe<Scalars['String']>;
  stageCandidateId?: Maybe<Scalars['String']>;
  status: InvitationSessionCandidateStatus;
  updatedAt: Scalars['Date'];
  userId?: Maybe<Scalars['String']>;
};

/** Status of the invitation session single candidate. */
export enum InvitationSessionCandidateStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
  Processing = 'PROCESSING',
  Skipped = 'SKIPPED'
}

export type InvitationSessionPaginated = {
  __typename?: 'InvitationSessionPaginated';
  data?: Maybe<Array<InvitationSession>>;
  pageInfo?: Maybe<Pageinfo>;
};

export enum InvitationSessionSort {
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  UploadedBy = 'UPLOADED_BY'
}

/** Source of the invitation session. */
export enum InvitationSessionSource {
  Csv = 'CSV',
  Icims = 'ICIMS'
}

/** Status of the invitation session. */
export enum InvitationSessionStatus {
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
  Partial = 'PARTIAL',
  Processing = 'PROCESSING'
}

export enum InvitationStatusCode {
  Completed = 'COMPLETED',
  Expired = 'EXPIRED',
  Pending = 'PENDING'
}

export type InvitationStatusResult = {
  __typename?: 'InvitationStatusResult';
  isCompleted: Scalars['Boolean'];
  isExpired: Scalars['Boolean'];
};

export enum InvitationType {
  Candidate = 'CANDIDATE',
  Company = 'COMPANY',
  TalentReview = 'TALENT_REVIEW'
}

export enum InviteType {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT'
}

export type InvivitationTokenProcessingResult = {
  __typename?: 'InvivitationTokenProcessingResult';
  invitationProcessingResult: Scalars['String'];
};

export enum IqFooterType {
  Hints = 'HINTS',
  IqFeedback = 'IQ_FEEDBACK',
  None = 'NONE'
}

export type JobApplication = {
  __typename?: 'JobApplication';
  application_status: Scalars['String'];
  candidate_email: Scalars['String'];
  candidate_first_name: Scalars['String'];
  candidate_id: Scalars['String'];
  candidate_last_name: Scalars['String'];
  candidate_rejected_at?: Maybe<Scalars['String']>;
};

export enum JobRoleFamily {
  AgricultureHorticultureOutdoors = 'AGRICULTURE_HORTICULTURE_OUTDOORS',
  BusinessManagementOperations = 'BUSINESS_MANAGEMENT_OPERATIONS',
  ClericalAdministrative = 'CLERICAL_ADMINISTRATIVE',
  CommunitySocialServices = 'COMMUNITY_SOCIAL_SERVICES',
  ConstructionExtractionArchitecture = 'CONSTRUCTION_EXTRACTION_ARCHITECTURE',
  CustomerClientSupport = 'CUSTOMER_CLIENT_SUPPORT',
  DesignMediaWriting = 'DESIGN_MEDIA_WRITING',
  EducationTraining = 'EDUCATION_TRAINING',
  Engineering = 'ENGINEERING',
  Finance = 'FINANCE',
  Healthcare = 'HEALTHCARE',
  HospitalityFoodTourism = 'HOSPITALITY_FOOD_TOURISM',
  HumanResources = 'HUMAN_RESOURCES',
  InformationTechnologyComputerScience = 'INFORMATION_TECHNOLOGY_COMPUTER_SCIENCE',
  LawCompliancePublicSafety = 'LAW_COMPLIANCE_PUBLIC_SAFETY',
  MaintenanceRepairInstallation = 'MAINTENANCE_REPAIR_INSTALLATION',
  ManufacturingProduction = 'MANUFACTURING_PRODUCTION',
  MarketingPublicRelations = 'MARKETING_PUBLIC_RELATIONS',
  Military = 'MILITARY',
  NotEmployed = 'NOT_EMPLOYED',
  PerformingArts = 'PERFORMING_ARTS',
  PersonalServices = 'PERSONAL_SERVICES',
  Sales = 'SALES',
  ScienceResearch = 'SCIENCE_RESEARCH',
  SocialAnalysisPlanning = 'SOCIAL_ANALYSIS_PLANNING',
  Students = 'STUDENTS',
  Transportation = 'TRANSPORTATION',
  UnclassifiedJobFamily = 'UNCLASSIFIED_JOB_FAMILY'
}

export type JobRoleModel = {
  __typename?: 'JobRoleModel';
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  family?: Maybe<JobRoleFamily>;
  id: Scalars['Int'];
  isAttachedToStage: Scalars['Boolean'];
  name: Scalars['String'];
  qualifier?: Maybe<Scalars['String']>;
  roleLevel: ContentRoleLevel;
  skillsProfiles: Array<SuccessProfile>;
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum Language {
  Afar = 'AFAR',
  Afrikaans = 'AFRIKAANS',
  Akan = 'AKAN',
  Albanian = 'ALBANIAN',
  Amharic = 'AMHARIC',
  Arabic = 'ARABIC',
  Aragonese = 'ARAGONESE',
  Armenian = 'ARMENIAN',
  Assamese = 'ASSAMESE',
  Avaric = 'AVARIC',
  Avestan = 'AVESTAN',
  Aymara = 'AYMARA',
  Azerbaijani = 'AZERBAIJANI',
  Bambara = 'BAMBARA',
  Bashkir = 'BASHKIR',
  Basque = 'BASQUE',
  Belarusian = 'BELARUSIAN',
  Bengali = 'BENGALI',
  Bislama = 'BISLAMA',
  Bosnian = 'BOSNIAN',
  Breton = 'BRETON',
  Bulgarian = 'BULGARIAN',
  Burmese = 'BURMESE',
  CatalanValencian = 'CATALAN_VALENCIAN',
  CentralKhmer = 'CENTRAL_KHMER',
  Chamorro = 'CHAMORRO',
  Chechen = 'CHECHEN',
  ChichewaChewaNyanja = 'CHICHEWA_CHEWA_NYANJA',
  Chinese = 'CHINESE',
  Chuvash = 'CHUVASH',
  Cornish = 'CORNISH',
  Corsican = 'CORSICAN',
  Cree = 'CREE',
  Croatian = 'CROATIAN',
  Czech = 'CZECH',
  Danish = 'DANISH',
  DivehiDhivehiMaldivian = 'DIVEHI_DHIVEHI_MALDIVIAN',
  DutchFlemish = 'DUTCH_FLEMISH',
  Dzongkha = 'DZONGKHA',
  English = 'ENGLISH',
  Esperanto = 'ESPERANTO',
  Estonian = 'ESTONIAN',
  Ewe = 'EWE',
  Faroese = 'FAROESE',
  Fijian = 'FIJIAN',
  Finnish = 'FINNISH',
  French = 'FRENCH',
  Fulah = 'FULAH',
  Gaelic = 'GAELIC',
  Galician = 'GALICIAN',
  Ganda = 'GANDA',
  Georgian = 'GEORGIAN',
  German = 'GERMAN',
  GreekModern = 'GREEK_MODERN',
  Guarani = 'GUARANI',
  Gujarati = 'GUJARATI',
  Haitian = 'HAITIAN',
  Hausa = 'HAUSA',
  Hebrew = 'HEBREW',
  Herero = 'HERERO',
  Hindi = 'HINDI',
  HiriMotu = 'HIRI_MOTU',
  Hungarian = 'HUNGARIAN',
  Icelandic = 'ICELANDIC',
  Ido = 'IDO',
  Igbo = 'IGBO',
  Indonesian = 'INDONESIAN',
  Interlingua = 'INTERLINGUA',
  Interlingue = 'INTERLINGUE',
  Inuktitut = 'INUKTITUT',
  Inupiaq = 'INUPIAQ',
  Irish = 'IRISH',
  Italian = 'ITALIAN',
  Japanese = 'JAPANESE',
  Javanese = 'JAVANESE',
  KalaallisutGreenlandic = 'KALAALLISUT_GREENLANDIC',
  Kannada = 'KANNADA',
  Kanuri = 'KANURI',
  Kashmiri = 'KASHMIRI',
  Kazakh = 'KAZAKH',
  KikuyuGikuyu = 'KIKUYU_GIKUYU',
  Kinyarwanda = 'KINYARWANDA',
  KirghizKyrgyz = 'KIRGHIZ_KYRGYZ',
  Komi = 'KOMI',
  Kongo = 'KONGO',
  Korean = 'KOREAN',
  KuanyamaKwanyama = 'KUANYAMA_KWANYAMA',
  Kurdish = 'KURDISH',
  Lao = 'LAO',
  Latin = 'LATIN',
  Latvian = 'LATVIAN',
  LimburganLimburgerLimburgish = 'LIMBURGAN_LIMBURGER_LIMBURGISH',
  Lingala = 'LINGALA',
  Lithuanian = 'LITHUANIAN',
  LubaKatanga = 'LUBA_KATANGA',
  LuxembourgishLetzeburgesch = 'LUXEMBOURGISH_LETZEBURGESCH',
  Macedonian = 'MACEDONIAN',
  Malagasy = 'MALAGASY',
  Malay = 'MALAY',
  Malayalam = 'MALAYALAM',
  Maltese = 'MALTESE',
  Manx = 'MANX',
  Maori = 'MAORI',
  Marathi = 'MARATHI',
  Marshallese = 'MARSHALLESE',
  Mongolian = 'MONGOLIAN',
  Nauru = 'NAURU',
  NavajoNavaho = 'NAVAJO_NAVAHO',
  Ndonga = 'NDONGA',
  Nepali = 'NEPALI',
  NorthernSami = 'NORTHERN_SAMI',
  NorthNdebele = 'NORTH_NDEBELE',
  Norwegian = 'NORWEGIAN',
  NorwegianBokmal = 'NORWEGIAN_BOKMAL',
  NorwegianNynorsk = 'NORWEGIAN_NYNORSK',
  Occitan = 'OCCITAN',
  Ojibwa = 'OJIBWA',
  OldSlavonic = 'OLD_SLAVONIC',
  Oriya = 'ORIYA',
  Oromo = 'OROMO',
  OssetianOssetic = 'OSSETIAN_OSSETIC',
  Pali = 'PALI',
  PashtoPushto = 'PASHTO_PUSHTO',
  Persian = 'PERSIAN',
  Polish = 'POLISH',
  Portuguese = 'PORTUGUESE',
  PunjabiPanjabi = 'PUNJABI_PANJABI',
  Quechua = 'QUECHUA',
  RomanianMoldavian = 'ROMANIAN_MOLDAVIAN',
  Romansh = 'ROMANSH',
  Rundi = 'RUNDI',
  Russian = 'RUSSIAN',
  Samoan = 'SAMOAN',
  Sango = 'SANGO',
  Sanskrit = 'SANSKRIT',
  Sardinian = 'SARDINIAN',
  Serbian = 'SERBIAN',
  Shona = 'SHONA',
  SichuanYiNuosu = 'SICHUAN_YI_NUOSU',
  Sindhi = 'SINDHI',
  SinhalaSinhalese = 'SINHALA_SINHALESE',
  Slovak = 'SLOVAK',
  Slovenian = 'SLOVENIAN',
  Somali = 'SOMALI',
  SouthernSotho = 'SOUTHERN_SOTHO',
  SouthNdebele = 'SOUTH_NDEBELE',
  SpanishCastilian = 'SPANISH_CASTILIAN',
  Sundanese = 'SUNDANESE',
  Swahili = 'SWAHILI',
  Swati = 'SWATI',
  Swedish = 'SWEDISH',
  Tagalog = 'TAGALOG',
  Tahitian = 'TAHITIAN',
  Tajik = 'TAJIK',
  Tamil = 'TAMIL',
  Tatar = 'TATAR',
  Telugu = 'TELUGU',
  Thai = 'THAI',
  Tibetan = 'TIBETAN',
  Tigrinya = 'TIGRINYA',
  Tonga = 'TONGA',
  Tsonga = 'TSONGA',
  Tswana = 'TSWANA',
  Turkish = 'TURKISH',
  Turkmen = 'TURKMEN',
  Twi = 'TWI',
  Uighur = 'UIGHUR',
  Ukrainian = 'UKRAINIAN',
  Urdu = 'URDU',
  Uzbek = 'UZBEK',
  Venda = 'VENDA',
  Vietnamese = 'VIETNAMESE',
  Volapuk = 'VOLAPUK',
  Walloon = 'WALLOON',
  Welsh = 'WELSH',
  WesternFrisian = 'WESTERN_FRISIAN',
  Wolof = 'WOLOF',
  Xhosa = 'XHOSA',
  Yiddish = 'YIDDISH',
  Yoruba = 'YORUBA',
  ZhuangChuang = 'ZHUANG_CHUANG',
  Zulu = 'ZULU'
}

export type LocationLinkCreateArgs = {
  companyId: Scalars['Int'];
  locationName: Scalars['String'];
  projectId: Scalars['Int'];
  userGroupId: Scalars['Int'];
};

export type LocationLinkCreateOneResult = {
  __typename?: 'LocationLinkCreateOneResult';
  error?: Maybe<Scalars['String']>;
  link?: Maybe<LocationLinkModel>;
  status: Scalars['String'];
};

export type LocationLinkDeleteManyArgs = {
  locationId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  userGroupId?: InputMaybe<Scalars['Int']>;
};

export type LocationLinkFindManyArgs = {
  locationId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  userGroupId?: InputMaybe<Scalars['Int']>;
};

export type LocationLinkModel = {
  __typename?: 'LocationLinkModel';
  id: Scalars['Int'];
  locationId: Scalars['Int'];
  projectId: Scalars['Int'];
  userGroupId: Scalars['Int'];
};

export type LongTextField = Field & {
  __typename?: 'LongTextField';
  fieldInstanceType: FieldInstanceType;
  fieldOwnerId?: Maybe<Scalars['Int']>;
  fieldOwnerName?: Maybe<Scalars['String']>;
  fieldOwnerType?: Maybe<FieldOwnerType>;
  fieldType: FieldType;
  formManagerType: FormManagerType;
  hint?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  settings?: Maybe<FieldSettingsModel>;
  textBoxHeight?: Maybe<Scalars['Int']>;
  txtData?: Maybe<Scalars['String']>;
};

export enum MeasurementConstructType {
  Behavior = 'BEHAVIOR',
  CalculatedOnSsSummary = 'CALCULATED_ON_SS_SUMMARY',
  Cognitive = 'COGNITIVE',
  Values = 'VALUES'
}

export type MfaResult = {
  __typename?: 'MfaResult';
  mfaAccessToken: Scalars['String'];
};

export type ModifiedQuestionResponseModel = {
  __typename?: 'ModifiedQuestionResponseModel';
  id: Scalars['String'];
  isOk: Scalars['Boolean'];
};

export type MotivationResultScoreModel = {
  __typename?: 'MotivationResultScoreModel';
  isFlagged: Scalars['Boolean'];
  motivationScore: Scalars['Float'];
};

export type MultiSelectField = Field & {
  __typename?: 'MultiSelectField';
  fieldInstanceType: FieldInstanceType;
  fieldOwnerId?: Maybe<Scalars['Int']>;
  fieldOwnerName?: Maybe<Scalars['String']>;
  fieldOwnerType?: Maybe<FieldOwnerType>;
  fieldType: FieldType;
  formManagerType: FormManagerType;
  hint?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  isFreetextField?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  selectOptions?: Maybe<Scalars['String']>;
  settings?: Maybe<FieldSettingsModel>;
  txtData?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  AnswerCreate?: Maybe<Answer>;
  AnswerDelete?: Maybe<DeleteResult>;
  AnswerUpdate?: Maybe<Answer>;
  ApiKeyCompanyDisable: Scalars['Boolean'];
  ApiKeyCompanyGenerate: AmApiKeyModel;
  ApiKeyDisable: Scalars['Boolean'];
  ApiKeyDisableUser: Scalars['Boolean'];
  ApiKeyUserGenerate: AmApiKeyModel;
  ApiUserCreateOne: ApiUserModel;
  ApiUserResetClientSecret: ApiUserModel;
  ApiUserUpdateOne: ApiUserModel;
  AssessmentDataCreateOne: AssessmentDataModel;
  AssessmentDataSoftDeleteOne: DeleteResult;
  AssessmentDataUpdateOne: AssessmentDataModel;
  BaseMethodTestCreateMany: Array<BaseMethodTest>;
  BaseMethodTestCreateOne: BaseMethodTest;
  BaseMethodTestDelayedEvent: DeleteResult;
  BaseMethodTestDeleteMany: DeleteResult;
  BaseMethodTestDeleteOne: DeleteResult;
  BaseMethodTestSoftDeleteMany: DeleteResult;
  BaseMethodTestSoftDeleteOne: DeleteResult;
  BaseMethodTestUpdateMany: Array<BaseMethodTest>;
  BaseMethodTestUpdateOne: BaseMethodTest;
  BehaviourContentCreateOne: BehaviourContentModel;
  BehaviourContentDeleteOne: DeleteResult;
  BehaviourContentUpdateOne: BehaviourContentModel;
  BehaviourCreateOne: BehaviourModel;
  BehaviourDeleteOne: DeleteResult;
  BehaviourFromCsvRowsCreateMany: Scalars['String'];
  BehaviourUpdateOne: BehaviourModel;
  CalibrationCandidateCreateOne: CalibrationCandidateModel;
  CalibrationCandidateDeleteOne: DeleteResult;
  CalibrationCandidateRestoreOne: CalibrationCandidateModel;
  CalibrationCandidateRollBackOne: CalibrationCandidateModel;
  CalibrationCandidateSoftDeleteOne: DeleteResult;
  CalibrationCandidateUpdateOne: CalibrationCandidateModel;
  CalibrationFileUpload: CalibrationFileUploadModel;
  ClientAuthenticate: AuthCodeResult;
  ClientMFAAuthenticate: AuthCodeResultCookie;
  ClientRegisterAccount: AuthCodeResult;
  CmCandidateResultsCSVExport: CmCandidateResultsExportCsvModel;
  CompanyAllowedEmailDomainsUpdate: Array<Scalars['String']>;
  CompanyCreateUser: User;
  CompanyEmployeeCreate: CompanyEmployeeModel;
  CompanyEmployeeDeleteOne: DeleteResult;
  CompanyEmployeeFileUpload: CompanyEmployeeFileUploadModel;
  CompanyEmployeeSyncWithUserCompanyInfo?: Maybe<Array<CompanyEmployeeModel>>;
  CompanyEmployeeUpdate: CompanyEmployeeModel;
  CompanyResendInvite: Scalars['Boolean'];
  CompanyRoleUpsert: CompanyRole;
  CompanySignatureCreateOne: CompanySignatureModel;
  CompanySignatureDeleteOne: DeleteResult;
  CompanySignatureSoftDeleteOne: DeleteResult;
  CompanySignatureUpdateOne: CompanySignatureModel;
  CreateAssessmentBrowserSwitchCandidateTrackingEvent: Scalars['Boolean'];
  CreateCompanyUser: User;
  DeiStageActionArchive: DeiStageActionModel;
  DeiStageActionCandidateReopen: DeiStageActionCandidateModel;
  DeiStageActionCandidateSubmitResponses: DeiStageActionCandidateModel;
  DeiStageActionTemplateUpsert: DeiStageActionTemplateModel;
  DeiStageActionUnarchive: DeiStageActionModel;
  DeiStageActionUpsert: DeiStageActionModel;
  E2eCookie: AuthenticationResultE2eCookie;
  /** Mark the candidate status as in progress and return the external assessment stage action candidate. */
  EAStageActionCandidateMarkInProgress: EaStageActionCandidateModel;
  /** Reset external assessment stage action candidate and return it */
  EAStageActionCandidateResetOne: EaStageActionCandidateModel;
  /** Update external assessment stage action candidate and return it */
  EAStageActionCandidateUpdateOne: EaStageActionCandidateModel;
  /** Create a new stage action for an external assessment and a stage. */
  EAStageActionCreateOne: EaStageActionModel;
  /** Mark the stage action as deleted, effectively removing it from the stage. */
  EAStageActionSoftDeleteOne: DeleteResult;
  EmailTemplateCreateOne: EmailTemplateModel;
  /** @deprecated Use ProjectAddLocale or editProject */
  EntityLanguageCreateOne: EntityLanguageModel;
  /** @deprecated Use ProjectRemoveLocale or editProject */
  EntityLanguageDeleteOne: DeleteResult;
  ExchangeAuthCode: AuthenticationResult;
  /** Create a new external assessment in the bank. Also create the platform assessments, skills and company platform links */
  ExternalAssessmentCreateOne: ExternalAssessmentModel;
  /** Update existing assessment in the bank. Update the platform assessments and skills as well */
  ExternalAssessmentUpdateOne: ExternalAssessmentModel;
  FieldCreate?: Maybe<Field>;
  FieldDelete?: Maybe<DeleteResult>;
  FieldUpdate?: Maybe<Field>;
  FormAddField?: Maybe<Form>;
  FormBuilderCreate?: Maybe<FormBuilder>;
  FormBuilderUpdate?: Maybe<FormBuilder>;
  FormCandidateCreateOne: FormCandidateModel;
  FormCandidateDeleteOne: DeleteResult;
  FormCandidateResetOne: FormCandidateModel;
  FormCandidateUpdateOne: FormCandidateModel;
  FormCreate?: Maybe<Form>;
  FormDelete?: Maybe<DeleteResult>;
  FormFieldRemove?: Maybe<Form>;
  FormFieldUpdate?: Maybe<Form>;
  FormUpdate?: Maybe<Form>;
  /** Mutation to create Greenhouse External ATS Integrate */
  GreenhouseExternalATSCreateOne: GheaIntegrationModel;
  /** Mutation to delete Greenhouse External ATS Integrate */
  GreenhouseExternalATSDeleteOne: DeleteResult;
  HiringDecisionAddStartDate: HiringDecisionModel;
  HiringDecisionSkillReviewSubmit?: Maybe<HiringDecisionSkillReviewModel>;
  HiringDecisionSubmit: Array<HiringDecisionModel>;
  HiringInviteFileUpload: HiringInviteFileUploadModel;
  IdentityProviderCreateOne: IdentityProviderModel;
  IdentityProviderDeleteOne: DeleteResult;
  IdentityProviderRestoreOne?: Maybe<IdentityProviderModel>;
  IdentityProviderSoftDeleteOne: DeleteResult;
  IdentityProviderUpdateOne: IdentityProviderModel;
  IgBehaviourAssessmentEntryRemoveOne: DeleteResult;
  IgCategoryCreate?: Maybe<IgCategory>;
  IgCategoryDeleteOne?: Maybe<DeleteResult>;
  IgCategoryUpdate?: Maybe<IgCategory>;
  IgInterviewGuideCategoryCreate?: Maybe<IgInterviewGuideCategory>;
  IgInterviewGuideCategoryDeleteOne?: Maybe<DeleteResult>;
  IgInterviewGuideCategoryUpdate?: Maybe<IgInterviewGuideCategory>;
  IgInterviewGuideCreate?: Maybe<IgInterviewGuide>;
  IgInterviewGuideDeleteOne?: Maybe<DeleteResult>;
  IgInterviewGuideUpdate?: Maybe<IgInterviewGuide>;
  IgRenderUpdateOne?: Maybe<IgRenderModel>;
  IgStepCreate?: Maybe<IgStep>;
  IgStepDeleteOne?: Maybe<DeleteResult>;
  IgStepUpdate?: Maybe<IgStep>;
  IgTabRenderRefineModifiedQuestionRequest: ModifiedQuestionResponseModel;
  IgTabRenderResetModifiedQuestion: InterviewGuideModifiedQuestionModel;
  InterviewGuideBehaviourUpdateOne: InterviewGuideBehaviourModel;
  InterviewGuideDeleteOne: DeleteResult;
  InterviewGuideFeedbackRating: Scalars['Boolean'];
  InterviewGuideFeedbackRegenerateRequest: GenerateCandidateFeedbackResponseModel;
  InterviewGuideFeedbackSubmitOne: InterviewGuideFeedbackModel;
  InterviewGuideFeedbackUpdateOne: InterviewGuideFeedbackModel;
  InterviewGuideGenerateOne: InterviewGuideModel;
  InterviewGuideOpenSecondPage: Scalars['Boolean'];
  InterviewGuideRecipientCreateManyAndSendEmails: Array<IgRenderRecipientModel>;
  InterviewGuideRecipientDeleteOne: DeleteResult;
  InterviewGuideSectionMarkTabAsSelected: InterviewGuideSectionUnion;
  InterviewGuideSelectedUpdateOne: InterviewGuideSelectedModel;
  InterviewGuideSoftDeleteOne: DeleteResult;
  InterviewGuideTextFieldUpdateOne: InterviewGuideTextFieldModel;
  InterviewQuestionContentCreateOne: InterviewQuestionContentModel;
  InterviewQuestionContentDeleteOne: DeleteResult;
  InterviewQuestionContentUpdateOne: InterviewQuestionContentModel;
  InterviewQuestionReasonContentCreateOne: InterviewQuestionReasonContentModel;
  InterviewQuestionReasonContentDeleteOne: DeleteResult;
  InterviewQuestionReasonContentUpdateOne: InterviewQuestionReasonContentModel;
  InterviewQuestionRoleLevelContentUpdate?: Maybe<InterviewQuestionRoleLevel>;
  InvitationCreate: InvitationModel;
  InvitationDeleteMany: DeleteResult;
  InvitationDeleteOne: DeleteResult;
  InvitationUpdate: InvitationModel;
  JobRoleCreateOne: JobRoleModel;
  JobRoleDeleteOne: DeleteResult;
  JobRoleSoftDeleteOne: DeleteResult;
  JobRoleUpdateOne: JobRoleModel;
  LocationLinkCreateMany: Array<LocationLinkCreateOneResult>;
  LocationLinkCreateOne: LocationLinkCreateOneResult;
  LocationLinkDeleteMany: Array<LocationLinkModel>;
  ProjectCollaboratorsCreateMany: Array<ProjectCollaboratorRole>;
  ProjectCollaboratorsDeleteMany: Array<ProjectCollaboratorDeleteManyResult>;
  ProjectCollaboratorsUpdateRole: ProjectCollaboratorRole;
  ProjectDelete: DeleteResult;
  ProjectJobRoleCreateOne: ProjectJobRoleModel;
  ProjectJobRoleDeleteOne: DeleteResult;
  ProjectJobRoleSoftDeleteOne: DeleteResult;
  ProjectRequestCreateOne: ProjectRequestModel;
  ProjectRequestDeleteOne: DeleteResult;
  ProjectRequestFileUpload: Scalars['String'];
  ProjectRequestFileUploadDeleteOne: DeleteResult;
  ProjectRequestFileUploadSoftDeleteOne: DeleteResult;
  ProjectRequestLinkProject: ProjectRequestModel;
  ProjectRequestRequestJobSpecification: Scalars['Boolean'];
  ProjectRequestRequestManagerSurvey: Scalars['Boolean'];
  ProjectRequestRestoreOne?: Maybe<ProjectRequestModel>;
  ProjectRequestSoftDeleteOne: DeleteResult;
  ProjectRequestUpdateManagerSurvey: ProjectRequestModel;
  ProjectRequestUpdateOne: ProjectRequestModel;
  ProjectRequestUserApprovalCreateMany: Array<ProjectRequestUserApprovalModel>;
  ProjectRequestUserApprovalCreateOne: ProjectRequestUserApprovalModel;
  ProjectRequestUserApprovalDeleteOne: DeleteResult;
  ProjectRequestUserApprovalSoftDeleteOne: DeleteResult;
  ProjectRequestUserApprovalUpdateMany: Array<ProjectRequestUserApprovalModel>;
  ProjectRequestUserApprovalUpdateOne: ProjectRequestUserApprovalModel;
  ProjectRequestUserCreateMany: Array<ProjectRequestUserModel>;
  ProjectRequestUserCreateOne: ProjectRequestUserModel;
  ProjectRequestUserDeleteOne: DeleteResult;
  ProjectRequestUserSoftDeleteOne: DeleteResult;
  ProjectRequestUserUpdateOne: ProjectRequestUserModel;
  ProjectSetCompanySignature: Project;
  ProjectUserDeleteOne: ProjectUser;
  ProjectUserUpdateOne: ProjectUser;
  QCalcGetNextQuestion: QCalcResponse;
  ReportAssignmentCreate?: Maybe<ReportAssignment>;
  ReportAssignmentDelete?: Maybe<DeleteResult>;
  ReportAssignmentUpdate?: Maybe<ReportAssignment>;
  ReportConfigCreate?: Maybe<ReportConfig>;
  ReportConfigDelete?: Maybe<DeleteResult>;
  ReportCopy?: Maybe<Report>;
  ReportCreate?: Maybe<Report>;
  ReportDelete?: Maybe<DeleteResult>;
  /** @deprecated Please use either StageResultsReportRenderCreate, or SuccessProfileReportRenderCreate */
  ReportRenderCreate?: Maybe<ReportRender>;
  ReportUpdate?: Maybe<Report>;
  ReportUpdateIsArchived?: Maybe<Report>;
  RequeueRejectedEvent?: Maybe<DlxRejectedEvent>;
  RespondantFormDeleteByRespondantAndAssociatedId?: Maybe<DeleteResult>;
  RespondantFormDeleteByRespondantId?: Maybe<DeleteResult>;
  RespondantFormUpdate?: Maybe<RespondantForm>;
  ResultCreateManySoftSkillsProfile: Array<ResultModel>;
  ResultCreateManySoftSkillsProfileFromSc: Array<ResultModel>;
  ResultCreateManyStatistical: Array<ResultModel>;
  ResultCreateManyTechnicalSkillsProfileFromSc: Array<ResultModel>;
  ResultCreateManyTrCustom: Array<ResultModel>;
  ResultCreateOneBehaviour: ResultModel;
  ResultCreateOneIgCustom: ResultModel;
  ResultCreateOneIgFinalDecision: ResultModel;
  ResultCreateOnePspSoftSkill: ResultModel;
  ResultCreateOnePspTechnicalSkill: ResultModel;
  ResultCreateOneSoftSkillsProfile: Array<ResultModel>;
  ResultCreateOneSoftSkillsProfileFromSc: Array<ResultModel>;
  ResultCreateOneStatistical: ResultModel;
  ResultCreateOneTechnicalSkillsProfileFromSc: Array<ResultModel>;
  ResultCreateOneTrCustom: ResultModel;
  ResultDeleteMany: DeleteResult;
  ResultDeleteOne: DeleteResult;
  ResultRestoreOne: ResultModel;
  ResultSoftDeleteMany: DeleteResult;
  ResultSoftDeleteOne: DeleteResult;
  ResultUpdateOne: ResultModel;
  ResultUpdateOneBehaviour: ResultModel;
  RssAssessmentCandidateReopenAllSubmittedForStageCandidate: Array<RssAssessmentCandidateModel>;
  RssAssessmentCandidateSubmitOne: RssAssessmentCandidateModel;
  RssAssessmentCreateMany: Array<RssAssessmentModel>;
  RssAssessmentDeleteMany: DeleteResult;
  SamlAuthInfoCreateOne: SamlAuthInfoModel;
  SamlAuthInfoDeleteOne: DeleteResult;
  SamlAuthInfoRestoreOne?: Maybe<SamlAuthInfoModel>;
  SamlAuthInfoSoftDeleteOne: DeleteResult;
  SamlAuthInfoUpdateOne: SamlAuthInfoModel;
  ScheduleROICsvFileExport: FileExportScheduledTask;
  ScheduleStageCandidateResultsCsvFileExport: FileExportScheduledTask;
  SkillCategoryCreateMany: Array<SkillCategory>;
  SkillCategoryCreateOne: SkillCategory;
  SkillCategoryDeleteMany: DeleteResult;
  SkillCategoryDeleteOne: DeleteResult;
  SkillCategoryRestoreOne?: Maybe<SkillCategory>;
  SkillCategorySoftDeleteMany: DeleteResult;
  SkillCategorySoftDeleteOne: DeleteResult;
  SkillCategoryUpdateMany: Array<SkillCategory>;
  SkillCategoryUpdateOne: SkillCategory;
  SmInviteHiring: Array<SmStageCandidateModel>;
  SmInviteTR: Array<SmStageCandidateModel>;
  SoftSkillAddTrait?: Maybe<SoftSkill>;
  SoftSkillBulkUpdateTraits?: Maybe<SoftSkill>;
  SoftSkillCreate?: Maybe<SoftSkill>;
  SoftSkillDelete?: Maybe<DeleteResult>;
  SoftSkillDeleteTrait?: Maybe<SoftSkill>;
  SoftSkillProjectModuleTypeCreateOne: SoftSkillProjectModuleTypeModel;
  SoftSkillProjectModuleTypeDeleteOne: DeleteResult;
  SoftSkillProjectModuleTypeFindOne: SoftSkillProjectModuleTypeModel;
  SoftSkillRemoveIcon: SoftSkill;
  SoftSkillRoleLevelContentUpdate?: Maybe<SoftSkillRoleLevelContent>;
  SoftSkillSuccessProfileContentCreate?: Maybe<SoftSkillSuccessProfileContent>;
  SoftSkillSuccessProfileContentDelete?: Maybe<DeleteResult>;
  SoftSkillSuccessProfileContentUpdate?: Maybe<SoftSkillSuccessProfileContent>;
  SoftSkillTypeSuccessProfileContentCreate?: Maybe<SoftSkillTypeSuccessProfileContent>;
  SoftSkillTypeSuccessProfileContentDelete?: Maybe<DeleteResult>;
  SoftSkillTypeSuccessProfileContentUpdate?: Maybe<SoftSkillTypeSuccessProfileContent>;
  SoftSkillUpdate?: Maybe<SoftSkill>;
  SoftSkillUpdateIsArchived?: Maybe<SoftSkill>;
  SoftSkillUpdateTrait?: Maybe<SoftSkill>;
  SoftSkillUploadIcon: SoftSkill;
  SrEmailDispatchEmails: Scalars['Boolean'];
  StageCandidateAcceptIndirectInvitation: SmStageCandidateModel;
  StageCandidateCopyTestResults: Scalars['Boolean'];
  StageCandidateCreate: SmStageCandidateModel;
  StageCandidateDeleteMany: DeleteResult;
  StageCandidateDeleteOne: DeleteResult;
  StageCandidateDeleteTalentReviewRequest: Scalars['Boolean'];
  StageCandidatePledgeAnticheating: Scalars['Boolean'];
  StageCandidateReprocessOne: Scalars['Boolean'];
  StageCandidateRequestTalentReview: Scalars['Boolean'];
  StageCandidateResetOne: Scalars['Boolean'];
  StageCandidateSubmitAdjustmentQuestionnaire: SmStageCandidateModel;
  StageCandidateUpdate: SmStageCandidateModel;
  StageCreate: SmStageModel;
  StageCreateCsvReport: StageGetCsvReportModel;
  StageDeleteMany: DeleteResult;
  StageDeleteOne: DeleteResult;
  StageReorderMany: Array<SmStageModel>;
  StageResultsReportRenderCreate?: Maybe<ReportRender>;
  StageUpdate: SmStageModel;
  /** @deprecated use SuccessProfileCreateOne instead */
  SuccessProfileCreate: SuccessProfile;
  SuccessProfileCreateOne: SuccessProfile;
  SuccessProfileDelete: DeleteResult;
  SuccessProfileDeleteMany?: Maybe<DeleteResult>;
  SuccessProfileExplanationDelete: DeleteResult;
  SuccessProfileExplanationSetStatusComplete?: Maybe<SuccessProfileExplanation>;
  SuccessProfileExplantionCreate: SuccessProfileExplanation;
  SuccessProfileReportRenderCreate?: Maybe<ReportRender>;
  SuccessProfileSoftSkillExplantionUpdate: SuccessProfileSoftSkillExplanation;
  SuccessProfileSoftSkillTypeExplantionUpdate: SuccessProfileSoftSkillTypeExplanation;
  SuccessProfileSoftSkillsOverwrite: SuccessProfile;
  SuccessProfileTechnicalSkillCreateMany: Array<SuccessProfileTechnicalSkill>;
  SuccessProfileTechnicalSkillCreateOne: SuccessProfileTechnicalSkill;
  SuccessProfileTechnicalSkillDeleteMany: DeleteResult;
  SuccessProfileTechnicalSkillDeleteOne: DeleteResult;
  SuccessProfileTechnicalSkillOverwriteMany: Array<SuccessProfileTechnicalSkill>;
  SuccessProfileTechnicalSkillSoftDeleteMany: DeleteResult;
  SuccessProfileTechnicalSkillSoftDeleteOne: DeleteResult;
  SuccessProfileTechnicalSkillUpdateMany: Array<SuccessProfileTechnicalSkill>;
  SuccessProfileTechnicalSkillUpdateOne: SuccessProfileTechnicalSkill;
  SuccessProfileTechnicalSkillsOverwrite: SuccessProfile;
  /** @deprecated use SuccessProfileUpdateOne instead */
  SuccessProfileUpdate: SuccessProfile;
  SuccessProfileUpdateOne: SuccessProfile;
  SummaryNotesUpsertOne: SummaryNotesModel;
  SzSkillsMergeCreateMany: Array<SzSkillsMerge>;
  SzSkillsMergeCreateOne: SzSkillsMerge;
  SzSkillsMergeDeleteMany: DeleteResult;
  SzSkillsMergeDeleteOne: DeleteResult;
  SzSkillsMergeSoftDeleteMany: DeleteResult;
  SzSkillsMergeSoftDeleteOne: DeleteResult;
  SzSkillsMergeUpdateMany: Array<SzSkillsMerge>;
  SzSkillsMergeUpdateOne: SzSkillsMerge;
  TalentReviewFileUpload: TalentReviewFileUploadModel;
  TechnicalSkillCreateMany: Array<TechnicalSkill>;
  TechnicalSkillCreateOne: TechnicalSkill;
  TechnicalSkillDeleteMany: DeleteResult;
  TechnicalSkillDeleteOne: DeleteResult;
  TechnicalSkillIconUpload: Scalars['String'];
  TechnicalSkillRestoreOne?: Maybe<TechnicalSkill>;
  TechnicalSkillSoftDeleteMany: DeleteResult;
  TechnicalSkillSoftDeleteOne: DeleteResult;
  TechnicalSkillUpdateMany: Array<TechnicalSkill>;
  TechnicalSkillUpdateOne: TechnicalSkill;
  TestCandidateRemoveOne: Scalars['Boolean'];
  TestCandidateReprocessScores: Scalars['Boolean'];
  TestCandidateResetOne: Scalars['Boolean'];
  TestCandidateStartSzPracticeTest: StartSzTestModel;
  TestCandidateStartSzTest: TmTestCandidateModel;
  TestCandidateStartTpTest: StartTpTestModel;
  TestCandidateUpdateOne: TmTestCandidateModel;
  TmTestCreateOne: TmTestModel;
  TmTestUpdateOne: TmTestModel;
  TraitCreate?: Maybe<Trait>;
  TraitDelete?: Maybe<DeleteResult>;
  TraitRoleLevelContentUpdate?: Maybe<TraitRoleLevelContent>;
  TraitSoftSkillContextCreateOne: TraitSoftSkillContextModel;
  TraitSoftSkillContextDeleteMany: DeleteResult;
  TraitSoftSkillContextDeleteOne: DeleteResult;
  TraitSoftSkillContextFileUpload: TraitSoftSkillContextFileUploadModel;
  TraitTestTypeReplaceMany?: Maybe<Array<TraitTestTypeModel>>;
  TraitUpdate?: Maybe<Trait>;
  TraitUpdateIsArchived?: Maybe<Trait>;
  UserCompanyInfoCreate: UserCompanyInfo;
  UserCompanyInfoDeleteOne: DeleteResult;
  UserCompanyInfoUpdate: UserCompanyInfo;
  UserCreate: User;
  UserDelete: DeleteResult;
  UserDeleteByEmail: DeleteResult;
  UserGroupCreateOne: UserGroupModel;
  UserGroupDeleteOne: Scalars['Boolean'];
  UserGroupMembersCreateMany: Scalars['Boolean'];
  UserGroupMembersDeleteMany: Scalars['Boolean'];
  UserGroupUpdateOne: UserGroupModel;
  UserRoleCreate?: Maybe<UserRole>;
  UserRoleDelete: DeleteResult;
  UserRoleUpdate: UserRole;
  UserSettingsCreate: UserSettings;
  UserSettingsDeleteOne: DeleteResult;
  UserSettingsUpdate: UserSettings;
  UserUpdate: User;
  WidgetConfigCreate?: Maybe<WidgetConfig>;
  WidgetConfigDelete?: Maybe<DeleteResult>;
  WidgetConfigUpdate?: Maybe<WidgetConfig>;
  WidgetConfigUpdateMany?: Maybe<Array<WidgetConfig>>;
  WidgetCreate?: Maybe<Widget>;
  WidgetDelete?: Maybe<DeleteResult>;
  WidgetDeleteScreenShot: Widget;
  WidgetOptionCreate?: Maybe<WidgetOption>;
  WidgetOptionDelete?: Maybe<DeleteResult>;
  WidgetOptionUpdate?: Maybe<WidgetOption>;
  WidgetOptionUpdateIsArchived?: Maybe<WidgetOption>;
  WidgetOptionUploadBrandedSoftSkillImage: BlobModel;
  WidgetOptionUploadCoverPageImage: BlobModel;
  WidgetUpdate?: Maybe<Widget>;
  WidgetUpdateIsArchived?: Maybe<Widget>;
  WidgetUploadScreenShot: Widget;
  authenticate: AuthenticationResult;
  authenticateApi: AuthenticationResult;
  createCompany: Company;
  createProject: Project;
  createProjectFile: ProjectFile;
  deauthenticate: Scalars['Boolean'];
  deleteCompany: Scalars['Boolean'];
  editProject: Project;
  editProjectArchiving: Project;
  editProjectCandidateAssignees: Array<StageCandidateAssigneeModel>;
  editProjectCandidateManagers: Array<ProjectCandidateManagerModel>;
  editProjectCandidateReportState: Project;
  editProjectState: Project;
  icimsIntegrationsCreateOne: IcimsIntegration;
  icimsIntegrationsDeleteOne: IcimsIntegration;
  icimsIntegrationsUpdateOne: IcimsIntegration;
  mfaAccessToken: MfaResult;
  mfaAuthenticate: AuthenticationResultCookie;
  processInvitation: InvivitationTokenProcessingResult;
  registerAccount: AuthenticationResult;
  requestMfaCode: Scalars['Boolean'];
  requestPasswordRecovery: Scalars['Boolean'];
  requestTokenRefresh: AuthenticationResult;
  retriveTpManualCandidates: Scalars['Boolean'];
  setIndirectInviteSSO: Scalars['Boolean'];
  setProjectCobranding: Scalars['Boolean'];
  stackOneIntegrationsCreateOne: StackOneIntegration;
  stackOneIntegrationsDeleteOne: StackOneIntegration;
  stackOneIntegrationsUpdateOne: StackOneIntegration;
  stackOneLinkProject: Array<StackOneJobProject>;
  stackOneStartJobsExport: StackOneJobsExport;
  updateCompany: Company;
  updateIdentityPassword: Scalars['Boolean'];
  uploadCompanyLogo: Scalars['String'];
  uploadFile: UploadResult;
};


export type MutationAnswerCreateArgs = {
  associatedId: Scalars['Int'];
  fieldId: Scalars['Int'];
  formId: Scalars['Int'];
  respondantId: Scalars['Int'];
  response: Scalars['String'];
};


export type MutationAnswerDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationAnswerUpdateArgs = {
  associatedId?: InputMaybe<Scalars['Int']>;
  fieldId?: InputMaybe<Scalars['Int']>;
  formId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  respondantId?: InputMaybe<Scalars['Int']>;
  response?: InputMaybe<Scalars['String']>;
};


export type MutationApiKeyCompanyDisableArgs = {
  companyId: Scalars['Int'];
  permission?: InputMaybe<IdentityPermissionRole>;
};


export type MutationApiKeyCompanyGenerateArgs = {
  companyId: Scalars['Int'];
  permission: IdentityPermissionRole;
};


export type MutationApiKeyDisableArgs = {
  key: Scalars['String'];
};


export type MutationApiKeyDisableUserArgs = {
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  userSubId?: InputMaybe<Scalars['String']>;
};


export type MutationApiKeyUserGenerateArgs = {
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  userSubId?: InputMaybe<Scalars['String']>;
};


export type MutationApiUserCreateOneArgs = {
  companyId: Scalars['Int'];
  hasClientSecret?: InputMaybe<Scalars['Boolean']>;
  ownerId: Scalars['Int'];
  ownerType: ApiUserOwnerType;
};


export type MutationApiUserResetClientSecretArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  hasClientSecret?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerType?: InputMaybe<ApiUserOwnerType>;
  puppetUserId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationApiUserUpdateOneArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  hasClientSecret?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerType?: InputMaybe<ApiUserOwnerType>;
  puppetUserId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationAssessmentDataCreateOneArgs = {
  allowedTimePerSection?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  isPractice?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  normGroupType?: InputMaybe<NormGroupType>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  startingAbilityLowerBound?: InputMaybe<Scalars['Float']>;
  startingAbilityUpperBound?: InputMaybe<Scalars['Float']>;
  startingQuestions?: InputMaybe<Array<IAssessmentDataQuestionModel>>;
  supportedLocale?: InputMaybe<SupportedLocale>;
  testCandidateId: Scalars['Int'];
};


export type MutationAssessmentDataSoftDeleteOneArgs = {
  allowedTimePerSection?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  isPractice?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  normGroupType?: InputMaybe<NormGroupType>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  startingAbilityLowerBound?: InputMaybe<Scalars['Float']>;
  startingAbilityUpperBound?: InputMaybe<Scalars['Float']>;
  startingQuestions?: InputMaybe<Array<IAssessmentDataQuestionModel>>;
  supportedLocale?: InputMaybe<SupportedLocale>;
  testCandidateId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationAssessmentDataUpdateOneArgs = {
  allowedTimePerSection?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  isPractice?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  normGroupType?: InputMaybe<NormGroupType>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  startingAbilityLowerBound?: InputMaybe<Scalars['Float']>;
  startingAbilityUpperBound?: InputMaybe<Scalars['Float']>;
  startingQuestions?: InputMaybe<Array<IAssessmentDataQuestionModel>>;
  supportedLocale?: InputMaybe<SupportedLocale>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationBaseMethodTestCreateManyArgs = {
  dto: Array<BaseMethodTestCreateOneArgs>;
};


export type MutationBaseMethodTestCreateOneArgs = {
  name?: InputMaybe<Scalars['String']>;
  someEnum: BaseMethodTestSomeEnum;
  someFlag: Scalars['Boolean'];
};


export type MutationBaseMethodTestDelayedEventArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someEnums?: InputMaybe<Array<BaseMethodTestSomeEnum>>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
  someFlags?: InputMaybe<Array<Scalars['Boolean']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationBaseMethodTestDeleteManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someEnums?: InputMaybe<Array<BaseMethodTestSomeEnum>>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
  someFlags?: InputMaybe<Array<Scalars['Boolean']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationBaseMethodTestDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
  subId?: InputMaybe<Scalars['String']>;
};


export type MutationBaseMethodTestSoftDeleteManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someEnums?: InputMaybe<Array<BaseMethodTestSomeEnum>>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
  someFlags?: InputMaybe<Array<Scalars['Boolean']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationBaseMethodTestSoftDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
  subId?: InputMaybe<Scalars['String']>;
};


export type MutationBaseMethodTestUpdateManyArgs = {
  dto: Array<BaseMethodTestUpdateOneArgs>;
};


export type MutationBaseMethodTestUpdateOneArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
};


export type MutationBehaviourContentCreateOneArgs = {
  behaviourId: Scalars['Int'];
  negativeSummary: Scalars['String'];
  positiveSummary: Scalars['String'];
  roleLevel?: InputMaybe<ContentRoleLevel>;
};


export type MutationBehaviourContentDeleteOneArgs = {
  behaviourId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  negativeSummary?: InputMaybe<Scalars['String']>;
  positiveSummary?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
};


export type MutationBehaviourContentUpdateOneArgs = {
  behaviourId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  negativeSummary?: InputMaybe<Scalars['String']>;
  positiveSummary?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
};


export type MutationBehaviourCreateOneArgs = {
  contextIds?: InputMaybe<Array<Scalars['Int']>>;
  internalName: Scalars['String'];
};


export type MutationBehaviourDeleteOneArgs = {
  contextIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  internalName?: InputMaybe<Scalars['String']>;
};


export type MutationBehaviourFromCsvRowsCreateManyArgs = {
  dto: Array<BehaviourFromCsvRowsCreateManyArgs>;
};


export type MutationBehaviourUpdateOneArgs = {
  contextIds?: InputMaybe<Array<Scalars['Int']>>;
  id: Scalars['Int'];
  internalName?: InputMaybe<Scalars['String']>;
};


export type MutationCalibrationCandidateCreateOneArgs = {
  stageCandidateId: Scalars['Int'];
};


export type MutationCalibrationCandidateDeleteOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  lastAdjustedById?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CalibrationCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationCalibrationCandidateRestoreOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  lastAdjustedById?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CalibrationCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationCalibrationCandidateRollBackOneArgs = {
  id: Scalars['Int'];
  status: CalibrationCandidateStatus;
};


export type MutationCalibrationCandidateSoftDeleteOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  lastAdjustedById?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CalibrationCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationCalibrationCandidateUpdateOneArgs = {
  id: Scalars['Int'];
  lastAdjustedById?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CalibrationCandidateStatus>;
};


export type MutationCalibrationFileUploadArgs = {
  apply: Scalars['Boolean'];
  args: CalibrationFileUploadArgs;
  file: Scalars['Upload'];
};


export type MutationClientAuthenticateArgs = {
  clientType?: InputMaybe<ClientType>;
  email: Scalars['String'];
  mfaCookie?: InputMaybe<Array<Scalars['String']>>;
  password: Scalars['String'];
};


export type MutationClientMfaAuthenticateArgs = {
  mfaAccessToken: Scalars['String'];
  mfaCode: Scalars['String'];
};


export type MutationClientRegisterAccountArgs = {
  companyId?: InputMaybe<Scalars['Float']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitationToken?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String'];
  locale?: InputMaybe<SupportedLocale>;
  password: Scalars['String'];
  projectId?: InputMaybe<Scalars['Float']>;
};


export type MutationCmCandidateResultsCsvExportArgs = {
  departments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  freeTextSearch?: InputMaybe<Scalars['String']>;
  hrManagerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  invitedDate?: InputMaybe<FilterMomentRange>;
  lastDateOfCandidateAction?: InputMaybe<FilterMomentRange>;
  managerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  orderBy?: InputMaybe<CmCandidateResultOrderByArgs>;
  projectCandidateGroupedStatus?: InputMaybe<ProjectCandidateGroupedStatus>;
  projectCandidateGroupedStatuses?: InputMaybe<Array<ProjectCandidateGroupedStatus>>;
  projectId: Scalars['Int'];
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type MutationCompanyAllowedEmailDomainsUpdateArgs = {
  allowedEmailDomains: Array<Scalars['String']>;
  id: Scalars['Int'];
};


export type MutationCompanyCreateUserArgs = {
  companyId: Scalars['Float'];
  email: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  role?: InputMaybe<IdentityPermissionRole>;
  roles?: InputMaybe<Array<IdentityPermissionRole>>;
  sendInvite: Scalars['Boolean'];
};


export type MutationCompanyEmployeeCreateArgs = {
  companyId: Scalars['Int'];
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  employeeId: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  firstName: Scalars['String'];
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationCompanyEmployeeDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationCompanyEmployeeFileUploadArgs = {
  apply: Scalars['Boolean'];
  args: CompanyEmployeeFileUploadArgs;
  file: Scalars['Upload'];
};


export type MutationCompanyEmployeeSyncWithUserCompanyInfoArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  employeeId?: InputMaybe<Scalars['String']>;
  employeeIds?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationCompanyEmployeeUpdateArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationCompanyResendInviteArgs = {
  companyId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type MutationCompanyRoleUpsertArgs = {
  companyId: Scalars['Int'];
  id?: InputMaybe<Scalars['Int']>;
  isPasswordDisabled?: InputMaybe<Scalars['Boolean']>;
  roleId: Scalars['Int'];
  samlAuthInfoIds?: InputMaybe<Array<Scalars['Int']>>;
  twoFactorEnabled?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCompanySignatureCreateOneArgs = {
  companyId: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  signature: Scalars['String'];
  signatureEmail: Scalars['String'];
};


export type MutationCompanySignatureDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationCompanySignatureSoftDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationCompanySignatureUpdateOneArgs = {
  id: Scalars['Int'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  signatureEmail?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAssessmentBrowserSwitchCandidateTrackingEventArgs = {
  assessmentId: Scalars['Int'];
  assessmentTimeSeconds?: InputMaybe<Scalars['Int']>;
  type: AssessmentBrowserSwitchCandidateTrackingEvent;
};


export type MutationCreateCompanyUserArgs = {
  companyId: Scalars['Float'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  roles: Array<IdentityPermissionRole>;
};


export type MutationDeiStageActionArchiveArgs = {
  stageId: Scalars['Int'];
};


export type MutationDeiStageActionCandidateReopenArgs = {
  stageCandidateId: Scalars['Int'];
};


export type MutationDeiStageActionCandidateSubmitResponsesArgs = {
  responses: DeiStageActionCandidateResponsesInput;
  stageCandidateId: Scalars['Int'];
};


export type MutationDeiStageActionTemplateUpsertArgs = {
  companyId: Scalars['Int'];
  fields: DeiStageActionTemplateFieldsInput;
};


export type MutationDeiStageActionUnarchiveArgs = {
  fields?: InputMaybe<DeiStageActionFieldsInput>;
  stageId: Scalars['Int'];
};


export type MutationDeiStageActionUpsertArgs = {
  fields: DeiStageActionFieldsInput;
  stageId: Scalars['Int'];
};


export type MutationE2eCookieArgs = {
  email: Scalars['String'];
};


export type MutationEaStageActionCandidateMarkInProgressArgs = {
  eaStageActionId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  invitationUuid?: InputMaybe<Scalars['String']>;
  modifier?: InputMaybe<ExternalPlatformModifier>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<EaStageActionCandidateStatus>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationEaStageActionCandidateResetOneArgs = {
  eaStageActionId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};


export type MutationEaStageActionCandidateUpdateOneArgs = {
  eaStageActionId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  invitationUuid?: InputMaybe<Scalars['String']>;
  modifier?: InputMaybe<ExternalPlatformModifier>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<EaStageActionCandidateStatus>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationEaStageActionCreateOneArgs = {
  companyId: Scalars['Int'];
  externalAssessmentId: Scalars['Int'];
  stageId: Scalars['Int'];
};


export type MutationEaStageActionSoftDeleteOneArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  externalAssessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stageId?: InputMaybe<Scalars['Int']>;
};


export type MutationEmailTemplateCreateOneArgs = {
  language?: InputMaybe<Language>;
  locale?: InputMaybe<SupportedLocale>;
  templateId: Scalars['String'];
  type: EmailTemplateType;
  version?: InputMaybe<Scalars['Int']>;
};


export type MutationEntityLanguageCreateOneArgs = {
  entityId: Scalars['Int'];
  entityType: EntityType;
  language: Language;
};


export type MutationEntityLanguageDeleteOneArgs = {
  entityId?: InputMaybe<Scalars['Int']>;
  entityType?: InputMaybe<EntityType>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Language>;
};


export type MutationExchangeAuthCodeArgs = {
  authCode: Scalars['String'];
};


export type MutationExternalAssessmentCreateOneArgs = {
  companyId?: InputMaybe<Scalars['Float']>;
  eaCompanyPlatform?: InputMaybe<EaCompanyPlatformCreateOneArgs>;
  eaCompanyPlatformId?: InputMaybe<Scalars['Float']>;
  eaPlatformAssessments: Array<EaPlatformAssessmentCreateOneArgs>;
  eaSkills?: InputMaybe<Array<EaSkillCreateOneArgs>>;
  estimatedDurationInMinutes?: InputMaybe<Scalars['Float']>;
  externalPlatformType: ExternalPlatformType;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  timeLimitInMinutes?: InputMaybe<Scalars['Float']>;
};


export type MutationExternalAssessmentUpdateOneArgs = {
  eaPlatformAssessments?: InputMaybe<Array<EaPlatformAssessmentUpsertOneArgs>>;
  eaSkills?: InputMaybe<Array<EaSkillUpsertOneArgs>>;
  estimatedDurationInMinutes?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  lastUsedAt?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  timeLimitInMinutes?: InputMaybe<Scalars['Float']>;
};


export type MutationFieldCreateArgs = {
  fieldInstanceType: FieldInstanceType;
  fieldOwnerId?: InputMaybe<Scalars['Int']>;
  fieldOwnerType: FieldOwnerType;
  fieldType: FieldType;
  formManagerType: FormManagerType;
  hint?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
  selectOptions?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<FieldSettingsArgs>;
};


export type MutationFieldDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationFieldUpdateArgs = {
  hint?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  selectOptions?: InputMaybe<Scalars['String']>;
  settings?: InputMaybe<FieldSettingsArgs>;
};


export type MutationFormAddFieldArgs = {
  displayOrder?: InputMaybe<Scalars['Int']>;
  fieldId: Scalars['Int'];
  formId: Scalars['Int'];
  isOptional: Scalars['Boolean'];
};


export type MutationFormBuilderCreateArgs = {
  addFields?: InputMaybe<Array<CreateFormFieldByFieldIdArgs>>;
  formManagerType: FormManagerType;
  formOwnerId: Scalars['Int'];
  formType: FormType;
  name: Scalars['String'];
};


export type MutationFormBuilderUpdateArgs = {
  addFields?: InputMaybe<Array<CreateFormFieldByFieldIdArgs>>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  removeFields?: InputMaybe<Array<DeleteFormFieldByFieldIdArgs>>;
  updateFields?: InputMaybe<Array<UpdateFormFieldByFieldIdArgs>>;
};


export type MutationFormCandidateCreateOneArgs = {
  formId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};


export type MutationFormCandidateDeleteOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  formId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<FormCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationFormCandidateResetOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  formId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<FormCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationFormCandidateUpdateOneArgs = {
  formId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<FormCandidateStatus>;
};


export type MutationFormCreateArgs = {
  formManagerType: FormManagerType;
  formOwnerId: Scalars['Int'];
  formType: FormType;
  name: Scalars['String'];
};


export type MutationFormDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationFormFieldRemoveArgs = {
  fieldId: Scalars['Int'];
  formId: Scalars['Int'];
};


export type MutationFormFieldUpdateArgs = {
  displayOrder?: InputMaybe<Scalars['Int']>;
  formFieldId?: InputMaybe<Scalars['Int']>;
  formId?: InputMaybe<Scalars['Int']>;
  isOptional?: InputMaybe<Scalars['Boolean']>;
};


export type MutationFormUpdateArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationGreenhouseExternalAtsCreateOneArgs = {
  clientId: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
  companyId: Scalars['Int'];
};


export type MutationGreenhouseExternalAtsDeleteOneArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  eaIntegrationId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationHiringDecisionAddStartDateArgs = {
  id: Scalars['Int'];
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationHiringDecisionSkillReviewSubmitArgs = {
  evaluation?: InputMaybe<HiringDecisionSkillReviewEvaluation>;
  projectJobRoleId: Scalars['Int'];
  skillId?: InputMaybe<Scalars['Int']>;
  stageCandidateId: Scalars['Int'];
  type: HiringDecisionSkillReviewType;
};


export type MutationHiringDecisionSubmitArgs = {
  decision: HiringDecisionValue;
  projectJobRoleId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};


export type MutationHiringInviteFileUploadArgs = {
  apply: Scalars['Boolean'];
  args: HiringInviteFileUploadArgs;
  file: Scalars['Upload'];
};


export type MutationIdentityProviderCreateOneArgs = {
  name: Scalars['String'];
};


export type MutationIdentityProviderDeleteOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationIdentityProviderRestoreOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationIdentityProviderSoftDeleteOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationIdentityProviderUpdateOneArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationIgBehaviourAssessmentEntryRemoveOneArgs = {
  baEntryId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  renderId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationIgCategoryCreateArgs = {
  internalName: Scalars['String'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationIgCategoryDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationIgCategoryUpdateArgs = {
  id: Scalars['Int'];
  internalName?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationIgInterviewGuideCategoryCreateArgs = {
  categoryId: Scalars['Int'];
  colour: IgCategoryColour;
  interviewGuideId: Scalars['Int'];
  order: Scalars['Int'];
};


export type MutationIgInterviewGuideCategoryDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationIgInterviewGuideCategoryUpdateArgs = {
  colour?: InputMaybe<IgCategoryColour>;
  id: Scalars['Int'];
  order?: InputMaybe<Scalars['Int']>;
};


export type MutationIgInterviewGuideCreateArgs = {
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  ownerId: Scalars['Int'];
};


export type MutationIgInterviewGuideDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationIgInterviewGuideUpdateArgs = {
  id: Scalars['Int'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
};


export type MutationIgRenderUpdateOneArgs = {
  authorId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  pageStatus?: InputMaybe<IgRenderPageStatus>;
  secondPageOpened?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<IgRenderStatus>;
};


export type MutationIgStepCreateArgs = {
  action?: InputMaybe<Scalars['String']>;
  colour?: InputMaybe<IgCategoryColour>;
  durationInSeconds?: InputMaybe<Scalars['Int']>;
  internalName: Scalars['String'];
  interviewGuideId: Scalars['Int'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  questionType: IgQuestionType;
  staticQuestionName?: InputMaybe<Scalars['String']>;
};


export type MutationIgStepDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationIgStepUpdateArgs = {
  action?: InputMaybe<Scalars['String']>;
  colour?: InputMaybe<IgCategoryColour>;
  durationInSeconds?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  internalName?: InputMaybe<Scalars['String']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  questionType?: InputMaybe<IgQuestionType>;
  staticQuestionName?: InputMaybe<Scalars['String']>;
};


export type MutationIgTabRenderRefineModifiedQuestionRequestArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  renderId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  tabId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationIgTabRenderResetModifiedQuestionArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  renderId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  tabId?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationInterviewGuideBehaviourUpdateOneArgs = {
  behaviourId?: InputMaybe<Scalars['Int']>;
  classification?: InputMaybe<IgRenderBehaviourClassification>;
  doneById?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  interviewGuideId?: InputMaybe<Scalars['Int']>;
};


export type MutationInterviewGuideDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
};


export type MutationInterviewGuideFeedbackRatingArgs = {
  notes?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<IgFeedbackRating>;
  renderId: Scalars['Int'];
};


export type MutationInterviewGuideFeedbackRegenerateRequestArgs = {
  doneById?: InputMaybe<Scalars['Int']>;
  feedback?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
};


export type MutationInterviewGuideFeedbackSubmitOneArgs = {
  developmentBehaviours?: InputMaybe<Array<Scalars['String']>>;
  doneById?: InputMaybe<Scalars['Int']>;
  feedback?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  strengthBehaviours?: InputMaybe<Array<Scalars['String']>>;
  userEdited?: InputMaybe<Scalars['Boolean']>;
};


export type MutationInterviewGuideFeedbackUpdateOneArgs = {
  doneById?: InputMaybe<Scalars['Int']>;
  feedback?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
};


export type MutationInterviewGuideGenerateOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  renderId?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
};


export type MutationInterviewGuideOpenSecondPageArgs = {
  id?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  renderId?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
};


export type MutationInterviewGuideRecipientCreateManyAndSendEmailsArgs = {
  dto: Array<IgRenderRecipientCreateOneArgs>;
};


export type MutationInterviewGuideRecipientDeleteOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  recipientId?: InputMaybe<Scalars['Int']>;
  renderId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationInterviewGuideSectionMarkTabAsSelectedArgs = {
  doneById: Scalars['Int'];
  id: Scalars['Int'];
  tabId: Scalars['Int'];
};


export type MutationInterviewGuideSelectedUpdateOneArgs = {
  behaviourAssessmentEntryId?: InputMaybe<Scalars['Int']>;
  doneById?: InputMaybe<Scalars['Int']>;
  forTab: Scalars['Boolean'];
  id: Scalars['Int'];
  sectionTabId?: InputMaybe<Scalars['Int']>;
  select?: InputMaybe<Scalars['Boolean']>;
};


export type MutationInterviewGuideSoftDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
};


export type MutationInterviewGuideTextFieldUpdateOneArgs = {
  behaviourAssessmentEntryId?: InputMaybe<Scalars['Int']>;
  doneById?: InputMaybe<Scalars['Int']>;
  forTab: Scalars['Boolean'];
  id: Scalars['Int'];
  sectionTabId?: InputMaybe<Scalars['Int']>;
  text?: InputMaybe<Scalars['String']>;
};


export type MutationInterviewQuestionContentCreateOneArgs = {
  contextId: Scalars['Int'];
  mainQuestion: Scalars['String'];
  roleLevel: ContentRoleLevel;
  subQuestions: Array<Scalars['String']>;
};


export type MutationInterviewQuestionContentDeleteOneArgs = {
  contextId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  mainQuestion?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  subQuestions?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationInterviewQuestionContentUpdateOneArgs = {
  contextId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  mainQuestion?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  subQuestions?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationInterviewQuestionReasonContentCreateOneArgs = {
  contextId: Scalars['Int'];
  numLowerBound: Scalars['Int'];
  roleLevel: ContentRoleLevel;
};


export type MutationInterviewQuestionReasonContentDeleteOneArgs = {
  contextId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  numLowerBound?: InputMaybe<Scalars['Int']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
};


export type MutationInterviewQuestionReasonContentUpdateOneArgs = {
  contextId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  numLowerBound?: InputMaybe<Scalars['Int']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
};


export type MutationInterviewQuestionRoleLevelContentUpdateArgs = {
  feedback?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  mainQuestion?: InputMaybe<Scalars['String']>;
  reasonForQuestion?: InputMaybe<Scalars['String']>;
  subQuestions?: InputMaybe<Array<Scalars['String']>>;
  summary?: InputMaybe<Scalars['String']>;
};


export type MutationInvitationCreateArgs = {
  companyId: Scalars['Int'];
  email?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  invitationType: InvitationType;
  language?: InputMaybe<Language>;
  lastActionType?: InputMaybe<InvitationLastActionType>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  projectSubId?: InputMaybe<Scalars['String']>;
  shouldCreateAccount?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<InvitationStatusCode>;
  targetId: Scalars['Int'];
};


export type MutationInvitationDeleteManyArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationInvitationDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationInvitationUpdateArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  invitationType?: InputMaybe<InvitationType>;
  language?: InputMaybe<Language>;
  lastActionType?: InputMaybe<InvitationLastActionType>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  projectSubId?: InputMaybe<Scalars['String']>;
  shouldCreateAccount?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<InvitationStatusCode>;
  targetId?: InputMaybe<Scalars['Int']>;
};


export type MutationJobRoleCreateOneArgs = {
  companyId: Scalars['Int'];
  family?: InputMaybe<JobRoleFamily>;
  name: Scalars['String'];
  qualifier?: InputMaybe<Scalars['String']>;
  roleLevel: ContentRoleLevel;
};


export type MutationJobRoleDeleteOneArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  family?: InputMaybe<JobRoleFamily>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameOrQualifierContains?: InputMaybe<Scalars['String']>;
  qualifier?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationJobRoleSoftDeleteOneArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  family?: InputMaybe<JobRoleFamily>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameOrQualifierContains?: InputMaybe<Scalars['String']>;
  qualifier?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationJobRoleUpdateOneArgs = {
  family?: InputMaybe<JobRoleFamily>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  qualifier?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
};


export type MutationLocationLinkCreateManyArgs = {
  links: Array<LocationLinkCreateArgs>;
};


export type MutationLocationLinkCreateOneArgs = {
  companyId: Scalars['Int'];
  locationName: Scalars['String'];
  projectId: Scalars['Int'];
  userGroupId: Scalars['Int'];
};


export type MutationLocationLinkDeleteManyArgs = {
  args: LocationLinkDeleteManyArgs;
};


export type MutationProjectCollaboratorsCreateManyArgs = {
  collaborators: Array<ProjectCollaboratorCreateOneArgs>;
};


export type MutationProjectCollaboratorsDeleteManyArgs = {
  collaborators: Array<Scalars['Int']>;
};


export type MutationProjectCollaboratorsUpdateRoleArgs = {
  newActorRole?: InputMaybe<ActorRole>;
  projectTeamMemberId: Scalars['Int'];
};


export type MutationProjectDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationProjectJobRoleCreateOneArgs = {
  jobRoleId: Scalars['Int'];
  projectId: Scalars['Int'];
  skillsProfileId: Scalars['Int'];
};


export type MutationProjectJobRoleDeleteOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  jobRoleId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  skillsProfileId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationProjectJobRoleSoftDeleteOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  jobRoleId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  skillsProfileId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type MutationProjectRequestCreateOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId: Scalars['Int'];
  creatorId: Scalars['Int'];
  projectName: Scalars['String'];
  status?: InputMaybe<ProjectRequestStatus>;
};


export type MutationProjectRequestDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationProjectRequestFileUploadArgs = {
  args: ProjectRequestFileUploadArgs;
  file: Scalars['Upload'];
};


export type MutationProjectRequestFileUploadDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationProjectRequestFileUploadSoftDeleteOneArgs = {
  blobId?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  projectRequestId?: InputMaybe<Scalars['Int']>;
};


export type MutationProjectRequestLinkProjectArgs = {
  id: Scalars['Int'];
  projectId: Scalars['Int'];
};


export type MutationProjectRequestRequestJobSpecificationArgs = {
  id: Scalars['Int'];
  projectRequestUserIds: Array<Scalars['Int']>;
};


export type MutationProjectRequestRequestManagerSurveyArgs = {
  id: Scalars['Int'];
  projectRequestUserIds: Array<Scalars['Int']>;
  surveyLink: Scalars['String'];
};


export type MutationProjectRequestRestoreOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  managerSurveyId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  status?: InputMaybe<ProjectRequestStatus>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type MutationProjectRequestSoftDeleteOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  managerSurveyId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectRequestStatus>;
};


export type MutationProjectRequestUpdateManagerSurveyArgs = {
  id: Scalars['Int'];
  managerSurveyId: Scalars['String'];
};


export type MutationProjectRequestUpdateOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  managerSurveyId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectRequestStatus>;
};


export type MutationProjectRequestUserApprovalCreateManyArgs = {
  dto: Array<IProjectRequestUserApprovalCreateOneArgs>;
};


export type MutationProjectRequestUserApprovalCreateOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  approvalStatus?: InputMaybe<ProjectRequestUserApprovalStatus>;
  projectRequestUserId: Scalars['Int'];
};


export type MutationProjectRequestUserApprovalDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationProjectRequestUserApprovalSoftDeleteOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  approvalStatus?: InputMaybe<ProjectRequestUserApprovalStatus>;
  id?: InputMaybe<Scalars['Int']>;
};


export type MutationProjectRequestUserApprovalUpdateManyArgs = {
  dto: Array<IProjectRequestUserApprovalUpdateOneArgs>;
};


export type MutationProjectRequestUserApprovalUpdateOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  approvalStatus?: InputMaybe<ProjectRequestUserApprovalStatus>;
  id: Scalars['Int'];
};


export type MutationProjectRequestUserCreateManyArgs = {
  dto: Array<IProjectRequestUserCreateOneWithEmployeeCheckArgs>;
};


export type MutationProjectRequestUserCreateOneArgs = {
  approvalId?: InputMaybe<Scalars['Int']>;
  email: Scalars['String'];
  projectRequestId: Scalars['Int'];
  type: ProjectRequestUserType;
};


export type MutationProjectRequestUserDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationProjectRequestUserSoftDeleteOneArgs = {
  approvalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  projectRequestId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProjectRequestUserType>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type MutationProjectRequestUserUpdateOneArgs = {
  approvalId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  projectRequestId?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<ProjectRequestUserType>;
};


export type MutationProjectSetCompanySignatureArgs = {
  companySignatureId?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
};


export type MutationProjectUserDeleteOneArgs = {
  projectId: Scalars['Float'];
  userId: Scalars['Float'];
};


export type MutationProjectUserUpdateOneArgs = {
  actorRole: ActorRole;
  projectId: Scalars['Int'];
  userId: Scalars['Int'];
};


export type MutationQCalcGetNextQuestionArgs = {
  answers?: InputMaybe<Array<AssessmentDataAnswerGqlModel>>;
  assessmentId: Scalars['Int'];
  nQuestions?: InputMaybe<Scalars['Int']>;
};


export type MutationReportAssignmentCreateArgs = {
  assignedId: Scalars['Int'];
  assignedType: RAssignedType;
  reportId: Scalars['Int'];
};


export type MutationReportAssignmentDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationReportAssignmentUpdateArgs = {
  assignedId: Scalars['Int'];
  assignedType: RAssignedType;
  id: Scalars['Int'];
  reportId: Scalars['Int'];
};


export type MutationReportConfigCreateArgs = {
  newPageBefore?: InputMaybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  reportId: Scalars['Int'];
  widgetConfigsToUpsert?: InputMaybe<Array<WidgetConfigUpsertOneBatchedWithReportConfigArgs>>;
  widgetId: Scalars['Int'];
};


export type MutationReportConfigDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationReportCopyArgs = {
  copyReportId: Scalars['Int'];
  name: Scalars['String'];
  ownerId: Scalars['Int'];
  ownerType: ReportOwnerType;
  subType?: InputMaybe<ReportSubType>;
  type: ReportType;
};


export type MutationReportCreateArgs = {
  name: Scalars['String'];
  ownerId: Scalars['Int'];
  ownerType: ReportOwnerType;
  subType?: InputMaybe<ReportSubType>;
  type: ReportType;
};


export type MutationReportDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationReportRenderCreateArgs = {
  dataSourceId: Scalars['Int'];
  dontSendEmailToCandidate?: InputMaybe<Scalars['Boolean']>;
  reportId: Scalars['Int'];
  reportRenderType: ReportRenderType;
};


export type MutationReportUpdateArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  reportConfigsToCreate?: InputMaybe<Array<ReportConfigCreateOneBatchedWithReportArgs>>;
  reportConfigsToDelete?: InputMaybe<Array<DeleteByIdArgs>>;
  reportConfigsToUpdate?: InputMaybe<Array<ReportConfigUpdateOneArgs>>;
  subType?: InputMaybe<ReportSubType>;
  type?: InputMaybe<ReportType>;
};


export type MutationReportUpdateIsArchivedArgs = {
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
};


export type MutationRequeueRejectedEventArgs = {
  id: Scalars['Int'];
};


export type MutationRespondantFormDeleteByRespondantAndAssociatedIdArgs = {
  associatedId: Scalars['Int'];
  id: Scalars['Int'];
};


export type MutationRespondantFormDeleteByRespondantIdArgs = {
  id: Scalars['Int'];
};


export type MutationRespondantFormUpdateArgs = {
  answers?: InputMaybe<Array<UpdateRespondantFormAnswerArgs>>;
  associatedId: Scalars['Int'];
  formOwnerId: Scalars['Int'];
  formType: FormType;
  projectId?: InputMaybe<Scalars['Int']>;
  respondantId?: InputMaybe<Scalars['Int']>;
};


export type MutationResultCreateManySoftSkillsProfileArgs = {
  args: Array<ResultCreateOneRoleSkillsSelfAssessmentArgs>;
};


export type MutationResultCreateManySoftSkillsProfileFromScArgs = {
  args: Array<ResultSoftSkillProfileCreateOneFromSc>;
};


export type MutationResultCreateManyStatisticalArgs = {
  args: Array<ResultCreateOneStatisticalArgs>;
};


export type MutationResultCreateManyTechnicalSkillsProfileFromScArgs = {
  args: Array<ResultTechnicalSkillProfileCreateOneFromSc>;
};


export type MutationResultCreateManyTrCustomArgs = {
  args: Array<ResultCreateOneTrCustomArgs>;
};


export type MutationResultCreateOneBehaviourArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: IIgBehaviourResultScoreModel;
};


export type MutationResultCreateOneIgCustomArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: IIgCustomResultScoreModel;
};


export type MutationResultCreateOneIgFinalDecisionArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: IIgFinalDecisionResultScoreModel;
};


export type MutationResultCreateOnePspSoftSkillArgs = {
  score: ISoftSkillSelfAssessmentScoreModel;
  softSkillId: Scalars['Int'];
};


export type MutationResultCreateOnePspTechnicalSkillArgs = {
  score: ITechnicalSkillSelfAssessmentScoreModel;
  technicalSkillId: Scalars['Int'];
};


export type MutationResultCreateOneSoftSkillsProfileArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: ISoftSkillSelfAssessmentScoreModel;
};


export type MutationResultCreateOneSoftSkillsProfileFromScArgs = {
  score: ISoftSkillSelfAssessmentScoreModel;
  softSkillId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};


export type MutationResultCreateOneStatisticalArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: IStatisticalResultScoreModel;
};


export type MutationResultCreateOneTechnicalSkillsProfileFromScArgs = {
  score: ITechnicalSkillSelfAssessmentScoreModel;
  stageCandidateId: Scalars['Int'];
  technicalSkillId: Scalars['Int'];
};


export type MutationResultCreateOneTrCustomArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: ITrCustomResultScoreModel;
};


export type MutationResultDeleteManyArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  copiedFroms?: InputMaybe<Array<Scalars['Int']>>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneBys?: InputMaybe<Array<Scalars['Int']>>;
  doneFor?: InputMaybe<Scalars['Int']>;
  doneFors?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  label?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  manuallyAdjustedBys?: InputMaybe<Array<Scalars['Int']>>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementIds?: InputMaybe<Array<Scalars['Int']>>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  measurementTypes?: InputMaybe<Array<ResultMeasurementType>>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  previousVersionIds?: InputMaybe<Array<Scalars['Int']>>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  projectJobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  scoreType?: InputMaybe<ResultScoreType>;
  scoreTypes?: InputMaybe<Array<ResultScoreType>>;
  version?: InputMaybe<Scalars['Int']>;
  versions?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationResultDeleteOneArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  scoreType?: InputMaybe<ResultScoreType>;
  version?: InputMaybe<Scalars['Int']>;
};


export type MutationResultRestoreOneArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  scoreType?: InputMaybe<ResultScoreType>;
  version?: InputMaybe<Scalars['Int']>;
};


export type MutationResultSoftDeleteManyArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  copiedFroms?: InputMaybe<Array<Scalars['Int']>>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneBys?: InputMaybe<Array<Scalars['Int']>>;
  doneFor?: InputMaybe<Scalars['Int']>;
  doneFors?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  label?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  manuallyAdjustedBys?: InputMaybe<Array<Scalars['Int']>>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementIds?: InputMaybe<Array<Scalars['Int']>>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  measurementTypes?: InputMaybe<Array<ResultMeasurementType>>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  previousVersionIds?: InputMaybe<Array<Scalars['Int']>>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  projectJobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  scoreType?: InputMaybe<ResultScoreType>;
  scoreTypes?: InputMaybe<Array<ResultScoreType>>;
  version?: InputMaybe<Scalars['Int']>;
  versions?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationResultSoftDeleteOneArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  scoreType?: InputMaybe<ResultScoreType>;
  version?: InputMaybe<Scalars['Int']>;
};


export type MutationResultUpdateOneArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
};


export type MutationResultUpdateOneBehaviourArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  id: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: IIgBehaviourResultScoreModel;
};


export type MutationRssAssessmentCandidateReopenAllSubmittedForStageCandidateArgs = {
  stageCandidateId: Scalars['Int'];
};


export type MutationRssAssessmentCandidateSubmitOneArgs = {
  id: Scalars['Int'];
};


export type MutationRssAssessmentCreateManyArgs = {
  dto: Array<RssAssessmentCreateOneArgs>;
};


export type MutationRssAssessmentDeleteManyArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationSamlAuthInfoCreateOneArgs = {
  cert: Scalars['String'];
  entryPoint: Scalars['String'];
  identityProviderId: Scalars['Int'];
  issuer: Scalars['String'];
  signatureAlgorithm?: InputMaybe<Scalars['String']>;
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']>;
  wantAuthResponseSigned?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSamlAuthInfoDeleteOneArgs = {
  cert?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  entryPoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identityProviderId?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  signatureAlgorithm?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']>;
  wantAuthResponseSigned?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSamlAuthInfoRestoreOneArgs = {
  cert?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  entryPoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identityProviderId?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  signatureAlgorithm?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']>;
  wantAuthResponseSigned?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSamlAuthInfoSoftDeleteOneArgs = {
  cert?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  entryPoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identityProviderId?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  signatureAlgorithm?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']>;
  wantAuthResponseSigned?: InputMaybe<Scalars['Boolean']>;
};


export type MutationSamlAuthInfoUpdateOneArgs = {
  cert?: InputMaybe<Scalars['String']>;
  entryPoint?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  identityProviderId?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  signatureAlgorithm?: InputMaybe<Scalars['String']>;
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']>;
  wantAuthResponseSigned?: InputMaybe<Scalars['Boolean']>;
};


export type MutationScheduleRoiCsvFileExportArgs = {
  projectIds: Array<Scalars['Int']>;
};


export type MutationScheduleStageCandidateResultsCsvFileExportArgs = {
  exportLevel: CandidateResultsExportLevel;
  includeDeiData?: InputMaybe<Scalars['Boolean']>;
  includeFormsData?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
};


export type MutationSkillCategoryCreateManyArgs = {
  dto: Array<SkillCategoryCreateOneArgs>;
};


export type MutationSkillCategoryCreateOneArgs = {
  name: Scalars['String'];
};


export type MutationSkillCategoryDeleteManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSkillCategoryDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationSkillCategoryRestoreOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type MutationSkillCategorySoftDeleteManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationSkillCategorySoftDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationSkillCategoryUpdateManyArgs = {
  dto: Array<SkillCategoryUpdateOneArgs>;
};


export type MutationSkillCategoryUpdateOneArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationSmInviteHiringArgs = {
  doneForsAndProjectManagers: Array<SmInviteHiringDoneForsAndProjectManagersArgs>;
  endTime?: InputMaybe<Scalars['Date']>;
  includeDeadlineInEmail?: InputMaybe<Scalars['Boolean']>;
  isCompanyCandidate?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language>;
  locale?: InputMaybe<SupportedLocale>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  skipSendingEmailForExisting?: InputMaybe<Scalars['Boolean']>;
  stageId: Scalars['Int'];
  type: SmInviteType;
};


export type MutationSmInviteTrArgs = {
  doneByEmails?: InputMaybe<Array<Scalars['String']>>;
  doneForEmails: Array<Scalars['String']>;
  endTime?: InputMaybe<Scalars['Date']>;
  includeDeadlineInEmail?: InputMaybe<Scalars['Boolean']>;
  isCompanyCandidate?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language>;
  locale?: InputMaybe<SupportedLocale>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  skipSendingEmailForExisting?: InputMaybe<Scalars['Boolean']>;
  stageId: Scalars['Int'];
  type: SmInviteType;
};


export type MutationSoftSkillAddTraitArgs = {
  addEmptyContent?: InputMaybe<Scalars['Boolean']>;
  orientation: Orientation;
  softSkillId: Scalars['Int'];
  traitId: Scalars['Int'];
  weight: Scalars['Float'];
};


export type MutationSoftSkillBulkUpdateTraitsArgs = {
  softSkillTraits: Array<BulkUpdateSoftSkillTraitArgsSingle>;
};


export type MutationSoftSkillCreateArgs = {
  category: Scalars['String'];
  companyId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name: Scalars['String'];
  softSkillId?: InputMaybe<Scalars['Int']>;
};


export type MutationSoftSkillDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationSoftSkillDeleteTraitArgs = {
  softSkillId: Scalars['Int'];
  traitId: Scalars['Int'];
};


export type MutationSoftSkillProjectModuleTypeCreateOneArgs = {
  projectModuleType: ProjectModuleType;
  softSkillId: Scalars['Int'];
};


export type MutationSoftSkillProjectModuleTypeDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  projectModuleType?: InputMaybe<ProjectModuleType>;
  softSkillId?: InputMaybe<Scalars['Int']>;
};


export type MutationSoftSkillProjectModuleTypeFindOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  projectModuleType?: InputMaybe<ProjectModuleType>;
  softSkillId?: InputMaybe<Scalars['Int']>;
};


export type MutationSoftSkillRemoveIconArgs = {
  id: Scalars['Int'];
};


export type MutationSoftSkillRoleLevelContentUpdateArgs = {
  candidateContent?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  hiringManagerContent?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  internalCandidateContent?: InputMaybe<Scalars['String']>;
};


export type MutationSoftSkillSuccessProfileContentCreateArgs = {
  description: Scalars['String'];
  roleLevel: ContentRoleLevel;
  shortDescription: Scalars['String'];
  softSkillId: Scalars['Int'];
};


export type MutationSoftSkillSuccessProfileContentDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationSoftSkillSuccessProfileContentUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  roleLevel?: InputMaybe<ContentRoleLevel>;
  shortDescription?: InputMaybe<Scalars['String']>;
  softSkillId?: InputMaybe<Scalars['Int']>;
};


export type MutationSoftSkillTypeSuccessProfileContentCreateArgs = {
  description: Scalars['String'];
  softSkillType: SoftSkillType;
};


export type MutationSoftSkillTypeSuccessProfileContentDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationSoftSkillTypeSuccessProfileContentUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  softSkillType?: InputMaybe<SoftSkillType>;
};


export type MutationSoftSkillUpdateArgs = {
  category?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
};


export type MutationSoftSkillUpdateIsArchivedArgs = {
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
};


export type MutationSoftSkillUpdateTraitArgs = {
  orientation?: InputMaybe<Orientation>;
  softSkillId: Scalars['Int'];
  traitId: Scalars['Int'];
  weight?: InputMaybe<Scalars['Float']>;
};


export type MutationSoftSkillUploadIconArgs = {
  file: Scalars['Upload'];
  id: Scalars['Int'];
};


export type MutationSrEmailDispatchEmailsArgs = {
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  stageCandidateId: Scalars['Int'];
};


export type MutationStageCandidateAcceptIndirectInvitationArgs = {
  hiringManagers?: InputMaybe<Array<HiringManager>>;
  projectId?: InputMaybe<Scalars['Int']>;
  stageId: Scalars['Int'];
};


export type MutationStageCandidateCopyTestResultsArgs = {
  baseStageId: Scalars['Int'];
  candidateId: Scalars['Int'];
  targetStageId: Scalars['Int'];
};


export type MutationStageCandidateCreateArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  candidateId: Scalars['Int'];
  doneBy: Scalars['Int'];
  hasPledgedAnticheating?: InputMaybe<Scalars['Boolean']>;
  projectTrackerId: Scalars['Int'];
  stageId: Scalars['Int'];
  status?: InputMaybe<StageCandidateStatus>;
};


export type MutationStageCandidateDeleteManyArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationStageCandidateDeleteOneArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  hasPledgedAnticheating?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  projectTrackerId?: InputMaybe<Scalars['Int']>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StageCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
};


export type MutationStageCandidateDeleteTalentReviewRequestArgs = {
  projectId: Scalars['Int'];
  reason: StageCandidateDeleteLogsReasonEnum;
  stageCandidateId: Scalars['Int'];
};


export type MutationStageCandidatePledgeAnticheatingArgs = {
  id: Scalars['Int'];
};


export type MutationStageCandidateReprocessOneArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  hasPledgedAnticheating?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  projectTrackerId?: InputMaybe<Scalars['Int']>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StageCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
};


export type MutationStageCandidateRequestTalentReviewArgs = {
  emails: Array<Scalars['String']>;
  projectId: Scalars['Int'];
};


export type MutationStageCandidateResetOneArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  hasPledgedAnticheating?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  projectTrackerId?: InputMaybe<Scalars['Int']>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StageCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
};


export type MutationStageCandidateSubmitAdjustmentQuestionnaireArgs = {
  responses: Array<AdjustmentQuestionnaireResponse>;
  stageCandidateId: Scalars['Int'];
};


export type MutationStageCandidateUpdateArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  hasPledgedAnticheating?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  lastDateOfCandidateAction?: InputMaybe<Scalars['Date']>;
  projectTrackerId?: InputMaybe<Scalars['Int']>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StageCandidateStatus>;
};


export type MutationStageCreateArgs = {
  createInterviewGuide?: InputMaybe<Scalars['Boolean']>;
  emailCandidateReport: Scalars['Boolean'];
  emailManagerReport: Scalars['Boolean'];
  emailProjectTeam?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeamRoles?: InputMaybe<Array<ActorRole>>;
  emailSzAdmin: Scalars['Boolean'];
  enableF2fInterviews?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  formId?: InputMaybe<Scalars['Int']>;
  hasAdjustmentQuestionnaire?: InputMaybe<Scalars['Boolean']>;
  hasCalibration?: InputMaybe<Scalars['Boolean']>;
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
  renderCandidateReport: Scalars['Boolean'];
  startTime?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<StageStatus>;
  testIds?: InputMaybe<Array<Scalars['Int']>>;
  type: StageType;
};


export type MutationStageCreateCsvReportArgs = {
  basicScoreTypesToDisplay?: InputMaybe<Array<BasicScoreType>>;
  measurementConstructsToDisplay?: InputMaybe<Array<MeasurementConstructType>>;
  name?: InputMaybe<Scalars['String']>;
  options: StageCsvOptions;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  scoreTypesToDisplay?: InputMaybe<Array<DisplayScoreType>>;
  softSkillSort?: InputMaybe<SpSoftSkillSort>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationStageDeleteManyArgs = {
  ids: Array<Scalars['Int']>;
};


export type MutationStageDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationStageReorderManyArgs = {
  projectId: Scalars['Int'];
  stageOrderings: Array<OrderStageInput>;
};


export type MutationStageResultsReportRenderCreateArgs = {
  dataSourceId: Scalars['Int'];
  dontSendEmailToCandidate?: InputMaybe<Scalars['Boolean']>;
  projectJobRoleId: Scalars['Int'];
  reportId: Scalars['Int'];
  reportRenderType: ReportRenderType;
};


export type MutationStageUpdateArgs = {
  createInterviewGuide?: InputMaybe<Scalars['Boolean']>;
  emailCandidateReport?: InputMaybe<Scalars['Boolean']>;
  emailManagerReport?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeam?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeamRoles?: InputMaybe<Array<ActorRole>>;
  emailSzAdmin?: InputMaybe<Scalars['Boolean']>;
  enableF2fInterviews?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  formId?: InputMaybe<Scalars['Int']>;
  hasAdjustmentQuestionnaire?: InputMaybe<Scalars['Boolean']>;
  hasCalibration?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['Int'];
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  renderCandidateReport?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['Date']>;
  testIds?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<StageType>;
};


export type MutationSuccessProfileCreateArgs = {
  calculationMethod: CalculationMethod;
  gradingMethod: GradingMethod;
  jobRoleId: Scalars['Int'];
  roleLevel: ContentRoleLevel;
  softSkills: Array<SuccessProfileSoftSkillCreateOneArgs>;
};


export type MutationSuccessProfileCreateOneArgs = {
  calculationMethod: CalculationMethod;
  gradingMethod: GradingMethod;
  jobRoleId: Scalars['Int'];
  roleLevel: ContentRoleLevel;
};


export type MutationSuccessProfileDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationSuccessProfileDeleteManyArgs = {
  calculationMethod?: InputMaybe<CalculationMethod>;
  gradingMethod?: InputMaybe<GradingMethod>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  jobRoleId?: InputMaybe<Scalars['Int']>;
  jobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
};


export type MutationSuccessProfileExplanationDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationSuccessProfileExplanationSetStatusCompleteArgs = {
  id: Scalars['Int'];
};


export type MutationSuccessProfileExplantionCreateArgs = {
  successProfileId: Scalars['Int'];
};


export type MutationSuccessProfileReportRenderCreateArgs = {
  dataSourceId: Scalars['Int'];
  dontSendEmailToCandidate?: InputMaybe<Scalars['Boolean']>;
  reportId: Scalars['Int'];
  reportRenderType: ReportRenderType;
};


export type MutationSuccessProfileSoftSkillExplantionUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  shortDescription?: InputMaybe<Scalars['String']>;
};


export type MutationSuccessProfileSoftSkillTypeExplantionUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};


export type MutationSuccessProfileSoftSkillsOverwriteArgs = {
  id: Scalars['Int'];
  softSkills: Array<SuccessProfileSoftSkillCreateOneArgs>;
};


export type MutationSuccessProfileTechnicalSkillCreateManyArgs = {
  dto: Array<SuccessProfileTechnicalSkillCreateOneArgs>;
};


export type MutationSuccessProfileTechnicalSkillCreateOneArgs = {
  successProfileId?: InputMaybe<Scalars['Int']>;
  technicalSkillId: Scalars['Int'];
};


export type MutationSuccessProfileTechnicalSkillDeleteManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  successProfileId?: InputMaybe<Scalars['Int']>;
  successProfileIds?: InputMaybe<Array<Scalars['Int']>>;
  technicalSkillId?: InputMaybe<Scalars['Int']>;
  technicalSkillIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationSuccessProfileTechnicalSkillDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  successProfileId?: InputMaybe<Scalars['Int']>;
  technicalSkillId?: InputMaybe<Scalars['Int']>;
};


export type MutationSuccessProfileTechnicalSkillOverwriteManyArgs = {
  dto: Array<SuccessProfileTechnicalSkillCreateOneArgs>;
};


export type MutationSuccessProfileTechnicalSkillSoftDeleteManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  successProfileId?: InputMaybe<Scalars['Int']>;
  successProfileIds?: InputMaybe<Array<Scalars['Int']>>;
  technicalSkillId?: InputMaybe<Scalars['Int']>;
  technicalSkillIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationSuccessProfileTechnicalSkillSoftDeleteOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  successProfileId?: InputMaybe<Scalars['Int']>;
  technicalSkillId?: InputMaybe<Scalars['Int']>;
};


export type MutationSuccessProfileTechnicalSkillUpdateManyArgs = {
  dto: Array<SuccessProfileTechnicalSkillUpdateOneArgs>;
};


export type MutationSuccessProfileTechnicalSkillUpdateOneArgs = {
  id: Scalars['Int'];
  successProfileId?: InputMaybe<Scalars['Int']>;
  technicalSkillId?: InputMaybe<Scalars['Int']>;
};


export type MutationSuccessProfileTechnicalSkillsOverwriteArgs = {
  id: Scalars['Int'];
  technicalSkills: Array<SuccessProfileTechnicalSkillCreateOneArgs>;
};


export type MutationSuccessProfileUpdateArgs = {
  calculationMethod: CalculationMethod;
  gradingMethod: GradingMethod;
  id: Scalars['Int'];
  jobRoleId?: InputMaybe<Scalars['Int']>;
  roleLevel: ContentRoleLevel;
  softSkills: Array<SuccessProfileSoftSkillCreateOneArgs>;
};


export type MutationSuccessProfileUpdateOneArgs = {
  calculationMethod: CalculationMethod;
  gradingMethod: GradingMethod;
  id: Scalars['Int'];
  jobRoleId?: InputMaybe<Scalars['Int']>;
  roleLevel: ContentRoleLevel;
};


export type MutationSummaryNotesUpsertOneArgs = {
  projectJobRoleId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
  summary?: InputMaybe<Scalars['String']>;
};


export type MutationSzSkillsMergeCreateManyArgs = {
  dto: Array<SzSkillsMergeCreateOneArgs>;
};


export type MutationSzSkillsMergeCreateOneArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name: Scalars['String'];
  originId: Scalars['Int'];
  skillType: SkillType;
};


export type MutationSzSkillsMergeDeleteManyArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  measurementConstructTypes?: InputMaybe<Array<MeasurementConstructType>>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  originIds?: InputMaybe<Array<Scalars['Int']>>;
  skillType?: InputMaybe<SkillType>;
  skillTypes?: InputMaybe<Array<SkillType>>;
};


export type MutationSzSkillsMergeDeleteOneArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  skillType?: InputMaybe<SkillType>;
};


export type MutationSzSkillsMergeSoftDeleteManyArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  measurementConstructTypes?: InputMaybe<Array<MeasurementConstructType>>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  originIds?: InputMaybe<Array<Scalars['Int']>>;
  skillType?: InputMaybe<SkillType>;
  skillTypes?: InputMaybe<Array<SkillType>>;
};


export type MutationSzSkillsMergeSoftDeleteOneArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  skillType?: InputMaybe<SkillType>;
};


export type MutationSzSkillsMergeUpdateManyArgs = {
  dto: Array<SzSkillsMergeUpdateOneArgs>;
};


export type MutationSzSkillsMergeUpdateOneArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  skillType?: InputMaybe<SkillType>;
};


export type MutationTalentReviewFileUploadArgs = {
  apply: Scalars['Boolean'];
  args: TalentReviewFileUploadArgs;
  file: Scalars['Upload'];
};


export type MutationTechnicalSkillCreateManyArgs = {
  dto: Array<TechnicalSkillCreateOneArgs>;
};


export type MutationTechnicalSkillCreateOneArgs = {
  iconBlobId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  skillCategoryId?: InputMaybe<Scalars['Int']>;
};


export type MutationTechnicalSkillDeleteManyArgs = {
  iconBlobId?: InputMaybe<Scalars['Int']>;
  iconBlobIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
  skillCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationTechnicalSkillDeleteOneArgs = {
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
};


export type MutationTechnicalSkillIconUploadArgs = {
  file: Scalars['Upload'];
  id: Scalars['Int'];
};


export type MutationTechnicalSkillRestoreOneArgs = {
  getOption?: InputMaybe<GetOption>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type MutationTechnicalSkillSoftDeleteManyArgs = {
  iconBlobId?: InputMaybe<Scalars['Int']>;
  iconBlobIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
  skillCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationTechnicalSkillSoftDeleteOneArgs = {
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
};


export type MutationTechnicalSkillUpdateManyArgs = {
  dto: Array<TechnicalSkillUpdateOneArgs>;
};


export type MutationTechnicalSkillUpdateOneArgs = {
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
};


export type MutationTestCandidateRemoveOneArgs = {
  candidateId: Scalars['Int'];
  testId: Scalars['Int'];
};


export type MutationTestCandidateReprocessScoresArgs = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TestStatus>;
  testId?: InputMaybe<Scalars['Int']>;
  testOrigin?: InputMaybe<TestOrigin>;
  testResultId?: InputMaybe<Scalars['Int']>;
  timeModifierPercentage?: InputMaybe<Scalars['Float']>;
};


export type MutationTestCandidateResetOneArgs = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TestStatus>;
  testId?: InputMaybe<Scalars['Int']>;
  testOrigin?: InputMaybe<TestOrigin>;
  testResultId?: InputMaybe<Scalars['Int']>;
  timeModifierPercentage?: InputMaybe<Scalars['Float']>;
};


export type MutationTestCandidateStartSzPracticeTestArgs = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TestStatus>;
  testId?: InputMaybe<Scalars['Int']>;
  testOrigin?: InputMaybe<TestOrigin>;
  testResultId?: InputMaybe<Scalars['Int']>;
  timeModifierPercentage?: InputMaybe<Scalars['Float']>;
};


export type MutationTestCandidateStartSzTestArgs = {
  testCandidateId: Scalars['Int'];
};


export type MutationTestCandidateStartTpTestArgs = {
  testCandidateId: Scalars['Int'];
};


export type MutationTestCandidateUpdateOneArgs = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TestStatus>;
  testOrigin?: InputMaybe<TestOrigin>;
  timeModifierPercentage?: InputMaybe<Scalars['Float']>;
};


export type MutationTmTestCreateOneArgs = {
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  origin: TestOrigin;
  stageId: Scalars['Int'];
  subType?: InputMaybe<TestSubType>;
  tpProjectId?: InputMaybe<Scalars['String']>;
  type: TestType;
};


export type MutationTmTestUpdateOneArgs = {
  id: Scalars['Int'];
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<TestOrigin>;
  subType?: InputMaybe<TestSubType>;
  tpProjectId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TestType>;
};


export type MutationTraitCreateArgs = {
  description: Scalars['String'];
  name: Scalars['String'];
};


export type MutationTraitDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationTraitRoleLevelContentUpdateArgs = {
  content?: InputMaybe<Scalars['String']>;
  developmentContent?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  summary?: InputMaybe<Scalars['String']>;
};


export type MutationTraitSoftSkillContextCreateOneArgs = {
  behaviourIds?: InputMaybe<Array<Scalars['Int']>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  traitId: Scalars['Int'];
};


export type MutationTraitSoftSkillContextDeleteManyArgs = {
  behaviourIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  traitId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type MutationTraitSoftSkillContextDeleteOneArgs = {
  behaviourIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  traitId?: InputMaybe<Scalars['Int']>;
};


export type MutationTraitSoftSkillContextFileUploadArgs = {
  apply: Scalars['Boolean'];
  file: Scalars['Upload'];
};


export type MutationTraitTestTypeReplaceManyArgs = {
  dto: Array<TraitTestTypesUpdateOneArgs>;
};


export type MutationTraitUpdateArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationTraitUpdateIsArchivedArgs = {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationUserCompanyInfoCreateArgs = {
  companyId: Scalars['Int'];
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  userId: Scalars['Int'];
};


export type MutationUserCompanyInfoDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationUserCompanyInfoUpdateArgs = {
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  jobTitle?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
};


export type MutationUserCreateArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  isPasswordDisabled?: InputMaybe<Scalars['Boolean']>;
  isPuppet?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String'];
  locale?: InputMaybe<SupportedLocale>;
  password?: InputMaybe<Scalars['String']>;
};


export type MutationUserDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationUserDeleteByEmailArgs = {
  email: Scalars['String'];
};


export type MutationUserGroupCreateOneArgs = {
  companyId: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};


export type MutationUserGroupDeleteOneArgs = {
  subId: Scalars['String'];
};


export type MutationUserGroupMembersCreateManyArgs = {
  groupSubId: Scalars['String'];
  userIds: Array<Scalars['Int']>;
};


export type MutationUserGroupMembersDeleteManyArgs = {
  groupSubId: Scalars['String'];
  userIds: Array<Scalars['Int']>;
};


export type MutationUserGroupUpdateOneArgs = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  subId: Scalars['String'];
};


export type MutationUserRoleCreateArgs = {
  companyId: Scalars['Float'];
  externalId?: InputMaybe<Scalars['String']>;
  role: IdentityPermissionRole;
  userId: Scalars['Float'];
};


export type MutationUserRoleDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationUserRoleUpdateArgs = {
  companyId?: InputMaybe<Scalars['Float']>;
  externalId?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  role?: InputMaybe<IdentityPermissionRole>;
};


export type MutationUserSettingsCreateArgs = {
  settings: UserSettingsOptionsArgs;
  userId: Scalars['Int'];
};


export type MutationUserSettingsDeleteOneArgs = {
  id: Scalars['Int'];
};


export type MutationUserSettingsUpdateArgs = {
  id: Scalars['Int'];
  settings: UserSettingsOptionsArgs;
};


export type MutationUserUpdateArgs = {
  email?: InputMaybe<Scalars['String']>;
  externalId?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  isPasswordDisabled?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
};


export type MutationWidgetConfigCreateArgs = {
  reportConfigId: Scalars['Int'];
  settings?: InputMaybe<Scalars['String']>;
  widgetOptionId: Scalars['Int'];
};


export type MutationWidgetConfigDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationWidgetConfigUpdateArgs = {
  id: Scalars['Int'];
  settings?: InputMaybe<Scalars['String']>;
};


export type MutationWidgetConfigUpdateManyArgs = {
  widgetConfigUpdates: Array<WidgetConfigUpdateOneArgs>;
};


export type MutationWidgetCreateArgs = {
  name: Scalars['String'];
  type: WidgetType;
};


export type MutationWidgetDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationWidgetDeleteScreenShotArgs = {
  id: Scalars['Int'];
};


export type MutationWidgetOptionCreateArgs = {
  defaultValue?: InputMaybe<Scalars['String']>;
  fieldType: WidgetOptionFieldType;
  name: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
  widgetId: Scalars['Int'];
};


export type MutationWidgetOptionDeleteArgs = {
  id: Scalars['Int'];
};


export type MutationWidgetOptionUpdateArgs = {
  defaultValue?: InputMaybe<Scalars['String']>;
  fieldType: WidgetOptionFieldType;
  id: Scalars['Int'];
  name: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
};


export type MutationWidgetOptionUpdateIsArchivedArgs = {
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
};


export type MutationWidgetOptionUploadBrandedSoftSkillImageArgs = {
  file: Scalars['Upload'];
};


export type MutationWidgetOptionUploadCoverPageImageArgs = {
  file: Scalars['Upload'];
};


export type MutationWidgetUpdateArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<WidgetType>;
};


export type MutationWidgetUpdateIsArchivedArgs = {
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
};


export type MutationWidgetUploadScreenShotArgs = {
  file: Scalars['Upload'];
  id: Scalars['Int'];
};


export type MutationAuthenticateArgs = {
  clientType?: InputMaybe<ClientType>;
  email: Scalars['String'];
  mfaCookie?: InputMaybe<Array<Scalars['String']>>;
  password: Scalars['String'];
};


export type MutationAuthenticateApiArgs = {
  clientId: Scalars['String'];
  clientSecret?: InputMaybe<Scalars['String']>;
};


export type MutationCreateCompanyArgs = {
  expiresAt?: InputMaybe<Scalars['Date']>;
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationCreateProjectArgs = {
  companyId: Scalars['Float'];
  expiresAt?: InputMaybe<Scalars['Date']>;
  managers?: InputMaybe<Array<CreateProjectManagerInput>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  name: Scalars['String'];
  productSolution?: InputMaybe<ProductSolution>;
  requireSheetsExport?: InputMaybe<Scalars['Boolean']>;
};


export type MutationCreateProjectFileArgs = {
  downloadUrl: Scalars['String'];
  id: Scalars['Float'];
};


export type MutationDeauthenticateArgs = {
  accessToken: Scalars['String'];
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['Int'];
};


export type MutationEditProjectArgs = {
  companyId: Scalars['Float'];
  expiresAt?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  locales?: InputMaybe<Array<SupportedLocale>>;
  managers?: InputMaybe<Array<CreateProjectManagerInput>>;
  name: Scalars['String'];
  productSolution?: InputMaybe<ProductSolution>;
  requireSheetsExport?: InputMaybe<Scalars['Boolean']>;
};


export type MutationEditProjectArchivingArgs = {
  id: Scalars['Int'];
  state: Scalars['Boolean'];
};


export type MutationEditProjectCandidateAssigneesArgs = {
  candidateId: Scalars['Int'];
  projectId: Scalars['Int'];
  teamMemberIds: Array<Scalars['Int']>;
};


export type MutationEditProjectCandidateManagersArgs = {
  candidateId: Scalars['Int'];
  managerIds?: InputMaybe<Array<Scalars['Int']>>;
  projectId: Scalars['Int'];
};


export type MutationEditProjectCandidateReportStateArgs = {
  id: Scalars['Int'];
  renderCandidateReport: Scalars['Boolean'];
};


export type MutationEditProjectStateArgs = {
  id: Scalars['Int'];
  state: Scalars['Boolean'];
};


export type MutationIcimsIntegrationsCreateOneArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  companyId: Scalars['Int'];
  customerId: Scalars['String'];
  hostUrl?: InputMaybe<Scalars['String']>;
};


export type MutationIcimsIntegrationsDeleteOneArgs = {
  id: Scalars['String'];
};


export type MutationIcimsIntegrationsUpdateOneArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  clientSecret?: InputMaybe<Scalars['String']>;
  hostUrl?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
};


export type MutationMfaAccessTokenArgs = {
  clientDomainType: ClientDomainType;
  clientType?: InputMaybe<ClientType>;
  email: Scalars['String'];
  mfaCookie?: InputMaybe<Array<Scalars['String']>>;
  password: Scalars['String'];
};


export type MutationMfaAuthenticateArgs = {
  mfaAccessToken: Scalars['String'];
  mfaCode: Scalars['String'];
};


export type MutationProcessInvitationArgs = {
  invitationToken: Scalars['String'];
  subId: Scalars['String'];
};


export type MutationRegisterAccountArgs = {
  companyId?: InputMaybe<Scalars['Float']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  invitationToken?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Language>;
  lastName: Scalars['String'];
  locale?: InputMaybe<SupportedLocale>;
  password: Scalars['String'];
  projectId?: InputMaybe<Scalars['Float']>;
};


export type MutationRequestMfaCodeArgs = {
  clientDomainType: ClientDomainType;
  mfaAccessToken: Scalars['String'];
};


export type MutationRequestPasswordRecoveryArgs = {
  clientDomainType: ClientDomainType;
  email: Scalars['String'];
  language?: InputMaybe<Language>;
};


export type MutationRequestTokenRefreshArgs = {
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};


export type MutationRetriveTpManualCandidatesArgs = {
  candidateEmail: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  inductiveTpAssessmentId?: InputMaybe<Scalars['Int']>;
  lastName?: InputMaybe<Scalars['String']>;
  numericalTpAssessmentId?: InputMaybe<Scalars['Int']>;
  personalityTpAssessmentId?: InputMaybe<Scalars['Int']>;
  stageId: Scalars['Int'];
  verbalTpAssessmentId?: InputMaybe<Scalars['Int']>;
};


export type MutationSetIndirectInviteSsoArgs = {
  id: Scalars['Int'];
  indirectInviteSSO: Scalars['Boolean'];
};


export type MutationSetProjectCobrandingArgs = {
  cobrandingEnabled: Scalars['Boolean'];
  id: Scalars['Int'];
};


export type MutationStackOneIntegrationsCreateOneArgs = {
  accountId: Scalars['String'];
  companyId: Scalars['Int'];
  originOwnerId: Scalars['String'];
  originOwnerName: Scalars['String'];
  originUsername?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};


export type MutationStackOneIntegrationsDeleteOneArgs = {
  id: Scalars['String'];
};


export type MutationStackOneIntegrationsUpdateOneArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  originOwnerId?: InputMaybe<Scalars['String']>;
  originOwnerName?: InputMaybe<Scalars['String']>;
  originUsername?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['String']>;
};


export type MutationStackOneLinkProjectArgs = {
  companyId: Scalars['Int'];
  jobIds: Array<Scalars['String']>;
  projectId: Scalars['Int'];
};


export type MutationStackOneStartJobsExportArgs = {
  companyId: Scalars['Int'];
};


export type MutationUpdateCompanyArgs = {
  expiresAt?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
};


export type MutationUpdateIdentityPasswordArgs = {
  newPassword: Scalars['String'];
  recoveryToken: Scalars['String'];
};


export type MutationUploadCompanyLogoArgs = {
  file: Scalars['Upload'];
  id: Scalars['Int'];
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
};

export type NewStructuredScoreModel = {
  __typename?: 'NewStructuredScoreModel';
  children?: Maybe<Array<NewStructuredScoreModel>>;
  copiedFrom?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  manuallyAdjustedBy?: Maybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  order?: Maybe<Scalars['Int']>;
  previousVersionId?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  projectJobRoleId?: Maybe<Scalars['Int']>;
  score: ResultScoreUnion;
  scoreType: ResultScoreType;
  updatedAt: Scalars['Date'];
  version: Scalars['Int'];
};

export type NormGroupModel = {
  __typename?: 'NormGroupModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  jobRoleFamily?: Maybe<JobRoleFamily>;
  roleLevel?: Maybe<ContentRoleLevel>;
  trait: Trait;
  traitId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export enum NormGroupType {
  AdministrativeStaff = 'ADMINISTRATIVE_STAFF',
  CogAdministrativeOperationalNonGraduate = 'COG_ADMINISTRATIVE_OPERATIONAL_NON_GRADUATE',
  CogApprenticesSchoolLeaversEntryLevelStaff = 'COG_APPRENTICES_SCHOOL_LEAVERS_ENTRY_LEVEL_STAFF',
  CogGradsManagersProfessionalsExecutives = 'COG_GRADS_MANAGERS_PROFESSIONALS_EXECUTIVES',
  Executives = 'EXECUTIVES',
  Finance = 'FINANCE',
  FirstLineManagers = 'FIRST_LINE_MANAGERS',
  GeneralPopulation = 'GENERAL_POPULATION',
  Graduates = 'GRADUATES',
  HealthProfessionals = 'HEALTH_PROFESSIONALS',
  Hr = 'HR',
  LegalProfessionals = 'LEGAL_PROFESSIONALS',
  ManagementConsultants = 'MANAGEMENT_CONSULTANTS',
  MarketingProfessionals = 'MARKETING_PROFESSIONALS',
  MiddleManagers = 'MIDDLE_MANAGERS',
  SalesProfessionals = 'SALES_PROFESSIONALS',
  Stem = 'STEM',
  Teaching = 'TEACHING'
}

export type NumericalOptionModel = {
  __typename?: 'NumericalOptionModel';
  key: Scalars['Int'];
  subId: Scalars['String'];
  text: Scalars['String'];
};

export type NumericalQuestionModel = {
  __typename?: 'NumericalQuestionModel';
  difficulty: Scalars['Float'];
  options: Array<NumericalOptionModel>;
  subId: Scalars['String'];
  text: Scalars['String'];
};

export type NumericalTestletModel = {
  __typename?: 'NumericalTestletModel';
  imageUrl: Scalars['String'];
  isCompleted: Scalars['Boolean'];
  questions: Array<NumericalQuestionModel>;
  remainingQuestions: Scalars['Int'];
  remainingTime: Scalars['Int'];
  /** @deprecated Please use new property `isCompleted` if you wish to know whether the assessment is completed or not! */
  testCandidateStatus: TestStatus;
  timeLimit: Scalars['Int'];
  totalQuestions: Scalars['Int'];
};

export type OffsetBasedPaginationArgs = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type OptionModel = {
  __typename?: 'OptionModel';
  key: Scalars['Int'];
  subId: Scalars['String'];
};

export type OrderByArgs = {
  direction?: InputMaybe<OrderByDirection>;
  nullLow?: InputMaybe<Scalars['Boolean']>;
  priority: Scalars['Int'];
};

export enum OrderByDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type OrderStageInput = {
  order: Scalars['Int'];
  stageId: Scalars['Int'];
};

export enum Orientation {
  Inverted = 'INVERTED',
  Standard = 'STANDARD'
}

export type Pageinfo = {
  __typename?: 'Pageinfo';
  currentPage: Scalars['Int'];
  itemsOnPage: Scalars['Int'];
  itemsTotal: Scalars['Int'];
  pageTotal: Scalars['Int'];
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
};

export type PaginatedBaseMethodTestModel = {
  __typename?: 'PaginatedBaseMethodTestModel';
  baseMethodTests: Array<BaseMethodTest>;
  total: Scalars['Int'];
};

export type PaginatedEaStageActionModel = {
  __typename?: 'PaginatedEAStageActionModel';
  eaStageActions: Array<EaStageActionModel>;
  total: Scalars['Int'];
};

export type PaginatedExternalAssessmentModel = {
  __typename?: 'PaginatedExternalAssessmentModel';
  externalAssessments: Array<ExternalAssessmentModel>;
  total: Scalars['Int'];
};

export type PaginatedProjectsModel = {
  __typename?: 'PaginatedProjectsModel';
  data?: Maybe<Array<Project>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type PaginatedSkillCategory = {
  __typename?: 'PaginatedSkillCategory';
  skillCategories: Array<SkillCategory>;
  total: Scalars['Int'];
};

export type PaginatedSzSkillsMerge = {
  __typename?: 'PaginatedSzSkillsMerge';
  skills: Array<SzSkillsMergeWithAvailability>;
  total: Scalars['Int'];
};

export type PaginatedTechnicalSkill = {
  __typename?: 'PaginatedTechnicalSkill';
  technicalSkills: Array<TechnicalSkill>;
  total: Scalars['Int'];
};

export type PersonalityItemModel = {
  __typename?: 'PersonalityItemModel';
  isCompleted: Scalars['Boolean'];
  questions: Array<PersonalityQuestionModel>;
  remainingQuestions: Scalars['Int'];
  /** @deprecated Please use new property `isCompleted` if you wish to know whether the assessment is completed or not! */
  testCandidateStatus: TestStatus;
  totalQuestions: Scalars['Int'];
};

export type PersonalityOptionModel = {
  __typename?: 'PersonalityOptionModel';
  key: Scalars['Int'];
  subId: Scalars['String'];
  text: Scalars['String'];
  value: Scalars['Float'];
};

export type PersonalityQuestionModel = {
  __typename?: 'PersonalityQuestionModel';
  difficulty: Scalars['Float'];
  options: Array<PersonalityOptionModel>;
  orientation: QuestionOrientation;
  subId: Scalars['String'];
  text: Scalars['String'];
};

export enum PollingStatus {
  Error = 'ERROR',
  Generating = 'GENERATING',
  Idling = 'IDLING',
  Ready = 'READY'
}

export enum ProductSolution {
  EarlyCareerHiring = 'EARLY_CAREER_HIRING',
  Learning = 'LEARNING',
  ProfessionalHiring = 'PROFESSIONAL_HIRING',
  Reskilling = 'RESKILLING',
  SuccessionPlanning = 'SUCCESSION_PLANNING',
  VolumeHiring = 'VOLUME_HIRING'
}

export type Project = {
  __typename?: 'Project';
  company?: Maybe<ProjectCompany>;
  companyId?: Maybe<Scalars['Float']>;
  companySignatureId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  creatorId: Scalars['Int'];
  deletedAt?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  indirectInviteSSOEnabled?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isStopped: Scalars['Boolean'];
  locales: Array<SupportedLocale>;
  /** @deprecated Please use "teamMembers" instead! */
  managers?: Maybe<Array<ProjectManager>>;
  moduleType: ProjectModuleType;
  name: Scalars['String'];
  productSolution?: Maybe<ProductSolution>;
  projectRequest?: Maybe<ProjectRequestModel>;
  renderCandidateReport: Scalars['Boolean'];
  requireSheetsExport: Scalars['Boolean'];
  subId: Scalars['String'];
  teamMembers?: Maybe<Array<ProjectManager>>;
  updatedAt: Scalars['String'];
  version: Scalars['Float'];
};

export enum ProjectCandidateGroupedStatus {
  Completed = 'COMPLETED',
  Invited = 'INVITED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  SignedOff = 'SIGNED_OFF'
}

export type ProjectCandidateManagerModel = {
  __typename?: 'ProjectCandidateManagerModel';
  candidateId: Scalars['Int'];
  managerId: Scalars['Int'];
  projectId: Scalars['Int'];
};

export enum ProjectCandidateStatus {
  Completed = 'COMPLETED',
  Invited = 'INVITED',
  InvitedBounced = 'INVITED_BOUNCED',
  InvitedClicked = 'INVITED_CLICKED',
  InvitedDelivered = 'INVITED_DELIVERED',
  InvitedOpened = 'INVITED_OPENED',
  InvitedPending = 'INVITED_PENDING',
  InviteAcccepted = 'INVITE_ACCCEPTED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  SignedOff = 'SIGNED_OFF'
}

export type ProjectCobrandingModel = {
  __typename?: 'ProjectCobrandingModel';
  companyLogoUrl?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  name: Scalars['String'];
};

export type ProjectCollaborator = {
  __typename?: 'ProjectCollaborator';
  /** The decorative role that user got assigned for this particular project. Optional. */
  actorRole?: Maybe<ActorRole>;
  group?: Maybe<ProjectCollaboratorGroup>;
  groupId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  user?: Maybe<ProjectCollaboratorUser>;
  userId?: Maybe<Scalars['Int']>;
};

export type ProjectCollaboratorArgs = {
  collaboratorType?: InputMaybe<ProjectCollaboratorType>;
  membersPreviewCount?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
};

export type ProjectCollaboratorCreateOneArgs = {
  actorRole?: InputMaybe<ActorRole>;
  groupId?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
  userId?: InputMaybe<Scalars['Int']>;
};

export type ProjectCollaboratorDeleteManyResult = {
  __typename?: 'ProjectCollaboratorDeleteManyResult';
  collaboratorId: Scalars['Int'];
  error?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type ProjectCollaboratorGroup = {
  __typename?: 'ProjectCollaboratorGroup';
  companyId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  membersCount: Scalars['Int'];
  membersPreview: Array<ProjectCollaboratorGroupMembersPreview>;
  name: Scalars['String'];
  subId: Scalars['String'];
};

export type ProjectCollaboratorGroupMembersPreview = {
  __typename?: 'ProjectCollaboratorGroupMembersPreview';
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export enum ProjectCollaboratorOrderField {
  FirstName = 'FIRST_NAME'
}

export type ProjectCollaboratorOrderOptions = {
  direction: SortOption;
  field: ProjectCollaboratorOrderField;
};

export type ProjectCollaboratorPaginated = {
  __typename?: 'ProjectCollaboratorPaginated';
  data?: Maybe<Array<ProjectCollaborator>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type ProjectCollaboratorRole = {
  __typename?: 'ProjectCollaboratorRole';
  /** The decorative role that user got assigned for this particular project. Optional. */
  actorRole?: Maybe<ActorRole>;
  groupId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  projectId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
};

export enum ProjectCollaboratorType {
  Group = 'GROUP',
  User = 'USER'
}

export type ProjectCollaboratorUser = {
  __typename?: 'ProjectCollaboratorUser';
  avatarUrl?: Maybe<Scalars['String']>;
  /** Array of actual access roles that user holds for company that owns project */
  companyRoles?: Maybe<Array<Maybe<IdentityPermissionRole>>>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type ProjectCompany = {
  __typename?: 'ProjectCompany';
  id: Scalars['Int'];
  logoUrl?: Maybe<Scalars['String']>;
  managerReportEmailLinks: Scalars['Boolean'];
  name: Scalars['String'];
};

export type ProjectCompanyArgs = {
  projectOrCompanyNameContains?: InputMaybe<Scalars['String']>;
};

export type ProjectCompanyFilterOptions = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  createdAtFrom?: InputMaybe<Scalars['Date']>;
  createdAtTo?: InputMaybe<Scalars['Date']>;
  editedAtFrom?: InputMaybe<Scalars['Date']>;
  editedAtTo?: InputMaybe<Scalars['Date']>;
  lastActivityFrom?: InputMaybe<Scalars['Date']>;
  lastActivityTo?: InputMaybe<Scalars['Date']>;
  showArchived?: InputMaybe<Scalars['Boolean']>;
  /** this is related to if project is stopped. statuses: [false, true] will fetch open and closed projects. statues [false] will only fetch non-stopped projects. statuses: [true] will only fetch stopped projects */
  statuses?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type ProjectCompanyId = {
  __typename?: 'ProjectCompanyId';
  companyId: Scalars['Int'];
};

export enum ProjectCompanyOrderField {
  CompanyName = 'COMPANY_NAME',
  ProjectCreatedAt = 'PROJECT_CREATED_AT',
  ProjectEditedAt = 'PROJECT_EDITED_AT',
  ProjectLastActivity = 'PROJECT_LAST_ACTIVITY',
  ProjectName = 'PROJECT_NAME',
  ProjectStatus = 'PROJECT_STATUS'
}

export type ProjectCompanyOrderOptions = {
  direction: SortOption;
  field: ProjectCompanyOrderField;
};

export type ProjectFile = {
  __typename?: 'ProjectFile';
  downloadUrl: Scalars['String'];
  id: Scalars['Int'];
};

export type ProjectJobRoleModel = {
  __typename?: 'ProjectJobRoleModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isLocalizedBenchmarkGroup?: Maybe<Scalars['Boolean']>;
  jobRole: JobRoleModel;
  jobRoleId: Scalars['Int'];
  project: Project;
  projectId: Scalars['Int'];
  skillsProfile: SuccessProfile;
  skillsProfileId: Scalars['Int'];
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type ProjectManager = {
  __typename?: 'ProjectManager';
  /** The decorative role that user got assigned for this particular project. Optional. */
  actorRole?: Maybe<ActorRole>;
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  group?: Maybe<UserGroupModel>;
  groupId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  projectId: Scalars['Int'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
};

export enum ProjectModuleType {
  Hiring = 'HIRING',
  TalentReview = 'TALENT_REVIEW'
}

export type ProjectRequestFileUploadArgs = {
  fileName: Scalars['String'];
  projectRequestId: Scalars['Int'];
};

export type ProjectRequestFileUploadModel = {
  __typename?: 'ProjectRequestFileUploadModel';
  blob?: Maybe<Blob>;
  blobId: Scalars['Int'];
  fileName: Scalars['String'];
  id: Scalars['Int'];
  projectRequest?: Maybe<ProjectRequestModel>;
  projectRequestId?: Maybe<Scalars['Int']>;
};

export type ProjectRequestModel = {
  __typename?: 'ProjectRequestModel';
  approvalRequestDate?: Maybe<Scalars['Date']>;
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  creator?: Maybe<User>;
  creatorId: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  managerSurveyId?: Maybe<Scalars['String']>;
  projectId?: Maybe<Scalars['Int']>;
  projectName: Scalars['String'];
  status: ProjectRequestStatus;
  updatedAt: Scalars['Date'];
};

export enum ProjectRequestStatus {
  ConfirmMatch = 'CONFIRM_MATCH',
  JobSpecsRecieved = 'JOB_SPECS_RECIEVED',
  ProjectCreated = 'PROJECT_CREATED',
  RequiresProject = 'REQUIRES_PROJECT'
}

export type ProjectRequestUserApprovalModel = {
  __typename?: 'ProjectRequestUserApprovalModel';
  approvalRequestDate: Scalars['Date'];
  approvalStatus: ProjectRequestUserApprovalStatus;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export enum ProjectRequestUserApprovalStatus {
  Approved = 'APPROVED',
  Denied = 'DENIED',
  Sent = 'SENT'
}

export type ProjectRequestUserModel = {
  __typename?: 'ProjectRequestUserModel';
  approval?: Maybe<ProjectRequestUserApprovalModel>;
  approvalId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  projectRequest?: Maybe<ProjectRequestModel>;
  projectRequestId?: Maybe<Scalars['Int']>;
  type: ProjectRequestUserType;
  user?: Maybe<User>;
  userId: Scalars['Int'];
};

export enum ProjectRequestUserType {
  AdditionalContact = 'ADDITIONAL_CONTACT',
  Manager = 'MANAGER',
  Recruiter = 'RECRUITER'
}

export type ProjectUser = {
  __typename?: 'ProjectUser';
  actorRole?: Maybe<ActorRole>;
  project: Project;
  user: User;
};

export type ProjectWithStageCandidates = {
  __typename?: 'ProjectWithStageCandidates';
  company?: Maybe<ProjectCompany>;
  companyId?: Maybe<Scalars['Float']>;
  companySignatureId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['String'];
  creatorId: Scalars['Int'];
  deletedAt?: Maybe<Scalars['String']>;
  demographicsDataCollected: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  indirectInviteSSOEnabled?: Maybe<Scalars['Boolean']>;
  isArchived: Scalars['Boolean'];
  isStopped: Scalars['Boolean'];
  locales: Array<SupportedLocale>;
  /** @deprecated Please use "teamMembers" instead! */
  managers?: Maybe<Array<ProjectManager>>;
  moduleType: ProjectModuleType;
  name: Scalars['String'];
  numberOfCompletedInTimeFrameStageCandidates: Scalars['Int'];
  numberOfCompletedStageCandidates: Scalars['Int'];
  numberOfStageCandidates: Scalars['Int'];
  percentageOfCompletedStageCandidates: Scalars['Float'];
  productSolution?: Maybe<ProductSolution>;
  projectRequest?: Maybe<ProjectRequestModel>;
  projectTeamEmailReportFormat: Scalars['String'];
  renderCandidateReport: Scalars['Boolean'];
  requireSheetsExport: Scalars['Boolean'];
  stageCandidates: Array<SmStageCandidateModel>;
  subId: Scalars['String'];
  teamMembers?: Maybe<Array<ProjectManager>>;
  updatedAt: Scalars['String'];
  version: Scalars['Float'];
};

export type QCalcDisplayedAnswerUnion = QCalcInductiveDisplayedAnswerModel | QCalcNumericalDisplayedTestletModel | QCalcPersonalityDisplayedTraitAnswersModel | QCalcTrDisplayedTraitAnswersModel | QCalcVerbalDisplayedTestletModel;

export type QCalcInductiveDisplayedAnswerModel = {
  __typename?: 'QCalcInductiveDisplayedAnswerModel';
  ability: Scalars['Float'];
  answerOptionImageUrl?: Maybe<Scalars['String']>;
  correctOptionImageUrl: Scalars['String'];
  inTime: Scalars['Boolean'];
  isCorrect: Scalars['Boolean'];
  percentile: Scalars['Float'];
  questionDifficulty: Scalars['Float'];
  questionImageUrls: Array<Scalars['String']>;
  zScore: Scalars['Float'];
};

export type QCalcNumericalDisplayedTestletModel = {
  __typename?: 'QCalcNumericalDisplayedTestletModel';
  ability: Scalars['Float'];
  displayedAnswers: Array<QCalcTestletDisplayedAnswerModel>;
  imageUrl: Scalars['String'];
  percentile: Scalars['Float'];
  zScore: Scalars['Float'];
};

export type QCalcPersonalityDisplayedAnswerModel = {
  __typename?: 'QCalcPersonalityDisplayedAnswerModel';
  ability?: Maybe<Scalars['Float']>;
  answerText?: Maybe<Scalars['String']>;
  answerValue?: Maybe<Scalars['Float']>;
  grade?: Maybe<Grade>;
  percentile?: Maybe<Scalars['Float']>;
  questionDifficulty?: Maybe<Scalars['Float']>;
  questionOrientation: QuestionOrientation;
  questionText: Scalars['String'];
  stenScore?: Maybe<Scalars['Float']>;
  zScore?: Maybe<Scalars['Float']>;
};

export type QCalcPersonalityDisplayedTraitAnswersModel = {
  __typename?: 'QCalcPersonalityDisplayedTraitAnswersModel';
  displayedAnswers: Array<QCalcPersonalityDisplayedAnswerModel>;
  trait: Trait;
  traitId: Scalars['Int'];
};

export type QCalcResponse = InductiveItemModel | NumericalTestletModel | PersonalityItemModel | TrItemModel | VerbalTestletModel;

export type QCalcTestletDisplayedAnswerModel = {
  __typename?: 'QCalcTestletDisplayedAnswerModel';
  answerText?: Maybe<Scalars['String']>;
  correctOptionText: Scalars['String'];
  inTime: Scalars['Boolean'];
  isCorrect: Scalars['Boolean'];
  questionDifficulty: Scalars['Float'];
  questionText: Scalars['String'];
};

export type QCalcTrDisplayedAnswerModel = {
  __typename?: 'QCalcTrDisplayedAnswerModel';
  answerText?: Maybe<Scalars['String']>;
  answerValue?: Maybe<Scalars['Float']>;
  questionOrientation: QuestionOrientation;
  questionText: Scalars['String'];
  score: Scalars['Float'];
};

export type QCalcTrDisplayedTraitAnswersModel = {
  __typename?: 'QCalcTrDisplayedTraitAnswersModel';
  displayedAnswers: Array<QCalcTrDisplayedAnswerModel>;
  trait: Trait;
  traitId: Scalars['Int'];
};

export type QCalcVerbalDisplayedTestletModel = {
  __typename?: 'QCalcVerbalDisplayedTestletModel';
  ability: Scalars['Float'];
  displayedAnswers: Array<QCalcTestletDisplayedAnswerModel>;
  mainQuestionText: Scalars['String'];
  percentile: Scalars['Float'];
  zScore: Scalars['Float'];
};

export type QCalcVisualisedAssessmentModel = {
  __typename?: 'QCalcVisualisedAssessmentModel';
  entries: Array<QCalcDisplayedAnswerUnion>;
};

export type Query = {
  __typename?: 'Query';
  Answer?: Maybe<Answer>;
  Answers?: Maybe<Array<Answer>>;
  ApiKeyCompanyFindMany: Array<AmApiKeyModel>;
  ApiKeyFindAll: Array<AmApiKeyModel>;
  ApiKeyUserFindOne?: Maybe<AmApiKeyModel>;
  ApiUserFindMany?: Maybe<Array<Maybe<ApiUserModel>>>;
  ApiUserFindOne?: Maybe<ApiUserModel>;
  AssessmentDataFindMany: Array<Maybe<AssessmentDataModel>>;
  AssessmentDataFindOne?: Maybe<AssessmentDataModel>;
  BaseMethodTest?: Maybe<BaseMethodTest>;
  BaseMethodTestFindMany: Array<BaseMethodTest>;
  BaseMethodTestFindManySearchPaginated: PaginatedBaseMethodTestModel;
  BaseMethodTestFindOne?: Maybe<BaseMethodTest>;
  BaseMethodTests: Array<BaseMethodTest>;
  BehaviourContentFindOne: BehaviourContentModel;
  BehaviourFindOne?: Maybe<BehaviourModel>;
  BlobFindById: Blob;
  BlobFindByName?: Maybe<Blob>;
  BlobFindCMCandidateResultsCSV?: Maybe<Blob>;
  CalibrationCandidateFindMany: Array<CalibrationCandidateModel>;
  CalibrationCandidateFindOne?: Maybe<CalibrationCandidateModel>;
  CalibrationConfigExtendedGenerateOne: CalibrationConfigExtendedModel;
  CalibrationConfigFindOne?: Maybe<CalibrationConfigModel>;
  CalibrationConfigGenerateMany: Array<CalibrationConfigModel>;
  CalibrationConfigGenerateOne?: Maybe<CalibrationConfigModel>;
  CmAllowedArea?: Maybe<CmAllowedArea>;
  CmAllowedCompanies: Array<CmAllowedCompanyModel>;
  CmAllowedProject?: Maybe<CmAllowedProject>;
  CmCandidateResultFindAndCSV?: Maybe<Blob>;
  CmCandidateResultFindAndPaginate?: Maybe<CmCandidateResultModelPaginated>;
  CmCandidateResultFindMany?: Maybe<Array<CmCandidateResult>>;
  CmDepartments?: Maybe<Array<Scalars['String']>>;
  CmProjectCandidateManagers?: Maybe<Array<CmProjectCandidateManagerModel>>;
  CmProjectFindMany?: Maybe<Array<CmProject>>;
  CmProjectFindManyPaginated?: Maybe<CmProjectModelPaginated>;
  CmProjectManagers?: Maybe<Array<CmProjectManagerModel>>;
  CmResultScoreDisplayableKeysFindOne: Array<Scalars['String']>;
  CmTalentReview?: Maybe<CmTalentReviewGridChartModel>;
  CmTalentReviewGridChartBasic?: Maybe<CmTalentReviewGridChartBasicModel>;
  CmTalentReviewGridChartFilterOptionsFindMany?: Maybe<CmTalentReviewGridChartFilterOptionsModel>;
  CompanyAllowedEmailDomains?: Maybe<Array<Scalars['String']>>;
  CompanyEmployee?: Maybe<CompanyEmployeeModel>;
  CompanyEmployeeCompanyWriteAccess?: Maybe<Array<Scalars['Int']>>;
  CompanyEmployeeFindMany?: Maybe<Array<CompanyEmployeeModel>>;
  CompanyEmployeeFindOne?: Maybe<CompanyEmployeeModel>;
  CompanyEmployeeFreeTextSearchPaginate?: Maybe<CompanyEmployeeModelPaginated>;
  CompanyEmployees?: Maybe<Array<CompanyEmployeeModel>>;
  CompanyFindManyAndPaginate: CompanyPaginatedModel;
  CompanyFindManyUsersWithRoles: CompanyUserInfoPaginated;
  CompanyFindUsers: Array<Maybe<User>>;
  CompanyFindUsersPaginated: UserModelPaginated;
  CompanyMaybeUserEmployeeFindMany?: Maybe<Array<CompanyMaybeUserEmployeeModel>>;
  CompanyRole?: Maybe<CompanyRole>;
  CompanyRoles: Array<CompanyRole>;
  CompanySignature?: Maybe<CompanySignatureModel>;
  CompanySignatureFindMany?: Maybe<Array<CompanySignatureModel>>;
  CompanySignatureFindOne?: Maybe<CompanySignatureModel>;
  CompanySignatures?: Maybe<Array<CompanySignatureModel>>;
  CompanyUsersFindManyPaginated: CompanyUserModelPaginated;
  ContentViewerViewStageCandidateManagerReport: ContentViewerViewStageCandidateManagerReportModel;
  ContentViewerViewStageCandidateReport: ContentViewerViewStageCandidateReportModel;
  DeiStageAction?: Maybe<DeiStageActionModel>;
  DeiStageActionTemplate?: Maybe<DeiStageActionTemplateModel>;
  DynamicEnum?: Maybe<Scalars['String']>;
  /** Get list of all the available external assessment platforms */
  EAPlatformFindMany: Array<ExternalPlatformAdapterModel>;
  /** Query the stage action for external assessments. */
  EAResultFindMany: Array<EaResultUniversalModel>;
  /** Query the stage action for external assessments. */
  EAResultFindOne?: Maybe<EaResultUniversalModel>;
  /** Check availability of the stage action of the external assessment process in the project stage. This method checks if there is an existing stage action for given stage and company, and it returns the stage action if it exists. If it doesn't exist, it checks if there are any external assessments for given skills (if any are provided). Note that this doesn't prevent you from creating a stage action for the external assessment process in the project stage, but it means you will probably not find an exact match of the external assessments for your skills. In case there is already stage action for given stage and company, it will return the stage action and set the available flag to true. */
  EAStageActionAvailable: EaStageActionAvailabilityModel;
  /** Find many external assessment stage action candidates and return them. */
  EAStageActionCandidateFindMany: Array<EaStageActionCandidateModel>;
  /** Find many external assessment stage action candidates and return them with the invitation links. */
  EAStageActionCandidateFindManyWithInvitationLink: Array<EaStageActionCandidateModel>;
  /** Query the stage action for external assessments. */
  EAStageActionFindMany: Array<EaStageActionModel>;
  /** Query the stage action for external assessments. (paginated) */
  EAStageActionFindManyPaginated: PaginatedEaStageActionModel;
  /** Query the stage action for external assessments. */
  EAStageActionFindOne?: Maybe<EaStageActionModel>;
  /** @deprecated Use Project.locales */
  EntityLanguageFindMany?: Maybe<Array<EntityLanguageModel>>;
  /** Query the external assessments in the bank. */
  ExternalAssessmentFindMany: Array<ExternalAssessmentModel>;
  /** Query the external assessments in the bank, paginated. */
  ExternalAssessmentFindManyPaginated: PaginatedExternalAssessmentModel;
  /** Query the external assessments in the bank. */
  ExternalAssessmentFindOne?: Maybe<ExternalAssessmentModel>;
  /** View and search for available assessments from the external platform. */
  ExternalPlatformAssessmentFindMany: Array<ExternalPlatformAssessmentModel>;
  /** View an assessment from the external platform. */
  ExternalPlatformAssessmentFindOne?: Maybe<ExternalPlatformAssessmentModel>;
  Field?: Maybe<Field>;
  Fields?: Maybe<Array<Field>>;
  FindManyAssessmentBrowserSwitchCandidateTrackingEvent?: Maybe<Array<AssessmentCandidateBrowserSwitchTrackingEventModel>>;
  FindOneAssessmentBrowserSwitchCandidateTrackingEvent?: Maybe<AssessmentCandidateBrowserSwitchTrackingEventModel>;
  FindOneROICsvFileExportScheduledTask: FileExportScheduledTask;
  FindOneStageCandidateResultsCsvFileExportScheduledTask: FileExportScheduledTask;
  FixContentPiece?: Maybe<ContentValidatiorPieceModel>;
  Form?: Maybe<Form>;
  FormBuilder?: Maybe<FormBuilder>;
  FormBuilderFindByOwnerAndType?: Maybe<FormBuilder>;
  FormCandidateFindMany: Array<FormCandidateModel>;
  FormCandidateFindOne?: Maybe<FormCandidateModel>;
  FormReportFindAssociatedIds?: Maybe<FormReportIndividualQueries>;
  FormReportFindFormManagerTypes?: Maybe<FormReportIndividualQueries>;
  FormReportFindFormOwners?: Maybe<Array<FormReportFormOwners>>;
  FormReportFindFormTypes?: Maybe<FormReportIndividualQueries>;
  Forms?: Maybe<Array<Form>>;
  GetAuthenticationMethods: Array<AuthenticationMethodModel>;
  GetCurrentProjectCollaborator?: Maybe<ProjectCollaborator>;
  GetDlxQueueMessageCount?: Maybe<Scalars['Float']>;
  GetInterviewFeedbackSummary: Array<InterviewFeedbackSummaryModel>;
  GetStageActionSummaries: Array<StageActionSummary>;
  GetStageActions: StageActionsModel;
  GetStageCards: Array<StageCardModel>;
  HiringDecisionFindLatestHiringDecisionsForStageCandidate: Array<HiringDecisionModel>;
  HiringDecisionSkillReviewsFindOne: HiringDecisionSkillReviewsModel;
  IdentityProviderFindMany?: Maybe<Array<IdentityProviderModel>>;
  IdentityProviderFindOne?: Maybe<IdentityProviderModel>;
  IgCategories?: Maybe<Array<IgCategory>>;
  IgCategory?: Maybe<IgCategory>;
  IgCategoryFindMany?: Maybe<Array<IgCategory>>;
  IgCategoryFindOne?: Maybe<IgCategory>;
  IgInterviewGuide?: Maybe<IgInterviewGuide>;
  IgInterviewGuideCategories?: Maybe<Array<IgInterviewGuideCategory>>;
  IgInterviewGuideCategory?: Maybe<IgInterviewGuideCategory>;
  IgInterviewGuideCategoryFindMany?: Maybe<Array<IgInterviewGuideCategory>>;
  IgInterviewGuideCategoryFindOne?: Maybe<IgInterviewGuideCategory>;
  IgInterviewGuideFindMany?: Maybe<Array<IgInterviewGuide>>;
  IgInterviewGuideFindOne?: Maybe<IgInterviewGuide>;
  IgInterviewGuides?: Maybe<Array<IgInterviewGuide>>;
  IgRenderFindOne?: Maybe<IgRenderModel>;
  IgStep?: Maybe<IgStep>;
  IgStepFindMany?: Maybe<Array<IgStep>>;
  IgStepFindOne?: Maybe<IgStep>;
  IgSteps?: Maybe<Array<IgStep>>;
  IgTabRenderRefineModifiedQuestionFindOne: InterviewGuideModifiedQuestionResponseModel;
  InterviewGuideBehaviourFindMany: Array<InterviewGuideBehaviourModel>;
  InterviewGuideFeedbackFindOne?: Maybe<InterviewGuideFeedbackModel>;
  InterviewGuideFindOne: InterviewGuideModel;
  InterviewGuideGeneratedFeedbackFindOne: IgGeneratedFeedbackModel;
  InterviewGuidePageStatusFindOne?: Maybe<InterviewGuidePageStatusModel>;
  InterviewGuideRecipientFindMany: Array<IgRenderRecipientModel>;
  InterviewGuideRecipientUniqueFindMany: Array<IgRenderRecipientModel>;
  InterviewGuideSSDescriptionFindMany: IgSoftSkillDescriptionModel;
  InterviewQuestionContentFindOne?: Maybe<InterviewQuestionContentModel>;
  InterviewQuestionReasonContentFindOne?: Maybe<InterviewQuestionReasonContentModel>;
  InterviewQuestionRoleLevelContent: InterviewQuestionRoleLevel;
  InterviewQuestionRoleLevelContentFindManyAndCount: InterviewQuestionRoleLevelPaginatedModel;
  InterviewQuestionRoleLevelContents: Array<InterviewQuestionRoleLevel>;
  Invitation?: Maybe<InvitationModel>;
  InvitationFindMany?: Maybe<Array<InvitationModel>>;
  InvitationFindOne?: Maybe<InvitationModel>;
  InvitationSessionFindManyPaginated: InvitationSessionPaginated;
  InvitationSessionFindOne: InvitationSession;
  Invitations?: Maybe<Array<InvitationModel>>;
  JobRoleFindMany: Array<JobRoleModel>;
  JobRoleFindOne?: Maybe<JobRoleModel>;
  LocationLinkFindMany: Array<LocationLinkModel>;
  ProjectCollaboratorsFindManyPaginated?: Maybe<ProjectCollaboratorPaginated>;
  ProjectFindMany?: Maybe<Array<Project>>;
  ProjectFindManyByCompany: Array<Project>;
  ProjectFindManyPaginated: PaginatedProjectsModel;
  ProjectFindManysByCompanyWithStageCandidateRange: Array<CompanyProjects>;
  ProjectFindManysByCompanyWithStageCandidateRangeCsv: Blob;
  ProjectJobRoleFindMany: Array<ProjectJobRoleModel>;
  ProjectJobRoleFindOne?: Maybe<ProjectJobRoleModel>;
  ProjectRequest?: Maybe<ProjectRequestModel>;
  ProjectRequestFileUpload?: Maybe<ProjectRequestFileUploadModel>;
  ProjectRequestFileUploadFindMany: Array<ProjectRequestFileUploadModel>;
  ProjectRequestFileUploadFindOne?: Maybe<ProjectRequestFileUploadModel>;
  ProjectRequestFileUploads: Array<ProjectRequestFileUploadModel>;
  ProjectRequestFindMany?: Maybe<Array<ProjectRequestModel>>;
  ProjectRequestFindOne?: Maybe<ProjectRequestModel>;
  ProjectRequestUser?: Maybe<ProjectRequestUserModel>;
  ProjectRequestUserApproval?: Maybe<ProjectRequestUserApprovalModel>;
  ProjectRequestUserApprovalFindMany: Array<ProjectRequestUserApprovalModel>;
  ProjectRequestUserApprovalFindOne?: Maybe<ProjectRequestUserApprovalModel>;
  ProjectRequestUserApprovals: Array<ProjectRequestUserApprovalModel>;
  ProjectRequestUserFindMany: Array<ProjectRequestUserModel>;
  ProjectRequestUserFindOne?: Maybe<ProjectRequestUserModel>;
  ProjectRequestUsers: Array<ProjectRequestUserModel>;
  ProjectRequests: Array<ProjectRequestModel>;
  ProjectUserFindMany: Array<ProjectUser>;
  QCalcTestDataCacheGetSupportedLocales: Array<Maybe<Scalars['String']>>;
  QCalcVisualiseAssessment: QCalcVisualisedAssessmentModel;
  Report?: Maybe<Report>;
  ReportAssignment?: Maybe<ReportAssignment>;
  ReportAssignmentFindMany?: Maybe<Array<ReportAssignment>>;
  ReportAssignmentFindStageReport?: Maybe<ReportAssignment>;
  ReportAssignments?: Maybe<Array<ReportAssignment>>;
  ReportConfig?: Maybe<ReportConfig>;
  ReportConfigFindMany?: Maybe<Array<ReportConfig>>;
  ReportConfigs?: Maybe<Array<ReportConfig>>;
  ReportFindMany?: Maybe<Array<Report>>;
  ReportRender?: Maybe<ReportRender>;
  ReportRenderDeleteMany?: Maybe<DeleteResult>;
  ReportRenderFindMany?: Maybe<Array<ReportRender>>;
  ReportRenderFindOne?: Maybe<ReportRender>;
  ReportRenderWithDataApi?: Maybe<ReportRender>;
  ReportRenders: Array<ReportRender>;
  Reports?: Maybe<Array<Report>>;
  ReportsFindByCompany?: Maybe<Array<Report>>;
  RespondantForm?: Maybe<RespondantForm>;
  ResultAccessFindMany?: Maybe<Array<ResultAccessModel>>;
  ResultAccessFindOne?: Maybe<ResultAccessModel>;
  ResultFindBatch?: Maybe<Array<Maybe<ResultModel>>>;
  ResultFindBatches?: Maybe<Array<Maybe<Array<Maybe<ResultModel>>>>>;
  ResultFindMany: Array<ResultModel>;
  ResultFindOne?: Maybe<ResultModel>;
  ResultValidatedSkills: ResultValidatedSkillsPaginatedModel;
  RoleFindMany: Array<Role>;
  Roles: Array<Role>;
  RssAssessmentCandidateOpenOne: RssCandidateOpenOneModel;
  /** @deprecated Please use "query RssAsssessmentFindAllForStage" instead! */
  RssAssessmentFindMany: Array<RssAssessmentModel>;
  RssAsssessmentFindAllForStage: Array<RssAssessmentModel>;
  SamlAuthInfoFindMany?: Maybe<Array<SamlAuthInfoModel>>;
  SamlAuthInfoFindOne?: Maybe<SamlAuthInfoModel>;
  SkillCategory?: Maybe<SkillCategory>;
  SkillCategoryFindMany: Array<SkillCategory>;
  SkillCategoryFindOne?: Maybe<SkillCategory>;
  SkillCategoryFreeTextSearchPaginated: PaginatedSkillCategory;
  SkillCategorys: Array<SkillCategory>;
  SkillsProfileOpenOne: SkillsProfileOpenOneModel;
  SoftSkill?: Maybe<SoftSkill>;
  SoftSkillFindMany?: Maybe<Array<SoftSkill>>;
  SoftSkillFindManyByCompanyId?: Maybe<Array<SoftSkill>>;
  SoftSkillFindManyByProjectId?: Maybe<Array<SoftSkill>>;
  SoftSkillFindManyByTraitId?: Maybe<Array<SoftSkill>>;
  SoftSkillFreeTextSearchPaginate?: Maybe<SoftSkillModelPaginated>;
  SoftSkillGetAllValidForProject: Array<SoftSkill>;
  SoftSkillProjectModuleTypeFindOne?: Maybe<SoftSkillProjectModuleTypeModel>;
  SoftSkillRoleLevelContent: SoftSkillRoleLevelContent;
  SoftSkillRoleLevelContentFindManyAndCount: SoftSkillRoleLevelContentPaginated;
  SoftSkillRoleLevelContents: Array<SoftSkillRoleLevelContent>;
  SoftSkillSuccessProfileContent: SoftSkillSuccessProfileContent;
  SoftSkillSuccessProfileContentFindManyAndCount: SoftSkillSuccessProfileContentPaginated;
  SoftSkillSuccessProfileContents: Array<SoftSkillSuccessProfileContent>;
  SoftSkillTraitFindManyBySoftSkill?: Maybe<Array<SoftSkillTrait>>;
  SoftSkillTraitFindManyBySoftSkills?: Maybe<Array<SoftSkillTrait>>;
  SoftSkillTraits?: Maybe<Array<SoftSkillTrait>>;
  SoftSkillTypeSuccessProfileContent: SoftSkillTypeSuccessProfileContent;
  SoftSkillTypeSuccessProfileContentFindMany: Array<SoftSkillTypeSuccessProfileContent>;
  SoftSkillTypeSuccessProfileContentFindOne: SoftSkillTypeSuccessProfileContent;
  SoftSkillTypeSuccessProfileContents: Array<SoftSkillTypeSuccessProfileContent>;
  SoftSkills?: Maybe<Array<SoftSkill>>;
  Stage?: Maybe<SmStageModel>;
  StageCandidateFindById?: Maybe<SmStageCandidateModel>;
  StageCandidateFindBySubId?: Maybe<SmStageCandidateModel>;
  StageCandidateFindMany?: Maybe<Array<SmStageCandidateModel>>;
  StageCandidateFindOne?: Maybe<SmStageCandidateModel>;
  StageCandidateSkillsProfileResultsFindOne: StageCandidateSkillsProfileResultsModel;
  /** @deprecated Please use query StageResultsSummary instead! */
  StageCandidateStatisticsByProjectJobRoleFindMany: StageCandidateStatisticsByProjectJobRoleFindManyOutput;
  /** @deprecated Please use query StageProgressSummary instead! */
  StageCandidateStatisticsFindMany: StageCandidateStatisticsFindManyOutput;
  StageCandidateStatisticsFindOne: StageCandidateStatisticsModel;
  StageFindMany?: Maybe<Array<SmStageModel>>;
  StageFindOne?: Maybe<SmStageModel>;
  StageGenerateIndirectInvitationUrl: GenerateStageIndirectInvitationUrlModel;
  StageProgressSummary?: Maybe<StageCandidateStatisticsFindManyOutput>;
  StageResultsSummary: StageCandidateStatisticsByProjectJobRoleFindManyOutput;
  Stages?: Maybe<Array<SmStageModel>>;
  /** @deprecated Success Profile no longer has a link with Project. If you're looking for the Success Profiles connected to a Project, fetch the linked ProjectJobRoles. */
  SuccessProfile?: Maybe<SuccessProfile>;
  SuccessProfileExplanation?: Maybe<SuccessProfileExplanation>;
  SuccessProfileExplanationFindBySPId?: Maybe<SuccessProfileExplanation>;
  SuccessProfileExplanationGetDefaultSSContent?: Maybe<Array<SoftSkillSuccessProfileContent>>;
  SuccessProfileExplanationGetDefaultSSTypeContent?: Maybe<Array<SoftSkillTypeSuccessProfileContent>>;
  SuccessProfileFindMany?: Maybe<Array<SuccessProfile>>;
  SuccessProfileTechnicalSkill?: Maybe<SuccessProfileTechnicalSkill>;
  SuccessProfileTechnicalSkillFindMany: Array<SuccessProfileTechnicalSkill>;
  SuccessProfileTechnicalSkillFindOne?: Maybe<SuccessProfileTechnicalSkill>;
  SuccessProfileTechnicalSkills: Array<SuccessProfileTechnicalSkill>;
  SuccessProfiles?: Maybe<Array<SuccessProfile>>;
  SummaryNotesFindOne?: Maybe<SummaryNotesModel>;
  SzSkillsMerge?: Maybe<SzSkillsMerge>;
  SzSkillsMergeFindMany: Array<SzSkillsMerge>;
  SzSkillsMergeFindOne?: Maybe<SzSkillsMerge>;
  SzSkillsMergeFreeTextSearchPaginated: PaginatedSzSkillsMerge;
  TechnicalSkill?: Maybe<TechnicalSkill>;
  TechnicalSkillFindMany: Array<TechnicalSkill>;
  TechnicalSkillFindManyPaginated: PaginatedTechnicalSkill;
  TechnicalSkillFindOne?: Maybe<TechnicalSkill>;
  TechnicalSkillFreeTextSearchPaginated: PaginatedTechnicalSkill;
  TechnicalSkills: Array<TechnicalSkill>;
  TestCandidateFindMany: Array<TmTestCandidateModel>;
  TestCandidateFindManyByCandidate?: Maybe<Array<TmTestCandidateModel>>;
  TestCandidateFindOne?: Maybe<TmTestCandidateModel>;
  TmTest?: Maybe<TmTestModel>;
  TmTestFindMany: Array<TmTestModel>;
  TmTestFindOne?: Maybe<TmTestModel>;
  TmTests?: Maybe<Array<TmTestModel>>;
  Trait?: Maybe<Trait>;
  TraitFindManyBySoftSkillId?: Maybe<Array<Trait>>;
  TraitRoleLevelContent: TraitRoleLevelContent;
  TraitRoleLevelContentFindManyAndCount: TraitRoleLevelContentPaginatedModel;
  TraitRoleLevelContents: Array<TraitRoleLevelContent>;
  TraitSoftSkillContextCsvGenerateAndUpload?: Maybe<Blob>;
  TraitSoftSkillContextFindMany?: Maybe<Array<TraitSoftSkillContextModel>>;
  TraitSoftSkillContextFindOne?: Maybe<TraitSoftSkillContextModel>;
  Traits?: Maybe<Array<Trait>>;
  User?: Maybe<User>;
  UserCompanyInfo?: Maybe<UserCompanyInfo>;
  UserCompanyInfoFindMany?: Maybe<Array<UserCompanyInfo>>;
  UserCompanyInfoFindOne?: Maybe<UserCompanyInfo>;
  UserCompanyInfos?: Maybe<Array<UserCompanyInfo>>;
  UserFreeTextSearchPaginate?: Maybe<UserModelPaginated>;
  UserGroupFindManyPaginated: UserGroupPaginatedModel;
  UserGroupFindOne: UserGroupModel;
  UserGroupMemberFindManyPaginated: UserModelPaginated;
  UserSettings?: Maybe<UserSettings>;
  UserSettingsFindMany?: Maybe<Array<UserSettings>>;
  UserSettingsFindOne?: Maybe<UserSettings>;
  UserSettingss?: Maybe<Array<UserSettings>>;
  Users?: Maybe<Array<User>>;
  ValidateContent?: Maybe<ContentValidator>;
  ValidateContentPiece?: Maybe<ContentValidatiorPieceModel>;
  Widget?: Maybe<Widget>;
  WidgetConfig?: Maybe<WidgetConfig>;
  WidgetConfigFindMany?: Maybe<Array<WidgetConfig>>;
  WidgetConfigs?: Maybe<Array<WidgetConfig>>;
  WidgetFindMany?: Maybe<Array<Widget>>;
  WidgetOption?: Maybe<WidgetOption>;
  WidgetOptionFindMany?: Maybe<Array<WidgetOption>>;
  WidgetOptions?: Maybe<Array<WidgetOption>>;
  Widgets?: Maybe<Array<Widget>>;
  alive: Blob;
  companies?: Maybe<Array<Company>>;
  company?: Maybe<Company>;
  companyFindManyByIds?: Maybe<Array<Company>>;
  getAllProjectFiles: Array<ProjectFile>;
  getAllProjects: Array<Project>;
  getCompanyId: ProjectCompanyId;
  getFormReportCSV?: Maybe<Scalars['String']>;
  getInvitationStatus: InvitationStatusResult;
  getProjectById?: Maybe<Project>;
  getProjectCobranding: ProjectCobrandingModel;
  getProjectSamlAuthInfo: Scalars['String'];
  icimsIntegrationsFindAllPaginated: IcimsIntegrationPaginated;
  icimsIntegrationsFindOne?: Maybe<IcimsIntegration>;
  me: User;
  stackOneIntegrationsFindManyPaginated: StackOneIntegrationPaginated;
  stackOneIntegrationsFindOne?: Maybe<StackOneIntegration>;
  stackOneJobsCoverage: Array<StackOneJobsCoverage>;
  stackOneJobsDetails: Array<StackOneJobsDetails>;
  stackOneJobsFindManyPaginated: StackOneJobWithProjectsPaginated;
};


export type QueryAnswerArgs = {
  id: Scalars['Int'];
};


export type QueryAnswersArgs = {
  associatedId?: InputMaybe<Scalars['Int']>;
  associatedIds?: InputMaybe<Array<Scalars['Int']>>;
  fieldId?: InputMaybe<Scalars['Int']>;
  fields?: InputMaybe<Array<Scalars['Int']>>;
  formId?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  respondantId?: InputMaybe<Scalars['Int']>;
  respondantIds?: InputMaybe<Array<Scalars['Int']>>;
  response?: InputMaybe<Scalars['String']>;
};


export type QueryApiKeyCompanyFindManyArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  permission?: InputMaybe<IdentityPermissionRole>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryApiKeyUserFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  relationDepth?: InputMaybe<RelationDepth>;
  userEmail?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['Int']>;
  userSubId?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryApiUserFindManyArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  clientIds?: InputMaybe<Array<Scalars['String']>>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  getOption?: InputMaybe<GetOption>;
  hasClientSecret?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerIds?: InputMaybe<Array<Scalars['Int']>>;
  ownerType?: InputMaybe<ApiUserOwnerType>;
  puppetUserId?: InputMaybe<Scalars['Int']>;
  puppetUserIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryApiUserFindOneArgs = {
  clientId?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  getOption?: InputMaybe<GetOption>;
  hasClientSecret?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerType?: InputMaybe<ApiUserOwnerType>;
  puppetUserId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryAssessmentDataFindManyArgs = {
  allowedTimePerSection?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isPractice?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  normGroupType?: InputMaybe<NormGroupType>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  startingAbilityLowerBound?: InputMaybe<Scalars['Float']>;
  startingAbilityUpperBound?: InputMaybe<Scalars['Float']>;
  startingQuestions?: InputMaybe<Array<IAssessmentDataQuestionModel>>;
  supportedLocale?: InputMaybe<SupportedLocale>;
  testCandidateId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryAssessmentDataFindOneArgs = {
  allowedTimePerSection?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  isPractice?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  normGroupType?: InputMaybe<NormGroupType>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  startingAbilityLowerBound?: InputMaybe<Scalars['Float']>;
  startingAbilityUpperBound?: InputMaybe<Scalars['Float']>;
  startingQuestions?: InputMaybe<Array<IAssessmentDataQuestionModel>>;
  supportedLocale?: InputMaybe<SupportedLocale>;
  testCandidateId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryBaseMethodTestArgs = {
  id: Scalars['Int'];
};


export type QueryBaseMethodTestFindManyArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someEnums?: InputMaybe<Array<BaseMethodTestSomeEnum>>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
  someFlags?: InputMaybe<Array<Scalars['Boolean']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryBaseMethodTestFindManySearchPaginatedArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  skip: Scalars['Int'];
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someEnums?: InputMaybe<Array<BaseMethodTestSomeEnum>>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
  someFlags?: InputMaybe<Array<Scalars['Boolean']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  take: Scalars['Int'];
  textSearch?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryBaseMethodTestFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  someEnum?: InputMaybe<BaseMethodTestSomeEnum>;
  someFlag?: InputMaybe<Scalars['Boolean']>;
  subId?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryBehaviourContentFindOneArgs = {
  behaviourId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  negativeSummary?: InputMaybe<Scalars['String']>;
  positiveSummary?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryBehaviourFindOneArgs = {
  contextIds?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  internalName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryBlobFindByIdArgs = {
  getOption: Scalars['String'];
  id: Scalars['Int'];
};


export type QueryBlobFindByNameArgs = {
  getOption: Scalars['String'];
  name: Scalars['String'];
};


export type QueryBlobFindCmCandidateResultsCsvArgs = {
  getOption: Scalars['String'];
  subId: Scalars['String'];
};


export type QueryCalibrationCandidateFindManyArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  lastAdjustedById?: InputMaybe<Scalars['Int']>;
  lastAdjustedByIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  stageCandidateIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<CalibrationCandidateStatus>;
  statuses?: InputMaybe<Array<CalibrationCandidateStatus>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryCalibrationCandidateFindOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  lastAdjustedById?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<CalibrationCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryCalibrationConfigExtendedGenerateOneArgs = {
  doneBy: Scalars['Int'];
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  scoreType: ResultScoreType;
  stageCandidateId: Scalars['Int'];
};


export type QueryCalibrationConfigFindOneArgs = {
  ig?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
};


export type QueryCalibrationConfigGenerateManyArgs = {
  ig?: InputMaybe<Scalars['Boolean']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryCalibrationConfigGenerateOneArgs = {
  ig?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
};


export type QueryCmAllowedProjectArgs = {
  id: Scalars['Int'];
};


export type QueryCmCandidateResultFindAndCsvArgs = {
  departments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  freeTextSearch?: InputMaybe<Scalars['String']>;
  hrManagerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  invitedDate?: InputMaybe<FilterMomentRange>;
  lastDateOfCandidateAction?: InputMaybe<FilterMomentRange>;
  managerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  orderBy?: InputMaybe<CmCandidateResultOrderByArgs>;
  projectCandidateGroupedStatus?: InputMaybe<ProjectCandidateGroupedStatus>;
  projectCandidateGroupedStatuses?: InputMaybe<Array<ProjectCandidateGroupedStatus>>;
  projectId: Scalars['Int'];
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCmCandidateResultFindAndPaginateArgs = {
  departments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  freeTextSearch?: InputMaybe<Scalars['String']>;
  hrManagerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  invitedDate?: InputMaybe<FilterMomentRange>;
  lastDateOfCandidateAction?: InputMaybe<FilterMomentRange>;
  managerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  orderBy?: InputMaybe<CmCandidateResultOrderByArgs>;
  projectCandidateGroupedStatus?: InputMaybe<ProjectCandidateGroupedStatus>;
  projectCandidateGroupedStatuses?: InputMaybe<Array<ProjectCandidateGroupedStatus>>;
  projectId: Scalars['Int'];
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCmCandidateResultFindManyArgs = {
  departments?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  freeTextSearch?: InputMaybe<Scalars['String']>;
  hrManagerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  invitedDate?: InputMaybe<FilterMomentRange>;
  lastDateOfCandidateAction?: InputMaybe<FilterMomentRange>;
  managerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  orderBy?: InputMaybe<CmCandidateResultOrderByArgs>;
  projectCandidateGroupedStatus?: InputMaybe<ProjectCandidateGroupedStatus>;
  projectCandidateGroupedStatuses?: InputMaybe<Array<ProjectCandidateGroupedStatus>>;
  projectId: Scalars['Int'];
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCmDepartmentsArgs = {
  projectId: Scalars['Int'];
};


export type QueryCmProjectCandidateManagersArgs = {
  projectId: Scalars['Int'];
};


export type QueryCmProjectFindManyArgs = {
  moduleType?: InputMaybe<ProjectModuleType>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryCmProjectFindManyPaginatedArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  digEnabledOnly?: InputMaybe<Scalars['Boolean']>;
  direction?: InputMaybe<CmProjectSortingDirection>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  includeStopped?: InputMaybe<Scalars['Boolean']>;
  managerIds?: InputMaybe<Array<Scalars['Int']>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<CmProjectSortingOptions>;
  take: Scalars['Int'];
  textSearch?: InputMaybe<Scalars['String']>;
};


export type QueryCmProjectManagersArgs = {
  projectId: Scalars['Int'];
};


export type QueryCmResultScoreDisplayableKeysFindOneArgs = {
  projectId: Scalars['Int'];
};


export type QueryCmTalentReviewArgs = {
  criticality?: InputMaybe<Array<InputMaybe<TrCriticalityEnum>>>;
  division?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hrManagerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  impact?: InputMaybe<Array<InputMaybe<TrImpactEnum>>>;
  managerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  numberSuccessors?: InputMaybe<Array<InputMaybe<TrNumberSuccessorsEnum>>>;
  projectIds: Array<Scalars['Int']>;
  risk?: InputMaybe<Array<InputMaybe<TrRiskEnum>>>;
  score?: InputMaybe<Array<InputMaybe<TrScoreEnum>>>;
  xAxis: CmTrGridChartAxisEnum;
  yAxis: CmTrGridChartAxisEnum;
  zAxis?: InputMaybe<CmTrGridChartAxisEnum>;
};


export type QueryCmTalentReviewGridChartBasicArgs = {
  criticality?: InputMaybe<Array<InputMaybe<TrCriticalityEnum>>>;
  division?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  hrManagerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  impact?: InputMaybe<Array<InputMaybe<TrImpactEnum>>>;
  managerIds?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  numberSuccessors?: InputMaybe<Array<InputMaybe<TrNumberSuccessorsEnum>>>;
  projectIds: Array<Scalars['Int']>;
  risk?: InputMaybe<Array<InputMaybe<TrRiskEnum>>>;
  score?: InputMaybe<Array<InputMaybe<TrScoreEnum>>>;
  xAxis: CmTrGridChartAxisEnum;
  yAxis: CmTrGridChartAxisEnum;
  zAxis?: InputMaybe<CmTrGridChartAxisEnum>;
};


export type QueryCmTalentReviewGridChartFilterOptionsFindManyArgs = {
  companyId: Scalars['Int'];
};


export type QueryCompanyAllowedEmailDomainsArgs = {
  id: Scalars['Float'];
};


export type QueryCompanyEmployeeArgs = {
  id: Scalars['Int'];
};


export type QueryCompanyEmployeeFindManyArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  employeeId?: InputMaybe<Scalars['String']>;
  employeeIds?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  startDate?: InputMaybe<Scalars['Date']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryCompanyEmployeeFindOneArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  startDate?: InputMaybe<Scalars['Date']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryCompanyEmployeeFreeTextSearchPaginateArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  freetext?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<CompanyEmployeeOrderByArgs>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryCompanyFindManyAndPaginateArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryCompanyFindManyUsersWithRolesArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  userRoles: Array<IdentityPermissionRole>;
};


export type QueryCompanyFindUsersArgs = {
  id: Scalars['Int'];
};


export type QueryCompanyFindUsersPaginatedArgs = {
  id: Scalars['Int'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryCompanyMaybeUserEmployeeFindManyArgs = {
  companyId: Scalars['Float'];
};


export type QueryCompanyRoleArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  isPasswordDisabled?: InputMaybe<Scalars['Boolean']>;
  roleId?: InputMaybe<Scalars['Int']>;
  twoFactorEnabled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type QueryCompanyRolesArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isPasswordDisabled?: InputMaybe<Scalars['Boolean']>;
  roleId?: InputMaybe<Scalars['Int']>;
  roleIds?: InputMaybe<Array<Scalars['Int']>>;
  twoFactorEnabled?: InputMaybe<Scalars['Boolean']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
};


export type QueryCompanySignatureArgs = {
  id: Scalars['Int'];
};


export type QueryCompanySignatureFindManyArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  relationDepth?: InputMaybe<RelationDepth>;
  signature?: InputMaybe<Scalars['String']>;
  signatureEmail?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryCompanySignatureFindOneArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  relationDepth?: InputMaybe<RelationDepth>;
  signature?: InputMaybe<Scalars['String']>;
  signatureEmail?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryCompanyUsersFindManyPaginatedArgs = {
  args: CompanyUsersArgs;
  order?: InputMaybe<CompanyUsersOrderOptions>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
};


export type QueryContentViewerViewStageCandidateManagerReportArgs = {
  stageCandidateSub: Scalars['String'];
};


export type QueryContentViewerViewStageCandidateReportArgs = {
  stageCandidateSub: Scalars['String'];
};


export type QueryDeiStageActionArgs = {
  stageId: Scalars['Int'];
};


export type QueryDeiStageActionTemplateArgs = {
  companyId: Scalars['Int'];
};


export type QueryEaResultFindManyArgs = {
  eaStageActionCandidateId?: InputMaybe<Scalars['Int']>;
  eaStageActionCandidateIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  invitationUuid?: InputMaybe<Scalars['String']>;
  invitationUuids?: InputMaybe<Array<Scalars['String']>>;
  platformType: ExternalPlatformType;
};


export type QueryEaResultFindOneArgs = {
  eaStageActionCandidateId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  invitationUuid?: InputMaybe<Scalars['String']>;
  platformType: ExternalPlatformType;
};


export type QueryEaStageActionAvailableArgs = {
  companyId: Scalars['Int'];
  stageId: Scalars['Int'];
  technicalSkillIds: Array<Scalars['Int']>;
};


export type QueryEaStageActionCandidateFindManyArgs = {
  eaStageActionId?: InputMaybe<Scalars['Int']>;
  eaStageActionIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  invitationUuid?: InputMaybe<Scalars['String']>;
  modifier?: InputMaybe<ExternalPlatformModifier>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  stageCandidateIds?: InputMaybe<Array<Scalars['Int']>>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<EaStageActionCandidateStatus>;
  statuses?: InputMaybe<Array<EaStageActionCandidateStatus>>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryEaStageActionCandidateFindManyWithInvitationLinkArgs = {
  eaStageActionId?: InputMaybe<Scalars['Int']>;
  eaStageActionIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  invitationUuid?: InputMaybe<Scalars['String']>;
  modifier?: InputMaybe<ExternalPlatformModifier>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  stageCandidateIds?: InputMaybe<Array<Scalars['Int']>>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<EaStageActionCandidateStatus>;
  statuses?: InputMaybe<Array<EaStageActionCandidateStatus>>;
  userId?: InputMaybe<Scalars['Int']>;
};


export type QueryEaStageActionFindManyArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  externalAssessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryEaStageActionFindManyPaginatedArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  externalAssessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  skip: Scalars['Int'];
  stageId?: InputMaybe<Scalars['Int']>;
  stageIds?: InputMaybe<Array<Scalars['Int']>>;
  take: Scalars['Int'];
};


export type QueryEaStageActionFindOneArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  externalAssessmentId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  stageId?: InputMaybe<Scalars['Int']>;
};


export type QueryEntityLanguageFindManyArgs = {
  entityId?: InputMaybe<Scalars['Int']>;
  entityIds?: InputMaybe<Array<Scalars['Int']>>;
  entityType?: InputMaybe<EntityType>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  language?: InputMaybe<Language>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryExternalAssessmentFindManyArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  direction?: InputMaybe<EaSortingDirection>;
  eaCompanyPlatform?: InputMaybe<EaCompanyPlatformCreateOneArgs>;
  eaCompanyPlatformId?: InputMaybe<Scalars['Int']>;
  eaPlatformAssessments?: InputMaybe<Array<EaPlatformAssessmentCreateOneArgs>>;
  eaSkills?: InputMaybe<Array<EaSkillCreateOneArgs>>;
  estimatedDurationInMinutes?: InputMaybe<Scalars['Float']>;
  externalPlatformType?: InputMaybe<ExternalPlatformType>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  lastUsedAt?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<EaSortingOptions>;
  technicalSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  textSearch?: InputMaybe<Scalars['String']>;
  timeLimitInMinutes?: InputMaybe<Scalars['Float']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryExternalAssessmentFindManyPaginatedArgs = {
  companyId?: InputMaybe<Scalars['Float']>;
  direction?: InputMaybe<EaSortingDirection>;
  eaCompanyPlatform?: InputMaybe<EaCompanyPlatformCreateOneArgs>;
  eaCompanyPlatformId?: InputMaybe<Scalars['Float']>;
  eaPlatformAssessments?: InputMaybe<Array<EaPlatformAssessmentCreateOneArgs>>;
  eaSkills?: InputMaybe<Array<EaSkillCreateOneArgs>>;
  estimatedDurationInMinutes?: InputMaybe<Scalars['Float']>;
  externalPlatformType?: InputMaybe<ExternalPlatformType>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  lastUsedAt?: InputMaybe<Scalars['Date']>;
  name?: InputMaybe<Scalars['String']>;
  skip: Scalars['Int'];
  sortBy?: InputMaybe<EaSortingOptions>;
  take: Scalars['Int'];
  technicalSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  textSearch?: InputMaybe<Scalars['String']>;
  timeLimitInMinutes?: InputMaybe<Scalars['Float']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryExternalAssessmentFindOneArgs = {
  companyId?: InputMaybe<Scalars['Float']>;
  eaCompanyPlatform?: InputMaybe<EaCompanyPlatformCreateOneArgs>;
  eaCompanyPlatformId?: InputMaybe<Scalars['Float']>;
  eaPlatformAssessments?: InputMaybe<Array<EaPlatformAssessmentCreateOneArgs>>;
  eaSkills?: InputMaybe<Array<EaSkillCreateOneArgs>>;
  estimatedDurationInMinutes?: InputMaybe<Scalars['Float']>;
  externalPlatformType?: InputMaybe<ExternalPlatformType>;
  id?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  timeLimitInMinutes?: InputMaybe<Scalars['Float']>;
};


export type QueryExternalPlatformAssessmentFindManyArgs = {
  externalPlatformType: ExternalPlatformType;
  ids?: InputMaybe<Array<Scalars['String']>>;
  textSearch?: InputMaybe<Scalars['String']>;
};


export type QueryExternalPlatformAssessmentFindOneArgs = {
  externalPlatformType: ExternalPlatformType;
  id?: InputMaybe<Scalars['String']>;
};


export type QueryFieldArgs = {
  id: Scalars['Int'];
};


export type QueryFieldsArgs = {
  fieldType?: InputMaybe<FieldType>;
  formManagerType?: InputMaybe<FormManagerType>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
};


export type QueryFindManyAssessmentBrowserSwitchCandidateTrackingEventArgs = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  assessmentIds?: InputMaybe<Array<Scalars['Int']>>;
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<AssessmentBrowserSwitchCandidateTrackingEvent>;
  types?: InputMaybe<Array<AssessmentBrowserSwitchCandidateTrackingEvent>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
};


export type QueryFindOneAssessmentBrowserSwitchCandidateTrackingEventArgs = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<AssessmentBrowserSwitchCandidateTrackingEvent>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type QueryFindOneRoiCsvFileExportScheduledTaskArgs = {
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ScheduledTaskStatus>;
  subId?: InputMaybe<Scalars['String']>;
  triggeredBy?: InputMaybe<Scalars['Int']>;
};


export type QueryFindOneStageCandidateResultsCsvFileExportScheduledTaskArgs = {
  id?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<ScheduledTaskStatus>;
  subId?: InputMaybe<Scalars['String']>;
  triggeredBy?: InputMaybe<Scalars['Int']>;
};


export type QueryFixContentPieceArgs = {
  softSkillId: Scalars['Int'];
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryFormArgs = {
  id: Scalars['Int'];
};


export type QueryFormBuilderArgs = {
  id: Scalars['Int'];
};


export type QueryFormBuilderFindByOwnerAndTypeArgs = {
  formManagerType?: InputMaybe<FormManagerType>;
  formOwnerId?: InputMaybe<Scalars['Int']>;
  formType?: InputMaybe<FormType>;
};


export type QueryFormCandidateFindManyArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  formId?: InputMaybe<Scalars['Int']>;
  formIds?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  stageCandidateIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<FormCandidateStatus>;
  statuses?: InputMaybe<Array<FormCandidateStatus>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryFormCandidateFindOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  formId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<FormCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryFormReportFindAssociatedIdsArgs = {
  formManagerType?: InputMaybe<FormManagerType>;
  formOwnerId?: InputMaybe<Scalars['Int']>;
  formType?: InputMaybe<FormType>;
};


export type QueryFormReportFindFormOwnersArgs = {
  formManagerType: FormManagerType;
  formType: FormType;
};


export type QueryFormReportFindFormTypesArgs = {
  formManagerType: FormManagerType;
};


export type QueryFormsArgs = {
  formManagerType?: InputMaybe<FormManagerType>;
  formOwnerId?: InputMaybe<Scalars['Int']>;
  formType?: InputMaybe<FormType>;
  name?: InputMaybe<Scalars['String']>;
};


export type QueryGetAuthenticationMethodsArgs = {
  email: Scalars['String'];
};


export type QueryGetCurrentProjectCollaboratorArgs = {
  projectId: Scalars['Int'];
};


export type QueryGetInterviewFeedbackSummaryArgs = {
  projectJobRoleId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};


export type QueryGetStageActionSummariesArgs = {
  projectId: Scalars['Int'];
};


export type QueryGetStageActionsArgs = {
  stageCandidateId: Scalars['Int'];
};


export type QueryHiringDecisionFindLatestHiringDecisionsForStageCandidateArgs = {
  stageCandidateId: Scalars['Int'];
};


export type QueryHiringDecisionSkillReviewsFindOneArgs = {
  projectJobRoleId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};


export type QueryIdentityProviderFindManyArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
};


export type QueryIdentityProviderFindOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type QueryIgCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryIgCategoryFindManyArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  internalName?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryIgCategoryFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  internalName?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryIgInterviewGuideArgs = {
  id: Scalars['Int'];
};


export type QueryIgInterviewGuideCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryIgInterviewGuideCategoryFindManyArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  colour?: InputMaybe<IgCategoryColour>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryIgInterviewGuideCategoryFindOneArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  colour?: InputMaybe<IgCategoryColour>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryIgInterviewGuideFindManyArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryIgInterviewGuideFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryIgRenderFindOneArgs = {
  authorId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  pageStatus?: InputMaybe<IgRenderPageStatus>;
  status?: InputMaybe<IgRenderStatus>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type QueryIgStepArgs = {
  id: Scalars['Int'];
};


export type QueryIgStepFindManyArgs = {
  action?: InputMaybe<Scalars['String']>;
  colour?: InputMaybe<IgCategoryColour>;
  durationInSeconds?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  internalName?: InputMaybe<Scalars['String']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  questionType?: InputMaybe<IgQuestionType>;
  relationDepth?: InputMaybe<RelationDepth>;
  staticQuestionName?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryIgStepFindOneArgs = {
  action?: InputMaybe<Scalars['String']>;
  colour?: InputMaybe<IgCategoryColour>;
  durationInSeconds?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  internalName?: InputMaybe<Scalars['String']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  questionType?: InputMaybe<IgQuestionType>;
  relationDepth?: InputMaybe<RelationDepth>;
  staticQuestionName?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryIgTabRenderRefineModifiedQuestionFindOneArgs = {
  correlationId: Scalars['String'];
  renderId: Scalars['Int'];
  tabId: Scalars['Int'];
};


export type QueryInterviewGuideBehaviourFindManyArgs = {
  behaviourId?: InputMaybe<Scalars['Int']>;
  behaviourIds?: InputMaybe<Array<Scalars['Int']>>;
  classification?: InputMaybe<IgRenderBehaviourClassification>;
  classifications?: InputMaybe<Array<IgRenderBehaviourClassification>>;
  doneById?: InputMaybe<Scalars['Int']>;
  doneByIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  interviewGuideIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryInterviewGuideFeedbackFindOneArgs = {
  doneById?: InputMaybe<Scalars['Int']>;
  doneByIds?: InputMaybe<Array<Scalars['Int']>>;
  feedback?: InputMaybe<Scalars['String']>;
  feedbacks?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  interviewGuideIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryInterviewGuideFindOneArgs = {
  id?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
};


export type QueryInterviewGuideGeneratedFeedbackFindOneArgs = {
  correlationId: Scalars['String'];
  renderId: Scalars['Int'];
};


export type QueryInterviewGuidePageStatusFindOneArgs = {
  doneById?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  interviewGuideId?: InputMaybe<Scalars['Int']>;
  secondPageOpened?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<IgRenderPageStatus>;
};


export type QueryInterviewGuideRecipientFindManyArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  recipientId?: InputMaybe<Scalars['Int']>;
  recipientIds?: InputMaybe<Array<Scalars['Int']>>;
  renderId?: InputMaybe<Scalars['Int']>;
  renderIds?: InputMaybe<Array<Scalars['Int']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
};


export type QueryInterviewGuideRecipientUniqueFindManyArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  recipientId?: InputMaybe<Scalars['Int']>;
  recipientIds?: InputMaybe<Array<Scalars['Int']>>;
  renderId?: InputMaybe<Scalars['Int']>;
  renderIds?: InputMaybe<Array<Scalars['Int']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
};


export type QueryInterviewGuideSsDescriptionFindManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  renderId?: InputMaybe<Scalars['Int']>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
};


export type QueryInterviewQuestionContentFindOneArgs = {
  contextId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  mainQuestion?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  subQuestions?: InputMaybe<Array<Scalars['String']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryInterviewQuestionReasonContentFindOneArgs = {
  contextId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  numLowerBound?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryInterviewQuestionRoleLevelContentArgs = {
  classification?: InputMaybe<ContentClassification>;
  feedback?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  mainQuestion?: InputMaybe<Scalars['String']>;
  reasonForQuestion?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  subQuestions?: InputMaybe<Array<Scalars['String']>>;
  summary?: InputMaybe<Scalars['String']>;
  traitId?: InputMaybe<Scalars['Int']>;
};


export type QueryInterviewQuestionRoleLevelContentFindManyAndCountArgs = {
  classification?: InputMaybe<ContentClassification>;
  classifications?: InputMaybe<Array<ContentClassification>>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  feedback?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  mainQuestion?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  reasonForQuestion?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  subQuestions?: InputMaybe<Array<Scalars['String']>>;
  summary?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  traitId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInterviewQuestionRoleLevelContentsArgs = {
  classification?: InputMaybe<ContentClassification>;
  classifications?: InputMaybe<Array<ContentClassification>>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  feedback?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  mainQuestion?: InputMaybe<Scalars['String']>;
  reasonForQuestion?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  subQuestions?: InputMaybe<Array<Scalars['String']>>;
  summary?: InputMaybe<Scalars['String']>;
  traitId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryInvitationArgs = {
  id: Scalars['Int'];
};


export type QueryInvitationFindManyArgs = {
  baseUrl?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  expiresAt?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  invitationToken?: InputMaybe<Scalars['String']>;
  invitationType?: InputMaybe<InvitationType>;
  language?: InputMaybe<Language>;
  lastActionType?: InputMaybe<InvitationLastActionType>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  projectSubId?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  status?: InputMaybe<InvitationStatusCode>;
  targetId?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryInvitationFindOneArgs = {
  baseUrl?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  email?: InputMaybe<Scalars['String']>;
  expiresAt?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  invitationToken?: InputMaybe<Scalars['String']>;
  invitationType?: InputMaybe<InvitationType>;
  language?: InputMaybe<Language>;
  lastActionType?: InputMaybe<InvitationLastActionType>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  projectSubId?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  status?: InputMaybe<InvitationStatusCode>;
  targetId?: InputMaybe<Scalars['Int']>;
  url?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryInvitationSessionFindManyPaginatedArgs = {
  companyIds?: InputMaybe<Array<Scalars['String']>>;
  dateRange?: InputMaybe<FilterMomentRange>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  skip: Scalars['Int'];
  sortField?: InputMaybe<InvitationSessionSort>;
  sortOrder?: InputMaybe<SortOption>;
  sources?: InputMaybe<Array<InvitationSessionSource>>;
  statuses?: InputMaybe<Array<InvitationSessionStatus>>;
  take: Scalars['Int'];
};


export type QueryInvitationSessionFindOneArgs = {
  id: Scalars['String'];
};


export type QueryJobRoleFindManyArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  families?: InputMaybe<Array<JobRoleFamily>>;
  family?: InputMaybe<JobRoleFamily>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  nameOrQualifierContains?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  qualifier?: InputMaybe<Scalars['String']>;
  qualifiers?: InputMaybe<Array<Scalars['String']>>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
};


export type QueryJobRoleFindOneArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  family?: InputMaybe<JobRoleFamily>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nameOrQualifierContains?: InputMaybe<Scalars['String']>;
  qualifier?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type QueryLocationLinkFindManyArgs = {
  args?: InputMaybe<LocationLinkFindManyArgs>;
};


export type QueryProjectCollaboratorsFindManyPaginatedArgs = {
  args: ProjectCollaboratorArgs;
  order?: InputMaybe<ProjectCollaboratorOrderOptions>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
};


export type QueryProjectFindManyArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  companySignatureId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  managerIds?: InputMaybe<Array<Scalars['Int']>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  name?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
};


export type QueryProjectFindManyByCompanyArgs = {
  companyId: Scalars['Int'];
};


export type QueryProjectFindManyPaginatedArgs = {
  args: ProjectCompanyArgs;
  filter?: InputMaybe<ProjectCompanyFilterOptions>;
  order?: InputMaybe<ProjectCompanyOrderOptions>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
};


export type QueryProjectFindManysByCompanyWithStageCandidateRangeArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  companySignatureId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  managerIds?: InputMaybe<Array<Scalars['Int']>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  name?: InputMaybe<Scalars['String']>;
  stageCandidateCreatedAtFrom?: InputMaybe<Scalars['Date']>;
  stageCandidateCreatedAtTo?: InputMaybe<Scalars['Date']>;
  subId?: InputMaybe<Scalars['String']>;
};


export type QueryProjectFindManysByCompanyWithStageCandidateRangeCsvArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  companySignatureId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  managerIds?: InputMaybe<Array<Scalars['Int']>>;
  moduleType?: InputMaybe<ProjectModuleType>;
  name?: InputMaybe<Scalars['String']>;
  stageCandidateCreatedAtFrom?: InputMaybe<Scalars['Date']>;
  stageCandidateCreatedAtTo?: InputMaybe<Scalars['Date']>;
  subId?: InputMaybe<Scalars['String']>;
};


export type QueryProjectJobRoleFindManyArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  jobRoleId?: InputMaybe<Scalars['Int']>;
  jobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
  skillsProfileId?: InputMaybe<Scalars['Int']>;
  skillsProfileIds?: InputMaybe<Array<Scalars['Int']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
};


export type QueryProjectJobRoleFindOneArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  jobRoleId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  skillsProfileId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
};


export type QueryProjectRequestArgs = {
  id: Scalars['Int'];
};


export type QueryProjectRequestFileUploadArgs = {
  id: Scalars['Int'];
};


export type QueryProjectRequestFileUploadFindManyArgs = {
  blobId?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  projectRequestId?: InputMaybe<Scalars['Int']>;
  projectRequestIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryProjectRequestFileUploadFindOneArgs = {
  blobId?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  projectRequestId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryProjectRequestFindManyArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  managerSurveyId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  status?: InputMaybe<ProjectRequestStatus>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryProjectRequestFindOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  managerSurveyId?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  status?: InputMaybe<ProjectRequestStatus>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryProjectRequestUserArgs = {
  id: Scalars['Int'];
};


export type QueryProjectRequestUserApprovalArgs = {
  id: Scalars['Int'];
};


export type QueryProjectRequestUserApprovalFindManyArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  approvalStatus?: InputMaybe<ProjectRequestUserApprovalStatus>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  projectRequestUserIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryProjectRequestUserApprovalFindOneArgs = {
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  approvalStatus?: InputMaybe<ProjectRequestUserApprovalStatus>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryProjectRequestUserFindManyArgs = {
  approvalId?: InputMaybe<Scalars['Int']>;
  approvalIds?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  projectRequestId?: InputMaybe<Scalars['Int']>;
  projectRequestIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  type?: InputMaybe<ProjectRequestUserType>;
  userId?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryProjectRequestUserFindOneArgs = {
  approvalId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  projectRequestId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  type?: InputMaybe<ProjectRequestUserType>;
  userId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryProjectUserFindManyArgs = {
  includeArchivedProjects?: InputMaybe<Scalars['Boolean']>;
  includeClosedProjects?: InputMaybe<Scalars['Boolean']>;
  userIds: Array<Scalars['Int']>;
};


export type QueryQCalcTestDataCacheGetSupportedLocalesArgs = {
  testSubType?: InputMaybe<TestSubType>;
  testType: TestType;
};


export type QueryQCalcVisualiseAssessmentArgs = {
  allowedTimePerSection?: InputMaybe<Scalars['Int']>;
  completedAt?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  isPractice?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  normGroupType?: InputMaybe<NormGroupType>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  startingAbilityLowerBound?: InputMaybe<Scalars['Float']>;
  startingAbilityUpperBound?: InputMaybe<Scalars['Float']>;
  startingQuestions?: InputMaybe<Array<IAssessmentDataQuestionModel>>;
  supportedLocale?: InputMaybe<SupportedLocale>;
  testCandidateId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryReportArgs = {
  id: Scalars['Int'];
};


export type QueryReportAssignmentArgs = {
  id: Scalars['Int'];
};


export type QueryReportAssignmentFindManyArgs = {
  assignedId?: InputMaybe<Scalars['Int']>;
  assignedType?: InputMaybe<RAssignedType>;
  id?: InputMaybe<Scalars['Int']>;
  reportId?: InputMaybe<Scalars['Int']>;
  subType?: InputMaybe<ReportSubType>;
  type?: InputMaybe<ReportType>;
};


export type QueryReportAssignmentFindStageReportArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  stageId?: InputMaybe<Scalars['Int']>;
  subType: ReportSubType;
  type: ReportType;
};


export type QueryReportConfigArgs = {
  id: Scalars['Int'];
};


export type QueryReportConfigFindManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  newPageBefore?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Int']>;
  reportId?: InputMaybe<Scalars['Int']>;
  widgetConfigsToUpsert?: InputMaybe<Array<WidgetConfigUpsertOneBatchedWithReportConfigArgs>>;
  widgetId?: InputMaybe<Scalars['Int']>;
};


export type QueryReportFindManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerType?: InputMaybe<ReportOwnerType>;
  subType?: InputMaybe<ReportSubType>;
  type?: InputMaybe<ReportType>;
};


export type QueryReportRenderArgs = {
  subId: Scalars['String'];
};


export type QueryReportRenderDeleteManyArgs = {
  dataSourceId?: InputMaybe<Scalars['Int']>;
  dataSourceIds?: InputMaybe<Array<Scalars['Int']>>;
  dontSendEmailToCandidate?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerType?: InputMaybe<ReportOwnerType>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  projectJobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  reportId?: InputMaybe<Scalars['Int']>;
  reportRenderStatus?: InputMaybe<Scalars['String']>;
  reportRenderStatuses?: InputMaybe<Array<ReportRenderStatus>>;
  reportRenderType?: InputMaybe<ReportRenderType>;
  subId?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<ReportSubType>;
  type?: InputMaybe<ReportType>;
};


export type QueryReportRenderFindManyArgs = {
  dataSourceId?: InputMaybe<Scalars['Int']>;
  dataSourceIds?: InputMaybe<Array<Scalars['Int']>>;
  dontSendEmailToCandidate?: InputMaybe<Scalars['Boolean']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerType?: InputMaybe<ReportOwnerType>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  projectJobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  reportId?: InputMaybe<Scalars['Int']>;
  reportRenderStatus?: InputMaybe<Scalars['String']>;
  reportRenderStatuses?: InputMaybe<Array<ReportRenderStatus>>;
  reportRenderType?: InputMaybe<ReportRenderType>;
  subId?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<ReportSubType>;
  type?: InputMaybe<ReportType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryReportRenderFindOneArgs = {
  dataSourceId?: InputMaybe<Scalars['Int']>;
  dontSendEmailToCandidate?: InputMaybe<Scalars['Boolean']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerType?: InputMaybe<ReportOwnerType>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  reportId?: InputMaybe<Scalars['Int']>;
  reportRenderStatus?: InputMaybe<Scalars['String']>;
  reportRenderType?: InputMaybe<ReportRenderType>;
  subId?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<ReportSubType>;
  type?: InputMaybe<ReportType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryReportRenderWithDataApiArgs = {
  identitySubId: Scalars['String'];
  subId: Scalars['String'];
};


export type QueryReportsFindByCompanyArgs = {
  includeSharedReports: Scalars['Boolean'];
  name?: InputMaybe<Scalars['String']>;
  ownerId?: InputMaybe<Scalars['Int']>;
  ownerType?: InputMaybe<ReportOwnerType>;
  subType?: InputMaybe<ReportSubType>;
  type?: InputMaybe<ReportType>;
};


export type QueryRespondantFormArgs = {
  associatedId: Scalars['Int'];
  formOwnerId: Scalars['Int'];
  formType: FormType;
  projectId?: InputMaybe<Scalars['Int']>;
  respondantId?: InputMaybe<Scalars['Int']>;
};


export type QueryResultAccessFindManyArgs = {
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  stageCandidateIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryResultAccessFindOneArgs = {
  stageCandidateId?: InputMaybe<Scalars['Int']>;
};


export type QueryResultFindBatchArgs = {
  args: Array<ResultFindOneArgs>;
  getOption?: InputMaybe<GetOption>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryResultFindBatchesArgs = {
  args: Array<ResultFindManyArgs>;
  getOption?: InputMaybe<GetOption>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryResultFindManyArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  copiedFroms?: InputMaybe<Array<Scalars['Int']>>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneBys?: InputMaybe<Array<Scalars['Int']>>;
  doneFor?: InputMaybe<Scalars['Int']>;
  doneFors?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  label?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  manuallyAdjustedBys?: InputMaybe<Array<Scalars['Int']>>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementIds?: InputMaybe<Array<Scalars['Int']>>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  measurementTypes?: InputMaybe<Array<ResultMeasurementType>>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  previousVersionIds?: InputMaybe<Array<Scalars['Int']>>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  projectJobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  scoreType?: InputMaybe<ResultScoreType>;
  scoreTypes?: InputMaybe<Array<ResultScoreType>>;
  version?: InputMaybe<Scalars['Int']>;
  versions?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryResultFindOneArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  scoreType?: InputMaybe<ResultScoreType>;
  version?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryResultValidatedSkillsArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryRoleFindManyArgs = {
  description?: InputMaybe<Scalars['String']>;
  filterForIdentity?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<RoleType>;
  types?: InputMaybe<Array<RoleType>>;
};


export type QueryRssAssessmentCandidateOpenOneArgs = {
  id: Scalars['Int'];
};


export type QueryRssAssessmentFindManyArgs = {
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageIds?: InputMaybe<Array<Scalars['Int']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  type?: InputMaybe<RssAssessmentType>;
  types?: InputMaybe<Array<RssAssessmentType>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryRssAsssessmentFindAllForStageArgs = {
  stageId: Scalars['Int'];
};


export type QuerySamlAuthInfoFindManyArgs = {
  cert?: InputMaybe<Scalars['String']>;
  certs?: InputMaybe<Array<Scalars['String']>>;
  createdAt?: InputMaybe<Scalars['Date']>;
  createdAts?: InputMaybe<Array<Scalars['Date']>>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  deletedAts?: InputMaybe<Array<Scalars['Date']>>;
  entryPoint?: InputMaybe<Scalars['String']>;
  entryPoints?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  identityProviderId?: InputMaybe<Scalars['Int']>;
  identityProviderIds?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  issuer?: InputMaybe<Scalars['String']>;
  issuers?: InputMaybe<Array<Scalars['String']>>;
  signatureAlgorithm?: InputMaybe<Scalars['String']>;
  signatureAlgorithms?: InputMaybe<Array<Scalars['String']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  updatedAts?: InputMaybe<Array<Scalars['Date']>>;
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']>;
  wantAuthResponseSigned?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySamlAuthInfoFindOneArgs = {
  cert?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  entryPoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  identityProviderId?: InputMaybe<Scalars['Int']>;
  issuer?: InputMaybe<Scalars['String']>;
  signatureAlgorithm?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
  wantAssertionsSigned?: InputMaybe<Scalars['Boolean']>;
  wantAuthResponseSigned?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySkillCategoryArgs = {
  id: Scalars['Int'];
};


export type QuerySkillCategoryFindManyArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySkillCategoryFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySkillCategoryFreeTextSearchPaginatedArgs = {
  freetext?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySoftSkillArgs = {
  getOption?: InputMaybe<GetOption>;
  id: Scalars['Int'];
};


export type QuerySoftSkillFindManyArgs = {
  category?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  traitId?: InputMaybe<Scalars['Int']>;
};


export type QuerySoftSkillFindManyByCompanyIdArgs = {
  id: Scalars['Int'];
};


export type QuerySoftSkillFindManyByProjectIdArgs = {
  category?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
  projectId: Scalars['Int'];
  traitId?: InputMaybe<Scalars['Int']>;
};


export type QuerySoftSkillFindManyByTraitIdArgs = {
  id: Scalars['Int'];
};


export type QuerySoftSkillFreeTextSearchPaginateArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  freetext: Scalars['String'];
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySoftSkillGetAllValidForProjectArgs = {
  projectId: Scalars['Int'];
};


export type QuerySoftSkillProjectModuleTypeFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  projectModuleType?: InputMaybe<ProjectModuleType>;
  relationDepth?: InputMaybe<RelationDepth>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySoftSkillRoleLevelContentArgs = {
  candidateContent?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Grade>;
  hiringManagerContent?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  internalCandidateContent?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  softSkillId?: InputMaybe<Scalars['Int']>;
};


export type QuerySoftSkillRoleLevelContentFindManyAndCountArgs = {
  candidateContent?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Grade>;
  grades?: InputMaybe<Array<Grade>>;
  hiringManagerContent?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  internalCandidateContent?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySoftSkillRoleLevelContentsArgs = {
  candidateContent?: InputMaybe<Scalars['String']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  grade?: InputMaybe<Grade>;
  grades?: InputMaybe<Array<Grade>>;
  hiringManagerContent?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  internalCandidateContent?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QuerySoftSkillSuccessProfileContentArgs = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  shortDescription?: InputMaybe<Scalars['String']>;
  softSkillId?: InputMaybe<Scalars['Int']>;
};


export type QuerySoftSkillSuccessProfileContentFindManyAndCountArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  order?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  shortDescription?: InputMaybe<Scalars['String']>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QuerySoftSkillSuccessProfileContentsArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  shortDescription?: InputMaybe<Scalars['String']>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QuerySoftSkillTraitFindManyBySoftSkillArgs = {
  id: Scalars['Int'];
};


export type QuerySoftSkillTraitFindManyBySoftSkillsArgs = {
  ids: Array<Scalars['Int']>;
};


export type QuerySoftSkillTraitsArgs = {
  ids?: InputMaybe<Array<Scalars['Int']>>;
  orientation?: InputMaybe<Orientation>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
  weight?: InputMaybe<Scalars['Float']>;
};


export type QuerySoftSkillTypeSuccessProfileContentArgs = {
  id: Scalars['Int'];
};


export type QuerySoftSkillTypeSuccessProfileContentFindManyArgs = {
  description?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  softSkillType?: InputMaybe<SoftSkillType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySoftSkillTypeSuccessProfileContentFindOneArgs = {
  description?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  softSkillType?: InputMaybe<SoftSkillType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryStageArgs = {
  id: Scalars['Int'];
};


export type QueryStageCandidateFindByIdArgs = {
  id: Scalars['Int'];
};


export type QueryStageCandidateFindBySubIdArgs = {
  subId: Scalars['String'];
};


export type QueryStageCandidateFindManyArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  candidateIds?: InputMaybe<Array<Scalars['Int']>>;
  createdAtFrom?: InputMaybe<Scalars['Date']>;
  createdAtTo?: InputMaybe<Scalars['Date']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneBys?: InputMaybe<Array<Scalars['Int']>>;
  doneFor?: InputMaybe<Scalars['Int']>;
  doneFors?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  hasPledgedAnticheating?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  lastDateOfCandidateActionFrom?: InputMaybe<Scalars['Date']>;
  lastDateOfCandidateActionTo?: InputMaybe<Scalars['Date']>;
  orderBy?: InputMaybe<StageCandidateOrderByArgs>;
  projectTrackerId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  skip?: InputMaybe<Scalars['Int']>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageIds?: InputMaybe<Array<Scalars['Int']>>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StageCandidateStatus>;
  statuses?: InputMaybe<Array<StageCandidateStatus>>;
  subId?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  updatedAtFrom?: InputMaybe<Scalars['Date']>;
  updatedAtTo?: InputMaybe<Scalars['Date']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryStageCandidateFindOneArgs = {
  applicationId?: InputMaybe<Scalars['String']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  hasPledgedAnticheating?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  projectTrackerId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StageCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryStageCandidateSkillsProfileResultsFindOneArgs = {
  projectJobRoleId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};


export type QueryStageCandidateStatisticsByProjectJobRoleFindManyArgs = {
  filter?: InputMaybe<StageCandidateStatisticsByProjectJobRoleFindManyFilterArgs>;
  order?: InputMaybe<StageCandidateStatisticsByProjectJobRoleFindManyOrderArgs>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
  projectJobRoleId: Scalars['Int'];
};


export type QueryStageCandidateStatisticsFindManyArgs = {
  filter?: InputMaybe<StageCandidateStatisticsFindManyFilterArgs>;
  order?: InputMaybe<StageCandidateStatisticsFindManyOrderArgs>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
  projectId: Scalars['Int'];
};


export type QueryStageCandidateStatisticsFindOneArgs = {
  stageCandidateId: Scalars['Int'];
};


export type QueryStageFindManyArgs = {
  createInterviewGuide?: InputMaybe<Scalars['Boolean']>;
  emailCandidateReport?: InputMaybe<Scalars['Boolean']>;
  emailManagerReport?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeam?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeamRoles?: InputMaybe<Array<ActorRole>>;
  emailSzAdmin?: InputMaybe<Scalars['Boolean']>;
  enableF2fInterviews?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  formId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  hasAdjustmentQuestionnaire?: InputMaybe<Scalars['Boolean']>;
  hasCalibration?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  renderCandidateReport?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<StageStatus>;
  subId?: InputMaybe<Scalars['String']>;
  testIds?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<StageType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryStageFindOneArgs = {
  createInterviewGuide?: InputMaybe<Scalars['Boolean']>;
  emailCandidateReport?: InputMaybe<Scalars['Boolean']>;
  emailManagerReport?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeam?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeamRoles?: InputMaybe<Array<ActorRole>>;
  emailSzAdmin?: InputMaybe<Scalars['Boolean']>;
  enableF2fInterviews?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  experimentalNormGroups?: InputMaybe<Scalars['Boolean']>;
  formId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  hasAdjustmentQuestionnaire?: InputMaybe<Scalars['Boolean']>;
  hasCalibration?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  renderCandidateReport?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<StageStatus>;
  subId?: InputMaybe<Scalars['String']>;
  testIds?: InputMaybe<Array<Scalars['Int']>>;
  type?: InputMaybe<StageType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryStageGenerateIndirectInvitationUrlArgs = {
  id: Scalars['Int'];
  language?: InputMaybe<Language>;
  locale?: InputMaybe<SupportedLocale>;
};


export type QueryStageProgressSummaryArgs = {
  filter?: InputMaybe<StageCandidateStatisticsFindManyFilterArgs>;
  order?: InputMaybe<StageCandidateStatisticsFindManyOrderArgs>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
  projectId: Scalars['Int'];
};


export type QueryStageResultsSummaryArgs = {
  filter?: InputMaybe<StageCandidateStatisticsByProjectJobRoleFindManyFilterArgs>;
  order?: InputMaybe<StageCandidateStatisticsByProjectJobRoleFindManyOrderArgs>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
  projectJobRoleId: Scalars['Int'];
};


export type QuerySuccessProfileArgs = {
  projectId: Scalars['Int'];
};


export type QuerySuccessProfileExplanationArgs = {
  id: Scalars['Int'];
};


export type QuerySuccessProfileExplanationFindBySpIdArgs = {
  id: Scalars['Int'];
};


export type QuerySuccessProfileExplanationGetDefaultSsContentArgs = {
  id: Scalars['Int'];
};


export type QuerySuccessProfileExplanationGetDefaultSsTypeContentArgs = {
  id: Scalars['Int'];
};


export type QuerySuccessProfileFindManyArgs = {
  calculationMethod?: InputMaybe<CalculationMethod>;
  gradingMethod?: InputMaybe<GradingMethod>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  jobRoleId?: InputMaybe<Scalars['Int']>;
  jobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
};


export type QuerySuccessProfileTechnicalSkillArgs = {
  id: Scalars['Int'];
};


export type QuerySuccessProfileTechnicalSkillFindManyArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  successProfileId?: InputMaybe<Scalars['Int']>;
  successProfileIds?: InputMaybe<Array<Scalars['Int']>>;
  technicalSkillId?: InputMaybe<Scalars['Int']>;
  technicalSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySuccessProfileTechnicalSkillFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  successProfileId?: InputMaybe<Scalars['Int']>;
  technicalSkillId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySummaryNotesFindOneArgs = {
  projectJobRoleId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};


export type QuerySzSkillsMergeArgs = {
  id: Scalars['Int'];
};


export type QuerySzSkillsMergeFindManyArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  categoryIds?: InputMaybe<Array<Scalars['Int']>>;
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  description?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  measurementConstructTypes?: InputMaybe<Array<MeasurementConstructType>>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  originIds?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  skillType?: InputMaybe<SkillType>;
  skillTypes?: InputMaybe<Array<SkillType>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySzSkillsMergeFindOneArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  skillType?: InputMaybe<SkillType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QuerySzSkillsMergeFreeTextSearchPaginatedArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  freetext?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  skillType?: InputMaybe<SkillType>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTechnicalSkillArgs = {
  id: Scalars['Int'];
};


export type QueryTechnicalSkillFindManyArgs = {
  getOption?: InputMaybe<GetOption>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  iconBlobIds?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
  skillCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTechnicalSkillFindManyPaginatedArgs = {
  getOption?: InputMaybe<GetOption>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  icons?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTechnicalSkillFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTechnicalSkillFreeTextSearchPaginatedArgs = {
  freetext?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  relationDepth?: InputMaybe<RelationDepth>;
  skillCategoryIds?: InputMaybe<Array<Scalars['Int']>>;
  skip: Scalars['Int'];
  sortField?: InputMaybe<TechnicalSkillSortField>;
  sortOrder?: InputMaybe<SortOption>;
  take: Scalars['Int'];
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTestCandidateFindManyArgs = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  candidateIds?: InputMaybe<Array<Scalars['Int']>>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneBys?: InputMaybe<Array<Scalars['Int']>>;
  doneFor?: InputMaybe<Scalars['Int']>;
  doneFors?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  stageCandidateIds?: InputMaybe<Array<Scalars['Int']>>;
  status?: InputMaybe<TestStatus>;
  testId?: InputMaybe<Scalars['Int']>;
  testIds?: InputMaybe<Array<Scalars['Int']>>;
  testOrigin?: InputMaybe<TestOrigin>;
  testResultId?: InputMaybe<Scalars['Int']>;
  timeModifierPercentage?: InputMaybe<Scalars['Float']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTestCandidateFindManyByCandidateArgs = {
  candidateId: Scalars['Int'];
  getOption?: InputMaybe<GetOption>;
  relationDepth?: InputMaybe<RelationDepth>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTestCandidateFindOneArgs = {
  assessmentId?: InputMaybe<Scalars['Int']>;
  candidateId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TestStatus>;
  testId?: InputMaybe<Scalars['Int']>;
  testOrigin?: InputMaybe<TestOrigin>;
  testResultId?: InputMaybe<Scalars['Int']>;
  timeModifierPercentage?: InputMaybe<Scalars['Float']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTmTestArgs = {
  id: Scalars['Int'];
};


export type QueryTmTestFindManyArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<TestOrigin>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageIds?: InputMaybe<Array<Scalars['Int']>>;
  subId?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  subType?: InputMaybe<TestSubType>;
  tpProjectId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TestType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTmTestFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<TestOrigin>;
  relationDepth?: InputMaybe<RelationDepth>;
  stageId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  subType?: InputMaybe<TestSubType>;
  tpProjectId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<TestType>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTraitArgs = {
  id: Scalars['Int'];
};


export type QueryTraitFindManyBySoftSkillIdArgs = {
  id: Scalars['Int'];
};


export type QueryTraitRoleLevelContentArgs = {
  classification?: InputMaybe<ContentClassification>;
  content?: InputMaybe<Scalars['String']>;
  developmentContent?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  summary?: InputMaybe<Scalars['String']>;
  traitId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTraitRoleLevelContentFindManyAndCountArgs = {
  classification?: InputMaybe<ContentClassification>;
  classifications?: InputMaybe<Array<ContentClassification>>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  content?: InputMaybe<Scalars['String']>;
  developmentContent?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  order?: InputMaybe<Array<Scalars['String']>>;
  page?: InputMaybe<Scalars['Int']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  summary?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
  traitId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTraitRoleLevelContentsArgs = {
  classification?: InputMaybe<ContentClassification>;
  classifications?: InputMaybe<Array<ContentClassification>>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  content?: InputMaybe<Scalars['String']>;
  developmentContent?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  summary?: InputMaybe<Scalars['String']>;
  traitId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<Scalars['Boolean']>;
};


export type QueryTraitSoftSkillContextCsvGenerateAndUploadArgs = {
  behaviourIds?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  traitId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTraitSoftSkillContextFindManyArgs = {
  behaviourIds?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  traitId?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTraitSoftSkillContextFindOneArgs = {
  behaviourIds?: InputMaybe<Array<Scalars['Int']>>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  traitId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryTraitsArgs = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  softSkillId?: InputMaybe<Scalars['Int']>;
};


export type QueryUserArgs = {
  email?: InputMaybe<Scalars['String']>;
  filterFakeUsers?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  isPasswordDisabled?: InputMaybe<Scalars['Boolean']>;
  isPuppet?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  recoveryToken?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
};


export type QueryUserCompanyInfoArgs = {
  id: Scalars['Int'];
};


export type QueryUserCompanyInfoFindManyArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  department?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  employeeIds?: InputMaybe<Array<Scalars['String']>>;
  endDate?: InputMaybe<Scalars['Date']>;
  getOption?: InputMaybe<GetOption>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  jobTitle?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  startDate?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryUserCompanyInfoFindOneArgs = {
  companyId?: InputMaybe<Scalars['Int']>;
  department?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  getOption?: InputMaybe<GetOption>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  startDate?: InputMaybe<Scalars['Date']>;
  userId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryUserFreeTextSearchPaginateArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  filterFakeUsers?: InputMaybe<Scalars['Boolean']>;
  freetext: Scalars['String'];
  ids?: InputMaybe<Array<Scalars['Int']>>;
  roleEnd?: InputMaybe<IdentityPermissionRole>;
  roleStart?: InputMaybe<IdentityPermissionRole>;
  roles?: InputMaybe<Array<IdentityPermissionRole>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryUserGroupFindManyPaginatedArgs = {
  args: UserGroupFindManyArgs;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
};


export type QueryUserGroupFindOneArgs = {
  subId: Scalars['String'];
};


export type QueryUserGroupMemberFindManyPaginatedArgs = {
  args: UserGroupMemberFindManyArgs;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
};


export type QueryUserSettingsArgs = {
  id: Scalars['Int'];
};


export type QueryUserSettingsFindManyArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  relationDepth?: InputMaybe<RelationDepth>;
  userId?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Array<Scalars['Int']>>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryUserSettingsFindOneArgs = {
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  relationDepth?: InputMaybe<RelationDepth>;
  userId?: InputMaybe<Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
};


export type QueryUsersArgs = {
  email?: InputMaybe<Scalars['String']>;
  emails?: InputMaybe<Array<Scalars['String']>>;
  filterFakeUsers?: InputMaybe<Scalars['Boolean']>;
  firstName?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  isPasswordDisabled?: InputMaybe<Scalars['Boolean']>;
  isPuppet?: InputMaybe<Scalars['Boolean']>;
  language?: InputMaybe<Language>;
  lastName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  recoveryToken?: InputMaybe<Scalars['String']>;
  refreshToken?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']>>;
  twoFactorCode?: InputMaybe<Scalars['String']>;
};


export type QueryValidateContentArgs = {
  page?: InputMaybe<Scalars['Int']>;
  softSkillIds?: InputMaybe<Array<Scalars['Int']>>;
  take?: InputMaybe<Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryValidateContentPieceArgs = {
  softSkillId: Scalars['Int'];
  traitIds?: InputMaybe<Array<Scalars['Int']>>;
};


export type QueryWidgetArgs = {
  id: Scalars['Int'];
};


export type QueryWidgetConfigArgs = {
  id: Scalars['Int'];
};


export type QueryWidgetConfigFindManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  reportConfigId?: InputMaybe<Scalars['Int']>;
  settings?: InputMaybe<Scalars['String']>;
  widgetOptionId?: InputMaybe<Scalars['Int']>;
};


export type QueryWidgetFindManyArgs = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<WidgetType>;
};


export type QueryWidgetOptionArgs = {
  id: Scalars['Int'];
};


export type QueryWidgetOptionFindManyArgs = {
  defaultValue?: InputMaybe<Scalars['String']>;
  fieldType?: InputMaybe<WidgetOptionFieldType>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  question?: InputMaybe<Scalars['String']>;
  widgetId?: InputMaybe<Scalars['Int']>;
};


export type QueryCompanyArgs = {
  id: Scalars['Int'];
};


export type QueryCompanyFindManyByIdsArgs = {
  ids: Array<Scalars['Int']>;
};


export type QueryGetAllProjectFilesArgs = {
  id: Scalars['Float'];
};


export type QueryGetCompanyIdArgs = {
  id: Scalars['Float'];
};


export type QueryGetFormReportCsvArgs = {
  associatedId: Scalars['Int'];
  formManagerType: FormManagerType;
  formOwnerId: Scalars['Int'];
  formType: FormType;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};


export type QueryGetInvitationStatusArgs = {
  invitationToken: Scalars['String'];
};


export type QueryGetProjectByIdArgs = {
  id: Scalars['Float'];
};


export type QueryGetProjectCobrandingArgs = {
  subId: Scalars['String'];
};


export type QueryGetProjectSamlAuthInfoArgs = {
  id: Scalars['Float'];
};


export type QueryIcimsIntegrationsFindAllPaginatedArgs = {
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  customerIds?: InputMaybe<Array<Scalars['String']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryIcimsIntegrationsFindOneArgs = {
  id: Scalars['String'];
};


export type QueryStackOneIntegrationsFindManyPaginatedArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  companyIds?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  skip: Scalars['Int'];
  take: Scalars['Int'];
};


export type QueryStackOneIntegrationsFindOneArgs = {
  id: Scalars['String'];
};


export type QueryStackOneJobsCoverageArgs = {
  companyId: Scalars['Int'];
};


export type QueryStackOneJobsDetailsArgs = {
  companyId: Scalars['Int'];
};


export type QueryStackOneJobsFindManyPaginatedArgs = {
  companyId: Scalars['Int'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
};

export type QuestionModel = {
  __typename?: 'QuestionModel';
  options: Array<OptionModel>;
  subId: Scalars['String'];
  text: Scalars['String'];
};

export enum QuestionOrientation {
  Inverted = 'INVERTED',
  Standard = 'STANDARD'
}

export enum RAssignedType {
  Company = 'COMPANY',
  Project = 'PROJECT',
  Stage = 'STAGE'
}

/** Original JSON object that was gathered from the platform */
export type RawPlatformAssessmentModel = {
  __typename?: 'RawPlatformAssessmentModel';
  id?: Maybe<Scalars['String']>;
};

export enum RelationDepth {
  Basic = 'BASIC',
  Full = 'FULL',
  FullWithExternal = 'FULL_WITH_EXTERNAL',
  NoRelations = 'NO_RELATIONS'
}

export type Report = {
  __typename?: 'Report';
  company?: Maybe<Company>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  ownerId: Scalars['Int'];
  ownerType: ReportOwnerType;
  reportConfigs?: Maybe<Array<ReportConfig>>;
  reportRenders?: Maybe<Array<ReportRender>>;
  subType?: Maybe<ReportSubType>;
  type: ReportType;
  updatedAt: Scalars['Date'];
};

export type ReportAssignment = {
  __typename?: 'ReportAssignment';
  assignedId: Scalars['Int'];
  assignedType: RAssignedType;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  inheritsSetting?: Maybe<Scalars['Boolean']>;
  report: Report;
  reportId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export type ReportConfig = {
  __typename?: 'ReportConfig';
  id: Scalars['Int'];
  newPageBefore: Scalars['Boolean'];
  position: Scalars['Int'];
  report: Report;
  reportId: Scalars['Int'];
  widget: Widget;
  widgetConfigs?: Maybe<Array<WidgetConfig>>;
  widgetId: Scalars['Int'];
};

export type ReportConfigCreateOneBatchedWithReportArgs = {
  newPageBefore?: InputMaybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  widgetConfigsToUpsert?: InputMaybe<Array<WidgetConfigUpsertOneBatchedWithReportConfigArgs>>;
  widgetId: Scalars['Int'];
};

export type ReportConfigUpdateOneArgs = {
  id: Scalars['Int'];
  newPageBefore?: InputMaybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  widgetConfigsToUpsert?: InputMaybe<Array<WidgetConfigUpsertOneBatchedWithReportConfigArgs>>;
};

export enum ReportOwnerType {
  /** Company owner */
  Company = 'COMPANY',
  /** User owner */
  User = 'USER'
}

export type ReportRender = {
  __typename?: 'ReportRender';
  blob?: Maybe<Blob>;
  blobId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  dataSourceId: Scalars['Int'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  projectJobRoleId?: Maybe<Scalars['Int']>;
  report: Report;
  reportId: Scalars['Int'];
  reportRenderStatus?: Maybe<ReportRenderStatus>;
  reportRenderType?: Maybe<ReportRenderType>;
  statusMessage?: Maybe<Scalars['String']>;
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
  widgetRenders?: Maybe<Array<WidgetRender>>;
};

export enum ReportRenderStatus {
  CollectingData = 'COLLECTING_DATA',
  Complete = 'COMPLETE',
  CompleteEmailed = 'COMPLETE_EMAILED',
  CompleteEmailing = 'COMPLETE_EMAILING',
  Failed = 'FAILED',
  Initialisation = 'INITIALISATION',
  Rendering = 'RENDERING'
}

export enum ReportRenderType {
  Csv = 'CSV',
  Data = 'DATA',
  Pdf = 'PDF'
}

export enum ReportSubType {
  /** Stage results candidate */
  StageResultsCandidate = 'STAGE_RESULTS_CANDIDATE',
  /** Stage results manager */
  StageResultsManager = 'STAGE_RESULTS_MANAGER',
  /** Sp report full */
  SuccessProfileFull = 'SUCCESS_PROFILE_FULL',
  /** Sp report partial */
  SuccessProfilePartial = 'SUCCESS_PROFILE_PARTIAL'
}

export enum ReportType {
  /** Stage results */
  StageResults = 'STAGE_RESULTS',
  /** Success profile results */
  SuccessProfile = 'SUCCESS_PROFILE'
}

export type RespondantFieldWithAnswers = {
  __typename?: 'RespondantFieldWithAnswers';
  answer?: Maybe<Scalars['String']>;
  displayOrder: Scalars['Int'];
  field?: Maybe<Field>;
  isOptional: Scalars['Boolean'];
  selectOptionAnswerIsFreeText?: Maybe<Scalars['Boolean']>;
};

export type RespondantForm = {
  __typename?: 'RespondantForm';
  associatedId: Scalars['Int'];
  formId: Scalars['Int'];
  formOwnerId: Scalars['Int'];
  formType: FormType;
  isComplete: Scalars['Boolean'];
  respondantFieldWithAnswers: Array<RespondantFieldWithAnswers>;
  submittedFormLogId?: Maybe<Scalars['Int']>;
};

export enum ResponseType {
  CompanyEmployeeSelectResponse = 'COMPANY_EMPLOYEE_SELECT_RESPONSE',
  DateResponse = 'DATE_RESPONSE',
  LongTextResponse = 'LONG_TEXT_RESPONSE',
  MultipleSelectResponse = 'MULTIPLE_SELECT_RESPONSE',
  ShortTextResponse = 'SHORT_TEXT_RESPONSE',
  SingleSelectResponse = 'SINGLE_SELECT_RESPONSE'
}

export enum ResultAccessAllowedActions {
  Create = 'CREATE',
  SignOff = 'SIGN_OFF'
}

export type ResultAccessModel = {
  __typename?: 'ResultAccessModel';
  allowedActions?: Maybe<Array<ResultAccessAllowedActions>>;
  label?: Maybe<Scalars['String']>;
  latestActionlabel?: Maybe<Scalars['String']>;
  resultAccessOwnerId?: Maybe<Scalars['Int']>;
  status: ResultAccessStatus;
};

export enum ResultAccessStatus {
  Editable = 'EDITABLE',
  LockedForHigherLevel = 'LOCKED_FOR_HIGHER_LEVEL',
  SignedOff = 'SIGNED_OFF'
}

export type ResultCreateOneRoleSkillsSelfAssessmentArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: ISoftSkillSelfAssessmentScoreModel;
};

export type ResultCreateOneStatisticalArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: IStatisticalResultScoreModel;
};

export type ResultCreateOneTrCustomArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  score: ITrCustomResultScoreModel;
};

export type ResultFindManyArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  copiedFroms?: InputMaybe<Array<Scalars['Int']>>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneBys?: InputMaybe<Array<Scalars['Int']>>;
  doneFor?: InputMaybe<Scalars['Int']>;
  doneFors?: InputMaybe<Array<Scalars['Int']>>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']>>;
  label?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Array<Scalars['String']>>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  manuallyAdjustedBys?: InputMaybe<Array<Scalars['Int']>>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementIds?: InputMaybe<Array<Scalars['Int']>>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  measurementTypes?: InputMaybe<Array<ResultMeasurementType>>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  previousVersionIds?: InputMaybe<Array<Scalars['Int']>>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']>>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  projectJobRoleIds?: InputMaybe<Array<Scalars['Int']>>;
  scoreType?: InputMaybe<ResultScoreType>;
  scoreTypes?: InputMaybe<Array<ResultScoreType>>;
  version?: InputMaybe<Scalars['Int']>;
  versions?: InputMaybe<Array<Scalars['Int']>>;
};

export type ResultFindOneArgs = {
  copiedFrom?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  manuallyAdjustedBy?: InputMaybe<Scalars['Int']>;
  measurementId?: InputMaybe<Scalars['Int']>;
  measurementType?: InputMaybe<ResultMeasurementType>;
  normGroupStatisticsId?: InputMaybe<Scalars['Int']>;
  onlyLatest?: InputMaybe<Scalars['Boolean']>;
  onlyLatestVersionPerLabel?: InputMaybe<Scalars['Boolean']>;
  previousVersionId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  scoreType?: InputMaybe<ResultScoreType>;
  version?: InputMaybe<Scalars['Int']>;
};

export enum ResultMeasurementType {
  Behaviour = 'BEHAVIOUR',
  InterviewGuide = 'INTERVIEW_GUIDE',
  SoftSkill = 'SOFT_SKILL',
  SuccessProfile = 'SUCCESS_PROFILE',
  TechnicalSkill = 'TECHNICAL_SKILL',
  Trait = 'TRAIT'
}

export type ResultModel = {
  __typename?: 'ResultModel';
  copiedFrom?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  id: Scalars['Int'];
  label?: Maybe<Scalars['String']>;
  manuallyAdjustedBy?: Maybe<Scalars['Int']>;
  measurementId: Scalars['Int'];
  measurementType: ResultMeasurementType;
  previousVersionId?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  projectJobRoleId?: Maybe<Scalars['Int']>;
  score: ResultScoreUnion;
  scoreType: ResultScoreType;
  updatedAt: Scalars['Date'];
  version: Scalars['Int'];
};

export enum ResultScoreType {
  ExternalAssessmentCode = 'EXTERNAL_ASSESSMENT_CODE',
  ExternalAssessmentFinal = 'EXTERNAL_ASSESSMENT_FINAL',
  ExternalAssessmentMcq = 'EXTERNAL_ASSESSMENT_MCQ',
  IgBehaviour = 'IG_BEHAVIOUR',
  IgCustom = 'IG_CUSTOM',
  IgFinalDecision = 'IG_FINAL_DECISION',
  Motivation = 'MOTIVATION',
  RoleSoftSkillsProfile = 'ROLE_SOFT_SKILLS_PROFILE',
  RoleTechnicalSkillsProfile = 'ROLE_TECHNICAL_SKILLS_PROFILE',
  Statistical = 'STATISTICAL',
  TrCustom = 'TR_CUSTOM'
}

export type ResultScoreUnion = ExternalAssessmentCodeScoreModel | ExternalAssessmentFinalScoreModel | ExternalAssessmentMcqScoreModel | IgBehaviourResultScoreModel | IgCustomResultScoreModel | IgFinalDecisionResultScoreModel | MotivationResultScoreModel | SoftSkillSelfAssessmentScoreModel | StatisticalResultScoreModel | TechnicalSkillSelfAssessmentScoreModel | TrCustomResultScoreModel;

export type ResultSoftSkillProfileCreateOneFromSc = {
  score: ISoftSkillSelfAssessmentScoreModel;
  softSkillId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};

export type ResultTechnicalSkillProfileCreateOneFromSc = {
  score: ITechnicalSkillSelfAssessmentScoreModel;
  stageCandidateId: Scalars['Int'];
  technicalSkillId: Scalars['Int'];
};

export type ResultValidatedSkillsModel = {
  __typename?: 'ResultValidatedSkillsModel';
  company: Company;
  totalValidateSkills: Scalars['Int'];
};

export type ResultValidatedSkillsPaginatedModel = {
  __typename?: 'ResultValidatedSkillsPaginatedModel';
  resultValidatedSkills: Array<ResultValidatedSkillsModel>;
  total: Scalars['Int'];
};

export type Role = {
  __typename?: 'Role';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  type: RoleType;
  uuid?: Maybe<Scalars['String']>;
};

export enum RoleType {
  Company = 'COMPANY',
  External = 'EXTERNAL',
  ModuleAccess = 'MODULE_ACCESS',
  SzProtected = 'SZ_PROTECTED',
  SzSingleton = 'SZ_SINGLETON'
}

export type RssAssessmentCandidateModel = {
  __typename?: 'RssAssessmentCandidateModel';
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  rssAssessment: RssAssessmentModel;
  rssAssessmentId: Scalars['Int'];
  stageCandidate?: Maybe<SmStageCandidateModel>;
  stageCandidateId: Scalars['Int'];
  status: RssAssessmentCandidateStatus;
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum RssAssessmentCandidateStatus {
  NotStarted = 'NOT_STARTED',
  OpenForChanges = 'OPEN_FOR_CHANGES',
  Submitted = 'SUBMITTED'
}

export type RssAssessmentCreateOneArgs = {
  stageId: Scalars['Int'];
  type: RssAssessmentType;
};

export type RssAssessmentModel = {
  __typename?: 'RssAssessmentModel';
  createdAt: Scalars['Date'];
  id: Scalars['Int'];
  stage?: Maybe<SmStageModel>;
  stageId: Scalars['Int'];
  subId: Scalars['String'];
  type: RssAssessmentType;
};

export enum RssAssessmentType {
  SoftSkill = 'SOFT_SKILL',
  TechnicalSkill = 'TECHNICAL_SKILL'
}

export type RssCandidateOpenOneModel = {
  __typename?: 'RssCandidateOpenOneModel';
  gradeBands: Array<GradeBandUnion>;
  rssAssessmentCandidate: RssAssessmentCandidateModel;
  skillsWithResults: Array<SkillWithResultModel>;
};

export type SamlAuthInfoModel = {
  __typename?: 'SamlAuthInfoModel';
  cert: Scalars['String'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  entryPoint: Scalars['String'];
  id: Scalars['Int'];
  identityProvider: IdentityProviderModel;
  identityProviderId: Scalars['Int'];
  issuer: Scalars['String'];
  signatureAlgorithm: Scalars['String'];
  subId: Scalars['String'];
  updatedAt: Scalars['Date'];
  wantAssertionsSigned: Scalars['Boolean'];
  wantAuthResponseSigned: Scalars['Boolean'];
};

export type ScheduleRoiCsvFileExportArgs = {
  __typename?: 'ScheduleROICsvFileExportArgs';
  projectIds: Array<Scalars['Int']>;
};

export type ScheduleStageCandidateResultsCsvFileExportArgs = {
  __typename?: 'ScheduleStageCandidateResultsCsvFileExportArgs';
  exportLevel: CandidateResultsExportLevel;
  includeDeiData?: Maybe<Scalars['Boolean']>;
  includeFormsData?: Maybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
};

export type ScheduledTask = {
  args: ScheduledTaskArgs;
  error?: Maybe<Scalars['String']>;
  kind: ScheduledTaskKind;
  status: ScheduledTaskStatus;
  subId: Scalars['String'];
};

export type ScheduledTaskArgs = ScheduleRoiCsvFileExportArgs | ScheduleStageCandidateResultsCsvFileExportArgs;

export enum ScheduledTaskKind {
  RoiCsvFileExport = 'ROI_CSV_FILE_EXPORT',
  StageCandidateResultsCsvFileExport = 'STAGE_CANDIDATE_RESULTS_CSV_FILE_EXPORT'
}

export enum ScheduledTaskStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Running = 'RUNNING',
  Scheduled = 'SCHEDULED'
}

export type ScoreModel = {
  __typename?: 'ScoreModel';
  customEvaluation?: Maybe<Scalars['String']>;
  customScore?: Maybe<Scalars['Float']>;
  grade?: Maybe<Grade>;
  percentile?: Maybe<Scalars['Float']>;
  stenScore?: Maybe<Scalars['Float']>;
  zScore?: Maybe<Scalars['Float']>;
};

export type ShortTextField = Field & {
  __typename?: 'ShortTextField';
  fieldInstanceType: FieldInstanceType;
  fieldOwnerId?: Maybe<Scalars['Int']>;
  fieldOwnerName?: Maybe<Scalars['String']>;
  fieldOwnerType?: Maybe<FieldOwnerType>;
  fieldType: FieldType;
  formManagerType: FormManagerType;
  hint?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  settings?: Maybe<FieldSettingsModel>;
  txtData?: Maybe<Scalars['String']>;
};

export type SingleSelectField = Field & {
  __typename?: 'SingleSelectField';
  fieldInstanceType: FieldInstanceType;
  fieldOwnerId?: Maybe<Scalars['Int']>;
  fieldOwnerName?: Maybe<Scalars['String']>;
  fieldOwnerType?: Maybe<FieldOwnerType>;
  fieldType: FieldType;
  formManagerType: FormManagerType;
  hint?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  isFreetextField?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  selectOptions?: Maybe<Scalars['String']>;
  settings?: Maybe<FieldSettingsModel>;
  txtData?: Maybe<Scalars['String']>;
};

export type SkillCategory = {
  __typename?: 'SkillCategory';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type SkillCategoryCreateOneArgs = {
  name: Scalars['String'];
};

export type SkillCategoryUpdateOneArgs = {
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
};

export enum SkillType {
  SoftSkill = 'SOFT_SKILL',
  TechnicalSkill = 'TECHNICAL_SKILL'
}

export type SkillUnion = SoftSkill | TechnicalSkill;

export type SkillWithResultModel = {
  __typename?: 'SkillWithResultModel';
  result?: Maybe<ResultModel>;
  skill: SkillUnion;
};

export type SkillsProfileOpenOneModel = {
  __typename?: 'SkillsProfileOpenOneModel';
  skillsWithResults: Array<SpSkillWithResultModel>;
  softSkillGradeBands: Array<SoftSkillSelfAssessmentGradeBandModel>;
  technicalSkillGradeBands: Array<TechnicalSkillSelfAssessmentGradeBandModel>;
};

export type SmInviteHiringDoneForsAndProjectManagersArgs = {
  doneForNewUser: SmInviteNewUserDetailsArgs;
  projectManagerNewUsers?: InputMaybe<Array<SmInviteHiringManagerDetailsArgs>>;
};

export type SmInviteHiringManagerDetailsArgs = {
  email: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type SmInviteNewUserDetailsArgs = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  locale?: InputMaybe<SupportedLocale>;
};

export enum SmInviteType {
  Hiring = 'HIRING',
  TalentReview = 'TALENT_REVIEW'
}

export type SmStageCandidateModel = {
  __typename?: 'SmStageCandidateModel';
  actions: StageCandidateActionsModel;
  applicationId?: Maybe<Scalars['String']>;
  assignees: Array<ProjectManager>;
  calibrationCandidate?: Maybe<CalibrationCandidateModel>;
  /** @deprecated Please use doneFor instead! */
  candidateId: Scalars['Int'];
  candidateProjectManagerIds?: Maybe<Array<Scalars['Int']>>;
  companyId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Date'];
  deiStageActionCandidate?: Maybe<DeiStageActionCandidateModel>;
  deletedAt?: Maybe<Scalars['Date']>;
  doneBy: Scalars['Int'];
  doneByUser: User;
  doneFor: Scalars['Int'];
  doneForUser: User;
  eaStageActionCandidates?: Maybe<Array<Maybe<EaStageActionCandidateModel>>>;
  formCandidates?: Maybe<Array<Maybe<FormCandidateModel>>>;
  hasCompletedAdjustmentQuestionnaire: Scalars['Boolean'];
  hasPledgedAnticheating: Scalars['Boolean'];
  hiringManagers: Array<User>;
  id: Scalars['Int'];
  interviewGuide?: Maybe<InterviewGuideModel>;
  isNew: Scalars['Boolean'];
  lastDateOfCandidateAction?: Maybe<Scalars['Date']>;
  projectTrackerId: Scalars['Int'];
  results?: Maybe<Array<ResultModel>>;
  rssAssessmentCandidates?: Maybe<Array<RssAssessmentCandidateModel>>;
  stage: SmStageModel;
  stageActions: Array<StageActionModel>;
  stageId: Scalars['Int'];
  /** @deprecated Stage result is removed */
  stageResult?: Maybe<SmStageResultModel>;
  status: StageCandidateStatus;
  subId: Scalars['String'];
  testCandidates?: Maybe<Array<Maybe<TmTestCandidateModel>>>;
  updatedAt: Scalars['Date'];
};

export type SmStageModel = {
  __typename?: 'SmStageModel';
  availableActions: Array<StageAvailableActionModel>;
  createInterviewGuide: Scalars['Boolean'];
  createdAt: Scalars['Date'];
  deiStageAction?: Maybe<DeiStageActionModel>;
  deiStageActionTemplate?: Maybe<DeiStageActionTemplateModel>;
  deletedAt?: Maybe<Scalars['Date']>;
  emailCandidateReport: Scalars['Boolean'];
  emailManagerReport: Scalars['Boolean'];
  emailProjectTeam: Scalars['Boolean'];
  emailProjectTeamRoles: Array<ActorRole>;
  emailSzAdmin: Scalars['Boolean'];
  enableF2fInterviews: Scalars['Boolean'];
  endTime?: Maybe<Scalars['Date']>;
  experimentalNormGroups: Scalars['Boolean'];
  formId?: Maybe<Scalars['Int']>;
  hasAdjustmentQuestionnaire: Scalars['Boolean'];
  hasCalibration: Scalars['Boolean'];
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
  managerReportEmailLinks: Scalars['Boolean'];
  name: Scalars['String'];
  order: Scalars['Int'];
  projectId: Scalars['Int'];
  renderCandidateReport: Scalars['Boolean'];
  rssAssessmentIds?: Maybe<Array<Scalars['Int']>>;
  rssAssessments: Array<RssAssessmentModel>;
  startTime?: Maybe<Scalars['Date']>;
  status: StageStatus;
  subId: Scalars['String'];
  testIds: Array<Scalars['Int']>;
  type: StageType;
  updatedAt: Scalars['Date'];
};

export type SmStageResultModel = {
  __typename?: 'SmStageResultModel';
  calculationMethodUsed?: Maybe<CalculationMethod>;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  gradingMethodUsed?: Maybe<GradingMethod>;
  id: Scalars['Int'];
  scores: Array<BasicScoreModel>;
  stageCandidate: SmStageCandidateModel;
  stageCandidateId: Scalars['Int'];
  structuredScore?: Maybe<StructuredScoreModel>;
  totalScore: BasicScoreModel;
  updatedAt: Scalars['Date'];
};

export type SoftSkill = {
  __typename?: 'SoftSkill';
  category?: Maybe<Scalars['String']>;
  company?: Maybe<Company>;
  companyId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Blob>;
  iconBlobId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  measurementConstructType?: Maybe<MeasurementConstructType>;
  name: Scalars['String'];
  softSkillTraits?: Maybe<Array<SoftSkillTrait>>;
};

export enum SoftSkillContentHeaderType {
  InterviewInstructions = 'INTERVIEW_INSTRUCTIONS',
  None = 'NONE'
}

export enum SoftSkillImageType {
  CategoryImage = 'CATEGORY_IMAGE',
  SoftSkillImage = 'SOFT_SKILL_IMAGE'
}

export type SoftSkillModelPaginated = {
  __typename?: 'SoftSkillModelPaginated';
  data?: Maybe<Array<SoftSkill>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type SoftSkillProjectModuleTypeModel = {
  __typename?: 'SoftSkillProjectModuleTypeModel';
  id: Scalars['Int'];
  projectModuleType: ProjectModuleType;
  softSkillId: Scalars['Int'];
};

export type SoftSkillRoleLevelContent = {
  __typename?: 'SoftSkillRoleLevelContent';
  candidateContent?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  grade: Grade;
  gradeDescription?: Maybe<Scalars['String']>;
  hiringManagerContent?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  internalCandidateContent?: Maybe<Scalars['String']>;
  roleLevel: ContentRoleLevel;
  scoreRange?: Maybe<Scalars['String']>;
  softSkill?: Maybe<SoftSkill>;
  softSkillId?: Maybe<Scalars['Int']>;
};

export type SoftSkillRoleLevelContentPaginated = {
  __typename?: 'SoftSkillRoleLevelContentPaginated';
  count?: Maybe<Scalars['Float']>;
  softSkillContent?: Maybe<Array<SoftSkillRoleLevelContent>>;
};

export type SoftSkillSelfAssessmentGradeBandModel = {
  __typename?: 'SoftSkillSelfAssessmentGradeBandModel';
  displayText: Scalars['String'];
  position: Scalars['Int'];
  proficiency: SoftSkillSelfAssessmentProficiencyLevel;
};

export enum SoftSkillSelfAssessmentProficiencyLevel {
  Beginner = 'BEGINNER',
  Expert = 'EXPERT',
  Intermediate = 'INTERMEDIATE'
}

export type SoftSkillSelfAssessmentScoreModel = {
  __typename?: 'SoftSkillSelfAssessmentScoreModel';
  proficiency: SoftSkillSelfAssessmentProficiencyLevel;
};

export type SoftSkillSuccessProfileContent = {
  __typename?: 'SoftSkillSuccessProfileContent';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  roleLevel: ContentRoleLevel;
  shortDescription?: Maybe<Scalars['String']>;
  softSkill?: Maybe<SoftSkill>;
  softSkillId: Scalars['Int'];
};

export type SoftSkillSuccessProfileContentPaginated = {
  __typename?: 'SoftSkillSuccessProfileContentPaginated';
  count?: Maybe<Scalars['Float']>;
  softSkillContent?: Maybe<Array<SoftSkillSuccessProfileContent>>;
};

export type SoftSkillTrait = {
  __typename?: 'SoftSkillTrait';
  id?: Maybe<Scalars['Int']>;
  orientation: Scalars['Int'];
  softSkill?: Maybe<SoftSkill>;
  softSkillId?: Maybe<Scalars['Int']>;
  softSkillName?: Maybe<Scalars['String']>;
  trait?: Maybe<Trait>;
  traitId?: Maybe<Scalars['Int']>;
  traitName?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
};

export enum SoftSkillType {
  Core = 'CORE',
  Differentiator = 'DIFFERENTIATOR',
  Diversity = 'DIVERSITY'
}

export type SoftSkillTypeSuccessProfileContent = {
  __typename?: 'SoftSkillTypeSuccessProfileContent';
  description: Scalars['String'];
  id: Scalars['Int'];
  softSkillType: SoftSkillType;
};

export enum SortOption {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SpSkillWithResultModel = {
  __typename?: 'SpSkillWithResultModel';
  result: ResultModel;
  skill: SkillUnion;
};

export enum SpSoftSkillSort {
  Category = 'CATEGORY',
  Name = 'NAME'
}

export type StackOneDepartment = {
  __typename?: 'StackOneDepartment';
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type StackOneIntegration = {
  __typename?: 'StackOneIntegration';
  accountId: Scalars['String'];
  createdAt: Scalars['Date'];
  eaIntegration: EaIntegration;
  eaIntegrationId: Scalars['Int'];
  id: Scalars['String'];
  originOwnerId: Scalars['String'];
  originOwnerName: Scalars['String'];
  originUsername: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type StackOneIntegrationPaginated = {
  __typename?: 'StackOneIntegrationPaginated';
  data?: Maybe<Array<StackOneIntegration>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type StackOneJob = {
  __typename?: 'StackOneJob';
  code: Scalars['String'];
  companyId: Scalars['Float'];
  confidential: Scalars['String'];
  createdAt: Scalars['Date'];
  departments: StackOneDepartment;
  hiringTeam: StackOneUser;
  id: Scalars['String'];
  locations: StackOneLocation;
  title: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type StackOneJobProject = {
  __typename?: 'StackOneJobProject';
  companyId: Scalars['Int'];
  createdAt: Scalars['Date'];
  createdBy: Scalars['Int'];
  id: Scalars['String'];
  jobId: Scalars['String'];
  projectId: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type StackOneJobWithProjects = {
  __typename?: 'StackOneJobWithProjects';
  job: StackOneJob;
  jobProject?: Maybe<StackOneJobProject>;
};

export type StackOneJobWithProjectsPaginated = {
  __typename?: 'StackOneJobWithProjectsPaginated';
  data?: Maybe<Array<StackOneJobWithProjects>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type StackOneJobsCoverage = {
  __typename?: 'StackOneJobsCoverage';
  application_count: Scalars['Int'];
  code: Scalars['String'];
  departments: Array<Department>;
  hiring_team: Array<Scalars['String']>;
  id: Scalars['String'];
  job_status: Scalars['String'];
  title: Scalars['String'];
};

export type StackOneJobsDetails = {
  __typename?: 'StackOneJobsDetails';
  applications: Array<JobApplication>;
  job_hiring_status: Scalars['String'];
  job_id: Scalars['String'];
};

export type StackOneJobsExport = {
  __typename?: 'StackOneJobsExport';
  id: Scalars['String'];
  status: Scalars['String'];
};

export type StackOneLocation = {
  __typename?: 'StackOneLocation';
  createdAt: Scalars['Date'];
  id: Scalars['String'];
  name: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export type StackOneUser = {
  __typename?: 'StackOneUser';
  createdAt: Scalars['Date'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName: Scalars['String'];
  role: Scalars['String'];
  updatedAt: Scalars['Date'];
};

export enum StageActionAvailability {
  AlreadyExists = 'ALREADY_EXISTS',
  Available = 'AVAILABLE',
  NotAvailable = 'NOT_AVAILABLE'
}

export type StageActionAvailabilityModel = {
  __typename?: 'StageActionAvailabilityModel';
  reason?: Maybe<Scalars['String']>;
  status: StageActionAvailabilityStatus;
};

export enum StageActionAvailabilityStatus {
  Available = 'AVAILABLE',
  Completed = 'COMPLETED',
  NotAvailable = 'NOT_AVAILABLE'
}

export type StageActionCalibrationModel = StageActionModel & {
  __typename?: 'StageActionCalibrationModel';
  availability: StageActionAvailabilityModel;
  calibrationCandidate: CalibrationCandidateModel;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  status: StageCandidateActionStatus;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export type StageActionCognitiveTestModel = StageActionModel & {
  __typename?: 'StageActionCognitiveTestModel';
  allowedSectionTime?: Maybe<Scalars['Int']>;
  allowedTotalTime?: Maybe<Scalars['Int']>;
  availability: StageActionAvailabilityModel;
  description?: Maybe<Scalars['String']>;
  hasPractice: Scalars['Boolean'];
  instructions?: Maybe<Scalars['String']>;
  status: StageCandidateActionStatus;
  testCandidate: TmTestCandidateModel;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export type StageActionDeiFormModel = StageActionModel & {
  __typename?: 'StageActionDeiFormModel';
  availability: StageActionAvailabilityModel;
  deiStageActionCandidate: DeiStageActionCandidateModel;
  description?: Maybe<Scalars['String']>;
  estimatedTotalTime: Scalars['Int'];
  instructions?: Maybe<Scalars['String']>;
  status: StageCandidateActionStatus;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export type StageActionExternalAssessmentModel = StageActionModel & {
  __typename?: 'StageActionExternalAssessmentModel';
  allowedTotalTime?: Maybe<Scalars['Int']>;
  availability: StageActionAvailabilityModel;
  description?: Maybe<Scalars['String']>;
  eaStageActionCandidate: EaStageActionCandidateModel;
  estimatedTotalTime?: Maybe<Scalars['Int']>;
  instructions?: Maybe<Scalars['String']>;
  invitationLink?: Maybe<Scalars['String']>;
  status: StageCandidateActionStatus;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export type StageActionFormModel = StageActionModel & {
  __typename?: 'StageActionFormModel';
  availability: StageActionAvailabilityModel;
  description?: Maybe<Scalars['String']>;
  estimatedTotalTime?: Maybe<Scalars['Int']>;
  formCandidate: FormCandidateModel;
  instructions?: Maybe<Scalars['String']>;
  status: StageCandidateActionStatus;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export type StageActionModel = {
  availability: StageActionAvailabilityModel;
  description?: Maybe<Scalars['String']>;
  instructions?: Maybe<Scalars['String']>;
  status: StageCandidateActionStatus;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export type StageActionPersonalityTestModel = StageActionModel & {
  __typename?: 'StageActionPersonalityTestModel';
  availability: StageActionAvailabilityModel;
  description?: Maybe<Scalars['String']>;
  estimatedSectionTime?: Maybe<Scalars['Int']>;
  estimatedTotalTime?: Maybe<Scalars['Int']>;
  hasPractice: Scalars['Boolean'];
  instructions?: Maybe<Scalars['String']>;
  status: StageCandidateActionStatus;
  testCandidate: TmTestCandidateModel;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export type StageActionRssAssessmentModel = StageActionModel & {
  __typename?: 'StageActionRssAssessmentModel';
  availability: StageActionAvailabilityModel;
  description?: Maybe<Scalars['String']>;
  estimatedTotalTime?: Maybe<Scalars['Int']>;
  instructions?: Maybe<Scalars['String']>;
  softRssAssessmentCandidate?: Maybe<RssAssessmentCandidateModel>;
  status: StageCandidateActionStatus;
  technicalRssAssessmentCandidate?: Maybe<RssAssessmentCandidateModel>;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export enum StageActionSubType {
  RssAssessmentSoft = 'RSS_ASSESSMENT_SOFT',
  RssAssessmentTechnical = 'RSS_ASSESSMENT_TECHNICAL'
}

export type StageActionSummary = {
  __typename?: 'StageActionSummary';
  estimatedTime?: Maybe<Scalars['Int']>;
  stageActionType: StageActionType;
};

export type StageActionTalentReviewTestModel = StageActionModel & {
  __typename?: 'StageActionTalentReviewTestModel';
  availability: StageActionAvailabilityModel;
  description?: Maybe<Scalars['String']>;
  estimatedSectionTime?: Maybe<Scalars['Int']>;
  estimatedTotalTime?: Maybe<Scalars['Int']>;
  hasPractice: Scalars['Boolean'];
  instructions?: Maybe<Scalars['String']>;
  status: StageCandidateActionStatus;
  testCandidate: TmTestCandidateModel;
  title: Scalars['String'];
  type: StageCandidateActionType;
};

export enum StageActionType {
  Calibration = 'CALIBRATION',
  CognitiveTestInductive = 'COGNITIVE_TEST_INDUCTIVE',
  CognitiveTestNumerical = 'COGNITIVE_TEST_NUMERICAL',
  CognitiveTestVerbal = 'COGNITIVE_TEST_VERBAL',
  DeiForm = 'DEI_FORM',
  ExternalAssessment = 'EXTERNAL_ASSESSMENT',
  Form = 'FORM',
  ManagerReview = 'MANAGER_REVIEW',
  PersonalityTest = 'PERSONALITY_TEST',
  RssAssessment = 'RSS_ASSESSMENT'
}

export type StageActionsModel = {
  __typename?: 'StageActionsModel';
  actions: Array<StageActionModel>;
  company: Company;
  doneByUser: User;
  doneForUser: User;
  project: Project;
  stage: SmStageModel;
  stageCandidate: SmStageCandidateModel;
};

export type StageAvailableActionModel = {
  __typename?: 'StageAvailableActionModel';
  availability: StageActionAvailability;
  name: Scalars['String'];
  subType?: Maybe<StageActionSubType>;
  type: StageActionType;
};

export enum StageCandidateActionStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED',
  Processing = 'PROCESSING'
}

export enum StageCandidateActionType {
  Calibration = 'CALIBRATION',
  CodingExternalAssessment = 'CODING_EXTERNAL_ASSESSMENT',
  CognitiveInductiveTest = 'COGNITIVE_INDUCTIVE_TEST',
  CognitiveNumericalTest = 'COGNITIVE_NUMERICAL_TEST',
  CognitiveVerablTest = 'COGNITIVE_VERABL_TEST',
  DeiForm = 'DEI_FORM',
  Form = 'FORM',
  PersonalityTest = 'PERSONALITY_TEST',
  RssAssessment = 'RSS_ASSESSMENT',
  TalentReviewTest = 'TALENT_REVIEW_TEST'
}

export type StageCandidateActionsModel = {
  __typename?: 'StageCandidateActionsModel';
  rssAssessmentAction: StageActionRssAssessmentModel;
};

export type StageCandidateAssigneeModel = {
  __typename?: 'StageCandidateAssigneeModel';
  id: Scalars['Int'];
  projectTeamMemberId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
};

export enum StageCandidateDeleteLogsReasonEnum {
  AnotherReason = 'ANOTHER_REASON',
  LeftMyTeam = 'LEFT_MY_TEAM',
  LeftTheCompany = 'LEFT_THE_COMPANY',
  NotTheCorrectLevel = 'NOT_THE_CORRECT_LEVEL'
}

export type StageCandidateOrderByArgs = {
  candidateId?: InputMaybe<OrderByArgs>;
  lastDateOfCandidateAction?: InputMaybe<OrderByArgs>;
};

export enum StageCandidateSkillsProfileCategory {
  GeneralSkills = 'GENERAL_SKILLS',
  RightForTheFuture = 'RIGHT_FOR_THE_FUTURE',
  RightForTheRole = 'RIGHT_FOR_THE_ROLE',
  RightForUs = 'RIGHT_FOR_US'
}

export type StageCandidateSkillsProfileCategoryResultsModel = {
  __typename?: 'StageCandidateSkillsProfileCategoryResultsModel';
  name: StageCandidateSkillsProfileCategory;
  score?: Maybe<StatisticalResultScoreModel>;
  skills: Array<StageCandidateSkillsProfileSkillResultModel>;
};

export type StageCandidateSkillsProfileResultsModel = {
  __typename?: 'StageCandidateSkillsProfileResultsModel';
  categories: Array<StageCandidateSkillsProfileCategoryResultsModel>;
  projectJobRoleId: Scalars['Int'];
  score?: Maybe<StatisticalResultScoreModel>;
  skillsProfileId: Scalars['Int'];
  stageCandidateCreatedAt: Scalars['Date'];
  stageCandidateId: Scalars['Int'];
  stageCandidateLastDateOfCandidateAction?: Maybe<Scalars['Date']>;
  stageCandidateStatus: StageCandidateStatus;
};

export type StageCandidateSkillsProfileSkillResultModel = {
  __typename?: 'StageCandidateSkillsProfileSkillResultModel';
  id: Scalars['Int'];
  name: Scalars['String'];
  score?: Maybe<ResultScoreUnion>;
  type: SkillType;
};

export type StageCandidateStatisticsByProjectJobRoleFindManyFilterArgs = {
  lastDateOfCandidateActionGte?: InputMaybe<Scalars['Date']>;
  lastDateOfCandidateActionLte?: InputMaybe<Scalars['Date']>;
  nameOrEmailLike?: InputMaybe<Scalars['String']>;
  overallScorePercentileGte?: InputMaybe<Scalars['Float']>;
  overallScorePercentileLte?: InputMaybe<Scalars['Float']>;
};

export type StageCandidateStatisticsByProjectJobRoleFindManyOrderArgs = {
  direction?: InputMaybe<SortOption>;
  field?: InputMaybe<StageCandidateStatisticsByProjectJobRoleOrderField>;
};

export type StageCandidateStatisticsByProjectJobRoleFindManyOutput = {
  __typename?: 'StageCandidateStatisticsByProjectJobRoleFindManyOutput';
  page: Pageinfo;
  projectJobRole: ProjectJobRoleModel;
  stageCandidatesStatisticsByJobRole: Array<StageCandidateStatisticsByProjectJobRoleModel>;
};

export type StageCandidateStatisticsByProjectJobRoleModel = {
  __typename?: 'StageCandidateStatisticsByProjectJobRoleModel';
  cognitiveCombinedScore?: Maybe<StatisticalResultScoreModel>;
  overallScore?: Maybe<StatisticalResultScoreModel>;
  projectJobRole: ProjectJobRoleModel;
  rightForTheFutureScore?: Maybe<StatisticalResultScoreModel>;
  rightForTheRoleScore?: Maybe<StatisticalResultScoreModel>;
  rightForUsScore?: Maybe<StatisticalResultScoreModel>;
  stageCandidate: SmStageCandidateModel;
};

export enum StageCandidateStatisticsByProjectJobRoleOrderField {
  CognitiveCombinedScore = 'CognitiveCombinedScore',
  LastDateOfCandidateAction = 'LastDateOfCandidateAction',
  Name = 'Name',
  OverallScore = 'OverallScore',
  RightForTheFutureScore = 'RightForTheFutureScore',
  RightForTheRoleScore = 'RightForTheRoleScore',
  RightForUsScore = 'RightForUsScore'
}

export type StageCandidateStatisticsFindManyFilterArgs = {
  createdAtGte?: InputMaybe<Scalars['Date']>;
  createdAtLte?: InputMaybe<Scalars['Date']>;
  lastDateOfCandidateActionGte?: InputMaybe<Scalars['Date']>;
  lastDateOfCandidateActionLte?: InputMaybe<Scalars['Date']>;
  nameOrEmailContains?: InputMaybe<Scalars['String']>;
  statusIn?: InputMaybe<Array<StageCandidateStatus>>;
};

export type StageCandidateStatisticsFindManyOrderArgs = {
  direction?: InputMaybe<SortOption>;
  field?: InputMaybe<StageCandidateStatisticsOrderField>;
};

export type StageCandidateStatisticsFindManyOutput = {
  __typename?: 'StageCandidateStatisticsFindManyOutput';
  page: Pageinfo;
  stage: SmStageModel;
  stageCandidatesStatistics: Array<StageCandidateStatisticsModel>;
};

export type StageCandidateStatisticsModel = {
  __typename?: 'StageCandidateStatisticsModel';
  stageCandidate: SmStageCandidateModel;
  stageCandidateStatisticsProjectJobRoles: Array<StageCandidateStatisticsProjectJobRoleModel>;
};

export enum StageCandidateStatisticsOrderField {
  CreatedAt = 'CreatedAt',
  LastDateOfCandidateAction = 'LastDateOfCandidateAction',
  Name = 'Name',
  Status = 'Status'
}

export type StageCandidateStatisticsProjectJobRoleModel = {
  __typename?: 'StageCandidateStatisticsProjectJobRoleModel';
  cognitiveCombinedScore?: Maybe<StatisticalResultScoreModel>;
  hiringDecision?: Maybe<HiringDecisionModel>;
  overallScore?: Maybe<StatisticalResultScoreModel>;
  projectJobRole: ProjectJobRoleModel;
  reports: Array<StageCandidateStatisticsProjectJobRoleReportModel>;
  rightForTheFutureScore?: Maybe<StatisticalResultScoreModel>;
  rightForTheRoleScore?: Maybe<StatisticalResultScoreModel>;
  rightForUsScore?: Maybe<StatisticalResultScoreModel>;
  technicalSkillResults: Array<EaResultUniversalModel>;
};

export type StageCandidateStatisticsProjectJobRoleReportModel = {
  __typename?: 'StageCandidateStatisticsProjectJobRoleReportModel';
  blob: Blob;
  projectJobRoleId: Scalars['Int'];
  stageCandidateId: Scalars['Int'];
  subType?: Maybe<ReportSubType>;
  type: ReportType;
};

export enum StageCandidateStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  ManagerCompleted = 'MANAGER_COMPLETED',
  NotStarted = 'NOT_STARTED',
  Processing = 'PROCESSING',
  SignedOff = 'SIGNED_OFF'
}

export type StageCardModel = {
  __typename?: 'StageCardModel';
  candidateReportId?: Maybe<Scalars['Int']>;
  company: Company;
  /** @deprecated Please use `company { id }` instead!-T@10/12/2022 */
  companyId: Scalars['Int'];
  /** @deprecated Please use `company { logoUrl }` instead!-T@10/12/2022 */
  companyLogoUrl?: Maybe<Scalars['String']>;
  /** @deprecated Please use `company { companyName }` instead!-T@10/12/2022 */
  companyName: Scalars['String'];
  /** @deprecated Please use doneByUser.id instead!-T@10/12/2022 */
  doneBy: Scalars['Int'];
  /** @deprecated Please use doneByUser.firstName instead!-T@10/12/2022 */
  doneByFirstName: Scalars['String'];
  /** @deprecated Please use doneByUser.lastName instead!-T@10/12/2022 */
  doneByLastName: Scalars['String'];
  doneByUser: User;
  /** @deprecated Please use doneForUser.id instead!-T@10/12/2022 */
  doneFor: Scalars['Int'];
  /** @deprecated Please use doneForUser.firstName instead!-T@10/12/2022 */
  doneForFirstName: Scalars['String'];
  /** @deprecated Please use doneForUser.lastName instead!-T@10/12/2022 */
  doneForLastName: Scalars['String'];
  doneForUser: User;
  /** @deprecated Please use `stage { endTime }` instead!-T@10/12/2022 */
  endTime?: Maybe<Scalars['Date']>;
  inspectable: Scalars['Boolean'];
  /** @deprecated Please use `stageCandidate { lastDateOfCandidateAction }` instead!-T@10/12/2022 */
  lastDateOfCandidateAction?: Maybe<Scalars['Date']>;
  project: Project;
  /** @deprecated Please use `project { id }` instead!-T@10/12/2022 */
  projectId: Scalars['Int'];
  /** @deprecated Please use `project { moduleType }` instead!-T@10/12/2022 */
  projectModuleType: ProjectModuleType;
  /** @deprecated Please use `project { name }` instead!-T@10/12/2022 */
  projectName: Scalars['String'];
  /** @deprecated Please use `stage { renderCandidateReport }` instead!-T@10/12/2022 */
  renderCandidateReport: Scalars['Boolean'];
  stage: SmStageModel;
  stageCandidate: SmStageCandidateModel;
  /** @deprecated Please use `stageCandidate { id }` instead!-T@10/12/2022 */
  stageCandidateId: Scalars['Int'];
  /** @deprecated Please use `stageCandidate { status }` instead!-T@10/12/2022 */
  stageCandidateStatus: StageCandidateStatus;
  /** @deprecated Please use `stage { id }` instead!-T@10/12/2022 */
  stageId: Scalars['Int'];
  /** @deprecated Please use `stage { name }` instead!-T@10/12/2022 */
  stageName: Scalars['String'];
  /** @deprecated Never used AFAIK! */
  stageResultGrade?: Maybe<Grade>;
  /** @deprecated Please use `stage { name }` instead!-T@10/12/2022 */
  stageStatus: StageStatus;
  /** @deprecated Please use `stage { startTime }` instead!-T@10/12/2022 */
  startTime?: Maybe<Scalars['Date']>;
};

export enum StageCsvOptions {
  AllScoresAllMeasures = 'ALL_SCORES_ALL_MEASURES',
  Custom = 'CUSTOM',
  PercentileAllMeasures = 'PERCENTILE_ALL_MEASURES',
  PercentileCategoryCog = 'PERCENTILE_CATEGORY_COG',
  PercentileSoftSkills = 'PERCENTILE_SOFT_SKILLS'
}

export type StageGetCsvReportModel = {
  __typename?: 'StageGetCsvReportModel';
  blobName: Scalars['String'];
};

export enum StageStatus {
  Active = 'ACTIVE',
  Expired = 'EXPIRED',
  Scheduled = 'SCHEDULED'
}

export enum StageType {
  External = 'EXTERNAL',
  Full = 'FULL',
  Partial = 'PARTIAL'
}

export type StartSzTestModel = {
  __typename?: 'StartSzTestModel';
  assessmentId: Scalars['Int'];
};

export type StartTpTestModel = {
  __typename?: 'StartTpTestModel';
  url: Scalars['String'];
};

export type StatisticalGradeBandModel = {
  __typename?: 'StatisticalGradeBandModel';
  displayText: Scalars['String'];
  grade: Grade;
  position: Scalars['Int'];
};

export type StatisticalResultScoreModel = {
  __typename?: 'StatisticalResultScoreModel';
  grade: Grade;
  percentile: Scalars['Float'];
  stenScore: Scalars['Float'];
  zScore: Scalars['Float'];
};

export type StructuredScoreModel = {
  __typename?: 'StructuredScoreModel';
  children?: Maybe<Array<StructuredScoreModel>>;
  id: Scalars['Int'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  type: BasicScoreType;
};

export type SuccessProfile = {
  __typename?: 'SuccessProfile';
  calculationMethod: CalculationMethod;
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  gradingMethod: GradingMethod;
  id: Scalars['Int'];
  jobRole: JobRoleModel;
  jobRoleId: Scalars['Int'];
  roleLevel: ContentRoleLevel;
  status: SuccessProfileStatus;
  successProfileSoftSkills?: Maybe<Array<SuccessProfileSoftSkill>>;
  successProfileTechnicalSkills?: Maybe<Array<SuccessProfileTechnicalSkill>>;
  updatedAt: Scalars['Date'];
};

export type SuccessProfileExplanation = {
  __typename?: 'SuccessProfileExplanation';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  softSkillExplanations?: Maybe<Array<SuccessProfileSoftSkillExplanation>>;
  softSkillTypeExplanations?: Maybe<Array<SuccessProfileSoftSkillTypeExplanation>>;
  status: SuccessProfileExplanationStatus;
  successProfile: SuccessProfile;
  successProfileId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export enum SuccessProfileExplanationStatus {
  Complete = 'COMPLETE',
  Editing = 'EDITING'
}

export type SuccessProfileSoftSkill = {
  __typename?: 'SuccessProfileSoftSkill';
  id: Scalars['Int'];
  includeInCalibration: Scalars['Boolean'];
  name: Scalars['String'];
  softSkill: SoftSkill;
  softSkillId: Scalars['Int'];
  successProfile: SuccessProfile;
  successProfileId: Scalars['Int'];
  successProfileSoftSkillDataSources?: Maybe<Array<SuccessProfileSoftSkillDataSource>>;
  successProfileSoftSkillTraits?: Maybe<Array<SuccessProfileSoftSkillTrait>>;
  type: SoftSkillType;
  weight: Scalars['Float'];
};

export type SuccessProfileSoftSkillCreateOneArgs = {
  dataSources?: InputMaybe<Array<SuccessProfileSoftSkillDataSourceCreateOneBase>>;
  includeInCalibration?: InputMaybe<Scalars['Boolean']>;
  softSkillId: Scalars['Int'];
  successProfileId?: InputMaybe<Scalars['Int']>;
  traits?: InputMaybe<Array<SuccessProfileSoftSkillTraitCreateOneArgs>>;
  type: SoftSkillType;
  weight?: InputMaybe<Scalars['Float']>;
};

export type SuccessProfileSoftSkillDataSource = {
  __typename?: 'SuccessProfileSoftSkillDataSource';
  dataSourceType: DataSourceType;
  id: Scalars['Int'];
  orientation?: Maybe<Scalars['Int']>;
  softSkillType: SoftSkillType;
  successProfileSoftSkill: SuccessProfileSoftSkill;
  successProfileSoftSkillId: Scalars['Int'];
};

export type SuccessProfileSoftSkillDataSourceCreateOneBase = {
  dataSourceType: DataSourceType;
  softSkillType: SoftSkillType;
};

export type SuccessProfileSoftSkillExplanation = {
  __typename?: 'SuccessProfileSoftSkillExplanation';
  defaultContent?: Maybe<SoftSkillSuccessProfileContent>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  shortDescription?: Maybe<Scalars['String']>;
  softSkill: SoftSkill;
  softSkillId: Scalars['Int'];
  successProfileExplanation: SuccessProfileExplanation;
  successProfileExplanationId: Scalars['Int'];
};

export type SuccessProfileSoftSkillTrait = {
  __typename?: 'SuccessProfileSoftSkillTrait';
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  orientation?: Maybe<Scalars['Int']>;
  softSkill: SoftSkill;
  softSkillId: Scalars['Int'];
  successProfileSoftSkill: SuccessProfileSoftSkill;
  successProfileSoftSkillId: Scalars['Int'];
  trait: Trait;
  traitId: Scalars['Int'];
  weight: Scalars['Float'];
};

export type SuccessProfileSoftSkillTraitCreateOneArgs = {
  orientation?: InputMaybe<Scalars['Int']>;
  softSkillId?: InputMaybe<Scalars['Int']>;
  successProfileSoftSkillId?: InputMaybe<Scalars['Int']>;
  traitId: Scalars['Int'];
  weight?: InputMaybe<Scalars['Float']>;
};

export type SuccessProfileSoftSkillTypeExplanation = {
  __typename?: 'SuccessProfileSoftSkillTypeExplanation';
  defaultContent?: Maybe<SoftSkillTypeSuccessProfileContent>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  softSkillType: SoftSkillType;
  successProfileExplanation: SuccessProfileExplanation;
  successProfileExplanationId: Scalars['Int'];
};

export enum SuccessProfileStatus {
  Complete = 'COMPLETE',
  MissingSoftSkills = 'MISSING_SOFT_SKILLS'
}

export type SuccessProfileTechnicalSkill = {
  __typename?: 'SuccessProfileTechnicalSkill';
  id: Scalars['Int'];
  successProfile?: Maybe<SuccessProfile>;
  successProfileId: Scalars['Int'];
  technicalSkill?: Maybe<TechnicalSkill>;
  technicalSkillId: Scalars['Int'];
};

export type SuccessProfileTechnicalSkillCreateOneArgs = {
  successProfileId?: InputMaybe<Scalars['Int']>;
  technicalSkillId: Scalars['Int'];
};

export type SuccessProfileTechnicalSkillUpdateOneArgs = {
  id: Scalars['Int'];
  successProfileId?: InputMaybe<Scalars['Int']>;
  technicalSkillId?: InputMaybe<Scalars['Int']>;
};

export type SummaryNotesModel = {
  __typename?: 'SummaryNotesModel';
  id: Scalars['Int'];
  projectJobRole: ProjectJobRoleModel;
  projectJobRoleId: Scalars['Int'];
  stageCandidate: SmStageCandidateModel;
  stageCandidateId: Scalars['Int'];
  summary?: Maybe<Scalars['String']>;
};

export enum SupportedLocale {
  Chinese = 'CHINESE',
  ChineseTraditional = 'CHINESE_TRADITIONAL',
  Danish = 'DANISH',
  Dutch = 'DUTCH',
  English = 'ENGLISH',
  French = 'FRENCH',
  German = 'GERMAN',
  Indonesian = 'INDONESIAN',
  Italian = 'ITALIAN',
  Korean = 'KOREAN',
  Malay = 'MALAY',
  SpanishLatinAmerican = 'SPANISH_LATIN_AMERICAN',
  Turkish = 'TURKISH'
}

export type SzSkillsMerge = {
  __typename?: 'SzSkillsMerge';
  category?: Maybe<SkillCategory>;
  categoryId?: Maybe<Scalars['Int']>;
  companyId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<Blob>;
  iconBlobId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  measurementConstructType?: Maybe<MeasurementConstructType>;
  name: Scalars['String'];
  originId: Scalars['Int'];
  skillType: SkillType;
};

export type SzSkillsMergeCreateOneArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name: Scalars['String'];
  originId: Scalars['Int'];
  skillType: SkillType;
};

export type SzSkillsMergeUpdateOneArgs = {
  categoryId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  measurementConstructType?: InputMaybe<MeasurementConstructType>;
  name?: InputMaybe<Scalars['String']>;
  originId?: InputMaybe<Scalars['Int']>;
  skillType?: InputMaybe<SkillType>;
};

export type SzSkillsMergeWithAvailability = {
  __typename?: 'SzSkillsMergeWithAvailability';
  alreadyExist: Scalars['Boolean'];
  result?: Maybe<ResultModel>;
  skill: SzSkillsMerge;
};

export enum TrCriticalityEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum TrImpactEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum TrNumberSuccessorsEnum {
  One = 'ONE',
  ThreeOrMore = 'THREE_OR_MORE',
  Two = 'TWO',
  Zero = 'ZERO'
}

export enum TrRiskEnum {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum TrScoreEnum {
  RisingTalent = 'RISING_TALENT',
  SolidContributor = 'SOLID_CONTRIBUTOR',
  StarTalent = 'STAR_TALENT',
  UnderperformingTalent = 'UNDERPERFORMING_TALENT'
}

export type TalentReviewFileUploadArgs = {
  endTime?: InputMaybe<Scalars['Date']>;
  includeDeadlineInEmail?: InputMaybe<Scalars['Boolean']>;
  projectId: Scalars['Int'];
  sendEmail: Scalars['Boolean'];
};

export type TalentReviewFileUploadLineModel = {
  __typename?: 'TalentReviewFileUploadLineModel';
  directReportEmail?: Maybe<Scalars['String']>;
  directReportName?: Maybe<Scalars['String']>;
  managerEmail?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
};

export type TalentReviewFileUploadModel = {
  __typename?: 'TalentReviewFileUploadModel';
  apply: Scalars['Boolean'];
  creates?: Maybe<Array<TalentReviewFileUploadLineModel>>;
  deletes?: Maybe<Array<TalentReviewFileUploadLineModel>>;
  endTime?: Maybe<Scalars['Date']>;
  errorMessage?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<TalentReviewFileUploadLineModel>>;
  includeDeadlineInEmail?: Maybe<Scalars['Boolean']>;
  noChanges?: Maybe<Array<TalentReviewFileUploadLineModel>>;
  projectId: Scalars['Int'];
  sendEmail: Scalars['Boolean'];
  updates?: Maybe<Array<TalentReviewFileUploadLineModel>>;
};

export type TechnicalSkill = {
  __typename?: 'TechnicalSkill';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  icon?: Maybe<Blob>;
  iconBlobId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  skillCategory?: Maybe<SkillCategory>;
  skillCategoryId?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Date'];
};

export type TechnicalSkillCreateOneArgs = {
  iconBlobId?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  skillCategoryId?: InputMaybe<Scalars['Int']>;
};

export type TechnicalSkillSelfAssessmentGradeBandModel = {
  __typename?: 'TechnicalSkillSelfAssessmentGradeBandModel';
  displayText: Scalars['String'];
  position: Scalars['Int'];
  technicalProficiency: TechnicalSkillSelfAssessmentProficiencyLevel;
};

export enum TechnicalSkillSelfAssessmentProficiencyLevel {
  Beginner = 'BEGINNER',
  Expert = 'EXPERT',
  Intermediate = 'INTERMEDIATE',
  NoKnowledge = 'NO_KNOWLEDGE'
}

export type TechnicalSkillSelfAssessmentScoreModel = {
  __typename?: 'TechnicalSkillSelfAssessmentScoreModel';
  technicalProficiency: TechnicalSkillSelfAssessmentProficiencyLevel;
};

export enum TechnicalSkillSortField {
  Date = 'DATE',
  Name = 'NAME'
}

export type TechnicalSkillUpdateOneArgs = {
  iconBlobId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
};

export enum TestFullType {
  CognitiveInductive = 'CognitiveInductive',
  CognitiveNumerical = 'CognitiveNumerical',
  CognitiveVerbal = 'CognitiveVerbal',
  Personality = 'Personality',
  TalentReview = 'TalentReview'
}

export enum TestOrigin {
  SpottedZebra = 'SPOTTED_ZEBRA',
  TestPartnership = 'TEST_PARTNERSHIP'
}

export enum TestStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export enum TestSubType {
  Inductive = 'INDUCTIVE',
  Numerical = 'NUMERICAL',
  Verbal = 'VERBAL'
}

export enum TestType {
  Cognitive = 'COGNITIVE',
  Personality = 'PERSONALITY',
  TalentReview = 'TALENT_REVIEW'
}

export type TmTestCandidateModel = {
  __typename?: 'TmTestCandidateModel';
  assessmentId?: Maybe<Scalars['Int']>;
  /** @deprecated This will be removed in the future. Renamed to "doneFor". */
  candidateId: Scalars['Int'];
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  doneBy: Scalars['Int'];
  doneFor: Scalars['Int'];
  id: Scalars['Int'];
  results?: Maybe<Array<ResultModel>>;
  stageCandidate?: Maybe<SmStageCandidateModel>;
  stageCandidateId: Scalars['Int'];
  status: TestStatus;
  test?: Maybe<TmTestModel>;
  testId: Scalars['Int'];
  testOrigin: TestOrigin;
  /** @deprecated Test result is removed */
  testResult?: Maybe<TmTestResultModel>;
  /** @deprecated Test result is removed */
  testResultId?: Maybe<Scalars['Int']>;
  timeModifierPercentage: Scalars['Float'];
  updatedAt: Scalars['Date'];
};

export type TmTestModel = {
  __typename?: 'TmTestModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  nQuestionsPerTrait?: Maybe<Scalars['Int']>;
  origin: TestOrigin;
  stageId: Scalars['Int'];
  subId: Scalars['String'];
  subType?: Maybe<TestSubType>;
  tpProjectId?: Maybe<Scalars['String']>;
  type: TestType;
  updatedAt: Scalars['Date'];
};

export type TmTestResultModel = {
  __typename?: 'TmTestResultModel';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  scores: Array<BasicScoreModel>;
  testCandidate: TmTestCandidateModel;
  testCandidateId: Scalars['Int'];
  updatedAt: Scalars['Date'];
};

export enum TrCustomEvaluation {
  RisingTalent = 'RISING_TALENT',
  SolidContributor = 'SOLID_CONTRIBUTOR',
  StarTalent = 'STAR_TALENT',
  UnderperformingTalent = 'UNDERPERFORMING_TALENT'
}

export type TrCustomGradeBandModel = {
  __typename?: 'TrCustomGradeBandModel';
  displayText: Scalars['String'];
  evaluation: TrCustomEvaluation;
  position: Scalars['Int'];
};

export type TrCustomResultScoreModel = {
  __typename?: 'TrCustomResultScoreModel';
  evaluation: TrCustomEvaluation;
  score: Scalars['Float'];
};

export type TrItemModel = {
  __typename?: 'TrItemModel';
  isCompleted: Scalars['Boolean'];
  questions: Array<TrQuestionModel>;
  remainingQuestions: Scalars['Int'];
  /** @deprecated Please use new property `isCompleted` if you wish to know whether the assessment is completed or not! */
  testCandidateStatus: TestStatus;
  totalQuestions: Scalars['Int'];
};

export type TrOptionModel = {
  __typename?: 'TrOptionModel';
  key: Scalars['Int'];
  subId: Scalars['String'];
  text: Scalars['String'];
};

export type TrQuestionModel = {
  __typename?: 'TrQuestionModel';
  options: Array<TrOptionModel>;
  orientation: QuestionOrientation;
  subId: Scalars['String'];
  text: Scalars['String'];
};

export type Trait = {
  __typename?: 'Trait';
  description: Scalars['String'];
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  softSkillTraits?: Maybe<Array<SoftSkillTrait>>;
  testTypes: Array<TraitTestTypeModel>;
};

export type TraitRoleLevelContent = {
  __typename?: 'TraitRoleLevelContent';
  classification: ContentClassification;
  content?: Maybe<Scalars['String']>;
  developmentContent?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  roleLevel: ContentRoleLevel;
  softSkill?: Maybe<SoftSkill>;
  softSkillId?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
  trait?: Maybe<Trait>;
  traitId?: Maybe<Scalars['Int']>;
};

export type TraitRoleLevelContentPaginatedModel = {
  __typename?: 'TraitRoleLevelContentPaginatedModel';
  count?: Maybe<Scalars['Float']>;
  traitContent?: Maybe<Array<TraitRoleLevelContent>>;
};

export type TraitSoftSkillContextFileUploadLineModel = {
  __typename?: 'TraitSoftSkillContextFileUploadLineModel';
  internalName: Scalars['String'];
  level: Scalars['String'];
  negativeSummary: Scalars['String'];
  positiveSummary: Scalars['String'];
  softSkillId: Scalars['String'];
  traitId: Scalars['String'];
};

export type TraitSoftSkillContextFileUploadModel = {
  __typename?: 'TraitSoftSkillContextFileUploadModel';
  apply: Scalars['Boolean'];
  creates?: Maybe<Array<TraitSoftSkillContextFileUploadLineModel>>;
  deletes?: Maybe<Array<TraitSoftSkillContextFileUploadLineModel>>;
  errorMessage?: Maybe<Scalars['String']>;
  errorMessages?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<TraitSoftSkillContextFileUploadLineModel>>;
  noChanges?: Maybe<Array<TraitSoftSkillContextFileUploadLineModel>>;
  updates?: Maybe<Array<TraitSoftSkillContextFileUploadLineModel>>;
  warnings: Array<Scalars['String']>;
};

export type TraitSoftSkillContextModel = {
  __typename?: 'TraitSoftSkillContextModel';
  behaviours: Array<BehaviourModel>;
  id: Scalars['Int'];
  interviewQuestionContents?: Maybe<Array<InterviewQuestionContentModel>>;
  interviewQuestionReasonContents?: Maybe<Array<InterviewQuestionReasonContentModel>>;
  softSkillId?: Maybe<Scalars['Int']>;
  traitId: Scalars['Int'];
};

export type TraitTestTypeModel = {
  __typename?: 'TraitTestTypeModel';
  id: Scalars['Int'];
  testSubType?: Maybe<TestSubType>;
  testType: TestType;
  trait?: Maybe<Trait>;
  traitId: Scalars['Int'];
};

export type TraitTestTypesUpdateOneArgs = {
  testSubType?: InputMaybe<TestSubType>;
  testType: TestType;
  traitId: Scalars['Int'];
};

export type UpdateFormFieldByFieldIdArgs = {
  displayOrder?: InputMaybe<Scalars['Int']>;
  fieldId: Scalars['Int'];
  isOptional?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateRespondantFormAnswerArgs = {
  fieldId: Scalars['Int'];
  response: Scalars['String'];
};

export type UploadResult = {
  __typename?: 'UploadResult';
  url: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  avatarUrl?: Maybe<Scalars['String']>;
  companyInfo?: Maybe<Array<UserCompanyInfo>>;
  email: Scalars['String'];
  externalId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  groups?: Maybe<Array<UserGroupMemberModel>>;
  id: Scalars['Int'];
  isInternal: Scalars['Boolean'];
  isPasswordDisabled?: Maybe<Scalars['Boolean']>;
  isPuppet: Scalars['Boolean'];
  /** @deprecated Please use "locale" instead! */
  language: Language;
  lastName: Scalars['String'];
  locale: SupportedLocale;
  roles: Array<UserRole>;
  subId: Scalars['String'];
  twoFactorEnabled: Scalars['Boolean'];
};

export type UserCompanyInfo = {
  __typename?: 'UserCompanyInfo';
  companyId: Scalars['Int'];
  customFields?: Maybe<Scalars['JSON']>;
  customFieldsString?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  employeeId?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['Date']>;
  hrManagerEmployeeId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  jobTitle?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  userId: Scalars['Int'];
};

export type UserCompanyInfoCustomFieldsArgs = {
  division?: InputMaybe<Scalars['String']>;
  function?: InputMaybe<Scalars['String']>;
  functionGroup?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['String']>;
  office?: InputMaybe<Scalars['String']>;
  officeCountry?: InputMaybe<Scalars['String']>;
  organisationLevel?: InputMaybe<Scalars['String']>;
};

export type UserGroupFindManyArgs = {
  companyId: Scalars['Int'];
};

export type UserGroupMemberFindManyArgs = {
  groupSubId: Scalars['String'];
};

export type UserGroupMemberModel = {
  __typename?: 'UserGroupMemberModel';
  group?: Maybe<UserGroupModel>;
  groupId: Scalars['Int'];
  id: Scalars['Int'];
  member?: Maybe<User>;
  memberId?: Maybe<Scalars['Int']>;
  subId: Scalars['String'];
};

export type UserGroupMemberPreview = {
  __typename?: 'UserGroupMemberPreview';
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  lastName?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type UserGroupModel = {
  __typename?: 'UserGroupModel';
  companyId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  members?: Maybe<Array<UserGroupMemberModel>>;
  membersCount?: Maybe<Scalars['Int']>;
  membersPreview?: Maybe<Array<UserGroupMemberPreview>>;
  name: Scalars['String'];
  subId: Scalars['String'];
};

export type UserGroupPaginatedModel = {
  __typename?: 'UserGroupPaginatedModel';
  data?: Maybe<Array<UserGroupModel>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type UserModelPaginated = {
  __typename?: 'UserModelPaginated';
  data?: Maybe<Array<User>>;
  pageInfo?: Maybe<Pageinfo>;
};

export type UserRole = {
  __typename?: 'UserRole';
  companyRole: CompanyRole;
  companyRoleId: Scalars['Int'];
  externalId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  user: User;
  userId?: Maybe<Scalars['Int']>;
};

export type UserSettings = {
  __typename?: 'UserSettings';
  id: Scalars['Int'];
  settings?: Maybe<UserSettingsOptions>;
  userId: Scalars['Int'];
};

export type UserSettingsOptions = {
  __typename?: 'UserSettingsOptions';
  CM_lastVisitedModule?: Maybe<Scalars['Boolean']>;
  TR_displayOnboarding?: Maybe<Scalars['Boolean']>;
};

export type UserSettingsOptionsArgs = {
  CM_lastVisitedModule?: InputMaybe<Scalars['Boolean']>;
  TR_displayOnboarding?: InputMaybe<Scalars['Boolean']>;
};

export type VerbalOptionModel = {
  __typename?: 'VerbalOptionModel';
  key: Scalars['Int'];
  subId: Scalars['String'];
  text: Scalars['String'];
};

export type VerbalQuestionModel = {
  __typename?: 'VerbalQuestionModel';
  difficulty: Scalars['Float'];
  options: Array<VerbalOptionModel>;
  subId: Scalars['String'];
  text: Scalars['String'];
};

export type VerbalTestletModel = {
  __typename?: 'VerbalTestletModel';
  isCompleted: Scalars['Boolean'];
  questions: Array<VerbalQuestionModel>;
  remainingQuestions: Scalars['Int'];
  remainingTime: Scalars['Int'];
  /** @deprecated Please use new property `isCompleted` if you wish to know whether the assessment is completed or not! */
  testCandidateStatus: TestStatus;
  text: Scalars['String'];
  timeLimit: Scalars['Int'];
  totalQuestions: Scalars['Int'];
};

export type WrdMerlinBrandedSkillsFeedbackModel = WidgetRenderData & {
  __typename?: 'WRDMerlinBrandedSkillsFeedbackModel';
  candidateFamilyName: Scalars['String'];
  candidateGivenName: Scalars['String'];
  companyLogoUrl?: Maybe<Scalars['String']>;
  driveAndDiscoverSoftSkill?: Maybe<WrdMerlinBrandedSkillsFeedbackSoftSkillModel>;
  enjoyTheRideSoftSkill?: Maybe<WrdMerlinBrandedSkillsFeedbackSoftSkillModel>;
  generatedAt?: Maybe<Scalars['Date']>;
  goTogetherSoftSkill?: Maybe<WrdMerlinBrandedSkillsFeedbackSoftSkillModel>;
  jobRoleName: Scalars['String'];
  ownYourCraftSoftSkill?: Maybe<WrdMerlinBrandedSkillsFeedbackSoftSkillModel>;
  projectName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  weCareSoftSkill?: Maybe<WrdMerlinBrandedSkillsFeedbackSoftSkillModel>;
  widgetType: WidgetType;
};

export type WrdMerlinBrandedSkillsFeedbackSoftSkillModel = {
  __typename?: 'WRDMerlinBrandedSkillsFeedbackSoftSkillModel';
  developmentContents: Array<Scalars['String']>;
  internalCandidateContent?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  primerValue?: Maybe<Scalars['Float']>;
};

export type Widget = {
  __typename?: 'Widget';
  createdAt: Scalars['Date'];
  deletedAt?: Maybe<Scalars['Date']>;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  reportConfigs?: Maybe<Array<ReportConfig>>;
  screenShotBlob?: Maybe<Blob>;
  screenShotBlobId?: Maybe<Scalars['Int']>;
  type: WidgetType;
  updatedAt: Scalars['Date'];
  widgetOptions?: Maybe<Array<WidgetOption>>;
};

export type WidgetConfig = {
  __typename?: 'WidgetConfig';
  id: Scalars['Int'];
  reportConfig?: Maybe<ReportConfig>;
  reportConfigId: Scalars['Int'];
  settings: Scalars['String'];
  widgetOption?: Maybe<WidgetOption>;
  widgetOptionId: Scalars['Int'];
};

export type WidgetConfigUpdateOneArgs = {
  id: Scalars['Int'];
  settings?: InputMaybe<Scalars['String']>;
};

export type WidgetConfigUpsertOneBatchedWithReportConfigArgs = {
  settings?: InputMaybe<Scalars['String']>;
  widgetOptionId: Scalars['Int'];
};

export type WidgetOption = {
  __typename?: 'WidgetOption';
  defaultValue?: Maybe<Scalars['String']>;
  fieldType: WidgetOptionFieldType;
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
  name: Scalars['String'];
  question?: Maybe<Scalars['String']>;
  widget: Widget;
  widgetConfigs?: Maybe<Array<WidgetConfig>>;
  widgetId: Scalars['Int'];
};

export enum WidgetOptionFieldType {
  /** File upload */
  FileUploadField = 'FILE_UPLOAD_FIELD',
  /** Multiple select */
  MultipleSelectField = 'MULTIPLE_SELECT_FIELD',
  /** Short text */
  ShortTextField = 'SHORT_TEXT_FIELD',
  /** Single select */
  SingleSelectField = 'SINGLE_SELECT_FIELD'
}

export type WidgetRender = {
  __typename?: 'WidgetRender';
  newPageBefore: Scalars['Boolean'];
  position: Scalars['Int'];
  type: WidgetType;
  widgetConfigs?: Maybe<Array<WidgetConfig>>;
  widgetRenderData?: Maybe<WidgetRenderData>;
};

export type WidgetRenderData = {
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export enum WidgetType {
  /** Assessment link */
  AssessmentLink = 'ASSESSMENT_LINK',
  /** Assessment links */
  AssessmentLinks = 'ASSESSMENT_LINKS',
  /** Assessment summaries */
  AssessmentSummaries = 'ASSESSMENT_SUMMARIES',
  /** Assessment summary */
  AssessmentSummary = 'ASSESSMENT_SUMMARY',
  BrandedCoverPage = 'BRANDED_COVER_PAGE',
  /** Candidate and company details */
  CandidateCompany = 'CANDIDATE_COMPANY',
  /** (Internal) Contextual score */
  ContextualScore = 'CONTEXTUAL_SCORE',
  InterviewGuideCategories = 'INTERVIEW_GUIDE_CATEGORIES',
  InterviewGuideCategory = 'INTERVIEW_GUIDE_CATEGORY',
  InterviewGuideFlow = 'INTERVIEW_GUIDE_FLOW',
  InterviewGuideHowToUse = 'INTERVIEW_GUIDE_HOW_TO_USE',
  InterviewGuideStep = 'INTERVIEW_GUIDE_STEP',
  MerlinBrandedSkillsFeedback = 'MERLIN_BRANDED_SKILLS_FEEDBACK',
  /** Role level chart */
  RoleLevelChart = 'ROLE_LEVEL_CHART',
  /** Soft skills card */
  SoftSkillCard = 'SOFT_SKILL_CARD',
  /** Soft skills cards */
  SoftSkillCards = 'SOFT_SKILL_CARDS',
  /** Soft skills datasource */
  SoftSkillDataSource = 'SOFT_SKILL_DATA_SOURCE',
  /** Soft skill excluded */
  SoftSkillExcluded = 'SOFT_SKILL_EXCLUDED',
  /** (Internal) Soft skill score */
  SoftSkillScore = 'SOFT_SKILL_SCORE',
  /** (Internal) Soft skill score chart */
  SoftSkillScoreChart = 'SOFT_SKILL_SCORE_CHART',
  /** (Internal) Soft skill score top and bottom */
  SoftSkillScoreTopBottom = 'SOFT_SKILL_SCORE_TOP_BOTTOM',
  /** (Internal) Soft skill score with content */
  SoftSkillScoreWithContent = 'SOFT_SKILL_SCORE_WITH_CONTENT',
  /** Success profile cover page */
  SpCoverPage = 'SP_COVER_PAGE',
  /** Success profile explanation */
  SpExplanation = 'SP_EXPLANATION',
  /** Success profile header and footer */
  SpHeaderFooter = 'SP_HEADER_FOOTER',
  /** Soft skills detail */
  SpSoftSkillDetail = 'SP_SOFT_SKILL_DETAIL',
  /** Soft skills spider chart */
  SpSoftSkillSpider = 'SP_SOFT_SKILL_SPIDER',
  /** Soft skill type explanation */
  SpSoftSkillTypeExplanation = 'SP_SOFT_SKILL_TYPE_EXPLANATION',
  /** Stage results cover page */
  SrCoverPage = 'SR_COVER_PAGE',
  /** Information required for email */
  SrEmailContent = 'SR_EMAIL_CONTENT',
  StageCandidateHeader = 'STAGE_CANDIDATE_HEADER',
  StageCandidateHighlightedScores = 'STAGE_CANDIDATE_HIGHLIGHTED_SCORES',
  /** (Internal) Stage Candidate Soft skill score with content */
  StageCandidateSoftSkillScoreSummaryWithContent = 'STAGE_CANDIDATE_SOFT_SKILL_SCORE_SUMMARY_WITH_CONTENT',
  /** (Internal) Stage Candidate Soft skill score with content */
  StageCandidateSoftSkillScoreWithContent = 'STAGE_CANDIDATE_SOFT_SKILL_SCORE_WITH_CONTENT',
  StageCandidateSuccessProfileScoreFinalScores = 'STAGE_CANDIDATE_SUCCESS_PROFILE_SCORE_FINAL_SCORES',
  StageCandidateSuccessProfileScoreSummaryWithContent = 'STAGE_CANDIDATE_SUCCESS_PROFILE_SCORE_SUMMARY_WITH_CONTENT',
  StageCandidateSuccessProfileScoreWithContent = 'STAGE_CANDIDATE_SUCCESS_PROFILE_SCORE_WITH_CONTENT',
  /** Static */
  Static = 'STATIC',
  /** (Internal) Success profile score */
  SuccessProfileScore = 'SUCCESS_PROFILE_SCORE',
  /** Success profile scores - chart */
  SuccessProfileScoreChart = 'SUCCESS_PROFILE_SCORE_CHART',
  /** Success profile scores - just final scores */
  SuccessProfileScoreFinalScores = 'SUCCESS_PROFILE_SCORE_FINAL_SCORES',
  /** Success profile scores - top and bottom soft skills */
  SuccessProfileScoreTopBottom = 'SUCCESS_PROFILE_SCORE_TOP_BOTTOM',
  /** Success profile scores - with content */
  SuccessProfileScoreWithContent = 'SUCCESS_PROFILE_SCORE_WITH_CONTENT',
  TestingWidget = 'TESTING_WIDGET',
  /** Test summary */
  TestSummary = 'TEST_SUMMARY',
  /** (Internal) trait score */
  TraitScore = 'TRAIT_SCORE',
  /** (Internal) trait score with content */
  TraitScoreWithContent = 'TRAIT_SCORE_WITH_CONTENT',
  /** (Internal) trait score with content and IQ */
  TraitScoreWithIq = 'TRAIT_SCORE_WITH_IQ',
  WidgetExample = 'WIDGET_EXAMPLE'
}

export enum WithDeletedChoice {
  All = 'ALL',
  OnlyArchived = 'ONLY_ARCHIVED',
  OnlyNotArchived = 'ONLY_NOT_ARCHIVED'
}

export type WrdAssessmentLink = WidgetRenderData & {
  __typename?: 'WrdAssessmentLink';
  header: Scalars['String'];
  order: Scalars['Int'];
  subId?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  urlDisplayText: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdAssessmentLinks = WidgetRenderData & {
  __typename?: 'WrdAssessmentLinks';
  assessmentLinks?: Maybe<Array<WrdAssessmentLink>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdAssessmentSummaries = WidgetRenderData & {
  __typename?: 'WrdAssessmentSummaries';
  assessmentSummaries?: Maybe<Array<WrdAssessmentSummary>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdAssessmentSummary = WidgetRenderData & {
  __typename?: 'WrdAssessmentSummary';
  order: Scalars['Int'];
  subId?: Maybe<Scalars['String']>;
  testSummaries: Array<WrdTestSummary>;
  totalTime: Scalars['Int'];
  widgetType: WidgetType;
};

export type WrdBrandedCoverPage = WidgetRenderData & {
  __typename?: 'WrdBrandedCoverPage';
  candidateFamilyName: Scalars['String'];
  candidateGivenName: Scalars['String'];
  companyLogoUrl?: Maybe<Scalars['String']>;
  generatedAt?: Maybe<Scalars['Date']>;
  jobRoleName: Scalars['String'];
  projectName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdCandidateCompany = WidgetRenderData & {
  __typename?: 'WrdCandidateCompany';
  candidateAvatarUrl?: Maybe<Scalars['String']>;
  candidateInitials: Scalars['String'];
  candidateName: Scalars['String'];
  companyLogo?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdContextualScore = WidgetRenderData & {
  __typename?: 'WrdContextualScore';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdContextualScore>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdIgContent = WidgetRenderData & {
  __typename?: 'WrdIgContent';
  contentType: IgContentType;
  id: Scalars['Int'];
  interviewGuideId: Scalars['Int'];
  subId?: Maybe<Scalars['String']>;
  text: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdIgInterviewGuideCategories = WidgetRenderData & {
  __typename?: 'WrdIgInterviewGuideCategories';
  categories?: Maybe<Array<Maybe<WrdIgInterviewGuideCategory>>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdIgInterviewGuideCategory = WidgetRenderData & {
  __typename?: 'WrdIgInterviewGuideCategory';
  colour?: Maybe<IgCategoryColour>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdIgStep = WidgetRenderData & {
  __typename?: 'WrdIgStep';
  action?: Maybe<Scalars['String']>;
  colour?: Maybe<IgCategoryColour>;
  durationInSeconds?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  interviewGuideId: Scalars['Int'];
  name: Scalars['String'];
  order: Scalars['Int'];
  questionType: IgQuestionType;
  staticQuestionName?: Maybe<Scalars['String']>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdInterviewGuide = WidgetRenderData & {
  __typename?: 'WrdInterviewGuide';
  contents?: Maybe<Array<Maybe<WrdIgContent>>>;
  id: Scalars['Int'];
  steps?: Maybe<Array<Maybe<WrdIgStep>>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdInterviewGuideFlow = WidgetRenderData & {
  __typename?: 'WrdInterviewGuideFlow';
  contents?: Maybe<Array<Maybe<WrdIgContent>>>;
  id: Scalars['Int'];
  steps?: Maybe<Array<Maybe<WrdIgStep>>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdInterviewGuideHowToUse = WidgetRenderData & {
  __typename?: 'WrdInterviewGuideHowToUse';
  contents?: Maybe<Array<Maybe<WrdIgContent>>>;
  id: Scalars['Int'];
  steps?: Maybe<Array<Maybe<WrdIgStep>>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdRoleLevelChart = WidgetRenderData & {
  __typename?: 'WrdRoleLevelChart';
  contentRoleLevelsToDisplay?: Maybe<Array<ContentRoleLevel>>;
  roleLevel: ContentRoleLevel;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdSoftSkillCard = WidgetRenderData & {
  __typename?: 'WrdSoftSkillCard';
  order: Scalars['Int'];
  softSkillDataSources?: Maybe<Array<WrdSoftSkillDataSource>>;
  softSkillId: Scalars['Int'];
  softSkillName: Scalars['String'];
  softSkillType: SoftSkillType;
  subId?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
  weightRatio: Scalars['Float'];
  widgetType: WidgetType;
};

export type WrdSoftSkillCards = WidgetRenderData & {
  __typename?: 'WrdSoftSkillCards';
  softSkillCards?: Maybe<Array<WrdSoftSkillCard>>;
  softSkillsExcluded?: Maybe<Array<WrdSoftSkillExcluded>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdSoftSkillDataSource = WidgetRenderData & {
  __typename?: 'WrdSoftSkillDataSource';
  dataSourceType: DataSourceType;
  softSkillType: SoftSkillType;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdSoftSkillExcluded = WidgetRenderData & {
  __typename?: 'WrdSoftSkillExcluded';
  category: Scalars['String'];
  companyId: Scalars['Int'];
  softSkillId: Scalars['Int'];
  softSkillName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdSoftSkillScore = WidgetRenderData & {
  __typename?: 'WrdSoftSkillScore';
  barSize?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  children?: Maybe<Array<WrdTraitScore>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  measurementConstructType?: Maybe<MeasurementConstructType>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  softSkillImageType?: Maybe<SoftSkillImageType>;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdSoftSkillScoreChart = WidgetRenderData & {
  __typename?: 'WrdSoftSkillScoreChart';
  barSize?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  children?: Maybe<Array<WrdTraitScore>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  measurementConstructType?: Maybe<MeasurementConstructType>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  softSkillImageType?: Maybe<SoftSkillImageType>;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdSoftSkillScoreTopBottom = WidgetRenderData & {
  __typename?: 'WrdSoftSkillScoreTopBottom';
  barSize?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  children?: Maybe<Array<WrdTraitScoreWithContent>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  measurementConstructType?: Maybe<MeasurementConstructType>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  positionType?: Maybe<ContextualScorePositionType>;
  score: ScoreModel;
  softSkillImageType?: Maybe<SoftSkillImageType>;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdSoftSkillScoreWithContent = WidgetRenderData & {
  __typename?: 'WrdSoftSkillScoreWithContent';
  barSize?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  children?: Maybe<Array<WrdTraitScoreWithContent>>;
  description?: Maybe<Scalars['String']>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  feedback?: Maybe<Array<Maybe<Scalars['String']>>>;
  headerText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  iqTrait?: Maybe<WrdTraitScoreWithIq>;
  measurementConstructType?: Maybe<MeasurementConstructType>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  softSkillContentHeaderType?: Maybe<SoftSkillContentHeaderType>;
  softSkillImageType?: Maybe<SoftSkillImageType>;
  softSkillType?: Maybe<SoftSkillType>;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  widgetType: WidgetType;
};

export type WrdSpCoverPage = WidgetRenderData & {
  __typename?: 'WrdSpCoverPage';
  companyLogo?: Maybe<Scalars['String']>;
  jobRoleName: Scalars['String'];
  projectId: Scalars['Int'];
  projectName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  widgetType: WidgetType;
};

export type WrdSpExplanation = WidgetRenderData & {
  __typename?: 'WrdSpExplanation';
  softSkillExplanations?: Maybe<Array<WrdSpSoftSkillDetail>>;
  softSkillTypeExplanations?: Maybe<Array<WrdSpSoftSkillTypeExplanation>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdSpHeaderFooter = WidgetRenderData & {
  __typename?: 'WrdSpHeaderFooter';
  companyLogo?: Maybe<Scalars['String']>;
  jobRoleName: Scalars['String'];
  projectId: Scalars['Int'];
  projectName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  widgetType: WidgetType;
};

export type WrdSpSoftSkillDetail = WidgetRenderData & {
  __typename?: 'WrdSpSoftSkillDetail';
  description: Scalars['String'];
  order: Scalars['Int'];
  shortDescription: Scalars['String'];
  softSkillId: Scalars['Int'];
  softSkillName: Scalars['String'];
  softSkillType: SoftSkillType;
  subId?: Maybe<Scalars['String']>;
  weight: Scalars['Float'];
  weightRatio: Scalars['Float'];
  widgetType: WidgetType;
};

export type WrdSpSoftSkillSpider = WidgetRenderData & {
  __typename?: 'WrdSpSoftSkillSpider';
  projectName: Scalars['String'];
  spSoftSkillDetails?: Maybe<Array<WrdSpSoftSkillDetail>>;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdSpSoftSkillTypeExplanation = WidgetRenderData & {
  __typename?: 'WrdSpSoftSkillTypeExplanation';
  description: Scalars['String'];
  softSkillType: SoftSkillType;
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdSrCoverPage = WidgetRenderData & {
  __typename?: 'WrdSrCoverPage';
  candidateAvatarUrl?: Maybe<Scalars['String']>;
  candidateInitials: Scalars['String'];
  candidateName: Scalars['String'];
  companyLogo?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  jobRoleName: Scalars['String'];
  projectId: Scalars['Int'];
  projectName: Scalars['String'];
  stageName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  widgetType: WidgetType;
};

export type WrdSrEmailContent = WidgetRenderData & {
  __typename?: 'WrdSrEmailContent';
  candidateAvatarUrl?: Maybe<Scalars['String']>;
  candidateInitials: Scalars['String'];
  candidateName: Scalars['String'];
  companyLogo?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  gradeColor: Scalars['String'];
  gradeColorRGBA: Scalars['String'];
  jobRoleName: Scalars['String'];
  projectId: Scalars['Int'];
  projectName: Scalars['String'];
  score: Scalars['String'];
  stageName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  widgetType: WidgetType;
};

export type WrdStageCandidateHeader = WidgetRenderData & {
  __typename?: 'WrdStageCandidateHeader';
  candidateAvatarUrl?: Maybe<Scalars['String']>;
  candidateInitials: Scalars['String'];
  candidateName: Scalars['String'];
  companyLogo?: Maybe<Scalars['String']>;
  companyName: Scalars['String'];
  projectName: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  widgetType: WidgetType;
};

export type WrdStageCandidateHighlightedScores = WidgetRenderData & {
  __typename?: 'WrdStageCandidateHighlightedScores';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdContextualScore>>;
  content?: Maybe<Scalars['String']>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  highlightedScores?: Maybe<Array<WrdSoftSkillScore>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  positionType?: Maybe<ContextualScorePositionType>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdStageCandidateSoftSkillScoreSummaryWithContent = WidgetRenderData & {
  __typename?: 'WrdStageCandidateSoftSkillScoreSummaryWithContent';
  barSize?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  children?: Maybe<Array<WrdTraitScoreWithContent>>;
  description?: Maybe<Scalars['String']>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  feedback?: Maybe<Array<Maybe<Scalars['String']>>>;
  headerText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  iqTrait?: Maybe<WrdTraitScoreWithIq>;
  measurementConstructType?: Maybe<MeasurementConstructType>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  softSkillContentHeaderType?: Maybe<SoftSkillContentHeaderType>;
  softSkillImageType?: Maybe<SoftSkillImageType>;
  softSkillType?: Maybe<SoftSkillType>;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  widgetType: WidgetType;
};

export type WrdStageCandidateSoftSkillScoreWithContent = WidgetRenderData & {
  __typename?: 'WrdStageCandidateSoftSkillScoreWithContent';
  barSize?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  children?: Maybe<Array<WrdTraitScoreWithContent>>;
  description?: Maybe<Scalars['String']>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  feedback?: Maybe<Array<Maybe<Scalars['String']>>>;
  headerText?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  iqTrait?: Maybe<WrdTraitScoreWithIq>;
  measurementConstructType?: Maybe<MeasurementConstructType>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  softSkillContentHeaderType?: Maybe<SoftSkillContentHeaderType>;
  softSkillImageType?: Maybe<SoftSkillImageType>;
  softSkillType?: Maybe<SoftSkillType>;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  weight?: Maybe<Scalars['Float']>;
  widgetType: WidgetType;
};

export type WrdStageCandidateSuccessProfileScoreFinalScores = WidgetRenderData & {
  __typename?: 'WrdStageCandidateSuccessProfileScoreFinalScores';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdSoftSkillScoreChart>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  highlightedScores?: Maybe<Array<WrdSoftSkillScore>>;
  id: Scalars['Int'];
  manualScores?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdStageCandidateSuccessProfileScoreSummaryWithContent = WidgetRenderData & {
  __typename?: 'WrdStageCandidateSuccessProfileScoreSummaryWithContent';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdStageCandidateSoftSkillScoreSummaryWithContent>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  highlightedScores?: Maybe<Array<WrdSoftSkillScore>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdStageCandidateSuccessProfileScoreWithContent = WidgetRenderData & {
  __typename?: 'WrdStageCandidateSuccessProfileScoreWithContent';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdStageCandidateSoftSkillScoreWithContent>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  highlightedScores?: Maybe<Array<WrdSoftSkillScore>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdStatic = WidgetRenderData & {
  __typename?: 'WrdStatic';
  subId?: Maybe<Scalars['String']>;
  widgetType: WidgetType;
};

export type WrdSuccessProfileScore = WidgetRenderData & {
  __typename?: 'WrdSuccessProfileScore';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdSoftSkillScore>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdSuccessProfileScoreChart = WidgetRenderData & {
  __typename?: 'WrdSuccessProfileScoreChart';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdSoftSkillScoreChart>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  highlightedScores?: Maybe<Array<WrdSoftSkillScore>>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdSuccessProfileScoreFinalScores = WidgetRenderData & {
  __typename?: 'WrdSuccessProfileScoreFinalScores';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdSoftSkillScoreChart>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  highlightedScores?: Maybe<Array<WrdSoftSkillScore>>;
  id: Scalars['Int'];
  manualScores?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdSuccessProfileScoreTopBottom = WidgetRenderData & {
  __typename?: 'WrdSuccessProfileScoreTopBottom';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdSoftSkillScoreTopBottom>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdSuccessProfileScoreWithContent = WidgetRenderData & {
  __typename?: 'WrdSuccessProfileScoreWithContent';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdSoftSkillScoreWithContent>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdTestSummary = WidgetRenderData & {
  __typename?: 'WrdTestSummary';
  name: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  time: Scalars['Int'];
  type: TestType;
  widgetType: WidgetType;
};

export type WrdTraitScore = WidgetRenderData & {
  __typename?: 'WrdTraitScore';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdContextualScore>>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdTraitScoreWithContent = WidgetRenderData & {
  __typename?: 'WrdTraitScoreWithContent';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdContextualScore>>;
  content?: Maybe<Scalars['String']>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  positionType?: Maybe<ContextualScorePositionType>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdTraitScoreWithIq = WidgetRenderData & {
  __typename?: 'WrdTraitScoreWithIq';
  barSize?: Maybe<Scalars['Int']>;
  children?: Maybe<Array<WrdContextualScore>>;
  content?: Maybe<Scalars['String']>;
  displayClassification: Scalars['String'];
  displayScoreType?: Maybe<DisplayScoreType>;
  id: Scalars['Int'];
  iqFeedback?: Maybe<Scalars['String']>;
  iqFooterType: IqFooterType;
  iqHints?: Maybe<Array<Scalars['String']>>;
  iqMainQuestion?: Maybe<Scalars['String']>;
  iqReasonForQuestion?: Maybe<Scalars['String']>;
  iqSubQuestions?: Maybe<Array<Scalars['String']>>;
  iqSummary?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order?: Maybe<Scalars['Int']>;
  positionType?: Maybe<ContextualScorePositionType>;
  score: ScoreModel;
  subId?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  widgetType: WidgetType;
};

export type WrdWidgetExample = WidgetRenderData & {
  __typename?: 'WrdWidgetExample';
  name: Scalars['String'];
  subId?: Maybe<Scalars['String']>;
  summary: Scalars['String'];
  testType: TestType;
  time: Scalars['Int'];
  widgetType: WidgetType;
};

export type WrdWidgetTesting = WidgetRenderData & {
  __typename?: 'WrdWidgetTesting';
  subId?: Maybe<Scalars['String']>;
  test: Scalars['String'];
  widgetType: WidgetType;
};

export type AllowedResultActions = CmAllowedResultActionsHiringModel | CmAllowedResultActionsTalentReviewModel;

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']>;
  isDeprecated: Scalars['Boolean'];
  deprecationReason?: Maybe<Scalars['String']>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  specifiedByURL?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
  isOneOf?: Maybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL'
}

export type IgStepFragment = { __typename?: 'IgStep', id: number, name: string, internalName: string, action?: string | null | undefined, durationInSeconds?: number | null | undefined, colour?: IgCategoryColour | null | undefined, questionType: IgQuestionType, order: number, isArchived: boolean, staticQuestionName?: string | null | undefined };

export type TechnicalSkillFragment = { __typename?: 'TechnicalSkill', createdAt: any, deletedAt?: any | null | undefined, iconBlobId?: number | null | undefined, id: number, name: string, skillCategoryId?: number | null | undefined, updatedAt: any };

export type ApiKeyUsageRecordFragment = { __typename?: 'ApiKeyUsageRecordModel', route: string, count: number };

export type ApiKeyFragment = { __typename?: 'AmApiKeyModel', id: number, key: string, ownerId: number, ownerType: ApiKeyOwnerType, puppetUserId: number, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, role?: IdentityPermissionRole | null | undefined, usageRecord: Array<{ __typename?: 'ApiKeyUsageRecordModel', route: string, count: number }> };

export type BlobFragment = { __typename?: 'Blob', id: string, key: string, acl: Acl, extension: string, bucket: string, publishedName: string, url: string, contentType?: string | null | undefined, contentEncoding?: string | null | undefined, ownerService: string, createdAt: string, updatedAt: string, deletedAt?: string | null | undefined };

export type BlobUrlFragment = { __typename?: 'Blob', id: string, url: string };

export type CompanyFragment = { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean };

export type CompanySignatureModelFragment = { __typename?: 'CompanySignatureModel', id: number, companyId: number, isDefault: boolean, signature: string, signatureEmail: string };

export type CompanyEmployeeFragment = { __typename?: 'CompanyEmployeeModel', companyId: number, createdAt: any, customFields?: any | null | undefined, deletedAt?: any | null | undefined, department?: string | null | undefined, email: string, employeeId: string, endDate?: any | null | undefined, firstName: string, hrManagerEmployeeId?: string | null | undefined, id?: number | null | undefined, isArchived?: boolean | null | undefined, jobTitle?: string | null | undefined, lastName: string, startDate?: any | null | undefined, szUserId?: number | null | undefined, updatedAt: any };

export type CompanyProjectsFragment = { __typename?: 'CompanyProjects', id: number, numberOfStageCandidates: number, percentageOfCompletedStageCandidates: number, numberOfCompletedInTimeFrameStageCandidates: number, numberOfCompletedStageCandidates: number, company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean }, projects: Array<{ __typename?: 'ProjectWithStageCandidates', id: number, name: string, numberOfStageCandidates: number, percentageOfCompletedStageCandidates: number, numberOfCompletedStageCandidates: number, numberOfCompletedInTimeFrameStageCandidates: number }> };

export type CompanyRoleFragment = { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined };

export type CompanyUserInfoPaginatedFragment = { __typename?: 'CompanyUserInfoPaginated', total: number, companyUserInfo: Array<{ __typename?: 'CompanyUserInfo', company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean }, companyUserAcrossAllRoles: { __typename?: 'CompanyUsersCount', companyId: number, totalUsers: number, totalUsersWithPassword: number }, companyUserAcrossEachRole: Array<{ __typename?: 'CompanyUsersWithRolesCount', companyId: number, totalUsersWithPassword: number, totalUsers: number, roleName: IdentityPermissionRole }> }> };

export type CompanyUserInfoFragment = { __typename?: 'CompanyUserInfo', company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean }, companyUserAcrossAllRoles: { __typename?: 'CompanyUsersCount', companyId: number, totalUsers: number, totalUsersWithPassword: number }, companyUserAcrossEachRole: Array<{ __typename?: 'CompanyUsersWithRolesCount', companyId: number, totalUsersWithPassword: number, totalUsers: number, roleName: IdentityPermissionRole }> };

export type CompanyUsersCountFragment = { __typename?: 'CompanyUsersCount', companyId: number, totalUsers: number, totalUsersWithPassword: number };

export type CompanyUsersWithRolesCountFragment = { __typename?: 'CompanyUsersWithRolesCount', companyId: number, totalUsersWithPassword: number, totalUsers: number, roleName: IdentityPermissionRole };

export type DeiStageActionFragment = { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } };

export type DeiFieldsFragment = { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean };

export type DeiStageActionCandidateFragment = { __typename?: 'DeiStageActionCandidateModel', createdAt: any, status: DeiStageActionCandidateStatus, updatedAt: any };

export type ExternalAssessmentFragment = { __typename?: 'ExternalAssessmentModel', createdAt: any, deletedAt?: any | null | undefined, eaCompanyPlatformId?: number | null | undefined, estimatedDurationInMinutes?: number | null | undefined, externalPlatformType: ExternalPlatformType, id: number, name: string, subId: string, updatedAt: any, timeLimitInMinutes?: number | null | undefined, eaSkills?: Array<{ __typename?: 'EASkillModel', id: number, subId: string, technicalSkillId: number }> | null | undefined, eaPlatformAssessments?: Array<{ __typename?: 'EAPlatformAssessmentModel', eaPlatformId: string, id: number, platformUrl?: string | null | undefined, modifier: ExternalPlatformModifier, subId: string }> | null | undefined };

export type FormFragment = { __typename?: 'Form', formManagerType: FormManagerType, formOwnerName: string, formType: FormType, id: number, name: string };

export type FormCandidateModelFragment = { __typename?: 'FormCandidateModel', createdAt: any, deletedAt?: any | null | undefined, formId: number, id: number, stageCandidateId: number, status: FormCandidateStatus, subId: string, updatedAt: any, form: { __typename?: 'Form', formManagerType: FormManagerType, formOwnerName: string, formType: FormType, id: number, name: string } };

export type IgCategoryFragment = { __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any };

export type IgInterviewGuideFragment = { __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any };

export type IgInterviewGuideCategoryFragment = { __typename?: 'IgInterviewGuideCategory', id: number, order?: number | null | undefined, colour?: IgCategoryColour | null | undefined, interviewGuide: { __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any }, category: { __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any } };

export type JobRoleFragment = { __typename?: 'JobRoleModel', companyId: number, createdAt: any, deletedAt?: any | null | undefined, id: number, name: string, qualifier?: string | null | undefined, roleLevel: ContentRoleLevel, updatedAt: any, family?: JobRoleFamily | null | undefined, skillsProfiles: Array<{ __typename?: 'SuccessProfile', calculationMethod: CalculationMethod, createdAt: any, deletedAt?: any | null | undefined, gradingMethod: GradingMethod, id: number, jobRoleId: number, roleLevel: ContentRoleLevel, status: SuccessProfileStatus, updatedAt: any, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined }> | null | undefined }> };

export type JobRoleOverviewFragment = { __typename?: 'JobRoleModel', companyId: number, createdAt: any, deletedAt?: any | null | undefined, id: number, name: string, qualifier?: string | null | undefined, roleLevel: ContentRoleLevel, updatedAt: any, skillsProfiles: Array<{ __typename?: 'SuccessProfile', id: number, createdAt: any }> };

export type PageInfoFragment = { __typename?: 'Pageinfo', itemsTotal: number, currentPage: number, itemsOnPage: number, pageTotal: number, skip?: number | null | undefined, take?: number | null | undefined };

export type ProjectFragment = { __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined };

export type ProjectCompanyFragment = { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean };

export type SuccessProfileTechnicalSkillNameFragment = { __typename?: 'SuccessProfileTechnicalSkill', id: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined };

export type SuccessProfileSoftSkillNameFragment = { __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, name: string };

export type SuccessProfileSkillNamesFragment = { __typename?: 'SuccessProfile', id: number, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, name: string }> | null | undefined };

export type JobRoleNameFragment = { __typename?: 'JobRoleModel', name: string, id: number, roleLevel: ContentRoleLevel, family?: JobRoleFamily | null | undefined, skillsProfiles: Array<{ __typename?: 'SuccessProfile', id: number, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, name: string }> | null | undefined }> };

export type ProjectJobRoleFragment = { __typename?: 'ProjectJobRoleModel', id: number, skillsProfileId: number, isLocalizedBenchmarkGroup?: boolean | null | undefined, jobRole: { __typename?: 'JobRoleModel', name: string, id: number, roleLevel: ContentRoleLevel, family?: JobRoleFamily | null | undefined, skillsProfiles: Array<{ __typename?: 'SuccessProfile', id: number, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, name: string }> | null | undefined }> } };

export type ProjectManagerFragment = { __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined };

export type ProjectRequestFragment = { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined };

export type ProjectRequestUserModelFragment = { __typename?: 'ProjectRequestUserModel', id: number, projectRequestId?: number | null | undefined, type: ProjectRequestUserType, userId: number, approval?: { __typename?: 'ProjectRequestUserApprovalModel', approvalStatus: ProjectRequestUserApprovalStatus, createdAt: any, deletedAt?: any | null | undefined, id: number, updatedAt: any, approvalRequestDate: any } | null | undefined, projectRequest?: { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined } | null | undefined, user?: { __typename?: 'User', email: string, firstName: string, lastName: string, id: number } | null | undefined };

export type ProjectRequestUserApprovalFragment = { __typename?: 'ProjectRequestUserApprovalModel', approvalStatus: ProjectRequestUserApprovalStatus, createdAt: any, deletedAt?: any | null | undefined, id: number, updatedAt: any, approvalRequestDate: any };

export type ProjectWithStageCandidatesFragment = { __typename?: 'ProjectWithStageCandidates', id: number, name: string, numberOfStageCandidates: number, percentageOfCompletedStageCandidates: number, numberOfCompletedStageCandidates: number, numberOfCompletedInTimeFrameStageCandidates: number };

export type ReportAssignmentFragment = { __typename?: 'ReportAssignment', assignedId: number, assignedType: RAssignedType, id: number, inheritsSetting?: boolean | null | undefined, reportId: number, report: { __typename?: 'Report', id: number, name: string } };

export type ReportFragment = { __typename?: 'Report', id: number, name: string, type: ReportType, subType?: ReportSubType | null | undefined, isArchived: boolean, ownerId: number, ownerType: ReportOwnerType, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, newPageBefore: boolean, position: number, widgetConfigs?: Array<{ __typename?: 'WidgetConfig', id: number, settings: string, widgetOption?: { __typename?: 'WidgetOption', question?: string | null | undefined, fieldType: WidgetOptionFieldType, widgetId: number, id: number, defaultValue?: string | null | undefined } | null | undefined }> | null | undefined, widget: { __typename?: 'Widget', id: number, name?: string | null | undefined, isArchived: boolean, updatedAt: any, type: WidgetType } }> | null | undefined };

export type ReportListItemFragment = { __typename?: 'Report', id: number, name: string, type: ReportType, subType?: ReportSubType | null | undefined, isArchived: boolean, ownerId: number, ownerType: ReportOwnerType, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined };

export type ResultValidatedSkillsModelFragment = { __typename?: 'ResultValidatedSkillsModel', totalValidateSkills: number, company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean } };

export type ResultValidatedSkillsPaginatedModelFragment = { __typename?: 'ResultValidatedSkillsPaginatedModel', total: number, resultValidatedSkills: Array<{ __typename?: 'ResultValidatedSkillsModel', totalValidateSkills: number, company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean } }> };

export type RoleFragment = { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType };

export type RssAssessmentFragment = { __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number };

export type RssAssessmentCandidateFragment = { __typename?: 'RssAssessmentCandidateModel', id: number, status: RssAssessmentCandidateStatus, rssAssessment: { __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number } };

export type SoftSkillFragment = { __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined };

export type SoftSkillTraitFragment = { __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined };

export type StageAvailableActionFragment = { __typename?: 'StageAvailableActionModel', availability: StageActionAvailability, name: string, subType?: StageActionSubType | null | undefined, type: StageActionType };

export type SmStageModelFragment = { __typename?: 'SmStageModel', id: number, name: string, type: StageType, order: number, status: StageStatus, startTime?: any | null | undefined, endTime?: any | null | undefined, projectId: number, managerReportEmailLinks: boolean, renderCandidateReport: boolean, emailCandidateReport: boolean, emailManagerReport: boolean, emailProjectTeam: boolean, emailProjectTeamRoles: Array<ActorRole>, hasCalibration: boolean, createInterviewGuide: boolean, emailSzAdmin: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, enableF2fInterviews: boolean, deiStageAction?: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } | null | undefined, availableActions: Array<{ __typename?: 'StageAvailableActionModel', availability: StageActionAvailability, name: string, subType?: StageActionSubType | null | undefined, type: StageActionType }>, rssAssessments: Array<{ __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number }> };

export type StageCandidateModelFragment = { __typename?: 'SmStageCandidateModel', id: number, subId: string, companyId?: number | null | undefined, doneFor: number, doneBy: number, lastDateOfCandidateAction?: any | null | undefined, status: StageCandidateStatus, deiStageActionCandidate?: { __typename?: 'DeiStageActionCandidateModel', createdAt: any, status: DeiStageActionCandidateStatus, updatedAt: any } | null | undefined, stage: { __typename?: 'SmStageModel', id: number, name: string, projectId: number }, stageResult?: { __typename?: 'SmStageResultModel', scores: Array<{ __typename?: 'BasicScoreModel', id: number, score: { __typename?: 'ScoreModel', zScore?: number | null | undefined, percentile?: number | null | undefined, stenScore?: number | null | undefined, grade?: Grade | null | undefined } }>, totalScore: { __typename?: 'BasicScoreModel', id: number, score: { __typename?: 'ScoreModel', zScore?: number | null | undefined, percentile?: number | null | undefined, stenScore?: number | null | undefined, grade?: Grade | null | undefined } } } | null | undefined, rssAssessmentCandidates?: Array<{ __typename?: 'RssAssessmentCandidateModel', id: number, status: RssAssessmentCandidateStatus, rssAssessment: { __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number } }> | null | undefined, formCandidates?: Array<{ __typename?: 'FormCandidateModel', createdAt: any, deletedAt?: any | null | undefined, formId: number, id: number, stageCandidateId: number, status: FormCandidateStatus, subId: string, updatedAt: any, form: { __typename?: 'Form', formManagerType: FormManagerType, formOwnerName: string, formType: FormType, id: number, name: string } } | null | undefined> | null | undefined };

export type TmTestModelFragment = { __typename?: 'TmTestModel', id: number, subId: string, type: TestType, nQuestionsPerTrait?: number | null | undefined, origin: TestOrigin, tpProjectId?: string | null | undefined, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, stageId: number };

export type TmTestCandidateModelFragment = { __typename?: 'TmTestCandidateModel', id: number, assessmentId?: number | null | undefined, status: TestStatus, testId: number, doneBy: number, doneFor: number, timeModifierPercentage: number, test?: { __typename?: 'TmTestModel', tpProjectId?: string | null | undefined, type: TestType, stageId: number, subType?: TestSubType | null | undefined } | null | undefined, testResult?: { __typename?: 'TmTestResultModel', updatedAt: any, testCandidateId: number, scores: Array<{ __typename?: 'BasicScoreModel', id: number, score: { __typename?: 'ScoreModel', zScore?: number | null | undefined, percentile?: number | null | undefined, stenScore?: number | null | undefined, grade?: Grade | null | undefined } }> } | null | undefined };

export type SuccessProfileFragment = { __typename?: 'SuccessProfile', calculationMethod: CalculationMethod, createdAt: any, deletedAt?: any | null | undefined, gradingMethod: GradingMethod, id: number, jobRoleId: number, roleLevel: ContentRoleLevel, status: SuccessProfileStatus, updatedAt: any, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined }> | null | undefined };

export type SuccessProfileTechnicalSkillFragment = { __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined };

export type SuccessProfileSoftSkillFragment = { __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined };

export type SuccessProfileSoftSkillTraitFragment = { __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined };

export type TraitFragment = { __typename?: 'Trait', id: number, name: string, description: string, isArchived: boolean, testTypes: Array<{ __typename?: 'TraitTestTypeModel', id: number, testSubType?: TestSubType | null | undefined, testType: TestType, traitId: number }> };

export type TraitTestTypeFragment = { __typename?: 'TraitTestTypeModel', id: number, testSubType?: TestSubType | null | undefined, testType: TestType, traitId: number };

export type TraitSoftSkillContextFileUploadRowFragment = { __typename?: 'TraitSoftSkillContextFileUploadLineModel', internalName: string, level: string, negativeSummary: string, positiveSummary: string, softSkillId: string, traitId: string };

export type UserFragment = { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> };

export type UserRoleFragment = { __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } };

export type ContentValidatiorPieceModelFragment = { __typename?: 'ContentValidatiorPieceModel', softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, traitId?: number | null | undefined, traitName?: string | null | undefined, numOfSsContent: string, numOfSsSpContent: string, numOfTraitContent: string, numOfIqContent: string };

export type WidgetFragment = { __typename?: 'Widget', id: number, name?: string | null | undefined, type: WidgetType, screenShotBlobId?: number | null | undefined, isArchived: boolean, updatedAt: any, screenShotBlob?: { __typename?: 'Blob', id: string, url: string } | null | undefined, widgetOptions?: Array<{ __typename?: 'WidgetOption', id: number, question?: string | null | undefined, fieldType: WidgetOptionFieldType, defaultValue?: string | null | undefined, isArchived: boolean }> | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, reportId: number, newPageBefore: boolean }> | null | undefined };

export type TechnicalSkillCreateOneMutationVariables = Exact<{
  name: Scalars['String'];
  iconBlobId?: InputMaybe<Scalars['Int']>;
  skillCategoryId?: InputMaybe<Scalars['Int']>;
}>;


export type TechnicalSkillCreateOneMutation = { __typename?: 'Mutation', TechnicalSkillCreateOne: { __typename?: 'TechnicalSkill', createdAt: any, deletedAt?: any | null | undefined, iconBlobId?: number | null | undefined, id: number, name: string, skillCategoryId?: number | null | undefined, updatedAt: any } };

export type TechnicalSkillIconUploadMutationVariables = Exact<{
  id: Scalars['Int'];
  file: Scalars['Upload'];
}>;


export type TechnicalSkillIconUploadMutation = { __typename?: 'Mutation', TechnicalSkillIconUpload: string };

export type ApiKeyCompanyGenerateMutationVariables = Exact<{
  companyId: Scalars['Int'];
  permission: IdentityPermissionRole;
}>;


export type ApiKeyCompanyGenerateMutation = { __typename?: 'Mutation', ApiKey: { __typename?: 'AmApiKeyModel', id: number, key: string, ownerId: number, ownerType: ApiKeyOwnerType, puppetUserId: number, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, role?: IdentityPermissionRole | null | undefined, usageRecord: Array<{ __typename?: 'ApiKeyUsageRecordModel', route: string, count: number }> } };

export type ApiKeyUserGenerateMutationVariables = Exact<{
  userId?: InputMaybe<Scalars['Int']>;
  userSubId?: InputMaybe<Scalars['String']>;
  userEmail?: InputMaybe<Scalars['String']>;
}>;


export type ApiKeyUserGenerateMutation = { __typename?: 'Mutation', ApiKey: { __typename?: 'AmApiKeyModel', id: number, key: string, ownerId: number, ownerType: ApiKeyOwnerType, puppetUserId: number, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, role?: IdentityPermissionRole | null | undefined, usageRecord: Array<{ __typename?: 'ApiKeyUsageRecordModel', route: string, count: number }> } };

export type ApiKeyDisableMutationVariables = Exact<{
  key: Scalars['String'];
}>;


export type ApiKeyDisableMutation = { __typename?: 'Mutation', ApiKeyDisable: boolean };

export type RequestPasswordRecoveryMutationVariables = Exact<{
  email: Scalars['String'];
  clientDomainType: ClientDomainType;
}>;


export type RequestPasswordRecoveryMutation = { __typename?: 'Mutation', requestPasswordRecovery: boolean };

export type ExchangeAuthCodeMutationVariables = Exact<{
  authCode: Scalars['String'];
}>;


export type ExchangeAuthCodeMutation = { __typename?: 'Mutation', ExchangeAuthCode: { __typename?: 'AuthenticationResult', accessToken: string, refreshToken: string } };

export type DeauthenticateMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type DeauthenticateMutation = { __typename?: 'Mutation', deauthenticate: boolean };

export type TraitSoftSkillContextFileUploadMutationVariables = Exact<{
  apply: Scalars['Boolean'];
  file: Scalars['Upload'];
}>;


export type TraitSoftSkillContextFileUploadMutation = { __typename?: 'Mutation', TraitSoftSkillContextFileUpload: { __typename?: 'TraitSoftSkillContextFileUploadModel', apply: boolean, warnings: Array<string>, errorMessage?: string | null | undefined, errorMessages?: Array<string> | null | undefined, creates?: Array<{ __typename?: 'TraitSoftSkillContextFileUploadLineModel', internalName: string, level: string, negativeSummary: string, positiveSummary: string, softSkillId: string, traitId: string }> | null | undefined } };

export type BehaviourFromCsvRowsCreateManyMutationVariables = Exact<{
  dto: Array<BehaviourFromCsvRowsCreateManyArgs> | BehaviourFromCsvRowsCreateManyArgs;
}>;


export type BehaviourFromCsvRowsCreateManyMutation = { __typename?: 'Mutation', BehaviourFromCsvRowsCreateMany: string };

export type TestCandidateResetOneMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type TestCandidateResetOneMutation = { __typename?: 'Mutation', TestCandidateResetOne: boolean };

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateCompanyMutation = { __typename?: 'Mutation', Company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean } };

export type CompanySignatureCreateOneMutationVariables = Exact<{
  companyId: Scalars['Int'];
  isDefault: Scalars['Boolean'];
  signature: Scalars['String'];
  signatureEmail: Scalars['String'];
}>;


export type CompanySignatureCreateOneMutation = { __typename?: 'Mutation', CompanySignature: { __typename?: 'CompanySignatureModel', id: number, companyId: number, isDefault: boolean, signature: string, signatureEmail: string } };

export type CompanySignatureUpdateOneMutationVariables = Exact<{
  id: Scalars['Int'];
  isDefault?: InputMaybe<Scalars['Boolean']>;
  signature?: InputMaybe<Scalars['String']>;
  signatureEmail?: InputMaybe<Scalars['String']>;
}>;


export type CompanySignatureUpdateOneMutation = { __typename?: 'Mutation', CompanySignature: { __typename?: 'CompanySignatureModel', id: number, companyId: number, isDefault: boolean, signature: string, signatureEmail: string } };

export type CompanySignatureDeleteOneMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompanySignatureDeleteOneMutation = { __typename?: 'Mutation', CompanySignature: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type CreateCompanyMutationVariables = Exact<{
  name: Scalars['String'];
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateCompanyMutation = { __typename?: 'Mutation', company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean } };

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteCompanyMutation = { __typename?: 'Mutation', deleteCompany: boolean };

export type UploadCompanyLogoMutationVariables = Exact<{
  id: Scalars['Int'];
  file: Scalars['Upload'];
}>;


export type UploadCompanyLogoMutation = { __typename?: 'Mutation', uploadCompanyLogo: string };

export type CompanyAllowedEmailDomainsUpdateMutationVariables = Exact<{
  allowedEmailDomains: Array<Scalars['String']> | Scalars['String'];
  id: Scalars['Int'];
}>;


export type CompanyAllowedEmailDomainsUpdateMutation = { __typename?: 'Mutation', CompanyAllowedEmailDomainsUpdate: Array<string> };

export type CompanyCreateUserMutationVariables = Exact<{
  companyId: Scalars['Float'];
  email: Scalars['String'];
  externalId: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<SupportedLocale>;
  lastName?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<Array<IdentityPermissionRole> | IdentityPermissionRole>;
  sendInvite: Scalars['Boolean'];
}>;


export type CompanyCreateUserMutation = { __typename?: 'Mutation', CompanyCreateUser: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } };

export type CompanyResendInviteMutationVariables = Exact<{
  userId: Scalars['Float'];
  companyId: Scalars['Float'];
}>;


export type CompanyResendInviteMutation = { __typename?: 'Mutation', CompanyResendInvite: boolean };

export type CompanyMaybeUserEmployeeFindManyQueryVariables = Exact<{
  companyId: Scalars['Float'];
}>;


export type CompanyMaybeUserEmployeeFindManyQuery = { __typename?: 'Query', CompanyMaybeUserEmployeeFindMany?: Array<{ __typename?: 'CompanyMaybeUserEmployeeModel', userId?: number | null | undefined, employeeId?: string | null | undefined, email: string, firstName: string, lastName: string, isUser: boolean, isCompanyEmployee: boolean }> | null | undefined };

export type CompanyEmployeeUpdateMutationVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']>;
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  employeeId?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  firstName?: InputMaybe<Scalars['String']>;
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
}>;


export type CompanyEmployeeUpdateMutation = { __typename?: 'Mutation', CompanyEmployeeUpdate: { __typename?: 'CompanyEmployeeModel', companyId: number, createdAt: any, customFields?: any | null | undefined, deletedAt?: any | null | undefined, department?: string | null | undefined, email: string, employeeId: string, endDate?: any | null | undefined, firstName: string, hrManagerEmployeeId?: string | null | undefined, id?: number | null | undefined, isArchived?: boolean | null | undefined, jobTitle?: string | null | undefined, lastName: string, startDate?: any | null | undefined, szUserId?: number | null | undefined, updatedAt: any } };

export type CompanyEmployeeDeleteOneMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompanyEmployeeDeleteOneMutation = { __typename?: 'Mutation', CompanyEmployeeDeleteOne: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type CompanyEmployeeCreateMutationVariables = Exact<{
  companyId: Scalars['Int'];
  customFields?: InputMaybe<UserCompanyInfoCustomFieldsArgs>;
  department?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  employeeId: Scalars['String'];
  endDate?: InputMaybe<Scalars['Date']>;
  firstName: Scalars['String'];
  hrManagerEmployeeId?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  jobTitle?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  startDate?: InputMaybe<Scalars['Date']>;
}>;


export type CompanyEmployeeCreateMutation = { __typename?: 'Mutation', CompanyEmployeeCreate: { __typename?: 'CompanyEmployeeModel', companyId: number, createdAt: any, customFields?: any | null | undefined, deletedAt?: any | null | undefined, department?: string | null | undefined, email: string, employeeId: string, endDate?: any | null | undefined, firstName: string, hrManagerEmployeeId?: string | null | undefined, id?: number | null | undefined, isArchived?: boolean | null | undefined, jobTitle?: string | null | undefined, lastName: string, startDate?: any | null | undefined, szUserId?: number | null | undefined, updatedAt: any } };

export type CompanyRoleUpsertMutationVariables = Exact<{
  companyId: Scalars['Int'];
  roleId: Scalars['Int'];
  twoFactorEnabled: Scalars['Boolean'];
}>;


export type CompanyRoleUpsertMutation = { __typename?: 'Mutation', CompanyRoleUpsert: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } };

export type SrEmailDispatchEmailsMutationVariables = Exact<{
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
  stageCandidateId: Scalars['Int'];
}>;


export type SrEmailDispatchEmailsMutation = { __typename?: 'Mutation', SrEmailDispatchEmails: boolean };

export type IgCategoryCreateMutationVariables = Exact<{
  internalName: Scalars['String'];
  name: Scalars['String'];
}>;


export type IgCategoryCreateMutation = { __typename?: 'Mutation', IgCategoryCreate?: { __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any } | null | undefined };

export type IgCategoryUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  internalName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
}>;


export type IgCategoryUpdateMutation = { __typename?: 'Mutation', IgCategoryUpdate?: { __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any } | null | undefined };

export type IgCategoryDeleteOneMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IgCategoryDeleteOneMutation = { __typename?: 'Mutation', IgCategoryDeleteOne?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type IgInterviewGuideCreateMutationVariables = Exact<{
  name: Scalars['String'];
  ownerId: Scalars['Int'];
}>;


export type IgInterviewGuideCreateMutation = { __typename?: 'Mutation', IgInterviewGuideCreate?: { __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any } | null | undefined };

export type IgInterviewGuideUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  ownerId?: InputMaybe<Scalars['Int']>;
}>;


export type IgInterviewGuideUpdateMutation = { __typename?: 'Mutation', IgInterviewGuideUpdate?: { __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any } | null | undefined };

export type IgInterviewGuideDeleteOneMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IgInterviewGuideDeleteOneMutation = { __typename?: 'Mutation', IgInterviewGuideDeleteOne?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type IgInterviewGuideCategoryCreateMutationVariables = Exact<{
  categoryId: Scalars['Int'];
  interviewGuideId: Scalars['Int'];
  order: Scalars['Int'];
  colour: IgCategoryColour;
}>;


export type IgInterviewGuideCategoryCreateMutation = { __typename?: 'Mutation', IgInterviewGuideCategoryCreate?: { __typename?: 'IgInterviewGuideCategory', id: number, order?: number | null | undefined, colour?: IgCategoryColour | null | undefined, interviewGuide: { __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any }, category: { __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any } } | null | undefined };

export type IgInterviewGuideCategoryDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IgInterviewGuideCategoryDeleteMutation = { __typename?: 'Mutation', IgInterviewGuideCategoryDeleteOne?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type IgInterviewGuideCategoryUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  colour: IgCategoryColour;
  order: Scalars['Int'];
}>;


export type IgInterviewGuideCategoryUpdateMutation = { __typename?: 'Mutation', IgInterviewGuideCategoryUpdate?: { __typename?: 'IgInterviewGuideCategory', id: number, order?: number | null | undefined, colour?: IgCategoryColour | null | undefined } | null | undefined };

export type IgStepDeleteOneMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IgStepDeleteOneMutation = { __typename?: 'Mutation', IgStepDeleteOne?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type IgStepUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  interviewGuideId: Scalars['Int'];
  isArchived?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  action?: InputMaybe<Scalars['String']>;
  durationInSeconds?: InputMaybe<Scalars['Int']>;
  colour?: InputMaybe<IgCategoryColour>;
  questionType?: InputMaybe<IgQuestionType>;
  order?: InputMaybe<Scalars['Int']>;
  staticQuestionName?: InputMaybe<Scalars['String']>;
}>;


export type IgStepUpdateMutation = { __typename?: 'Mutation', IgStepUpdate?: { __typename?: 'IgStep', id: number, name: string, internalName: string, action?: string | null | undefined, durationInSeconds?: number | null | undefined, colour?: IgCategoryColour | null | undefined, questionType: IgQuestionType, order: number, isArchived: boolean, staticQuestionName?: string | null | undefined } | null | undefined };

export type IgStepArchiveMutationVariables = Exact<{
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
}>;


export type IgStepArchiveMutation = { __typename?: 'Mutation', IgStepUpdate?: { __typename?: 'IgStep', id: number, name: string, internalName: string, action?: string | null | undefined, durationInSeconds?: number | null | undefined, colour?: IgCategoryColour | null | undefined, questionType: IgQuestionType, order: number, isArchived: boolean, staticQuestionName?: string | null | undefined } | null | undefined };

export type IgStepCreateMutationVariables = Exact<{
  name: Scalars['String'];
  internalName: Scalars['String'];
  action: Scalars['String'];
  durationInSeconds: Scalars['Int'];
  colour?: InputMaybe<IgCategoryColour>;
  questionType: IgQuestionType;
  interviewGuideId: Scalars['Int'];
  order: Scalars['Int'];
  staticQuestionName?: InputMaybe<Scalars['String']>;
}>;


export type IgStepCreateMutation = { __typename?: 'Mutation', IgStepCreate?: { __typename?: 'IgStep', id: number, name: string, internalName: string, action?: string | null | undefined, durationInSeconds?: number | null | undefined, colour?: IgCategoryColour | null | undefined, questionType: IgQuestionType, order: number, isArchived: boolean, staticQuestionName?: string | null | undefined } | null | undefined };

export type SaveInterviewQuestionContentMutationVariables = Exact<{
  id: Scalars['Int'];
  summary: Scalars['String'];
  feedback: Scalars['String'];
  reasonForQuestion: Scalars['String'];
  mainQuestion: Scalars['String'];
  subQuestions: Array<Scalars['String']> | Scalars['String'];
}>;


export type SaveInterviewQuestionContentMutation = { __typename?: 'Mutation', interviewQuestionContent?: { __typename?: 'InterviewQuestionRoleLevel', id: number, softSkillId: number, traitId: number, classification: ContentClassification, roleLevel: ContentRoleLevel, summary?: string | null | undefined, feedback?: string | null | undefined, reasonForQuestion?: string | null | undefined, mainQuestion?: string | null | undefined, subQuestions?: Array<string> | null | undefined, softSkill?: { __typename?: 'SoftSkill', name: string } | null | undefined, trait?: { __typename?: 'Trait', name: string } | null | undefined } | null | undefined };

export type SmInviteHiringMutationVariables = Exact<{
  isCompanyCandidate?: InputMaybe<Scalars['Boolean']>;
  doneForsAndProjectManagers: Array<SmInviteHiringDoneForsAndProjectManagersArgs> | SmInviteHiringDoneForsAndProjectManagersArgs;
  endTime?: InputMaybe<Scalars['Date']>;
  includeDeadlineInEmail?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<SupportedLocale>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  stageId: Scalars['Int'];
  type: SmInviteType;
}>;


export type SmInviteHiringMutation = { __typename?: 'Mutation', SmInviteHiring: Array<{ __typename?: 'SmStageCandidateModel', candidateId: number, stageId: number, status: StageCandidateStatus }> };

export type SmInviteTrMutationVariables = Exact<{
  isCompanyCandidate?: InputMaybe<Scalars['Boolean']>;
  doneByEmails?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  doneForEmails: Array<Scalars['String']> | Scalars['String'];
  endTime?: InputMaybe<Scalars['Date']>;
  includeDeadlineInEmail?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<SupportedLocale>;
  sendEmail?: InputMaybe<Scalars['Boolean']>;
  stageId: Scalars['Int'];
  type: SmInviteType;
}>;


export type SmInviteTrMutation = { __typename?: 'Mutation', SmInviteTR: Array<{ __typename?: 'SmStageCandidateModel', id: number, doneBy: number, doneFor: number }> };

export type JobRoleCreateOneMutationVariables = Exact<{
  companyId: Scalars['Int'];
  name: Scalars['String'];
  qualifier?: InputMaybe<Scalars['String']>;
  roleLevel: ContentRoleLevel;
  family?: InputMaybe<JobRoleFamily>;
}>;


export type JobRoleCreateOneMutation = { __typename?: 'Mutation', JobRoleCreateOne: { __typename?: 'JobRoleModel', companyId: number, createdAt: any, deletedAt?: any | null | undefined, id: number, name: string, qualifier?: string | null | undefined, roleLevel: ContentRoleLevel, updatedAt: any, family?: JobRoleFamily | null | undefined, skillsProfiles: Array<{ __typename?: 'SuccessProfile', calculationMethod: CalculationMethod, createdAt: any, deletedAt?: any | null | undefined, gradingMethod: GradingMethod, id: number, jobRoleId: number, roleLevel: ContentRoleLevel, status: SuccessProfileStatus, updatedAt: any, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined }> | null | undefined }> } };

export type JobRoleUpdateOneMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  qualifier?: InputMaybe<Scalars['String']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
  family?: InputMaybe<JobRoleFamily>;
}>;


export type JobRoleUpdateOneMutation = { __typename?: 'Mutation', JobRoleUpdateOne: { __typename?: 'JobRoleModel', companyId: number, createdAt: any, deletedAt?: any | null | undefined, id: number, name: string, qualifier?: string | null | undefined, roleLevel: ContentRoleLevel, updatedAt: any, family?: JobRoleFamily | null | undefined, skillsProfiles: Array<{ __typename?: 'SuccessProfile', calculationMethod: CalculationMethod, createdAt: any, deletedAt?: any | null | undefined, gradingMethod: GradingMethod, id: number, jobRoleId: number, roleLevel: ContentRoleLevel, status: SuccessProfileStatus, updatedAt: any, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined }> | null | undefined }> } };

export type ProjectSetCompanySignatureMutationVariables = Exact<{
  companySignatureId?: InputMaybe<Scalars['Int']>;
  projectId: Scalars['Int'];
}>;


export type ProjectSetCompanySignatureMutation = { __typename?: 'Mutation', Project: { __typename?: 'Project', id: number, companySignatureId?: number | null | undefined } };

export type EditProjectStateMutationVariables = Exact<{
  id: Scalars['Int'];
  state: Scalars['Boolean'];
}>;


export type EditProjectStateMutation = { __typename?: 'Mutation', project: { __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined } };

export type EditProjectArchivingMutationVariables = Exact<{
  id: Scalars['Int'];
  state: Scalars['Boolean'];
}>;


export type EditProjectArchivingMutation = { __typename?: 'Mutation', project: { __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined } };

export type CreateProjectMutationVariables = Exact<{
  name: Scalars['String'];
  companyId: Scalars['Float'];
  managers?: InputMaybe<Array<CreateProjectManagerInput> | CreateProjectManagerInput>;
  moduleType?: InputMaybe<ProjectModuleType>;
  requireSheetsExport?: InputMaybe<Scalars['Boolean']>;
  productSolution?: InputMaybe<ProductSolution>;
}>;


export type CreateProjectMutation = { __typename?: 'Mutation', project: { __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined } };

export type EditProjectMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  companyId: Scalars['Float'];
  managers?: InputMaybe<Array<CreateProjectManagerInput> | CreateProjectManagerInput>;
  requireSheetsExport?: InputMaybe<Scalars['Boolean']>;
  locales?: InputMaybe<Array<SupportedLocale> | SupportedLocale>;
  productSolution?: InputMaybe<ProductSolution>;
}>;


export type EditProjectMutation = { __typename?: 'Mutation', project: { __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined } };

export type SetProjectCobrandingMutationVariables = Exact<{
  projectId: Scalars['Int'];
  cobrandingEnabled: Scalars['Boolean'];
}>;


export type SetProjectCobrandingMutation = { __typename?: 'Mutation', setProjectCobranding: boolean };

export type ProjectCollaboratorsCreateManyMutationVariables = Exact<{
  collaborators: Array<ProjectCollaboratorCreateOneArgs> | ProjectCollaboratorCreateOneArgs;
}>;


export type ProjectCollaboratorsCreateManyMutation = { __typename?: 'Mutation', collaborators: Array<{ __typename?: 'ProjectCollaboratorRole', id: number, userId?: number | null | undefined, groupId?: number | null | undefined, actorRole?: ActorRole | null | undefined, projectId: number }> };

export type ProjectCollaboratorsUpdateRoleMutationVariables = Exact<{
  actorRole?: InputMaybe<ActorRole>;
  collaboratorId: Scalars['Int'];
}>;


export type ProjectCollaboratorsUpdateRoleMutation = { __typename?: 'Mutation', update: { __typename?: 'ProjectCollaboratorRole', id: number, projectId: number, userId?: number | null | undefined, groupId?: number | null | undefined, actorRole?: ActorRole | null | undefined } };

export type ProjectCollaboratorsDeleteManyMutationVariables = Exact<{
  collaborators: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ProjectCollaboratorsDeleteManyMutation = { __typename?: 'Mutation', deleted: Array<{ __typename?: 'ProjectCollaboratorDeleteManyResult', status: string, error?: string | null | undefined }> };

export type ProjectJobRoleCreateOneMutationVariables = Exact<{
  jobRoleId: Scalars['Int'];
  projectId: Scalars['Int'];
  skillsProfileId: Scalars['Int'];
}>;


export type ProjectJobRoleCreateOneMutation = { __typename?: 'Mutation', ProjectJobRoleCreateOne: { __typename?: 'ProjectJobRoleModel', id: number, skillsProfileId: number, isLocalizedBenchmarkGroup?: boolean | null | undefined, jobRole: { __typename?: 'JobRoleModel', name: string, id: number, roleLevel: ContentRoleLevel, family?: JobRoleFamily | null | undefined, skillsProfiles: Array<{ __typename?: 'SuccessProfile', id: number, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, name: string }> | null | undefined }> } } };

export type ProjectJobRoleDeleteOneMutationVariables = Exact<{
  createdAt?: InputMaybe<Scalars['Date']>;
  deletedAt?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  jobRoleId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  skillsProfileId?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['Date']>;
}>;


export type ProjectJobRoleDeleteOneMutation = { __typename?: 'Mutation', ProjectJobRoleDeleteOne: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type ProjectRequestDeleteOneMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProjectRequestDeleteOneMutation = { __typename?: 'Mutation', ProjectRequestDeleteOne: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type ProjectRequestCreateOneMutationVariables = Exact<{
  companyId: Scalars['Int'];
  creatorId: Scalars['Int'];
  projectName: Scalars['String'];
  status?: InputMaybe<ProjectRequestStatus>;
}>;


export type ProjectRequestCreateOneMutation = { __typename?: 'Mutation', ProjectRequestCreateOne: { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined } };

export type ProjectRequestUpdateOneMutationVariables = Exact<{
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  projectId?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectRequestStatus>;
}>;


export type ProjectRequestUpdateOneMutation = { __typename?: 'Mutation', ProjectRequestUpdateOne: { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined } };

export type ProjectRequestFileUploadMutationVariables = Exact<{
  args: ProjectRequestFileUploadArgs;
  file: Scalars['Upload'];
}>;


export type ProjectRequestFileUploadMutation = { __typename?: 'Mutation', ProjectRequestFileUpload: string };

export type ProjectRequestFileUploadDeleteOneMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProjectRequestFileUploadDeleteOneMutation = { __typename?: 'Mutation', ProjectRequestFileUploadDeleteOne: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type ProjectRequestRequestJobSpecificationMutationVariables = Exact<{
  id: Scalars['Int'];
  projectRequestUserIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ProjectRequestRequestJobSpecificationMutation = { __typename?: 'Mutation', ProjectRequestRequestJobSpecification: boolean };

export type ProjectRequestManagerRequestSurveyMutationVariables = Exact<{
  id: Scalars['Int'];
  projectRequestUserIds: Array<Scalars['Int']> | Scalars['Int'];
  surveyLink: Scalars['String'];
}>;


export type ProjectRequestManagerRequestSurveyMutation = { __typename?: 'Mutation', ProjectRequestRequestManagerSurvey: boolean };

export type ProjectRequestSoftDeleteOneMutationVariables = Exact<{
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ProjectRequestStatus>;
}>;


export type ProjectRequestSoftDeleteOneMutation = { __typename?: 'Mutation', ProjectRequestSoftDeleteOne: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type ProjectRequestRestoreOneMutationVariables = Exact<{
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  status?: InputMaybe<ProjectRequestStatus>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
}>;


export type ProjectRequestRestoreOneMutation = { __typename?: 'Mutation', ProjectRequestRestoreOne?: { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined } | null | undefined };

export type ProjectRequestUpdateManagerSurveyMutationVariables = Exact<{
  id: Scalars['Int'];
  managerSurveyId: Scalars['String'];
}>;


export type ProjectRequestUpdateManagerSurveyMutation = { __typename?: 'Mutation', ProjectRequestUpdateManagerSurvey: { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined } };

export type ProjectRequestUserDeleteOneMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProjectRequestUserDeleteOneMutation = { __typename?: 'Mutation', ProjectRequestUserDeleteOne: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type ProjectRequestUserCreateManyMutationVariables = Exact<{
  dto: Array<IProjectRequestUserCreateOneWithEmployeeCheckArgs> | IProjectRequestUserCreateOneWithEmployeeCheckArgs;
}>;


export type ProjectRequestUserCreateManyMutation = { __typename?: 'Mutation', ProjectRequestUserCreateMany: Array<{ __typename?: 'ProjectRequestUserModel', id: number, projectRequestId?: number | null | undefined, type: ProjectRequestUserType, userId: number, approval?: { __typename?: 'ProjectRequestUserApprovalModel', approvalStatus: ProjectRequestUserApprovalStatus, createdAt: any, deletedAt?: any | null | undefined, id: number, updatedAt: any, approvalRequestDate: any } | null | undefined, projectRequest?: { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined } | null | undefined, user?: { __typename?: 'User', email: string, firstName: string, lastName: string, id: number } | null | undefined }> };

export type ProjectRequestUserApprovalCreateManyMutationVariables = Exact<{
  dto: Array<IProjectRequestUserApprovalCreateOneArgs> | IProjectRequestUserApprovalCreateOneArgs;
}>;


export type ProjectRequestUserApprovalCreateManyMutation = { __typename?: 'Mutation', ProjectRequestUserApprovalCreateMany: Array<{ __typename?: 'ProjectRequestUserApprovalModel', approvalStatus: ProjectRequestUserApprovalStatus, createdAt: any, deletedAt?: any | null | undefined, id: number, updatedAt: any, approvalRequestDate: any }> };

export type ProjectRequestUserApprovalUpdateManyMutationVariables = Exact<{
  dto: Array<IProjectRequestUserApprovalUpdateOneArgs> | IProjectRequestUserApprovalUpdateOneArgs;
}>;


export type ProjectRequestUserApprovalUpdateManyMutation = { __typename?: 'Mutation', ProjectRequestUserApprovalUpdateMany: Array<{ __typename?: 'ProjectRequestUserApprovalModel', approvalStatus: ProjectRequestUserApprovalStatus, createdAt: any, deletedAt?: any | null | undefined, id: number, updatedAt: any, approvalRequestDate: any }> };

export type ProjectUserUpdateOneMutationVariables = Exact<{
  actorRole: ActorRole;
  projectId: Scalars['Int'];
  userId: Scalars['Int'];
}>;


export type ProjectUserUpdateOneMutation = { __typename?: 'Mutation', ProjectUserUpdateOne: { __typename?: 'ProjectUser', actorRole?: ActorRole | null | undefined, user: { __typename?: 'User', id: number }, project: { __typename?: 'Project', id: number } } };

export type ProjectUserDeleteOneMutationVariables = Exact<{
  projectId: Scalars['Float'];
  userId: Scalars['Float'];
}>;


export type ProjectUserDeleteOneMutation = { __typename?: 'Mutation', ProjectUserDeleteOne: { __typename?: 'ProjectUser', actorRole?: ActorRole | null | undefined, user: { __typename?: 'User', id: number }, project: { __typename?: 'Project', id: number } } };

export type ReportRenderCreateMutationVariables = Exact<{
  dataSourceId: Scalars['Int'];
  reportId: Scalars['Int'];
  reportRenderType: ReportRenderType;
}>;


export type ReportRenderCreateMutation = { __typename?: 'Mutation', ReportRenderCreate?: { __typename?: 'ReportRender', id: number, subId: string, reportRenderStatus?: ReportRenderStatus | null | undefined } | null | undefined };

export type ReportAssignmentCreateMutationVariables = Exact<{
  assignedId: Scalars['Int'];
  assignedType: RAssignedType;
  reportId: Scalars['Int'];
}>;


export type ReportAssignmentCreateMutation = { __typename?: 'Mutation', ReportAssignmentCreate?: { __typename?: 'ReportAssignment', assignedId: number, assignedType: RAssignedType, id: number, inheritsSetting?: boolean | null | undefined, reportId: number, report: { __typename?: 'Report', id: number, name: string } } | null | undefined };

export type ReportAssignmentUpdateMutationVariables = Exact<{
  assignedId: Scalars['Int'];
  assignedType: RAssignedType;
  reportId: Scalars['Int'];
  id: Scalars['Int'];
}>;


export type ReportAssignmentUpdateMutation = { __typename?: 'Mutation', ReportAssignmentUpdate?: { __typename?: 'ReportAssignment', assignedId: number, assignedType: RAssignedType, id: number, inheritsSetting?: boolean | null | undefined, reportId: number, report: { __typename?: 'Report', id: number, name: string } } | null | undefined };

export type ReportAssignmentDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReportAssignmentDeleteMutation = { __typename?: 'Mutation', ReportAssignmentDelete?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type ReportDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReportDeleteMutation = { __typename?: 'Mutation', report?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type ReportUpdateIsArchivedMutationVariables = Exact<{
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
}>;


export type ReportUpdateIsArchivedMutation = { __typename?: 'Mutation', report?: { __typename?: 'Report', id: number, name: string, type: ReportType, subType?: ReportSubType | null | undefined, isArchived: boolean, ownerId: number, ownerType: ReportOwnerType, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, newPageBefore: boolean, position: number, widgetConfigs?: Array<{ __typename?: 'WidgetConfig', id: number, settings: string, widgetOption?: { __typename?: 'WidgetOption', question?: string | null | undefined, fieldType: WidgetOptionFieldType, widgetId: number, id: number, defaultValue?: string | null | undefined } | null | undefined }> | null | undefined, widget: { __typename?: 'Widget', id: number, name?: string | null | undefined, isArchived: boolean, updatedAt: any, type: WidgetType } }> | null | undefined } | null | undefined };

export type ReportUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  type: ReportType;
  subType?: InputMaybe<ReportSubType>;
  reportConfigsToCreate?: InputMaybe<Array<ReportConfigCreateOneBatchedWithReportArgs> | ReportConfigCreateOneBatchedWithReportArgs>;
  reportConfigsToDelete?: InputMaybe<Array<DeleteByIdArgs> | DeleteByIdArgs>;
  reportConfigsToUpdate?: InputMaybe<Array<ReportConfigUpdateOneArgs> | ReportConfigUpdateOneArgs>;
}>;


export type ReportUpdateMutation = { __typename?: 'Mutation', Report?: { __typename?: 'Report', id: number, name: string, type: ReportType, subType?: ReportSubType | null | undefined, isArchived: boolean, ownerId: number, ownerType: ReportOwnerType, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, newPageBefore: boolean, position: number, widgetConfigs?: Array<{ __typename?: 'WidgetConfig', id: number, settings: string, widgetOption?: { __typename?: 'WidgetOption', question?: string | null | undefined, fieldType: WidgetOptionFieldType, widgetId: number, id: number, defaultValue?: string | null | undefined } | null | undefined }> | null | undefined, widget: { __typename?: 'Widget', id: number, name?: string | null | undefined, isArchived: boolean, updatedAt: any, type: WidgetType } }> | null | undefined } | null | undefined };

export type ReportCopyMutationVariables = Exact<{
  name: Scalars['String'];
  type: ReportType;
  subType?: InputMaybe<ReportSubType>;
  ownerId: Scalars['Int'];
  ownerType: ReportOwnerType;
  copyReportId: Scalars['Int'];
}>;


export type ReportCopyMutation = { __typename?: 'Mutation', report?: { __typename?: 'Report', id: number, name: string, type: ReportType, subType?: ReportSubType | null | undefined, isArchived: boolean, ownerId: number, ownerType: ReportOwnerType, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, newPageBefore: boolean, position: number, widgetConfigs?: Array<{ __typename?: 'WidgetConfig', id: number, settings: string, widgetOption?: { __typename?: 'WidgetOption', question?: string | null | undefined, fieldType: WidgetOptionFieldType, widgetId: number, id: number, defaultValue?: string | null | undefined } | null | undefined }> | null | undefined, widget: { __typename?: 'Widget', id: number, name?: string | null | undefined, isArchived: boolean, updatedAt: any, type: WidgetType } }> | null | undefined } | null | undefined };

export type ReportCreateMutationVariables = Exact<{
  name: Scalars['String'];
  type: ReportType;
  subType?: InputMaybe<ReportSubType>;
  ownerId: Scalars['Int'];
  ownerType: ReportOwnerType;
}>;


export type ReportCreateMutation = { __typename?: 'Mutation', report?: { __typename?: 'Report', id: number, name: string, type: ReportType, subType?: ReportSubType | null | undefined, isArchived: boolean, ownerId: number, ownerType: ReportOwnerType, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, newPageBefore: boolean, position: number, widgetConfigs?: Array<{ __typename?: 'WidgetConfig', id: number, settings: string, widgetOption?: { __typename?: 'WidgetOption', question?: string | null | undefined, fieldType: WidgetOptionFieldType, widgetId: number, id: number, defaultValue?: string | null | undefined } | null | undefined }> | null | undefined, widget: { __typename?: 'Widget', id: number, name?: string | null | undefined, isArchived: boolean, updatedAt: any, type: WidgetType } }> | null | undefined } | null | undefined };

export type StageResultsReportRenderCreateMutationVariables = Exact<{
  dataSourceId: Scalars['Int'];
  reportId: Scalars['Int'];
  reportRenderType: ReportRenderType;
  projectJobRoleId: Scalars['Int'];
}>;


export type StageResultsReportRenderCreateMutation = { __typename?: 'Mutation', StageResultsReportRenderCreate?: { __typename?: 'ReportRender', reportRenderStatus?: ReportRenderStatus | null | undefined, statusMessage?: string | null | undefined, subId: string, blob?: { __typename?: 'Blob', url: string } | null | undefined } | null | undefined };

export type RespondantFormDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
  associatedId: Scalars['Int'];
}>;


export type RespondantFormDeleteMutation = { __typename?: 'Mutation', RespondantFormDeleteByRespondantAndAssociatedId?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type ResultDeleteManyMutationVariables = Exact<{
  doneFor: Scalars['Int'];
  doneBy: Scalars['Int'];
  labels?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type ResultDeleteManyMutation = { __typename?: 'Mutation', ResultDeleteMany: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type RetrieveTpCandidatesMutationVariables = Exact<{
  candidateEmail: Scalars['String'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  stageId: Scalars['Int'];
  personalityTpAssessmentId?: InputMaybe<Scalars['Int']>;
  inductiveTpAssessmentId?: InputMaybe<Scalars['Int']>;
  numericalTpAssessmentId?: InputMaybe<Scalars['Int']>;
  verbalTpAssessmentId?: InputMaybe<Scalars['Int']>;
}>;


export type RetrieveTpCandidatesMutation = { __typename?: 'Mutation', retriveTpManualCandidates: boolean };

export type ScheduleRoiCsvFileExportMutationVariables = Exact<{
  projectIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ScheduleRoiCsvFileExportMutation = { __typename?: 'Mutation', FileExportScheduledTask: { __typename?: 'FileExportScheduledTask', status: ScheduledTaskStatus, error?: string | null | undefined, subId: string, blob?: { __typename?: 'Blob', url: string, id: string } | null | undefined } };

export type RssAssessmentCreateManyMutationVariables = Exact<{
  dto: Array<RssAssessmentCreateOneArgs> | RssAssessmentCreateOneArgs;
}>;


export type RssAssessmentCreateManyMutation = { __typename?: 'Mutation', RssAssessmentCreateMany: Array<{ __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number }> };

export type RssAssessmentDeleteManyMutationVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type RssAssessmentDeleteManyMutation = { __typename?: 'Mutation', RssAssessmentDeleteMany: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationVariables = Exact<{
  stageCandidateId: Scalars['Int'];
}>;


export type RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation = { __typename?: 'Mutation', RssAssessmentCandidateReopenAllSubmittedForStageCandidate: Array<{ __typename?: 'RssAssessmentCandidateModel', id: number, status: RssAssessmentCandidateStatus, rssAssessment: { __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number } }> };

export type SoftSkillBulkUpdateTraitsMutationVariables = Exact<{
  softSkillTraits: Array<BulkUpdateSoftSkillTraitArgsSingle> | BulkUpdateSoftSkillTraitArgsSingle;
}>;


export type SoftSkillBulkUpdateTraitsMutation = { __typename?: 'Mutation', softSkill?: { __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined } | null | undefined };

export type SoftSkillUploadIconMutationVariables = Exact<{
  id: Scalars['Int'];
  file: Scalars['Upload'];
}>;


export type SoftSkillUploadIconMutation = { __typename?: 'Mutation', softSkill: { __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined } };

export type SoftSkillDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SoftSkillDeleteMutation = { __typename?: 'Mutation', affected?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type AddSoftSkillMutationVariables = Exact<{
  name: Scalars['String'];
  category: Scalars['String'];
  companyId: Scalars['Int'];
  softSkillId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  measurementConstructType: MeasurementConstructType;
}>;


export type AddSoftSkillMutation = { __typename?: 'Mutation', softSkill?: { __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined } | null | undefined };

export type DeleteSoftSkillTraitMutationVariables = Exact<{
  softSkillId: Scalars['Int'];
  traitId: Scalars['Int'];
}>;


export type DeleteSoftSkillTraitMutation = { __typename?: 'Mutation', softSkill?: { __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined } | null | undefined };

export type ArchiveSoftSkillMutationVariables = Exact<{
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
}>;


export type ArchiveSoftSkillMutation = { __typename?: 'Mutation', softSkill?: { __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined } | null | undefined };

export type UpdateSoftSkillMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  measurementConstructType: MeasurementConstructType;
}>;


export type UpdateSoftSkillMutation = { __typename?: 'Mutation', softSkill?: { __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined } | null | undefined };

export type SaveSoftSkillSuccessProfileContentMutationVariables = Exact<{
  id: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  shortDescription?: InputMaybe<Scalars['String']>;
}>;


export type SaveSoftSkillSuccessProfileContentMutation = { __typename?: 'Mutation', saveSoftSkillContent?: { __typename?: 'SoftSkillSuccessProfileContent', id: number, softSkillId: number, roleLevel: ContentRoleLevel, shortDescription?: string | null | undefined, description?: string | null | undefined, softSkill?: { __typename?: 'SoftSkill', name: string } | null | undefined } | null | undefined };

export type SaveSoftSkillContentMutationVariables = Exact<{
  id: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  candidateContent?: InputMaybe<Scalars['String']>;
  hiringManagerContent?: InputMaybe<Scalars['String']>;
  internalCandidateContent?: InputMaybe<Scalars['String']>;
}>;


export type SaveSoftSkillContentMutation = { __typename?: 'Mutation', saveSoftSkillContent?: { __typename?: 'SoftSkillRoleLevelContent', id: number, softSkillId?: number | null | undefined, grade: Grade, roleLevel: ContentRoleLevel, description?: string | null | undefined, candidateContent?: string | null | undefined, hiringManagerContent?: string | null | undefined, internalCandidateContent?: string | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined } | null | undefined };

export type SetIndirectInviteSsoMutationVariables = Exact<{
  projectId: Scalars['Int'];
  indirectInviteSSO: Scalars['Boolean'];
}>;


export type SetIndirectInviteSsoMutation = { __typename?: 'Mutation', setIndirectInviteSSO: boolean };

export type StackOneJobExportMutationVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type StackOneJobExportMutation = { __typename?: 'Mutation', stackOneStartJobsExport: { __typename?: 'StackOneJobsExport', id: string, status: string } };

export type RemoveOneStageMutationVariables = Exact<{
  stageId: Scalars['Int'];
}>;


export type RemoveOneStageMutation = { __typename?: 'Mutation', RemoveOneStage: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type OrderStagesMutationVariables = Exact<{
  projectId: Scalars['Int'];
  stageOrderings: Array<OrderStageInput> | OrderStageInput;
}>;


export type OrderStagesMutation = { __typename?: 'Mutation', Stages: Array<{ __typename?: 'SmStageModel', id: number, name: string, type: StageType, order: number, status: StageStatus, startTime?: any | null | undefined, endTime?: any | null | undefined, projectId: number, managerReportEmailLinks: boolean, renderCandidateReport: boolean, emailCandidateReport: boolean, emailManagerReport: boolean, emailProjectTeam: boolean, emailProjectTeamRoles: Array<ActorRole>, hasCalibration: boolean, createInterviewGuide: boolean, emailSzAdmin: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, enableF2fInterviews: boolean, deiStageAction?: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } | null | undefined, availableActions: Array<{ __typename?: 'StageAvailableActionModel', availability: StageActionAvailability, name: string, subType?: StageActionSubType | null | undefined, type: StageActionType }>, rssAssessments: Array<{ __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number }> }> };

export type CreateStageMutationVariables = Exact<{
  hasCalibration: Scalars['Boolean'];
  createInterviewGuide: Scalars['Boolean'];
  emailCandidateReport: Scalars['Boolean'];
  emailManagerReport: Scalars['Boolean'];
  emailProjectTeam: Scalars['Boolean'];
  emailSzAdmin: Scalars['Boolean'];
  emailProjectTeamRoles?: InputMaybe<Array<ActorRole> | ActorRole>;
  endTime?: InputMaybe<Scalars['Date']>;
  name: Scalars['String'];
  projectId: Scalars['Int'];
  renderCandidateReport: Scalars['Boolean'];
  startTime?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<StageStatus>;
  testIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  type: StageType;
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
  enableF2fInterviews?: InputMaybe<Scalars['Boolean']>;
}>;


export type CreateStageMutation = { __typename?: 'Mutation', Stage: { __typename?: 'SmStageModel', id: number, name: string, type: StageType, order: number, status: StageStatus, startTime?: any | null | undefined, endTime?: any | null | undefined, projectId: number, managerReportEmailLinks: boolean, renderCandidateReport: boolean, emailCandidateReport: boolean, emailManagerReport: boolean, emailProjectTeam: boolean, emailProjectTeamRoles: Array<ActorRole>, hasCalibration: boolean, createInterviewGuide: boolean, emailSzAdmin: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, enableF2fInterviews: boolean, deiStageAction?: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } | null | undefined, availableActions: Array<{ __typename?: 'StageAvailableActionModel', availability: StageActionAvailability, name: string, subType?: StageActionSubType | null | undefined, type: StageActionType }>, rssAssessments: Array<{ __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number }> } };

export type StageEditDetailsMutationVariables = Exact<{
  emailCandidateReport?: InputMaybe<Scalars['Boolean']>;
  emailManagerReport?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeam?: InputMaybe<Scalars['Boolean']>;
  emailProjectTeamRoles?: InputMaybe<Array<ActorRole> | ActorRole>;
  hasCalibration?: InputMaybe<Scalars['Boolean']>;
  createInterviewGuide?: InputMaybe<Scalars['Boolean']>;
  emailSzAdmin?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  order?: InputMaybe<Scalars['Int']>;
  renderCandidateReport?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['Date']>;
  testIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  type?: InputMaybe<StageType>;
  managerReportEmailLinks?: InputMaybe<Scalars['Boolean']>;
  enableF2fInterviews?: InputMaybe<Scalars['Boolean']>;
}>;


export type StageEditDetailsMutation = { __typename?: 'Mutation', Stage: { __typename?: 'SmStageModel', id: number, name: string, type: StageType, order: number, status: StageStatus, startTime?: any | null | undefined, endTime?: any | null | undefined, projectId: number, managerReportEmailLinks: boolean, renderCandidateReport: boolean, emailCandidateReport: boolean, emailManagerReport: boolean, emailProjectTeam: boolean, emailProjectTeamRoles: Array<ActorRole>, hasCalibration: boolean, createInterviewGuide: boolean, emailSzAdmin: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, enableF2fInterviews: boolean, deiStageAction?: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } | null | undefined, availableActions: Array<{ __typename?: 'StageAvailableActionModel', availability: StageActionAvailability, name: string, subType?: StageActionSubType | null | undefined, type: StageActionType }>, rssAssessments: Array<{ __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number }> } };

export type StageCandidateResetOneMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type StageCandidateResetOneMutation = { __typename?: 'Mutation', Stages: boolean };

export type StageCandidateReprocessOneMutationVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type StageCandidateReprocessOneMutation = { __typename?: 'Mutation', StageCandidateReprocessOne: boolean };

export type StageCreateCsvReportMutationVariables = Exact<{
  stageIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  measurementConstructsToDisplay?: InputMaybe<Array<MeasurementConstructType> | MeasurementConstructType>;
  scoreTypesToDisplay?: InputMaybe<Array<DisplayScoreType> | DisplayScoreType>;
  basicScoreTypesToDisplay?: InputMaybe<Array<BasicScoreType> | BasicScoreType>;
  softSkillSort?: InputMaybe<SpSoftSkillSort>;
  name?: InputMaybe<Scalars['String']>;
  options: StageCsvOptions;
}>;


export type StageCreateCsvReportMutation = { __typename?: 'Mutation', StageCreateCsvReport: { __typename?: 'StageGetCsvReportModel', blobName: string } };

export type StageCandidateCopyTestResultsMutationVariables = Exact<{
  baseStageId: Scalars['Int'];
  candidateId: Scalars['Int'];
  targetStageId: Scalars['Int'];
}>;


export type StageCandidateCopyTestResultsMutation = { __typename?: 'Mutation', StageCandidateCopyTestResults: boolean };

export type StageCandidateDeleteOneMutationVariables = Exact<{
  subId?: InputMaybe<Scalars['String']>;
}>;


export type StageCandidateDeleteOneMutation = { __typename?: 'Mutation', StageCandidateDeleteOne: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type DeiStageActionUpsertMutationVariables = Exact<{
  stageId: Scalars['Int'];
  fields: DeiStageActionFieldsInput;
}>;


export type DeiStageActionUpsertMutation = { __typename?: 'Mutation', DeiStageActionUpsert: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } };

export type DeiStageActionArchiveMutationVariables = Exact<{
  stageId: Scalars['Int'];
}>;


export type DeiStageActionArchiveMutation = { __typename?: 'Mutation', DeiStageActionArchive: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } };

export type DeiStageActionUnarchiveMutationVariables = Exact<{
  stageId: Scalars['Int'];
  fields: DeiStageActionFieldsInput;
}>;


export type DeiStageActionUnarchiveMutation = { __typename?: 'Mutation', DeiStageActionUnarchive: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } };

export type DeiStageActionCandidateReopenMutationVariables = Exact<{
  stageCandidateId: Scalars['Int'];
}>;


export type DeiStageActionCandidateReopenMutation = { __typename?: 'Mutation', DeiStageActionCandidateReopen: { __typename?: 'DeiStageActionCandidateModel', createdAt: any, status: DeiStageActionCandidateStatus, updatedAt: any } };

export type TmTestUpdateOneMutationVariables = Exact<{
  tpProjectId?: InputMaybe<Scalars['String']>;
  testId: Scalars['Int'];
  origin: TestOrigin;
  nQuestionsPerTrait?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<TestType>;
  subType?: InputMaybe<TestSubType>;
}>;


export type TmTestUpdateOneMutation = { __typename?: 'Mutation', TmTestUpdateOne: { __typename?: 'TmTestModel', id: number, stageId: number, nQuestionsPerTrait?: number | null | undefined, origin: TestOrigin, tpProjectId?: string | null | undefined, type: TestType, subType?: TestSubType | null | undefined } };

export type UpdateSuccessProfileMutationVariables = Exact<{
  id: Scalars['Int'];
  roleLevel: ContentRoleLevel;
  calculationMethod: CalculationMethod;
  gradingMethod: GradingMethod;
}>;


export type UpdateSuccessProfileMutation = { __typename?: 'Mutation', successProfile: { __typename?: 'SuccessProfile', calculationMethod: CalculationMethod, createdAt: any, deletedAt?: any | null | undefined, gradingMethod: GradingMethod, id: number, jobRoleId: number, roleLevel: ContentRoleLevel, status: SuccessProfileStatus, updatedAt: any, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined }> | null | undefined } };

export type CreateSuccessProfileMutationVariables = Exact<{
  roleLevel: ContentRoleLevel;
  calculationMethod: CalculationMethod;
  gradingMethod: GradingMethod;
  jobRoleId: Scalars['Int'];
}>;


export type CreateSuccessProfileMutation = { __typename?: 'Mutation', successProfile: { __typename?: 'SuccessProfile', calculationMethod: CalculationMethod, createdAt: any, deletedAt?: any | null | undefined, gradingMethod: GradingMethod, id: number, jobRoleId: number, roleLevel: ContentRoleLevel, status: SuccessProfileStatus, updatedAt: any, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined }> | null | undefined } };

export type SuccessProfileSoftSkillsOverwriteMutationVariables = Exact<{
  id: Scalars['Int'];
  softSkills: Array<SuccessProfileSoftSkillCreateOneArgs> | SuccessProfileSoftSkillCreateOneArgs;
}>;


export type SuccessProfileSoftSkillsOverwriteMutation = { __typename?: 'Mutation', SuccessProfileSoftSkillsOverwrite: { __typename?: 'SuccessProfile', id: number, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, weight: number }> | null | undefined } };

export type SuccessProfileTechnicalSkillsOverwriteMutationVariables = Exact<{
  id: Scalars['Int'];
  technicalSkills: Array<SuccessProfileTechnicalSkillCreateOneArgs> | SuccessProfileTechnicalSkillCreateOneArgs;
}>;


export type SuccessProfileTechnicalSkillsOverwriteMutation = { __typename?: 'Mutation', SuccessProfileTechnicalSkillsOverwrite: { __typename?: 'SuccessProfile', id: number, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, technicalSkillId: number, successProfileId: number }> | null | undefined } };

export type TestCandidateUpdateOneMutationVariables = Exact<{
  assessmentId?: InputMaybe<Scalars['Int']>;
  doneBy?: InputMaybe<Scalars['Int']>;
  doneFor?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
  stageCandidateId?: InputMaybe<Scalars['Int']>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<TestStatus>;
  testOrigin?: InputMaybe<TestOrigin>;
  timeModifierPercentage?: InputMaybe<Scalars['Float']>;
}>;


export type TestCandidateUpdateOneMutation = { __typename?: 'Mutation', TestCandidateUpdateOne: { __typename?: 'TmTestCandidateModel', id: number, assessmentId?: number | null | undefined, status: TestStatus, testId: number, doneBy: number, doneFor: number, timeModifierPercentage: number, test?: { __typename?: 'TmTestModel', tpProjectId?: string | null | undefined, type: TestType, stageId: number, subType?: TestSubType | null | undefined } | null | undefined, testResult?: { __typename?: 'TmTestResultModel', updatedAt: any, testCandidateId: number, scores: Array<{ __typename?: 'BasicScoreModel', id: number, score: { __typename?: 'ScoreModel', zScore?: number | null | undefined, percentile?: number | null | undefined, stenScore?: number | null | undefined, grade?: Grade | null | undefined } }> } | null | undefined } };

export type AddTraitMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type AddTraitMutation = { __typename?: 'Mutation', Trait?: { __typename?: 'Trait', id: number, name: string, description: string, isArchived: boolean, testTypes: Array<{ __typename?: 'TraitTestTypeModel', id: number, testSubType?: TestSubType | null | undefined, testType: TestType, traitId: number }> } | null | undefined };

export type UpdateTraitMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  description: Scalars['String'];
  isArchived: Scalars['Boolean'];
}>;


export type UpdateTraitMutation = { __typename?: 'Mutation', Trait?: { __typename?: 'Trait', id: number, name: string, description: string, isArchived: boolean, testTypes: Array<{ __typename?: 'TraitTestTypeModel', id: number, testSubType?: TestSubType | null | undefined, testType: TestType, traitId: number }> } | null | undefined };

export type UpdateTraitTestTypesMutationVariables = Exact<{
  dto: Array<TraitTestTypesUpdateOneArgs> | TraitTestTypesUpdateOneArgs;
}>;


export type UpdateTraitTestTypesMutation = { __typename?: 'Mutation', traitTestTypes?: Array<{ __typename?: 'TraitTestTypeModel', id: number, testSubType?: TestSubType | null | undefined, testType: TestType, traitId: number }> | null | undefined };

export type ArchiveTraitMutationVariables = Exact<{
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
}>;


export type ArchiveTraitMutation = { __typename?: 'Mutation', Trait?: { __typename?: 'Trait', id: number, name: string, description: string, isArchived: boolean, testTypes: Array<{ __typename?: 'TraitTestTypeModel', id: number, testSubType?: TestSubType | null | undefined, testType: TestType, traitId: number }> } | null | undefined };

export type DeleteTraitMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteTraitMutation = { __typename?: 'Mutation', affected?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type SaveTraitContentMutationVariables = Exact<{
  id: Scalars['Int'];
  summary?: InputMaybe<Scalars['String']>;
  content?: InputMaybe<Scalars['String']>;
  developmentContent?: InputMaybe<Scalars['String']>;
}>;


export type SaveTraitContentMutation = { __typename?: 'Mutation', traitContent?: { __typename?: 'TraitRoleLevelContent', id?: number | null | undefined, softSkillId?: number | null | undefined, traitId?: number | null | undefined, classification: ContentClassification, roleLevel: ContentRoleLevel, summary?: string | null | undefined, content?: string | null | undefined, developmentContent?: string | null | undefined } | null | undefined };

export type UserDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserDeleteMutation = { __typename?: 'Mutation', UserDelete: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type UserDeleteByEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type UserDeleteByEmailMutation = { __typename?: 'Mutation', UserDeleteByEmail: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type UserCreateMutationVariables = Exact<{
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
}>;


export type UserCreateMutation = { __typename?: 'Mutation', User: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } };

export type UserUpdateMutationVariables = Exact<{
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Scalars['String'];
}>;


export type UserUpdateMutation = { __typename?: 'Mutation', User: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } };

export type UserRoleUpdateMutationVariables = Exact<{
  companyId?: InputMaybe<Scalars['Float']>;
  role?: InputMaybe<IdentityPermissionRole>;
  externalId?: InputMaybe<Scalars['String']>;
  id: Scalars['Float'];
}>;


export type UserRoleUpdateMutation = { __typename?: 'Mutation', UserRole: { __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } } };

export type UserRoleCreateMutationVariables = Exact<{
  userId: Scalars['Float'];
  companyId: Scalars['Float'];
  role: IdentityPermissionRole;
  externalId?: InputMaybe<Scalars['String']>;
}>;


export type UserRoleCreateMutation = { __typename?: 'Mutation', UserRoleCreate?: { __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } } | null | undefined };

export type UserRoleDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UserRoleDeleteMutation = { __typename?: 'Mutation', UserRoleDelete: { __typename?: 'DeleteResult', affected?: number | null | undefined } };

export type WidgetOptionUpdateMutationVariables = Exact<{
  name: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  fieldType: WidgetOptionFieldType;
}>;


export type WidgetOptionUpdateMutation = { __typename?: 'Mutation', WidgetOptions?: { __typename?: 'WidgetOption', id: number, name: string, fieldType: WidgetOptionFieldType, defaultValue?: string | null | undefined, question?: string | null | undefined, isArchived: boolean, widgetId: number } | null | undefined };

export type WidgetUpdateIsArchivedMutationVariables = Exact<{
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
}>;


export type WidgetUpdateIsArchivedMutation = { __typename?: 'Mutation', WidgetUpdateIsArchived?: { __typename?: 'Widget', id: number, name?: string | null | undefined, type: WidgetType, screenShotBlobId?: number | null | undefined, isArchived: boolean, updatedAt: any, screenShotBlob?: { __typename?: 'Blob', id: string, url: string } | null | undefined, widgetOptions?: Array<{ __typename?: 'WidgetOption', id: number, question?: string | null | undefined, fieldType: WidgetOptionFieldType, defaultValue?: string | null | undefined, isArchived: boolean }> | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, reportId: number, newPageBefore: boolean }> | null | undefined } | null | undefined };

export type WidgetOptionUpdateIsArchivedMutationVariables = Exact<{
  id: Scalars['Int'];
  isArchived: Scalars['Boolean'];
}>;


export type WidgetOptionUpdateIsArchivedMutation = { __typename?: 'Mutation', WidgetOptionUpdateIsArchived?: { __typename?: 'WidgetOption', id: number, name: string, question?: string | null | undefined, isArchived: boolean, fieldType: WidgetOptionFieldType, defaultValue?: string | null | undefined } | null | undefined };

export type WidgetDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type WidgetDeleteMutation = { __typename?: 'Mutation', WidgetDelete?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type WidgetOptionDeleteMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type WidgetOptionDeleteMutation = { __typename?: 'Mutation', WidgetOptionDelete?: { __typename?: 'DeleteResult', affected?: number | null | undefined } | null | undefined };

export type WidgetCreateMutationVariables = Exact<{
  type: WidgetType;
  name: Scalars['String'];
}>;


export type WidgetCreateMutation = { __typename?: 'Mutation', Widget?: { __typename?: 'Widget', id: number, name?: string | null | undefined, type: WidgetType, screenShotBlobId?: number | null | undefined, isArchived: boolean, updatedAt: any, screenShotBlob?: { __typename?: 'Blob', id: string, url: string } | null | undefined, widgetOptions?: Array<{ __typename?: 'WidgetOption', id: number, question?: string | null | undefined, fieldType: WidgetOptionFieldType, defaultValue?: string | null | undefined, isArchived: boolean }> | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, reportId: number, newPageBefore: boolean }> | null | undefined } | null | undefined };

export type WidgetUploadScreenShotMutationVariables = Exact<{
  id: Scalars['Int'];
  file: Scalars['Upload'];
}>;


export type WidgetUploadScreenShotMutation = { __typename?: 'Mutation', WidgetUploadScreenShot: { __typename?: 'Widget', id: number, name?: string | null | undefined, type: WidgetType } };

export type WidgetUpdateMutationVariables = Exact<{
  type?: InputMaybe<WidgetType>;
  name?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
}>;


export type WidgetUpdateMutation = { __typename?: 'Mutation', WidgetUpdate?: { __typename?: 'Widget', id: number, name?: string | null | undefined, type: WidgetType } | null | undefined };

export type WidgetOptionCreateMutationVariables = Exact<{
  name: Scalars['String'];
  question?: InputMaybe<Scalars['String']>;
  defaultValue?: InputMaybe<Scalars['String']>;
  widgetId: Scalars['Int'];
  fieldType: WidgetOptionFieldType;
}>;


export type WidgetOptionCreateMutation = { __typename?: 'Mutation', WidgetOptions?: { __typename?: 'WidgetOption', id: number, name: string, fieldType: WidgetOptionFieldType, defaultValue?: string | null | undefined, question?: string | null | undefined, isArchived: boolean, widgetId: number } | null | undefined };

export type WidgetOptionUploadCoverPageImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type WidgetOptionUploadCoverPageImageMutation = { __typename?: 'Mutation', WidgetOptionUploadCoverPageImage: { __typename?: 'BlobModel', id: string, url: string } };

export type WidgetOptionUploadBrandedSoftSkillImageMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type WidgetOptionUploadBrandedSoftSkillImageMutation = { __typename?: 'Mutation', WidgetOptionUploadBrandedSoftSkillImage: { __typename?: 'BlobModel', id: string, url: string } };

export type SkillCategoryFindManyQueryVariables = Exact<{ [key: string]: never; }>;


export type SkillCategoryFindManyQuery = { __typename?: 'Query', SkillCategoryFindMany: Array<{ __typename?: 'SkillCategory', id: number, name: string }> };

export type ApiKeyFindAllQueryVariables = Exact<{ [key: string]: never; }>;


export type ApiKeyFindAllQuery = { __typename?: 'Query', ApiKeyFindAll: Array<{ __typename?: 'AmApiKeyModel', id: number, key: string, ownerId: number, ownerType: ApiKeyOwnerType, puppetUserId: number, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, role?: IdentityPermissionRole | null | undefined, usageRecord: Array<{ __typename?: 'ApiKeyUsageRecordModel', route: string, count: number }> }> };

export type BaseMethodTestFindManySearchPaginatedQueryVariables = Exact<{
  textSearch: Scalars['String'];
}>;


export type BaseMethodTestFindManySearchPaginatedQuery = { __typename?: 'Query', BaseMethodTestFindManySearchPaginated: { __typename?: 'PaginatedBaseMethodTestModel', total: number, baseMethodTests: Array<{ __typename?: 'BaseMethodTest', id: number, name?: string | null | undefined }> } };

export type BlobFindByNameQueryVariables = Exact<{
  name: Scalars['String'];
  getOption: Scalars['String'];
}>;


export type BlobFindByNameQuery = { __typename?: 'Query', Blob?: { __typename?: 'Blob', id: string, url: string } | null | undefined };

export type CompaniesQueryVariables = Exact<{ [key: string]: never; }>;


export type CompaniesQuery = { __typename?: 'Query', Companies?: Array<{ __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean }> | null | undefined };

export type CompanyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompanyQuery = { __typename?: 'Query', company?: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean } | null | undefined };

export type CompanySignatureFindManyQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']>;
}>;


export type CompanySignatureFindManyQuery = { __typename?: 'Query', CompanySignature?: Array<{ __typename?: 'CompanySignatureModel', id: number, companyId: number, isDefault: boolean, signature: string, signatureEmail: string }> | null | undefined };

export type CompanyFindManyByIdsQueryVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type CompanyFindManyByIdsQuery = { __typename?: 'Query', CompanyFindManyByIds?: Array<{ __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean }> | null | undefined };

export type GetCompanyIdQueryVariables = Exact<{
  projectId: Scalars['Float'];
}>;


export type GetCompanyIdQuery = { __typename?: 'Query', getCompanyId: { __typename?: 'ProjectCompanyId', companyId: number } };

export type CompanyFindUsersQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type CompanyFindUsersQuery = { __typename?: 'Query', companyUsers: Array<{ __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined> };

export type CompanyFindUsersPaginatedQueryVariables = Exact<{
  id: Scalars['Int'];
  skip: Scalars['Int'];
  take: Scalars['Int'];
}>;


export type CompanyFindUsersPaginatedQuery = { __typename?: 'Query', companyUsers: { __typename?: 'UserModelPaginated', data?: Array<{ __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> }> | null | undefined, pageInfo?: { __typename?: 'Pageinfo', itemsTotal: number, currentPage: number, itemsOnPage: number, pageTotal: number, skip?: number | null | undefined, take?: number | null | undefined } | null | undefined } };

export type CompanyRolesQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type CompanyRolesQuery = { __typename?: 'Query', CompanyRoles: Array<{ __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined }> };

export type CompanyUsersFindManyPaginatedQueryVariables = Exact<{
  args: CompanyUsersArgs;
  order?: InputMaybe<CompanyUsersOrderOptions>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
}>;


export type CompanyUsersFindManyPaginatedQuery = { __typename?: 'Query', companyUsers: { __typename?: 'CompanyUserModelPaginated', data?: Array<{ __typename?: 'CompanyUser', group?: { __typename?: 'UserGroupModel', id: number, subId: string, companyId: number, name: string, description?: string | null | undefined, membersCount?: number | null | undefined, membersPreview?: Array<{ __typename?: 'UserGroupMemberPreview', id: number, firstName?: string | null | undefined, lastName?: string | null | undefined, email: string }> | null | undefined } | null | undefined, user?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined } | null | undefined, inProjectTeam?: { __typename?: 'AssignmentStatus', asGroupMember?: boolean | null | undefined, asIndividual?: boolean | null | undefined } | null | undefined, inStageCandidate?: { __typename?: 'AssignmentStatus', asGroupMember?: boolean | null | undefined, asIndividual?: boolean | null | undefined } | null | undefined, inUserGroup?: { __typename?: 'AssignmentStatus', asGroupMember?: boolean | null | undefined, asIndividual?: boolean | null | undefined } | null | undefined }> | null | undefined, pageInfo?: { __typename?: 'Pageinfo', itemsTotal: number, currentPage: number, itemsOnPage: number, pageTotal: number, skip?: number | null | undefined, take?: number | null | undefined } | null | undefined } };

export type CompanyFindManyUsersWithRolesQueryVariables = Exact<{
  take: Scalars['Int'];
  skip: Scalars['Int'];
  userRoles: Array<IdentityPermissionRole> | IdentityPermissionRole;
  companyIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type CompanyFindManyUsersWithRolesQuery = { __typename?: 'Query', CompanyFindManyUsersWithRoles: { __typename?: 'CompanyUserInfoPaginated', total: number, companyUserInfo: Array<{ __typename?: 'CompanyUserInfo', company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean }, companyUserAcrossAllRoles: { __typename?: 'CompanyUsersCount', companyId: number, totalUsers: number, totalUsersWithPassword: number }, companyUserAcrossEachRole: Array<{ __typename?: 'CompanyUsersWithRolesCount', companyId: number, totalUsersWithPassword: number, totalUsers: number, roleName: IdentityPermissionRole }> }> } };

export type TraitSoftSkillContextCsvGenerateAndUploadQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type TraitSoftSkillContextCsvGenerateAndUploadQuery = { __typename?: 'Query', TraitSoftSkillContextCsvGenerateAndUpload?: { __typename?: 'Blob', id: string, key: string, acl: Acl, extension: string, bucket: string, publishedName: string, url: string, contentType?: string | null | undefined, contentEncoding?: string | null | undefined, ownerService: string, createdAt: string, updatedAt: string, deletedAt?: string | null | undefined } | null | undefined };

export type ExternalAssessmentFindManyPaginatedQueryVariables = Exact<{
  skip: Scalars['Int'];
}>;


export type ExternalAssessmentFindManyPaginatedQuery = { __typename?: 'Query', ExternalAssessmentFindManyPaginated: { __typename?: 'PaginatedExternalAssessmentModel', total: number, externalAssessments: Array<{ __typename?: 'ExternalAssessmentModel', createdAt: any, deletedAt?: any | null | undefined, eaCompanyPlatformId?: number | null | undefined, estimatedDurationInMinutes?: number | null | undefined, externalPlatformType: ExternalPlatformType, id: number, name: string, subId: string, updatedAt: any, timeLimitInMinutes?: number | null | undefined, eaSkills?: Array<{ __typename?: 'EASkillModel', id: number, subId: string, technicalSkillId: number }> | null | undefined, eaPlatformAssessments?: Array<{ __typename?: 'EAPlatformAssessmentModel', eaPlatformId: string, id: number, platformUrl?: string | null | undefined, modifier: ExternalPlatformModifier, subId: string }> | null | undefined }> } };

export type GetFormReportCsvQueryVariables = Exact<{
  formManagerType: FormManagerType;
  formType: FormType;
  formOwnerId: Scalars['Int'];
  associatedId: Scalars['Int'];
}>;


export type GetFormReportCsvQuery = { __typename?: 'Query', getFormReportCSV?: string | null | undefined };

export type FormManagerTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type FormManagerTypesQuery = { __typename?: 'Query', FormReportFindFormManagerTypes?: { __typename?: 'FormReportIndividualQueries', formManagerTypes?: Array<FormManagerType> | null | undefined } | null | undefined };

export type FormTypesQueryVariables = Exact<{
  formManagerType: FormManagerType;
}>;


export type FormTypesQuery = { __typename?: 'Query', FormReportFindFormTypes?: { __typename?: 'FormReportIndividualQueries', formTypes?: Array<FormType> | null | undefined } | null | undefined };

export type FormOwnersQueryVariables = Exact<{
  formManagerType: FormManagerType;
  formType: FormType;
}>;


export type FormOwnersQuery = { __typename?: 'Query', FormReportFindFormOwners?: Array<{ __typename?: 'FormReportFormOwners', id?: number | null | undefined, name?: string | null | undefined }> | null | undefined };

export type FormAssociatesQueryVariables = Exact<{
  formManagerType: FormManagerType;
  formType: FormType;
  formOwnerId: Scalars['Int'];
}>;


export type FormAssociatesQuery = { __typename?: 'Query', FormReportFindAssociatedIds?: { __typename?: 'FormReportIndividualQueries', associatedIds?: Array<number> | null | undefined } | null | undefined };

export type IgCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IgCategoryQuery = { __typename?: 'Query', IgCategory?: { __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any, interviewGuideCategories?: Array<{ __typename?: 'IgInterviewGuideCategory', id: number, categoryId: number, interviewGuideId: number, interviewGuide: { __typename?: 'IgInterviewGuide', id: number, name: string } }> | null | undefined } | null | undefined };

export type IgCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type IgCategoriesQuery = { __typename?: 'Query', IgCategories?: Array<{ __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any, interviewGuideCategories?: Array<{ __typename?: 'IgInterviewGuideCategory', id: number, categoryId: number, interviewGuideId: number, interviewGuide: { __typename?: 'IgInterviewGuide', id: number, name: string } }> | null | undefined }> | null | undefined };

export type IgCategoryFindManyQueryVariables = Exact<{
  isArchived: Scalars['Boolean'];
}>;


export type IgCategoryFindManyQuery = { __typename?: 'Query', IgCategoryFindMany?: Array<{ __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any, interviewGuideCategories?: Array<{ __typename?: 'IgInterviewGuideCategory', id: number, categoryId: number, interviewGuideId: number, interviewGuide: { __typename?: 'IgInterviewGuide', id: number, name: string } }> | null | undefined }> | null | undefined };

export type IgInterviewGuideCategoryFindManyQueryVariables = Exact<{
  interviewGuideId: Scalars['Int'];
}>;


export type IgInterviewGuideCategoryFindManyQuery = { __typename?: 'Query', IgInterviewGuideCategoryFindMany?: Array<{ __typename?: 'IgInterviewGuideCategory', id: number, order?: number | null | undefined, colour?: IgCategoryColour | null | undefined, interviewGuide: { __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any }, category: { __typename?: 'IgCategory', id: number, name: string, internalName: string, isArchived: boolean, updatedAt: any } }> | null | undefined };

export type IgInterviewGuidesQueryVariables = Exact<{ [key: string]: never; }>;


export type IgInterviewGuidesQuery = { __typename?: 'Query', IgInterviewGuides?: Array<{ __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any }> | null | undefined };

export type IgInterviewGuideQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IgInterviewGuideQuery = { __typename?: 'Query', IgInterviewGuide?: { __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any } | null | undefined };

export type IgInterviewGuideFindManyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IgInterviewGuideFindManyQuery = { __typename?: 'Query', IgInterviewGuideFindMany?: Array<{ __typename?: 'IgInterviewGuide', id: number, name: string, ownerId: number, updatedAt: any, isArchived: boolean, createdAt: any }> | null | undefined };

export type IgStepFindManyQueryVariables = Exact<{
  interviewGuideId: Scalars['Int'];
  isArchived: Scalars['Boolean'];
}>;


export type IgStepFindManyQuery = { __typename?: 'Query', IgStepFindMany?: Array<{ __typename?: 'IgStep', id: number, name: string, internalName: string, action?: string | null | undefined, durationInSeconds?: number | null | undefined, colour?: IgCategoryColour | null | undefined, questionType: IgQuestionType, order: number, isArchived: boolean, staticQuestionName?: string | null | undefined }> | null | undefined };

export type IgStepQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type IgStepQuery = { __typename?: 'Query', IgStep?: { __typename?: 'IgStep', id: number, name: string, internalName: string, action?: string | null | undefined, durationInSeconds?: number | null | undefined, colour?: IgCategoryColour | null | undefined, questionType: IgQuestionType, order: number, isArchived: boolean, staticQuestionName?: string | null | undefined } | null | undefined };

export type InterviewQuestionRoleLevelContentFindManyAndCountQueryVariables = Exact<{
  softSkillIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel> | ContentRoleLevel>;
  classifications?: InputMaybe<Array<ContentClassification> | ContentClassification>;
  take: Scalars['Int'];
  page: Scalars['Int'];
  order?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type InterviewQuestionRoleLevelContentFindManyAndCountQuery = { __typename?: 'Query', interviewQuestionContent: { __typename?: 'InterviewQuestionRoleLevelPaginatedModel', count?: number | null | undefined, interviewQuestionContent?: Array<{ __typename?: 'InterviewQuestionRoleLevel', id: number, softSkillId: number, traitId: number, classification: ContentClassification, roleLevel: ContentRoleLevel, summary?: string | null | undefined, feedback?: string | null | undefined, reasonForQuestion?: string | null | undefined, mainQuestion?: string | null | undefined, subQuestions?: Array<string> | null | undefined, softSkill?: { __typename?: 'SoftSkill', name: string } | null | undefined, trait?: { __typename?: 'Trait', name: string } | null | undefined }> | null | undefined } };

export type JobRoleFindOneQueryVariables = Exact<{
  id?: InputMaybe<Scalars['Int']>;
}>;


export type JobRoleFindOneQuery = { __typename?: 'Query', JobRoleFindOne?: { __typename?: 'JobRoleModel', companyId: number, createdAt: any, deletedAt?: any | null | undefined, id: number, name: string, qualifier?: string | null | undefined, roleLevel: ContentRoleLevel, updatedAt: any, family?: JobRoleFamily | null | undefined, skillsProfiles: Array<{ __typename?: 'SuccessProfile', calculationMethod: CalculationMethod, createdAt: any, deletedAt?: any | null | undefined, gradingMethod: GradingMethod, id: number, jobRoleId: number, roleLevel: ContentRoleLevel, status: SuccessProfileStatus, updatedAt: any, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined }> | null | undefined }> } | null | undefined };

export type JobRoleFindManyQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']>;
  roleLevel?: InputMaybe<ContentRoleLevel>;
}>;


export type JobRoleFindManyQuery = { __typename?: 'Query', JobRoleFindMany: Array<{ __typename?: 'JobRoleModel', companyId: number, createdAt: any, deletedAt?: any | null | undefined, id: number, name: string, qualifier?: string | null | undefined, roleLevel: ContentRoleLevel, updatedAt: any, skillsProfiles: Array<{ __typename?: 'SuccessProfile', id: number, createdAt: any }> }> };

export type ProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProjectsQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined }> };

export type GetProjectByIdQueryVariables = Exact<{
  id: Scalars['Float'];
}>;


export type GetProjectByIdQuery = { __typename?: 'Query', project?: { __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined } | null | undefined };

export type ProjectFindManyByCompanyQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type ProjectFindManyByCompanyQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined }> };

export type ResultsPageProjectFindManyByCompanyQueryVariables = Exact<{
  companyId: Scalars['Int'];
}>;


export type ResultsPageProjectFindManyByCompanyQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'Project', id: number, name: string }> };

export type ProjectFindManysByCompanyWithStageCandidateRangeQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  companySignatureId?: InputMaybe<Scalars['Int']>;
  createdAtFrom?: InputMaybe<Scalars['Date']>;
  createdAtTo?: InputMaybe<Scalars['Date']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  managerIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  moduleType?: InputMaybe<ProjectModuleType>;
  name?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
}>;


export type ProjectFindManysByCompanyWithStageCandidateRangeQuery = { __typename?: 'Query', projects: Array<{ __typename?: 'CompanyProjects', id: number, numberOfStageCandidates: number, percentageOfCompletedStageCandidates: number, numberOfCompletedInTimeFrameStageCandidates: number, numberOfCompletedStageCandidates: number, company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean }, projects: Array<{ __typename?: 'ProjectWithStageCandidates', id: number, name: string, numberOfStageCandidates: number, percentageOfCompletedStageCandidates: number, numberOfCompletedStageCandidates: number, numberOfCompletedInTimeFrameStageCandidates: number }> }> };

export type ProjectFindManysByCompanyWithStageCandidateRangeCsvQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']>;
  companyIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  companySignatureId?: InputMaybe<Scalars['Int']>;
  createdAtFrom?: InputMaybe<Scalars['Date']>;
  createdAtTo?: InputMaybe<Scalars['Date']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isStopped?: InputMaybe<Scalars['Boolean']>;
  managerIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  moduleType?: InputMaybe<ProjectModuleType>;
  name?: InputMaybe<Scalars['String']>;
  subId?: InputMaybe<Scalars['String']>;
}>;


export type ProjectFindManysByCompanyWithStageCandidateRangeCsvQuery = { __typename?: 'Query', projects: { __typename?: 'Blob', id: string, key: string, acl: Acl, extension: string, bucket: string, publishedName: string, url: string, contentType?: string | null | undefined, contentEncoding?: string | null | undefined, ownerService: string, createdAt: string, updatedAt: string, deletedAt?: string | null | undefined } };

export type ProjectFindManyPaginatedQueryVariables = Exact<{
  args: ProjectCompanyArgs;
  filter?: InputMaybe<ProjectCompanyFilterOptions>;
  order?: InputMaybe<ProjectCompanyOrderOptions>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
}>;


export type ProjectFindManyPaginatedQuery = { __typename?: 'Query', ProjectFindManyPaginated: { __typename?: 'PaginatedProjectsModel', data?: Array<{ __typename?: 'Project', companySignatureId?: number | null | undefined, createdAt: string, creatorId: number, id: number, isArchived: boolean, isStopped: boolean, locales: Array<SupportedLocale>, moduleType: ProjectModuleType, name: string, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, indirectInviteSSOEnabled?: boolean | null | undefined, company?: { __typename?: 'ProjectCompany', id: number, name: string, logoUrl?: string | null | undefined, managerReportEmailLinks: boolean } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined }> | null | undefined, pageInfo?: { __typename?: 'Pageinfo', itemsTotal: number, currentPage: number, itemsOnPage: number, pageTotal: number, skip?: number | null | undefined, take?: number | null | undefined } | null | undefined } };

export type GetProjectCobrandingQueryVariables = Exact<{
  subId: Scalars['String'];
}>;


export type GetProjectCobrandingQuery = { __typename?: 'Query', getProjectCobranding: { __typename?: 'ProjectCobrandingModel', companyLogoUrl?: string | null | undefined, companyName: string, name: string } };

export type ProjectCollaboratorsFindManyPaginatedQueryVariables = Exact<{
  args: ProjectCollaboratorArgs;
  order?: InputMaybe<ProjectCollaboratorOrderOptions>;
  paginate?: InputMaybe<OffsetBasedPaginationArgs>;
}>;


export type ProjectCollaboratorsFindManyPaginatedQuery = { __typename?: 'Query', collaborators?: { __typename?: 'ProjectCollaboratorPaginated', data?: Array<{ __typename?: 'ProjectCollaborator', id: number, actorRole?: ActorRole | null | undefined, userId?: number | null | undefined, groupId?: number | null | undefined, user?: { __typename?: 'ProjectCollaboratorUser', id: number, uuid: string, email: string, firstName?: string | null | undefined, lastName?: string | null | undefined, companyRoles?: Array<IdentityPermissionRole | null | undefined> | null | undefined, avatarUrl?: string | null | undefined } | null | undefined, group?: { __typename?: 'ProjectCollaboratorGroup', id: number, subId: string, name: string, description?: string | null | undefined, companyId: number, membersCount: number, membersPreview: Array<{ __typename?: 'ProjectCollaboratorGroupMembersPreview', id: number, uuid: string, firstName?: string | null | undefined, lastName?: string | null | undefined, email: string }> } | null | undefined }> | null | undefined, pageInfo?: { __typename?: 'Pageinfo', currentPage: number, itemsOnPage: number, itemsTotal: number, pageTotal: number, skip?: number | null | undefined, take?: number | null | undefined } | null | undefined } | null | undefined };

export type ProjectJobRoleFindManyQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProjectJobRoleFindManyQuery = { __typename?: 'Query', ProjectJobRoleFindMany: Array<{ __typename?: 'ProjectJobRoleModel', id: number, skillsProfileId: number, isLocalizedBenchmarkGroup?: boolean | null | undefined, jobRole: { __typename?: 'JobRoleModel', name: string, id: number, roleLevel: ContentRoleLevel, family?: JobRoleFamily | null | undefined, skillsProfiles: Array<{ __typename?: 'SuccessProfile', id: number, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, name: string }> | null | undefined }> } }> };

export type ProjectRequestUserFindManyQueryVariables = Exact<{
  approvalId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  projectRequestId?: InputMaybe<Scalars['Int']>;
  projectRequestIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  type?: InputMaybe<ProjectRequestUserType>;
  userId?: InputMaybe<Scalars['Int']>;
  userIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
  relationDepth?: InputMaybe<RelationDepth>;
}>;


export type ProjectRequestUserFindManyQuery = { __typename?: 'Query', ProjectRequestUserFindMany: Array<{ __typename?: 'ProjectRequestUserModel', id: number, projectRequestId?: number | null | undefined, type: ProjectRequestUserType, userId: number, approval?: { __typename?: 'ProjectRequestUserApprovalModel', approvalStatus: ProjectRequestUserApprovalStatus, createdAt: any, deletedAt?: any | null | undefined, id: number, updatedAt: any, approvalRequestDate: any } | null | undefined, projectRequest?: { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined } | null | undefined, user?: { __typename?: 'User', email: string, firstName: string, lastName: string, id: number } | null | undefined }> };

export type ProjectRequestFileUploadFindManyQueryVariables = Exact<{
  blobId?: InputMaybe<Scalars['Int']>;
  fileName?: InputMaybe<Scalars['String']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  projectRequestId?: InputMaybe<Scalars['Int']>;
  projectRequestIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
}>;


export type ProjectRequestFileUploadFindManyQuery = { __typename?: 'Query', ProjectRequestFileUploadFindMany: Array<{ __typename?: 'ProjectRequestFileUploadModel', blobId: number, fileName: string, id: number, projectRequestId?: number | null | undefined, blob?: { __typename?: 'Blob', id: string, key: string, acl: Acl, extension: string, bucket: string, publishedName: string, url: string, contentType?: string | null | undefined, contentEncoding?: string | null | undefined, ownerService: string, createdAt: string, updatedAt: string, deletedAt?: string | null | undefined } | null | undefined }> };

export type ProjectRequestFindManyQueryVariables = Exact<{
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  status?: InputMaybe<ProjectRequestStatus>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
  managerSurveyId?: InputMaybe<Scalars['String']>;
}>;


export type ProjectRequestFindManyQuery = { __typename?: 'Query', ProjectRequestFindMany?: Array<{ __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined }> | null | undefined };

export type ProjectRequestFindOneQueryVariables = Exact<{
  approvalRequestDate?: InputMaybe<Scalars['DateTime']>;
  companyId?: InputMaybe<Scalars['Int']>;
  creatorId?: InputMaybe<Scalars['Int']>;
  getOption?: InputMaybe<GetOption>;
  id?: InputMaybe<Scalars['Int']>;
  projectName?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
  status?: InputMaybe<ProjectRequestStatus>;
  withDeleted?: InputMaybe<WithDeletedChoice>;
  managerSurveyId?: InputMaybe<Scalars['String']>;
}>;


export type ProjectRequestFindOneQuery = { __typename?: 'Query', ProjectRequestFindOne?: { __typename?: 'ProjectRequestModel', approvalRequestDate?: any | null | undefined, companyId: number, createdAt: any, creatorId: number, deletedAt?: any | null | undefined, id: number, projectId?: number | null | undefined, projectName: string, status: ProjectRequestStatus, updatedAt: any, managerSurveyId?: string | null | undefined, creator?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined } | null | undefined };

export type ProjectUserFindManyQueryVariables = Exact<{
  userIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type ProjectUserFindManyQuery = { __typename?: 'Query', ProjectUserFindMany: Array<{ __typename?: 'ProjectUser', actorRole?: ActorRole | null | undefined, project: { __typename?: 'Project', id: number, name: string, createdAt: string, isArchived: boolean, isStopped: boolean, moduleType: ProjectModuleType, requireSheetsExport: boolean, updatedAt: string, version: number, productSolution?: ProductSolution | null | undefined, subId: string, company?: { __typename?: 'ProjectCompany', id: number, name: string } | null | undefined, managers?: Array<{ __typename?: 'ProjectManager', email?: string | null | undefined, firstName?: string | null | undefined, id: number, lastName?: string | null | undefined, userId?: number | null | undefined }> | null | undefined }, user: { __typename?: 'User', id: number, email: string } }> };

export type ReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportsQuery = { __typename?: 'Query', Reports?: Array<{ __typename?: 'Report', id: number, name: string, type: ReportType, subType?: ReportSubType | null | undefined, isArchived: boolean, ownerId: number, ownerType: ReportOwnerType, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, newPageBefore: boolean, position: number, widgetConfigs?: Array<{ __typename?: 'WidgetConfig', id: number, settings: string, widgetOption?: { __typename?: 'WidgetOption', question?: string | null | undefined, fieldType: WidgetOptionFieldType, widgetId: number, id: number, defaultValue?: string | null | undefined } | null | undefined }> | null | undefined, widget: { __typename?: 'Widget', id: number, name?: string | null | undefined, isArchived: boolean, updatedAt: any, type: WidgetType } }> | null | undefined }> | null | undefined };

export type ReportQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ReportQuery = { __typename?: 'Query', report?: { __typename?: 'Report', id: number, name: string, type: ReportType, subType?: ReportSubType | null | undefined, isArchived: boolean, ownerId: number, ownerType: ReportOwnerType, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, newPageBefore: boolean, position: number, widgetConfigs?: Array<{ __typename?: 'WidgetConfig', id: number, settings: string, widgetOption?: { __typename?: 'WidgetOption', question?: string | null | undefined, fieldType: WidgetOptionFieldType, widgetId: number, id: number, defaultValue?: string | null | undefined } | null | undefined }> | null | undefined, widget: { __typename?: 'Widget', id: number, name?: string | null | undefined, isArchived: boolean, updatedAt: any, type: WidgetType } }> | null | undefined } | null | undefined };

export type ReportRenderFindManyQueryVariables = Exact<{
  dataSourceId?: InputMaybe<Scalars['Int']>;
  subType?: InputMaybe<ReportSubType>;
  reportRenderType?: InputMaybe<ReportRenderType>;
  projectJobRoleId?: InputMaybe<Scalars['Int']>;
}>;


export type ReportRenderFindManyQuery = { __typename?: 'Query', report?: Array<{ __typename?: 'ReportRender', id: number, subId: string, reportRenderStatus?: ReportRenderStatus | null | undefined }> | null | undefined };

export type ReportAssignmentFindStageReportQueryVariables = Exact<{
  type: ReportType;
  subType: ReportSubType;
  stageId?: InputMaybe<Scalars['Int']>;
  projectId?: InputMaybe<Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
}>;


export type ReportAssignmentFindStageReportQuery = { __typename?: 'Query', reports?: { __typename?: 'ReportAssignment', assignedId: number, assignedType: RAssignedType, id: number, inheritsSetting?: boolean | null | undefined, reportId: number, report: { __typename?: 'Report', id: number, name: string } } | null | undefined };

export type ReportFindManyQueryVariables = Exact<{
  type?: InputMaybe<ReportType>;
}>;


export type ReportFindManyQuery = { __typename?: 'Query', report?: Array<{ __typename?: 'Report', id: number, name: string }> | null | undefined };

export type ReportsFindByCompanyQueryVariables = Exact<{
  type?: InputMaybe<ReportType>;
  subType?: InputMaybe<ReportSubType>;
  ownerId?: InputMaybe<Scalars['Int']>;
  includeSharedReports: Scalars['Boolean'];
}>;


export type ReportsFindByCompanyQuery = { __typename?: 'Query', ReportsFindByCompany?: Array<{ __typename?: 'Report', id: number, name: string, ownerId: number }> | null | undefined };

export type ReportRenderQueryVariables = Exact<{
  subId: Scalars['String'];
}>;


export type ReportRenderQuery = { __typename?: 'Query', ReportRender?: { __typename?: 'ReportRender', id: number, subId: string, reportRenderStatus?: ReportRenderStatus | null | undefined, statusMessage?: string | null | undefined, blob?: { __typename?: 'Blob', url: string } | null | undefined } | null | undefined };

export type FindOneRoiCsvFileExportScheduledTaskQueryVariables = Exact<{
  subId?: InputMaybe<Scalars['String']>;
}>;


export type FindOneRoiCsvFileExportScheduledTaskQuery = { __typename?: 'Query', FindOneROICsvFileExportScheduledTask: { __typename?: 'FileExportScheduledTask', error?: string | null | undefined, status: ScheduledTaskStatus, subId: string, blob?: { __typename?: 'Blob', id: string, url: string } | null | undefined } };

export type RolesQueryVariables = Exact<{ [key: string]: never; }>;


export type RolesQuery = { __typename?: 'Query', Roles: Array<{ __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }> };

export type RoleFindManyQueryVariables = Exact<{
  filterForIdentity?: InputMaybe<Scalars['Boolean']>;
}>;


export type RoleFindManyQuery = { __typename?: 'Query', RoleFindMany: Array<{ __typename?: 'Role', id: number, description?: string | null | undefined, name: string, type: RoleType }> };

export type RssAssessmentFindManyQueryVariables = Exact<{
  stageId?: InputMaybe<Scalars['Int']>;
}>;


export type RssAssessmentFindManyQuery = { __typename?: 'Query', RssAssessmentFindMany: Array<{ __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number }> };

export type ResultValidatedSkillsQueryVariables = Exact<{
  dateFrom?: InputMaybe<Scalars['Date']>;
  dateTo?: InputMaybe<Scalars['Date']>;
  companyIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  take: Scalars['Int'];
  skip: Scalars['Int'];
}>;


export type ResultValidatedSkillsQuery = { __typename?: 'Query', ResultValidatedSkills: { __typename?: 'ResultValidatedSkillsPaginatedModel', total: number, resultValidatedSkills: Array<{ __typename?: 'ResultValidatedSkillsModel', totalValidateSkills: number, company: { __typename?: 'Company', id: number, name: string, logoUrl?: string | null | undefined, allowedEmailDomains?: Array<string> | null | undefined, managerReportEmailLinks: boolean } }> } };

export type SoftSkillRoleLevelContentFindManyAndCountQueryVariables = Exact<{
  softSkillIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel> | ContentRoleLevel>;
  grades?: InputMaybe<Array<Grade> | Grade>;
  take: Scalars['Int'];
  page: Scalars['Int'];
  order?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SoftSkillRoleLevelContentFindManyAndCountQuery = { __typename?: 'Query', softSkillContent: { __typename?: 'SoftSkillRoleLevelContentPaginated', count?: number | null | undefined, softSkillContent?: Array<{ __typename?: 'SoftSkillRoleLevelContent', id: number, softSkillId?: number | null | undefined, grade: Grade, roleLevel: ContentRoleLevel, description?: string | null | undefined, candidateContent?: string | null | undefined, hiringManagerContent?: string | null | undefined, internalCandidateContent?: string | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined } };

export type SoftSkillSuccessProfileContentFindManyAndCountQueryVariables = Exact<{
  softSkillIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel> | ContentRoleLevel>;
  take: Scalars['Int'];
  page: Scalars['Int'];
  order?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type SoftSkillSuccessProfileContentFindManyAndCountQuery = { __typename?: 'Query', softSkillContent: { __typename?: 'SoftSkillSuccessProfileContentPaginated', count?: number | null | undefined, softSkillContent?: Array<{ __typename?: 'SoftSkillSuccessProfileContent', id: number, softSkillId: number, roleLevel: ContentRoleLevel, shortDescription?: string | null | undefined, description?: string | null | undefined, softSkill?: { __typename?: 'SoftSkill', name: string } | null | undefined }> | null | undefined } };

export type SoftSkillGetAllValidForProjectQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type SoftSkillGetAllValidForProjectQuery = { __typename?: 'Query', softSkills: Array<{ __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined }> };

export type SoftSkillsQueryVariables = Exact<{ [key: string]: never; }>;


export type SoftSkillsQuery = { __typename?: 'Query', SoftSkills?: Array<{ __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined }> | null | undefined };

export type SoftSkillFindManyQueryVariables = Exact<{
  isArchived?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  companyId?: InputMaybe<Scalars['Int']>;
}>;


export type SoftSkillFindManyQuery = { __typename?: 'Query', SoftSkillFindMany?: Array<{ __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined }> | null | undefined };

export type GetSzAndProvidedCompanySoftSkillsQueryVariables = Exact<{
  companyId?: InputMaybe<Scalars['Int']>;
}>;


export type GetSzAndProvidedCompanySoftSkillsQuery = { __typename?: 'Query', SZSoftSkills?: Array<{ __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined }> | null | undefined, CompanySoftSkills?: Array<{ __typename?: 'SoftSkill', id: number, name: string, companyId: number, category?: string | null | undefined, isArchived: boolean, measurementConstructType?: MeasurementConstructType | null | undefined, description?: string | null | undefined, company?: { __typename?: 'Company', id: number, name: string } | null | undefined, softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined, icon?: { __typename?: 'Blob', url: string } | null | undefined }> | null | undefined };

export type SoftSkillTraitsQueryVariables = Exact<{ [key: string]: never; }>;


export type SoftSkillTraitsQuery = { __typename?: 'Query', softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined };

export type FindSoftSkillTraitsBySsIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FindSoftSkillTraitsBySsIdQuery = { __typename?: 'Query', softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined };

export type FindManySoftSkillTraitsBySsIdQueryVariables = Exact<{
  ids: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type FindManySoftSkillTraitsBySsIdQuery = { __typename?: 'Query', softSkillTraits?: Array<{ __typename?: 'SoftSkillTrait', id?: number | null | undefined, orientation: number, weight: number, traitId?: number | null | undefined, traitName?: string | null | undefined, softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, trait?: { __typename?: 'Trait', id: number, name: string } | null | undefined, softSkill?: { __typename?: 'SoftSkill', id: number, name: string } | null | undefined }> | null | undefined };

export type GetProjectSamlAuthInfoQueryVariables = Exact<{
  projectId: Scalars['Float'];
}>;


export type GetProjectSamlAuthInfoQuery = { __typename?: 'Query', getProjectSamlAuthInfo: string };

export type GenerateStageIndirectInvitationUrlQueryVariables = Exact<{
  id: Scalars['Int'];
  locale?: InputMaybe<SupportedLocale>;
}>;


export type GenerateStageIndirectInvitationUrlQuery = { __typename?: 'Query', GenerateStageIndirectInvitationUrl: { __typename?: 'GenerateStageIndirectInvitationUrlModel', url: string } };

export type TmTestFindManyQueryVariables = Exact<{
  stageId: Scalars['Int'];
}>;


export type TmTestFindManyQuery = { __typename?: 'Query', tests: Array<{ __typename?: 'TmTestModel', id: number, stageId: number, nQuestionsPerTrait?: number | null | undefined, origin: TestOrigin, tpProjectId?: string | null | undefined, subType?: TestSubType | null | undefined, type: TestType, createdAt: any, subId: string, updatedAt: any }> };

export type StageFindManyQueryVariables = Exact<{
  emailCandidateReport?: InputMaybe<Scalars['Boolean']>;
  emailManagerReport?: InputMaybe<Scalars['Boolean']>;
  emailSzAdmin?: InputMaybe<Scalars['Boolean']>;
  hasCalibration?: InputMaybe<Scalars['Boolean']>;
  createInterviewGuide?: InputMaybe<Scalars['Boolean']>;
  endTime?: InputMaybe<Scalars['Date']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  projectId?: InputMaybe<Scalars['Int']>;
  projectIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  renderCandidateReport?: InputMaybe<Scalars['Boolean']>;
  startTime?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<StageStatus>;
  subId?: InputMaybe<Scalars['String']>;
  testIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  type?: InputMaybe<StageType>;
}>;


export type StageFindManyQuery = { __typename?: 'Query', Stages?: Array<{ __typename?: 'SmStageModel', id: number, name: string, type: StageType, order: number, status: StageStatus, startTime?: any | null | undefined, endTime?: any | null | undefined, projectId: number, managerReportEmailLinks: boolean, renderCandidateReport: boolean, emailCandidateReport: boolean, emailManagerReport: boolean, emailProjectTeam: boolean, emailProjectTeamRoles: Array<ActorRole>, hasCalibration: boolean, createInterviewGuide: boolean, emailSzAdmin: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, enableF2fInterviews: boolean, deiStageAction?: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } | null | undefined, availableActions: Array<{ __typename?: 'StageAvailableActionModel', availability: StageActionAvailability, name: string, subType?: StageActionSubType | null | undefined, type: StageActionType }>, rssAssessments: Array<{ __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number }> }> | null | undefined };

export type ResultsPageStageFindManyQueryVariables = Exact<{
  projectIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ResultsPageStageFindManyQuery = { __typename?: 'Query', Stages?: Array<{ __typename?: 'SmStageModel', projectId: number, name: string, id: number }> | null | undefined };

export type StageQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StageQuery = { __typename?: 'Query', Stage?: { __typename?: 'SmStageModel', id: number, name: string, type: StageType, order: number, status: StageStatus, startTime?: any | null | undefined, endTime?: any | null | undefined, projectId: number, managerReportEmailLinks: boolean, renderCandidateReport: boolean, emailCandidateReport: boolean, emailManagerReport: boolean, emailProjectTeam: boolean, emailProjectTeamRoles: Array<ActorRole>, hasCalibration: boolean, createInterviewGuide: boolean, emailSzAdmin: boolean, createdAt: any, updatedAt: any, deletedAt?: any | null | undefined, enableF2fInterviews: boolean, deiStageAction?: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } | null | undefined, availableActions: Array<{ __typename?: 'StageAvailableActionModel', availability: StageActionAvailability, name: string, subType?: StageActionSubType | null | undefined, type: StageActionType }>, rssAssessments: Array<{ __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number }> } | null | undefined };

export type DeiStageActionQueryVariables = Exact<{
  stageId: Scalars['Int'];
}>;


export type DeiStageActionQuery = { __typename?: 'Query', DeiStageAction?: { __typename?: 'DeiStageActionModel', completedCount: number, submittedCount: number, createdAt: any, deletedAt?: any | null | undefined, stageId: number, updatedAt: any, fields: { __typename?: 'DeiStageActionFieldsModel', hasAgeRangeField: boolean, hasDisabilityField: boolean, hasEthnicityField: boolean, hasGenderIdentityField: boolean, hasNeurodiversityField: boolean, hasReligiousBeliefsField: boolean, hasSexualOrientationField: boolean } } | null | undefined };

export type StageCandidateFindManyQueryVariables = Exact<{
  candidateId?: InputMaybe<Scalars['Int']>;
  candidateIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  stageId?: InputMaybe<Scalars['Int']>;
  stageResultId?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<StageCandidateStatus>;
  subId?: InputMaybe<Scalars['String']>;
  relationDepth?: InputMaybe<RelationDepth>;
}>;


export type StageCandidateFindManyQuery = { __typename?: 'Query', StageCandidates?: Array<{ __typename?: 'SmStageCandidateModel', id: number, subId: string, companyId?: number | null | undefined, doneFor: number, doneBy: number, lastDateOfCandidateAction?: any | null | undefined, status: StageCandidateStatus, deiStageActionCandidate?: { __typename?: 'DeiStageActionCandidateModel', createdAt: any, status: DeiStageActionCandidateStatus, updatedAt: any } | null | undefined, stage: { __typename?: 'SmStageModel', id: number, name: string, projectId: number }, stageResult?: { __typename?: 'SmStageResultModel', scores: Array<{ __typename?: 'BasicScoreModel', id: number, score: { __typename?: 'ScoreModel', zScore?: number | null | undefined, percentile?: number | null | undefined, stenScore?: number | null | undefined, grade?: Grade | null | undefined } }>, totalScore: { __typename?: 'BasicScoreModel', id: number, score: { __typename?: 'ScoreModel', zScore?: number | null | undefined, percentile?: number | null | undefined, stenScore?: number | null | undefined, grade?: Grade | null | undefined } } } | null | undefined, rssAssessmentCandidates?: Array<{ __typename?: 'RssAssessmentCandidateModel', id: number, status: RssAssessmentCandidateStatus, rssAssessment: { __typename?: 'RssAssessmentModel', id: number, type: RssAssessmentType, stageId: number } }> | null | undefined, formCandidates?: Array<{ __typename?: 'FormCandidateModel', createdAt: any, deletedAt?: any | null | undefined, formId: number, id: number, stageCandidateId: number, status: FormCandidateStatus, subId: string, updatedAt: any, form: { __typename?: 'Form', formManagerType: FormManagerType, formOwnerName: string, formType: FormType, id: number, name: string } } | null | undefined> | null | undefined }> | null | undefined };

export type TestCandidateFindManyByCandidateQueryVariables = Exact<{
  candidateId: Scalars['Int'];
}>;


export type TestCandidateFindManyByCandidateQuery = { __typename?: 'Query', testResult?: Array<{ __typename?: 'TmTestCandidateModel', id: number, assessmentId?: number | null | undefined, status: TestStatus, testId: number, doneBy: number, doneFor: number, timeModifierPercentage: number, test?: { __typename?: 'TmTestModel', tpProjectId?: string | null | undefined, type: TestType, stageId: number, subType?: TestSubType | null | undefined } | null | undefined, testResult?: { __typename?: 'TmTestResultModel', updatedAt: any, testCandidateId: number, scores: Array<{ __typename?: 'BasicScoreModel', id: number, score: { __typename?: 'ScoreModel', zScore?: number | null | undefined, percentile?: number | null | undefined, stenScore?: number | null | undefined, grade?: Grade | null | undefined } }> } | null | undefined }> | null | undefined };

export type SuccessProfileQueryVariables = Exact<{
  projectId: Scalars['Int'];
}>;


export type SuccessProfileQuery = { __typename?: 'Query', SuccessProfile?: { __typename?: 'SuccessProfile', calculationMethod: CalculationMethod, createdAt: any, deletedAt?: any | null | undefined, gradingMethod: GradingMethod, id: number, jobRoleId: number, roleLevel: ContentRoleLevel, status: SuccessProfileStatus, updatedAt: any, successProfileTechnicalSkills?: Array<{ __typename?: 'SuccessProfileTechnicalSkill', id: number, successProfileId: number, technicalSkillId: number, technicalSkill?: { __typename?: 'TechnicalSkill', id: number, name: string } | null | undefined }> | null | undefined, successProfileSoftSkills?: Array<{ __typename?: 'SuccessProfileSoftSkill', id: number, softSkillId: number, type: SoftSkillType, name: string, weight: number, includeInCalibration: boolean, successProfileSoftSkillTraits?: Array<{ __typename?: 'SuccessProfileSoftSkillTrait', id: number, orientation?: number | null | undefined, traitId: number, weight: number, name?: string | null | undefined }> | null | undefined, successProfileSoftSkillDataSources?: Array<{ __typename?: 'SuccessProfileSoftSkillDataSource', id: number, dataSourceType: DataSourceType, softSkillType: SoftSkillType }> | null | undefined }> | null | undefined } | null | undefined };

export type TechnicalSkillSearchQueryVariables = Exact<{
  searchText?: InputMaybe<Scalars['String']>;
}>;


export type TechnicalSkillSearchQuery = { __typename?: 'Query', TechnicalSkillFreeTextSearchPaginated: { __typename?: 'PaginatedTechnicalSkill', total: number, technicalSkills: Array<{ __typename?: 'TechnicalSkill', createdAt: any, deletedAt?: any | null | undefined, iconBlobId?: number | null | undefined, id: number, name: string, skillCategoryId?: number | null | undefined, updatedAt: any }> } };

export type TechnicalSkillFindManyQueryVariables = Exact<{ [key: string]: never; }>;


export type TechnicalSkillFindManyQuery = { __typename?: 'Query', TechnicalSkillFindMany: Array<{ __typename?: 'TechnicalSkill', createdAt: any, deletedAt?: any | null | undefined, iconBlobId?: number | null | undefined, id: number, name: string, skillCategoryId?: number | null | undefined, updatedAt: any }> };

export type TraitsQueryVariables = Exact<{
  isArchived?: InputMaybe<Scalars['Boolean']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type TraitsQuery = { __typename?: 'Query', Traits?: Array<{ __typename?: 'Trait', id: number, name: string, description: string, isArchived: boolean, testTypes: Array<{ __typename?: 'TraitTestTypeModel', id: number, testSubType?: TestSubType | null | undefined, testType: TestType, traitId: number }> }> | null | undefined };

export type TraitRoleLevelContentFindManyAndCountQueryVariables = Exact<{
  softSkillIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  roleLevels?: InputMaybe<Array<ContentRoleLevel> | ContentRoleLevel>;
  classifications?: InputMaybe<Array<ContentClassification> | ContentClassification>;
  take: Scalars['Int'];
  page: Scalars['Int'];
  order?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type TraitRoleLevelContentFindManyAndCountQuery = { __typename?: 'Query', traitContent: { __typename?: 'TraitRoleLevelContentPaginatedModel', count?: number | null | undefined, traitContent?: Array<{ __typename?: 'TraitRoleLevelContent', id?: number | null | undefined, softSkillId?: number | null | undefined, traitId?: number | null | undefined, classification: ContentClassification, roleLevel: ContentRoleLevel, summary?: string | null | undefined, content?: string | null | undefined, developmentContent?: string | null | undefined, softSkill?: { __typename?: 'SoftSkill', name: string } | null | undefined, trait?: { __typename?: 'Trait', name: string } | null | undefined }> | null | undefined } };

export type UserQueryVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  subId?: InputMaybe<Scalars['String']>;
}>;


export type UserQuery = { __typename?: 'Query', User?: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } | null | undefined };

export type UsersQueryVariables = Exact<{
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  subIds?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type UsersQuery = { __typename?: 'Query', Users?: Array<{ __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> }> | null | undefined };

export type UserFreeTextSearchPaginateQueryVariables = Exact<{
  freetext: Scalars['String'];
  take?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type UserFreeTextSearchPaginateQuery = { __typename?: 'Query', Users?: { __typename?: 'UserModelPaginated', data?: Array<{ __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string }> | null | undefined, pageInfo?: { __typename?: 'Pageinfo', currentPage: number, itemsOnPage: number, itemsTotal: number, pageTotal: number, skip?: number | null | undefined, take?: number | null | undefined } | null | undefined } | null | undefined };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, subId: string, firstName: string, lastName: string, email: string, avatarUrl?: string | null | undefined, externalId?: string | null | undefined, twoFactorEnabled: boolean, language: Language, roles: Array<{ __typename?: 'UserRole', id: number, userId?: number | null | undefined, externalId?: string | null | undefined, companyRole: { __typename?: 'CompanyRole', companyId: number, twoFactorEnabled: boolean, id: number, role: { __typename?: 'Role', id: number, name: string, description?: string | null | undefined, type: RoleType }, samlAuthInfos?: Array<{ __typename?: 'SamlAuthInfoModel', subId: string }> | null | undefined } }> } };

export type ValidateContentQueryVariables = Exact<{
  softSkillIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  traitIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  take: Scalars['Int'];
  page: Scalars['Int'];
}>;


export type ValidateContentQuery = { __typename?: 'Query', validateContent?: { __typename?: 'ContentValidator', count?: number | null | undefined, content: Array<{ __typename?: 'ContentValidatiorPieceModel', softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, traitId?: number | null | undefined, traitName?: string | null | undefined, numOfSsContent: string, numOfSsSpContent: string, numOfTraitContent: string, numOfIqContent: string }> } | null | undefined };

export type ValidateContentPieceQueryVariables = Exact<{
  softSkillId: Scalars['Int'];
  traitIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type ValidateContentPieceQuery = { __typename?: 'Query', validateContent?: { __typename?: 'ContentValidatiorPieceModel', softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, traitId?: number | null | undefined, traitName?: string | null | undefined, numOfSsContent: string, numOfSsSpContent: string, numOfTraitContent: string, numOfIqContent: string } | null | undefined };

export type FixContentPieceQueryVariables = Exact<{
  softSkillId: Scalars['Int'];
  traitIds?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type FixContentPieceQuery = { __typename?: 'Query', validateContent?: { __typename?: 'ContentValidatiorPieceModel', softSkillId?: number | null | undefined, softSkillName?: string | null | undefined, traitId?: number | null | undefined, traitName?: string | null | undefined, numOfSsContent: string, numOfSsSpContent: string, numOfTraitContent: string, numOfIqContent: string } | null | undefined };

export type WidgetsQueryVariables = Exact<{ [key: string]: never; }>;


export type WidgetsQuery = { __typename?: 'Query', Widgets?: Array<{ __typename?: 'Widget', id: number, name?: string | null | undefined, type: WidgetType, screenShotBlobId?: number | null | undefined, isArchived: boolean, updatedAt: any, screenShotBlob?: { __typename?: 'Blob', id: string, url: string } | null | undefined, widgetOptions?: Array<{ __typename?: 'WidgetOption', id: number, question?: string | null | undefined, fieldType: WidgetOptionFieldType, defaultValue?: string | null | undefined, isArchived: boolean }> | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, reportId: number, newPageBefore: boolean }> | null | undefined }> | null | undefined };

export type WidgetQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type WidgetQuery = { __typename?: 'Query', Widget?: { __typename?: 'Widget', id: number, name?: string | null | undefined, type: WidgetType, screenShotBlobId?: number | null | undefined, isArchived: boolean, screenShotBlob?: { __typename?: 'Blob', url: string, id: string } | null | undefined, widgetOptions?: Array<{ __typename?: 'WidgetOption', id: number, name: string, fieldType: WidgetOptionFieldType, question?: string | null | undefined, defaultValue?: string | null | undefined, isArchived: boolean }> | null | undefined, reportConfigs?: Array<{ __typename?: 'ReportConfig', id: number, reportId: number, newPageBefore: boolean, report: { __typename?: 'Report', id: number, name: string } }> | null | undefined } | null | undefined };

export type GetWidgetTypeEnumQueryVariables = Exact<{ [key: string]: never; }>;


export type GetWidgetTypeEnumQuery = { __typename?: 'Query', __type?: { __typename?: '__Type', enumValues?: Array<{ __typename?: '__EnumValue', name: string, description?: string | null | undefined }> | null | undefined } | null | undefined };

export const IgStepFragmentDoc = gql`
    fragment IgStep on IgStep {
  id
  name
  internalName
  action
  durationInSeconds
  colour
  questionType
  order
  isArchived
  staticQuestionName
}
    `;
export const TechnicalSkillFragmentDoc = gql`
    fragment TechnicalSkill on TechnicalSkill {
  createdAt
  deletedAt
  iconBlobId
  id
  name
  skillCategoryId
  updatedAt
}
    `;
export const ApiKeyUsageRecordFragmentDoc = gql`
    fragment ApiKeyUsageRecord on ApiKeyUsageRecordModel {
  route
  count
}
    `;
export const ApiKeyFragmentDoc = gql`
    fragment ApiKey on AmApiKeyModel {
  id
  key
  ownerId
  ownerType
  puppetUserId
  usageRecord {
    ...ApiKeyUsageRecord
  }
  createdAt
  updatedAt
  deletedAt
  role
}
    ${ApiKeyUsageRecordFragmentDoc}`;
export const BlobFragmentDoc = gql`
    fragment Blob on Blob {
  id
  key
  acl
  extension
  bucket
  publishedName
  url
  contentType
  contentEncoding
  ownerService
  createdAt
  updatedAt
  deletedAt
}
    `;
export const BlobUrlFragmentDoc = gql`
    fragment BlobUrl on Blob {
  id
  url
}
    `;
export const CompanySignatureModelFragmentDoc = gql`
    fragment CompanySignatureModel on CompanySignatureModel {
  id
  companyId
  isDefault
  signature
  signatureEmail
}
    `;
export const CompanyEmployeeFragmentDoc = gql`
    fragment CompanyEmployee on CompanyEmployeeModel {
  companyId
  createdAt
  customFields
  deletedAt
  department
  email
  employeeId
  endDate
  firstName
  hrManagerEmployeeId
  id
  isArchived
  jobTitle
  lastName
  startDate
  szUserId
  updatedAt
}
    `;
export const CompanyFragmentDoc = gql`
    fragment Company on Company {
  id
  name
  logoUrl
  allowedEmailDomains
  managerReportEmailLinks
}
    `;
export const ProjectWithStageCandidatesFragmentDoc = gql`
    fragment ProjectWithStageCandidates on ProjectWithStageCandidates {
  id
  name
  numberOfStageCandidates
  percentageOfCompletedStageCandidates
  numberOfCompletedStageCandidates
  numberOfCompletedInTimeFrameStageCandidates
}
    `;
export const CompanyProjectsFragmentDoc = gql`
    fragment CompanyProjects on CompanyProjects {
  id
  company {
    ...Company
  }
  numberOfStageCandidates
  percentageOfCompletedStageCandidates
  numberOfCompletedInTimeFrameStageCandidates
  numberOfCompletedStageCandidates
  projects {
    ...ProjectWithStageCandidates
  }
}
    ${CompanyFragmentDoc}
${ProjectWithStageCandidatesFragmentDoc}`;
export const CompanyUsersCountFragmentDoc = gql`
    fragment CompanyUsersCount on CompanyUsersCount {
  companyId
  totalUsers
  totalUsersWithPassword
}
    `;
export const CompanyUsersWithRolesCountFragmentDoc = gql`
    fragment CompanyUsersWithRolesCount on CompanyUsersWithRolesCount {
  companyId
  totalUsersWithPassword
  totalUsers
  roleName
}
    `;
export const CompanyUserInfoFragmentDoc = gql`
    fragment CompanyUserInfo on CompanyUserInfo {
  company {
    ...Company
  }
  companyUserAcrossAllRoles {
    ...CompanyUsersCount
  }
  companyUserAcrossEachRole {
    ...CompanyUsersWithRolesCount
  }
}
    ${CompanyFragmentDoc}
${CompanyUsersCountFragmentDoc}
${CompanyUsersWithRolesCountFragmentDoc}`;
export const CompanyUserInfoPaginatedFragmentDoc = gql`
    fragment CompanyUserInfoPaginated on CompanyUserInfoPaginated {
  companyUserInfo {
    ...CompanyUserInfo
  }
  total
}
    ${CompanyUserInfoFragmentDoc}`;
export const ExternalAssessmentFragmentDoc = gql`
    fragment ExternalAssessment on ExternalAssessmentModel {
  createdAt
  deletedAt
  eaCompanyPlatformId
  estimatedDurationInMinutes
  externalPlatformType
  id
  name
  subId
  updatedAt
  timeLimitInMinutes
  eaSkills {
    id
    subId
    technicalSkillId
  }
  eaPlatformAssessments {
    eaPlatformId
    id
    platformUrl
    modifier
    subId
  }
}
    `;
export const IgInterviewGuideFragmentDoc = gql`
    fragment IgInterviewGuide on IgInterviewGuide {
  id
  name
  ownerId
  updatedAt
  isArchived
  createdAt
}
    `;
export const IgCategoryFragmentDoc = gql`
    fragment IgCategory on IgCategory {
  id
  name
  internalName
  isArchived
  updatedAt
}
    `;
export const IgInterviewGuideCategoryFragmentDoc = gql`
    fragment IgInterviewGuideCategory on IgInterviewGuideCategory {
  id
  order
  colour
  interviewGuide {
    ...IgInterviewGuide
  }
  category {
    ...IgCategory
  }
}
    ${IgInterviewGuideFragmentDoc}
${IgCategoryFragmentDoc}`;
export const SuccessProfileTechnicalSkillFragmentDoc = gql`
    fragment SuccessProfileTechnicalSkill on SuccessProfileTechnicalSkill {
  id
  successProfileId
  technicalSkill {
    id
    name
  }
  technicalSkillId
}
    `;
export const SuccessProfileSoftSkillTraitFragmentDoc = gql`
    fragment SuccessProfileSoftSkillTrait on SuccessProfileSoftSkillTrait {
  id
  orientation
  traitId
  weight
  name
}
    `;
export const SuccessProfileSoftSkillFragmentDoc = gql`
    fragment SuccessProfileSoftSkill on SuccessProfileSoftSkill {
  id
  softSkillId
  type
  name
  weight
  includeInCalibration
  successProfileSoftSkillTraits {
    ...SuccessProfileSoftSkillTrait
  }
  successProfileSoftSkillDataSources {
    id
    dataSourceType
    softSkillType
  }
}
    ${SuccessProfileSoftSkillTraitFragmentDoc}`;
export const SuccessProfileFragmentDoc = gql`
    fragment SuccessProfile on SuccessProfile {
  calculationMethod
  createdAt
  deletedAt
  gradingMethod
  id
  jobRoleId
  roleLevel
  status
  successProfileTechnicalSkills {
    ...SuccessProfileTechnicalSkill
  }
  successProfileSoftSkills {
    ...SuccessProfileSoftSkill
  }
  updatedAt
}
    ${SuccessProfileTechnicalSkillFragmentDoc}
${SuccessProfileSoftSkillFragmentDoc}`;
export const JobRoleFragmentDoc = gql`
    fragment JobRole on JobRoleModel {
  companyId
  createdAt
  deletedAt
  id
  name
  qualifier
  roleLevel
  skillsProfiles {
    ...SuccessProfile
  }
  updatedAt
  family
}
    ${SuccessProfileFragmentDoc}`;
export const JobRoleOverviewFragmentDoc = gql`
    fragment JobRoleOverview on JobRoleModel {
  companyId
  createdAt
  deletedAt
  id
  name
  qualifier
  roleLevel
  updatedAt
  skillsProfiles {
    id
    createdAt
  }
}
    `;
export const PageInfoFragmentDoc = gql`
    fragment PageInfo on Pageinfo {
  itemsTotal
  currentPage
  itemsOnPage
  pageTotal
  skip
  take
}
    `;
export const ProjectCompanyFragmentDoc = gql`
    fragment ProjectCompany on ProjectCompany {
  id
  name
  logoUrl
  managerReportEmailLinks
}
    `;
export const ProjectManagerFragmentDoc = gql`
    fragment ProjectManager on ProjectManager {
  email
  firstName
  id
  lastName
  userId
}
    `;
export const ProjectFragmentDoc = gql`
    fragment Project on Project {
  company {
    ...ProjectCompany
  }
  companySignatureId
  createdAt
  creatorId
  id
  isArchived
  isStopped
  locales
  managers {
    ...ProjectManager
  }
  moduleType
  name
  requireSheetsExport
  updatedAt
  version
  productSolution
  subId
  indirectInviteSSOEnabled
}
    ${ProjectCompanyFragmentDoc}
${ProjectManagerFragmentDoc}`;
export const SuccessProfileTechnicalSkillNameFragmentDoc = gql`
    fragment SuccessProfileTechnicalSkillName on SuccessProfileTechnicalSkill {
  id
  technicalSkill {
    id
    name
  }
}
    `;
export const SuccessProfileSoftSkillNameFragmentDoc = gql`
    fragment SuccessProfileSoftSkillName on SuccessProfileSoftSkill {
  id
  softSkillId
  name
}
    `;
export const SuccessProfileSkillNamesFragmentDoc = gql`
    fragment SuccessProfileSkillNames on SuccessProfile {
  id
  successProfileTechnicalSkills {
    ...SuccessProfileTechnicalSkillName
  }
  successProfileSoftSkills {
    ...SuccessProfileSoftSkillName
  }
}
    ${SuccessProfileTechnicalSkillNameFragmentDoc}
${SuccessProfileSoftSkillNameFragmentDoc}`;
export const JobRoleNameFragmentDoc = gql`
    fragment JobRoleName on JobRoleModel {
  name
  id
  skillsProfiles {
    ...SuccessProfileSkillNames
  }
  roleLevel
  family
}
    ${SuccessProfileSkillNamesFragmentDoc}`;
export const ProjectJobRoleFragmentDoc = gql`
    fragment ProjectJobRole on ProjectJobRoleModel {
  id
  jobRole {
    ...JobRoleName
  }
  skillsProfileId
  isLocalizedBenchmarkGroup
}
    ${JobRoleNameFragmentDoc}`;
export const ProjectRequestUserApprovalFragmentDoc = gql`
    fragment ProjectRequestUserApproval on ProjectRequestUserApprovalModel {
  approvalStatus
  createdAt
  deletedAt
  id
  updatedAt
  approvalRequestDate
}
    `;
export const RoleFragmentDoc = gql`
    fragment Role on Role {
  id
  name
  description
  type
}
    `;
export const CompanyRoleFragmentDoc = gql`
    fragment CompanyRole on CompanyRole {
  companyId
  twoFactorEnabled
  role {
    ...Role
  }
  id
  samlAuthInfos {
    subId
  }
}
    ${RoleFragmentDoc}`;
export const UserRoleFragmentDoc = gql`
    fragment UserRole on UserRole {
  id
  userId
  companyRole {
    ...CompanyRole
  }
  externalId
}
    ${CompanyRoleFragmentDoc}`;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  subId
  firstName
  lastName
  email
  avatarUrl
  externalId
  twoFactorEnabled
  language
  roles {
    ...UserRole
  }
}
    ${UserRoleFragmentDoc}`;
export const ProjectRequestFragmentDoc = gql`
    fragment ProjectRequest on ProjectRequestModel {
  approvalRequestDate
  companyId
  createdAt
  creatorId
  deletedAt
  id
  projectId
  projectName
  status
  projectId
  updatedAt
  managerSurveyId
  creator {
    ...User
  }
}
    ${UserFragmentDoc}`;
export const ProjectRequestUserModelFragmentDoc = gql`
    fragment ProjectRequestUserModel on ProjectRequestUserModel {
  approval {
    ...ProjectRequestUserApproval
  }
  id
  projectRequest {
    ...ProjectRequest
  }
  projectRequestId
  type
  user {
    email
    firstName
    lastName
    id
  }
  userId
}
    ${ProjectRequestUserApprovalFragmentDoc}
${ProjectRequestFragmentDoc}`;
export const ReportAssignmentFragmentDoc = gql`
    fragment ReportAssignment on ReportAssignment {
  assignedId
  assignedType
  id
  inheritsSetting
  reportId
  report {
    id
    name
  }
}
    `;
export const ReportFragmentDoc = gql`
    fragment Report on Report {
  id
  name
  type
  subType
  isArchived
  ownerId
  ownerType
  createdAt
  updatedAt
  deletedAt
  company {
    id
    name
  }
  reportConfigs {
    id
    newPageBefore
    position
    widgetConfigs {
      id
      settings
      widgetOption {
        question
        fieldType
        widgetId
        id
        defaultValue
      }
    }
    widget {
      id
      name
      isArchived
      updatedAt
      type
    }
  }
}
    `;
export const ReportListItemFragmentDoc = gql`
    fragment ReportListItem on Report {
  id
  name
  type
  subType
  isArchived
  ownerId
  company {
    id
    name
  }
  ownerType
  createdAt
  updatedAt
  deletedAt
}
    `;
export const ResultValidatedSkillsModelFragmentDoc = gql`
    fragment ResultValidatedSkillsModel on ResultValidatedSkillsModel {
  company {
    ...Company
  }
  totalValidateSkills
}
    ${CompanyFragmentDoc}`;
export const ResultValidatedSkillsPaginatedModelFragmentDoc = gql`
    fragment ResultValidatedSkillsPaginatedModel on ResultValidatedSkillsPaginatedModel {
  resultValidatedSkills {
    ...ResultValidatedSkillsModel
  }
  total
}
    ${ResultValidatedSkillsModelFragmentDoc}`;
export const SoftSkillTraitFragmentDoc = gql`
    fragment SoftSkillTrait on SoftSkillTrait {
  id
  orientation
  weight
  traitId
  traitName
  trait {
    id
    name
  }
  softSkillId
  softSkillName
  softSkill {
    id
    name
  }
}
    `;
export const SoftSkillFragmentDoc = gql`
    fragment SoftSkill on SoftSkill {
  id
  name
  companyId
  category
  isArchived
  measurementConstructType
  description
  company {
    id
    name
  }
  softSkillTraits {
    ...SoftSkillTrait
  }
  icon {
    url
  }
}
    ${SoftSkillTraitFragmentDoc}`;
export const DeiFieldsFragmentDoc = gql`
    fragment DeiFields on DeiStageActionFieldsModel {
  hasAgeRangeField
  hasDisabilityField
  hasEthnicityField
  hasGenderIdentityField
  hasNeurodiversityField
  hasReligiousBeliefsField
  hasSexualOrientationField
}
    `;
export const DeiStageActionFragmentDoc = gql`
    fragment DeiStageAction on DeiStageActionModel {
  completedCount
  submittedCount
  createdAt
  deletedAt
  fields {
    ...DeiFields
  }
  stageId
  updatedAt
}
    ${DeiFieldsFragmentDoc}`;
export const StageAvailableActionFragmentDoc = gql`
    fragment StageAvailableAction on StageAvailableActionModel {
  availability
  name
  subType
  type
}
    `;
export const RssAssessmentFragmentDoc = gql`
    fragment RssAssessment on RssAssessmentModel {
  id
  type
  stageId
}
    `;
export const SmStageModelFragmentDoc = gql`
    fragment SmStageModel on SmStageModel {
  id
  name
  type
  order
  status
  startTime
  endTime
  projectId
  managerReportEmailLinks
  renderCandidateReport
  emailCandidateReport
  emailManagerReport
  emailProjectTeam
  emailProjectTeamRoles
  hasCalibration
  createInterviewGuide
  emailSzAdmin
  createdAt
  updatedAt
  deletedAt
  enableF2fInterviews
  deiStageAction {
    ...DeiStageAction
  }
  availableActions {
    ...StageAvailableAction
  }
  rssAssessments {
    ...RssAssessment
  }
}
    ${DeiStageActionFragmentDoc}
${StageAvailableActionFragmentDoc}
${RssAssessmentFragmentDoc}`;
export const DeiStageActionCandidateFragmentDoc = gql`
    fragment DeiStageActionCandidate on DeiStageActionCandidateModel {
  createdAt
  status
  updatedAt
}
    `;
export const RssAssessmentCandidateFragmentDoc = gql`
    fragment RssAssessmentCandidate on RssAssessmentCandidateModel {
  id
  status
  rssAssessment {
    ...RssAssessment
  }
}
    ${RssAssessmentFragmentDoc}`;
export const FormFragmentDoc = gql`
    fragment Form on Form {
  formManagerType
  formOwnerName
  formType
  id
  name
}
    `;
export const FormCandidateModelFragmentDoc = gql`
    fragment FormCandidateModel on FormCandidateModel {
  createdAt
  deletedAt
  formId
  id
  stageCandidateId
  status
  subId
  updatedAt
  form {
    ...Form
  }
}
    ${FormFragmentDoc}`;
export const StageCandidateModelFragmentDoc = gql`
    fragment StageCandidateModel on SmStageCandidateModel {
  id
  subId
  companyId
  doneFor
  doneBy
  lastDateOfCandidateAction
  deiStageActionCandidate {
    ...DeiStageActionCandidate
  }
  stage {
    id
    name
    projectId
  }
  status
  stageResult {
    scores {
      id
      score {
        zScore
        percentile
        stenScore
        grade
      }
    }
    totalScore {
      id
      score {
        zScore
        percentile
        stenScore
        grade
      }
    }
  }
  rssAssessmentCandidates {
    ...RssAssessmentCandidate
  }
  formCandidates {
    ...FormCandidateModel
  }
}
    ${DeiStageActionCandidateFragmentDoc}
${RssAssessmentCandidateFragmentDoc}
${FormCandidateModelFragmentDoc}`;
export const TmTestModelFragmentDoc = gql`
    fragment TmTestModel on TmTestModel {
  id
  subId
  type
  nQuestionsPerTrait
  origin
  tpProjectId
  createdAt
  updatedAt
  deletedAt
  stageId
}
    `;
export const TmTestCandidateModelFragmentDoc = gql`
    fragment TmTestCandidateModel on TmTestCandidateModel {
  id
  assessmentId
  status
  testId
  doneBy
  doneFor
  timeModifierPercentage
  test {
    tpProjectId
    type
    stageId
    subType
  }
  testResult {
    updatedAt
    testCandidateId
    scores {
      id
      score {
        zScore
        percentile
        stenScore
        grade
      }
    }
  }
}
    `;
export const TraitTestTypeFragmentDoc = gql`
    fragment TraitTestType on TraitTestTypeModel {
  id
  testSubType
  testType
  traitId
}
    `;
export const TraitFragmentDoc = gql`
    fragment Trait on Trait {
  id
  name
  description
  isArchived
  testTypes {
    ...TraitTestType
  }
}
    ${TraitTestTypeFragmentDoc}`;
export const TraitSoftSkillContextFileUploadRowFragmentDoc = gql`
    fragment TraitSoftSkillContextFileUploadRow on TraitSoftSkillContextFileUploadLineModel {
  internalName
  level
  negativeSummary
  positiveSummary
  softSkillId
  traitId
}
    `;
export const ContentValidatiorPieceModelFragmentDoc = gql`
    fragment ContentValidatiorPieceModel on ContentValidatiorPieceModel {
  softSkillId
  softSkillName
  traitId
  traitName
  numOfSsContent
  numOfSsSpContent
  numOfTraitContent
  numOfIqContent
}
    `;
export const WidgetFragmentDoc = gql`
    fragment Widget on Widget {
  id
  name
  type
  screenShotBlob {
    id
    url
  }
  screenShotBlobId
  isArchived
  widgetOptions {
    id
    question
    fieldType
    defaultValue
    isArchived
  }
  reportConfigs {
    id
    reportId
    newPageBefore
  }
  updatedAt
}
    `;
export const TechnicalSkillCreateOneDocument = gql`
    mutation TechnicalSkillCreateOne($name: String!, $iconBlobId: Int, $skillCategoryId: Int) {
  TechnicalSkillCreateOne(
    name: $name
    iconBlobId: $iconBlobId
    skillCategoryId: $skillCategoryId
  ) {
    ...TechnicalSkill
  }
}
    ${TechnicalSkillFragmentDoc}`;
export type TechnicalSkillCreateOneMutationFn = Apollo.MutationFunction<TechnicalSkillCreateOneMutation, TechnicalSkillCreateOneMutationVariables>;

/**
 * __useTechnicalSkillCreateOneMutation__
 *
 * To run a mutation, you first call `useTechnicalSkillCreateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTechnicalSkillCreateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [technicalSkillCreateOneMutation, { data, loading, error }] = useTechnicalSkillCreateOneMutation({
 *   variables: {
 *      name: // value for 'name'
 *      iconBlobId: // value for 'iconBlobId'
 *      skillCategoryId: // value for 'skillCategoryId'
 *   },
 * });
 */
export function useTechnicalSkillCreateOneMutation(baseOptions?: Apollo.MutationHookOptions<TechnicalSkillCreateOneMutation, TechnicalSkillCreateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TechnicalSkillCreateOneMutation, TechnicalSkillCreateOneMutationVariables>(TechnicalSkillCreateOneDocument, options);
      }
export type TechnicalSkillCreateOneMutationHookResult = ReturnType<typeof useTechnicalSkillCreateOneMutation>;
export type TechnicalSkillCreateOneMutationResult = Apollo.MutationResult<TechnicalSkillCreateOneMutation>;
export type TechnicalSkillCreateOneMutationOptions = Apollo.BaseMutationOptions<TechnicalSkillCreateOneMutation, TechnicalSkillCreateOneMutationVariables>;
export const TechnicalSkillIconUploadDocument = gql`
    mutation TechnicalSkillIconUpload($id: Int!, $file: Upload!) {
  TechnicalSkillIconUpload(id: $id, file: $file)
}
    `;
export type TechnicalSkillIconUploadMutationFn = Apollo.MutationFunction<TechnicalSkillIconUploadMutation, TechnicalSkillIconUploadMutationVariables>;

/**
 * __useTechnicalSkillIconUploadMutation__
 *
 * To run a mutation, you first call `useTechnicalSkillIconUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTechnicalSkillIconUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [technicalSkillIconUploadMutation, { data, loading, error }] = useTechnicalSkillIconUploadMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useTechnicalSkillIconUploadMutation(baseOptions?: Apollo.MutationHookOptions<TechnicalSkillIconUploadMutation, TechnicalSkillIconUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TechnicalSkillIconUploadMutation, TechnicalSkillIconUploadMutationVariables>(TechnicalSkillIconUploadDocument, options);
      }
export type TechnicalSkillIconUploadMutationHookResult = ReturnType<typeof useTechnicalSkillIconUploadMutation>;
export type TechnicalSkillIconUploadMutationResult = Apollo.MutationResult<TechnicalSkillIconUploadMutation>;
export type TechnicalSkillIconUploadMutationOptions = Apollo.BaseMutationOptions<TechnicalSkillIconUploadMutation, TechnicalSkillIconUploadMutationVariables>;
export const ApiKeyCompanyGenerateDocument = gql`
    mutation ApiKeyCompanyGenerate($companyId: Int!, $permission: IdentityPermissionRole!) {
  ApiKey: ApiKeyCompanyGenerate(companyId: $companyId, permission: $permission) {
    ...ApiKey
  }
}
    ${ApiKeyFragmentDoc}`;
export type ApiKeyCompanyGenerateMutationFn = Apollo.MutationFunction<ApiKeyCompanyGenerateMutation, ApiKeyCompanyGenerateMutationVariables>;

/**
 * __useApiKeyCompanyGenerateMutation__
 *
 * To run a mutation, you first call `useApiKeyCompanyGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiKeyCompanyGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiKeyCompanyGenerateMutation, { data, loading, error }] = useApiKeyCompanyGenerateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      permission: // value for 'permission'
 *   },
 * });
 */
export function useApiKeyCompanyGenerateMutation(baseOptions?: Apollo.MutationHookOptions<ApiKeyCompanyGenerateMutation, ApiKeyCompanyGenerateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiKeyCompanyGenerateMutation, ApiKeyCompanyGenerateMutationVariables>(ApiKeyCompanyGenerateDocument, options);
      }
export type ApiKeyCompanyGenerateMutationHookResult = ReturnType<typeof useApiKeyCompanyGenerateMutation>;
export type ApiKeyCompanyGenerateMutationResult = Apollo.MutationResult<ApiKeyCompanyGenerateMutation>;
export type ApiKeyCompanyGenerateMutationOptions = Apollo.BaseMutationOptions<ApiKeyCompanyGenerateMutation, ApiKeyCompanyGenerateMutationVariables>;
export const ApiKeyUserGenerateDocument = gql`
    mutation ApiKeyUserGenerate($userId: Int, $userSubId: String, $userEmail: String) {
  ApiKey: ApiKeyUserGenerate(
    userId: $userId
    userSubId: $userSubId
    userEmail: $userEmail
  ) {
    ...ApiKey
  }
}
    ${ApiKeyFragmentDoc}`;
export type ApiKeyUserGenerateMutationFn = Apollo.MutationFunction<ApiKeyUserGenerateMutation, ApiKeyUserGenerateMutationVariables>;

/**
 * __useApiKeyUserGenerateMutation__
 *
 * To run a mutation, you first call `useApiKeyUserGenerateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiKeyUserGenerateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiKeyUserGenerateMutation, { data, loading, error }] = useApiKeyUserGenerateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userSubId: // value for 'userSubId'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useApiKeyUserGenerateMutation(baseOptions?: Apollo.MutationHookOptions<ApiKeyUserGenerateMutation, ApiKeyUserGenerateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiKeyUserGenerateMutation, ApiKeyUserGenerateMutationVariables>(ApiKeyUserGenerateDocument, options);
      }
export type ApiKeyUserGenerateMutationHookResult = ReturnType<typeof useApiKeyUserGenerateMutation>;
export type ApiKeyUserGenerateMutationResult = Apollo.MutationResult<ApiKeyUserGenerateMutation>;
export type ApiKeyUserGenerateMutationOptions = Apollo.BaseMutationOptions<ApiKeyUserGenerateMutation, ApiKeyUserGenerateMutationVariables>;
export const ApiKeyDisableDocument = gql`
    mutation ApiKeyDisable($key: String!) {
  ApiKeyDisable(key: $key)
}
    `;
export type ApiKeyDisableMutationFn = Apollo.MutationFunction<ApiKeyDisableMutation, ApiKeyDisableMutationVariables>;

/**
 * __useApiKeyDisableMutation__
 *
 * To run a mutation, you first call `useApiKeyDisableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApiKeyDisableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [apiKeyDisableMutation, { data, loading, error }] = useApiKeyDisableMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useApiKeyDisableMutation(baseOptions?: Apollo.MutationHookOptions<ApiKeyDisableMutation, ApiKeyDisableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApiKeyDisableMutation, ApiKeyDisableMutationVariables>(ApiKeyDisableDocument, options);
      }
export type ApiKeyDisableMutationHookResult = ReturnType<typeof useApiKeyDisableMutation>;
export type ApiKeyDisableMutationResult = Apollo.MutationResult<ApiKeyDisableMutation>;
export type ApiKeyDisableMutationOptions = Apollo.BaseMutationOptions<ApiKeyDisableMutation, ApiKeyDisableMutationVariables>;
export const RequestPasswordRecoveryDocument = gql`
    mutation RequestPasswordRecovery($email: String!, $clientDomainType: ClientDomainType!) {
  requestPasswordRecovery(email: $email, clientDomainType: $clientDomainType)
}
    `;
export type RequestPasswordRecoveryMutationFn = Apollo.MutationFunction<RequestPasswordRecoveryMutation, RequestPasswordRecoveryMutationVariables>;

/**
 * __useRequestPasswordRecoveryMutation__
 *
 * To run a mutation, you first call `useRequestPasswordRecoveryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestPasswordRecoveryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestPasswordRecoveryMutation, { data, loading, error }] = useRequestPasswordRecoveryMutation({
 *   variables: {
 *      email: // value for 'email'
 *      clientDomainType: // value for 'clientDomainType'
 *   },
 * });
 */
export function useRequestPasswordRecoveryMutation(baseOptions?: Apollo.MutationHookOptions<RequestPasswordRecoveryMutation, RequestPasswordRecoveryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestPasswordRecoveryMutation, RequestPasswordRecoveryMutationVariables>(RequestPasswordRecoveryDocument, options);
      }
export type RequestPasswordRecoveryMutationHookResult = ReturnType<typeof useRequestPasswordRecoveryMutation>;
export type RequestPasswordRecoveryMutationResult = Apollo.MutationResult<RequestPasswordRecoveryMutation>;
export type RequestPasswordRecoveryMutationOptions = Apollo.BaseMutationOptions<RequestPasswordRecoveryMutation, RequestPasswordRecoveryMutationVariables>;
export const ExchangeAuthCodeDocument = gql`
    mutation ExchangeAuthCode($authCode: String!) {
  ExchangeAuthCode(authCode: $authCode) {
    accessToken
    refreshToken
  }
}
    `;
export type ExchangeAuthCodeMutationFn = Apollo.MutationFunction<ExchangeAuthCodeMutation, ExchangeAuthCodeMutationVariables>;

/**
 * __useExchangeAuthCodeMutation__
 *
 * To run a mutation, you first call `useExchangeAuthCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeAuthCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeAuthCodeMutation, { data, loading, error }] = useExchangeAuthCodeMutation({
 *   variables: {
 *      authCode: // value for 'authCode'
 *   },
 * });
 */
export function useExchangeAuthCodeMutation(baseOptions?: Apollo.MutationHookOptions<ExchangeAuthCodeMutation, ExchangeAuthCodeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExchangeAuthCodeMutation, ExchangeAuthCodeMutationVariables>(ExchangeAuthCodeDocument, options);
      }
export type ExchangeAuthCodeMutationHookResult = ReturnType<typeof useExchangeAuthCodeMutation>;
export type ExchangeAuthCodeMutationResult = Apollo.MutationResult<ExchangeAuthCodeMutation>;
export type ExchangeAuthCodeMutationOptions = Apollo.BaseMutationOptions<ExchangeAuthCodeMutation, ExchangeAuthCodeMutationVariables>;
export const DeauthenticateDocument = gql`
    mutation Deauthenticate($accessToken: String!) {
  deauthenticate(accessToken: $accessToken)
}
    `;
export type DeauthenticateMutationFn = Apollo.MutationFunction<DeauthenticateMutation, DeauthenticateMutationVariables>;

/**
 * __useDeauthenticateMutation__
 *
 * To run a mutation, you first call `useDeauthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeauthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deauthenticateMutation, { data, loading, error }] = useDeauthenticateMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useDeauthenticateMutation(baseOptions?: Apollo.MutationHookOptions<DeauthenticateMutation, DeauthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeauthenticateMutation, DeauthenticateMutationVariables>(DeauthenticateDocument, options);
      }
export type DeauthenticateMutationHookResult = ReturnType<typeof useDeauthenticateMutation>;
export type DeauthenticateMutationResult = Apollo.MutationResult<DeauthenticateMutation>;
export type DeauthenticateMutationOptions = Apollo.BaseMutationOptions<DeauthenticateMutation, DeauthenticateMutationVariables>;
export const TraitSoftSkillContextFileUploadDocument = gql`
    mutation TraitSoftSkillContextFileUpload($apply: Boolean!, $file: Upload!) {
  TraitSoftSkillContextFileUpload(apply: $apply, file: $file) {
    apply
    creates {
      ...TraitSoftSkillContextFileUploadRow
    }
    warnings
    errorMessage
    errorMessages
  }
}
    ${TraitSoftSkillContextFileUploadRowFragmentDoc}`;
export type TraitSoftSkillContextFileUploadMutationFn = Apollo.MutationFunction<TraitSoftSkillContextFileUploadMutation, TraitSoftSkillContextFileUploadMutationVariables>;

/**
 * __useTraitSoftSkillContextFileUploadMutation__
 *
 * To run a mutation, you first call `useTraitSoftSkillContextFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTraitSoftSkillContextFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [traitSoftSkillContextFileUploadMutation, { data, loading, error }] = useTraitSoftSkillContextFileUploadMutation({
 *   variables: {
 *      apply: // value for 'apply'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useTraitSoftSkillContextFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<TraitSoftSkillContextFileUploadMutation, TraitSoftSkillContextFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TraitSoftSkillContextFileUploadMutation, TraitSoftSkillContextFileUploadMutationVariables>(TraitSoftSkillContextFileUploadDocument, options);
      }
export type TraitSoftSkillContextFileUploadMutationHookResult = ReturnType<typeof useTraitSoftSkillContextFileUploadMutation>;
export type TraitSoftSkillContextFileUploadMutationResult = Apollo.MutationResult<TraitSoftSkillContextFileUploadMutation>;
export type TraitSoftSkillContextFileUploadMutationOptions = Apollo.BaseMutationOptions<TraitSoftSkillContextFileUploadMutation, TraitSoftSkillContextFileUploadMutationVariables>;
export const BehaviourFromCsvRowsCreateManyDocument = gql`
    mutation BehaviourFromCsvRowsCreateMany($dto: [BehaviourFromCsvRowsCreateManyArgs!]!) {
  BehaviourFromCsvRowsCreateMany(dto: $dto)
}
    `;
export type BehaviourFromCsvRowsCreateManyMutationFn = Apollo.MutationFunction<BehaviourFromCsvRowsCreateManyMutation, BehaviourFromCsvRowsCreateManyMutationVariables>;

/**
 * __useBehaviourFromCsvRowsCreateManyMutation__
 *
 * To run a mutation, you first call `useBehaviourFromCsvRowsCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBehaviourFromCsvRowsCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [behaviourFromCsvRowsCreateManyMutation, { data, loading, error }] = useBehaviourFromCsvRowsCreateManyMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useBehaviourFromCsvRowsCreateManyMutation(baseOptions?: Apollo.MutationHookOptions<BehaviourFromCsvRowsCreateManyMutation, BehaviourFromCsvRowsCreateManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BehaviourFromCsvRowsCreateManyMutation, BehaviourFromCsvRowsCreateManyMutationVariables>(BehaviourFromCsvRowsCreateManyDocument, options);
      }
export type BehaviourFromCsvRowsCreateManyMutationHookResult = ReturnType<typeof useBehaviourFromCsvRowsCreateManyMutation>;
export type BehaviourFromCsvRowsCreateManyMutationResult = Apollo.MutationResult<BehaviourFromCsvRowsCreateManyMutation>;
export type BehaviourFromCsvRowsCreateManyMutationOptions = Apollo.BaseMutationOptions<BehaviourFromCsvRowsCreateManyMutation, BehaviourFromCsvRowsCreateManyMutationVariables>;
export const TestCandidateResetOneDocument = gql`
    mutation TestCandidateResetOne($id: Int) {
  TestCandidateResetOne(id: $id)
}
    `;
export type TestCandidateResetOneMutationFn = Apollo.MutationFunction<TestCandidateResetOneMutation, TestCandidateResetOneMutationVariables>;

/**
 * __useTestCandidateResetOneMutation__
 *
 * To run a mutation, you first call `useTestCandidateResetOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCandidateResetOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCandidateResetOneMutation, { data, loading, error }] = useTestCandidateResetOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTestCandidateResetOneMutation(baseOptions?: Apollo.MutationHookOptions<TestCandidateResetOneMutation, TestCandidateResetOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCandidateResetOneMutation, TestCandidateResetOneMutationVariables>(TestCandidateResetOneDocument, options);
      }
export type TestCandidateResetOneMutationHookResult = ReturnType<typeof useTestCandidateResetOneMutation>;
export type TestCandidateResetOneMutationResult = Apollo.MutationResult<TestCandidateResetOneMutation>;
export type TestCandidateResetOneMutationOptions = Apollo.BaseMutationOptions<TestCandidateResetOneMutation, TestCandidateResetOneMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: Int!, $name: String!, $managerReportEmailLinks: Boolean) {
  Company: updateCompany(
    id: $id
    name: $name
    managerReportEmailLinks: $managerReportEmailLinks
  ) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      managerReportEmailLinks: // value for 'managerReportEmailLinks'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const CompanySignatureCreateOneDocument = gql`
    mutation CompanySignatureCreateOne($companyId: Int!, $isDefault: Boolean!, $signature: String!, $signatureEmail: String!) {
  CompanySignature: CompanySignatureCreateOne(
    companyId: $companyId
    isDefault: $isDefault
    signature: $signature
    signatureEmail: $signatureEmail
  ) {
    ...CompanySignatureModel
  }
}
    ${CompanySignatureModelFragmentDoc}`;
export type CompanySignatureCreateOneMutationFn = Apollo.MutationFunction<CompanySignatureCreateOneMutation, CompanySignatureCreateOneMutationVariables>;

/**
 * __useCompanySignatureCreateOneMutation__
 *
 * To run a mutation, you first call `useCompanySignatureCreateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanySignatureCreateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companySignatureCreateOneMutation, { data, loading, error }] = useCompanySignatureCreateOneMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      isDefault: // value for 'isDefault'
 *      signature: // value for 'signature'
 *      signatureEmail: // value for 'signatureEmail'
 *   },
 * });
 */
export function useCompanySignatureCreateOneMutation(baseOptions?: Apollo.MutationHookOptions<CompanySignatureCreateOneMutation, CompanySignatureCreateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanySignatureCreateOneMutation, CompanySignatureCreateOneMutationVariables>(CompanySignatureCreateOneDocument, options);
      }
export type CompanySignatureCreateOneMutationHookResult = ReturnType<typeof useCompanySignatureCreateOneMutation>;
export type CompanySignatureCreateOneMutationResult = Apollo.MutationResult<CompanySignatureCreateOneMutation>;
export type CompanySignatureCreateOneMutationOptions = Apollo.BaseMutationOptions<CompanySignatureCreateOneMutation, CompanySignatureCreateOneMutationVariables>;
export const CompanySignatureUpdateOneDocument = gql`
    mutation CompanySignatureUpdateOne($id: Int!, $isDefault: Boolean, $signature: String, $signatureEmail: String) {
  CompanySignature: CompanySignatureUpdateOne(
    id: $id
    isDefault: $isDefault
    signature: $signature
    signatureEmail: $signatureEmail
  ) {
    ...CompanySignatureModel
  }
}
    ${CompanySignatureModelFragmentDoc}`;
export type CompanySignatureUpdateOneMutationFn = Apollo.MutationFunction<CompanySignatureUpdateOneMutation, CompanySignatureUpdateOneMutationVariables>;

/**
 * __useCompanySignatureUpdateOneMutation__
 *
 * To run a mutation, you first call `useCompanySignatureUpdateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanySignatureUpdateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companySignatureUpdateOneMutation, { data, loading, error }] = useCompanySignatureUpdateOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDefault: // value for 'isDefault'
 *      signature: // value for 'signature'
 *      signatureEmail: // value for 'signatureEmail'
 *   },
 * });
 */
export function useCompanySignatureUpdateOneMutation(baseOptions?: Apollo.MutationHookOptions<CompanySignatureUpdateOneMutation, CompanySignatureUpdateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanySignatureUpdateOneMutation, CompanySignatureUpdateOneMutationVariables>(CompanySignatureUpdateOneDocument, options);
      }
export type CompanySignatureUpdateOneMutationHookResult = ReturnType<typeof useCompanySignatureUpdateOneMutation>;
export type CompanySignatureUpdateOneMutationResult = Apollo.MutationResult<CompanySignatureUpdateOneMutation>;
export type CompanySignatureUpdateOneMutationOptions = Apollo.BaseMutationOptions<CompanySignatureUpdateOneMutation, CompanySignatureUpdateOneMutationVariables>;
export const CompanySignatureDeleteOneDocument = gql`
    mutation CompanySignatureDeleteOne($id: Int!) {
  CompanySignature: CompanySignatureDeleteOne(id: $id) {
    affected
  }
}
    `;
export type CompanySignatureDeleteOneMutationFn = Apollo.MutationFunction<CompanySignatureDeleteOneMutation, CompanySignatureDeleteOneMutationVariables>;

/**
 * __useCompanySignatureDeleteOneMutation__
 *
 * To run a mutation, you first call `useCompanySignatureDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanySignatureDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companySignatureDeleteOneMutation, { data, loading, error }] = useCompanySignatureDeleteOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanySignatureDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<CompanySignatureDeleteOneMutation, CompanySignatureDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanySignatureDeleteOneMutation, CompanySignatureDeleteOneMutationVariables>(CompanySignatureDeleteOneDocument, options);
      }
export type CompanySignatureDeleteOneMutationHookResult = ReturnType<typeof useCompanySignatureDeleteOneMutation>;
export type CompanySignatureDeleteOneMutationResult = Apollo.MutationResult<CompanySignatureDeleteOneMutation>;
export type CompanySignatureDeleteOneMutationOptions = Apollo.BaseMutationOptions<CompanySignatureDeleteOneMutation, CompanySignatureDeleteOneMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($name: String!, $managerReportEmailLinks: Boolean) {
  company: createCompany(
    name: $name
    managerReportEmailLinks: $managerReportEmailLinks
  ) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      managerReportEmailLinks: // value for 'managerReportEmailLinks'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: Int!) {
  deleteCompany(id: $id)
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const UploadCompanyLogoDocument = gql`
    mutation UploadCompanyLogo($id: Int!, $file: Upload!) {
  uploadCompanyLogo(id: $id, file: $file)
}
    `;
export type UploadCompanyLogoMutationFn = Apollo.MutationFunction<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;

/**
 * __useUploadCompanyLogoMutation__
 *
 * To run a mutation, you first call `useUploadCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyLogoMutation, { data, loading, error }] = useUploadCompanyLogoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadCompanyLogoMutation(baseOptions?: Apollo.MutationHookOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>(UploadCompanyLogoDocument, options);
      }
export type UploadCompanyLogoMutationHookResult = ReturnType<typeof useUploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationResult = Apollo.MutationResult<UploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationOptions = Apollo.BaseMutationOptions<UploadCompanyLogoMutation, UploadCompanyLogoMutationVariables>;
export const CompanyAllowedEmailDomainsUpdateDocument = gql`
    mutation CompanyAllowedEmailDomainsUpdate($allowedEmailDomains: [String!]!, $id: Int!) {
  CompanyAllowedEmailDomainsUpdate(
    allowedEmailDomains: $allowedEmailDomains
    id: $id
  )
}
    `;
export type CompanyAllowedEmailDomainsUpdateMutationFn = Apollo.MutationFunction<CompanyAllowedEmailDomainsUpdateMutation, CompanyAllowedEmailDomainsUpdateMutationVariables>;

/**
 * __useCompanyAllowedEmailDomainsUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyAllowedEmailDomainsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyAllowedEmailDomainsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyAllowedEmailDomainsUpdateMutation, { data, loading, error }] = useCompanyAllowedEmailDomainsUpdateMutation({
 *   variables: {
 *      allowedEmailDomains: // value for 'allowedEmailDomains'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyAllowedEmailDomainsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CompanyAllowedEmailDomainsUpdateMutation, CompanyAllowedEmailDomainsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyAllowedEmailDomainsUpdateMutation, CompanyAllowedEmailDomainsUpdateMutationVariables>(CompanyAllowedEmailDomainsUpdateDocument, options);
      }
export type CompanyAllowedEmailDomainsUpdateMutationHookResult = ReturnType<typeof useCompanyAllowedEmailDomainsUpdateMutation>;
export type CompanyAllowedEmailDomainsUpdateMutationResult = Apollo.MutationResult<CompanyAllowedEmailDomainsUpdateMutation>;
export type CompanyAllowedEmailDomainsUpdateMutationOptions = Apollo.BaseMutationOptions<CompanyAllowedEmailDomainsUpdateMutation, CompanyAllowedEmailDomainsUpdateMutationVariables>;
export const CompanyCreateUserDocument = gql`
    mutation CompanyCreateUser($companyId: Float!, $email: String!, $externalId: String!, $firstName: String, $locale: SupportedLocale, $lastName: String, $roles: [IdentityPermissionRole!], $sendInvite: Boolean!) {
  CompanyCreateUser(
    companyId: $companyId
    email: $email
    externalId: $externalId
    firstName: $firstName
    lastName: $lastName
    locale: $locale
    roles: $roles
    sendInvite: $sendInvite
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type CompanyCreateUserMutationFn = Apollo.MutationFunction<CompanyCreateUserMutation, CompanyCreateUserMutationVariables>;

/**
 * __useCompanyCreateUserMutation__
 *
 * To run a mutation, you first call `useCompanyCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyCreateUserMutation, { data, loading, error }] = useCompanyCreateUserMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      email: // value for 'email'
 *      externalId: // value for 'externalId'
 *      firstName: // value for 'firstName'
 *      locale: // value for 'locale'
 *      lastName: // value for 'lastName'
 *      roles: // value for 'roles'
 *      sendInvite: // value for 'sendInvite'
 *   },
 * });
 */
export function useCompanyCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CompanyCreateUserMutation, CompanyCreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyCreateUserMutation, CompanyCreateUserMutationVariables>(CompanyCreateUserDocument, options);
      }
export type CompanyCreateUserMutationHookResult = ReturnType<typeof useCompanyCreateUserMutation>;
export type CompanyCreateUserMutationResult = Apollo.MutationResult<CompanyCreateUserMutation>;
export type CompanyCreateUserMutationOptions = Apollo.BaseMutationOptions<CompanyCreateUserMutation, CompanyCreateUserMutationVariables>;
export const CompanyResendInviteDocument = gql`
    mutation CompanyResendInvite($userId: Float!, $companyId: Float!) {
  CompanyResendInvite(userId: $userId, companyId: $companyId)
}
    `;
export type CompanyResendInviteMutationFn = Apollo.MutationFunction<CompanyResendInviteMutation, CompanyResendInviteMutationVariables>;

/**
 * __useCompanyResendInviteMutation__
 *
 * To run a mutation, you first call `useCompanyResendInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyResendInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyResendInviteMutation, { data, loading, error }] = useCompanyResendInviteMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyResendInviteMutation(baseOptions?: Apollo.MutationHookOptions<CompanyResendInviteMutation, CompanyResendInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyResendInviteMutation, CompanyResendInviteMutationVariables>(CompanyResendInviteDocument, options);
      }
export type CompanyResendInviteMutationHookResult = ReturnType<typeof useCompanyResendInviteMutation>;
export type CompanyResendInviteMutationResult = Apollo.MutationResult<CompanyResendInviteMutation>;
export type CompanyResendInviteMutationOptions = Apollo.BaseMutationOptions<CompanyResendInviteMutation, CompanyResendInviteMutationVariables>;
export const CompanyMaybeUserEmployeeFindManyDocument = gql`
    query CompanyMaybeUserEmployeeFindMany($companyId: Float!) {
  CompanyMaybeUserEmployeeFindMany(companyId: $companyId) {
    userId
    employeeId
    email
    firstName
    lastName
    isUser
    isCompanyEmployee
  }
}
    `;

/**
 * __useCompanyMaybeUserEmployeeFindManyQuery__
 *
 * To run a query within a React component, call `useCompanyMaybeUserEmployeeFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyMaybeUserEmployeeFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyMaybeUserEmployeeFindManyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyMaybeUserEmployeeFindManyQuery(baseOptions: Apollo.QueryHookOptions<CompanyMaybeUserEmployeeFindManyQuery, CompanyMaybeUserEmployeeFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyMaybeUserEmployeeFindManyQuery, CompanyMaybeUserEmployeeFindManyQueryVariables>(CompanyMaybeUserEmployeeFindManyDocument, options);
      }
export function useCompanyMaybeUserEmployeeFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyMaybeUserEmployeeFindManyQuery, CompanyMaybeUserEmployeeFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyMaybeUserEmployeeFindManyQuery, CompanyMaybeUserEmployeeFindManyQueryVariables>(CompanyMaybeUserEmployeeFindManyDocument, options);
        }
export type CompanyMaybeUserEmployeeFindManyQueryHookResult = ReturnType<typeof useCompanyMaybeUserEmployeeFindManyQuery>;
export type CompanyMaybeUserEmployeeFindManyLazyQueryHookResult = ReturnType<typeof useCompanyMaybeUserEmployeeFindManyLazyQuery>;
export type CompanyMaybeUserEmployeeFindManyQueryResult = Apollo.QueryResult<CompanyMaybeUserEmployeeFindManyQuery, CompanyMaybeUserEmployeeFindManyQueryVariables>;
export const CompanyEmployeeUpdateDocument = gql`
    mutation companyEmployeeUpdate($companyId: Int, $customFields: UserCompanyInfoCustomFieldsArgs, $department: String, $email: String, $employeeId: String, $endDate: Date, $firstName: String, $hrManagerEmployeeId: String, $id: Float!, $isArchived: Boolean, $jobTitle: String, $lastName: String, $startDate: Date) {
  CompanyEmployeeUpdate(
    companyId: $companyId
    customFields: $customFields
    department: $department
    email: $email
    employeeId: $employeeId
    endDate: $endDate
    firstName: $firstName
    hrManagerEmployeeId: $hrManagerEmployeeId
    id: $id
    isArchived: $isArchived
    jobTitle: $jobTitle
    lastName: $lastName
    startDate: $startDate
  ) {
    ...CompanyEmployee
  }
}
    ${CompanyEmployeeFragmentDoc}`;
export type CompanyEmployeeUpdateMutationFn = Apollo.MutationFunction<CompanyEmployeeUpdateMutation, CompanyEmployeeUpdateMutationVariables>;

/**
 * __useCompanyEmployeeUpdateMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeUpdateMutation, { data, loading, error }] = useCompanyEmployeeUpdateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customFields: // value for 'customFields'
 *      department: // value for 'department'
 *      email: // value for 'email'
 *      employeeId: // value for 'employeeId'
 *      endDate: // value for 'endDate'
 *      firstName: // value for 'firstName'
 *      hrManagerEmployeeId: // value for 'hrManagerEmployeeId'
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *      jobTitle: // value for 'jobTitle'
 *      lastName: // value for 'lastName'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useCompanyEmployeeUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CompanyEmployeeUpdateMutation, CompanyEmployeeUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyEmployeeUpdateMutation, CompanyEmployeeUpdateMutationVariables>(CompanyEmployeeUpdateDocument, options);
      }
export type CompanyEmployeeUpdateMutationHookResult = ReturnType<typeof useCompanyEmployeeUpdateMutation>;
export type CompanyEmployeeUpdateMutationResult = Apollo.MutationResult<CompanyEmployeeUpdateMutation>;
export type CompanyEmployeeUpdateMutationOptions = Apollo.BaseMutationOptions<CompanyEmployeeUpdateMutation, CompanyEmployeeUpdateMutationVariables>;
export const CompanyEmployeeDeleteOneDocument = gql`
    mutation companyEmployeeDeleteOne($id: Int!) {
  CompanyEmployeeDeleteOne(id: $id) {
    affected
  }
}
    `;
export type CompanyEmployeeDeleteOneMutationFn = Apollo.MutationFunction<CompanyEmployeeDeleteOneMutation, CompanyEmployeeDeleteOneMutationVariables>;

/**
 * __useCompanyEmployeeDeleteOneMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeDeleteOneMutation, { data, loading, error }] = useCompanyEmployeeDeleteOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyEmployeeDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<CompanyEmployeeDeleteOneMutation, CompanyEmployeeDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyEmployeeDeleteOneMutation, CompanyEmployeeDeleteOneMutationVariables>(CompanyEmployeeDeleteOneDocument, options);
      }
export type CompanyEmployeeDeleteOneMutationHookResult = ReturnType<typeof useCompanyEmployeeDeleteOneMutation>;
export type CompanyEmployeeDeleteOneMutationResult = Apollo.MutationResult<CompanyEmployeeDeleteOneMutation>;
export type CompanyEmployeeDeleteOneMutationOptions = Apollo.BaseMutationOptions<CompanyEmployeeDeleteOneMutation, CompanyEmployeeDeleteOneMutationVariables>;
export const CompanyEmployeeCreateDocument = gql`
    mutation companyEmployeeCreate($companyId: Int!, $customFields: UserCompanyInfoCustomFieldsArgs, $department: String, $email: String!, $employeeId: String!, $endDate: Date, $firstName: String!, $hrManagerEmployeeId: String, $isArchived: Boolean, $jobTitle: String, $lastName: String!, $startDate: Date) {
  CompanyEmployeeCreate(
    companyId: $companyId
    customFields: $customFields
    department: $department
    email: $email
    employeeId: $employeeId
    endDate: $endDate
    firstName: $firstName
    hrManagerEmployeeId: $hrManagerEmployeeId
    isArchived: $isArchived
    jobTitle: $jobTitle
    lastName: $lastName
    startDate: $startDate
  ) {
    ...CompanyEmployee
  }
}
    ${CompanyEmployeeFragmentDoc}`;
export type CompanyEmployeeCreateMutationFn = Apollo.MutationFunction<CompanyEmployeeCreateMutation, CompanyEmployeeCreateMutationVariables>;

/**
 * __useCompanyEmployeeCreateMutation__
 *
 * To run a mutation, you first call `useCompanyEmployeeCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyEmployeeCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyEmployeeCreateMutation, { data, loading, error }] = useCompanyEmployeeCreateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      customFields: // value for 'customFields'
 *      department: // value for 'department'
 *      email: // value for 'email'
 *      employeeId: // value for 'employeeId'
 *      endDate: // value for 'endDate'
 *      firstName: // value for 'firstName'
 *      hrManagerEmployeeId: // value for 'hrManagerEmployeeId'
 *      isArchived: // value for 'isArchived'
 *      jobTitle: // value for 'jobTitle'
 *      lastName: // value for 'lastName'
 *      startDate: // value for 'startDate'
 *   },
 * });
 */
export function useCompanyEmployeeCreateMutation(baseOptions?: Apollo.MutationHookOptions<CompanyEmployeeCreateMutation, CompanyEmployeeCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyEmployeeCreateMutation, CompanyEmployeeCreateMutationVariables>(CompanyEmployeeCreateDocument, options);
      }
export type CompanyEmployeeCreateMutationHookResult = ReturnType<typeof useCompanyEmployeeCreateMutation>;
export type CompanyEmployeeCreateMutationResult = Apollo.MutationResult<CompanyEmployeeCreateMutation>;
export type CompanyEmployeeCreateMutationOptions = Apollo.BaseMutationOptions<CompanyEmployeeCreateMutation, CompanyEmployeeCreateMutationVariables>;
export const CompanyRoleUpsertDocument = gql`
    mutation CompanyRoleUpsert($companyId: Int!, $roleId: Int!, $twoFactorEnabled: Boolean!) {
  CompanyRoleUpsert(
    companyId: $companyId
    roleId: $roleId
    twoFactorEnabled: $twoFactorEnabled
  ) {
    ...CompanyRole
  }
}
    ${CompanyRoleFragmentDoc}`;
export type CompanyRoleUpsertMutationFn = Apollo.MutationFunction<CompanyRoleUpsertMutation, CompanyRoleUpsertMutationVariables>;

/**
 * __useCompanyRoleUpsertMutation__
 *
 * To run a mutation, you first call `useCompanyRoleUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompanyRoleUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [companyRoleUpsertMutation, { data, loading, error }] = useCompanyRoleUpsertMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      roleId: // value for 'roleId'
 *      twoFactorEnabled: // value for 'twoFactorEnabled'
 *   },
 * });
 */
export function useCompanyRoleUpsertMutation(baseOptions?: Apollo.MutationHookOptions<CompanyRoleUpsertMutation, CompanyRoleUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompanyRoleUpsertMutation, CompanyRoleUpsertMutationVariables>(CompanyRoleUpsertDocument, options);
      }
export type CompanyRoleUpsertMutationHookResult = ReturnType<typeof useCompanyRoleUpsertMutation>;
export type CompanyRoleUpsertMutationResult = Apollo.MutationResult<CompanyRoleUpsertMutation>;
export type CompanyRoleUpsertMutationOptions = Apollo.BaseMutationOptions<CompanyRoleUpsertMutation, CompanyRoleUpsertMutationVariables>;
export const SrEmailDispatchEmailsDocument = gql`
    mutation SrEmailDispatchEmails($projectJobRoleId: Int, $stageCandidateId: Int!) {
  SrEmailDispatchEmails(
    projectJobRoleId: $projectJobRoleId
    stageCandidateId: $stageCandidateId
  )
}
    `;
export type SrEmailDispatchEmailsMutationFn = Apollo.MutationFunction<SrEmailDispatchEmailsMutation, SrEmailDispatchEmailsMutationVariables>;

/**
 * __useSrEmailDispatchEmailsMutation__
 *
 * To run a mutation, you first call `useSrEmailDispatchEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSrEmailDispatchEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [srEmailDispatchEmailsMutation, { data, loading, error }] = useSrEmailDispatchEmailsMutation({
 *   variables: {
 *      projectJobRoleId: // value for 'projectJobRoleId'
 *      stageCandidateId: // value for 'stageCandidateId'
 *   },
 * });
 */
export function useSrEmailDispatchEmailsMutation(baseOptions?: Apollo.MutationHookOptions<SrEmailDispatchEmailsMutation, SrEmailDispatchEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SrEmailDispatchEmailsMutation, SrEmailDispatchEmailsMutationVariables>(SrEmailDispatchEmailsDocument, options);
      }
export type SrEmailDispatchEmailsMutationHookResult = ReturnType<typeof useSrEmailDispatchEmailsMutation>;
export type SrEmailDispatchEmailsMutationResult = Apollo.MutationResult<SrEmailDispatchEmailsMutation>;
export type SrEmailDispatchEmailsMutationOptions = Apollo.BaseMutationOptions<SrEmailDispatchEmailsMutation, SrEmailDispatchEmailsMutationVariables>;
export const IgCategoryCreateDocument = gql`
    mutation IgCategoryCreate($internalName: String!, $name: String!) {
  IgCategoryCreate(internalName: $internalName, name: $name) {
    ...IgCategory
  }
}
    ${IgCategoryFragmentDoc}`;
export type IgCategoryCreateMutationFn = Apollo.MutationFunction<IgCategoryCreateMutation, IgCategoryCreateMutationVariables>;

/**
 * __useIgCategoryCreateMutation__
 *
 * To run a mutation, you first call `useIgCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igCategoryCreateMutation, { data, loading, error }] = useIgCategoryCreateMutation({
 *   variables: {
 *      internalName: // value for 'internalName'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useIgCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<IgCategoryCreateMutation, IgCategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgCategoryCreateMutation, IgCategoryCreateMutationVariables>(IgCategoryCreateDocument, options);
      }
export type IgCategoryCreateMutationHookResult = ReturnType<typeof useIgCategoryCreateMutation>;
export type IgCategoryCreateMutationResult = Apollo.MutationResult<IgCategoryCreateMutation>;
export type IgCategoryCreateMutationOptions = Apollo.BaseMutationOptions<IgCategoryCreateMutation, IgCategoryCreateMutationVariables>;
export const IgCategoryUpdateDocument = gql`
    mutation IgCategoryUpdate($id: Int!, $internalName: String, $name: String, $isArchived: Boolean) {
  IgCategoryUpdate(
    id: $id
    internalName: $internalName
    name: $name
    isArchived: $isArchived
  ) {
    ...IgCategory
  }
}
    ${IgCategoryFragmentDoc}`;
export type IgCategoryUpdateMutationFn = Apollo.MutationFunction<IgCategoryUpdateMutation, IgCategoryUpdateMutationVariables>;

/**
 * __useIgCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useIgCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igCategoryUpdateMutation, { data, loading, error }] = useIgCategoryUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      internalName: // value for 'internalName'
 *      name: // value for 'name'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useIgCategoryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<IgCategoryUpdateMutation, IgCategoryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgCategoryUpdateMutation, IgCategoryUpdateMutationVariables>(IgCategoryUpdateDocument, options);
      }
export type IgCategoryUpdateMutationHookResult = ReturnType<typeof useIgCategoryUpdateMutation>;
export type IgCategoryUpdateMutationResult = Apollo.MutationResult<IgCategoryUpdateMutation>;
export type IgCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<IgCategoryUpdateMutation, IgCategoryUpdateMutationVariables>;
export const IgCategoryDeleteOneDocument = gql`
    mutation IgCategoryDeleteOne($id: Int!) {
  IgCategoryDeleteOne(id: $id) {
    affected
  }
}
    `;
export type IgCategoryDeleteOneMutationFn = Apollo.MutationFunction<IgCategoryDeleteOneMutation, IgCategoryDeleteOneMutationVariables>;

/**
 * __useIgCategoryDeleteOneMutation__
 *
 * To run a mutation, you first call `useIgCategoryDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgCategoryDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igCategoryDeleteOneMutation, { data, loading, error }] = useIgCategoryDeleteOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgCategoryDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<IgCategoryDeleteOneMutation, IgCategoryDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgCategoryDeleteOneMutation, IgCategoryDeleteOneMutationVariables>(IgCategoryDeleteOneDocument, options);
      }
export type IgCategoryDeleteOneMutationHookResult = ReturnType<typeof useIgCategoryDeleteOneMutation>;
export type IgCategoryDeleteOneMutationResult = Apollo.MutationResult<IgCategoryDeleteOneMutation>;
export type IgCategoryDeleteOneMutationOptions = Apollo.BaseMutationOptions<IgCategoryDeleteOneMutation, IgCategoryDeleteOneMutationVariables>;
export const IgInterviewGuideCreateDocument = gql`
    mutation IgInterviewGuideCreate($name: String!, $ownerId: Int!) {
  IgInterviewGuideCreate(name: $name, ownerId: $ownerId) {
    ...IgInterviewGuide
  }
}
    ${IgInterviewGuideFragmentDoc}`;
export type IgInterviewGuideCreateMutationFn = Apollo.MutationFunction<IgInterviewGuideCreateMutation, IgInterviewGuideCreateMutationVariables>;

/**
 * __useIgInterviewGuideCreateMutation__
 *
 * To run a mutation, you first call `useIgInterviewGuideCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igInterviewGuideCreateMutation, { data, loading, error }] = useIgInterviewGuideCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useIgInterviewGuideCreateMutation(baseOptions?: Apollo.MutationHookOptions<IgInterviewGuideCreateMutation, IgInterviewGuideCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgInterviewGuideCreateMutation, IgInterviewGuideCreateMutationVariables>(IgInterviewGuideCreateDocument, options);
      }
export type IgInterviewGuideCreateMutationHookResult = ReturnType<typeof useIgInterviewGuideCreateMutation>;
export type IgInterviewGuideCreateMutationResult = Apollo.MutationResult<IgInterviewGuideCreateMutation>;
export type IgInterviewGuideCreateMutationOptions = Apollo.BaseMutationOptions<IgInterviewGuideCreateMutation, IgInterviewGuideCreateMutationVariables>;
export const IgInterviewGuideUpdateDocument = gql`
    mutation IgInterviewGuideUpdate($id: Int!, $name: String, $isArchived: Boolean, $ownerId: Int) {
  IgInterviewGuideUpdate(
    id: $id
    name: $name
    isArchived: $isArchived
    ownerId: $ownerId
  ) {
    ...IgInterviewGuide
  }
}
    ${IgInterviewGuideFragmentDoc}`;
export type IgInterviewGuideUpdateMutationFn = Apollo.MutationFunction<IgInterviewGuideUpdateMutation, IgInterviewGuideUpdateMutationVariables>;

/**
 * __useIgInterviewGuideUpdateMutation__
 *
 * To run a mutation, you first call `useIgInterviewGuideUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igInterviewGuideUpdateMutation, { data, loading, error }] = useIgInterviewGuideUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      isArchived: // value for 'isArchived'
 *      ownerId: // value for 'ownerId'
 *   },
 * });
 */
export function useIgInterviewGuideUpdateMutation(baseOptions?: Apollo.MutationHookOptions<IgInterviewGuideUpdateMutation, IgInterviewGuideUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgInterviewGuideUpdateMutation, IgInterviewGuideUpdateMutationVariables>(IgInterviewGuideUpdateDocument, options);
      }
export type IgInterviewGuideUpdateMutationHookResult = ReturnType<typeof useIgInterviewGuideUpdateMutation>;
export type IgInterviewGuideUpdateMutationResult = Apollo.MutationResult<IgInterviewGuideUpdateMutation>;
export type IgInterviewGuideUpdateMutationOptions = Apollo.BaseMutationOptions<IgInterviewGuideUpdateMutation, IgInterviewGuideUpdateMutationVariables>;
export const IgInterviewGuideDeleteOneDocument = gql`
    mutation IgInterviewGuideDeleteOne($id: Int!) {
  IgInterviewGuideDeleteOne(id: $id) {
    affected
  }
}
    `;
export type IgInterviewGuideDeleteOneMutationFn = Apollo.MutationFunction<IgInterviewGuideDeleteOneMutation, IgInterviewGuideDeleteOneMutationVariables>;

/**
 * __useIgInterviewGuideDeleteOneMutation__
 *
 * To run a mutation, you first call `useIgInterviewGuideDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igInterviewGuideDeleteOneMutation, { data, loading, error }] = useIgInterviewGuideDeleteOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgInterviewGuideDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<IgInterviewGuideDeleteOneMutation, IgInterviewGuideDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgInterviewGuideDeleteOneMutation, IgInterviewGuideDeleteOneMutationVariables>(IgInterviewGuideDeleteOneDocument, options);
      }
export type IgInterviewGuideDeleteOneMutationHookResult = ReturnType<typeof useIgInterviewGuideDeleteOneMutation>;
export type IgInterviewGuideDeleteOneMutationResult = Apollo.MutationResult<IgInterviewGuideDeleteOneMutation>;
export type IgInterviewGuideDeleteOneMutationOptions = Apollo.BaseMutationOptions<IgInterviewGuideDeleteOneMutation, IgInterviewGuideDeleteOneMutationVariables>;
export const IgInterviewGuideCategoryCreateDocument = gql`
    mutation IgInterviewGuideCategoryCreate($categoryId: Int!, $interviewGuideId: Int!, $order: Int!, $colour: IgCategoryColour!) {
  IgInterviewGuideCategoryCreate(
    categoryId: $categoryId
    interviewGuideId: $interviewGuideId
    order: $order
    colour: $colour
  ) {
    ...IgInterviewGuideCategory
  }
}
    ${IgInterviewGuideCategoryFragmentDoc}`;
export type IgInterviewGuideCategoryCreateMutationFn = Apollo.MutationFunction<IgInterviewGuideCategoryCreateMutation, IgInterviewGuideCategoryCreateMutationVariables>;

/**
 * __useIgInterviewGuideCategoryCreateMutation__
 *
 * To run a mutation, you first call `useIgInterviewGuideCategoryCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideCategoryCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igInterviewGuideCategoryCreateMutation, { data, loading, error }] = useIgInterviewGuideCategoryCreateMutation({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      interviewGuideId: // value for 'interviewGuideId'
 *      order: // value for 'order'
 *      colour: // value for 'colour'
 *   },
 * });
 */
export function useIgInterviewGuideCategoryCreateMutation(baseOptions?: Apollo.MutationHookOptions<IgInterviewGuideCategoryCreateMutation, IgInterviewGuideCategoryCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgInterviewGuideCategoryCreateMutation, IgInterviewGuideCategoryCreateMutationVariables>(IgInterviewGuideCategoryCreateDocument, options);
      }
export type IgInterviewGuideCategoryCreateMutationHookResult = ReturnType<typeof useIgInterviewGuideCategoryCreateMutation>;
export type IgInterviewGuideCategoryCreateMutationResult = Apollo.MutationResult<IgInterviewGuideCategoryCreateMutation>;
export type IgInterviewGuideCategoryCreateMutationOptions = Apollo.BaseMutationOptions<IgInterviewGuideCategoryCreateMutation, IgInterviewGuideCategoryCreateMutationVariables>;
export const IgInterviewGuideCategoryDeleteDocument = gql`
    mutation IgInterviewGuideCategoryDelete($id: Int!) {
  IgInterviewGuideCategoryDeleteOne(id: $id) {
    affected
  }
}
    `;
export type IgInterviewGuideCategoryDeleteMutationFn = Apollo.MutationFunction<IgInterviewGuideCategoryDeleteMutation, IgInterviewGuideCategoryDeleteMutationVariables>;

/**
 * __useIgInterviewGuideCategoryDeleteMutation__
 *
 * To run a mutation, you first call `useIgInterviewGuideCategoryDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideCategoryDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igInterviewGuideCategoryDeleteMutation, { data, loading, error }] = useIgInterviewGuideCategoryDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgInterviewGuideCategoryDeleteMutation(baseOptions?: Apollo.MutationHookOptions<IgInterviewGuideCategoryDeleteMutation, IgInterviewGuideCategoryDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgInterviewGuideCategoryDeleteMutation, IgInterviewGuideCategoryDeleteMutationVariables>(IgInterviewGuideCategoryDeleteDocument, options);
      }
export type IgInterviewGuideCategoryDeleteMutationHookResult = ReturnType<typeof useIgInterviewGuideCategoryDeleteMutation>;
export type IgInterviewGuideCategoryDeleteMutationResult = Apollo.MutationResult<IgInterviewGuideCategoryDeleteMutation>;
export type IgInterviewGuideCategoryDeleteMutationOptions = Apollo.BaseMutationOptions<IgInterviewGuideCategoryDeleteMutation, IgInterviewGuideCategoryDeleteMutationVariables>;
export const IgInterviewGuideCategoryUpdateDocument = gql`
    mutation IgInterviewGuideCategoryUpdate($id: Int!, $colour: IgCategoryColour!, $order: Int!) {
  IgInterviewGuideCategoryUpdate(id: $id, colour: $colour, order: $order) {
    id
    order
    colour
  }
}
    `;
export type IgInterviewGuideCategoryUpdateMutationFn = Apollo.MutationFunction<IgInterviewGuideCategoryUpdateMutation, IgInterviewGuideCategoryUpdateMutationVariables>;

/**
 * __useIgInterviewGuideCategoryUpdateMutation__
 *
 * To run a mutation, you first call `useIgInterviewGuideCategoryUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideCategoryUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igInterviewGuideCategoryUpdateMutation, { data, loading, error }] = useIgInterviewGuideCategoryUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      colour: // value for 'colour'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useIgInterviewGuideCategoryUpdateMutation(baseOptions?: Apollo.MutationHookOptions<IgInterviewGuideCategoryUpdateMutation, IgInterviewGuideCategoryUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgInterviewGuideCategoryUpdateMutation, IgInterviewGuideCategoryUpdateMutationVariables>(IgInterviewGuideCategoryUpdateDocument, options);
      }
export type IgInterviewGuideCategoryUpdateMutationHookResult = ReturnType<typeof useIgInterviewGuideCategoryUpdateMutation>;
export type IgInterviewGuideCategoryUpdateMutationResult = Apollo.MutationResult<IgInterviewGuideCategoryUpdateMutation>;
export type IgInterviewGuideCategoryUpdateMutationOptions = Apollo.BaseMutationOptions<IgInterviewGuideCategoryUpdateMutation, IgInterviewGuideCategoryUpdateMutationVariables>;
export const IgStepDeleteOneDocument = gql`
    mutation IgStepDeleteOne($id: Int!) {
  IgStepDeleteOne(id: $id) {
    affected
  }
}
    `;
export type IgStepDeleteOneMutationFn = Apollo.MutationFunction<IgStepDeleteOneMutation, IgStepDeleteOneMutationVariables>;

/**
 * __useIgStepDeleteOneMutation__
 *
 * To run a mutation, you first call `useIgStepDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgStepDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igStepDeleteOneMutation, { data, loading, error }] = useIgStepDeleteOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgStepDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<IgStepDeleteOneMutation, IgStepDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgStepDeleteOneMutation, IgStepDeleteOneMutationVariables>(IgStepDeleteOneDocument, options);
      }
export type IgStepDeleteOneMutationHookResult = ReturnType<typeof useIgStepDeleteOneMutation>;
export type IgStepDeleteOneMutationResult = Apollo.MutationResult<IgStepDeleteOneMutation>;
export type IgStepDeleteOneMutationOptions = Apollo.BaseMutationOptions<IgStepDeleteOneMutation, IgStepDeleteOneMutationVariables>;
export const IgStepUpdateDocument = gql`
    mutation IgStepUpdate($id: Int!, $interviewGuideId: Int!, $isArchived: Boolean, $name: String, $internalName: String, $action: String, $durationInSeconds: Int, $colour: IgCategoryColour, $questionType: IgQuestionType, $order: Int, $staticQuestionName: String) {
  IgStepUpdate(
    id: $id
    isArchived: $isArchived
    name: $name
    internalName: $internalName
    action: $action
    durationInSeconds: $durationInSeconds
    colour: $colour
    questionType: $questionType
    interviewGuideId: $interviewGuideId
    order: $order
    staticQuestionName: $staticQuestionName
  ) {
    ...IgStep
  }
}
    ${IgStepFragmentDoc}`;
export type IgStepUpdateMutationFn = Apollo.MutationFunction<IgStepUpdateMutation, IgStepUpdateMutationVariables>;

/**
 * __useIgStepUpdateMutation__
 *
 * To run a mutation, you first call `useIgStepUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgStepUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igStepUpdateMutation, { data, loading, error }] = useIgStepUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      interviewGuideId: // value for 'interviewGuideId'
 *      isArchived: // value for 'isArchived'
 *      name: // value for 'name'
 *      internalName: // value for 'internalName'
 *      action: // value for 'action'
 *      durationInSeconds: // value for 'durationInSeconds'
 *      colour: // value for 'colour'
 *      questionType: // value for 'questionType'
 *      order: // value for 'order'
 *      staticQuestionName: // value for 'staticQuestionName'
 *   },
 * });
 */
export function useIgStepUpdateMutation(baseOptions?: Apollo.MutationHookOptions<IgStepUpdateMutation, IgStepUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgStepUpdateMutation, IgStepUpdateMutationVariables>(IgStepUpdateDocument, options);
      }
export type IgStepUpdateMutationHookResult = ReturnType<typeof useIgStepUpdateMutation>;
export type IgStepUpdateMutationResult = Apollo.MutationResult<IgStepUpdateMutation>;
export type IgStepUpdateMutationOptions = Apollo.BaseMutationOptions<IgStepUpdateMutation, IgStepUpdateMutationVariables>;
export const IgStepArchiveDocument = gql`
    mutation IgStepArchive($id: Int!, $isArchived: Boolean!) {
  IgStepUpdate(id: $id, isArchived: $isArchived) {
    ...IgStep
  }
}
    ${IgStepFragmentDoc}`;
export type IgStepArchiveMutationFn = Apollo.MutationFunction<IgStepArchiveMutation, IgStepArchiveMutationVariables>;

/**
 * __useIgStepArchiveMutation__
 *
 * To run a mutation, you first call `useIgStepArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgStepArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igStepArchiveMutation, { data, loading, error }] = useIgStepArchiveMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useIgStepArchiveMutation(baseOptions?: Apollo.MutationHookOptions<IgStepArchiveMutation, IgStepArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgStepArchiveMutation, IgStepArchiveMutationVariables>(IgStepArchiveDocument, options);
      }
export type IgStepArchiveMutationHookResult = ReturnType<typeof useIgStepArchiveMutation>;
export type IgStepArchiveMutationResult = Apollo.MutationResult<IgStepArchiveMutation>;
export type IgStepArchiveMutationOptions = Apollo.BaseMutationOptions<IgStepArchiveMutation, IgStepArchiveMutationVariables>;
export const IgStepCreateDocument = gql`
    mutation IgStepCreate($name: String!, $internalName: String!, $action: String!, $durationInSeconds: Int!, $colour: IgCategoryColour, $questionType: IgQuestionType!, $interviewGuideId: Int!, $order: Int!, $staticQuestionName: String) {
  IgStepCreate(
    name: $name
    internalName: $internalName
    action: $action
    durationInSeconds: $durationInSeconds
    colour: $colour
    questionType: $questionType
    interviewGuideId: $interviewGuideId
    order: $order
    staticQuestionName: $staticQuestionName
  ) {
    ...IgStep
  }
}
    ${IgStepFragmentDoc}`;
export type IgStepCreateMutationFn = Apollo.MutationFunction<IgStepCreateMutation, IgStepCreateMutationVariables>;

/**
 * __useIgStepCreateMutation__
 *
 * To run a mutation, you first call `useIgStepCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useIgStepCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [igStepCreateMutation, { data, loading, error }] = useIgStepCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      internalName: // value for 'internalName'
 *      action: // value for 'action'
 *      durationInSeconds: // value for 'durationInSeconds'
 *      colour: // value for 'colour'
 *      questionType: // value for 'questionType'
 *      interviewGuideId: // value for 'interviewGuideId'
 *      order: // value for 'order'
 *      staticQuestionName: // value for 'staticQuestionName'
 *   },
 * });
 */
export function useIgStepCreateMutation(baseOptions?: Apollo.MutationHookOptions<IgStepCreateMutation, IgStepCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<IgStepCreateMutation, IgStepCreateMutationVariables>(IgStepCreateDocument, options);
      }
export type IgStepCreateMutationHookResult = ReturnType<typeof useIgStepCreateMutation>;
export type IgStepCreateMutationResult = Apollo.MutationResult<IgStepCreateMutation>;
export type IgStepCreateMutationOptions = Apollo.BaseMutationOptions<IgStepCreateMutation, IgStepCreateMutationVariables>;
export const SaveInterviewQuestionContentDocument = gql`
    mutation SaveInterviewQuestionContent($id: Int!, $summary: String!, $feedback: String!, $reasonForQuestion: String!, $mainQuestion: String!, $subQuestions: [String!]!) {
  interviewQuestionContent: InterviewQuestionRoleLevelContentUpdate(
    id: $id
    summary: $summary
    feedback: $feedback
    reasonForQuestion: $reasonForQuestion
    mainQuestion: $mainQuestion
    subQuestions: $subQuestions
  ) {
    id
    softSkill {
      name
    }
    trait {
      name
    }
    softSkillId
    traitId
    classification
    roleLevel
    summary
    feedback
    reasonForQuestion
    mainQuestion
    subQuestions
  }
}
    `;
export type SaveInterviewQuestionContentMutationFn = Apollo.MutationFunction<SaveInterviewQuestionContentMutation, SaveInterviewQuestionContentMutationVariables>;

/**
 * __useSaveInterviewQuestionContentMutation__
 *
 * To run a mutation, you first call `useSaveInterviewQuestionContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveInterviewQuestionContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveInterviewQuestionContentMutation, { data, loading, error }] = useSaveInterviewQuestionContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      summary: // value for 'summary'
 *      feedback: // value for 'feedback'
 *      reasonForQuestion: // value for 'reasonForQuestion'
 *      mainQuestion: // value for 'mainQuestion'
 *      subQuestions: // value for 'subQuestions'
 *   },
 * });
 */
export function useSaveInterviewQuestionContentMutation(baseOptions?: Apollo.MutationHookOptions<SaveInterviewQuestionContentMutation, SaveInterviewQuestionContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveInterviewQuestionContentMutation, SaveInterviewQuestionContentMutationVariables>(SaveInterviewQuestionContentDocument, options);
      }
export type SaveInterviewQuestionContentMutationHookResult = ReturnType<typeof useSaveInterviewQuestionContentMutation>;
export type SaveInterviewQuestionContentMutationResult = Apollo.MutationResult<SaveInterviewQuestionContentMutation>;
export type SaveInterviewQuestionContentMutationOptions = Apollo.BaseMutationOptions<SaveInterviewQuestionContentMutation, SaveInterviewQuestionContentMutationVariables>;
export const SmInviteHiringDocument = gql`
    mutation SmInviteHiring($isCompanyCandidate: Boolean, $doneForsAndProjectManagers: [SmInviteHiringDoneForsAndProjectManagersArgs!]!, $endTime: Date, $includeDeadlineInEmail: Boolean, $locale: SupportedLocale, $sendEmail: Boolean, $stageId: Int!, $type: SmInviteType!) {
  SmInviteHiring(
    isCompanyCandidate: $isCompanyCandidate
    doneForsAndProjectManagers: $doneForsAndProjectManagers
    locale: $locale
    stageId: $stageId
    type: $type
    endTime: $endTime
    includeDeadlineInEmail: $includeDeadlineInEmail
    sendEmail: $sendEmail
  ) {
    candidateId
    stageId
    status
  }
}
    `;
export type SmInviteHiringMutationFn = Apollo.MutationFunction<SmInviteHiringMutation, SmInviteHiringMutationVariables>;

/**
 * __useSmInviteHiringMutation__
 *
 * To run a mutation, you first call `useSmInviteHiringMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmInviteHiringMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smInviteHiringMutation, { data, loading, error }] = useSmInviteHiringMutation({
 *   variables: {
 *      isCompanyCandidate: // value for 'isCompanyCandidate'
 *      doneForsAndProjectManagers: // value for 'doneForsAndProjectManagers'
 *      endTime: // value for 'endTime'
 *      includeDeadlineInEmail: // value for 'includeDeadlineInEmail'
 *      locale: // value for 'locale'
 *      sendEmail: // value for 'sendEmail'
 *      stageId: // value for 'stageId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSmInviteHiringMutation(baseOptions?: Apollo.MutationHookOptions<SmInviteHiringMutation, SmInviteHiringMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SmInviteHiringMutation, SmInviteHiringMutationVariables>(SmInviteHiringDocument, options);
      }
export type SmInviteHiringMutationHookResult = ReturnType<typeof useSmInviteHiringMutation>;
export type SmInviteHiringMutationResult = Apollo.MutationResult<SmInviteHiringMutation>;
export type SmInviteHiringMutationOptions = Apollo.BaseMutationOptions<SmInviteHiringMutation, SmInviteHiringMutationVariables>;
export const SmInviteTrDocument = gql`
    mutation SmInviteTR($isCompanyCandidate: Boolean, $doneByEmails: [String!], $doneForEmails: [String!]!, $endTime: Date, $includeDeadlineInEmail: Boolean, $locale: SupportedLocale, $sendEmail: Boolean, $stageId: Int!, $type: SmInviteType!) {
  SmInviteTR(
    isCompanyCandidate: $isCompanyCandidate
    doneByEmails: $doneByEmails
    doneForEmails: $doneForEmails
    endTime: $endTime
    includeDeadlineInEmail: $includeDeadlineInEmail
    locale: $locale
    sendEmail: $sendEmail
    stageId: $stageId
    type: $type
  ) {
    id
    doneBy
    doneFor
  }
}
    `;
export type SmInviteTrMutationFn = Apollo.MutationFunction<SmInviteTrMutation, SmInviteTrMutationVariables>;

/**
 * __useSmInviteTrMutation__
 *
 * To run a mutation, you first call `useSmInviteTrMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSmInviteTrMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [smInviteTrMutation, { data, loading, error }] = useSmInviteTrMutation({
 *   variables: {
 *      isCompanyCandidate: // value for 'isCompanyCandidate'
 *      doneByEmails: // value for 'doneByEmails'
 *      doneForEmails: // value for 'doneForEmails'
 *      endTime: // value for 'endTime'
 *      includeDeadlineInEmail: // value for 'includeDeadlineInEmail'
 *      locale: // value for 'locale'
 *      sendEmail: // value for 'sendEmail'
 *      stageId: // value for 'stageId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSmInviteTrMutation(baseOptions?: Apollo.MutationHookOptions<SmInviteTrMutation, SmInviteTrMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SmInviteTrMutation, SmInviteTrMutationVariables>(SmInviteTrDocument, options);
      }
export type SmInviteTrMutationHookResult = ReturnType<typeof useSmInviteTrMutation>;
export type SmInviteTrMutationResult = Apollo.MutationResult<SmInviteTrMutation>;
export type SmInviteTrMutationOptions = Apollo.BaseMutationOptions<SmInviteTrMutation, SmInviteTrMutationVariables>;
export const JobRoleCreateOneDocument = gql`
    mutation JobRoleCreateOne($companyId: Int!, $name: String!, $qualifier: String, $roleLevel: ContentRoleLevel!, $family: JobRoleFamily) {
  JobRoleCreateOne(
    companyId: $companyId
    name: $name
    qualifier: $qualifier
    roleLevel: $roleLevel
    family: $family
  ) {
    ...JobRole
  }
}
    ${JobRoleFragmentDoc}`;
export type JobRoleCreateOneMutationFn = Apollo.MutationFunction<JobRoleCreateOneMutation, JobRoleCreateOneMutationVariables>;

/**
 * __useJobRoleCreateOneMutation__
 *
 * To run a mutation, you first call `useJobRoleCreateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobRoleCreateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobRoleCreateOneMutation, { data, loading, error }] = useJobRoleCreateOneMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      name: // value for 'name'
 *      qualifier: // value for 'qualifier'
 *      roleLevel: // value for 'roleLevel'
 *      family: // value for 'family'
 *   },
 * });
 */
export function useJobRoleCreateOneMutation(baseOptions?: Apollo.MutationHookOptions<JobRoleCreateOneMutation, JobRoleCreateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobRoleCreateOneMutation, JobRoleCreateOneMutationVariables>(JobRoleCreateOneDocument, options);
      }
export type JobRoleCreateOneMutationHookResult = ReturnType<typeof useJobRoleCreateOneMutation>;
export type JobRoleCreateOneMutationResult = Apollo.MutationResult<JobRoleCreateOneMutation>;
export type JobRoleCreateOneMutationOptions = Apollo.BaseMutationOptions<JobRoleCreateOneMutation, JobRoleCreateOneMutationVariables>;
export const JobRoleUpdateOneDocument = gql`
    mutation JobRoleUpdateOne($id: Int!, $name: String, $qualifier: String, $roleLevel: ContentRoleLevel, $family: JobRoleFamily) {
  JobRoleUpdateOne(
    id: $id
    name: $name
    qualifier: $qualifier
    roleLevel: $roleLevel
    family: $family
  ) {
    ...JobRole
  }
}
    ${JobRoleFragmentDoc}`;
export type JobRoleUpdateOneMutationFn = Apollo.MutationFunction<JobRoleUpdateOneMutation, JobRoleUpdateOneMutationVariables>;

/**
 * __useJobRoleUpdateOneMutation__
 *
 * To run a mutation, you first call `useJobRoleUpdateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJobRoleUpdateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [jobRoleUpdateOneMutation, { data, loading, error }] = useJobRoleUpdateOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      qualifier: // value for 'qualifier'
 *      roleLevel: // value for 'roleLevel'
 *      family: // value for 'family'
 *   },
 * });
 */
export function useJobRoleUpdateOneMutation(baseOptions?: Apollo.MutationHookOptions<JobRoleUpdateOneMutation, JobRoleUpdateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<JobRoleUpdateOneMutation, JobRoleUpdateOneMutationVariables>(JobRoleUpdateOneDocument, options);
      }
export type JobRoleUpdateOneMutationHookResult = ReturnType<typeof useJobRoleUpdateOneMutation>;
export type JobRoleUpdateOneMutationResult = Apollo.MutationResult<JobRoleUpdateOneMutation>;
export type JobRoleUpdateOneMutationOptions = Apollo.BaseMutationOptions<JobRoleUpdateOneMutation, JobRoleUpdateOneMutationVariables>;
export const ProjectSetCompanySignatureDocument = gql`
    mutation ProjectSetCompanySignature($companySignatureId: Int, $projectId: Int!) {
  Project: ProjectSetCompanySignature(
    companySignatureId: $companySignatureId
    projectId: $projectId
  ) {
    id
    companySignatureId
  }
}
    `;
export type ProjectSetCompanySignatureMutationFn = Apollo.MutationFunction<ProjectSetCompanySignatureMutation, ProjectSetCompanySignatureMutationVariables>;

/**
 * __useProjectSetCompanySignatureMutation__
 *
 * To run a mutation, you first call `useProjectSetCompanySignatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectSetCompanySignatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectSetCompanySignatureMutation, { data, loading, error }] = useProjectSetCompanySignatureMutation({
 *   variables: {
 *      companySignatureId: // value for 'companySignatureId'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useProjectSetCompanySignatureMutation(baseOptions?: Apollo.MutationHookOptions<ProjectSetCompanySignatureMutation, ProjectSetCompanySignatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectSetCompanySignatureMutation, ProjectSetCompanySignatureMutationVariables>(ProjectSetCompanySignatureDocument, options);
      }
export type ProjectSetCompanySignatureMutationHookResult = ReturnType<typeof useProjectSetCompanySignatureMutation>;
export type ProjectSetCompanySignatureMutationResult = Apollo.MutationResult<ProjectSetCompanySignatureMutation>;
export type ProjectSetCompanySignatureMutationOptions = Apollo.BaseMutationOptions<ProjectSetCompanySignatureMutation, ProjectSetCompanySignatureMutationVariables>;
export const EditProjectStateDocument = gql`
    mutation EditProjectState($id: Int!, $state: Boolean!) {
  project: editProjectState(id: $id, state: $state) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type EditProjectStateMutationFn = Apollo.MutationFunction<EditProjectStateMutation, EditProjectStateMutationVariables>;

/**
 * __useEditProjectStateMutation__
 *
 * To run a mutation, you first call `useEditProjectStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectStateMutation, { data, loading, error }] = useEditProjectStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useEditProjectStateMutation(baseOptions?: Apollo.MutationHookOptions<EditProjectStateMutation, EditProjectStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProjectStateMutation, EditProjectStateMutationVariables>(EditProjectStateDocument, options);
      }
export type EditProjectStateMutationHookResult = ReturnType<typeof useEditProjectStateMutation>;
export type EditProjectStateMutationResult = Apollo.MutationResult<EditProjectStateMutation>;
export type EditProjectStateMutationOptions = Apollo.BaseMutationOptions<EditProjectStateMutation, EditProjectStateMutationVariables>;
export const EditProjectArchivingDocument = gql`
    mutation EditProjectArchiving($id: Int!, $state: Boolean!) {
  project: editProjectArchiving(id: $id, state: $state) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type EditProjectArchivingMutationFn = Apollo.MutationFunction<EditProjectArchivingMutation, EditProjectArchivingMutationVariables>;

/**
 * __useEditProjectArchivingMutation__
 *
 * To run a mutation, you first call `useEditProjectArchivingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectArchivingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectArchivingMutation, { data, loading, error }] = useEditProjectArchivingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useEditProjectArchivingMutation(baseOptions?: Apollo.MutationHookOptions<EditProjectArchivingMutation, EditProjectArchivingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProjectArchivingMutation, EditProjectArchivingMutationVariables>(EditProjectArchivingDocument, options);
      }
export type EditProjectArchivingMutationHookResult = ReturnType<typeof useEditProjectArchivingMutation>;
export type EditProjectArchivingMutationResult = Apollo.MutationResult<EditProjectArchivingMutation>;
export type EditProjectArchivingMutationOptions = Apollo.BaseMutationOptions<EditProjectArchivingMutation, EditProjectArchivingMutationVariables>;
export const CreateProjectDocument = gql`
    mutation CreateProject($name: String!, $companyId: Float!, $managers: [CreateProjectManagerInput!], $moduleType: ProjectModuleType, $requireSheetsExport: Boolean, $productSolution: ProductSolution) {
  project: createProject(
    name: $name
    companyId: $companyId
    managers: $managers
    moduleType: $moduleType
    requireSheetsExport: $requireSheetsExport
    productSolution: $productSolution
  ) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type CreateProjectMutationFn = Apollo.MutationFunction<CreateProjectMutation, CreateProjectMutationVariables>;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      managers: // value for 'managers'
 *      moduleType: // value for 'moduleType'
 *      requireSheetsExport: // value for 'requireSheetsExport'
 *      productSolution: // value for 'productSolution'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: Apollo.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, options);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = Apollo.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = Apollo.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const EditProjectDocument = gql`
    mutation EditProject($id: Int!, $name: String!, $companyId: Float!, $managers: [CreateProjectManagerInput!], $requireSheetsExport: Boolean, $locales: [SupportedLocale!], $productSolution: ProductSolution) {
  project: editProject(
    id: $id
    name: $name
    companyId: $companyId
    managers: $managers
    requireSheetsExport: $requireSheetsExport
    locales: $locales
    productSolution: $productSolution
  ) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;
export type EditProjectMutationFn = Apollo.MutationFunction<EditProjectMutation, EditProjectMutationVariables>;

/**
 * __useEditProjectMutation__
 *
 * To run a mutation, you first call `useEditProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editProjectMutation, { data, loading, error }] = useEditProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      companyId: // value for 'companyId'
 *      managers: // value for 'managers'
 *      requireSheetsExport: // value for 'requireSheetsExport'
 *      locales: // value for 'locales'
 *      productSolution: // value for 'productSolution'
 *   },
 * });
 */
export function useEditProjectMutation(baseOptions?: Apollo.MutationHookOptions<EditProjectMutation, EditProjectMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditProjectMutation, EditProjectMutationVariables>(EditProjectDocument, options);
      }
export type EditProjectMutationHookResult = ReturnType<typeof useEditProjectMutation>;
export type EditProjectMutationResult = Apollo.MutationResult<EditProjectMutation>;
export type EditProjectMutationOptions = Apollo.BaseMutationOptions<EditProjectMutation, EditProjectMutationVariables>;
export const SetProjectCobrandingDocument = gql`
    mutation SetProjectCobranding($projectId: Int!, $cobrandingEnabled: Boolean!) {
  setProjectCobranding(id: $projectId, cobrandingEnabled: $cobrandingEnabled)
}
    `;
export type SetProjectCobrandingMutationFn = Apollo.MutationFunction<SetProjectCobrandingMutation, SetProjectCobrandingMutationVariables>;

/**
 * __useSetProjectCobrandingMutation__
 *
 * To run a mutation, you first call `useSetProjectCobrandingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetProjectCobrandingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setProjectCobrandingMutation, { data, loading, error }] = useSetProjectCobrandingMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      cobrandingEnabled: // value for 'cobrandingEnabled'
 *   },
 * });
 */
export function useSetProjectCobrandingMutation(baseOptions?: Apollo.MutationHookOptions<SetProjectCobrandingMutation, SetProjectCobrandingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetProjectCobrandingMutation, SetProjectCobrandingMutationVariables>(SetProjectCobrandingDocument, options);
      }
export type SetProjectCobrandingMutationHookResult = ReturnType<typeof useSetProjectCobrandingMutation>;
export type SetProjectCobrandingMutationResult = Apollo.MutationResult<SetProjectCobrandingMutation>;
export type SetProjectCobrandingMutationOptions = Apollo.BaseMutationOptions<SetProjectCobrandingMutation, SetProjectCobrandingMutationVariables>;
export const ProjectCollaboratorsCreateManyDocument = gql`
    mutation ProjectCollaboratorsCreateMany($collaborators: [ProjectCollaboratorCreateOneArgs!]!) {
  collaborators: ProjectCollaboratorsCreateMany(collaborators: $collaborators) {
    id
    userId
    groupId
    actorRole
    projectId
  }
}
    `;
export type ProjectCollaboratorsCreateManyMutationFn = Apollo.MutationFunction<ProjectCollaboratorsCreateManyMutation, ProjectCollaboratorsCreateManyMutationVariables>;

/**
 * __useProjectCollaboratorsCreateManyMutation__
 *
 * To run a mutation, you first call `useProjectCollaboratorsCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectCollaboratorsCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectCollaboratorsCreateManyMutation, { data, loading, error }] = useProjectCollaboratorsCreateManyMutation({
 *   variables: {
 *      collaborators: // value for 'collaborators'
 *   },
 * });
 */
export function useProjectCollaboratorsCreateManyMutation(baseOptions?: Apollo.MutationHookOptions<ProjectCollaboratorsCreateManyMutation, ProjectCollaboratorsCreateManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectCollaboratorsCreateManyMutation, ProjectCollaboratorsCreateManyMutationVariables>(ProjectCollaboratorsCreateManyDocument, options);
      }
export type ProjectCollaboratorsCreateManyMutationHookResult = ReturnType<typeof useProjectCollaboratorsCreateManyMutation>;
export type ProjectCollaboratorsCreateManyMutationResult = Apollo.MutationResult<ProjectCollaboratorsCreateManyMutation>;
export type ProjectCollaboratorsCreateManyMutationOptions = Apollo.BaseMutationOptions<ProjectCollaboratorsCreateManyMutation, ProjectCollaboratorsCreateManyMutationVariables>;
export const ProjectCollaboratorsUpdateRoleDocument = gql`
    mutation ProjectCollaboratorsUpdateRole($actorRole: ActorRole, $collaboratorId: Int!) {
  update: ProjectCollaboratorsUpdateRole(
    newActorRole: $actorRole
    projectTeamMemberId: $collaboratorId
  ) {
    id
    projectId
    userId
    groupId
    actorRole
  }
}
    `;
export type ProjectCollaboratorsUpdateRoleMutationFn = Apollo.MutationFunction<ProjectCollaboratorsUpdateRoleMutation, ProjectCollaboratorsUpdateRoleMutationVariables>;

/**
 * __useProjectCollaboratorsUpdateRoleMutation__
 *
 * To run a mutation, you first call `useProjectCollaboratorsUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectCollaboratorsUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectCollaboratorsUpdateRoleMutation, { data, loading, error }] = useProjectCollaboratorsUpdateRoleMutation({
 *   variables: {
 *      actorRole: // value for 'actorRole'
 *      collaboratorId: // value for 'collaboratorId'
 *   },
 * });
 */
export function useProjectCollaboratorsUpdateRoleMutation(baseOptions?: Apollo.MutationHookOptions<ProjectCollaboratorsUpdateRoleMutation, ProjectCollaboratorsUpdateRoleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectCollaboratorsUpdateRoleMutation, ProjectCollaboratorsUpdateRoleMutationVariables>(ProjectCollaboratorsUpdateRoleDocument, options);
      }
export type ProjectCollaboratorsUpdateRoleMutationHookResult = ReturnType<typeof useProjectCollaboratorsUpdateRoleMutation>;
export type ProjectCollaboratorsUpdateRoleMutationResult = Apollo.MutationResult<ProjectCollaboratorsUpdateRoleMutation>;
export type ProjectCollaboratorsUpdateRoleMutationOptions = Apollo.BaseMutationOptions<ProjectCollaboratorsUpdateRoleMutation, ProjectCollaboratorsUpdateRoleMutationVariables>;
export const ProjectCollaboratorsDeleteManyDocument = gql`
    mutation ProjectCollaboratorsDeleteMany($collaborators: [Int!]!) {
  deleted: ProjectCollaboratorsDeleteMany(collaborators: $collaborators) {
    status
    error
  }
}
    `;
export type ProjectCollaboratorsDeleteManyMutationFn = Apollo.MutationFunction<ProjectCollaboratorsDeleteManyMutation, ProjectCollaboratorsDeleteManyMutationVariables>;

/**
 * __useProjectCollaboratorsDeleteManyMutation__
 *
 * To run a mutation, you first call `useProjectCollaboratorsDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectCollaboratorsDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectCollaboratorsDeleteManyMutation, { data, loading, error }] = useProjectCollaboratorsDeleteManyMutation({
 *   variables: {
 *      collaborators: // value for 'collaborators'
 *   },
 * });
 */
export function useProjectCollaboratorsDeleteManyMutation(baseOptions?: Apollo.MutationHookOptions<ProjectCollaboratorsDeleteManyMutation, ProjectCollaboratorsDeleteManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectCollaboratorsDeleteManyMutation, ProjectCollaboratorsDeleteManyMutationVariables>(ProjectCollaboratorsDeleteManyDocument, options);
      }
export type ProjectCollaboratorsDeleteManyMutationHookResult = ReturnType<typeof useProjectCollaboratorsDeleteManyMutation>;
export type ProjectCollaboratorsDeleteManyMutationResult = Apollo.MutationResult<ProjectCollaboratorsDeleteManyMutation>;
export type ProjectCollaboratorsDeleteManyMutationOptions = Apollo.BaseMutationOptions<ProjectCollaboratorsDeleteManyMutation, ProjectCollaboratorsDeleteManyMutationVariables>;
export const ProjectJobRoleCreateOneDocument = gql`
    mutation ProjectJobRoleCreateOne($jobRoleId: Int!, $projectId: Int!, $skillsProfileId: Int!) {
  ProjectJobRoleCreateOne(
    jobRoleId: $jobRoleId
    projectId: $projectId
    skillsProfileId: $skillsProfileId
  ) {
    ...ProjectJobRole
  }
}
    ${ProjectJobRoleFragmentDoc}`;
export type ProjectJobRoleCreateOneMutationFn = Apollo.MutationFunction<ProjectJobRoleCreateOneMutation, ProjectJobRoleCreateOneMutationVariables>;

/**
 * __useProjectJobRoleCreateOneMutation__
 *
 * To run a mutation, you first call `useProjectJobRoleCreateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectJobRoleCreateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectJobRoleCreateOneMutation, { data, loading, error }] = useProjectJobRoleCreateOneMutation({
 *   variables: {
 *      jobRoleId: // value for 'jobRoleId'
 *      projectId: // value for 'projectId'
 *      skillsProfileId: // value for 'skillsProfileId'
 *   },
 * });
 */
export function useProjectJobRoleCreateOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectJobRoleCreateOneMutation, ProjectJobRoleCreateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectJobRoleCreateOneMutation, ProjectJobRoleCreateOneMutationVariables>(ProjectJobRoleCreateOneDocument, options);
      }
export type ProjectJobRoleCreateOneMutationHookResult = ReturnType<typeof useProjectJobRoleCreateOneMutation>;
export type ProjectJobRoleCreateOneMutationResult = Apollo.MutationResult<ProjectJobRoleCreateOneMutation>;
export type ProjectJobRoleCreateOneMutationOptions = Apollo.BaseMutationOptions<ProjectJobRoleCreateOneMutation, ProjectJobRoleCreateOneMutationVariables>;
export const ProjectJobRoleDeleteOneDocument = gql`
    mutation ProjectJobRoleDeleteOne($createdAt: Date, $deletedAt: Date, $id: Int, $jobRoleId: Int, $projectId: Int, $skillsProfileId: Int, $subId: String, $updatedAt: Date) {
  ProjectJobRoleDeleteOne(
    createdAt: $createdAt
    deletedAt: $deletedAt
    id: $id
    jobRoleId: $jobRoleId
    projectId: $projectId
    skillsProfileId: $skillsProfileId
    subId: $subId
    updatedAt: $updatedAt
  ) {
    affected
  }
}
    `;
export type ProjectJobRoleDeleteOneMutationFn = Apollo.MutationFunction<ProjectJobRoleDeleteOneMutation, ProjectJobRoleDeleteOneMutationVariables>;

/**
 * __useProjectJobRoleDeleteOneMutation__
 *
 * To run a mutation, you first call `useProjectJobRoleDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectJobRoleDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectJobRoleDeleteOneMutation, { data, loading, error }] = useProjectJobRoleDeleteOneMutation({
 *   variables: {
 *      createdAt: // value for 'createdAt'
 *      deletedAt: // value for 'deletedAt'
 *      id: // value for 'id'
 *      jobRoleId: // value for 'jobRoleId'
 *      projectId: // value for 'projectId'
 *      skillsProfileId: // value for 'skillsProfileId'
 *      subId: // value for 'subId'
 *      updatedAt: // value for 'updatedAt'
 *   },
 * });
 */
export function useProjectJobRoleDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectJobRoleDeleteOneMutation, ProjectJobRoleDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectJobRoleDeleteOneMutation, ProjectJobRoleDeleteOneMutationVariables>(ProjectJobRoleDeleteOneDocument, options);
      }
export type ProjectJobRoleDeleteOneMutationHookResult = ReturnType<typeof useProjectJobRoleDeleteOneMutation>;
export type ProjectJobRoleDeleteOneMutationResult = Apollo.MutationResult<ProjectJobRoleDeleteOneMutation>;
export type ProjectJobRoleDeleteOneMutationOptions = Apollo.BaseMutationOptions<ProjectJobRoleDeleteOneMutation, ProjectJobRoleDeleteOneMutationVariables>;
export const ProjectRequestDeleteOneDocument = gql`
    mutation ProjectRequestDeleteOne($id: Int!) {
  ProjectRequestDeleteOne(id: $id) {
    affected
  }
}
    `;
export type ProjectRequestDeleteOneMutationFn = Apollo.MutationFunction<ProjectRequestDeleteOneMutation, ProjectRequestDeleteOneMutationVariables>;

/**
 * __useProjectRequestDeleteOneMutation__
 *
 * To run a mutation, you first call `useProjectRequestDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestDeleteOneMutation, { data, loading, error }] = useProjectRequestDeleteOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectRequestDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestDeleteOneMutation, ProjectRequestDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestDeleteOneMutation, ProjectRequestDeleteOneMutationVariables>(ProjectRequestDeleteOneDocument, options);
      }
export type ProjectRequestDeleteOneMutationHookResult = ReturnType<typeof useProjectRequestDeleteOneMutation>;
export type ProjectRequestDeleteOneMutationResult = Apollo.MutationResult<ProjectRequestDeleteOneMutation>;
export type ProjectRequestDeleteOneMutationOptions = Apollo.BaseMutationOptions<ProjectRequestDeleteOneMutation, ProjectRequestDeleteOneMutationVariables>;
export const ProjectRequestCreateOneDocument = gql`
    mutation ProjectRequestCreateOne($companyId: Int!, $creatorId: Int!, $projectName: String!, $status: ProjectRequestStatus) {
  ProjectRequestCreateOne(
    companyId: $companyId
    creatorId: $creatorId
    projectName: $projectName
    status: $status
  ) {
    ...ProjectRequest
  }
}
    ${ProjectRequestFragmentDoc}`;
export type ProjectRequestCreateOneMutationFn = Apollo.MutationFunction<ProjectRequestCreateOneMutation, ProjectRequestCreateOneMutationVariables>;

/**
 * __useProjectRequestCreateOneMutation__
 *
 * To run a mutation, you first call `useProjectRequestCreateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestCreateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestCreateOneMutation, { data, loading, error }] = useProjectRequestCreateOneMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      creatorId: // value for 'creatorId'
 *      projectName: // value for 'projectName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useProjectRequestCreateOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestCreateOneMutation, ProjectRequestCreateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestCreateOneMutation, ProjectRequestCreateOneMutationVariables>(ProjectRequestCreateOneDocument, options);
      }
export type ProjectRequestCreateOneMutationHookResult = ReturnType<typeof useProjectRequestCreateOneMutation>;
export type ProjectRequestCreateOneMutationResult = Apollo.MutationResult<ProjectRequestCreateOneMutation>;
export type ProjectRequestCreateOneMutationOptions = Apollo.BaseMutationOptions<ProjectRequestCreateOneMutation, ProjectRequestCreateOneMutationVariables>;
export const ProjectRequestUpdateOneDocument = gql`
    mutation ProjectRequestUpdateOne($approvalRequestDate: DateTime, $companyId: Int, $creatorId: Int, $id: Int!, $projectId: Int, $projectName: String, $status: ProjectRequestStatus) {
  ProjectRequestUpdateOne(
    approvalRequestDate: $approvalRequestDate
    companyId: $companyId
    creatorId: $creatorId
    id: $id
    projectId: $projectId
    projectName: $projectName
    status: $status
  ) {
    ...ProjectRequest
  }
}
    ${ProjectRequestFragmentDoc}`;
export type ProjectRequestUpdateOneMutationFn = Apollo.MutationFunction<ProjectRequestUpdateOneMutation, ProjectRequestUpdateOneMutationVariables>;

/**
 * __useProjectRequestUpdateOneMutation__
 *
 * To run a mutation, you first call `useProjectRequestUpdateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestUpdateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestUpdateOneMutation, { data, loading, error }] = useProjectRequestUpdateOneMutation({
 *   variables: {
 *      approvalRequestDate: // value for 'approvalRequestDate'
 *      companyId: // value for 'companyId'
 *      creatorId: // value for 'creatorId'
 *      id: // value for 'id'
 *      projectId: // value for 'projectId'
 *      projectName: // value for 'projectName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useProjectRequestUpdateOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestUpdateOneMutation, ProjectRequestUpdateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestUpdateOneMutation, ProjectRequestUpdateOneMutationVariables>(ProjectRequestUpdateOneDocument, options);
      }
export type ProjectRequestUpdateOneMutationHookResult = ReturnType<typeof useProjectRequestUpdateOneMutation>;
export type ProjectRequestUpdateOneMutationResult = Apollo.MutationResult<ProjectRequestUpdateOneMutation>;
export type ProjectRequestUpdateOneMutationOptions = Apollo.BaseMutationOptions<ProjectRequestUpdateOneMutation, ProjectRequestUpdateOneMutationVariables>;
export const ProjectRequestFileUploadDocument = gql`
    mutation ProjectRequestFileUpload($args: ProjectRequestFileUploadArgs!, $file: Upload!) {
  ProjectRequestFileUpload(args: $args, file: $file)
}
    `;
export type ProjectRequestFileUploadMutationFn = Apollo.MutationFunction<ProjectRequestFileUploadMutation, ProjectRequestFileUploadMutationVariables>;

/**
 * __useProjectRequestFileUploadMutation__
 *
 * To run a mutation, you first call `useProjectRequestFileUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestFileUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestFileUploadMutation, { data, loading, error }] = useProjectRequestFileUploadMutation({
 *   variables: {
 *      args: // value for 'args'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useProjectRequestFileUploadMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestFileUploadMutation, ProjectRequestFileUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestFileUploadMutation, ProjectRequestFileUploadMutationVariables>(ProjectRequestFileUploadDocument, options);
      }
export type ProjectRequestFileUploadMutationHookResult = ReturnType<typeof useProjectRequestFileUploadMutation>;
export type ProjectRequestFileUploadMutationResult = Apollo.MutationResult<ProjectRequestFileUploadMutation>;
export type ProjectRequestFileUploadMutationOptions = Apollo.BaseMutationOptions<ProjectRequestFileUploadMutation, ProjectRequestFileUploadMutationVariables>;
export const ProjectRequestFileUploadDeleteOneDocument = gql`
    mutation ProjectRequestFileUploadDeleteOne($id: Int!) {
  ProjectRequestFileUploadDeleteOne(id: $id) {
    affected
  }
}
    `;
export type ProjectRequestFileUploadDeleteOneMutationFn = Apollo.MutationFunction<ProjectRequestFileUploadDeleteOneMutation, ProjectRequestFileUploadDeleteOneMutationVariables>;

/**
 * __useProjectRequestFileUploadDeleteOneMutation__
 *
 * To run a mutation, you first call `useProjectRequestFileUploadDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestFileUploadDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestFileUploadDeleteOneMutation, { data, loading, error }] = useProjectRequestFileUploadDeleteOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectRequestFileUploadDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestFileUploadDeleteOneMutation, ProjectRequestFileUploadDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestFileUploadDeleteOneMutation, ProjectRequestFileUploadDeleteOneMutationVariables>(ProjectRequestFileUploadDeleteOneDocument, options);
      }
export type ProjectRequestFileUploadDeleteOneMutationHookResult = ReturnType<typeof useProjectRequestFileUploadDeleteOneMutation>;
export type ProjectRequestFileUploadDeleteOneMutationResult = Apollo.MutationResult<ProjectRequestFileUploadDeleteOneMutation>;
export type ProjectRequestFileUploadDeleteOneMutationOptions = Apollo.BaseMutationOptions<ProjectRequestFileUploadDeleteOneMutation, ProjectRequestFileUploadDeleteOneMutationVariables>;
export const ProjectRequestRequestJobSpecificationDocument = gql`
    mutation ProjectRequestRequestJobSpecification($id: Int!, $projectRequestUserIds: [Int!]!) {
  ProjectRequestRequestJobSpecification(
    id: $id
    projectRequestUserIds: $projectRequestUserIds
  )
}
    `;
export type ProjectRequestRequestJobSpecificationMutationFn = Apollo.MutationFunction<ProjectRequestRequestJobSpecificationMutation, ProjectRequestRequestJobSpecificationMutationVariables>;

/**
 * __useProjectRequestRequestJobSpecificationMutation__
 *
 * To run a mutation, you first call `useProjectRequestRequestJobSpecificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestRequestJobSpecificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestRequestJobSpecificationMutation, { data, loading, error }] = useProjectRequestRequestJobSpecificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectRequestUserIds: // value for 'projectRequestUserIds'
 *   },
 * });
 */
export function useProjectRequestRequestJobSpecificationMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestRequestJobSpecificationMutation, ProjectRequestRequestJobSpecificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestRequestJobSpecificationMutation, ProjectRequestRequestJobSpecificationMutationVariables>(ProjectRequestRequestJobSpecificationDocument, options);
      }
export type ProjectRequestRequestJobSpecificationMutationHookResult = ReturnType<typeof useProjectRequestRequestJobSpecificationMutation>;
export type ProjectRequestRequestJobSpecificationMutationResult = Apollo.MutationResult<ProjectRequestRequestJobSpecificationMutation>;
export type ProjectRequestRequestJobSpecificationMutationOptions = Apollo.BaseMutationOptions<ProjectRequestRequestJobSpecificationMutation, ProjectRequestRequestJobSpecificationMutationVariables>;
export const ProjectRequestManagerRequestSurveyDocument = gql`
    mutation ProjectRequestManagerRequestSurvey($id: Int!, $projectRequestUserIds: [Int!]!, $surveyLink: String!) {
  ProjectRequestRequestManagerSurvey(
    id: $id
    projectRequestUserIds: $projectRequestUserIds
    surveyLink: $surveyLink
  )
}
    `;
export type ProjectRequestManagerRequestSurveyMutationFn = Apollo.MutationFunction<ProjectRequestManagerRequestSurveyMutation, ProjectRequestManagerRequestSurveyMutationVariables>;

/**
 * __useProjectRequestManagerRequestSurveyMutation__
 *
 * To run a mutation, you first call `useProjectRequestManagerRequestSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestManagerRequestSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestManagerRequestSurveyMutation, { data, loading, error }] = useProjectRequestManagerRequestSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      projectRequestUserIds: // value for 'projectRequestUserIds'
 *      surveyLink: // value for 'surveyLink'
 *   },
 * });
 */
export function useProjectRequestManagerRequestSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestManagerRequestSurveyMutation, ProjectRequestManagerRequestSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestManagerRequestSurveyMutation, ProjectRequestManagerRequestSurveyMutationVariables>(ProjectRequestManagerRequestSurveyDocument, options);
      }
export type ProjectRequestManagerRequestSurveyMutationHookResult = ReturnType<typeof useProjectRequestManagerRequestSurveyMutation>;
export type ProjectRequestManagerRequestSurveyMutationResult = Apollo.MutationResult<ProjectRequestManagerRequestSurveyMutation>;
export type ProjectRequestManagerRequestSurveyMutationOptions = Apollo.BaseMutationOptions<ProjectRequestManagerRequestSurveyMutation, ProjectRequestManagerRequestSurveyMutationVariables>;
export const ProjectRequestSoftDeleteOneDocument = gql`
    mutation ProjectRequestSoftDeleteOne($approvalRequestDate: DateTime, $companyId: Int, $creatorId: Int, $id: Int, $projectName: String, $status: ProjectRequestStatus) {
  ProjectRequestSoftDeleteOne(
    approvalRequestDate: $approvalRequestDate
    companyId: $companyId
    creatorId: $creatorId
    id: $id
    projectName: $projectName
    status: $status
  ) {
    affected
  }
}
    `;
export type ProjectRequestSoftDeleteOneMutationFn = Apollo.MutationFunction<ProjectRequestSoftDeleteOneMutation, ProjectRequestSoftDeleteOneMutationVariables>;

/**
 * __useProjectRequestSoftDeleteOneMutation__
 *
 * To run a mutation, you first call `useProjectRequestSoftDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestSoftDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestSoftDeleteOneMutation, { data, loading, error }] = useProjectRequestSoftDeleteOneMutation({
 *   variables: {
 *      approvalRequestDate: // value for 'approvalRequestDate'
 *      companyId: // value for 'companyId'
 *      creatorId: // value for 'creatorId'
 *      id: // value for 'id'
 *      projectName: // value for 'projectName'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useProjectRequestSoftDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestSoftDeleteOneMutation, ProjectRequestSoftDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestSoftDeleteOneMutation, ProjectRequestSoftDeleteOneMutationVariables>(ProjectRequestSoftDeleteOneDocument, options);
      }
export type ProjectRequestSoftDeleteOneMutationHookResult = ReturnType<typeof useProjectRequestSoftDeleteOneMutation>;
export type ProjectRequestSoftDeleteOneMutationResult = Apollo.MutationResult<ProjectRequestSoftDeleteOneMutation>;
export type ProjectRequestSoftDeleteOneMutationOptions = Apollo.BaseMutationOptions<ProjectRequestSoftDeleteOneMutation, ProjectRequestSoftDeleteOneMutationVariables>;
export const ProjectRequestRestoreOneDocument = gql`
    mutation ProjectRequestRestoreOne($approvalRequestDate: DateTime, $companyId: Int, $creatorId: Int, $id: Int, $projectName: String, $relationDepth: RelationDepth = FULL, $status: ProjectRequestStatus, $withDeleted: WithDeletedChoice = ONLY_NOT_ARCHIVED) {
  ProjectRequestRestoreOne(
    approvalRequestDate: $approvalRequestDate
    companyId: $companyId
    creatorId: $creatorId
    id: $id
    projectName: $projectName
    relationDepth: $relationDepth
    status: $status
    withDeleted: $withDeleted
  ) {
    ...ProjectRequest
  }
}
    ${ProjectRequestFragmentDoc}`;
export type ProjectRequestRestoreOneMutationFn = Apollo.MutationFunction<ProjectRequestRestoreOneMutation, ProjectRequestRestoreOneMutationVariables>;

/**
 * __useProjectRequestRestoreOneMutation__
 *
 * To run a mutation, you first call `useProjectRequestRestoreOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestRestoreOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestRestoreOneMutation, { data, loading, error }] = useProjectRequestRestoreOneMutation({
 *   variables: {
 *      approvalRequestDate: // value for 'approvalRequestDate'
 *      companyId: // value for 'companyId'
 *      creatorId: // value for 'creatorId'
 *      id: // value for 'id'
 *      projectName: // value for 'projectName'
 *      relationDepth: // value for 'relationDepth'
 *      status: // value for 'status'
 *      withDeleted: // value for 'withDeleted'
 *   },
 * });
 */
export function useProjectRequestRestoreOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestRestoreOneMutation, ProjectRequestRestoreOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestRestoreOneMutation, ProjectRequestRestoreOneMutationVariables>(ProjectRequestRestoreOneDocument, options);
      }
export type ProjectRequestRestoreOneMutationHookResult = ReturnType<typeof useProjectRequestRestoreOneMutation>;
export type ProjectRequestRestoreOneMutationResult = Apollo.MutationResult<ProjectRequestRestoreOneMutation>;
export type ProjectRequestRestoreOneMutationOptions = Apollo.BaseMutationOptions<ProjectRequestRestoreOneMutation, ProjectRequestRestoreOneMutationVariables>;
export const ProjectRequestUpdateManagerSurveyDocument = gql`
    mutation ProjectRequestUpdateManagerSurvey($id: Int!, $managerSurveyId: String!) {
  ProjectRequestUpdateManagerSurvey(id: $id, managerSurveyId: $managerSurveyId) {
    ...ProjectRequest
  }
}
    ${ProjectRequestFragmentDoc}`;
export type ProjectRequestUpdateManagerSurveyMutationFn = Apollo.MutationFunction<ProjectRequestUpdateManagerSurveyMutation, ProjectRequestUpdateManagerSurveyMutationVariables>;

/**
 * __useProjectRequestUpdateManagerSurveyMutation__
 *
 * To run a mutation, you first call `useProjectRequestUpdateManagerSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestUpdateManagerSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestUpdateManagerSurveyMutation, { data, loading, error }] = useProjectRequestUpdateManagerSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      managerSurveyId: // value for 'managerSurveyId'
 *   },
 * });
 */
export function useProjectRequestUpdateManagerSurveyMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestUpdateManagerSurveyMutation, ProjectRequestUpdateManagerSurveyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestUpdateManagerSurveyMutation, ProjectRequestUpdateManagerSurveyMutationVariables>(ProjectRequestUpdateManagerSurveyDocument, options);
      }
export type ProjectRequestUpdateManagerSurveyMutationHookResult = ReturnType<typeof useProjectRequestUpdateManagerSurveyMutation>;
export type ProjectRequestUpdateManagerSurveyMutationResult = Apollo.MutationResult<ProjectRequestUpdateManagerSurveyMutation>;
export type ProjectRequestUpdateManagerSurveyMutationOptions = Apollo.BaseMutationOptions<ProjectRequestUpdateManagerSurveyMutation, ProjectRequestUpdateManagerSurveyMutationVariables>;
export const ProjectRequestUserDeleteOneDocument = gql`
    mutation ProjectRequestUserDeleteOne($id: Int!) {
  ProjectRequestUserDeleteOne(id: $id) {
    affected
  }
}
    `;
export type ProjectRequestUserDeleteOneMutationFn = Apollo.MutationFunction<ProjectRequestUserDeleteOneMutation, ProjectRequestUserDeleteOneMutationVariables>;

/**
 * __useProjectRequestUserDeleteOneMutation__
 *
 * To run a mutation, you first call `useProjectRequestUserDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestUserDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestUserDeleteOneMutation, { data, loading, error }] = useProjectRequestUserDeleteOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectRequestUserDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestUserDeleteOneMutation, ProjectRequestUserDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestUserDeleteOneMutation, ProjectRequestUserDeleteOneMutationVariables>(ProjectRequestUserDeleteOneDocument, options);
      }
export type ProjectRequestUserDeleteOneMutationHookResult = ReturnType<typeof useProjectRequestUserDeleteOneMutation>;
export type ProjectRequestUserDeleteOneMutationResult = Apollo.MutationResult<ProjectRequestUserDeleteOneMutation>;
export type ProjectRequestUserDeleteOneMutationOptions = Apollo.BaseMutationOptions<ProjectRequestUserDeleteOneMutation, ProjectRequestUserDeleteOneMutationVariables>;
export const ProjectRequestUserCreateManyDocument = gql`
    mutation ProjectRequestUserCreateMany($dto: [IProjectRequestUserCreateOneWithEmployeeCheckArgs!]!) {
  ProjectRequestUserCreateMany(dto: $dto) {
    ...ProjectRequestUserModel
  }
}
    ${ProjectRequestUserModelFragmentDoc}`;
export type ProjectRequestUserCreateManyMutationFn = Apollo.MutationFunction<ProjectRequestUserCreateManyMutation, ProjectRequestUserCreateManyMutationVariables>;

/**
 * __useProjectRequestUserCreateManyMutation__
 *
 * To run a mutation, you first call `useProjectRequestUserCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestUserCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestUserCreateManyMutation, { data, loading, error }] = useProjectRequestUserCreateManyMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useProjectRequestUserCreateManyMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestUserCreateManyMutation, ProjectRequestUserCreateManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestUserCreateManyMutation, ProjectRequestUserCreateManyMutationVariables>(ProjectRequestUserCreateManyDocument, options);
      }
export type ProjectRequestUserCreateManyMutationHookResult = ReturnType<typeof useProjectRequestUserCreateManyMutation>;
export type ProjectRequestUserCreateManyMutationResult = Apollo.MutationResult<ProjectRequestUserCreateManyMutation>;
export type ProjectRequestUserCreateManyMutationOptions = Apollo.BaseMutationOptions<ProjectRequestUserCreateManyMutation, ProjectRequestUserCreateManyMutationVariables>;
export const ProjectRequestUserApprovalCreateManyDocument = gql`
    mutation ProjectRequestUserApprovalCreateMany($dto: [IProjectRequestUserApprovalCreateOneArgs!]!) {
  ProjectRequestUserApprovalCreateMany(dto: $dto) {
    ...ProjectRequestUserApproval
  }
}
    ${ProjectRequestUserApprovalFragmentDoc}`;
export type ProjectRequestUserApprovalCreateManyMutationFn = Apollo.MutationFunction<ProjectRequestUserApprovalCreateManyMutation, ProjectRequestUserApprovalCreateManyMutationVariables>;

/**
 * __useProjectRequestUserApprovalCreateManyMutation__
 *
 * To run a mutation, you first call `useProjectRequestUserApprovalCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestUserApprovalCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestUserApprovalCreateManyMutation, { data, loading, error }] = useProjectRequestUserApprovalCreateManyMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useProjectRequestUserApprovalCreateManyMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestUserApprovalCreateManyMutation, ProjectRequestUserApprovalCreateManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestUserApprovalCreateManyMutation, ProjectRequestUserApprovalCreateManyMutationVariables>(ProjectRequestUserApprovalCreateManyDocument, options);
      }
export type ProjectRequestUserApprovalCreateManyMutationHookResult = ReturnType<typeof useProjectRequestUserApprovalCreateManyMutation>;
export type ProjectRequestUserApprovalCreateManyMutationResult = Apollo.MutationResult<ProjectRequestUserApprovalCreateManyMutation>;
export type ProjectRequestUserApprovalCreateManyMutationOptions = Apollo.BaseMutationOptions<ProjectRequestUserApprovalCreateManyMutation, ProjectRequestUserApprovalCreateManyMutationVariables>;
export const ProjectRequestUserApprovalUpdateManyDocument = gql`
    mutation ProjectRequestUserApprovalUpdateMany($dto: [IProjectRequestUserApprovalUpdateOneArgs!]!) {
  ProjectRequestUserApprovalUpdateMany(dto: $dto) {
    ...ProjectRequestUserApproval
  }
}
    ${ProjectRequestUserApprovalFragmentDoc}`;
export type ProjectRequestUserApprovalUpdateManyMutationFn = Apollo.MutationFunction<ProjectRequestUserApprovalUpdateManyMutation, ProjectRequestUserApprovalUpdateManyMutationVariables>;

/**
 * __useProjectRequestUserApprovalUpdateManyMutation__
 *
 * To run a mutation, you first call `useProjectRequestUserApprovalUpdateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestUserApprovalUpdateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectRequestUserApprovalUpdateManyMutation, { data, loading, error }] = useProjectRequestUserApprovalUpdateManyMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useProjectRequestUserApprovalUpdateManyMutation(baseOptions?: Apollo.MutationHookOptions<ProjectRequestUserApprovalUpdateManyMutation, ProjectRequestUserApprovalUpdateManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectRequestUserApprovalUpdateManyMutation, ProjectRequestUserApprovalUpdateManyMutationVariables>(ProjectRequestUserApprovalUpdateManyDocument, options);
      }
export type ProjectRequestUserApprovalUpdateManyMutationHookResult = ReturnType<typeof useProjectRequestUserApprovalUpdateManyMutation>;
export type ProjectRequestUserApprovalUpdateManyMutationResult = Apollo.MutationResult<ProjectRequestUserApprovalUpdateManyMutation>;
export type ProjectRequestUserApprovalUpdateManyMutationOptions = Apollo.BaseMutationOptions<ProjectRequestUserApprovalUpdateManyMutation, ProjectRequestUserApprovalUpdateManyMutationVariables>;
export const ProjectUserUpdateOneDocument = gql`
    mutation ProjectUserUpdateOne($actorRole: ActorRole!, $projectId: Int!, $userId: Int!) {
  ProjectUserUpdateOne(
    actorRole: $actorRole
    projectId: $projectId
    userId: $userId
  ) {
    user {
      id
    }
    project {
      id
    }
    actorRole
  }
}
    `;
export type ProjectUserUpdateOneMutationFn = Apollo.MutationFunction<ProjectUserUpdateOneMutation, ProjectUserUpdateOneMutationVariables>;

/**
 * __useProjectUserUpdateOneMutation__
 *
 * To run a mutation, you first call `useProjectUserUpdateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectUserUpdateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectUserUpdateOneMutation, { data, loading, error }] = useProjectUserUpdateOneMutation({
 *   variables: {
 *      actorRole: // value for 'actorRole'
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProjectUserUpdateOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectUserUpdateOneMutation, ProjectUserUpdateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectUserUpdateOneMutation, ProjectUserUpdateOneMutationVariables>(ProjectUserUpdateOneDocument, options);
      }
export type ProjectUserUpdateOneMutationHookResult = ReturnType<typeof useProjectUserUpdateOneMutation>;
export type ProjectUserUpdateOneMutationResult = Apollo.MutationResult<ProjectUserUpdateOneMutation>;
export type ProjectUserUpdateOneMutationOptions = Apollo.BaseMutationOptions<ProjectUserUpdateOneMutation, ProjectUserUpdateOneMutationVariables>;
export const ProjectUserDeleteOneDocument = gql`
    mutation ProjectUserDeleteOne($projectId: Float!, $userId: Float!) {
  ProjectUserDeleteOne(projectId: $projectId, userId: $userId) {
    user {
      id
    }
    project {
      id
    }
    actorRole
  }
}
    `;
export type ProjectUserDeleteOneMutationFn = Apollo.MutationFunction<ProjectUserDeleteOneMutation, ProjectUserDeleteOneMutationVariables>;

/**
 * __useProjectUserDeleteOneMutation__
 *
 * To run a mutation, you first call `useProjectUserDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProjectUserDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [projectUserDeleteOneMutation, { data, loading, error }] = useProjectUserDeleteOneMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useProjectUserDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<ProjectUserDeleteOneMutation, ProjectUserDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProjectUserDeleteOneMutation, ProjectUserDeleteOneMutationVariables>(ProjectUserDeleteOneDocument, options);
      }
export type ProjectUserDeleteOneMutationHookResult = ReturnType<typeof useProjectUserDeleteOneMutation>;
export type ProjectUserDeleteOneMutationResult = Apollo.MutationResult<ProjectUserDeleteOneMutation>;
export type ProjectUserDeleteOneMutationOptions = Apollo.BaseMutationOptions<ProjectUserDeleteOneMutation, ProjectUserDeleteOneMutationVariables>;
export const ReportRenderCreateDocument = gql`
    mutation ReportRenderCreate($dataSourceId: Int!, $reportId: Int!, $reportRenderType: ReportRenderType!) {
  ReportRenderCreate(
    dataSourceId: $dataSourceId
    reportId: $reportId
    reportRenderType: $reportRenderType
  ) {
    id
    subId
    reportRenderStatus
  }
}
    `;
export type ReportRenderCreateMutationFn = Apollo.MutationFunction<ReportRenderCreateMutation, ReportRenderCreateMutationVariables>;

/**
 * __useReportRenderCreateMutation__
 *
 * To run a mutation, you first call `useReportRenderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportRenderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportRenderCreateMutation, { data, loading, error }] = useReportRenderCreateMutation({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *      reportId: // value for 'reportId'
 *      reportRenderType: // value for 'reportRenderType'
 *   },
 * });
 */
export function useReportRenderCreateMutation(baseOptions?: Apollo.MutationHookOptions<ReportRenderCreateMutation, ReportRenderCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportRenderCreateMutation, ReportRenderCreateMutationVariables>(ReportRenderCreateDocument, options);
      }
export type ReportRenderCreateMutationHookResult = ReturnType<typeof useReportRenderCreateMutation>;
export type ReportRenderCreateMutationResult = Apollo.MutationResult<ReportRenderCreateMutation>;
export type ReportRenderCreateMutationOptions = Apollo.BaseMutationOptions<ReportRenderCreateMutation, ReportRenderCreateMutationVariables>;
export const ReportAssignmentCreateDocument = gql`
    mutation ReportAssignmentCreate($assignedId: Int!, $assignedType: RAssignedType!, $reportId: Int!) {
  ReportAssignmentCreate(
    assignedId: $assignedId
    assignedType: $assignedType
    reportId: $reportId
  ) {
    ...ReportAssignment
  }
}
    ${ReportAssignmentFragmentDoc}`;
export type ReportAssignmentCreateMutationFn = Apollo.MutationFunction<ReportAssignmentCreateMutation, ReportAssignmentCreateMutationVariables>;

/**
 * __useReportAssignmentCreateMutation__
 *
 * To run a mutation, you first call `useReportAssignmentCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportAssignmentCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportAssignmentCreateMutation, { data, loading, error }] = useReportAssignmentCreateMutation({
 *   variables: {
 *      assignedId: // value for 'assignedId'
 *      assignedType: // value for 'assignedType'
 *      reportId: // value for 'reportId'
 *   },
 * });
 */
export function useReportAssignmentCreateMutation(baseOptions?: Apollo.MutationHookOptions<ReportAssignmentCreateMutation, ReportAssignmentCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportAssignmentCreateMutation, ReportAssignmentCreateMutationVariables>(ReportAssignmentCreateDocument, options);
      }
export type ReportAssignmentCreateMutationHookResult = ReturnType<typeof useReportAssignmentCreateMutation>;
export type ReportAssignmentCreateMutationResult = Apollo.MutationResult<ReportAssignmentCreateMutation>;
export type ReportAssignmentCreateMutationOptions = Apollo.BaseMutationOptions<ReportAssignmentCreateMutation, ReportAssignmentCreateMutationVariables>;
export const ReportAssignmentUpdateDocument = gql`
    mutation ReportAssignmentUpdate($assignedId: Int!, $assignedType: RAssignedType!, $reportId: Int!, $id: Int!) {
  ReportAssignmentUpdate(
    id: $id
    assignedId: $assignedId
    assignedType: $assignedType
    reportId: $reportId
  ) {
    ...ReportAssignment
  }
}
    ${ReportAssignmentFragmentDoc}`;
export type ReportAssignmentUpdateMutationFn = Apollo.MutationFunction<ReportAssignmentUpdateMutation, ReportAssignmentUpdateMutationVariables>;

/**
 * __useReportAssignmentUpdateMutation__
 *
 * To run a mutation, you first call `useReportAssignmentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportAssignmentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportAssignmentUpdateMutation, { data, loading, error }] = useReportAssignmentUpdateMutation({
 *   variables: {
 *      assignedId: // value for 'assignedId'
 *      assignedType: // value for 'assignedType'
 *      reportId: // value for 'reportId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportAssignmentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ReportAssignmentUpdateMutation, ReportAssignmentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportAssignmentUpdateMutation, ReportAssignmentUpdateMutationVariables>(ReportAssignmentUpdateDocument, options);
      }
export type ReportAssignmentUpdateMutationHookResult = ReturnType<typeof useReportAssignmentUpdateMutation>;
export type ReportAssignmentUpdateMutationResult = Apollo.MutationResult<ReportAssignmentUpdateMutation>;
export type ReportAssignmentUpdateMutationOptions = Apollo.BaseMutationOptions<ReportAssignmentUpdateMutation, ReportAssignmentUpdateMutationVariables>;
export const ReportAssignmentDeleteDocument = gql`
    mutation ReportAssignmentDelete($id: Int!) {
  ReportAssignmentDelete(id: $id) {
    affected
  }
}
    `;
export type ReportAssignmentDeleteMutationFn = Apollo.MutationFunction<ReportAssignmentDeleteMutation, ReportAssignmentDeleteMutationVariables>;

/**
 * __useReportAssignmentDeleteMutation__
 *
 * To run a mutation, you first call `useReportAssignmentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportAssignmentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportAssignmentDeleteMutation, { data, loading, error }] = useReportAssignmentDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportAssignmentDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ReportAssignmentDeleteMutation, ReportAssignmentDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportAssignmentDeleteMutation, ReportAssignmentDeleteMutationVariables>(ReportAssignmentDeleteDocument, options);
      }
export type ReportAssignmentDeleteMutationHookResult = ReturnType<typeof useReportAssignmentDeleteMutation>;
export type ReportAssignmentDeleteMutationResult = Apollo.MutationResult<ReportAssignmentDeleteMutation>;
export type ReportAssignmentDeleteMutationOptions = Apollo.BaseMutationOptions<ReportAssignmentDeleteMutation, ReportAssignmentDeleteMutationVariables>;
export const ReportDeleteDocument = gql`
    mutation ReportDelete($id: Int!) {
  report: ReportDelete(id: $id) {
    affected
  }
}
    `;
export type ReportDeleteMutationFn = Apollo.MutationFunction<ReportDeleteMutation, ReportDeleteMutationVariables>;

/**
 * __useReportDeleteMutation__
 *
 * To run a mutation, you first call `useReportDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportDeleteMutation, { data, loading, error }] = useReportDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportDeleteMutation(baseOptions?: Apollo.MutationHookOptions<ReportDeleteMutation, ReportDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportDeleteMutation, ReportDeleteMutationVariables>(ReportDeleteDocument, options);
      }
export type ReportDeleteMutationHookResult = ReturnType<typeof useReportDeleteMutation>;
export type ReportDeleteMutationResult = Apollo.MutationResult<ReportDeleteMutation>;
export type ReportDeleteMutationOptions = Apollo.BaseMutationOptions<ReportDeleteMutation, ReportDeleteMutationVariables>;
export const ReportUpdateIsArchivedDocument = gql`
    mutation ReportUpdateIsArchived($id: Int!, $isArchived: Boolean!) {
  report: ReportUpdateIsArchived(id: $id, isArchived: $isArchived) {
    ...Report
  }
}
    ${ReportFragmentDoc}`;
export type ReportUpdateIsArchivedMutationFn = Apollo.MutationFunction<ReportUpdateIsArchivedMutation, ReportUpdateIsArchivedMutationVariables>;

/**
 * __useReportUpdateIsArchivedMutation__
 *
 * To run a mutation, you first call `useReportUpdateIsArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportUpdateIsArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportUpdateIsArchivedMutation, { data, loading, error }] = useReportUpdateIsArchivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useReportUpdateIsArchivedMutation(baseOptions?: Apollo.MutationHookOptions<ReportUpdateIsArchivedMutation, ReportUpdateIsArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportUpdateIsArchivedMutation, ReportUpdateIsArchivedMutationVariables>(ReportUpdateIsArchivedDocument, options);
      }
export type ReportUpdateIsArchivedMutationHookResult = ReturnType<typeof useReportUpdateIsArchivedMutation>;
export type ReportUpdateIsArchivedMutationResult = Apollo.MutationResult<ReportUpdateIsArchivedMutation>;
export type ReportUpdateIsArchivedMutationOptions = Apollo.BaseMutationOptions<ReportUpdateIsArchivedMutation, ReportUpdateIsArchivedMutationVariables>;
export const ReportUpdateDocument = gql`
    mutation ReportUpdate($id: Int!, $name: String!, $type: ReportType!, $subType: ReportSubType, $reportConfigsToCreate: [ReportConfigCreateOneBatchedWithReportArgs!], $reportConfigsToDelete: [DeleteByIdArgs!], $reportConfigsToUpdate: [ReportConfigUpdateOneArgs!]) {
  Report: ReportUpdate(
    id: $id
    name: $name
    type: $type
    subType: $subType
    reportConfigsToCreate: $reportConfigsToCreate
    reportConfigsToUpdate: $reportConfigsToUpdate
    reportConfigsToDelete: $reportConfigsToDelete
  ) {
    ...Report
  }
}
    ${ReportFragmentDoc}`;
export type ReportUpdateMutationFn = Apollo.MutationFunction<ReportUpdateMutation, ReportUpdateMutationVariables>;

/**
 * __useReportUpdateMutation__
 *
 * To run a mutation, you first call `useReportUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportUpdateMutation, { data, loading, error }] = useReportUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *      reportConfigsToCreate: // value for 'reportConfigsToCreate'
 *      reportConfigsToDelete: // value for 'reportConfigsToDelete'
 *      reportConfigsToUpdate: // value for 'reportConfigsToUpdate'
 *   },
 * });
 */
export function useReportUpdateMutation(baseOptions?: Apollo.MutationHookOptions<ReportUpdateMutation, ReportUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportUpdateMutation, ReportUpdateMutationVariables>(ReportUpdateDocument, options);
      }
export type ReportUpdateMutationHookResult = ReturnType<typeof useReportUpdateMutation>;
export type ReportUpdateMutationResult = Apollo.MutationResult<ReportUpdateMutation>;
export type ReportUpdateMutationOptions = Apollo.BaseMutationOptions<ReportUpdateMutation, ReportUpdateMutationVariables>;
export const ReportCopyDocument = gql`
    mutation ReportCopy($name: String!, $type: ReportType!, $subType: ReportSubType, $ownerId: Int!, $ownerType: ReportOwnerType!, $copyReportId: Int!) {
  report: ReportCopy(
    name: $name
    type: $type
    subType: $subType
    ownerId: $ownerId
    ownerType: $ownerType
    copyReportId: $copyReportId
  ) {
    ...Report
  }
}
    ${ReportFragmentDoc}`;
export type ReportCopyMutationFn = Apollo.MutationFunction<ReportCopyMutation, ReportCopyMutationVariables>;

/**
 * __useReportCopyMutation__
 *
 * To run a mutation, you first call `useReportCopyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCopyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCopyMutation, { data, loading, error }] = useReportCopyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *      ownerId: // value for 'ownerId'
 *      ownerType: // value for 'ownerType'
 *      copyReportId: // value for 'copyReportId'
 *   },
 * });
 */
export function useReportCopyMutation(baseOptions?: Apollo.MutationHookOptions<ReportCopyMutation, ReportCopyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportCopyMutation, ReportCopyMutationVariables>(ReportCopyDocument, options);
      }
export type ReportCopyMutationHookResult = ReturnType<typeof useReportCopyMutation>;
export type ReportCopyMutationResult = Apollo.MutationResult<ReportCopyMutation>;
export type ReportCopyMutationOptions = Apollo.BaseMutationOptions<ReportCopyMutation, ReportCopyMutationVariables>;
export const ReportCreateDocument = gql`
    mutation ReportCreate($name: String!, $type: ReportType!, $subType: ReportSubType, $ownerId: Int!, $ownerType: ReportOwnerType!) {
  report: ReportCreate(
    name: $name
    type: $type
    subType: $subType
    ownerId: $ownerId
    ownerType: $ownerType
  ) {
    ...Report
  }
}
    ${ReportFragmentDoc}`;
export type ReportCreateMutationFn = Apollo.MutationFunction<ReportCreateMutation, ReportCreateMutationVariables>;

/**
 * __useReportCreateMutation__
 *
 * To run a mutation, you first call `useReportCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportCreateMutation, { data, loading, error }] = useReportCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *      ownerId: // value for 'ownerId'
 *      ownerType: // value for 'ownerType'
 *   },
 * });
 */
export function useReportCreateMutation(baseOptions?: Apollo.MutationHookOptions<ReportCreateMutation, ReportCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportCreateMutation, ReportCreateMutationVariables>(ReportCreateDocument, options);
      }
export type ReportCreateMutationHookResult = ReturnType<typeof useReportCreateMutation>;
export type ReportCreateMutationResult = Apollo.MutationResult<ReportCreateMutation>;
export type ReportCreateMutationOptions = Apollo.BaseMutationOptions<ReportCreateMutation, ReportCreateMutationVariables>;
export const StageResultsReportRenderCreateDocument = gql`
    mutation StageResultsReportRenderCreate($dataSourceId: Int!, $reportId: Int!, $reportRenderType: ReportRenderType!, $projectJobRoleId: Int!) {
  StageResultsReportRenderCreate(
    dataSourceId: $dataSourceId
    reportId: $reportId
    reportRenderType: $reportRenderType
    projectJobRoleId: $projectJobRoleId
  ) {
    reportRenderStatus
    statusMessage
    subId
    blob {
      url
    }
  }
}
    `;
export type StageResultsReportRenderCreateMutationFn = Apollo.MutationFunction<StageResultsReportRenderCreateMutation, StageResultsReportRenderCreateMutationVariables>;

/**
 * __useStageResultsReportRenderCreateMutation__
 *
 * To run a mutation, you first call `useStageResultsReportRenderCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageResultsReportRenderCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageResultsReportRenderCreateMutation, { data, loading, error }] = useStageResultsReportRenderCreateMutation({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *      reportId: // value for 'reportId'
 *      reportRenderType: // value for 'reportRenderType'
 *      projectJobRoleId: // value for 'projectJobRoleId'
 *   },
 * });
 */
export function useStageResultsReportRenderCreateMutation(baseOptions?: Apollo.MutationHookOptions<StageResultsReportRenderCreateMutation, StageResultsReportRenderCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageResultsReportRenderCreateMutation, StageResultsReportRenderCreateMutationVariables>(StageResultsReportRenderCreateDocument, options);
      }
export type StageResultsReportRenderCreateMutationHookResult = ReturnType<typeof useStageResultsReportRenderCreateMutation>;
export type StageResultsReportRenderCreateMutationResult = Apollo.MutationResult<StageResultsReportRenderCreateMutation>;
export type StageResultsReportRenderCreateMutationOptions = Apollo.BaseMutationOptions<StageResultsReportRenderCreateMutation, StageResultsReportRenderCreateMutationVariables>;
export const RespondantFormDeleteDocument = gql`
    mutation RespondantFormDelete($id: Int!, $associatedId: Int!) {
  RespondantFormDeleteByRespondantAndAssociatedId(
    id: $id
    associatedId: $associatedId
  ) {
    affected
  }
}
    `;
export type RespondantFormDeleteMutationFn = Apollo.MutationFunction<RespondantFormDeleteMutation, RespondantFormDeleteMutationVariables>;

/**
 * __useRespondantFormDeleteMutation__
 *
 * To run a mutation, you first call `useRespondantFormDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondantFormDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondantFormDeleteMutation, { data, loading, error }] = useRespondantFormDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      associatedId: // value for 'associatedId'
 *   },
 * });
 */
export function useRespondantFormDeleteMutation(baseOptions?: Apollo.MutationHookOptions<RespondantFormDeleteMutation, RespondantFormDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RespondantFormDeleteMutation, RespondantFormDeleteMutationVariables>(RespondantFormDeleteDocument, options);
      }
export type RespondantFormDeleteMutationHookResult = ReturnType<typeof useRespondantFormDeleteMutation>;
export type RespondantFormDeleteMutationResult = Apollo.MutationResult<RespondantFormDeleteMutation>;
export type RespondantFormDeleteMutationOptions = Apollo.BaseMutationOptions<RespondantFormDeleteMutation, RespondantFormDeleteMutationVariables>;
export const ResultDeleteManyDocument = gql`
    mutation ResultDeleteMany($doneFor: Int!, $doneBy: Int!, $labels: [String!]) {
  ResultDeleteMany(doneFor: $doneFor, doneBy: $doneBy, labels: $labels) {
    affected
  }
}
    `;
export type ResultDeleteManyMutationFn = Apollo.MutationFunction<ResultDeleteManyMutation, ResultDeleteManyMutationVariables>;

/**
 * __useResultDeleteManyMutation__
 *
 * To run a mutation, you first call `useResultDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResultDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resultDeleteManyMutation, { data, loading, error }] = useResultDeleteManyMutation({
 *   variables: {
 *      doneFor: // value for 'doneFor'
 *      doneBy: // value for 'doneBy'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function useResultDeleteManyMutation(baseOptions?: Apollo.MutationHookOptions<ResultDeleteManyMutation, ResultDeleteManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResultDeleteManyMutation, ResultDeleteManyMutationVariables>(ResultDeleteManyDocument, options);
      }
export type ResultDeleteManyMutationHookResult = ReturnType<typeof useResultDeleteManyMutation>;
export type ResultDeleteManyMutationResult = Apollo.MutationResult<ResultDeleteManyMutation>;
export type ResultDeleteManyMutationOptions = Apollo.BaseMutationOptions<ResultDeleteManyMutation, ResultDeleteManyMutationVariables>;
export const RetrieveTpCandidatesDocument = gql`
    mutation RetrieveTpCandidates($candidateEmail: String!, $firstName: String, $lastName: String, $stageId: Int!, $personalityTpAssessmentId: Int, $inductiveTpAssessmentId: Int, $numericalTpAssessmentId: Int, $verbalTpAssessmentId: Int) {
  retriveTpManualCandidates(
    candidateEmail: $candidateEmail
    firstName: $firstName
    lastName: $lastName
    stageId: $stageId
    personalityTpAssessmentId: $personalityTpAssessmentId
    inductiveTpAssessmentId: $inductiveTpAssessmentId
    numericalTpAssessmentId: $numericalTpAssessmentId
    verbalTpAssessmentId: $verbalTpAssessmentId
  )
}
    `;
export type RetrieveTpCandidatesMutationFn = Apollo.MutationFunction<RetrieveTpCandidatesMutation, RetrieveTpCandidatesMutationVariables>;

/**
 * __useRetrieveTpCandidatesMutation__
 *
 * To run a mutation, you first call `useRetrieveTpCandidatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetrieveTpCandidatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retrieveTpCandidatesMutation, { data, loading, error }] = useRetrieveTpCandidatesMutation({
 *   variables: {
 *      candidateEmail: // value for 'candidateEmail'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      stageId: // value for 'stageId'
 *      personalityTpAssessmentId: // value for 'personalityTpAssessmentId'
 *      inductiveTpAssessmentId: // value for 'inductiveTpAssessmentId'
 *      numericalTpAssessmentId: // value for 'numericalTpAssessmentId'
 *      verbalTpAssessmentId: // value for 'verbalTpAssessmentId'
 *   },
 * });
 */
export function useRetrieveTpCandidatesMutation(baseOptions?: Apollo.MutationHookOptions<RetrieveTpCandidatesMutation, RetrieveTpCandidatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RetrieveTpCandidatesMutation, RetrieveTpCandidatesMutationVariables>(RetrieveTpCandidatesDocument, options);
      }
export type RetrieveTpCandidatesMutationHookResult = ReturnType<typeof useRetrieveTpCandidatesMutation>;
export type RetrieveTpCandidatesMutationResult = Apollo.MutationResult<RetrieveTpCandidatesMutation>;
export type RetrieveTpCandidatesMutationOptions = Apollo.BaseMutationOptions<RetrieveTpCandidatesMutation, RetrieveTpCandidatesMutationVariables>;
export const ScheduleRoiCsvFileExportDocument = gql`
    mutation ScheduleROICsvFileExport($projectIds: [Int!]!) {
  FileExportScheduledTask: ScheduleROICsvFileExport(projectIds: $projectIds) {
    status
    error
    subId
    blob {
      url
      id
    }
  }
}
    `;
export type ScheduleRoiCsvFileExportMutationFn = Apollo.MutationFunction<ScheduleRoiCsvFileExportMutation, ScheduleRoiCsvFileExportMutationVariables>;

/**
 * __useScheduleRoiCsvFileExportMutation__
 *
 * To run a mutation, you first call `useScheduleRoiCsvFileExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleRoiCsvFileExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleRoiCsvFileExportMutation, { data, loading, error }] = useScheduleRoiCsvFileExportMutation({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useScheduleRoiCsvFileExportMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleRoiCsvFileExportMutation, ScheduleRoiCsvFileExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleRoiCsvFileExportMutation, ScheduleRoiCsvFileExportMutationVariables>(ScheduleRoiCsvFileExportDocument, options);
      }
export type ScheduleRoiCsvFileExportMutationHookResult = ReturnType<typeof useScheduleRoiCsvFileExportMutation>;
export type ScheduleRoiCsvFileExportMutationResult = Apollo.MutationResult<ScheduleRoiCsvFileExportMutation>;
export type ScheduleRoiCsvFileExportMutationOptions = Apollo.BaseMutationOptions<ScheduleRoiCsvFileExportMutation, ScheduleRoiCsvFileExportMutationVariables>;
export const RssAssessmentCreateManyDocument = gql`
    mutation RssAssessmentCreateMany($dto: [RssAssessmentCreateOneArgs!]!) {
  RssAssessmentCreateMany(dto: $dto) {
    ...RssAssessment
  }
}
    ${RssAssessmentFragmentDoc}`;
export type RssAssessmentCreateManyMutationFn = Apollo.MutationFunction<RssAssessmentCreateManyMutation, RssAssessmentCreateManyMutationVariables>;

/**
 * __useRssAssessmentCreateManyMutation__
 *
 * To run a mutation, you first call `useRssAssessmentCreateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRssAssessmentCreateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rssAssessmentCreateManyMutation, { data, loading, error }] = useRssAssessmentCreateManyMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRssAssessmentCreateManyMutation(baseOptions?: Apollo.MutationHookOptions<RssAssessmentCreateManyMutation, RssAssessmentCreateManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RssAssessmentCreateManyMutation, RssAssessmentCreateManyMutationVariables>(RssAssessmentCreateManyDocument, options);
      }
export type RssAssessmentCreateManyMutationHookResult = ReturnType<typeof useRssAssessmentCreateManyMutation>;
export type RssAssessmentCreateManyMutationResult = Apollo.MutationResult<RssAssessmentCreateManyMutation>;
export type RssAssessmentCreateManyMutationOptions = Apollo.BaseMutationOptions<RssAssessmentCreateManyMutation, RssAssessmentCreateManyMutationVariables>;
export const RssAssessmentDeleteManyDocument = gql`
    mutation RssAssessmentDeleteMany($ids: [Int!]!) {
  RssAssessmentDeleteMany(ids: $ids) {
    affected
  }
}
    `;
export type RssAssessmentDeleteManyMutationFn = Apollo.MutationFunction<RssAssessmentDeleteManyMutation, RssAssessmentDeleteManyMutationVariables>;

/**
 * __useRssAssessmentDeleteManyMutation__
 *
 * To run a mutation, you first call `useRssAssessmentDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRssAssessmentDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rssAssessmentDeleteManyMutation, { data, loading, error }] = useRssAssessmentDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRssAssessmentDeleteManyMutation(baseOptions?: Apollo.MutationHookOptions<RssAssessmentDeleteManyMutation, RssAssessmentDeleteManyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RssAssessmentDeleteManyMutation, RssAssessmentDeleteManyMutationVariables>(RssAssessmentDeleteManyDocument, options);
      }
export type RssAssessmentDeleteManyMutationHookResult = ReturnType<typeof useRssAssessmentDeleteManyMutation>;
export type RssAssessmentDeleteManyMutationResult = Apollo.MutationResult<RssAssessmentDeleteManyMutation>;
export type RssAssessmentDeleteManyMutationOptions = Apollo.BaseMutationOptions<RssAssessmentDeleteManyMutation, RssAssessmentDeleteManyMutationVariables>;
export const RssAssessmentCandidateReopenAllSubmittedForStageCandidateDocument = gql`
    mutation RssAssessmentCandidateReopenAllSubmittedForStageCandidate($stageCandidateId: Int!) {
  RssAssessmentCandidateReopenAllSubmittedForStageCandidate(
    stageCandidateId: $stageCandidateId
  ) {
    ...RssAssessmentCandidate
  }
}
    ${RssAssessmentCandidateFragmentDoc}`;
export type RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationFn = Apollo.MutationFunction<RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation, RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationVariables>;

/**
 * __useRssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation__
 *
 * To run a mutation, you first call `useRssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation, { data, loading, error }] = useRssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation({
 *   variables: {
 *      stageCandidateId: // value for 'stageCandidateId'
 *   },
 * });
 */
export function useRssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation(baseOptions?: Apollo.MutationHookOptions<RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation, RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation, RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationVariables>(RssAssessmentCandidateReopenAllSubmittedForStageCandidateDocument, options);
      }
export type RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationHookResult = ReturnType<typeof useRssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation>;
export type RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationResult = Apollo.MutationResult<RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation>;
export type RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationOptions = Apollo.BaseMutationOptions<RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutation, RssAssessmentCandidateReopenAllSubmittedForStageCandidateMutationVariables>;
export const SoftSkillBulkUpdateTraitsDocument = gql`
    mutation SoftSkillBulkUpdateTraits($softSkillTraits: [BulkUpdateSoftSkillTraitArgsSingle!]!) {
  softSkill: SoftSkillBulkUpdateTraits(softSkillTraits: $softSkillTraits) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;
export type SoftSkillBulkUpdateTraitsMutationFn = Apollo.MutationFunction<SoftSkillBulkUpdateTraitsMutation, SoftSkillBulkUpdateTraitsMutationVariables>;

/**
 * __useSoftSkillBulkUpdateTraitsMutation__
 *
 * To run a mutation, you first call `useSoftSkillBulkUpdateTraitsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillBulkUpdateTraitsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softSkillBulkUpdateTraitsMutation, { data, loading, error }] = useSoftSkillBulkUpdateTraitsMutation({
 *   variables: {
 *      softSkillTraits: // value for 'softSkillTraits'
 *   },
 * });
 */
export function useSoftSkillBulkUpdateTraitsMutation(baseOptions?: Apollo.MutationHookOptions<SoftSkillBulkUpdateTraitsMutation, SoftSkillBulkUpdateTraitsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftSkillBulkUpdateTraitsMutation, SoftSkillBulkUpdateTraitsMutationVariables>(SoftSkillBulkUpdateTraitsDocument, options);
      }
export type SoftSkillBulkUpdateTraitsMutationHookResult = ReturnType<typeof useSoftSkillBulkUpdateTraitsMutation>;
export type SoftSkillBulkUpdateTraitsMutationResult = Apollo.MutationResult<SoftSkillBulkUpdateTraitsMutation>;
export type SoftSkillBulkUpdateTraitsMutationOptions = Apollo.BaseMutationOptions<SoftSkillBulkUpdateTraitsMutation, SoftSkillBulkUpdateTraitsMutationVariables>;
export const SoftSkillUploadIconDocument = gql`
    mutation SoftSkillUploadIcon($id: Int!, $file: Upload!) {
  softSkill: SoftSkillUploadIcon(id: $id, file: $file) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;
export type SoftSkillUploadIconMutationFn = Apollo.MutationFunction<SoftSkillUploadIconMutation, SoftSkillUploadIconMutationVariables>;

/**
 * __useSoftSkillUploadIconMutation__
 *
 * To run a mutation, you first call `useSoftSkillUploadIconMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillUploadIconMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softSkillUploadIconMutation, { data, loading, error }] = useSoftSkillUploadIconMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useSoftSkillUploadIconMutation(baseOptions?: Apollo.MutationHookOptions<SoftSkillUploadIconMutation, SoftSkillUploadIconMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftSkillUploadIconMutation, SoftSkillUploadIconMutationVariables>(SoftSkillUploadIconDocument, options);
      }
export type SoftSkillUploadIconMutationHookResult = ReturnType<typeof useSoftSkillUploadIconMutation>;
export type SoftSkillUploadIconMutationResult = Apollo.MutationResult<SoftSkillUploadIconMutation>;
export type SoftSkillUploadIconMutationOptions = Apollo.BaseMutationOptions<SoftSkillUploadIconMutation, SoftSkillUploadIconMutationVariables>;
export const SoftSkillDeleteDocument = gql`
    mutation SoftSkillDelete($id: Int!) {
  affected: SoftSkillDelete(id: $id) {
    affected
  }
}
    `;
export type SoftSkillDeleteMutationFn = Apollo.MutationFunction<SoftSkillDeleteMutation, SoftSkillDeleteMutationVariables>;

/**
 * __useSoftSkillDeleteMutation__
 *
 * To run a mutation, you first call `useSoftSkillDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [softSkillDeleteMutation, { data, loading, error }] = useSoftSkillDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSoftSkillDeleteMutation(baseOptions?: Apollo.MutationHookOptions<SoftSkillDeleteMutation, SoftSkillDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SoftSkillDeleteMutation, SoftSkillDeleteMutationVariables>(SoftSkillDeleteDocument, options);
      }
export type SoftSkillDeleteMutationHookResult = ReturnType<typeof useSoftSkillDeleteMutation>;
export type SoftSkillDeleteMutationResult = Apollo.MutationResult<SoftSkillDeleteMutation>;
export type SoftSkillDeleteMutationOptions = Apollo.BaseMutationOptions<SoftSkillDeleteMutation, SoftSkillDeleteMutationVariables>;
export const AddSoftSkillDocument = gql`
    mutation AddSoftSkill($name: String!, $category: String!, $companyId: Int!, $softSkillId: Int, $description: String, $measurementConstructType: MeasurementConstructType!) {
  softSkill: SoftSkillCreate(
    name: $name
    category: $category
    companyId: $companyId
    softSkillId: $softSkillId
    measurementConstructType: $measurementConstructType
    description: $description
  ) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;
export type AddSoftSkillMutationFn = Apollo.MutationFunction<AddSoftSkillMutation, AddSoftSkillMutationVariables>;

/**
 * __useAddSoftSkillMutation__
 *
 * To run a mutation, you first call `useAddSoftSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSoftSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSoftSkillMutation, { data, loading, error }] = useAddSoftSkillMutation({
 *   variables: {
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      companyId: // value for 'companyId'
 *      softSkillId: // value for 'softSkillId'
 *      description: // value for 'description'
 *      measurementConstructType: // value for 'measurementConstructType'
 *   },
 * });
 */
export function useAddSoftSkillMutation(baseOptions?: Apollo.MutationHookOptions<AddSoftSkillMutation, AddSoftSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSoftSkillMutation, AddSoftSkillMutationVariables>(AddSoftSkillDocument, options);
      }
export type AddSoftSkillMutationHookResult = ReturnType<typeof useAddSoftSkillMutation>;
export type AddSoftSkillMutationResult = Apollo.MutationResult<AddSoftSkillMutation>;
export type AddSoftSkillMutationOptions = Apollo.BaseMutationOptions<AddSoftSkillMutation, AddSoftSkillMutationVariables>;
export const DeleteSoftSkillTraitDocument = gql`
    mutation DeleteSoftSkillTrait($softSkillId: Int!, $traitId: Int!) {
  softSkill: SoftSkillDeleteTrait(softSkillId: $softSkillId, traitId: $traitId) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;
export type DeleteSoftSkillTraitMutationFn = Apollo.MutationFunction<DeleteSoftSkillTraitMutation, DeleteSoftSkillTraitMutationVariables>;

/**
 * __useDeleteSoftSkillTraitMutation__
 *
 * To run a mutation, you first call `useDeleteSoftSkillTraitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSoftSkillTraitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSoftSkillTraitMutation, { data, loading, error }] = useDeleteSoftSkillTraitMutation({
 *   variables: {
 *      softSkillId: // value for 'softSkillId'
 *      traitId: // value for 'traitId'
 *   },
 * });
 */
export function useDeleteSoftSkillTraitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSoftSkillTraitMutation, DeleteSoftSkillTraitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSoftSkillTraitMutation, DeleteSoftSkillTraitMutationVariables>(DeleteSoftSkillTraitDocument, options);
      }
export type DeleteSoftSkillTraitMutationHookResult = ReturnType<typeof useDeleteSoftSkillTraitMutation>;
export type DeleteSoftSkillTraitMutationResult = Apollo.MutationResult<DeleteSoftSkillTraitMutation>;
export type DeleteSoftSkillTraitMutationOptions = Apollo.BaseMutationOptions<DeleteSoftSkillTraitMutation, DeleteSoftSkillTraitMutationVariables>;
export const ArchiveSoftSkillDocument = gql`
    mutation ArchiveSoftSkill($id: Int!, $isArchived: Boolean!) {
  softSkill: SoftSkillUpdateIsArchived(id: $id, isArchived: $isArchived) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;
export type ArchiveSoftSkillMutationFn = Apollo.MutationFunction<ArchiveSoftSkillMutation, ArchiveSoftSkillMutationVariables>;

/**
 * __useArchiveSoftSkillMutation__
 *
 * To run a mutation, you first call `useArchiveSoftSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveSoftSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveSoftSkillMutation, { data, loading, error }] = useArchiveSoftSkillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useArchiveSoftSkillMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveSoftSkillMutation, ArchiveSoftSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveSoftSkillMutation, ArchiveSoftSkillMutationVariables>(ArchiveSoftSkillDocument, options);
      }
export type ArchiveSoftSkillMutationHookResult = ReturnType<typeof useArchiveSoftSkillMutation>;
export type ArchiveSoftSkillMutationResult = Apollo.MutationResult<ArchiveSoftSkillMutation>;
export type ArchiveSoftSkillMutationOptions = Apollo.BaseMutationOptions<ArchiveSoftSkillMutation, ArchiveSoftSkillMutationVariables>;
export const UpdateSoftSkillDocument = gql`
    mutation UpdateSoftSkill($id: Int!, $name: String, $category: String, $companyId: Int, $description: String, $measurementConstructType: MeasurementConstructType!) {
  softSkill: SoftSkillUpdate(
    id: $id
    name: $name
    category: $category
    companyId: $companyId
    measurementConstructType: $measurementConstructType
    description: $description
  ) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;
export type UpdateSoftSkillMutationFn = Apollo.MutationFunction<UpdateSoftSkillMutation, UpdateSoftSkillMutationVariables>;

/**
 * __useUpdateSoftSkillMutation__
 *
 * To run a mutation, you first call `useUpdateSoftSkillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSoftSkillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSoftSkillMutation, { data, loading, error }] = useUpdateSoftSkillMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      category: // value for 'category'
 *      companyId: // value for 'companyId'
 *      description: // value for 'description'
 *      measurementConstructType: // value for 'measurementConstructType'
 *   },
 * });
 */
export function useUpdateSoftSkillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSoftSkillMutation, UpdateSoftSkillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSoftSkillMutation, UpdateSoftSkillMutationVariables>(UpdateSoftSkillDocument, options);
      }
export type UpdateSoftSkillMutationHookResult = ReturnType<typeof useUpdateSoftSkillMutation>;
export type UpdateSoftSkillMutationResult = Apollo.MutationResult<UpdateSoftSkillMutation>;
export type UpdateSoftSkillMutationOptions = Apollo.BaseMutationOptions<UpdateSoftSkillMutation, UpdateSoftSkillMutationVariables>;
export const SaveSoftSkillSuccessProfileContentDocument = gql`
    mutation SaveSoftSkillSuccessProfileContent($id: Int!, $description: String, $shortDescription: String) {
  saveSoftSkillContent: SoftSkillSuccessProfileContentUpdate(
    id: $id
    description: $description
    shortDescription: $shortDescription
  ) {
    id
    softSkillId
    softSkill {
      name
    }
    roleLevel
    shortDescription
    description
  }
}
    `;
export type SaveSoftSkillSuccessProfileContentMutationFn = Apollo.MutationFunction<SaveSoftSkillSuccessProfileContentMutation, SaveSoftSkillSuccessProfileContentMutationVariables>;

/**
 * __useSaveSoftSkillSuccessProfileContentMutation__
 *
 * To run a mutation, you first call `useSaveSoftSkillSuccessProfileContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSoftSkillSuccessProfileContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSoftSkillSuccessProfileContentMutation, { data, loading, error }] = useSaveSoftSkillSuccessProfileContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *   },
 * });
 */
export function useSaveSoftSkillSuccessProfileContentMutation(baseOptions?: Apollo.MutationHookOptions<SaveSoftSkillSuccessProfileContentMutation, SaveSoftSkillSuccessProfileContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSoftSkillSuccessProfileContentMutation, SaveSoftSkillSuccessProfileContentMutationVariables>(SaveSoftSkillSuccessProfileContentDocument, options);
      }
export type SaveSoftSkillSuccessProfileContentMutationHookResult = ReturnType<typeof useSaveSoftSkillSuccessProfileContentMutation>;
export type SaveSoftSkillSuccessProfileContentMutationResult = Apollo.MutationResult<SaveSoftSkillSuccessProfileContentMutation>;
export type SaveSoftSkillSuccessProfileContentMutationOptions = Apollo.BaseMutationOptions<SaveSoftSkillSuccessProfileContentMutation, SaveSoftSkillSuccessProfileContentMutationVariables>;
export const SaveSoftSkillContentDocument = gql`
    mutation SaveSoftSkillContent($id: Int!, $description: String, $candidateContent: String, $hiringManagerContent: String, $internalCandidateContent: String) {
  saveSoftSkillContent: SoftSkillRoleLevelContentUpdate(
    id: $id
    description: $description
    candidateContent: $candidateContent
    hiringManagerContent: $hiringManagerContent
    internalCandidateContent: $internalCandidateContent
  ) {
    id
    softSkillId
    softSkill {
      id
      name
    }
    grade
    roleLevel
    description
    candidateContent
    hiringManagerContent
    internalCandidateContent
  }
}
    `;
export type SaveSoftSkillContentMutationFn = Apollo.MutationFunction<SaveSoftSkillContentMutation, SaveSoftSkillContentMutationVariables>;

/**
 * __useSaveSoftSkillContentMutation__
 *
 * To run a mutation, you first call `useSaveSoftSkillContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSoftSkillContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSoftSkillContentMutation, { data, loading, error }] = useSaveSoftSkillContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      candidateContent: // value for 'candidateContent'
 *      hiringManagerContent: // value for 'hiringManagerContent'
 *      internalCandidateContent: // value for 'internalCandidateContent'
 *   },
 * });
 */
export function useSaveSoftSkillContentMutation(baseOptions?: Apollo.MutationHookOptions<SaveSoftSkillContentMutation, SaveSoftSkillContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveSoftSkillContentMutation, SaveSoftSkillContentMutationVariables>(SaveSoftSkillContentDocument, options);
      }
export type SaveSoftSkillContentMutationHookResult = ReturnType<typeof useSaveSoftSkillContentMutation>;
export type SaveSoftSkillContentMutationResult = Apollo.MutationResult<SaveSoftSkillContentMutation>;
export type SaveSoftSkillContentMutationOptions = Apollo.BaseMutationOptions<SaveSoftSkillContentMutation, SaveSoftSkillContentMutationVariables>;
export const SetIndirectInviteSsoDocument = gql`
    mutation SetIndirectInviteSSO($projectId: Int!, $indirectInviteSSO: Boolean!) {
  setIndirectInviteSSO(id: $projectId, indirectInviteSSO: $indirectInviteSSO)
}
    `;
export type SetIndirectInviteSsoMutationFn = Apollo.MutationFunction<SetIndirectInviteSsoMutation, SetIndirectInviteSsoMutationVariables>;

/**
 * __useSetIndirectInviteSsoMutation__
 *
 * To run a mutation, you first call `useSetIndirectInviteSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIndirectInviteSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIndirectInviteSsoMutation, { data, loading, error }] = useSetIndirectInviteSsoMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      indirectInviteSSO: // value for 'indirectInviteSSO'
 *   },
 * });
 */
export function useSetIndirectInviteSsoMutation(baseOptions?: Apollo.MutationHookOptions<SetIndirectInviteSsoMutation, SetIndirectInviteSsoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIndirectInviteSsoMutation, SetIndirectInviteSsoMutationVariables>(SetIndirectInviteSsoDocument, options);
      }
export type SetIndirectInviteSsoMutationHookResult = ReturnType<typeof useSetIndirectInviteSsoMutation>;
export type SetIndirectInviteSsoMutationResult = Apollo.MutationResult<SetIndirectInviteSsoMutation>;
export type SetIndirectInviteSsoMutationOptions = Apollo.BaseMutationOptions<SetIndirectInviteSsoMutation, SetIndirectInviteSsoMutationVariables>;
export const StackOneJobExportDocument = gql`
    mutation stackOneJobExport($companyId: Int!) {
  stackOneStartJobsExport(companyId: $companyId) {
    id
    status
  }
}
    `;
export type StackOneJobExportMutationFn = Apollo.MutationFunction<StackOneJobExportMutation, StackOneJobExportMutationVariables>;

/**
 * __useStackOneJobExportMutation__
 *
 * To run a mutation, you first call `useStackOneJobExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStackOneJobExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stackOneJobExportMutation, { data, loading, error }] = useStackOneJobExportMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useStackOneJobExportMutation(baseOptions?: Apollo.MutationHookOptions<StackOneJobExportMutation, StackOneJobExportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StackOneJobExportMutation, StackOneJobExportMutationVariables>(StackOneJobExportDocument, options);
      }
export type StackOneJobExportMutationHookResult = ReturnType<typeof useStackOneJobExportMutation>;
export type StackOneJobExportMutationResult = Apollo.MutationResult<StackOneJobExportMutation>;
export type StackOneJobExportMutationOptions = Apollo.BaseMutationOptions<StackOneJobExportMutation, StackOneJobExportMutationVariables>;
export const RemoveOneStageDocument = gql`
    mutation RemoveOneStage($stageId: Int!) {
  RemoveOneStage: StageDeleteOne(id: $stageId) {
    affected
  }
}
    `;
export type RemoveOneStageMutationFn = Apollo.MutationFunction<RemoveOneStageMutation, RemoveOneStageMutationVariables>;

/**
 * __useRemoveOneStageMutation__
 *
 * To run a mutation, you first call `useRemoveOneStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveOneStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeOneStageMutation, { data, loading, error }] = useRemoveOneStageMutation({
 *   variables: {
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useRemoveOneStageMutation(baseOptions?: Apollo.MutationHookOptions<RemoveOneStageMutation, RemoveOneStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveOneStageMutation, RemoveOneStageMutationVariables>(RemoveOneStageDocument, options);
      }
export type RemoveOneStageMutationHookResult = ReturnType<typeof useRemoveOneStageMutation>;
export type RemoveOneStageMutationResult = Apollo.MutationResult<RemoveOneStageMutation>;
export type RemoveOneStageMutationOptions = Apollo.BaseMutationOptions<RemoveOneStageMutation, RemoveOneStageMutationVariables>;
export const OrderStagesDocument = gql`
    mutation OrderStages($projectId: Int!, $stageOrderings: [OrderStageInput!]!) {
  Stages: StageReorderMany(projectId: $projectId, stageOrderings: $stageOrderings) {
    ...SmStageModel
  }
}
    ${SmStageModelFragmentDoc}`;
export type OrderStagesMutationFn = Apollo.MutationFunction<OrderStagesMutation, OrderStagesMutationVariables>;

/**
 * __useOrderStagesMutation__
 *
 * To run a mutation, you first call `useOrderStagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOrderStagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [orderStagesMutation, { data, loading, error }] = useOrderStagesMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      stageOrderings: // value for 'stageOrderings'
 *   },
 * });
 */
export function useOrderStagesMutation(baseOptions?: Apollo.MutationHookOptions<OrderStagesMutation, OrderStagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OrderStagesMutation, OrderStagesMutationVariables>(OrderStagesDocument, options);
      }
export type OrderStagesMutationHookResult = ReturnType<typeof useOrderStagesMutation>;
export type OrderStagesMutationResult = Apollo.MutationResult<OrderStagesMutation>;
export type OrderStagesMutationOptions = Apollo.BaseMutationOptions<OrderStagesMutation, OrderStagesMutationVariables>;
export const CreateStageDocument = gql`
    mutation CreateStage($hasCalibration: Boolean!, $createInterviewGuide: Boolean!, $emailCandidateReport: Boolean!, $emailManagerReport: Boolean!, $emailProjectTeam: Boolean!, $emailSzAdmin: Boolean!, $emailProjectTeamRoles: [ActorRole!], $endTime: Date, $name: String!, $projectId: Int!, $renderCandidateReport: Boolean!, $startTime: Date, $status: StageStatus, $testIds: [Int!], $type: StageType!, $managerReportEmailLinks: Boolean, $enableF2fInterviews: Boolean) {
  Stage: StageCreate(
    hasCalibration: $hasCalibration
    createInterviewGuide: $createInterviewGuide
    emailCandidateReport: $emailCandidateReport
    emailManagerReport: $emailManagerReport
    emailProjectTeam: $emailProjectTeam
    emailProjectTeamRoles: $emailProjectTeamRoles
    emailSzAdmin: $emailSzAdmin
    endTime: $endTime
    name: $name
    projectId: $projectId
    renderCandidateReport: $renderCandidateReport
    startTime: $startTime
    status: $status
    testIds: $testIds
    type: $type
    managerReportEmailLinks: $managerReportEmailLinks
    enableF2fInterviews: $enableF2fInterviews
  ) {
    ...SmStageModel
  }
}
    ${SmStageModelFragmentDoc}`;
export type CreateStageMutationFn = Apollo.MutationFunction<CreateStageMutation, CreateStageMutationVariables>;

/**
 * __useCreateStageMutation__
 *
 * To run a mutation, you first call `useCreateStageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStageMutation, { data, loading, error }] = useCreateStageMutation({
 *   variables: {
 *      hasCalibration: // value for 'hasCalibration'
 *      createInterviewGuide: // value for 'createInterviewGuide'
 *      emailCandidateReport: // value for 'emailCandidateReport'
 *      emailManagerReport: // value for 'emailManagerReport'
 *      emailProjectTeam: // value for 'emailProjectTeam'
 *      emailSzAdmin: // value for 'emailSzAdmin'
 *      emailProjectTeamRoles: // value for 'emailProjectTeamRoles'
 *      endTime: // value for 'endTime'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      renderCandidateReport: // value for 'renderCandidateReport'
 *      startTime: // value for 'startTime'
 *      status: // value for 'status'
 *      testIds: // value for 'testIds'
 *      type: // value for 'type'
 *      managerReportEmailLinks: // value for 'managerReportEmailLinks'
 *      enableF2fInterviews: // value for 'enableF2fInterviews'
 *   },
 * });
 */
export function useCreateStageMutation(baseOptions?: Apollo.MutationHookOptions<CreateStageMutation, CreateStageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateStageMutation, CreateStageMutationVariables>(CreateStageDocument, options);
      }
export type CreateStageMutationHookResult = ReturnType<typeof useCreateStageMutation>;
export type CreateStageMutationResult = Apollo.MutationResult<CreateStageMutation>;
export type CreateStageMutationOptions = Apollo.BaseMutationOptions<CreateStageMutation, CreateStageMutationVariables>;
export const StageEditDetailsDocument = gql`
    mutation StageEditDetails($emailCandidateReport: Boolean, $emailManagerReport: Boolean, $emailProjectTeam: Boolean, $emailProjectTeamRoles: [ActorRole!], $hasCalibration: Boolean, $createInterviewGuide: Boolean, $emailSzAdmin: Boolean, $endTime: Date, $id: Int!, $name: String, $order: Int, $renderCandidateReport: Boolean, $startTime: Date, $testIds: [Int!], $type: StageType, $managerReportEmailLinks: Boolean, $enableF2fInterviews: Boolean) {
  Stage: StageUpdate(
    emailCandidateReport: $emailCandidateReport
    emailManagerReport: $emailManagerReport
    emailProjectTeam: $emailProjectTeam
    emailProjectTeamRoles: $emailProjectTeamRoles
    hasCalibration: $hasCalibration
    createInterviewGuide: $createInterviewGuide
    emailSzAdmin: $emailSzAdmin
    endTime: $endTime
    id: $id
    name: $name
    order: $order
    renderCandidateReport: $renderCandidateReport
    startTime: $startTime
    testIds: $testIds
    type: $type
    managerReportEmailLinks: $managerReportEmailLinks
    enableF2fInterviews: $enableF2fInterviews
  ) {
    ...SmStageModel
  }
}
    ${SmStageModelFragmentDoc}`;
export type StageEditDetailsMutationFn = Apollo.MutationFunction<StageEditDetailsMutation, StageEditDetailsMutationVariables>;

/**
 * __useStageEditDetailsMutation__
 *
 * To run a mutation, you first call `useStageEditDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageEditDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageEditDetailsMutation, { data, loading, error }] = useStageEditDetailsMutation({
 *   variables: {
 *      emailCandidateReport: // value for 'emailCandidateReport'
 *      emailManagerReport: // value for 'emailManagerReport'
 *      emailProjectTeam: // value for 'emailProjectTeam'
 *      emailProjectTeamRoles: // value for 'emailProjectTeamRoles'
 *      hasCalibration: // value for 'hasCalibration'
 *      createInterviewGuide: // value for 'createInterviewGuide'
 *      emailSzAdmin: // value for 'emailSzAdmin'
 *      endTime: // value for 'endTime'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      order: // value for 'order'
 *      renderCandidateReport: // value for 'renderCandidateReport'
 *      startTime: // value for 'startTime'
 *      testIds: // value for 'testIds'
 *      type: // value for 'type'
 *      managerReportEmailLinks: // value for 'managerReportEmailLinks'
 *      enableF2fInterviews: // value for 'enableF2fInterviews'
 *   },
 * });
 */
export function useStageEditDetailsMutation(baseOptions?: Apollo.MutationHookOptions<StageEditDetailsMutation, StageEditDetailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageEditDetailsMutation, StageEditDetailsMutationVariables>(StageEditDetailsDocument, options);
      }
export type StageEditDetailsMutationHookResult = ReturnType<typeof useStageEditDetailsMutation>;
export type StageEditDetailsMutationResult = Apollo.MutationResult<StageEditDetailsMutation>;
export type StageEditDetailsMutationOptions = Apollo.BaseMutationOptions<StageEditDetailsMutation, StageEditDetailsMutationVariables>;
export const StageCandidateResetOneDocument = gql`
    mutation StageCandidateResetOne($id: Int) {
  Stages: StageCandidateResetOne(id: $id)
}
    `;
export type StageCandidateResetOneMutationFn = Apollo.MutationFunction<StageCandidateResetOneMutation, StageCandidateResetOneMutationVariables>;

/**
 * __useStageCandidateResetOneMutation__
 *
 * To run a mutation, you first call `useStageCandidateResetOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageCandidateResetOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageCandidateResetOneMutation, { data, loading, error }] = useStageCandidateResetOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStageCandidateResetOneMutation(baseOptions?: Apollo.MutationHookOptions<StageCandidateResetOneMutation, StageCandidateResetOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageCandidateResetOneMutation, StageCandidateResetOneMutationVariables>(StageCandidateResetOneDocument, options);
      }
export type StageCandidateResetOneMutationHookResult = ReturnType<typeof useStageCandidateResetOneMutation>;
export type StageCandidateResetOneMutationResult = Apollo.MutationResult<StageCandidateResetOneMutation>;
export type StageCandidateResetOneMutationOptions = Apollo.BaseMutationOptions<StageCandidateResetOneMutation, StageCandidateResetOneMutationVariables>;
export const StageCandidateReprocessOneDocument = gql`
    mutation StageCandidateReprocessOne($id: Int) {
  StageCandidateReprocessOne(id: $id)
}
    `;
export type StageCandidateReprocessOneMutationFn = Apollo.MutationFunction<StageCandidateReprocessOneMutation, StageCandidateReprocessOneMutationVariables>;

/**
 * __useStageCandidateReprocessOneMutation__
 *
 * To run a mutation, you first call `useStageCandidateReprocessOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageCandidateReprocessOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageCandidateReprocessOneMutation, { data, loading, error }] = useStageCandidateReprocessOneMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStageCandidateReprocessOneMutation(baseOptions?: Apollo.MutationHookOptions<StageCandidateReprocessOneMutation, StageCandidateReprocessOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageCandidateReprocessOneMutation, StageCandidateReprocessOneMutationVariables>(StageCandidateReprocessOneDocument, options);
      }
export type StageCandidateReprocessOneMutationHookResult = ReturnType<typeof useStageCandidateReprocessOneMutation>;
export type StageCandidateReprocessOneMutationResult = Apollo.MutationResult<StageCandidateReprocessOneMutation>;
export type StageCandidateReprocessOneMutationOptions = Apollo.BaseMutationOptions<StageCandidateReprocessOneMutation, StageCandidateReprocessOneMutationVariables>;
export const StageCreateCsvReportDocument = gql`
    mutation StageCreateCsvReport($stageIds: [Int!], $measurementConstructsToDisplay: [MeasurementConstructType!], $scoreTypesToDisplay: [DisplayScoreType!], $basicScoreTypesToDisplay: [BasicScoreType!], $softSkillSort: SpSoftSkillSort, $name: String, $options: StageCsvOptions!) {
  StageCreateCsvReport(
    stageIds: $stageIds
    measurementConstructsToDisplay: $measurementConstructsToDisplay
    scoreTypesToDisplay: $scoreTypesToDisplay
    basicScoreTypesToDisplay: $basicScoreTypesToDisplay
    softSkillSort: $softSkillSort
    name: $name
    options: $options
  ) {
    blobName
  }
}
    `;
export type StageCreateCsvReportMutationFn = Apollo.MutationFunction<StageCreateCsvReportMutation, StageCreateCsvReportMutationVariables>;

/**
 * __useStageCreateCsvReportMutation__
 *
 * To run a mutation, you first call `useStageCreateCsvReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageCreateCsvReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageCreateCsvReportMutation, { data, loading, error }] = useStageCreateCsvReportMutation({
 *   variables: {
 *      stageIds: // value for 'stageIds'
 *      measurementConstructsToDisplay: // value for 'measurementConstructsToDisplay'
 *      scoreTypesToDisplay: // value for 'scoreTypesToDisplay'
 *      basicScoreTypesToDisplay: // value for 'basicScoreTypesToDisplay'
 *      softSkillSort: // value for 'softSkillSort'
 *      name: // value for 'name'
 *      options: // value for 'options'
 *   },
 * });
 */
export function useStageCreateCsvReportMutation(baseOptions?: Apollo.MutationHookOptions<StageCreateCsvReportMutation, StageCreateCsvReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageCreateCsvReportMutation, StageCreateCsvReportMutationVariables>(StageCreateCsvReportDocument, options);
      }
export type StageCreateCsvReportMutationHookResult = ReturnType<typeof useStageCreateCsvReportMutation>;
export type StageCreateCsvReportMutationResult = Apollo.MutationResult<StageCreateCsvReportMutation>;
export type StageCreateCsvReportMutationOptions = Apollo.BaseMutationOptions<StageCreateCsvReportMutation, StageCreateCsvReportMutationVariables>;
export const StageCandidateCopyTestResultsDocument = gql`
    mutation StageCandidateCopyTestResults($baseStageId: Int!, $candidateId: Int!, $targetStageId: Int!) {
  StageCandidateCopyTestResults(
    baseStageId: $baseStageId
    candidateId: $candidateId
    targetStageId: $targetStageId
  )
}
    `;
export type StageCandidateCopyTestResultsMutationFn = Apollo.MutationFunction<StageCandidateCopyTestResultsMutation, StageCandidateCopyTestResultsMutationVariables>;

/**
 * __useStageCandidateCopyTestResultsMutation__
 *
 * To run a mutation, you first call `useStageCandidateCopyTestResultsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageCandidateCopyTestResultsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageCandidateCopyTestResultsMutation, { data, loading, error }] = useStageCandidateCopyTestResultsMutation({
 *   variables: {
 *      baseStageId: // value for 'baseStageId'
 *      candidateId: // value for 'candidateId'
 *      targetStageId: // value for 'targetStageId'
 *   },
 * });
 */
export function useStageCandidateCopyTestResultsMutation(baseOptions?: Apollo.MutationHookOptions<StageCandidateCopyTestResultsMutation, StageCandidateCopyTestResultsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageCandidateCopyTestResultsMutation, StageCandidateCopyTestResultsMutationVariables>(StageCandidateCopyTestResultsDocument, options);
      }
export type StageCandidateCopyTestResultsMutationHookResult = ReturnType<typeof useStageCandidateCopyTestResultsMutation>;
export type StageCandidateCopyTestResultsMutationResult = Apollo.MutationResult<StageCandidateCopyTestResultsMutation>;
export type StageCandidateCopyTestResultsMutationOptions = Apollo.BaseMutationOptions<StageCandidateCopyTestResultsMutation, StageCandidateCopyTestResultsMutationVariables>;
export const StageCandidateDeleteOneDocument = gql`
    mutation StageCandidateDeleteOne($subId: String) {
  StageCandidateDeleteOne(subId: $subId) {
    affected
  }
}
    `;
export type StageCandidateDeleteOneMutationFn = Apollo.MutationFunction<StageCandidateDeleteOneMutation, StageCandidateDeleteOneMutationVariables>;

/**
 * __useStageCandidateDeleteOneMutation__
 *
 * To run a mutation, you first call `useStageCandidateDeleteOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStageCandidateDeleteOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stageCandidateDeleteOneMutation, { data, loading, error }] = useStageCandidateDeleteOneMutation({
 *   variables: {
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useStageCandidateDeleteOneMutation(baseOptions?: Apollo.MutationHookOptions<StageCandidateDeleteOneMutation, StageCandidateDeleteOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StageCandidateDeleteOneMutation, StageCandidateDeleteOneMutationVariables>(StageCandidateDeleteOneDocument, options);
      }
export type StageCandidateDeleteOneMutationHookResult = ReturnType<typeof useStageCandidateDeleteOneMutation>;
export type StageCandidateDeleteOneMutationResult = Apollo.MutationResult<StageCandidateDeleteOneMutation>;
export type StageCandidateDeleteOneMutationOptions = Apollo.BaseMutationOptions<StageCandidateDeleteOneMutation, StageCandidateDeleteOneMutationVariables>;
export const DeiStageActionUpsertDocument = gql`
    mutation DeiStageActionUpsert($stageId: Int!, $fields: DeiStageActionFieldsInput!) {
  DeiStageActionUpsert(stageId: $stageId, fields: $fields) {
    ...DeiStageAction
  }
}
    ${DeiStageActionFragmentDoc}`;
export type DeiStageActionUpsertMutationFn = Apollo.MutationFunction<DeiStageActionUpsertMutation, DeiStageActionUpsertMutationVariables>;

/**
 * __useDeiStageActionUpsertMutation__
 *
 * To run a mutation, you first call `useDeiStageActionUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeiStageActionUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deiStageActionUpsertMutation, { data, loading, error }] = useDeiStageActionUpsertMutation({
 *   variables: {
 *      stageId: // value for 'stageId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useDeiStageActionUpsertMutation(baseOptions?: Apollo.MutationHookOptions<DeiStageActionUpsertMutation, DeiStageActionUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeiStageActionUpsertMutation, DeiStageActionUpsertMutationVariables>(DeiStageActionUpsertDocument, options);
      }
export type DeiStageActionUpsertMutationHookResult = ReturnType<typeof useDeiStageActionUpsertMutation>;
export type DeiStageActionUpsertMutationResult = Apollo.MutationResult<DeiStageActionUpsertMutation>;
export type DeiStageActionUpsertMutationOptions = Apollo.BaseMutationOptions<DeiStageActionUpsertMutation, DeiStageActionUpsertMutationVariables>;
export const DeiStageActionArchiveDocument = gql`
    mutation DeiStageActionArchive($stageId: Int!) {
  DeiStageActionArchive(stageId: $stageId) {
    ...DeiStageAction
  }
}
    ${DeiStageActionFragmentDoc}`;
export type DeiStageActionArchiveMutationFn = Apollo.MutationFunction<DeiStageActionArchiveMutation, DeiStageActionArchiveMutationVariables>;

/**
 * __useDeiStageActionArchiveMutation__
 *
 * To run a mutation, you first call `useDeiStageActionArchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeiStageActionArchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deiStageActionArchiveMutation, { data, loading, error }] = useDeiStageActionArchiveMutation({
 *   variables: {
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useDeiStageActionArchiveMutation(baseOptions?: Apollo.MutationHookOptions<DeiStageActionArchiveMutation, DeiStageActionArchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeiStageActionArchiveMutation, DeiStageActionArchiveMutationVariables>(DeiStageActionArchiveDocument, options);
      }
export type DeiStageActionArchiveMutationHookResult = ReturnType<typeof useDeiStageActionArchiveMutation>;
export type DeiStageActionArchiveMutationResult = Apollo.MutationResult<DeiStageActionArchiveMutation>;
export type DeiStageActionArchiveMutationOptions = Apollo.BaseMutationOptions<DeiStageActionArchiveMutation, DeiStageActionArchiveMutationVariables>;
export const DeiStageActionUnarchiveDocument = gql`
    mutation DeiStageActionUnarchive($stageId: Int!, $fields: DeiStageActionFieldsInput!) {
  DeiStageActionUnarchive(stageId: $stageId, fields: $fields) {
    ...DeiStageAction
  }
}
    ${DeiStageActionFragmentDoc}`;
export type DeiStageActionUnarchiveMutationFn = Apollo.MutationFunction<DeiStageActionUnarchiveMutation, DeiStageActionUnarchiveMutationVariables>;

/**
 * __useDeiStageActionUnarchiveMutation__
 *
 * To run a mutation, you first call `useDeiStageActionUnarchiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeiStageActionUnarchiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deiStageActionUnarchiveMutation, { data, loading, error }] = useDeiStageActionUnarchiveMutation({
 *   variables: {
 *      stageId: // value for 'stageId'
 *      fields: // value for 'fields'
 *   },
 * });
 */
export function useDeiStageActionUnarchiveMutation(baseOptions?: Apollo.MutationHookOptions<DeiStageActionUnarchiveMutation, DeiStageActionUnarchiveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeiStageActionUnarchiveMutation, DeiStageActionUnarchiveMutationVariables>(DeiStageActionUnarchiveDocument, options);
      }
export type DeiStageActionUnarchiveMutationHookResult = ReturnType<typeof useDeiStageActionUnarchiveMutation>;
export type DeiStageActionUnarchiveMutationResult = Apollo.MutationResult<DeiStageActionUnarchiveMutation>;
export type DeiStageActionUnarchiveMutationOptions = Apollo.BaseMutationOptions<DeiStageActionUnarchiveMutation, DeiStageActionUnarchiveMutationVariables>;
export const DeiStageActionCandidateReopenDocument = gql`
    mutation DeiStageActionCandidateReopen($stageCandidateId: Int!) {
  DeiStageActionCandidateReopen(stageCandidateId: $stageCandidateId) {
    ...DeiStageActionCandidate
  }
}
    ${DeiStageActionCandidateFragmentDoc}`;
export type DeiStageActionCandidateReopenMutationFn = Apollo.MutationFunction<DeiStageActionCandidateReopenMutation, DeiStageActionCandidateReopenMutationVariables>;

/**
 * __useDeiStageActionCandidateReopenMutation__
 *
 * To run a mutation, you first call `useDeiStageActionCandidateReopenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeiStageActionCandidateReopenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deiStageActionCandidateReopenMutation, { data, loading, error }] = useDeiStageActionCandidateReopenMutation({
 *   variables: {
 *      stageCandidateId: // value for 'stageCandidateId'
 *   },
 * });
 */
export function useDeiStageActionCandidateReopenMutation(baseOptions?: Apollo.MutationHookOptions<DeiStageActionCandidateReopenMutation, DeiStageActionCandidateReopenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeiStageActionCandidateReopenMutation, DeiStageActionCandidateReopenMutationVariables>(DeiStageActionCandidateReopenDocument, options);
      }
export type DeiStageActionCandidateReopenMutationHookResult = ReturnType<typeof useDeiStageActionCandidateReopenMutation>;
export type DeiStageActionCandidateReopenMutationResult = Apollo.MutationResult<DeiStageActionCandidateReopenMutation>;
export type DeiStageActionCandidateReopenMutationOptions = Apollo.BaseMutationOptions<DeiStageActionCandidateReopenMutation, DeiStageActionCandidateReopenMutationVariables>;
export const TmTestUpdateOneDocument = gql`
    mutation TmTestUpdateOne($tpProjectId: String, $testId: Int!, $origin: TestOrigin!, $nQuestionsPerTrait: Int, $type: TestType, $subType: TestSubType) {
  TmTestUpdateOne: TmTestUpdateOne(
    id: $testId
    tpProjectId: $tpProjectId
    origin: $origin
    nQuestionsPerTrait: $nQuestionsPerTrait
    type: $type
    subType: $subType
  ) {
    id
    stageId
    nQuestionsPerTrait
    origin
    tpProjectId
    type
    subType
  }
}
    `;
export type TmTestUpdateOneMutationFn = Apollo.MutationFunction<TmTestUpdateOneMutation, TmTestUpdateOneMutationVariables>;

/**
 * __useTmTestUpdateOneMutation__
 *
 * To run a mutation, you first call `useTmTestUpdateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTmTestUpdateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tmTestUpdateOneMutation, { data, loading, error }] = useTmTestUpdateOneMutation({
 *   variables: {
 *      tpProjectId: // value for 'tpProjectId'
 *      testId: // value for 'testId'
 *      origin: // value for 'origin'
 *      nQuestionsPerTrait: // value for 'nQuestionsPerTrait'
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *   },
 * });
 */
export function useTmTestUpdateOneMutation(baseOptions?: Apollo.MutationHookOptions<TmTestUpdateOneMutation, TmTestUpdateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TmTestUpdateOneMutation, TmTestUpdateOneMutationVariables>(TmTestUpdateOneDocument, options);
      }
export type TmTestUpdateOneMutationHookResult = ReturnType<typeof useTmTestUpdateOneMutation>;
export type TmTestUpdateOneMutationResult = Apollo.MutationResult<TmTestUpdateOneMutation>;
export type TmTestUpdateOneMutationOptions = Apollo.BaseMutationOptions<TmTestUpdateOneMutation, TmTestUpdateOneMutationVariables>;
export const UpdateSuccessProfileDocument = gql`
    mutation UpdateSuccessProfile($id: Int!, $roleLevel: ContentRoleLevel!, $calculationMethod: CalculationMethod!, $gradingMethod: GradingMethod!) {
  successProfile: SuccessProfileUpdateOne(
    id: $id
    roleLevel: $roleLevel
    calculationMethod: $calculationMethod
    gradingMethod: $gradingMethod
  ) {
    ...SuccessProfile
  }
}
    ${SuccessProfileFragmentDoc}`;
export type UpdateSuccessProfileMutationFn = Apollo.MutationFunction<UpdateSuccessProfileMutation, UpdateSuccessProfileMutationVariables>;

/**
 * __useUpdateSuccessProfileMutation__
 *
 * To run a mutation, you first call `useUpdateSuccessProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSuccessProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSuccessProfileMutation, { data, loading, error }] = useUpdateSuccessProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      roleLevel: // value for 'roleLevel'
 *      calculationMethod: // value for 'calculationMethod'
 *      gradingMethod: // value for 'gradingMethod'
 *   },
 * });
 */
export function useUpdateSuccessProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSuccessProfileMutation, UpdateSuccessProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSuccessProfileMutation, UpdateSuccessProfileMutationVariables>(UpdateSuccessProfileDocument, options);
      }
export type UpdateSuccessProfileMutationHookResult = ReturnType<typeof useUpdateSuccessProfileMutation>;
export type UpdateSuccessProfileMutationResult = Apollo.MutationResult<UpdateSuccessProfileMutation>;
export type UpdateSuccessProfileMutationOptions = Apollo.BaseMutationOptions<UpdateSuccessProfileMutation, UpdateSuccessProfileMutationVariables>;
export const CreateSuccessProfileDocument = gql`
    mutation CreateSuccessProfile($roleLevel: ContentRoleLevel!, $calculationMethod: CalculationMethod!, $gradingMethod: GradingMethod!, $jobRoleId: Int!) {
  successProfile: SuccessProfileCreateOne(
    roleLevel: $roleLevel
    calculationMethod: $calculationMethod
    gradingMethod: $gradingMethod
    jobRoleId: $jobRoleId
  ) {
    ...SuccessProfile
  }
}
    ${SuccessProfileFragmentDoc}`;
export type CreateSuccessProfileMutationFn = Apollo.MutationFunction<CreateSuccessProfileMutation, CreateSuccessProfileMutationVariables>;

/**
 * __useCreateSuccessProfileMutation__
 *
 * To run a mutation, you first call `useCreateSuccessProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSuccessProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSuccessProfileMutation, { data, loading, error }] = useCreateSuccessProfileMutation({
 *   variables: {
 *      roleLevel: // value for 'roleLevel'
 *      calculationMethod: // value for 'calculationMethod'
 *      gradingMethod: // value for 'gradingMethod'
 *      jobRoleId: // value for 'jobRoleId'
 *   },
 * });
 */
export function useCreateSuccessProfileMutation(baseOptions?: Apollo.MutationHookOptions<CreateSuccessProfileMutation, CreateSuccessProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSuccessProfileMutation, CreateSuccessProfileMutationVariables>(CreateSuccessProfileDocument, options);
      }
export type CreateSuccessProfileMutationHookResult = ReturnType<typeof useCreateSuccessProfileMutation>;
export type CreateSuccessProfileMutationResult = Apollo.MutationResult<CreateSuccessProfileMutation>;
export type CreateSuccessProfileMutationOptions = Apollo.BaseMutationOptions<CreateSuccessProfileMutation, CreateSuccessProfileMutationVariables>;
export const SuccessProfileSoftSkillsOverwriteDocument = gql`
    mutation SuccessProfileSoftSkillsOverwrite($id: Int!, $softSkills: [SuccessProfileSoftSkillCreateOneArgs!]!) {
  SuccessProfileSoftSkillsOverwrite(id: $id, softSkills: $softSkills) {
    id
    successProfileSoftSkills {
      id
      softSkillId
      weight
    }
  }
}
    `;
export type SuccessProfileSoftSkillsOverwriteMutationFn = Apollo.MutationFunction<SuccessProfileSoftSkillsOverwriteMutation, SuccessProfileSoftSkillsOverwriteMutationVariables>;

/**
 * __useSuccessProfileSoftSkillsOverwriteMutation__
 *
 * To run a mutation, you first call `useSuccessProfileSoftSkillsOverwriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuccessProfileSoftSkillsOverwriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [successProfileSoftSkillsOverwriteMutation, { data, loading, error }] = useSuccessProfileSoftSkillsOverwriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      softSkills: // value for 'softSkills'
 *   },
 * });
 */
export function useSuccessProfileSoftSkillsOverwriteMutation(baseOptions?: Apollo.MutationHookOptions<SuccessProfileSoftSkillsOverwriteMutation, SuccessProfileSoftSkillsOverwriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuccessProfileSoftSkillsOverwriteMutation, SuccessProfileSoftSkillsOverwriteMutationVariables>(SuccessProfileSoftSkillsOverwriteDocument, options);
      }
export type SuccessProfileSoftSkillsOverwriteMutationHookResult = ReturnType<typeof useSuccessProfileSoftSkillsOverwriteMutation>;
export type SuccessProfileSoftSkillsOverwriteMutationResult = Apollo.MutationResult<SuccessProfileSoftSkillsOverwriteMutation>;
export type SuccessProfileSoftSkillsOverwriteMutationOptions = Apollo.BaseMutationOptions<SuccessProfileSoftSkillsOverwriteMutation, SuccessProfileSoftSkillsOverwriteMutationVariables>;
export const SuccessProfileTechnicalSkillsOverwriteDocument = gql`
    mutation SuccessProfileTechnicalSkillsOverwrite($id: Int!, $technicalSkills: [SuccessProfileTechnicalSkillCreateOneArgs!]!) {
  SuccessProfileTechnicalSkillsOverwrite(
    id: $id
    technicalSkills: $technicalSkills
  ) {
    id
    successProfileTechnicalSkills {
      id
      technicalSkillId
      successProfileId
    }
  }
}
    `;
export type SuccessProfileTechnicalSkillsOverwriteMutationFn = Apollo.MutationFunction<SuccessProfileTechnicalSkillsOverwriteMutation, SuccessProfileTechnicalSkillsOverwriteMutationVariables>;

/**
 * __useSuccessProfileTechnicalSkillsOverwriteMutation__
 *
 * To run a mutation, you first call `useSuccessProfileTechnicalSkillsOverwriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuccessProfileTechnicalSkillsOverwriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [successProfileTechnicalSkillsOverwriteMutation, { data, loading, error }] = useSuccessProfileTechnicalSkillsOverwriteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      technicalSkills: // value for 'technicalSkills'
 *   },
 * });
 */
export function useSuccessProfileTechnicalSkillsOverwriteMutation(baseOptions?: Apollo.MutationHookOptions<SuccessProfileTechnicalSkillsOverwriteMutation, SuccessProfileTechnicalSkillsOverwriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuccessProfileTechnicalSkillsOverwriteMutation, SuccessProfileTechnicalSkillsOverwriteMutationVariables>(SuccessProfileTechnicalSkillsOverwriteDocument, options);
      }
export type SuccessProfileTechnicalSkillsOverwriteMutationHookResult = ReturnType<typeof useSuccessProfileTechnicalSkillsOverwriteMutation>;
export type SuccessProfileTechnicalSkillsOverwriteMutationResult = Apollo.MutationResult<SuccessProfileTechnicalSkillsOverwriteMutation>;
export type SuccessProfileTechnicalSkillsOverwriteMutationOptions = Apollo.BaseMutationOptions<SuccessProfileTechnicalSkillsOverwriteMutation, SuccessProfileTechnicalSkillsOverwriteMutationVariables>;
export const TestCandidateUpdateOneDocument = gql`
    mutation TestCandidateUpdateOne($assessmentId: Int, $doneBy: Int, $doneFor: Int, $id: Int!, $stageCandidateId: Int, $stageResultId: Int, $status: TestStatus, $testOrigin: TestOrigin, $timeModifierPercentage: Float) {
  TestCandidateUpdateOne(
    assessmentId: $assessmentId
    doneBy: $doneBy
    doneFor: $doneFor
    id: $id
    stageCandidateId: $stageCandidateId
    stageResultId: $stageResultId
    status: $status
    testOrigin: $testOrigin
    timeModifierPercentage: $timeModifierPercentage
  ) {
    ...TmTestCandidateModel
  }
}
    ${TmTestCandidateModelFragmentDoc}`;
export type TestCandidateUpdateOneMutationFn = Apollo.MutationFunction<TestCandidateUpdateOneMutation, TestCandidateUpdateOneMutationVariables>;

/**
 * __useTestCandidateUpdateOneMutation__
 *
 * To run a mutation, you first call `useTestCandidateUpdateOneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestCandidateUpdateOneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testCandidateUpdateOneMutation, { data, loading, error }] = useTestCandidateUpdateOneMutation({
 *   variables: {
 *      assessmentId: // value for 'assessmentId'
 *      doneBy: // value for 'doneBy'
 *      doneFor: // value for 'doneFor'
 *      id: // value for 'id'
 *      stageCandidateId: // value for 'stageCandidateId'
 *      stageResultId: // value for 'stageResultId'
 *      status: // value for 'status'
 *      testOrigin: // value for 'testOrigin'
 *      timeModifierPercentage: // value for 'timeModifierPercentage'
 *   },
 * });
 */
export function useTestCandidateUpdateOneMutation(baseOptions?: Apollo.MutationHookOptions<TestCandidateUpdateOneMutation, TestCandidateUpdateOneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TestCandidateUpdateOneMutation, TestCandidateUpdateOneMutationVariables>(TestCandidateUpdateOneDocument, options);
      }
export type TestCandidateUpdateOneMutationHookResult = ReturnType<typeof useTestCandidateUpdateOneMutation>;
export type TestCandidateUpdateOneMutationResult = Apollo.MutationResult<TestCandidateUpdateOneMutation>;
export type TestCandidateUpdateOneMutationOptions = Apollo.BaseMutationOptions<TestCandidateUpdateOneMutation, TestCandidateUpdateOneMutationVariables>;
export const AddTraitDocument = gql`
    mutation AddTrait($name: String!, $description: String!) {
  Trait: TraitCreate(name: $name, description: $description) {
    ...Trait
  }
}
    ${TraitFragmentDoc}`;
export type AddTraitMutationFn = Apollo.MutationFunction<AddTraitMutation, AddTraitMutationVariables>;

/**
 * __useAddTraitMutation__
 *
 * To run a mutation, you first call `useAddTraitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTraitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTraitMutation, { data, loading, error }] = useAddTraitMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddTraitMutation(baseOptions?: Apollo.MutationHookOptions<AddTraitMutation, AddTraitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTraitMutation, AddTraitMutationVariables>(AddTraitDocument, options);
      }
export type AddTraitMutationHookResult = ReturnType<typeof useAddTraitMutation>;
export type AddTraitMutationResult = Apollo.MutationResult<AddTraitMutation>;
export type AddTraitMutationOptions = Apollo.BaseMutationOptions<AddTraitMutation, AddTraitMutationVariables>;
export const UpdateTraitDocument = gql`
    mutation UpdateTrait($id: Int!, $name: String!, $description: String!, $isArchived: Boolean!) {
  Trait: TraitUpdate(
    id: $id
    name: $name
    description: $description
    isArchived: $isArchived
  ) {
    ...Trait
  }
}
    ${TraitFragmentDoc}`;
export type UpdateTraitMutationFn = Apollo.MutationFunction<UpdateTraitMutation, UpdateTraitMutationVariables>;

/**
 * __useUpdateTraitMutation__
 *
 * To run a mutation, you first call `useUpdateTraitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTraitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTraitMutation, { data, loading, error }] = useUpdateTraitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useUpdateTraitMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTraitMutation, UpdateTraitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTraitMutation, UpdateTraitMutationVariables>(UpdateTraitDocument, options);
      }
export type UpdateTraitMutationHookResult = ReturnType<typeof useUpdateTraitMutation>;
export type UpdateTraitMutationResult = Apollo.MutationResult<UpdateTraitMutation>;
export type UpdateTraitMutationOptions = Apollo.BaseMutationOptions<UpdateTraitMutation, UpdateTraitMutationVariables>;
export const UpdateTraitTestTypesDocument = gql`
    mutation UpdateTraitTestTypes($dto: [TraitTestTypesUpdateOneArgs!]!) {
  traitTestTypes: TraitTestTypeReplaceMany(dto: $dto) {
    ...TraitTestType
  }
}
    ${TraitTestTypeFragmentDoc}`;
export type UpdateTraitTestTypesMutationFn = Apollo.MutationFunction<UpdateTraitTestTypesMutation, UpdateTraitTestTypesMutationVariables>;

/**
 * __useUpdateTraitTestTypesMutation__
 *
 * To run a mutation, you first call `useUpdateTraitTestTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTraitTestTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTraitTestTypesMutation, { data, loading, error }] = useUpdateTraitTestTypesMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateTraitTestTypesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTraitTestTypesMutation, UpdateTraitTestTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTraitTestTypesMutation, UpdateTraitTestTypesMutationVariables>(UpdateTraitTestTypesDocument, options);
      }
export type UpdateTraitTestTypesMutationHookResult = ReturnType<typeof useUpdateTraitTestTypesMutation>;
export type UpdateTraitTestTypesMutationResult = Apollo.MutationResult<UpdateTraitTestTypesMutation>;
export type UpdateTraitTestTypesMutationOptions = Apollo.BaseMutationOptions<UpdateTraitTestTypesMutation, UpdateTraitTestTypesMutationVariables>;
export const ArchiveTraitDocument = gql`
    mutation ArchiveTrait($id: Int!, $isArchived: Boolean!) {
  Trait: TraitUpdateIsArchived(id: $id, isArchived: $isArchived) {
    ...Trait
  }
}
    ${TraitFragmentDoc}`;
export type ArchiveTraitMutationFn = Apollo.MutationFunction<ArchiveTraitMutation, ArchiveTraitMutationVariables>;

/**
 * __useArchiveTraitMutation__
 *
 * To run a mutation, you first call `useArchiveTraitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveTraitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveTraitMutation, { data, loading, error }] = useArchiveTraitMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useArchiveTraitMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveTraitMutation, ArchiveTraitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveTraitMutation, ArchiveTraitMutationVariables>(ArchiveTraitDocument, options);
      }
export type ArchiveTraitMutationHookResult = ReturnType<typeof useArchiveTraitMutation>;
export type ArchiveTraitMutationResult = Apollo.MutationResult<ArchiveTraitMutation>;
export type ArchiveTraitMutationOptions = Apollo.BaseMutationOptions<ArchiveTraitMutation, ArchiveTraitMutationVariables>;
export const DeleteTraitDocument = gql`
    mutation DeleteTrait($id: Int!) {
  affected: TraitDelete(id: $id) {
    affected
  }
}
    `;
export type DeleteTraitMutationFn = Apollo.MutationFunction<DeleteTraitMutation, DeleteTraitMutationVariables>;

/**
 * __useDeleteTraitMutation__
 *
 * To run a mutation, you first call `useDeleteTraitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTraitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTraitMutation, { data, loading, error }] = useDeleteTraitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTraitMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTraitMutation, DeleteTraitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTraitMutation, DeleteTraitMutationVariables>(DeleteTraitDocument, options);
      }
export type DeleteTraitMutationHookResult = ReturnType<typeof useDeleteTraitMutation>;
export type DeleteTraitMutationResult = Apollo.MutationResult<DeleteTraitMutation>;
export type DeleteTraitMutationOptions = Apollo.BaseMutationOptions<DeleteTraitMutation, DeleteTraitMutationVariables>;
export const SaveTraitContentDocument = gql`
    mutation SaveTraitContent($id: Int!, $summary: String, $content: String, $developmentContent: String) {
  traitContent: TraitRoleLevelContentUpdate(
    id: $id
    summary: $summary
    content: $content
    developmentContent: $developmentContent
  ) {
    id
    softSkillId
    traitId
    classification
    roleLevel
    summary
    content
    developmentContent
  }
}
    `;
export type SaveTraitContentMutationFn = Apollo.MutationFunction<SaveTraitContentMutation, SaveTraitContentMutationVariables>;

/**
 * __useSaveTraitContentMutation__
 *
 * To run a mutation, you first call `useSaveTraitContentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTraitContentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTraitContentMutation, { data, loading, error }] = useSaveTraitContentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      summary: // value for 'summary'
 *      content: // value for 'content'
 *      developmentContent: // value for 'developmentContent'
 *   },
 * });
 */
export function useSaveTraitContentMutation(baseOptions?: Apollo.MutationHookOptions<SaveTraitContentMutation, SaveTraitContentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTraitContentMutation, SaveTraitContentMutationVariables>(SaveTraitContentDocument, options);
      }
export type SaveTraitContentMutationHookResult = ReturnType<typeof useSaveTraitContentMutation>;
export type SaveTraitContentMutationResult = Apollo.MutationResult<SaveTraitContentMutation>;
export type SaveTraitContentMutationOptions = Apollo.BaseMutationOptions<SaveTraitContentMutation, SaveTraitContentMutationVariables>;
export const UserDeleteDocument = gql`
    mutation UserDelete($id: Int!) {
  UserDelete(id: $id) {
    affected
  }
}
    `;
export type UserDeleteMutationFn = Apollo.MutationFunction<UserDeleteMutation, UserDeleteMutationVariables>;

/**
 * __useUserDeleteMutation__
 *
 * To run a mutation, you first call `useUserDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteMutation, { data, loading, error }] = useUserDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteMutation, UserDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteMutation, UserDeleteMutationVariables>(UserDeleteDocument, options);
      }
export type UserDeleteMutationHookResult = ReturnType<typeof useUserDeleteMutation>;
export type UserDeleteMutationResult = Apollo.MutationResult<UserDeleteMutation>;
export type UserDeleteMutationOptions = Apollo.BaseMutationOptions<UserDeleteMutation, UserDeleteMutationVariables>;
export const UserDeleteByEmailDocument = gql`
    mutation UserDeleteByEmail($email: String!) {
  UserDeleteByEmail(email: $email) {
    affected
  }
}
    `;
export type UserDeleteByEmailMutationFn = Apollo.MutationFunction<UserDeleteByEmailMutation, UserDeleteByEmailMutationVariables>;

/**
 * __useUserDeleteByEmailMutation__
 *
 * To run a mutation, you first call `useUserDeleteByEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserDeleteByEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userDeleteByEmailMutation, { data, loading, error }] = useUserDeleteByEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserDeleteByEmailMutation(baseOptions?: Apollo.MutationHookOptions<UserDeleteByEmailMutation, UserDeleteByEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserDeleteByEmailMutation, UserDeleteByEmailMutationVariables>(UserDeleteByEmailDocument, options);
      }
export type UserDeleteByEmailMutationHookResult = ReturnType<typeof useUserDeleteByEmailMutation>;
export type UserDeleteByEmailMutationResult = Apollo.MutationResult<UserDeleteByEmailMutation>;
export type UserDeleteByEmailMutationOptions = Apollo.BaseMutationOptions<UserDeleteByEmailMutation, UserDeleteByEmailMutationVariables>;
export const UserCreateDocument = gql`
    mutation UserCreate($firstName: String!, $lastName: String!, $email: String!) {
  User: UserCreate(firstName: $firstName, lastName: $lastName, email: $email) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UserCreateMutationFn = Apollo.MutationFunction<UserCreateMutation, UserCreateMutationVariables>;

/**
 * __useUserCreateMutation__
 *
 * To run a mutation, you first call `useUserCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userCreateMutation, { data, loading, error }] = useUserCreateMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserCreateMutation, UserCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserCreateMutation, UserCreateMutationVariables>(UserCreateDocument, options);
      }
export type UserCreateMutationHookResult = ReturnType<typeof useUserCreateMutation>;
export type UserCreateMutationResult = Apollo.MutationResult<UserCreateMutation>;
export type UserCreateMutationOptions = Apollo.BaseMutationOptions<UserCreateMutation, UserCreateMutationVariables>;
export const UserUpdateDocument = gql`
    mutation UserUpdate($id: Int!, $firstName: String!, $lastName: String!, $email: String!) {
  User: UserUpdate(
    id: $id
    firstName: $firstName
    lastName: $lastName
    email: $email
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UserUpdateMutationFn = Apollo.MutationFunction<UserUpdateMutation, UserUpdateMutationVariables>;

/**
 * __useUserUpdateMutation__
 *
 * To run a mutation, you first call `useUserUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdateMutation, { data, loading, error }] = useUserUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *   },
 * });
 */
export function useUserUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdateMutation, UserUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UserUpdateDocument, options);
      }
export type UserUpdateMutationHookResult = ReturnType<typeof useUserUpdateMutation>;
export type UserUpdateMutationResult = Apollo.MutationResult<UserUpdateMutation>;
export type UserUpdateMutationOptions = Apollo.BaseMutationOptions<UserUpdateMutation, UserUpdateMutationVariables>;
export const UserRoleUpdateDocument = gql`
    mutation UserRoleUpdate($companyId: Float, $role: IdentityPermissionRole, $externalId: String, $id: Float!) {
  UserRole: UserRoleUpdate(
    companyId: $companyId
    role: $role
    externalId: $externalId
    id: $id
  ) {
    ...UserRole
  }
}
    ${UserRoleFragmentDoc}`;
export type UserRoleUpdateMutationFn = Apollo.MutationFunction<UserRoleUpdateMutation, UserRoleUpdateMutationVariables>;

/**
 * __useUserRoleUpdateMutation__
 *
 * To run a mutation, you first call `useUserRoleUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRoleUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRoleUpdateMutation, { data, loading, error }] = useUserRoleUpdateMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      role: // value for 'role'
 *      externalId: // value for 'externalId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRoleUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserRoleUpdateMutation, UserRoleUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRoleUpdateMutation, UserRoleUpdateMutationVariables>(UserRoleUpdateDocument, options);
      }
export type UserRoleUpdateMutationHookResult = ReturnType<typeof useUserRoleUpdateMutation>;
export type UserRoleUpdateMutationResult = Apollo.MutationResult<UserRoleUpdateMutation>;
export type UserRoleUpdateMutationOptions = Apollo.BaseMutationOptions<UserRoleUpdateMutation, UserRoleUpdateMutationVariables>;
export const UserRoleCreateDocument = gql`
    mutation UserRoleCreate($userId: Float!, $companyId: Float!, $role: IdentityPermissionRole!, $externalId: String) {
  UserRoleCreate(
    userId: $userId
    companyId: $companyId
    role: $role
    externalId: $externalId
  ) {
    ...UserRole
  }
}
    ${UserRoleFragmentDoc}`;
export type UserRoleCreateMutationFn = Apollo.MutationFunction<UserRoleCreateMutation, UserRoleCreateMutationVariables>;

/**
 * __useUserRoleCreateMutation__
 *
 * To run a mutation, you first call `useUserRoleCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRoleCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRoleCreateMutation, { data, loading, error }] = useUserRoleCreateMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      companyId: // value for 'companyId'
 *      role: // value for 'role'
 *      externalId: // value for 'externalId'
 *   },
 * });
 */
export function useUserRoleCreateMutation(baseOptions?: Apollo.MutationHookOptions<UserRoleCreateMutation, UserRoleCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRoleCreateMutation, UserRoleCreateMutationVariables>(UserRoleCreateDocument, options);
      }
export type UserRoleCreateMutationHookResult = ReturnType<typeof useUserRoleCreateMutation>;
export type UserRoleCreateMutationResult = Apollo.MutationResult<UserRoleCreateMutation>;
export type UserRoleCreateMutationOptions = Apollo.BaseMutationOptions<UserRoleCreateMutation, UserRoleCreateMutationVariables>;
export const UserRoleDeleteDocument = gql`
    mutation UserRoleDelete($id: Int!) {
  UserRoleDelete(id: $id) {
    affected
  }
}
    `;
export type UserRoleDeleteMutationFn = Apollo.MutationFunction<UserRoleDeleteMutation, UserRoleDeleteMutationVariables>;

/**
 * __useUserRoleDeleteMutation__
 *
 * To run a mutation, you first call `useUserRoleDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserRoleDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userRoleDeleteMutation, { data, loading, error }] = useUserRoleDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserRoleDeleteMutation(baseOptions?: Apollo.MutationHookOptions<UserRoleDeleteMutation, UserRoleDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserRoleDeleteMutation, UserRoleDeleteMutationVariables>(UserRoleDeleteDocument, options);
      }
export type UserRoleDeleteMutationHookResult = ReturnType<typeof useUserRoleDeleteMutation>;
export type UserRoleDeleteMutationResult = Apollo.MutationResult<UserRoleDeleteMutation>;
export type UserRoleDeleteMutationOptions = Apollo.BaseMutationOptions<UserRoleDeleteMutation, UserRoleDeleteMutationVariables>;
export const WidgetOptionUpdateDocument = gql`
    mutation WidgetOptionUpdate($name: String!, $question: String, $defaultValue: String, $id: Int!, $fieldType: WidgetOptionFieldType!) {
  WidgetOptions: WidgetOptionUpdate(
    name: $name
    question: $question
    defaultValue: $defaultValue
    id: $id
    fieldType: $fieldType
  ) {
    id
    name
    fieldType
    defaultValue
    question
    isArchived
    widgetId
  }
}
    `;
export type WidgetOptionUpdateMutationFn = Apollo.MutationFunction<WidgetOptionUpdateMutation, WidgetOptionUpdateMutationVariables>;

/**
 * __useWidgetOptionUpdateMutation__
 *
 * To run a mutation, you first call `useWidgetOptionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetOptionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetOptionUpdateMutation, { data, loading, error }] = useWidgetOptionUpdateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      question: // value for 'question'
 *      defaultValue: // value for 'defaultValue'
 *      id: // value for 'id'
 *      fieldType: // value for 'fieldType'
 *   },
 * });
 */
export function useWidgetOptionUpdateMutation(baseOptions?: Apollo.MutationHookOptions<WidgetOptionUpdateMutation, WidgetOptionUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetOptionUpdateMutation, WidgetOptionUpdateMutationVariables>(WidgetOptionUpdateDocument, options);
      }
export type WidgetOptionUpdateMutationHookResult = ReturnType<typeof useWidgetOptionUpdateMutation>;
export type WidgetOptionUpdateMutationResult = Apollo.MutationResult<WidgetOptionUpdateMutation>;
export type WidgetOptionUpdateMutationOptions = Apollo.BaseMutationOptions<WidgetOptionUpdateMutation, WidgetOptionUpdateMutationVariables>;
export const WidgetUpdateIsArchivedDocument = gql`
    mutation WidgetUpdateIsArchived($id: Int!, $isArchived: Boolean!) {
  WidgetUpdateIsArchived(id: $id, isArchived: $isArchived) {
    ...Widget
  }
}
    ${WidgetFragmentDoc}`;
export type WidgetUpdateIsArchivedMutationFn = Apollo.MutationFunction<WidgetUpdateIsArchivedMutation, WidgetUpdateIsArchivedMutationVariables>;

/**
 * __useWidgetUpdateIsArchivedMutation__
 *
 * To run a mutation, you first call `useWidgetUpdateIsArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetUpdateIsArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetUpdateIsArchivedMutation, { data, loading, error }] = useWidgetUpdateIsArchivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useWidgetUpdateIsArchivedMutation(baseOptions?: Apollo.MutationHookOptions<WidgetUpdateIsArchivedMutation, WidgetUpdateIsArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetUpdateIsArchivedMutation, WidgetUpdateIsArchivedMutationVariables>(WidgetUpdateIsArchivedDocument, options);
      }
export type WidgetUpdateIsArchivedMutationHookResult = ReturnType<typeof useWidgetUpdateIsArchivedMutation>;
export type WidgetUpdateIsArchivedMutationResult = Apollo.MutationResult<WidgetUpdateIsArchivedMutation>;
export type WidgetUpdateIsArchivedMutationOptions = Apollo.BaseMutationOptions<WidgetUpdateIsArchivedMutation, WidgetUpdateIsArchivedMutationVariables>;
export const WidgetOptionUpdateIsArchivedDocument = gql`
    mutation WidgetOptionUpdateIsArchived($id: Int!, $isArchived: Boolean!) {
  WidgetOptionUpdateIsArchived(id: $id, isArchived: $isArchived) {
    id
    name
    question
    isArchived
    fieldType
    defaultValue
  }
}
    `;
export type WidgetOptionUpdateIsArchivedMutationFn = Apollo.MutationFunction<WidgetOptionUpdateIsArchivedMutation, WidgetOptionUpdateIsArchivedMutationVariables>;

/**
 * __useWidgetOptionUpdateIsArchivedMutation__
 *
 * To run a mutation, you first call `useWidgetOptionUpdateIsArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetOptionUpdateIsArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetOptionUpdateIsArchivedMutation, { data, loading, error }] = useWidgetOptionUpdateIsArchivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useWidgetOptionUpdateIsArchivedMutation(baseOptions?: Apollo.MutationHookOptions<WidgetOptionUpdateIsArchivedMutation, WidgetOptionUpdateIsArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetOptionUpdateIsArchivedMutation, WidgetOptionUpdateIsArchivedMutationVariables>(WidgetOptionUpdateIsArchivedDocument, options);
      }
export type WidgetOptionUpdateIsArchivedMutationHookResult = ReturnType<typeof useWidgetOptionUpdateIsArchivedMutation>;
export type WidgetOptionUpdateIsArchivedMutationResult = Apollo.MutationResult<WidgetOptionUpdateIsArchivedMutation>;
export type WidgetOptionUpdateIsArchivedMutationOptions = Apollo.BaseMutationOptions<WidgetOptionUpdateIsArchivedMutation, WidgetOptionUpdateIsArchivedMutationVariables>;
export const WidgetDeleteDocument = gql`
    mutation WidgetDelete($id: Int!) {
  WidgetDelete(id: $id) {
    affected
  }
}
    `;
export type WidgetDeleteMutationFn = Apollo.MutationFunction<WidgetDeleteMutation, WidgetDeleteMutationVariables>;

/**
 * __useWidgetDeleteMutation__
 *
 * To run a mutation, you first call `useWidgetDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetDeleteMutation, { data, loading, error }] = useWidgetDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWidgetDeleteMutation(baseOptions?: Apollo.MutationHookOptions<WidgetDeleteMutation, WidgetDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetDeleteMutation, WidgetDeleteMutationVariables>(WidgetDeleteDocument, options);
      }
export type WidgetDeleteMutationHookResult = ReturnType<typeof useWidgetDeleteMutation>;
export type WidgetDeleteMutationResult = Apollo.MutationResult<WidgetDeleteMutation>;
export type WidgetDeleteMutationOptions = Apollo.BaseMutationOptions<WidgetDeleteMutation, WidgetDeleteMutationVariables>;
export const WidgetOptionDeleteDocument = gql`
    mutation WidgetOptionDelete($id: Int!) {
  WidgetOptionDelete(id: $id) {
    affected
  }
}
    `;
export type WidgetOptionDeleteMutationFn = Apollo.MutationFunction<WidgetOptionDeleteMutation, WidgetOptionDeleteMutationVariables>;

/**
 * __useWidgetOptionDeleteMutation__
 *
 * To run a mutation, you first call `useWidgetOptionDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetOptionDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetOptionDeleteMutation, { data, loading, error }] = useWidgetOptionDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWidgetOptionDeleteMutation(baseOptions?: Apollo.MutationHookOptions<WidgetOptionDeleteMutation, WidgetOptionDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetOptionDeleteMutation, WidgetOptionDeleteMutationVariables>(WidgetOptionDeleteDocument, options);
      }
export type WidgetOptionDeleteMutationHookResult = ReturnType<typeof useWidgetOptionDeleteMutation>;
export type WidgetOptionDeleteMutationResult = Apollo.MutationResult<WidgetOptionDeleteMutation>;
export type WidgetOptionDeleteMutationOptions = Apollo.BaseMutationOptions<WidgetOptionDeleteMutation, WidgetOptionDeleteMutationVariables>;
export const WidgetCreateDocument = gql`
    mutation WidgetCreate($type: WidgetType!, $name: String!) {
  Widget: WidgetCreate(name: $name, type: $type) {
    ...Widget
  }
}
    ${WidgetFragmentDoc}`;
export type WidgetCreateMutationFn = Apollo.MutationFunction<WidgetCreateMutation, WidgetCreateMutationVariables>;

/**
 * __useWidgetCreateMutation__
 *
 * To run a mutation, you first call `useWidgetCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetCreateMutation, { data, loading, error }] = useWidgetCreateMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useWidgetCreateMutation(baseOptions?: Apollo.MutationHookOptions<WidgetCreateMutation, WidgetCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetCreateMutation, WidgetCreateMutationVariables>(WidgetCreateDocument, options);
      }
export type WidgetCreateMutationHookResult = ReturnType<typeof useWidgetCreateMutation>;
export type WidgetCreateMutationResult = Apollo.MutationResult<WidgetCreateMutation>;
export type WidgetCreateMutationOptions = Apollo.BaseMutationOptions<WidgetCreateMutation, WidgetCreateMutationVariables>;
export const WidgetUploadScreenShotDocument = gql`
    mutation WidgetUploadScreenShot($id: Int!, $file: Upload!) {
  WidgetUploadScreenShot(id: $id, file: $file) {
    id
    name
    type
  }
}
    `;
export type WidgetUploadScreenShotMutationFn = Apollo.MutationFunction<WidgetUploadScreenShotMutation, WidgetUploadScreenShotMutationVariables>;

/**
 * __useWidgetUploadScreenShotMutation__
 *
 * To run a mutation, you first call `useWidgetUploadScreenShotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetUploadScreenShotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetUploadScreenShotMutation, { data, loading, error }] = useWidgetUploadScreenShotMutation({
 *   variables: {
 *      id: // value for 'id'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useWidgetUploadScreenShotMutation(baseOptions?: Apollo.MutationHookOptions<WidgetUploadScreenShotMutation, WidgetUploadScreenShotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetUploadScreenShotMutation, WidgetUploadScreenShotMutationVariables>(WidgetUploadScreenShotDocument, options);
      }
export type WidgetUploadScreenShotMutationHookResult = ReturnType<typeof useWidgetUploadScreenShotMutation>;
export type WidgetUploadScreenShotMutationResult = Apollo.MutationResult<WidgetUploadScreenShotMutation>;
export type WidgetUploadScreenShotMutationOptions = Apollo.BaseMutationOptions<WidgetUploadScreenShotMutation, WidgetUploadScreenShotMutationVariables>;
export const WidgetUpdateDocument = gql`
    mutation WidgetUpdate($type: WidgetType, $name: String, $id: Int!) {
  WidgetUpdate(id: $id, name: $name, type: $type) {
    id
    name
    type
  }
}
    `;
export type WidgetUpdateMutationFn = Apollo.MutationFunction<WidgetUpdateMutation, WidgetUpdateMutationVariables>;

/**
 * __useWidgetUpdateMutation__
 *
 * To run a mutation, you first call `useWidgetUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetUpdateMutation, { data, loading, error }] = useWidgetUpdateMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWidgetUpdateMutation(baseOptions?: Apollo.MutationHookOptions<WidgetUpdateMutation, WidgetUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetUpdateMutation, WidgetUpdateMutationVariables>(WidgetUpdateDocument, options);
      }
export type WidgetUpdateMutationHookResult = ReturnType<typeof useWidgetUpdateMutation>;
export type WidgetUpdateMutationResult = Apollo.MutationResult<WidgetUpdateMutation>;
export type WidgetUpdateMutationOptions = Apollo.BaseMutationOptions<WidgetUpdateMutation, WidgetUpdateMutationVariables>;
export const WidgetOptionCreateDocument = gql`
    mutation WidgetOptionCreate($name: String!, $question: String, $defaultValue: String, $widgetId: Int!, $fieldType: WidgetOptionFieldType!) {
  WidgetOptions: WidgetOptionCreate(
    name: $name
    question: $question
    defaultValue: $defaultValue
    widgetId: $widgetId
    fieldType: $fieldType
  ) {
    id
    name
    fieldType
    defaultValue
    question
    isArchived
    widgetId
  }
}
    `;
export type WidgetOptionCreateMutationFn = Apollo.MutationFunction<WidgetOptionCreateMutation, WidgetOptionCreateMutationVariables>;

/**
 * __useWidgetOptionCreateMutation__
 *
 * To run a mutation, you first call `useWidgetOptionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetOptionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetOptionCreateMutation, { data, loading, error }] = useWidgetOptionCreateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      question: // value for 'question'
 *      defaultValue: // value for 'defaultValue'
 *      widgetId: // value for 'widgetId'
 *      fieldType: // value for 'fieldType'
 *   },
 * });
 */
export function useWidgetOptionCreateMutation(baseOptions?: Apollo.MutationHookOptions<WidgetOptionCreateMutation, WidgetOptionCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetOptionCreateMutation, WidgetOptionCreateMutationVariables>(WidgetOptionCreateDocument, options);
      }
export type WidgetOptionCreateMutationHookResult = ReturnType<typeof useWidgetOptionCreateMutation>;
export type WidgetOptionCreateMutationResult = Apollo.MutationResult<WidgetOptionCreateMutation>;
export type WidgetOptionCreateMutationOptions = Apollo.BaseMutationOptions<WidgetOptionCreateMutation, WidgetOptionCreateMutationVariables>;
export const WidgetOptionUploadCoverPageImageDocument = gql`
    mutation WidgetOptionUploadCoverPageImage($file: Upload!) {
  WidgetOptionUploadCoverPageImage(file: $file) {
    id
    url
  }
}
    `;
export type WidgetOptionUploadCoverPageImageMutationFn = Apollo.MutationFunction<WidgetOptionUploadCoverPageImageMutation, WidgetOptionUploadCoverPageImageMutationVariables>;

/**
 * __useWidgetOptionUploadCoverPageImageMutation__
 *
 * To run a mutation, you first call `useWidgetOptionUploadCoverPageImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetOptionUploadCoverPageImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetOptionUploadCoverPageImageMutation, { data, loading, error }] = useWidgetOptionUploadCoverPageImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useWidgetOptionUploadCoverPageImageMutation(baseOptions?: Apollo.MutationHookOptions<WidgetOptionUploadCoverPageImageMutation, WidgetOptionUploadCoverPageImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetOptionUploadCoverPageImageMutation, WidgetOptionUploadCoverPageImageMutationVariables>(WidgetOptionUploadCoverPageImageDocument, options);
      }
export type WidgetOptionUploadCoverPageImageMutationHookResult = ReturnType<typeof useWidgetOptionUploadCoverPageImageMutation>;
export type WidgetOptionUploadCoverPageImageMutationResult = Apollo.MutationResult<WidgetOptionUploadCoverPageImageMutation>;
export type WidgetOptionUploadCoverPageImageMutationOptions = Apollo.BaseMutationOptions<WidgetOptionUploadCoverPageImageMutation, WidgetOptionUploadCoverPageImageMutationVariables>;
export const WidgetOptionUploadBrandedSoftSkillImageDocument = gql`
    mutation WidgetOptionUploadBrandedSoftSkillImage($file: Upload!) {
  WidgetOptionUploadBrandedSoftSkillImage(file: $file) {
    id
    url
  }
}
    `;
export type WidgetOptionUploadBrandedSoftSkillImageMutationFn = Apollo.MutationFunction<WidgetOptionUploadBrandedSoftSkillImageMutation, WidgetOptionUploadBrandedSoftSkillImageMutationVariables>;

/**
 * __useWidgetOptionUploadBrandedSoftSkillImageMutation__
 *
 * To run a mutation, you first call `useWidgetOptionUploadBrandedSoftSkillImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWidgetOptionUploadBrandedSoftSkillImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [widgetOptionUploadBrandedSoftSkillImageMutation, { data, loading, error }] = useWidgetOptionUploadBrandedSoftSkillImageMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useWidgetOptionUploadBrandedSoftSkillImageMutation(baseOptions?: Apollo.MutationHookOptions<WidgetOptionUploadBrandedSoftSkillImageMutation, WidgetOptionUploadBrandedSoftSkillImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WidgetOptionUploadBrandedSoftSkillImageMutation, WidgetOptionUploadBrandedSoftSkillImageMutationVariables>(WidgetOptionUploadBrandedSoftSkillImageDocument, options);
      }
export type WidgetOptionUploadBrandedSoftSkillImageMutationHookResult = ReturnType<typeof useWidgetOptionUploadBrandedSoftSkillImageMutation>;
export type WidgetOptionUploadBrandedSoftSkillImageMutationResult = Apollo.MutationResult<WidgetOptionUploadBrandedSoftSkillImageMutation>;
export type WidgetOptionUploadBrandedSoftSkillImageMutationOptions = Apollo.BaseMutationOptions<WidgetOptionUploadBrandedSoftSkillImageMutation, WidgetOptionUploadBrandedSoftSkillImageMutationVariables>;
export const SkillCategoryFindManyDocument = gql`
    query SkillCategoryFindMany {
  SkillCategoryFindMany {
    id
    name
  }
}
    `;

/**
 * __useSkillCategoryFindManyQuery__
 *
 * To run a query within a React component, call `useSkillCategoryFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSkillCategoryFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSkillCategoryFindManyQuery({
 *   variables: {
 *   },
 * });
 */
export function useSkillCategoryFindManyQuery(baseOptions?: Apollo.QueryHookOptions<SkillCategoryFindManyQuery, SkillCategoryFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SkillCategoryFindManyQuery, SkillCategoryFindManyQueryVariables>(SkillCategoryFindManyDocument, options);
      }
export function useSkillCategoryFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SkillCategoryFindManyQuery, SkillCategoryFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SkillCategoryFindManyQuery, SkillCategoryFindManyQueryVariables>(SkillCategoryFindManyDocument, options);
        }
export type SkillCategoryFindManyQueryHookResult = ReturnType<typeof useSkillCategoryFindManyQuery>;
export type SkillCategoryFindManyLazyQueryHookResult = ReturnType<typeof useSkillCategoryFindManyLazyQuery>;
export type SkillCategoryFindManyQueryResult = Apollo.QueryResult<SkillCategoryFindManyQuery, SkillCategoryFindManyQueryVariables>;
export const ApiKeyFindAllDocument = gql`
    query ApiKeyFindAll {
  ApiKeyFindAll {
    ...ApiKey
  }
}
    ${ApiKeyFragmentDoc}`;

/**
 * __useApiKeyFindAllQuery__
 *
 * To run a query within a React component, call `useApiKeyFindAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useApiKeyFindAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApiKeyFindAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useApiKeyFindAllQuery(baseOptions?: Apollo.QueryHookOptions<ApiKeyFindAllQuery, ApiKeyFindAllQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApiKeyFindAllQuery, ApiKeyFindAllQueryVariables>(ApiKeyFindAllDocument, options);
      }
export function useApiKeyFindAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApiKeyFindAllQuery, ApiKeyFindAllQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApiKeyFindAllQuery, ApiKeyFindAllQueryVariables>(ApiKeyFindAllDocument, options);
        }
export type ApiKeyFindAllQueryHookResult = ReturnType<typeof useApiKeyFindAllQuery>;
export type ApiKeyFindAllLazyQueryHookResult = ReturnType<typeof useApiKeyFindAllLazyQuery>;
export type ApiKeyFindAllQueryResult = Apollo.QueryResult<ApiKeyFindAllQuery, ApiKeyFindAllQueryVariables>;
export const BaseMethodTestFindManySearchPaginatedDocument = gql`
    query BaseMethodTestFindManySearchPaginated($textSearch: String!) {
  BaseMethodTestFindManySearchPaginated(
    textSearch: $textSearch
    skip: 0
    take: 10
  ) {
    baseMethodTests {
      id
      name
    }
    total
  }
}
    `;

/**
 * __useBaseMethodTestFindManySearchPaginatedQuery__
 *
 * To run a query within a React component, call `useBaseMethodTestFindManySearchPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useBaseMethodTestFindManySearchPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBaseMethodTestFindManySearchPaginatedQuery({
 *   variables: {
 *      textSearch: // value for 'textSearch'
 *   },
 * });
 */
export function useBaseMethodTestFindManySearchPaginatedQuery(baseOptions: Apollo.QueryHookOptions<BaseMethodTestFindManySearchPaginatedQuery, BaseMethodTestFindManySearchPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BaseMethodTestFindManySearchPaginatedQuery, BaseMethodTestFindManySearchPaginatedQueryVariables>(BaseMethodTestFindManySearchPaginatedDocument, options);
      }
export function useBaseMethodTestFindManySearchPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BaseMethodTestFindManySearchPaginatedQuery, BaseMethodTestFindManySearchPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BaseMethodTestFindManySearchPaginatedQuery, BaseMethodTestFindManySearchPaginatedQueryVariables>(BaseMethodTestFindManySearchPaginatedDocument, options);
        }
export type BaseMethodTestFindManySearchPaginatedQueryHookResult = ReturnType<typeof useBaseMethodTestFindManySearchPaginatedQuery>;
export type BaseMethodTestFindManySearchPaginatedLazyQueryHookResult = ReturnType<typeof useBaseMethodTestFindManySearchPaginatedLazyQuery>;
export type BaseMethodTestFindManySearchPaginatedQueryResult = Apollo.QueryResult<BaseMethodTestFindManySearchPaginatedQuery, BaseMethodTestFindManySearchPaginatedQueryVariables>;
export const BlobFindByNameDocument = gql`
    query BlobFindByName($name: String!, $getOption: String!) {
  Blob: BlobFindByName(name: $name, getOption: $getOption) {
    ...BlobUrl
  }
}
    ${BlobUrlFragmentDoc}`;

/**
 * __useBlobFindByNameQuery__
 *
 * To run a query within a React component, call `useBlobFindByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlobFindByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlobFindByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      getOption: // value for 'getOption'
 *   },
 * });
 */
export function useBlobFindByNameQuery(baseOptions: Apollo.QueryHookOptions<BlobFindByNameQuery, BlobFindByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BlobFindByNameQuery, BlobFindByNameQueryVariables>(BlobFindByNameDocument, options);
      }
export function useBlobFindByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BlobFindByNameQuery, BlobFindByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BlobFindByNameQuery, BlobFindByNameQueryVariables>(BlobFindByNameDocument, options);
        }
export type BlobFindByNameQueryHookResult = ReturnType<typeof useBlobFindByNameQuery>;
export type BlobFindByNameLazyQueryHookResult = ReturnType<typeof useBlobFindByNameLazyQuery>;
export type BlobFindByNameQueryResult = Apollo.QueryResult<BlobFindByNameQuery, BlobFindByNameQueryVariables>;
export const CompaniesDocument = gql`
    query Companies {
  Companies: companies {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocument = gql`
    query Company($id: Int!) {
  company(id: $id) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const CompanySignatureFindManyDocument = gql`
    query CompanySignatureFindMany($companyId: Int) {
  CompanySignature: CompanySignatureFindMany(companyId: $companyId) {
    ...CompanySignatureModel
  }
}
    ${CompanySignatureModelFragmentDoc}`;

/**
 * __useCompanySignatureFindManyQuery__
 *
 * To run a query within a React component, call `useCompanySignatureFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanySignatureFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanySignatureFindManyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanySignatureFindManyQuery(baseOptions?: Apollo.QueryHookOptions<CompanySignatureFindManyQuery, CompanySignatureFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanySignatureFindManyQuery, CompanySignatureFindManyQueryVariables>(CompanySignatureFindManyDocument, options);
      }
export function useCompanySignatureFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanySignatureFindManyQuery, CompanySignatureFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanySignatureFindManyQuery, CompanySignatureFindManyQueryVariables>(CompanySignatureFindManyDocument, options);
        }
export type CompanySignatureFindManyQueryHookResult = ReturnType<typeof useCompanySignatureFindManyQuery>;
export type CompanySignatureFindManyLazyQueryHookResult = ReturnType<typeof useCompanySignatureFindManyLazyQuery>;
export type CompanySignatureFindManyQueryResult = Apollo.QueryResult<CompanySignatureFindManyQuery, CompanySignatureFindManyQueryVariables>;
export const CompanyFindManyByIdsDocument = gql`
    query CompanyFindManyByIds($ids: [Int!]!) {
  CompanyFindManyByIds: companyFindManyByIds(ids: $ids) {
    ...Company
  }
}
    ${CompanyFragmentDoc}`;

/**
 * __useCompanyFindManyByIdsQuery__
 *
 * To run a query within a React component, call `useCompanyFindManyByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFindManyByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFindManyByIdsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useCompanyFindManyByIdsQuery(baseOptions: Apollo.QueryHookOptions<CompanyFindManyByIdsQuery, CompanyFindManyByIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyFindManyByIdsQuery, CompanyFindManyByIdsQueryVariables>(CompanyFindManyByIdsDocument, options);
      }
export function useCompanyFindManyByIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyFindManyByIdsQuery, CompanyFindManyByIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyFindManyByIdsQuery, CompanyFindManyByIdsQueryVariables>(CompanyFindManyByIdsDocument, options);
        }
export type CompanyFindManyByIdsQueryHookResult = ReturnType<typeof useCompanyFindManyByIdsQuery>;
export type CompanyFindManyByIdsLazyQueryHookResult = ReturnType<typeof useCompanyFindManyByIdsLazyQuery>;
export type CompanyFindManyByIdsQueryResult = Apollo.QueryResult<CompanyFindManyByIdsQuery, CompanyFindManyByIdsQueryVariables>;
export const GetCompanyIdDocument = gql`
    query GetCompanyId($projectId: Float!) {
  getCompanyId(id: $projectId) {
    companyId
  }
}
    `;

/**
 * __useGetCompanyIdQuery__
 *
 * To run a query within a React component, call `useGetCompanyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompanyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompanyIdQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCompanyIdQuery(baseOptions: Apollo.QueryHookOptions<GetCompanyIdQuery, GetCompanyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompanyIdQuery, GetCompanyIdQueryVariables>(GetCompanyIdDocument, options);
      }
export function useGetCompanyIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompanyIdQuery, GetCompanyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompanyIdQuery, GetCompanyIdQueryVariables>(GetCompanyIdDocument, options);
        }
export type GetCompanyIdQueryHookResult = ReturnType<typeof useGetCompanyIdQuery>;
export type GetCompanyIdLazyQueryHookResult = ReturnType<typeof useGetCompanyIdLazyQuery>;
export type GetCompanyIdQueryResult = Apollo.QueryResult<GetCompanyIdQuery, GetCompanyIdQueryVariables>;
export const CompanyFindUsersDocument = gql`
    query CompanyFindUsers($id: Int!) {
  companyUsers: CompanyFindUsers(id: $id) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useCompanyFindUsersQuery__
 *
 * To run a query within a React component, call `useCompanyFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFindUsersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyFindUsersQuery(baseOptions: Apollo.QueryHookOptions<CompanyFindUsersQuery, CompanyFindUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyFindUsersQuery, CompanyFindUsersQueryVariables>(CompanyFindUsersDocument, options);
      }
export function useCompanyFindUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyFindUsersQuery, CompanyFindUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyFindUsersQuery, CompanyFindUsersQueryVariables>(CompanyFindUsersDocument, options);
        }
export type CompanyFindUsersQueryHookResult = ReturnType<typeof useCompanyFindUsersQuery>;
export type CompanyFindUsersLazyQueryHookResult = ReturnType<typeof useCompanyFindUsersLazyQuery>;
export type CompanyFindUsersQueryResult = Apollo.QueryResult<CompanyFindUsersQuery, CompanyFindUsersQueryVariables>;
export const CompanyFindUsersPaginatedDocument = gql`
    query CompanyFindUsersPaginated($id: Int!, $skip: Int!, $take: Int!) {
  companyUsers: CompanyFindUsersPaginated(id: $id, skip: $skip, take: $take) {
    data {
      ...User
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${UserFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useCompanyFindUsersPaginatedQuery__
 *
 * To run a query within a React component, call `useCompanyFindUsersPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFindUsersPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFindUsersPaginatedQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useCompanyFindUsersPaginatedQuery(baseOptions: Apollo.QueryHookOptions<CompanyFindUsersPaginatedQuery, CompanyFindUsersPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyFindUsersPaginatedQuery, CompanyFindUsersPaginatedQueryVariables>(CompanyFindUsersPaginatedDocument, options);
      }
export function useCompanyFindUsersPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyFindUsersPaginatedQuery, CompanyFindUsersPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyFindUsersPaginatedQuery, CompanyFindUsersPaginatedQueryVariables>(CompanyFindUsersPaginatedDocument, options);
        }
export type CompanyFindUsersPaginatedQueryHookResult = ReturnType<typeof useCompanyFindUsersPaginatedQuery>;
export type CompanyFindUsersPaginatedLazyQueryHookResult = ReturnType<typeof useCompanyFindUsersPaginatedLazyQuery>;
export type CompanyFindUsersPaginatedQueryResult = Apollo.QueryResult<CompanyFindUsersPaginatedQuery, CompanyFindUsersPaginatedQueryVariables>;
export const CompanyRolesDocument = gql`
    query CompanyRoles($companyId: Int!) {
  CompanyRoles(companyId: $companyId) {
    ...CompanyRole
  }
}
    ${CompanyRoleFragmentDoc}`;

/**
 * __useCompanyRolesQuery__
 *
 * To run a query within a React component, call `useCompanyRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyRolesQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useCompanyRolesQuery(baseOptions: Apollo.QueryHookOptions<CompanyRolesQuery, CompanyRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyRolesQuery, CompanyRolesQueryVariables>(CompanyRolesDocument, options);
      }
export function useCompanyRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyRolesQuery, CompanyRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyRolesQuery, CompanyRolesQueryVariables>(CompanyRolesDocument, options);
        }
export type CompanyRolesQueryHookResult = ReturnType<typeof useCompanyRolesQuery>;
export type CompanyRolesLazyQueryHookResult = ReturnType<typeof useCompanyRolesLazyQuery>;
export type CompanyRolesQueryResult = Apollo.QueryResult<CompanyRolesQuery, CompanyRolesQueryVariables>;
export const CompanyUsersFindManyPaginatedDocument = gql`
    query CompanyUsersFindManyPaginated($args: CompanyUsersArgs!, $order: CompanyUsersOrderOptions, $paginate: OffsetBasedPaginationArgs) {
  companyUsers: CompanyUsersFindManyPaginated(
    args: $args
    order: $order
    paginate: $paginate
  ) {
    data {
      group {
        id
        subId
        companyId
        name
        description
        membersCount
        membersPreview {
          id
          firstName
          lastName
          email
        }
      }
      user {
        id
        subId
        firstName
        lastName
        email
        avatarUrl
      }
      inProjectTeam {
        asGroupMember
        asIndividual
      }
      inStageCandidate {
        asGroupMember
        asIndividual
      }
      inUserGroup {
        asGroupMember
        asIndividual
      }
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${PageInfoFragmentDoc}`;

/**
 * __useCompanyUsersFindManyPaginatedQuery__
 *
 * To run a query within a React component, call `useCompanyUsersFindManyPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyUsersFindManyPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyUsersFindManyPaginatedQuery({
 *   variables: {
 *      args: // value for 'args'
 *      order: // value for 'order'
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useCompanyUsersFindManyPaginatedQuery(baseOptions: Apollo.QueryHookOptions<CompanyUsersFindManyPaginatedQuery, CompanyUsersFindManyPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyUsersFindManyPaginatedQuery, CompanyUsersFindManyPaginatedQueryVariables>(CompanyUsersFindManyPaginatedDocument, options);
      }
export function useCompanyUsersFindManyPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyUsersFindManyPaginatedQuery, CompanyUsersFindManyPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyUsersFindManyPaginatedQuery, CompanyUsersFindManyPaginatedQueryVariables>(CompanyUsersFindManyPaginatedDocument, options);
        }
export type CompanyUsersFindManyPaginatedQueryHookResult = ReturnType<typeof useCompanyUsersFindManyPaginatedQuery>;
export type CompanyUsersFindManyPaginatedLazyQueryHookResult = ReturnType<typeof useCompanyUsersFindManyPaginatedLazyQuery>;
export type CompanyUsersFindManyPaginatedQueryResult = Apollo.QueryResult<CompanyUsersFindManyPaginatedQuery, CompanyUsersFindManyPaginatedQueryVariables>;
export const CompanyFindManyUsersWithRolesDocument = gql`
    query CompanyFindManyUsersWithRoles($take: Int!, $skip: Int!, $userRoles: [IdentityPermissionRole!]!, $companyIds: [Int!]) {
  CompanyFindManyUsersWithRoles(
    take: $take
    skip: $skip
    userRoles: $userRoles
    companyIds: $companyIds
  ) {
    ...CompanyUserInfoPaginated
  }
}
    ${CompanyUserInfoPaginatedFragmentDoc}`;

/**
 * __useCompanyFindManyUsersWithRolesQuery__
 *
 * To run a query within a React component, call `useCompanyFindManyUsersWithRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyFindManyUsersWithRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyFindManyUsersWithRolesQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      userRoles: // value for 'userRoles'
 *      companyIds: // value for 'companyIds'
 *   },
 * });
 */
export function useCompanyFindManyUsersWithRolesQuery(baseOptions: Apollo.QueryHookOptions<CompanyFindManyUsersWithRolesQuery, CompanyFindManyUsersWithRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyFindManyUsersWithRolesQuery, CompanyFindManyUsersWithRolesQueryVariables>(CompanyFindManyUsersWithRolesDocument, options);
      }
export function useCompanyFindManyUsersWithRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyFindManyUsersWithRolesQuery, CompanyFindManyUsersWithRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyFindManyUsersWithRolesQuery, CompanyFindManyUsersWithRolesQueryVariables>(CompanyFindManyUsersWithRolesDocument, options);
        }
export type CompanyFindManyUsersWithRolesQueryHookResult = ReturnType<typeof useCompanyFindManyUsersWithRolesQuery>;
export type CompanyFindManyUsersWithRolesLazyQueryHookResult = ReturnType<typeof useCompanyFindManyUsersWithRolesLazyQuery>;
export type CompanyFindManyUsersWithRolesQueryResult = Apollo.QueryResult<CompanyFindManyUsersWithRolesQuery, CompanyFindManyUsersWithRolesQueryVariables>;
export const TraitSoftSkillContextCsvGenerateAndUploadDocument = gql`
    query TraitSoftSkillContextCsvGenerateAndUpload($ids: [Int!]) {
  TraitSoftSkillContextCsvGenerateAndUpload(ids: $ids) {
    ...Blob
  }
}
    ${BlobFragmentDoc}`;

/**
 * __useTraitSoftSkillContextCsvGenerateAndUploadQuery__
 *
 * To run a query within a React component, call `useTraitSoftSkillContextCsvGenerateAndUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraitSoftSkillContextCsvGenerateAndUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraitSoftSkillContextCsvGenerateAndUploadQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTraitSoftSkillContextCsvGenerateAndUploadQuery(baseOptions?: Apollo.QueryHookOptions<TraitSoftSkillContextCsvGenerateAndUploadQuery, TraitSoftSkillContextCsvGenerateAndUploadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TraitSoftSkillContextCsvGenerateAndUploadQuery, TraitSoftSkillContextCsvGenerateAndUploadQueryVariables>(TraitSoftSkillContextCsvGenerateAndUploadDocument, options);
      }
export function useTraitSoftSkillContextCsvGenerateAndUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TraitSoftSkillContextCsvGenerateAndUploadQuery, TraitSoftSkillContextCsvGenerateAndUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TraitSoftSkillContextCsvGenerateAndUploadQuery, TraitSoftSkillContextCsvGenerateAndUploadQueryVariables>(TraitSoftSkillContextCsvGenerateAndUploadDocument, options);
        }
export type TraitSoftSkillContextCsvGenerateAndUploadQueryHookResult = ReturnType<typeof useTraitSoftSkillContextCsvGenerateAndUploadQuery>;
export type TraitSoftSkillContextCsvGenerateAndUploadLazyQueryHookResult = ReturnType<typeof useTraitSoftSkillContextCsvGenerateAndUploadLazyQuery>;
export type TraitSoftSkillContextCsvGenerateAndUploadQueryResult = Apollo.QueryResult<TraitSoftSkillContextCsvGenerateAndUploadQuery, TraitSoftSkillContextCsvGenerateAndUploadQueryVariables>;
export const ExternalAssessmentFindManyPaginatedDocument = gql`
    query ExternalAssessmentFindManyPaginated($skip: Int!) {
  ExternalAssessmentFindManyPaginated(skip: $skip, take: 20) {
    total
    externalAssessments {
      ...ExternalAssessment
    }
  }
}
    ${ExternalAssessmentFragmentDoc}`;

/**
 * __useExternalAssessmentFindManyPaginatedQuery__
 *
 * To run a query within a React component, call `useExternalAssessmentFindManyPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useExternalAssessmentFindManyPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExternalAssessmentFindManyPaginatedQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useExternalAssessmentFindManyPaginatedQuery(baseOptions: Apollo.QueryHookOptions<ExternalAssessmentFindManyPaginatedQuery, ExternalAssessmentFindManyPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ExternalAssessmentFindManyPaginatedQuery, ExternalAssessmentFindManyPaginatedQueryVariables>(ExternalAssessmentFindManyPaginatedDocument, options);
      }
export function useExternalAssessmentFindManyPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ExternalAssessmentFindManyPaginatedQuery, ExternalAssessmentFindManyPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ExternalAssessmentFindManyPaginatedQuery, ExternalAssessmentFindManyPaginatedQueryVariables>(ExternalAssessmentFindManyPaginatedDocument, options);
        }
export type ExternalAssessmentFindManyPaginatedQueryHookResult = ReturnType<typeof useExternalAssessmentFindManyPaginatedQuery>;
export type ExternalAssessmentFindManyPaginatedLazyQueryHookResult = ReturnType<typeof useExternalAssessmentFindManyPaginatedLazyQuery>;
export type ExternalAssessmentFindManyPaginatedQueryResult = Apollo.QueryResult<ExternalAssessmentFindManyPaginatedQuery, ExternalAssessmentFindManyPaginatedQueryVariables>;
export const GetFormReportCsvDocument = gql`
    query getFormReportCSV($formManagerType: FormManagerType!, $formType: FormType!, $formOwnerId: Int!, $associatedId: Int!) {
  getFormReportCSV(
    formManagerType: $formManagerType
    formType: $formType
    formOwnerId: $formOwnerId
    associatedId: $associatedId
  )
}
    `;

/**
 * __useGetFormReportCsvQuery__
 *
 * To run a query within a React component, call `useGetFormReportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFormReportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFormReportCsvQuery({
 *   variables: {
 *      formManagerType: // value for 'formManagerType'
 *      formType: // value for 'formType'
 *      formOwnerId: // value for 'formOwnerId'
 *      associatedId: // value for 'associatedId'
 *   },
 * });
 */
export function useGetFormReportCsvQuery(baseOptions: Apollo.QueryHookOptions<GetFormReportCsvQuery, GetFormReportCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFormReportCsvQuery, GetFormReportCsvQueryVariables>(GetFormReportCsvDocument, options);
      }
export function useGetFormReportCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFormReportCsvQuery, GetFormReportCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFormReportCsvQuery, GetFormReportCsvQueryVariables>(GetFormReportCsvDocument, options);
        }
export type GetFormReportCsvQueryHookResult = ReturnType<typeof useGetFormReportCsvQuery>;
export type GetFormReportCsvLazyQueryHookResult = ReturnType<typeof useGetFormReportCsvLazyQuery>;
export type GetFormReportCsvQueryResult = Apollo.QueryResult<GetFormReportCsvQuery, GetFormReportCsvQueryVariables>;
export const FormManagerTypesDocument = gql`
    query FormManagerTypes {
  FormReportFindFormManagerTypes {
    formManagerTypes
  }
}
    `;

/**
 * __useFormManagerTypesQuery__
 *
 * To run a query within a React component, call `useFormManagerTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormManagerTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormManagerTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFormManagerTypesQuery(baseOptions?: Apollo.QueryHookOptions<FormManagerTypesQuery, FormManagerTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormManagerTypesQuery, FormManagerTypesQueryVariables>(FormManagerTypesDocument, options);
      }
export function useFormManagerTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormManagerTypesQuery, FormManagerTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormManagerTypesQuery, FormManagerTypesQueryVariables>(FormManagerTypesDocument, options);
        }
export type FormManagerTypesQueryHookResult = ReturnType<typeof useFormManagerTypesQuery>;
export type FormManagerTypesLazyQueryHookResult = ReturnType<typeof useFormManagerTypesLazyQuery>;
export type FormManagerTypesQueryResult = Apollo.QueryResult<FormManagerTypesQuery, FormManagerTypesQueryVariables>;
export const FormTypesDocument = gql`
    query FormTypes($formManagerType: FormManagerType!) {
  FormReportFindFormTypes(formManagerType: $formManagerType) {
    formTypes
  }
}
    `;

/**
 * __useFormTypesQuery__
 *
 * To run a query within a React component, call `useFormTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormTypesQuery({
 *   variables: {
 *      formManagerType: // value for 'formManagerType'
 *   },
 * });
 */
export function useFormTypesQuery(baseOptions: Apollo.QueryHookOptions<FormTypesQuery, FormTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormTypesQuery, FormTypesQueryVariables>(FormTypesDocument, options);
      }
export function useFormTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormTypesQuery, FormTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormTypesQuery, FormTypesQueryVariables>(FormTypesDocument, options);
        }
export type FormTypesQueryHookResult = ReturnType<typeof useFormTypesQuery>;
export type FormTypesLazyQueryHookResult = ReturnType<typeof useFormTypesLazyQuery>;
export type FormTypesQueryResult = Apollo.QueryResult<FormTypesQuery, FormTypesQueryVariables>;
export const FormOwnersDocument = gql`
    query FormOwners($formManagerType: FormManagerType!, $formType: FormType!) {
  FormReportFindFormOwners(formManagerType: $formManagerType, formType: $formType) {
    id
    name
  }
}
    `;

/**
 * __useFormOwnersQuery__
 *
 * To run a query within a React component, call `useFormOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormOwnersQuery({
 *   variables: {
 *      formManagerType: // value for 'formManagerType'
 *      formType: // value for 'formType'
 *   },
 * });
 */
export function useFormOwnersQuery(baseOptions: Apollo.QueryHookOptions<FormOwnersQuery, FormOwnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormOwnersQuery, FormOwnersQueryVariables>(FormOwnersDocument, options);
      }
export function useFormOwnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormOwnersQuery, FormOwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormOwnersQuery, FormOwnersQueryVariables>(FormOwnersDocument, options);
        }
export type FormOwnersQueryHookResult = ReturnType<typeof useFormOwnersQuery>;
export type FormOwnersLazyQueryHookResult = ReturnType<typeof useFormOwnersLazyQuery>;
export type FormOwnersQueryResult = Apollo.QueryResult<FormOwnersQuery, FormOwnersQueryVariables>;
export const FormAssociatesDocument = gql`
    query FormAssociates($formManagerType: FormManagerType!, $formType: FormType!, $formOwnerId: Int!) {
  FormReportFindAssociatedIds(
    formManagerType: $formManagerType
    formType: $formType
    formOwnerId: $formOwnerId
  ) {
    associatedIds
  }
}
    `;

/**
 * __useFormAssociatesQuery__
 *
 * To run a query within a React component, call `useFormAssociatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormAssociatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormAssociatesQuery({
 *   variables: {
 *      formManagerType: // value for 'formManagerType'
 *      formType: // value for 'formType'
 *      formOwnerId: // value for 'formOwnerId'
 *   },
 * });
 */
export function useFormAssociatesQuery(baseOptions: Apollo.QueryHookOptions<FormAssociatesQuery, FormAssociatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormAssociatesQuery, FormAssociatesQueryVariables>(FormAssociatesDocument, options);
      }
export function useFormAssociatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormAssociatesQuery, FormAssociatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormAssociatesQuery, FormAssociatesQueryVariables>(FormAssociatesDocument, options);
        }
export type FormAssociatesQueryHookResult = ReturnType<typeof useFormAssociatesQuery>;
export type FormAssociatesLazyQueryHookResult = ReturnType<typeof useFormAssociatesLazyQuery>;
export type FormAssociatesQueryResult = Apollo.QueryResult<FormAssociatesQuery, FormAssociatesQueryVariables>;
export const IgCategoryDocument = gql`
    query IgCategory($id: Int!) {
  IgCategory(id: $id) {
    ...IgCategory
    interviewGuideCategories {
      id
      categoryId
      interviewGuideId
      interviewGuide {
        id
        name
      }
    }
  }
}
    ${IgCategoryFragmentDoc}`;

/**
 * __useIgCategoryQuery__
 *
 * To run a query within a React component, call `useIgCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgCategoryQuery(baseOptions: Apollo.QueryHookOptions<IgCategoryQuery, IgCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgCategoryQuery, IgCategoryQueryVariables>(IgCategoryDocument, options);
      }
export function useIgCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgCategoryQuery, IgCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgCategoryQuery, IgCategoryQueryVariables>(IgCategoryDocument, options);
        }
export type IgCategoryQueryHookResult = ReturnType<typeof useIgCategoryQuery>;
export type IgCategoryLazyQueryHookResult = ReturnType<typeof useIgCategoryLazyQuery>;
export type IgCategoryQueryResult = Apollo.QueryResult<IgCategoryQuery, IgCategoryQueryVariables>;
export const IgCategoriesDocument = gql`
    query IgCategories {
  IgCategories {
    ...IgCategory
    interviewGuideCategories {
      id
      categoryId
      interviewGuideId
      interviewGuide {
        id
        name
      }
    }
  }
}
    ${IgCategoryFragmentDoc}`;

/**
 * __useIgCategoriesQuery__
 *
 * To run a query within a React component, call `useIgCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIgCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<IgCategoriesQuery, IgCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgCategoriesQuery, IgCategoriesQueryVariables>(IgCategoriesDocument, options);
      }
export function useIgCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgCategoriesQuery, IgCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgCategoriesQuery, IgCategoriesQueryVariables>(IgCategoriesDocument, options);
        }
export type IgCategoriesQueryHookResult = ReturnType<typeof useIgCategoriesQuery>;
export type IgCategoriesLazyQueryHookResult = ReturnType<typeof useIgCategoriesLazyQuery>;
export type IgCategoriesQueryResult = Apollo.QueryResult<IgCategoriesQuery, IgCategoriesQueryVariables>;
export const IgCategoryFindManyDocument = gql`
    query IgCategoryFindMany($isArchived: Boolean!) {
  IgCategoryFindMany(isArchived: $isArchived) {
    ...IgCategory
    interviewGuideCategories {
      id
      categoryId
      interviewGuideId
      interviewGuide {
        id
        name
      }
    }
  }
}
    ${IgCategoryFragmentDoc}`;

/**
 * __useIgCategoryFindManyQuery__
 *
 * To run a query within a React component, call `useIgCategoryFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgCategoryFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgCategoryFindManyQuery({
 *   variables: {
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useIgCategoryFindManyQuery(baseOptions: Apollo.QueryHookOptions<IgCategoryFindManyQuery, IgCategoryFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgCategoryFindManyQuery, IgCategoryFindManyQueryVariables>(IgCategoryFindManyDocument, options);
      }
export function useIgCategoryFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgCategoryFindManyQuery, IgCategoryFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgCategoryFindManyQuery, IgCategoryFindManyQueryVariables>(IgCategoryFindManyDocument, options);
        }
export type IgCategoryFindManyQueryHookResult = ReturnType<typeof useIgCategoryFindManyQuery>;
export type IgCategoryFindManyLazyQueryHookResult = ReturnType<typeof useIgCategoryFindManyLazyQuery>;
export type IgCategoryFindManyQueryResult = Apollo.QueryResult<IgCategoryFindManyQuery, IgCategoryFindManyQueryVariables>;
export const IgInterviewGuideCategoryFindManyDocument = gql`
    query IgInterviewGuideCategoryFindMany($interviewGuideId: Int!) {
  IgInterviewGuideCategoryFindMany(interviewGuideId: $interviewGuideId) {
    ...IgInterviewGuideCategory
  }
}
    ${IgInterviewGuideCategoryFragmentDoc}`;

/**
 * __useIgInterviewGuideCategoryFindManyQuery__
 *
 * To run a query within a React component, call `useIgInterviewGuideCategoryFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideCategoryFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgInterviewGuideCategoryFindManyQuery({
 *   variables: {
 *      interviewGuideId: // value for 'interviewGuideId'
 *   },
 * });
 */
export function useIgInterviewGuideCategoryFindManyQuery(baseOptions: Apollo.QueryHookOptions<IgInterviewGuideCategoryFindManyQuery, IgInterviewGuideCategoryFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgInterviewGuideCategoryFindManyQuery, IgInterviewGuideCategoryFindManyQueryVariables>(IgInterviewGuideCategoryFindManyDocument, options);
      }
export function useIgInterviewGuideCategoryFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgInterviewGuideCategoryFindManyQuery, IgInterviewGuideCategoryFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgInterviewGuideCategoryFindManyQuery, IgInterviewGuideCategoryFindManyQueryVariables>(IgInterviewGuideCategoryFindManyDocument, options);
        }
export type IgInterviewGuideCategoryFindManyQueryHookResult = ReturnType<typeof useIgInterviewGuideCategoryFindManyQuery>;
export type IgInterviewGuideCategoryFindManyLazyQueryHookResult = ReturnType<typeof useIgInterviewGuideCategoryFindManyLazyQuery>;
export type IgInterviewGuideCategoryFindManyQueryResult = Apollo.QueryResult<IgInterviewGuideCategoryFindManyQuery, IgInterviewGuideCategoryFindManyQueryVariables>;
export const IgInterviewGuidesDocument = gql`
    query IgInterviewGuides {
  IgInterviewGuides {
    ...IgInterviewGuide
  }
}
    ${IgInterviewGuideFragmentDoc}`;

/**
 * __useIgInterviewGuidesQuery__
 *
 * To run a query within a React component, call `useIgInterviewGuidesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuidesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgInterviewGuidesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIgInterviewGuidesQuery(baseOptions?: Apollo.QueryHookOptions<IgInterviewGuidesQuery, IgInterviewGuidesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgInterviewGuidesQuery, IgInterviewGuidesQueryVariables>(IgInterviewGuidesDocument, options);
      }
export function useIgInterviewGuidesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgInterviewGuidesQuery, IgInterviewGuidesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgInterviewGuidesQuery, IgInterviewGuidesQueryVariables>(IgInterviewGuidesDocument, options);
        }
export type IgInterviewGuidesQueryHookResult = ReturnType<typeof useIgInterviewGuidesQuery>;
export type IgInterviewGuidesLazyQueryHookResult = ReturnType<typeof useIgInterviewGuidesLazyQuery>;
export type IgInterviewGuidesQueryResult = Apollo.QueryResult<IgInterviewGuidesQuery, IgInterviewGuidesQueryVariables>;
export const IgInterviewGuideDocument = gql`
    query IgInterviewGuide($id: Int!) {
  IgInterviewGuide(id: $id) {
    ...IgInterviewGuide
  }
}
    ${IgInterviewGuideFragmentDoc}`;

/**
 * __useIgInterviewGuideQuery__
 *
 * To run a query within a React component, call `useIgInterviewGuideQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgInterviewGuideQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgInterviewGuideQuery(baseOptions: Apollo.QueryHookOptions<IgInterviewGuideQuery, IgInterviewGuideQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgInterviewGuideQuery, IgInterviewGuideQueryVariables>(IgInterviewGuideDocument, options);
      }
export function useIgInterviewGuideLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgInterviewGuideQuery, IgInterviewGuideQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgInterviewGuideQuery, IgInterviewGuideQueryVariables>(IgInterviewGuideDocument, options);
        }
export type IgInterviewGuideQueryHookResult = ReturnType<typeof useIgInterviewGuideQuery>;
export type IgInterviewGuideLazyQueryHookResult = ReturnType<typeof useIgInterviewGuideLazyQuery>;
export type IgInterviewGuideQueryResult = Apollo.QueryResult<IgInterviewGuideQuery, IgInterviewGuideQueryVariables>;
export const IgInterviewGuideFindManyDocument = gql`
    query IgInterviewGuideFindMany($id: Int!) {
  IgInterviewGuideFindMany(id: $id) {
    ...IgInterviewGuide
  }
}
    ${IgInterviewGuideFragmentDoc}`;

/**
 * __useIgInterviewGuideFindManyQuery__
 *
 * To run a query within a React component, call `useIgInterviewGuideFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgInterviewGuideFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgInterviewGuideFindManyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgInterviewGuideFindManyQuery(baseOptions: Apollo.QueryHookOptions<IgInterviewGuideFindManyQuery, IgInterviewGuideFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgInterviewGuideFindManyQuery, IgInterviewGuideFindManyQueryVariables>(IgInterviewGuideFindManyDocument, options);
      }
export function useIgInterviewGuideFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgInterviewGuideFindManyQuery, IgInterviewGuideFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgInterviewGuideFindManyQuery, IgInterviewGuideFindManyQueryVariables>(IgInterviewGuideFindManyDocument, options);
        }
export type IgInterviewGuideFindManyQueryHookResult = ReturnType<typeof useIgInterviewGuideFindManyQuery>;
export type IgInterviewGuideFindManyLazyQueryHookResult = ReturnType<typeof useIgInterviewGuideFindManyLazyQuery>;
export type IgInterviewGuideFindManyQueryResult = Apollo.QueryResult<IgInterviewGuideFindManyQuery, IgInterviewGuideFindManyQueryVariables>;
export const IgStepFindManyDocument = gql`
    query IgStepFindMany($interviewGuideId: Int!, $isArchived: Boolean!) {
  IgStepFindMany(interviewGuideId: $interviewGuideId, isArchived: $isArchived) {
    ...IgStep
  }
}
    ${IgStepFragmentDoc}`;

/**
 * __useIgStepFindManyQuery__
 *
 * To run a query within a React component, call `useIgStepFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgStepFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgStepFindManyQuery({
 *   variables: {
 *      interviewGuideId: // value for 'interviewGuideId'
 *      isArchived: // value for 'isArchived'
 *   },
 * });
 */
export function useIgStepFindManyQuery(baseOptions: Apollo.QueryHookOptions<IgStepFindManyQuery, IgStepFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgStepFindManyQuery, IgStepFindManyQueryVariables>(IgStepFindManyDocument, options);
      }
export function useIgStepFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgStepFindManyQuery, IgStepFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgStepFindManyQuery, IgStepFindManyQueryVariables>(IgStepFindManyDocument, options);
        }
export type IgStepFindManyQueryHookResult = ReturnType<typeof useIgStepFindManyQuery>;
export type IgStepFindManyLazyQueryHookResult = ReturnType<typeof useIgStepFindManyLazyQuery>;
export type IgStepFindManyQueryResult = Apollo.QueryResult<IgStepFindManyQuery, IgStepFindManyQueryVariables>;
export const IgStepDocument = gql`
    query IgStep($id: Int!) {
  IgStep(id: $id) {
    ...IgStep
  }
}
    ${IgStepFragmentDoc}`;

/**
 * __useIgStepQuery__
 *
 * To run a query within a React component, call `useIgStepQuery` and pass it any options that fit your needs.
 * When your component renders, `useIgStepQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIgStepQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIgStepQuery(baseOptions: Apollo.QueryHookOptions<IgStepQuery, IgStepQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IgStepQuery, IgStepQueryVariables>(IgStepDocument, options);
      }
export function useIgStepLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IgStepQuery, IgStepQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IgStepQuery, IgStepQueryVariables>(IgStepDocument, options);
        }
export type IgStepQueryHookResult = ReturnType<typeof useIgStepQuery>;
export type IgStepLazyQueryHookResult = ReturnType<typeof useIgStepLazyQuery>;
export type IgStepQueryResult = Apollo.QueryResult<IgStepQuery, IgStepQueryVariables>;
export const InterviewQuestionRoleLevelContentFindManyAndCountDocument = gql`
    query InterviewQuestionRoleLevelContentFindManyAndCount($softSkillIds: [Int!], $traitIds: [Int!], $roleLevels: [ContentRoleLevel!], $classifications: [ContentClassification!], $take: Int!, $page: Int!, $order: [String!]) {
  interviewQuestionContent: InterviewQuestionRoleLevelContentFindManyAndCount(
    softSkillIds: $softSkillIds
    traitIds: $traitIds
    roleLevels: $roleLevels
    classifications: $classifications
    take: $take
    page: $page
    order: $order
  ) {
    interviewQuestionContent {
      id
      softSkill {
        name
      }
      trait {
        name
      }
      softSkillId
      traitId
      classification
      roleLevel
      summary
      feedback
      reasonForQuestion
      mainQuestion
      subQuestions
    }
    count
  }
}
    `;

/**
 * __useInterviewQuestionRoleLevelContentFindManyAndCountQuery__
 *
 * To run a query within a React component, call `useInterviewQuestionRoleLevelContentFindManyAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInterviewQuestionRoleLevelContentFindManyAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInterviewQuestionRoleLevelContentFindManyAndCountQuery({
 *   variables: {
 *      softSkillIds: // value for 'softSkillIds'
 *      traitIds: // value for 'traitIds'
 *      roleLevels: // value for 'roleLevels'
 *      classifications: // value for 'classifications'
 *      take: // value for 'take'
 *      page: // value for 'page'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useInterviewQuestionRoleLevelContentFindManyAndCountQuery(baseOptions: Apollo.QueryHookOptions<InterviewQuestionRoleLevelContentFindManyAndCountQuery, InterviewQuestionRoleLevelContentFindManyAndCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InterviewQuestionRoleLevelContentFindManyAndCountQuery, InterviewQuestionRoleLevelContentFindManyAndCountQueryVariables>(InterviewQuestionRoleLevelContentFindManyAndCountDocument, options);
      }
export function useInterviewQuestionRoleLevelContentFindManyAndCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InterviewQuestionRoleLevelContentFindManyAndCountQuery, InterviewQuestionRoleLevelContentFindManyAndCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InterviewQuestionRoleLevelContentFindManyAndCountQuery, InterviewQuestionRoleLevelContentFindManyAndCountQueryVariables>(InterviewQuestionRoleLevelContentFindManyAndCountDocument, options);
        }
export type InterviewQuestionRoleLevelContentFindManyAndCountQueryHookResult = ReturnType<typeof useInterviewQuestionRoleLevelContentFindManyAndCountQuery>;
export type InterviewQuestionRoleLevelContentFindManyAndCountLazyQueryHookResult = ReturnType<typeof useInterviewQuestionRoleLevelContentFindManyAndCountLazyQuery>;
export type InterviewQuestionRoleLevelContentFindManyAndCountQueryResult = Apollo.QueryResult<InterviewQuestionRoleLevelContentFindManyAndCountQuery, InterviewQuestionRoleLevelContentFindManyAndCountQueryVariables>;
export const JobRoleFindOneDocument = gql`
    query JobRoleFindOne($id: Int) {
  JobRoleFindOne(id: $id) {
    ...JobRole
  }
}
    ${JobRoleFragmentDoc}`;

/**
 * __useJobRoleFindOneQuery__
 *
 * To run a query within a React component, call `useJobRoleFindOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobRoleFindOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobRoleFindOneQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobRoleFindOneQuery(baseOptions?: Apollo.QueryHookOptions<JobRoleFindOneQuery, JobRoleFindOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobRoleFindOneQuery, JobRoleFindOneQueryVariables>(JobRoleFindOneDocument, options);
      }
export function useJobRoleFindOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobRoleFindOneQuery, JobRoleFindOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobRoleFindOneQuery, JobRoleFindOneQueryVariables>(JobRoleFindOneDocument, options);
        }
export type JobRoleFindOneQueryHookResult = ReturnType<typeof useJobRoleFindOneQuery>;
export type JobRoleFindOneLazyQueryHookResult = ReturnType<typeof useJobRoleFindOneLazyQuery>;
export type JobRoleFindOneQueryResult = Apollo.QueryResult<JobRoleFindOneQuery, JobRoleFindOneQueryVariables>;
export const JobRoleFindManyDocument = gql`
    query JobRoleFindMany($companyId: Int, $roleLevel: ContentRoleLevel) {
  JobRoleFindMany(companyId: $companyId, roleLevel: $roleLevel) {
    ...JobRoleOverview
  }
}
    ${JobRoleOverviewFragmentDoc}`;

/**
 * __useJobRoleFindManyQuery__
 *
 * To run a query within a React component, call `useJobRoleFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobRoleFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobRoleFindManyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      roleLevel: // value for 'roleLevel'
 *   },
 * });
 */
export function useJobRoleFindManyQuery(baseOptions?: Apollo.QueryHookOptions<JobRoleFindManyQuery, JobRoleFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobRoleFindManyQuery, JobRoleFindManyQueryVariables>(JobRoleFindManyDocument, options);
      }
export function useJobRoleFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobRoleFindManyQuery, JobRoleFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobRoleFindManyQuery, JobRoleFindManyQueryVariables>(JobRoleFindManyDocument, options);
        }
export type JobRoleFindManyQueryHookResult = ReturnType<typeof useJobRoleFindManyQuery>;
export type JobRoleFindManyLazyQueryHookResult = ReturnType<typeof useJobRoleFindManyLazyQuery>;
export type JobRoleFindManyQueryResult = Apollo.QueryResult<JobRoleFindManyQuery, JobRoleFindManyQueryVariables>;
export const ProjectsDocument = gql`
    query Projects {
  projects: getAllProjects {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

/**
 * __useProjectsQuery__
 *
 * To run a query within a React component, call `useProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useProjectsQuery(baseOptions?: Apollo.QueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
      }
export function useProjectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectsQuery, ProjectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectsQuery, ProjectsQueryVariables>(ProjectsDocument, options);
        }
export type ProjectsQueryHookResult = ReturnType<typeof useProjectsQuery>;
export type ProjectsLazyQueryHookResult = ReturnType<typeof useProjectsLazyQuery>;
export type ProjectsQueryResult = Apollo.QueryResult<ProjectsQuery, ProjectsQueryVariables>;
export const GetProjectByIdDocument = gql`
    query getProjectById($id: Float!) {
  project: getProjectById(id: $id) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

/**
 * __useGetProjectByIdQuery__
 *
 * To run a query within a React component, call `useGetProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectByIdQuery(baseOptions: Apollo.QueryHookOptions<GetProjectByIdQuery, GetProjectByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(GetProjectByIdDocument, options);
      }
export function useGetProjectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectByIdQuery, GetProjectByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectByIdQuery, GetProjectByIdQueryVariables>(GetProjectByIdDocument, options);
        }
export type GetProjectByIdQueryHookResult = ReturnType<typeof useGetProjectByIdQuery>;
export type GetProjectByIdLazyQueryHookResult = ReturnType<typeof useGetProjectByIdLazyQuery>;
export type GetProjectByIdQueryResult = Apollo.QueryResult<GetProjectByIdQuery, GetProjectByIdQueryVariables>;
export const ProjectFindManyByCompanyDocument = gql`
    query ProjectFindManyByCompany($companyId: Int!) {
  projects: ProjectFindManyByCompany(companyId: $companyId) {
    ...Project
  }
}
    ${ProjectFragmentDoc}`;

/**
 * __useProjectFindManyByCompanyQuery__
 *
 * To run a query within a React component, call `useProjectFindManyByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFindManyByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFindManyByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useProjectFindManyByCompanyQuery(baseOptions: Apollo.QueryHookOptions<ProjectFindManyByCompanyQuery, ProjectFindManyByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectFindManyByCompanyQuery, ProjectFindManyByCompanyQueryVariables>(ProjectFindManyByCompanyDocument, options);
      }
export function useProjectFindManyByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFindManyByCompanyQuery, ProjectFindManyByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectFindManyByCompanyQuery, ProjectFindManyByCompanyQueryVariables>(ProjectFindManyByCompanyDocument, options);
        }
export type ProjectFindManyByCompanyQueryHookResult = ReturnType<typeof useProjectFindManyByCompanyQuery>;
export type ProjectFindManyByCompanyLazyQueryHookResult = ReturnType<typeof useProjectFindManyByCompanyLazyQuery>;
export type ProjectFindManyByCompanyQueryResult = Apollo.QueryResult<ProjectFindManyByCompanyQuery, ProjectFindManyByCompanyQueryVariables>;
export const ResultsPageProjectFindManyByCompanyDocument = gql`
    query ResultsPageProjectFindManyByCompany($companyId: Int!) {
  projects: ProjectFindManyByCompany(companyId: $companyId) {
    id
    name
  }
}
    `;

/**
 * __useResultsPageProjectFindManyByCompanyQuery__
 *
 * To run a query within a React component, call `useResultsPageProjectFindManyByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useResultsPageProjectFindManyByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResultsPageProjectFindManyByCompanyQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useResultsPageProjectFindManyByCompanyQuery(baseOptions: Apollo.QueryHookOptions<ResultsPageProjectFindManyByCompanyQuery, ResultsPageProjectFindManyByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResultsPageProjectFindManyByCompanyQuery, ResultsPageProjectFindManyByCompanyQueryVariables>(ResultsPageProjectFindManyByCompanyDocument, options);
      }
export function useResultsPageProjectFindManyByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResultsPageProjectFindManyByCompanyQuery, ResultsPageProjectFindManyByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResultsPageProjectFindManyByCompanyQuery, ResultsPageProjectFindManyByCompanyQueryVariables>(ResultsPageProjectFindManyByCompanyDocument, options);
        }
export type ResultsPageProjectFindManyByCompanyQueryHookResult = ReturnType<typeof useResultsPageProjectFindManyByCompanyQuery>;
export type ResultsPageProjectFindManyByCompanyLazyQueryHookResult = ReturnType<typeof useResultsPageProjectFindManyByCompanyLazyQuery>;
export type ResultsPageProjectFindManyByCompanyQueryResult = Apollo.QueryResult<ResultsPageProjectFindManyByCompanyQuery, ResultsPageProjectFindManyByCompanyQueryVariables>;
export const ProjectFindManysByCompanyWithStageCandidateRangeDocument = gql`
    query ProjectFindManysByCompanyWithStageCandidateRange($companyId: Int, $companyIds: [Int!], $companySignatureId: Int, $createdAtFrom: Date, $createdAtTo: Date, $creatorId: Int, $description: String, $id: Int, $ids: [Int!], $isArchived: Boolean, $isStopped: Boolean, $managerIds: [Int!], $moduleType: ProjectModuleType, $name: String, $subId: String) {
  projects: ProjectFindManysByCompanyWithStageCandidateRange(
    companyId: $companyId
    companyIds: $companyIds
    companySignatureId: $companySignatureId
    stageCandidateCreatedAtFrom: $createdAtFrom
    stageCandidateCreatedAtTo: $createdAtTo
    creatorId: $creatorId
    description: $description
    id: $id
    ids: $ids
    isArchived: $isArchived
    isStopped: $isStopped
    managerIds: $managerIds
    moduleType: $moduleType
    name: $name
    subId: $subId
  ) {
    ...CompanyProjects
  }
}
    ${CompanyProjectsFragmentDoc}`;

/**
 * __useProjectFindManysByCompanyWithStageCandidateRangeQuery__
 *
 * To run a query within a React component, call `useProjectFindManysByCompanyWithStageCandidateRangeQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFindManysByCompanyWithStageCandidateRangeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFindManysByCompanyWithStageCandidateRangeQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyIds: // value for 'companyIds'
 *      companySignatureId: // value for 'companySignatureId'
 *      createdAtFrom: // value for 'createdAtFrom'
 *      createdAtTo: // value for 'createdAtTo'
 *      creatorId: // value for 'creatorId'
 *      description: // value for 'description'
 *      id: // value for 'id'
 *      ids: // value for 'ids'
 *      isArchived: // value for 'isArchived'
 *      isStopped: // value for 'isStopped'
 *      managerIds: // value for 'managerIds'
 *      moduleType: // value for 'moduleType'
 *      name: // value for 'name'
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useProjectFindManysByCompanyWithStageCandidateRangeQuery(baseOptions?: Apollo.QueryHookOptions<ProjectFindManysByCompanyWithStageCandidateRangeQuery, ProjectFindManysByCompanyWithStageCandidateRangeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectFindManysByCompanyWithStageCandidateRangeQuery, ProjectFindManysByCompanyWithStageCandidateRangeQueryVariables>(ProjectFindManysByCompanyWithStageCandidateRangeDocument, options);
      }
export function useProjectFindManysByCompanyWithStageCandidateRangeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFindManysByCompanyWithStageCandidateRangeQuery, ProjectFindManysByCompanyWithStageCandidateRangeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectFindManysByCompanyWithStageCandidateRangeQuery, ProjectFindManysByCompanyWithStageCandidateRangeQueryVariables>(ProjectFindManysByCompanyWithStageCandidateRangeDocument, options);
        }
export type ProjectFindManysByCompanyWithStageCandidateRangeQueryHookResult = ReturnType<typeof useProjectFindManysByCompanyWithStageCandidateRangeQuery>;
export type ProjectFindManysByCompanyWithStageCandidateRangeLazyQueryHookResult = ReturnType<typeof useProjectFindManysByCompanyWithStageCandidateRangeLazyQuery>;
export type ProjectFindManysByCompanyWithStageCandidateRangeQueryResult = Apollo.QueryResult<ProjectFindManysByCompanyWithStageCandidateRangeQuery, ProjectFindManysByCompanyWithStageCandidateRangeQueryVariables>;
export const ProjectFindManysByCompanyWithStageCandidateRangeCsvDocument = gql`
    query ProjectFindManysByCompanyWithStageCandidateRangeCsv($companyId: Int, $companyIds: [Int!], $companySignatureId: Int, $createdAtFrom: Date, $createdAtTo: Date, $creatorId: Int, $description: String, $id: Int, $ids: [Int!], $isArchived: Boolean, $isStopped: Boolean, $managerIds: [Int!], $moduleType: ProjectModuleType, $name: String, $subId: String) {
  projects: ProjectFindManysByCompanyWithStageCandidateRangeCsv(
    companyId: $companyId
    companyIds: $companyIds
    companySignatureId: $companySignatureId
    stageCandidateCreatedAtFrom: $createdAtFrom
    stageCandidateCreatedAtTo: $createdAtTo
    creatorId: $creatorId
    description: $description
    id: $id
    ids: $ids
    isArchived: $isArchived
    isStopped: $isStopped
    managerIds: $managerIds
    moduleType: $moduleType
    name: $name
    subId: $subId
  ) {
    ...Blob
  }
}
    ${BlobFragmentDoc}`;

/**
 * __useProjectFindManysByCompanyWithStageCandidateRangeCsvQuery__
 *
 * To run a query within a React component, call `useProjectFindManysByCompanyWithStageCandidateRangeCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFindManysByCompanyWithStageCandidateRangeCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFindManysByCompanyWithStageCandidateRangeCsvQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *      companyIds: // value for 'companyIds'
 *      companySignatureId: // value for 'companySignatureId'
 *      createdAtFrom: // value for 'createdAtFrom'
 *      createdAtTo: // value for 'createdAtTo'
 *      creatorId: // value for 'creatorId'
 *      description: // value for 'description'
 *      id: // value for 'id'
 *      ids: // value for 'ids'
 *      isArchived: // value for 'isArchived'
 *      isStopped: // value for 'isStopped'
 *      managerIds: // value for 'managerIds'
 *      moduleType: // value for 'moduleType'
 *      name: // value for 'name'
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useProjectFindManysByCompanyWithStageCandidateRangeCsvQuery(baseOptions?: Apollo.QueryHookOptions<ProjectFindManysByCompanyWithStageCandidateRangeCsvQuery, ProjectFindManysByCompanyWithStageCandidateRangeCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectFindManysByCompanyWithStageCandidateRangeCsvQuery, ProjectFindManysByCompanyWithStageCandidateRangeCsvQueryVariables>(ProjectFindManysByCompanyWithStageCandidateRangeCsvDocument, options);
      }
export function useProjectFindManysByCompanyWithStageCandidateRangeCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFindManysByCompanyWithStageCandidateRangeCsvQuery, ProjectFindManysByCompanyWithStageCandidateRangeCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectFindManysByCompanyWithStageCandidateRangeCsvQuery, ProjectFindManysByCompanyWithStageCandidateRangeCsvQueryVariables>(ProjectFindManysByCompanyWithStageCandidateRangeCsvDocument, options);
        }
export type ProjectFindManysByCompanyWithStageCandidateRangeCsvQueryHookResult = ReturnType<typeof useProjectFindManysByCompanyWithStageCandidateRangeCsvQuery>;
export type ProjectFindManysByCompanyWithStageCandidateRangeCsvLazyQueryHookResult = ReturnType<typeof useProjectFindManysByCompanyWithStageCandidateRangeCsvLazyQuery>;
export type ProjectFindManysByCompanyWithStageCandidateRangeCsvQueryResult = Apollo.QueryResult<ProjectFindManysByCompanyWithStageCandidateRangeCsvQuery, ProjectFindManysByCompanyWithStageCandidateRangeCsvQueryVariables>;
export const ProjectFindManyPaginatedDocument = gql`
    query ProjectFindManyPaginated($args: ProjectCompanyArgs!, $filter: ProjectCompanyFilterOptions, $order: ProjectCompanyOrderOptions, $paginate: OffsetBasedPaginationArgs) {
  ProjectFindManyPaginated(
    args: $args
    filter: $filter
    order: $order
    paginate: $paginate
  ) {
    data {
      ...Project
    }
    pageInfo {
      ...PageInfo
    }
  }
}
    ${ProjectFragmentDoc}
${PageInfoFragmentDoc}`;

/**
 * __useProjectFindManyPaginatedQuery__
 *
 * To run a query within a React component, call `useProjectFindManyPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFindManyPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFindManyPaginatedQuery({
 *   variables: {
 *      args: // value for 'args'
 *      filter: // value for 'filter'
 *      order: // value for 'order'
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useProjectFindManyPaginatedQuery(baseOptions: Apollo.QueryHookOptions<ProjectFindManyPaginatedQuery, ProjectFindManyPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectFindManyPaginatedQuery, ProjectFindManyPaginatedQueryVariables>(ProjectFindManyPaginatedDocument, options);
      }
export function useProjectFindManyPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectFindManyPaginatedQuery, ProjectFindManyPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectFindManyPaginatedQuery, ProjectFindManyPaginatedQueryVariables>(ProjectFindManyPaginatedDocument, options);
        }
export type ProjectFindManyPaginatedQueryHookResult = ReturnType<typeof useProjectFindManyPaginatedQuery>;
export type ProjectFindManyPaginatedLazyQueryHookResult = ReturnType<typeof useProjectFindManyPaginatedLazyQuery>;
export type ProjectFindManyPaginatedQueryResult = Apollo.QueryResult<ProjectFindManyPaginatedQuery, ProjectFindManyPaginatedQueryVariables>;
export const GetProjectCobrandingDocument = gql`
    query getProjectCobranding($subId: String!) {
  getProjectCobranding(subId: $subId) {
    companyLogoUrl
    companyName
    name
  }
}
    `;

/**
 * __useGetProjectCobrandingQuery__
 *
 * To run a query within a React component, call `useGetProjectCobrandingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCobrandingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCobrandingQuery({
 *   variables: {
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useGetProjectCobrandingQuery(baseOptions: Apollo.QueryHookOptions<GetProjectCobrandingQuery, GetProjectCobrandingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectCobrandingQuery, GetProjectCobrandingQueryVariables>(GetProjectCobrandingDocument, options);
      }
export function useGetProjectCobrandingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectCobrandingQuery, GetProjectCobrandingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectCobrandingQuery, GetProjectCobrandingQueryVariables>(GetProjectCobrandingDocument, options);
        }
export type GetProjectCobrandingQueryHookResult = ReturnType<typeof useGetProjectCobrandingQuery>;
export type GetProjectCobrandingLazyQueryHookResult = ReturnType<typeof useGetProjectCobrandingLazyQuery>;
export type GetProjectCobrandingQueryResult = Apollo.QueryResult<GetProjectCobrandingQuery, GetProjectCobrandingQueryVariables>;
export const ProjectCollaboratorsFindManyPaginatedDocument = gql`
    query ProjectCollaboratorsFindManyPaginated($args: ProjectCollaboratorArgs!, $order: ProjectCollaboratorOrderOptions, $paginate: OffsetBasedPaginationArgs) {
  collaborators: ProjectCollaboratorsFindManyPaginated(
    args: $args
    order: $order
    paginate: $paginate
  ) {
    data {
      id
      actorRole
      userId
      user {
        id
        uuid
        email
        firstName
        lastName
        companyRoles
        avatarUrl
      }
      groupId
      group {
        id
        subId
        name
        description
        companyId
        membersCount
        membersPreview {
          id
          uuid
          firstName
          lastName
          email
        }
      }
    }
    pageInfo {
      currentPage
      itemsOnPage
      itemsTotal
      pageTotal
      skip
      take
    }
  }
}
    `;

/**
 * __useProjectCollaboratorsFindManyPaginatedQuery__
 *
 * To run a query within a React component, call `useProjectCollaboratorsFindManyPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectCollaboratorsFindManyPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectCollaboratorsFindManyPaginatedQuery({
 *   variables: {
 *      args: // value for 'args'
 *      order: // value for 'order'
 *      paginate: // value for 'paginate'
 *   },
 * });
 */
export function useProjectCollaboratorsFindManyPaginatedQuery(baseOptions: Apollo.QueryHookOptions<ProjectCollaboratorsFindManyPaginatedQuery, ProjectCollaboratorsFindManyPaginatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectCollaboratorsFindManyPaginatedQuery, ProjectCollaboratorsFindManyPaginatedQueryVariables>(ProjectCollaboratorsFindManyPaginatedDocument, options);
      }
export function useProjectCollaboratorsFindManyPaginatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectCollaboratorsFindManyPaginatedQuery, ProjectCollaboratorsFindManyPaginatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectCollaboratorsFindManyPaginatedQuery, ProjectCollaboratorsFindManyPaginatedQueryVariables>(ProjectCollaboratorsFindManyPaginatedDocument, options);
        }
export type ProjectCollaboratorsFindManyPaginatedQueryHookResult = ReturnType<typeof useProjectCollaboratorsFindManyPaginatedQuery>;
export type ProjectCollaboratorsFindManyPaginatedLazyQueryHookResult = ReturnType<typeof useProjectCollaboratorsFindManyPaginatedLazyQuery>;
export type ProjectCollaboratorsFindManyPaginatedQueryResult = Apollo.QueryResult<ProjectCollaboratorsFindManyPaginatedQuery, ProjectCollaboratorsFindManyPaginatedQueryVariables>;
export const ProjectJobRoleFindManyDocument = gql`
    query ProjectJobRoleFindMany($id: Int!) {
  ProjectJobRoleFindMany(projectId: $id) {
    ...ProjectJobRole
  }
}
    ${ProjectJobRoleFragmentDoc}`;

/**
 * __useProjectJobRoleFindManyQuery__
 *
 * To run a query within a React component, call `useProjectJobRoleFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectJobRoleFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectJobRoleFindManyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectJobRoleFindManyQuery(baseOptions: Apollo.QueryHookOptions<ProjectJobRoleFindManyQuery, ProjectJobRoleFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectJobRoleFindManyQuery, ProjectJobRoleFindManyQueryVariables>(ProjectJobRoleFindManyDocument, options);
      }
export function useProjectJobRoleFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectJobRoleFindManyQuery, ProjectJobRoleFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectJobRoleFindManyQuery, ProjectJobRoleFindManyQueryVariables>(ProjectJobRoleFindManyDocument, options);
        }
export type ProjectJobRoleFindManyQueryHookResult = ReturnType<typeof useProjectJobRoleFindManyQuery>;
export type ProjectJobRoleFindManyLazyQueryHookResult = ReturnType<typeof useProjectJobRoleFindManyLazyQuery>;
export type ProjectJobRoleFindManyQueryResult = Apollo.QueryResult<ProjectJobRoleFindManyQuery, ProjectJobRoleFindManyQueryVariables>;
export const ProjectRequestUserFindManyDocument = gql`
    query ProjectRequestUserFindMany($approvalId: Int, $id: Int, $ids: [Int!], $projectRequestId: Int, $projectRequestIds: [Int!], $type: ProjectRequestUserType, $userId: Int, $userIds: [Int!], $withDeleted: WithDeletedChoice = ONLY_NOT_ARCHIVED, $relationDepth: RelationDepth = FULL) {
  ProjectRequestUserFindMany(
    approvalId: $approvalId
    id: $id
    ids: $ids
    projectRequestId: $projectRequestId
    projectRequestIds: $projectRequestIds
    type: $type
    userId: $userId
    userIds: $userIds
    withDeleted: $withDeleted
    relationDepth: $relationDepth
  ) {
    ...ProjectRequestUserModel
  }
}
    ${ProjectRequestUserModelFragmentDoc}`;

/**
 * __useProjectRequestUserFindManyQuery__
 *
 * To run a query within a React component, call `useProjectRequestUserFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestUserFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRequestUserFindManyQuery({
 *   variables: {
 *      approvalId: // value for 'approvalId'
 *      id: // value for 'id'
 *      ids: // value for 'ids'
 *      projectRequestId: // value for 'projectRequestId'
 *      projectRequestIds: // value for 'projectRequestIds'
 *      type: // value for 'type'
 *      userId: // value for 'userId'
 *      userIds: // value for 'userIds'
 *      withDeleted: // value for 'withDeleted'
 *      relationDepth: // value for 'relationDepth'
 *   },
 * });
 */
export function useProjectRequestUserFindManyQuery(baseOptions?: Apollo.QueryHookOptions<ProjectRequestUserFindManyQuery, ProjectRequestUserFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRequestUserFindManyQuery, ProjectRequestUserFindManyQueryVariables>(ProjectRequestUserFindManyDocument, options);
      }
export function useProjectRequestUserFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRequestUserFindManyQuery, ProjectRequestUserFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRequestUserFindManyQuery, ProjectRequestUserFindManyQueryVariables>(ProjectRequestUserFindManyDocument, options);
        }
export type ProjectRequestUserFindManyQueryHookResult = ReturnType<typeof useProjectRequestUserFindManyQuery>;
export type ProjectRequestUserFindManyLazyQueryHookResult = ReturnType<typeof useProjectRequestUserFindManyLazyQuery>;
export type ProjectRequestUserFindManyQueryResult = Apollo.QueryResult<ProjectRequestUserFindManyQuery, ProjectRequestUserFindManyQueryVariables>;
export const ProjectRequestFileUploadFindManyDocument = gql`
    query ProjectRequestFileUploadFindMany($blobId: Int, $fileName: String, $getOption: GetOption, $id: Int, $ids: [Int!], $projectRequestId: Int, $projectRequestIds: [Int!], $withDeleted: WithDeletedChoice = ONLY_NOT_ARCHIVED) {
  ProjectRequestFileUploadFindMany(
    blobId: $blobId
    fileName: $fileName
    getOption: $getOption
    id: $id
    ids: $ids
    projectRequestId: $projectRequestId
    projectRequestIds: $projectRequestIds
    withDeleted: $withDeleted
  ) {
    blob {
      ...Blob
    }
    blobId
    fileName
    id
    projectRequestId
  }
}
    ${BlobFragmentDoc}`;

/**
 * __useProjectRequestFileUploadFindManyQuery__
 *
 * To run a query within a React component, call `useProjectRequestFileUploadFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestFileUploadFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRequestFileUploadFindManyQuery({
 *   variables: {
 *      blobId: // value for 'blobId'
 *      fileName: // value for 'fileName'
 *      getOption: // value for 'getOption'
 *      id: // value for 'id'
 *      ids: // value for 'ids'
 *      projectRequestId: // value for 'projectRequestId'
 *      projectRequestIds: // value for 'projectRequestIds'
 *      withDeleted: // value for 'withDeleted'
 *   },
 * });
 */
export function useProjectRequestFileUploadFindManyQuery(baseOptions?: Apollo.QueryHookOptions<ProjectRequestFileUploadFindManyQuery, ProjectRequestFileUploadFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRequestFileUploadFindManyQuery, ProjectRequestFileUploadFindManyQueryVariables>(ProjectRequestFileUploadFindManyDocument, options);
      }
export function useProjectRequestFileUploadFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRequestFileUploadFindManyQuery, ProjectRequestFileUploadFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRequestFileUploadFindManyQuery, ProjectRequestFileUploadFindManyQueryVariables>(ProjectRequestFileUploadFindManyDocument, options);
        }
export type ProjectRequestFileUploadFindManyQueryHookResult = ReturnType<typeof useProjectRequestFileUploadFindManyQuery>;
export type ProjectRequestFileUploadFindManyLazyQueryHookResult = ReturnType<typeof useProjectRequestFileUploadFindManyLazyQuery>;
export type ProjectRequestFileUploadFindManyQueryResult = Apollo.QueryResult<ProjectRequestFileUploadFindManyQuery, ProjectRequestFileUploadFindManyQueryVariables>;
export const ProjectRequestFindManyDocument = gql`
    query ProjectRequestFindMany($approvalRequestDate: DateTime, $companyId: Int, $creatorId: Int, $getOption: GetOption, $id: Int, $ids: [Int!], $projectName: String, $relationDepth: RelationDepth = FULL, $status: ProjectRequestStatus, $withDeleted: WithDeletedChoice = ONLY_NOT_ARCHIVED, $managerSurveyId: String) {
  ProjectRequestFindMany(
    approvalRequestDate: $approvalRequestDate
    companyId: $companyId
    creatorId: $creatorId
    getOption: $getOption
    id: $id
    ids: $ids
    projectName: $projectName
    relationDepth: $relationDepth
    status: $status
    withDeleted: $withDeleted
    managerSurveyId: $managerSurveyId
  ) {
    ...ProjectRequest
  }
}
    ${ProjectRequestFragmentDoc}`;

/**
 * __useProjectRequestFindManyQuery__
 *
 * To run a query within a React component, call `useProjectRequestFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRequestFindManyQuery({
 *   variables: {
 *      approvalRequestDate: // value for 'approvalRequestDate'
 *      companyId: // value for 'companyId'
 *      creatorId: // value for 'creatorId'
 *      getOption: // value for 'getOption'
 *      id: // value for 'id'
 *      ids: // value for 'ids'
 *      projectName: // value for 'projectName'
 *      relationDepth: // value for 'relationDepth'
 *      status: // value for 'status'
 *      withDeleted: // value for 'withDeleted'
 *      managerSurveyId: // value for 'managerSurveyId'
 *   },
 * });
 */
export function useProjectRequestFindManyQuery(baseOptions?: Apollo.QueryHookOptions<ProjectRequestFindManyQuery, ProjectRequestFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRequestFindManyQuery, ProjectRequestFindManyQueryVariables>(ProjectRequestFindManyDocument, options);
      }
export function useProjectRequestFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRequestFindManyQuery, ProjectRequestFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRequestFindManyQuery, ProjectRequestFindManyQueryVariables>(ProjectRequestFindManyDocument, options);
        }
export type ProjectRequestFindManyQueryHookResult = ReturnType<typeof useProjectRequestFindManyQuery>;
export type ProjectRequestFindManyLazyQueryHookResult = ReturnType<typeof useProjectRequestFindManyLazyQuery>;
export type ProjectRequestFindManyQueryResult = Apollo.QueryResult<ProjectRequestFindManyQuery, ProjectRequestFindManyQueryVariables>;
export const ProjectRequestFindOneDocument = gql`
    query ProjectRequestFindOne($approvalRequestDate: DateTime, $companyId: Int, $creatorId: Int, $getOption: GetOption, $id: Int, $projectName: String, $relationDepth: RelationDepth = FULL, $status: ProjectRequestStatus, $withDeleted: WithDeletedChoice = ALL, $managerSurveyId: String) {
  ProjectRequestFindOne(
    approvalRequestDate: $approvalRequestDate
    companyId: $companyId
    creatorId: $creatorId
    getOption: $getOption
    id: $id
    projectName: $projectName
    relationDepth: $relationDepth
    status: $status
    withDeleted: $withDeleted
    managerSurveyId: $managerSurveyId
  ) {
    ...ProjectRequest
  }
}
    ${ProjectRequestFragmentDoc}`;

/**
 * __useProjectRequestFindOneQuery__
 *
 * To run a query within a React component, call `useProjectRequestFindOneQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRequestFindOneQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRequestFindOneQuery({
 *   variables: {
 *      approvalRequestDate: // value for 'approvalRequestDate'
 *      companyId: // value for 'companyId'
 *      creatorId: // value for 'creatorId'
 *      getOption: // value for 'getOption'
 *      id: // value for 'id'
 *      projectName: // value for 'projectName'
 *      relationDepth: // value for 'relationDepth'
 *      status: // value for 'status'
 *      withDeleted: // value for 'withDeleted'
 *      managerSurveyId: // value for 'managerSurveyId'
 *   },
 * });
 */
export function useProjectRequestFindOneQuery(baseOptions?: Apollo.QueryHookOptions<ProjectRequestFindOneQuery, ProjectRequestFindOneQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectRequestFindOneQuery, ProjectRequestFindOneQueryVariables>(ProjectRequestFindOneDocument, options);
      }
export function useProjectRequestFindOneLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectRequestFindOneQuery, ProjectRequestFindOneQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectRequestFindOneQuery, ProjectRequestFindOneQueryVariables>(ProjectRequestFindOneDocument, options);
        }
export type ProjectRequestFindOneQueryHookResult = ReturnType<typeof useProjectRequestFindOneQuery>;
export type ProjectRequestFindOneLazyQueryHookResult = ReturnType<typeof useProjectRequestFindOneLazyQuery>;
export type ProjectRequestFindOneQueryResult = Apollo.QueryResult<ProjectRequestFindOneQuery, ProjectRequestFindOneQueryVariables>;
export const ProjectUserFindManyDocument = gql`
    query ProjectUserFindMany($userIds: [Int!]!) {
  ProjectUserFindMany(userIds: $userIds) {
    project {
      id
      name
      createdAt
      isArchived
      isStopped
      moduleType
      requireSheetsExport
      updatedAt
      version
      productSolution
      subId
      company {
        id
        name
      }
      managers {
        ...ProjectManager
      }
    }
    actorRole
    user {
      id
      email
    }
  }
}
    ${ProjectManagerFragmentDoc}`;

/**
 * __useProjectUserFindManyQuery__
 *
 * To run a query within a React component, call `useProjectUserFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectUserFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectUserFindManyQuery({
 *   variables: {
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useProjectUserFindManyQuery(baseOptions: Apollo.QueryHookOptions<ProjectUserFindManyQuery, ProjectUserFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProjectUserFindManyQuery, ProjectUserFindManyQueryVariables>(ProjectUserFindManyDocument, options);
      }
export function useProjectUserFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProjectUserFindManyQuery, ProjectUserFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProjectUserFindManyQuery, ProjectUserFindManyQueryVariables>(ProjectUserFindManyDocument, options);
        }
export type ProjectUserFindManyQueryHookResult = ReturnType<typeof useProjectUserFindManyQuery>;
export type ProjectUserFindManyLazyQueryHookResult = ReturnType<typeof useProjectUserFindManyLazyQuery>;
export type ProjectUserFindManyQueryResult = Apollo.QueryResult<ProjectUserFindManyQuery, ProjectUserFindManyQueryVariables>;
export const ReportsDocument = gql`
    query Reports {
  Reports {
    ...Report
  }
}
    ${ReportFragmentDoc}`;

/**
 * __useReportsQuery__
 *
 * To run a query within a React component, call `useReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportsQuery(baseOptions?: Apollo.QueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
      }
export function useReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsQuery, ReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsQuery, ReportsQueryVariables>(ReportsDocument, options);
        }
export type ReportsQueryHookResult = ReturnType<typeof useReportsQuery>;
export type ReportsLazyQueryHookResult = ReturnType<typeof useReportsLazyQuery>;
export type ReportsQueryResult = Apollo.QueryResult<ReportsQuery, ReportsQueryVariables>;
export const ReportDocument = gql`
    query Report($id: Int!) {
  report: Report(id: $id) {
    ...Report
  }
}
    ${ReportFragmentDoc}`;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const ReportRenderFindManyDocument = gql`
    query ReportRenderFindMany($dataSourceId: Int, $subType: ReportSubType, $reportRenderType: ReportRenderType, $projectJobRoleId: Int) {
  report: ReportRenderFindMany(
    dataSourceId: $dataSourceId
    subType: $subType
    reportRenderType: $reportRenderType
    projectJobRoleId: $projectJobRoleId
  ) {
    id
    subId
    reportRenderStatus
  }
}
    `;

/**
 * __useReportRenderFindManyQuery__
 *
 * To run a query within a React component, call `useReportRenderFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportRenderFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportRenderFindManyQuery({
 *   variables: {
 *      dataSourceId: // value for 'dataSourceId'
 *      subType: // value for 'subType'
 *      reportRenderType: // value for 'reportRenderType'
 *      projectJobRoleId: // value for 'projectJobRoleId'
 *   },
 * });
 */
export function useReportRenderFindManyQuery(baseOptions?: Apollo.QueryHookOptions<ReportRenderFindManyQuery, ReportRenderFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportRenderFindManyQuery, ReportRenderFindManyQueryVariables>(ReportRenderFindManyDocument, options);
      }
export function useReportRenderFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportRenderFindManyQuery, ReportRenderFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportRenderFindManyQuery, ReportRenderFindManyQueryVariables>(ReportRenderFindManyDocument, options);
        }
export type ReportRenderFindManyQueryHookResult = ReturnType<typeof useReportRenderFindManyQuery>;
export type ReportRenderFindManyLazyQueryHookResult = ReturnType<typeof useReportRenderFindManyLazyQuery>;
export type ReportRenderFindManyQueryResult = Apollo.QueryResult<ReportRenderFindManyQuery, ReportRenderFindManyQueryVariables>;
export const ReportAssignmentFindStageReportDocument = gql`
    query ReportAssignmentFindStageReport($type: ReportType!, $subType: ReportSubType!, $stageId: Int, $projectId: Int, $companyId: Int) {
  reports: ReportAssignmentFindStageReport(
    type: $type
    subType: $subType
    stageId: $stageId
    projectId: $projectId
    companyId: $companyId
  ) {
    ...ReportAssignment
  }
}
    ${ReportAssignmentFragmentDoc}`;

/**
 * __useReportAssignmentFindStageReportQuery__
 *
 * To run a query within a React component, call `useReportAssignmentFindStageReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportAssignmentFindStageReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportAssignmentFindStageReportQuery({
 *   variables: {
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *      stageId: // value for 'stageId'
 *      projectId: // value for 'projectId'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useReportAssignmentFindStageReportQuery(baseOptions: Apollo.QueryHookOptions<ReportAssignmentFindStageReportQuery, ReportAssignmentFindStageReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportAssignmentFindStageReportQuery, ReportAssignmentFindStageReportQueryVariables>(ReportAssignmentFindStageReportDocument, options);
      }
export function useReportAssignmentFindStageReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportAssignmentFindStageReportQuery, ReportAssignmentFindStageReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportAssignmentFindStageReportQuery, ReportAssignmentFindStageReportQueryVariables>(ReportAssignmentFindStageReportDocument, options);
        }
export type ReportAssignmentFindStageReportQueryHookResult = ReturnType<typeof useReportAssignmentFindStageReportQuery>;
export type ReportAssignmentFindStageReportLazyQueryHookResult = ReturnType<typeof useReportAssignmentFindStageReportLazyQuery>;
export type ReportAssignmentFindStageReportQueryResult = Apollo.QueryResult<ReportAssignmentFindStageReportQuery, ReportAssignmentFindStageReportQueryVariables>;
export const ReportFindManyDocument = gql`
    query ReportFindMany($type: ReportType) {
  report: ReportFindMany(type: $type) {
    id
    name
  }
}
    `;

/**
 * __useReportFindManyQuery__
 *
 * To run a query within a React component, call `useReportFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportFindManyQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useReportFindManyQuery(baseOptions?: Apollo.QueryHookOptions<ReportFindManyQuery, ReportFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportFindManyQuery, ReportFindManyQueryVariables>(ReportFindManyDocument, options);
      }
export function useReportFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportFindManyQuery, ReportFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportFindManyQuery, ReportFindManyQueryVariables>(ReportFindManyDocument, options);
        }
export type ReportFindManyQueryHookResult = ReturnType<typeof useReportFindManyQuery>;
export type ReportFindManyLazyQueryHookResult = ReturnType<typeof useReportFindManyLazyQuery>;
export type ReportFindManyQueryResult = Apollo.QueryResult<ReportFindManyQuery, ReportFindManyQueryVariables>;
export const ReportsFindByCompanyDocument = gql`
    query ReportsFindByCompany($type: ReportType, $subType: ReportSubType, $ownerId: Int, $includeSharedReports: Boolean!) {
  ReportsFindByCompany(
    type: $type
    subType: $subType
    ownerId: $ownerId
    includeSharedReports: $includeSharedReports
  ) {
    id
    name
    ownerId
  }
}
    `;

/**
 * __useReportsFindByCompanyQuery__
 *
 * To run a query within a React component, call `useReportsFindByCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportsFindByCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportsFindByCompanyQuery({
 *   variables: {
 *      type: // value for 'type'
 *      subType: // value for 'subType'
 *      ownerId: // value for 'ownerId'
 *      includeSharedReports: // value for 'includeSharedReports'
 *   },
 * });
 */
export function useReportsFindByCompanyQuery(baseOptions: Apollo.QueryHookOptions<ReportsFindByCompanyQuery, ReportsFindByCompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportsFindByCompanyQuery, ReportsFindByCompanyQueryVariables>(ReportsFindByCompanyDocument, options);
      }
export function useReportsFindByCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportsFindByCompanyQuery, ReportsFindByCompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportsFindByCompanyQuery, ReportsFindByCompanyQueryVariables>(ReportsFindByCompanyDocument, options);
        }
export type ReportsFindByCompanyQueryHookResult = ReturnType<typeof useReportsFindByCompanyQuery>;
export type ReportsFindByCompanyLazyQueryHookResult = ReturnType<typeof useReportsFindByCompanyLazyQuery>;
export type ReportsFindByCompanyQueryResult = Apollo.QueryResult<ReportsFindByCompanyQuery, ReportsFindByCompanyQueryVariables>;
export const ReportRenderDocument = gql`
    query ReportRender($subId: String!) {
  ReportRender(subId: $subId) {
    id
    subId
    blob {
      url
    }
    reportRenderStatus
    statusMessage
  }
}
    `;

/**
 * __useReportRenderQuery__
 *
 * To run a query within a React component, call `useReportRenderQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportRenderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportRenderQuery({
 *   variables: {
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useReportRenderQuery(baseOptions: Apollo.QueryHookOptions<ReportRenderQuery, ReportRenderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportRenderQuery, ReportRenderQueryVariables>(ReportRenderDocument, options);
      }
export function useReportRenderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportRenderQuery, ReportRenderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportRenderQuery, ReportRenderQueryVariables>(ReportRenderDocument, options);
        }
export type ReportRenderQueryHookResult = ReturnType<typeof useReportRenderQuery>;
export type ReportRenderLazyQueryHookResult = ReturnType<typeof useReportRenderLazyQuery>;
export type ReportRenderQueryResult = Apollo.QueryResult<ReportRenderQuery, ReportRenderQueryVariables>;
export const FindOneRoiCsvFileExportScheduledTaskDocument = gql`
    query FindOneROICsvFileExportScheduledTask($subId: String) {
  FindOneROICsvFileExportScheduledTask(subId: $subId) {
    blob {
      id
      url
    }
    error
    status
    subId
  }
}
    `;

/**
 * __useFindOneRoiCsvFileExportScheduledTaskQuery__
 *
 * To run a query within a React component, call `useFindOneRoiCsvFileExportScheduledTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindOneRoiCsvFileExportScheduledTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindOneRoiCsvFileExportScheduledTaskQuery({
 *   variables: {
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useFindOneRoiCsvFileExportScheduledTaskQuery(baseOptions?: Apollo.QueryHookOptions<FindOneRoiCsvFileExportScheduledTaskQuery, FindOneRoiCsvFileExportScheduledTaskQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindOneRoiCsvFileExportScheduledTaskQuery, FindOneRoiCsvFileExportScheduledTaskQueryVariables>(FindOneRoiCsvFileExportScheduledTaskDocument, options);
      }
export function useFindOneRoiCsvFileExportScheduledTaskLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindOneRoiCsvFileExportScheduledTaskQuery, FindOneRoiCsvFileExportScheduledTaskQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindOneRoiCsvFileExportScheduledTaskQuery, FindOneRoiCsvFileExportScheduledTaskQueryVariables>(FindOneRoiCsvFileExportScheduledTaskDocument, options);
        }
export type FindOneRoiCsvFileExportScheduledTaskQueryHookResult = ReturnType<typeof useFindOneRoiCsvFileExportScheduledTaskQuery>;
export type FindOneRoiCsvFileExportScheduledTaskLazyQueryHookResult = ReturnType<typeof useFindOneRoiCsvFileExportScheduledTaskLazyQuery>;
export type FindOneRoiCsvFileExportScheduledTaskQueryResult = Apollo.QueryResult<FindOneRoiCsvFileExportScheduledTaskQuery, FindOneRoiCsvFileExportScheduledTaskQueryVariables>;
export const RolesDocument = gql`
    query Roles {
  Roles {
    ...Role
  }
}
    ${RoleFragmentDoc}`;

/**
 * __useRolesQuery__
 *
 * To run a query within a React component, call `useRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function useRolesQuery(baseOptions?: Apollo.QueryHookOptions<RolesQuery, RolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
      }
export function useRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RolesQuery, RolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RolesQuery, RolesQueryVariables>(RolesDocument, options);
        }
export type RolesQueryHookResult = ReturnType<typeof useRolesQuery>;
export type RolesLazyQueryHookResult = ReturnType<typeof useRolesLazyQuery>;
export type RolesQueryResult = Apollo.QueryResult<RolesQuery, RolesQueryVariables>;
export const RoleFindManyDocument = gql`
    query RoleFindMany($filterForIdentity: Boolean) {
  RoleFindMany(filterForIdentity: $filterForIdentity) {
    id
    description
    name
    type
  }
}
    `;

/**
 * __useRoleFindManyQuery__
 *
 * To run a query within a React component, call `useRoleFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRoleFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRoleFindManyQuery({
 *   variables: {
 *      filterForIdentity: // value for 'filterForIdentity'
 *   },
 * });
 */
export function useRoleFindManyQuery(baseOptions?: Apollo.QueryHookOptions<RoleFindManyQuery, RoleFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RoleFindManyQuery, RoleFindManyQueryVariables>(RoleFindManyDocument, options);
      }
export function useRoleFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RoleFindManyQuery, RoleFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RoleFindManyQuery, RoleFindManyQueryVariables>(RoleFindManyDocument, options);
        }
export type RoleFindManyQueryHookResult = ReturnType<typeof useRoleFindManyQuery>;
export type RoleFindManyLazyQueryHookResult = ReturnType<typeof useRoleFindManyLazyQuery>;
export type RoleFindManyQueryResult = Apollo.QueryResult<RoleFindManyQuery, RoleFindManyQueryVariables>;
export const RssAssessmentFindManyDocument = gql`
    query RssAssessmentFindMany($stageId: Int) {
  RssAssessmentFindMany(stageId: $stageId) {
    ...RssAssessment
  }
}
    ${RssAssessmentFragmentDoc}`;

/**
 * __useRssAssessmentFindManyQuery__
 *
 * To run a query within a React component, call `useRssAssessmentFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRssAssessmentFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRssAssessmentFindManyQuery({
 *   variables: {
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useRssAssessmentFindManyQuery(baseOptions?: Apollo.QueryHookOptions<RssAssessmentFindManyQuery, RssAssessmentFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RssAssessmentFindManyQuery, RssAssessmentFindManyQueryVariables>(RssAssessmentFindManyDocument, options);
      }
export function useRssAssessmentFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RssAssessmentFindManyQuery, RssAssessmentFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RssAssessmentFindManyQuery, RssAssessmentFindManyQueryVariables>(RssAssessmentFindManyDocument, options);
        }
export type RssAssessmentFindManyQueryHookResult = ReturnType<typeof useRssAssessmentFindManyQuery>;
export type RssAssessmentFindManyLazyQueryHookResult = ReturnType<typeof useRssAssessmentFindManyLazyQuery>;
export type RssAssessmentFindManyQueryResult = Apollo.QueryResult<RssAssessmentFindManyQuery, RssAssessmentFindManyQueryVariables>;
export const ResultValidatedSkillsDocument = gql`
    query ResultValidatedSkills($dateFrom: Date, $dateTo: Date, $companyIds: [Int!], $take: Int!, $skip: Int!) {
  ResultValidatedSkills(
    dateFrom: $dateFrom
    dateTo: $dateTo
    companyIds: $companyIds
    take: $take
    skip: $skip
  ) {
    ...ResultValidatedSkillsPaginatedModel
  }
}
    ${ResultValidatedSkillsPaginatedModelFragmentDoc}`;

/**
 * __useResultValidatedSkillsQuery__
 *
 * To run a query within a React component, call `useResultValidatedSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResultValidatedSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResultValidatedSkillsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *      companyIds: // value for 'companyIds'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useResultValidatedSkillsQuery(baseOptions: Apollo.QueryHookOptions<ResultValidatedSkillsQuery, ResultValidatedSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResultValidatedSkillsQuery, ResultValidatedSkillsQueryVariables>(ResultValidatedSkillsDocument, options);
      }
export function useResultValidatedSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResultValidatedSkillsQuery, ResultValidatedSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResultValidatedSkillsQuery, ResultValidatedSkillsQueryVariables>(ResultValidatedSkillsDocument, options);
        }
export type ResultValidatedSkillsQueryHookResult = ReturnType<typeof useResultValidatedSkillsQuery>;
export type ResultValidatedSkillsLazyQueryHookResult = ReturnType<typeof useResultValidatedSkillsLazyQuery>;
export type ResultValidatedSkillsQueryResult = Apollo.QueryResult<ResultValidatedSkillsQuery, ResultValidatedSkillsQueryVariables>;
export const SoftSkillRoleLevelContentFindManyAndCountDocument = gql`
    query SoftSkillRoleLevelContentFindManyAndCount($softSkillIds: [Int!], $roleLevels: [ContentRoleLevel!], $grades: [Grade!], $take: Int!, $page: Int!, $order: [String!]) {
  softSkillContent: SoftSkillRoleLevelContentFindManyAndCount(
    softSkillIds: $softSkillIds
    roleLevels: $roleLevels
    grades: $grades
    take: $take
    page: $page
    order: $order
  ) {
    softSkillContent {
      id
      softSkillId
      softSkill {
        id
        name
      }
      grade
      roleLevel
      description
      candidateContent
      hiringManagerContent
      internalCandidateContent
    }
    count
  }
}
    `;

/**
 * __useSoftSkillRoleLevelContentFindManyAndCountQuery__
 *
 * To run a query within a React component, call `useSoftSkillRoleLevelContentFindManyAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillRoleLevelContentFindManyAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftSkillRoleLevelContentFindManyAndCountQuery({
 *   variables: {
 *      softSkillIds: // value for 'softSkillIds'
 *      roleLevels: // value for 'roleLevels'
 *      grades: // value for 'grades'
 *      take: // value for 'take'
 *      page: // value for 'page'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSoftSkillRoleLevelContentFindManyAndCountQuery(baseOptions: Apollo.QueryHookOptions<SoftSkillRoleLevelContentFindManyAndCountQuery, SoftSkillRoleLevelContentFindManyAndCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SoftSkillRoleLevelContentFindManyAndCountQuery, SoftSkillRoleLevelContentFindManyAndCountQueryVariables>(SoftSkillRoleLevelContentFindManyAndCountDocument, options);
      }
export function useSoftSkillRoleLevelContentFindManyAndCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoftSkillRoleLevelContentFindManyAndCountQuery, SoftSkillRoleLevelContentFindManyAndCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SoftSkillRoleLevelContentFindManyAndCountQuery, SoftSkillRoleLevelContentFindManyAndCountQueryVariables>(SoftSkillRoleLevelContentFindManyAndCountDocument, options);
        }
export type SoftSkillRoleLevelContentFindManyAndCountQueryHookResult = ReturnType<typeof useSoftSkillRoleLevelContentFindManyAndCountQuery>;
export type SoftSkillRoleLevelContentFindManyAndCountLazyQueryHookResult = ReturnType<typeof useSoftSkillRoleLevelContentFindManyAndCountLazyQuery>;
export type SoftSkillRoleLevelContentFindManyAndCountQueryResult = Apollo.QueryResult<SoftSkillRoleLevelContentFindManyAndCountQuery, SoftSkillRoleLevelContentFindManyAndCountQueryVariables>;
export const SoftSkillSuccessProfileContentFindManyAndCountDocument = gql`
    query SoftSkillSuccessProfileContentFindManyAndCount($softSkillIds: [Int!], $roleLevels: [ContentRoleLevel!], $take: Int!, $page: Int!, $order: [String!]) {
  softSkillContent: SoftSkillSuccessProfileContentFindManyAndCount(
    softSkillIds: $softSkillIds
    roleLevels: $roleLevels
    take: $take
    page: $page
    order: $order
  ) {
    softSkillContent {
      id
      softSkillId
      softSkill {
        name
      }
      roleLevel
      shortDescription
      description
    }
    count
  }
}
    `;

/**
 * __useSoftSkillSuccessProfileContentFindManyAndCountQuery__
 *
 * To run a query within a React component, call `useSoftSkillSuccessProfileContentFindManyAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillSuccessProfileContentFindManyAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftSkillSuccessProfileContentFindManyAndCountQuery({
 *   variables: {
 *      softSkillIds: // value for 'softSkillIds'
 *      roleLevels: // value for 'roleLevels'
 *      take: // value for 'take'
 *      page: // value for 'page'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useSoftSkillSuccessProfileContentFindManyAndCountQuery(baseOptions: Apollo.QueryHookOptions<SoftSkillSuccessProfileContentFindManyAndCountQuery, SoftSkillSuccessProfileContentFindManyAndCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SoftSkillSuccessProfileContentFindManyAndCountQuery, SoftSkillSuccessProfileContentFindManyAndCountQueryVariables>(SoftSkillSuccessProfileContentFindManyAndCountDocument, options);
      }
export function useSoftSkillSuccessProfileContentFindManyAndCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoftSkillSuccessProfileContentFindManyAndCountQuery, SoftSkillSuccessProfileContentFindManyAndCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SoftSkillSuccessProfileContentFindManyAndCountQuery, SoftSkillSuccessProfileContentFindManyAndCountQueryVariables>(SoftSkillSuccessProfileContentFindManyAndCountDocument, options);
        }
export type SoftSkillSuccessProfileContentFindManyAndCountQueryHookResult = ReturnType<typeof useSoftSkillSuccessProfileContentFindManyAndCountQuery>;
export type SoftSkillSuccessProfileContentFindManyAndCountLazyQueryHookResult = ReturnType<typeof useSoftSkillSuccessProfileContentFindManyAndCountLazyQuery>;
export type SoftSkillSuccessProfileContentFindManyAndCountQueryResult = Apollo.QueryResult<SoftSkillSuccessProfileContentFindManyAndCountQuery, SoftSkillSuccessProfileContentFindManyAndCountQueryVariables>;
export const SoftSkillGetAllValidForProjectDocument = gql`
    query SoftSkillGetAllValidForProject($projectId: Int!) {
  softSkills: SoftSkillGetAllValidForProject(projectId: $projectId) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;

/**
 * __useSoftSkillGetAllValidForProjectQuery__
 *
 * To run a query within a React component, call `useSoftSkillGetAllValidForProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillGetAllValidForProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftSkillGetAllValidForProjectQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSoftSkillGetAllValidForProjectQuery(baseOptions: Apollo.QueryHookOptions<SoftSkillGetAllValidForProjectQuery, SoftSkillGetAllValidForProjectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SoftSkillGetAllValidForProjectQuery, SoftSkillGetAllValidForProjectQueryVariables>(SoftSkillGetAllValidForProjectDocument, options);
      }
export function useSoftSkillGetAllValidForProjectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoftSkillGetAllValidForProjectQuery, SoftSkillGetAllValidForProjectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SoftSkillGetAllValidForProjectQuery, SoftSkillGetAllValidForProjectQueryVariables>(SoftSkillGetAllValidForProjectDocument, options);
        }
export type SoftSkillGetAllValidForProjectQueryHookResult = ReturnType<typeof useSoftSkillGetAllValidForProjectQuery>;
export type SoftSkillGetAllValidForProjectLazyQueryHookResult = ReturnType<typeof useSoftSkillGetAllValidForProjectLazyQuery>;
export type SoftSkillGetAllValidForProjectQueryResult = Apollo.QueryResult<SoftSkillGetAllValidForProjectQuery, SoftSkillGetAllValidForProjectQueryVariables>;
export const SoftSkillsDocument = gql`
    query SoftSkills {
  SoftSkills {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;

/**
 * __useSoftSkillsQuery__
 *
 * To run a query within a React component, call `useSoftSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftSkillsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftSkillsQuery(baseOptions?: Apollo.QueryHookOptions<SoftSkillsQuery, SoftSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SoftSkillsQuery, SoftSkillsQueryVariables>(SoftSkillsDocument, options);
      }
export function useSoftSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoftSkillsQuery, SoftSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SoftSkillsQuery, SoftSkillsQueryVariables>(SoftSkillsDocument, options);
        }
export type SoftSkillsQueryHookResult = ReturnType<typeof useSoftSkillsQuery>;
export type SoftSkillsLazyQueryHookResult = ReturnType<typeof useSoftSkillsLazyQuery>;
export type SoftSkillsQueryResult = Apollo.QueryResult<SoftSkillsQuery, SoftSkillsQueryVariables>;
export const SoftSkillFindManyDocument = gql`
    query SoftSkillFindMany($isArchived: Boolean, $ids: [Int!], $companyId: Int) {
  SoftSkillFindMany(isArchived: $isArchived, ids: $ids, companyId: $companyId) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;

/**
 * __useSoftSkillFindManyQuery__
 *
 * To run a query within a React component, call `useSoftSkillFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftSkillFindManyQuery({
 *   variables: {
 *      isArchived: // value for 'isArchived'
 *      ids: // value for 'ids'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useSoftSkillFindManyQuery(baseOptions?: Apollo.QueryHookOptions<SoftSkillFindManyQuery, SoftSkillFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SoftSkillFindManyQuery, SoftSkillFindManyQueryVariables>(SoftSkillFindManyDocument, options);
      }
export function useSoftSkillFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoftSkillFindManyQuery, SoftSkillFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SoftSkillFindManyQuery, SoftSkillFindManyQueryVariables>(SoftSkillFindManyDocument, options);
        }
export type SoftSkillFindManyQueryHookResult = ReturnType<typeof useSoftSkillFindManyQuery>;
export type SoftSkillFindManyLazyQueryHookResult = ReturnType<typeof useSoftSkillFindManyLazyQuery>;
export type SoftSkillFindManyQueryResult = Apollo.QueryResult<SoftSkillFindManyQuery, SoftSkillFindManyQueryVariables>;
export const GetSzAndProvidedCompanySoftSkillsDocument = gql`
    query getSZAndProvidedCompanySoftSkills($companyId: Int) {
  SZSoftSkills: SoftSkillFindMany(companyId: 1) {
    ...SoftSkill
  }
  CompanySoftSkills: SoftSkillFindMany(companyId: $companyId) {
    ...SoftSkill
  }
}
    ${SoftSkillFragmentDoc}`;

/**
 * __useGetSzAndProvidedCompanySoftSkillsQuery__
 *
 * To run a query within a React component, call `useGetSzAndProvidedCompanySoftSkillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSzAndProvidedCompanySoftSkillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSzAndProvidedCompanySoftSkillsQuery({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGetSzAndProvidedCompanySoftSkillsQuery(baseOptions?: Apollo.QueryHookOptions<GetSzAndProvidedCompanySoftSkillsQuery, GetSzAndProvidedCompanySoftSkillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSzAndProvidedCompanySoftSkillsQuery, GetSzAndProvidedCompanySoftSkillsQueryVariables>(GetSzAndProvidedCompanySoftSkillsDocument, options);
      }
export function useGetSzAndProvidedCompanySoftSkillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSzAndProvidedCompanySoftSkillsQuery, GetSzAndProvidedCompanySoftSkillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSzAndProvidedCompanySoftSkillsQuery, GetSzAndProvidedCompanySoftSkillsQueryVariables>(GetSzAndProvidedCompanySoftSkillsDocument, options);
        }
export type GetSzAndProvidedCompanySoftSkillsQueryHookResult = ReturnType<typeof useGetSzAndProvidedCompanySoftSkillsQuery>;
export type GetSzAndProvidedCompanySoftSkillsLazyQueryHookResult = ReturnType<typeof useGetSzAndProvidedCompanySoftSkillsLazyQuery>;
export type GetSzAndProvidedCompanySoftSkillsQueryResult = Apollo.QueryResult<GetSzAndProvidedCompanySoftSkillsQuery, GetSzAndProvidedCompanySoftSkillsQueryVariables>;
export const SoftSkillTraitsDocument = gql`
    query SoftSkillTraits {
  softSkillTraits: SoftSkillTraits {
    ...SoftSkillTrait
  }
}
    ${SoftSkillTraitFragmentDoc}`;

/**
 * __useSoftSkillTraitsQuery__
 *
 * To run a query within a React component, call `useSoftSkillTraitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSoftSkillTraitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSoftSkillTraitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSoftSkillTraitsQuery(baseOptions?: Apollo.QueryHookOptions<SoftSkillTraitsQuery, SoftSkillTraitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SoftSkillTraitsQuery, SoftSkillTraitsQueryVariables>(SoftSkillTraitsDocument, options);
      }
export function useSoftSkillTraitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SoftSkillTraitsQuery, SoftSkillTraitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SoftSkillTraitsQuery, SoftSkillTraitsQueryVariables>(SoftSkillTraitsDocument, options);
        }
export type SoftSkillTraitsQueryHookResult = ReturnType<typeof useSoftSkillTraitsQuery>;
export type SoftSkillTraitsLazyQueryHookResult = ReturnType<typeof useSoftSkillTraitsLazyQuery>;
export type SoftSkillTraitsQueryResult = Apollo.QueryResult<SoftSkillTraitsQuery, SoftSkillTraitsQueryVariables>;
export const FindSoftSkillTraitsBySsIdDocument = gql`
    query FindSoftSkillTraitsBySSId($id: Int!) {
  softSkillTraits: SoftSkillTraitFindManyBySoftSkill(id: $id) {
    ...SoftSkillTrait
  }
}
    ${SoftSkillTraitFragmentDoc}`;

/**
 * __useFindSoftSkillTraitsBySsIdQuery__
 *
 * To run a query within a React component, call `useFindSoftSkillTraitsBySsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSoftSkillTraitsBySsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSoftSkillTraitsBySsIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFindSoftSkillTraitsBySsIdQuery(baseOptions: Apollo.QueryHookOptions<FindSoftSkillTraitsBySsIdQuery, FindSoftSkillTraitsBySsIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindSoftSkillTraitsBySsIdQuery, FindSoftSkillTraitsBySsIdQueryVariables>(FindSoftSkillTraitsBySsIdDocument, options);
      }
export function useFindSoftSkillTraitsBySsIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindSoftSkillTraitsBySsIdQuery, FindSoftSkillTraitsBySsIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindSoftSkillTraitsBySsIdQuery, FindSoftSkillTraitsBySsIdQueryVariables>(FindSoftSkillTraitsBySsIdDocument, options);
        }
export type FindSoftSkillTraitsBySsIdQueryHookResult = ReturnType<typeof useFindSoftSkillTraitsBySsIdQuery>;
export type FindSoftSkillTraitsBySsIdLazyQueryHookResult = ReturnType<typeof useFindSoftSkillTraitsBySsIdLazyQuery>;
export type FindSoftSkillTraitsBySsIdQueryResult = Apollo.QueryResult<FindSoftSkillTraitsBySsIdQuery, FindSoftSkillTraitsBySsIdQueryVariables>;
export const FindManySoftSkillTraitsBySsIdDocument = gql`
    query FindManySoftSkillTraitsBySSId($ids: [Int!]!) {
  softSkillTraits: SoftSkillTraitFindManyBySoftSkills(ids: $ids) {
    ...SoftSkillTrait
  }
}
    ${SoftSkillTraitFragmentDoc}`;

/**
 * __useFindManySoftSkillTraitsBySsIdQuery__
 *
 * To run a query within a React component, call `useFindManySoftSkillTraitsBySsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindManySoftSkillTraitsBySsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindManySoftSkillTraitsBySsIdQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useFindManySoftSkillTraitsBySsIdQuery(baseOptions: Apollo.QueryHookOptions<FindManySoftSkillTraitsBySsIdQuery, FindManySoftSkillTraitsBySsIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindManySoftSkillTraitsBySsIdQuery, FindManySoftSkillTraitsBySsIdQueryVariables>(FindManySoftSkillTraitsBySsIdDocument, options);
      }
export function useFindManySoftSkillTraitsBySsIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindManySoftSkillTraitsBySsIdQuery, FindManySoftSkillTraitsBySsIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindManySoftSkillTraitsBySsIdQuery, FindManySoftSkillTraitsBySsIdQueryVariables>(FindManySoftSkillTraitsBySsIdDocument, options);
        }
export type FindManySoftSkillTraitsBySsIdQueryHookResult = ReturnType<typeof useFindManySoftSkillTraitsBySsIdQuery>;
export type FindManySoftSkillTraitsBySsIdLazyQueryHookResult = ReturnType<typeof useFindManySoftSkillTraitsBySsIdLazyQuery>;
export type FindManySoftSkillTraitsBySsIdQueryResult = Apollo.QueryResult<FindManySoftSkillTraitsBySsIdQuery, FindManySoftSkillTraitsBySsIdQueryVariables>;
export const GetProjectSamlAuthInfoDocument = gql`
    query GetProjectSamlAuthInfo($projectId: Float!) {
  getProjectSamlAuthInfo(id: $projectId)
}
    `;

/**
 * __useGetProjectSamlAuthInfoQuery__
 *
 * To run a query within a React component, call `useGetProjectSamlAuthInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectSamlAuthInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectSamlAuthInfoQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectSamlAuthInfoQuery(baseOptions: Apollo.QueryHookOptions<GetProjectSamlAuthInfoQuery, GetProjectSamlAuthInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProjectSamlAuthInfoQuery, GetProjectSamlAuthInfoQueryVariables>(GetProjectSamlAuthInfoDocument, options);
      }
export function useGetProjectSamlAuthInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProjectSamlAuthInfoQuery, GetProjectSamlAuthInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProjectSamlAuthInfoQuery, GetProjectSamlAuthInfoQueryVariables>(GetProjectSamlAuthInfoDocument, options);
        }
export type GetProjectSamlAuthInfoQueryHookResult = ReturnType<typeof useGetProjectSamlAuthInfoQuery>;
export type GetProjectSamlAuthInfoLazyQueryHookResult = ReturnType<typeof useGetProjectSamlAuthInfoLazyQuery>;
export type GetProjectSamlAuthInfoQueryResult = Apollo.QueryResult<GetProjectSamlAuthInfoQuery, GetProjectSamlAuthInfoQueryVariables>;
export const GenerateStageIndirectInvitationUrlDocument = gql`
    query GenerateStageIndirectInvitationUrl($id: Int!, $locale: SupportedLocale) {
  GenerateStageIndirectInvitationUrl: StageGenerateIndirectInvitationUrl(
    locale: $locale
    id: $id
  ) {
    url
  }
}
    `;

/**
 * __useGenerateStageIndirectInvitationUrlQuery__
 *
 * To run a query within a React component, call `useGenerateStageIndirectInvitationUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenerateStageIndirectInvitationUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenerateStageIndirectInvitationUrlQuery({
 *   variables: {
 *      id: // value for 'id'
 *      locale: // value for 'locale'
 *   },
 * });
 */
export function useGenerateStageIndirectInvitationUrlQuery(baseOptions: Apollo.QueryHookOptions<GenerateStageIndirectInvitationUrlQuery, GenerateStageIndirectInvitationUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GenerateStageIndirectInvitationUrlQuery, GenerateStageIndirectInvitationUrlQueryVariables>(GenerateStageIndirectInvitationUrlDocument, options);
      }
export function useGenerateStageIndirectInvitationUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GenerateStageIndirectInvitationUrlQuery, GenerateStageIndirectInvitationUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GenerateStageIndirectInvitationUrlQuery, GenerateStageIndirectInvitationUrlQueryVariables>(GenerateStageIndirectInvitationUrlDocument, options);
        }
export type GenerateStageIndirectInvitationUrlQueryHookResult = ReturnType<typeof useGenerateStageIndirectInvitationUrlQuery>;
export type GenerateStageIndirectInvitationUrlLazyQueryHookResult = ReturnType<typeof useGenerateStageIndirectInvitationUrlLazyQuery>;
export type GenerateStageIndirectInvitationUrlQueryResult = Apollo.QueryResult<GenerateStageIndirectInvitationUrlQuery, GenerateStageIndirectInvitationUrlQueryVariables>;
export const TmTestFindManyDocument = gql`
    query TmTestFindMany($stageId: Int!) {
  tests: TmTestFindMany(stageId: $stageId) {
    id
    stageId
    nQuestionsPerTrait
    origin
    tpProjectId
    subType
    type
    createdAt
    subId
    updatedAt
  }
}
    `;

/**
 * __useTmTestFindManyQuery__
 *
 * To run a query within a React component, call `useTmTestFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTmTestFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTmTestFindManyQuery({
 *   variables: {
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useTmTestFindManyQuery(baseOptions: Apollo.QueryHookOptions<TmTestFindManyQuery, TmTestFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TmTestFindManyQuery, TmTestFindManyQueryVariables>(TmTestFindManyDocument, options);
      }
export function useTmTestFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TmTestFindManyQuery, TmTestFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TmTestFindManyQuery, TmTestFindManyQueryVariables>(TmTestFindManyDocument, options);
        }
export type TmTestFindManyQueryHookResult = ReturnType<typeof useTmTestFindManyQuery>;
export type TmTestFindManyLazyQueryHookResult = ReturnType<typeof useTmTestFindManyLazyQuery>;
export type TmTestFindManyQueryResult = Apollo.QueryResult<TmTestFindManyQuery, TmTestFindManyQueryVariables>;
export const StageFindManyDocument = gql`
    query StageFindMany($emailCandidateReport: Boolean, $emailManagerReport: Boolean, $emailSzAdmin: Boolean, $hasCalibration: Boolean, $createInterviewGuide: Boolean, $endTime: Date, $id: Int, $ids: [Int!], $name: String, $projectId: Int, $projectIds: [Int!], $renderCandidateReport: Boolean, $startTime: Date, $status: StageStatus, $subId: String, $testIds: [Int!], $type: StageType) {
  Stages: StageFindMany(
    emailCandidateReport: $emailCandidateReport
    emailManagerReport: $emailManagerReport
    emailSzAdmin: $emailSzAdmin
    createInterviewGuide: $createInterviewGuide
    hasCalibration: $hasCalibration
    endTime: $endTime
    id: $id
    ids: $ids
    name: $name
    projectId: $projectId
    projectIds: $projectIds
    renderCandidateReport: $renderCandidateReport
    startTime: $startTime
    status: $status
    subId: $subId
    testIds: $testIds
    type: $type
  ) {
    ...SmStageModel
  }
}
    ${SmStageModelFragmentDoc}`;

/**
 * __useStageFindManyQuery__
 *
 * To run a query within a React component, call `useStageFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStageFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStageFindManyQuery({
 *   variables: {
 *      emailCandidateReport: // value for 'emailCandidateReport'
 *      emailManagerReport: // value for 'emailManagerReport'
 *      emailSzAdmin: // value for 'emailSzAdmin'
 *      hasCalibration: // value for 'hasCalibration'
 *      createInterviewGuide: // value for 'createInterviewGuide'
 *      endTime: // value for 'endTime'
 *      id: // value for 'id'
 *      ids: // value for 'ids'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      projectIds: // value for 'projectIds'
 *      renderCandidateReport: // value for 'renderCandidateReport'
 *      startTime: // value for 'startTime'
 *      status: // value for 'status'
 *      subId: // value for 'subId'
 *      testIds: // value for 'testIds'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useStageFindManyQuery(baseOptions?: Apollo.QueryHookOptions<StageFindManyQuery, StageFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StageFindManyQuery, StageFindManyQueryVariables>(StageFindManyDocument, options);
      }
export function useStageFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StageFindManyQuery, StageFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StageFindManyQuery, StageFindManyQueryVariables>(StageFindManyDocument, options);
        }
export type StageFindManyQueryHookResult = ReturnType<typeof useStageFindManyQuery>;
export type StageFindManyLazyQueryHookResult = ReturnType<typeof useStageFindManyLazyQuery>;
export type StageFindManyQueryResult = Apollo.QueryResult<StageFindManyQuery, StageFindManyQueryVariables>;
export const ResultsPageStageFindManyDocument = gql`
    query ResultsPageStageFindMany($projectIds: [Int!]) {
  Stages: StageFindMany(projectIds: $projectIds) {
    projectId
    name
    id
  }
}
    `;

/**
 * __useResultsPageStageFindManyQuery__
 *
 * To run a query within a React component, call `useResultsPageStageFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useResultsPageStageFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResultsPageStageFindManyQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *   },
 * });
 */
export function useResultsPageStageFindManyQuery(baseOptions?: Apollo.QueryHookOptions<ResultsPageStageFindManyQuery, ResultsPageStageFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResultsPageStageFindManyQuery, ResultsPageStageFindManyQueryVariables>(ResultsPageStageFindManyDocument, options);
      }
export function useResultsPageStageFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResultsPageStageFindManyQuery, ResultsPageStageFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResultsPageStageFindManyQuery, ResultsPageStageFindManyQueryVariables>(ResultsPageStageFindManyDocument, options);
        }
export type ResultsPageStageFindManyQueryHookResult = ReturnType<typeof useResultsPageStageFindManyQuery>;
export type ResultsPageStageFindManyLazyQueryHookResult = ReturnType<typeof useResultsPageStageFindManyLazyQuery>;
export type ResultsPageStageFindManyQueryResult = Apollo.QueryResult<ResultsPageStageFindManyQuery, ResultsPageStageFindManyQueryVariables>;
export const StageDocument = gql`
    query Stage($id: Int!) {
  Stage(id: $id) {
    ...SmStageModel
  }
}
    ${SmStageModelFragmentDoc}`;

/**
 * __useStageQuery__
 *
 * To run a query within a React component, call `useStageQuery` and pass it any options that fit your needs.
 * When your component renders, `useStageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStageQuery(baseOptions: Apollo.QueryHookOptions<StageQuery, StageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StageQuery, StageQueryVariables>(StageDocument, options);
      }
export function useStageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StageQuery, StageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StageQuery, StageQueryVariables>(StageDocument, options);
        }
export type StageQueryHookResult = ReturnType<typeof useStageQuery>;
export type StageLazyQueryHookResult = ReturnType<typeof useStageLazyQuery>;
export type StageQueryResult = Apollo.QueryResult<StageQuery, StageQueryVariables>;
export const DeiStageActionDocument = gql`
    query DeiStageAction($stageId: Int!) {
  DeiStageAction(stageId: $stageId) {
    ...DeiStageAction
  }
}
    ${DeiStageActionFragmentDoc}`;

/**
 * __useDeiStageActionQuery__
 *
 * To run a query within a React component, call `useDeiStageActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeiStageActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeiStageActionQuery({
 *   variables: {
 *      stageId: // value for 'stageId'
 *   },
 * });
 */
export function useDeiStageActionQuery(baseOptions: Apollo.QueryHookOptions<DeiStageActionQuery, DeiStageActionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DeiStageActionQuery, DeiStageActionQueryVariables>(DeiStageActionDocument, options);
      }
export function useDeiStageActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DeiStageActionQuery, DeiStageActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DeiStageActionQuery, DeiStageActionQueryVariables>(DeiStageActionDocument, options);
        }
export type DeiStageActionQueryHookResult = ReturnType<typeof useDeiStageActionQuery>;
export type DeiStageActionLazyQueryHookResult = ReturnType<typeof useDeiStageActionLazyQuery>;
export type DeiStageActionQueryResult = Apollo.QueryResult<DeiStageActionQuery, DeiStageActionQueryVariables>;
export const StageCandidateFindManyDocument = gql`
    query StageCandidateFindMany($candidateId: Int, $candidateIds: [Int!], $id: Int, $ids: [Int!], $stageId: Int, $stageResultId: Int, $status: StageCandidateStatus, $subId: String, $relationDepth: RelationDepth) {
  StageCandidates: StageCandidateFindMany(
    candidateId: $candidateId
    candidateIds: $candidateIds
    id: $id
    ids: $ids
    stageId: $stageId
    stageResultId: $stageResultId
    status: $status
    subId: $subId
    relationDepth: $relationDepth
  ) {
    ...StageCandidateModel
  }
}
    ${StageCandidateModelFragmentDoc}`;

/**
 * __useStageCandidateFindManyQuery__
 *
 * To run a query within a React component, call `useStageCandidateFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useStageCandidateFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStageCandidateFindManyQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *      candidateIds: // value for 'candidateIds'
 *      id: // value for 'id'
 *      ids: // value for 'ids'
 *      stageId: // value for 'stageId'
 *      stageResultId: // value for 'stageResultId'
 *      status: // value for 'status'
 *      subId: // value for 'subId'
 *      relationDepth: // value for 'relationDepth'
 *   },
 * });
 */
export function useStageCandidateFindManyQuery(baseOptions?: Apollo.QueryHookOptions<StageCandidateFindManyQuery, StageCandidateFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<StageCandidateFindManyQuery, StageCandidateFindManyQueryVariables>(StageCandidateFindManyDocument, options);
      }
export function useStageCandidateFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<StageCandidateFindManyQuery, StageCandidateFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<StageCandidateFindManyQuery, StageCandidateFindManyQueryVariables>(StageCandidateFindManyDocument, options);
        }
export type StageCandidateFindManyQueryHookResult = ReturnType<typeof useStageCandidateFindManyQuery>;
export type StageCandidateFindManyLazyQueryHookResult = ReturnType<typeof useStageCandidateFindManyLazyQuery>;
export type StageCandidateFindManyQueryResult = Apollo.QueryResult<StageCandidateFindManyQuery, StageCandidateFindManyQueryVariables>;
export const TestCandidateFindManyByCandidateDocument = gql`
    query TestCandidateFindManyByCandidate($candidateId: Int!) {
  testResult: TestCandidateFindManyByCandidate(candidateId: $candidateId) {
    ...TmTestCandidateModel
  }
}
    ${TmTestCandidateModelFragmentDoc}`;

/**
 * __useTestCandidateFindManyByCandidateQuery__
 *
 * To run a query within a React component, call `useTestCandidateFindManyByCandidateQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestCandidateFindManyByCandidateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestCandidateFindManyByCandidateQuery({
 *   variables: {
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useTestCandidateFindManyByCandidateQuery(baseOptions: Apollo.QueryHookOptions<TestCandidateFindManyByCandidateQuery, TestCandidateFindManyByCandidateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestCandidateFindManyByCandidateQuery, TestCandidateFindManyByCandidateQueryVariables>(TestCandidateFindManyByCandidateDocument, options);
      }
export function useTestCandidateFindManyByCandidateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestCandidateFindManyByCandidateQuery, TestCandidateFindManyByCandidateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestCandidateFindManyByCandidateQuery, TestCandidateFindManyByCandidateQueryVariables>(TestCandidateFindManyByCandidateDocument, options);
        }
export type TestCandidateFindManyByCandidateQueryHookResult = ReturnType<typeof useTestCandidateFindManyByCandidateQuery>;
export type TestCandidateFindManyByCandidateLazyQueryHookResult = ReturnType<typeof useTestCandidateFindManyByCandidateLazyQuery>;
export type TestCandidateFindManyByCandidateQueryResult = Apollo.QueryResult<TestCandidateFindManyByCandidateQuery, TestCandidateFindManyByCandidateQueryVariables>;
export const SuccessProfileDocument = gql`
    query SuccessProfile($projectId: Int!) {
  SuccessProfile(projectId: $projectId) {
    ...SuccessProfile
  }
}
    ${SuccessProfileFragmentDoc}`;

/**
 * __useSuccessProfileQuery__
 *
 * To run a query within a React component, call `useSuccessProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuccessProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuccessProfileQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useSuccessProfileQuery(baseOptions: Apollo.QueryHookOptions<SuccessProfileQuery, SuccessProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SuccessProfileQuery, SuccessProfileQueryVariables>(SuccessProfileDocument, options);
      }
export function useSuccessProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SuccessProfileQuery, SuccessProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SuccessProfileQuery, SuccessProfileQueryVariables>(SuccessProfileDocument, options);
        }
export type SuccessProfileQueryHookResult = ReturnType<typeof useSuccessProfileQuery>;
export type SuccessProfileLazyQueryHookResult = ReturnType<typeof useSuccessProfileLazyQuery>;
export type SuccessProfileQueryResult = Apollo.QueryResult<SuccessProfileQuery, SuccessProfileQueryVariables>;
export const TechnicalSkillSearchDocument = gql`
    query TechnicalSkillSearch($searchText: String) {
  TechnicalSkillFreeTextSearchPaginated(
    freetext: $searchText
    skip: 0
    take: 10
    sortField: NAME
    sortOrder: ASC
    withDeleted: ALL
  ) {
    total
    technicalSkills {
      ...TechnicalSkill
    }
  }
}
    ${TechnicalSkillFragmentDoc}`;

/**
 * __useTechnicalSkillSearchQuery__
 *
 * To run a query within a React component, call `useTechnicalSkillSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnicalSkillSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnicalSkillSearchQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useTechnicalSkillSearchQuery(baseOptions?: Apollo.QueryHookOptions<TechnicalSkillSearchQuery, TechnicalSkillSearchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TechnicalSkillSearchQuery, TechnicalSkillSearchQueryVariables>(TechnicalSkillSearchDocument, options);
      }
export function useTechnicalSkillSearchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechnicalSkillSearchQuery, TechnicalSkillSearchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TechnicalSkillSearchQuery, TechnicalSkillSearchQueryVariables>(TechnicalSkillSearchDocument, options);
        }
export type TechnicalSkillSearchQueryHookResult = ReturnType<typeof useTechnicalSkillSearchQuery>;
export type TechnicalSkillSearchLazyQueryHookResult = ReturnType<typeof useTechnicalSkillSearchLazyQuery>;
export type TechnicalSkillSearchQueryResult = Apollo.QueryResult<TechnicalSkillSearchQuery, TechnicalSkillSearchQueryVariables>;
export const TechnicalSkillFindManyDocument = gql`
    query TechnicalSkillFindMany {
  TechnicalSkillFindMany {
    ...TechnicalSkill
  }
}
    ${TechnicalSkillFragmentDoc}`;

/**
 * __useTechnicalSkillFindManyQuery__
 *
 * To run a query within a React component, call `useTechnicalSkillFindManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTechnicalSkillFindManyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTechnicalSkillFindManyQuery({
 *   variables: {
 *   },
 * });
 */
export function useTechnicalSkillFindManyQuery(baseOptions?: Apollo.QueryHookOptions<TechnicalSkillFindManyQuery, TechnicalSkillFindManyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TechnicalSkillFindManyQuery, TechnicalSkillFindManyQueryVariables>(TechnicalSkillFindManyDocument, options);
      }
export function useTechnicalSkillFindManyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TechnicalSkillFindManyQuery, TechnicalSkillFindManyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TechnicalSkillFindManyQuery, TechnicalSkillFindManyQueryVariables>(TechnicalSkillFindManyDocument, options);
        }
export type TechnicalSkillFindManyQueryHookResult = ReturnType<typeof useTechnicalSkillFindManyQuery>;
export type TechnicalSkillFindManyLazyQueryHookResult = ReturnType<typeof useTechnicalSkillFindManyLazyQuery>;
export type TechnicalSkillFindManyQueryResult = Apollo.QueryResult<TechnicalSkillFindManyQuery, TechnicalSkillFindManyQueryVariables>;
export const TraitsDocument = gql`
    query Traits($isArchived: Boolean, $ids: [Int!]) {
  Traits(isArchived: $isArchived, ids: $ids) {
    ...Trait
  }
}
    ${TraitFragmentDoc}`;

/**
 * __useTraitsQuery__
 *
 * To run a query within a React component, call `useTraitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraitsQuery({
 *   variables: {
 *      isArchived: // value for 'isArchived'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTraitsQuery(baseOptions?: Apollo.QueryHookOptions<TraitsQuery, TraitsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TraitsQuery, TraitsQueryVariables>(TraitsDocument, options);
      }
export function useTraitsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TraitsQuery, TraitsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TraitsQuery, TraitsQueryVariables>(TraitsDocument, options);
        }
export type TraitsQueryHookResult = ReturnType<typeof useTraitsQuery>;
export type TraitsLazyQueryHookResult = ReturnType<typeof useTraitsLazyQuery>;
export type TraitsQueryResult = Apollo.QueryResult<TraitsQuery, TraitsQueryVariables>;
export const TraitRoleLevelContentFindManyAndCountDocument = gql`
    query TraitRoleLevelContentFindManyAndCount($softSkillIds: [Int!], $traitIds: [Int!], $roleLevels: [ContentRoleLevel!], $classifications: [ContentClassification!], $take: Int!, $page: Int!, $order: [String!]) {
  traitContent: TraitRoleLevelContentFindManyAndCount(
    softSkillIds: $softSkillIds
    traitIds: $traitIds
    roleLevels: $roleLevels
    classifications: $classifications
    take: $take
    page: $page
    order: $order
  ) {
    traitContent {
      id
      softSkillId
      traitId
      softSkill {
        name
      }
      trait {
        name
      }
      classification
      roleLevel
      summary
      content
      developmentContent
    }
    count
  }
}
    `;

/**
 * __useTraitRoleLevelContentFindManyAndCountQuery__
 *
 * To run a query within a React component, call `useTraitRoleLevelContentFindManyAndCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useTraitRoleLevelContentFindManyAndCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTraitRoleLevelContentFindManyAndCountQuery({
 *   variables: {
 *      softSkillIds: // value for 'softSkillIds'
 *      traitIds: // value for 'traitIds'
 *      roleLevels: // value for 'roleLevels'
 *      classifications: // value for 'classifications'
 *      take: // value for 'take'
 *      page: // value for 'page'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTraitRoleLevelContentFindManyAndCountQuery(baseOptions: Apollo.QueryHookOptions<TraitRoleLevelContentFindManyAndCountQuery, TraitRoleLevelContentFindManyAndCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TraitRoleLevelContentFindManyAndCountQuery, TraitRoleLevelContentFindManyAndCountQueryVariables>(TraitRoleLevelContentFindManyAndCountDocument, options);
      }
export function useTraitRoleLevelContentFindManyAndCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TraitRoleLevelContentFindManyAndCountQuery, TraitRoleLevelContentFindManyAndCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TraitRoleLevelContentFindManyAndCountQuery, TraitRoleLevelContentFindManyAndCountQueryVariables>(TraitRoleLevelContentFindManyAndCountDocument, options);
        }
export type TraitRoleLevelContentFindManyAndCountQueryHookResult = ReturnType<typeof useTraitRoleLevelContentFindManyAndCountQuery>;
export type TraitRoleLevelContentFindManyAndCountLazyQueryHookResult = ReturnType<typeof useTraitRoleLevelContentFindManyAndCountLazyQuery>;
export type TraitRoleLevelContentFindManyAndCountQueryResult = Apollo.QueryResult<TraitRoleLevelContentFindManyAndCountQuery, TraitRoleLevelContentFindManyAndCountQueryVariables>;
export const UserDocument = gql`
    query User($firstName: String, $lastName: String, $email: String, $id: Int, $subId: String) {
  User(
    firstName: $firstName
    lastName: $lastName
    email: $email
    id: $id
    subId: $subId
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      id: // value for 'id'
 *      subId: // value for 'subId'
 *   },
 * });
 */
export function useUserQuery(baseOptions?: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
    query Users($firstName: String, $lastName: String, $email: String, $subIds: [String!], $ids: [Int!]) {
  Users(
    firstName: $firstName
    lastName: $lastName
    email: $email
    subIds: $subIds
    ids: $ids
  ) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      subIds: // value for 'subIds'
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const UserFreeTextSearchPaginateDocument = gql`
    query UserFreeTextSearchPaginate($freetext: String!, $take: Int, $skip: Int) {
  Users: UserFreeTextSearchPaginate(freetext: $freetext, skip: $skip, take: $take) {
    data {
      id
      subId
      firstName
      lastName
      email
    }
    pageInfo {
      currentPage
      itemsOnPage
      itemsTotal
      pageTotal
      skip
      take
    }
  }
}
    `;

/**
 * __useUserFreeTextSearchPaginateQuery__
 *
 * To run a query within a React component, call `useUserFreeTextSearchPaginateQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserFreeTextSearchPaginateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserFreeTextSearchPaginateQuery({
 *   variables: {
 *      freetext: // value for 'freetext'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useUserFreeTextSearchPaginateQuery(baseOptions: Apollo.QueryHookOptions<UserFreeTextSearchPaginateQuery, UserFreeTextSearchPaginateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserFreeTextSearchPaginateQuery, UserFreeTextSearchPaginateQueryVariables>(UserFreeTextSearchPaginateDocument, options);
      }
export function useUserFreeTextSearchPaginateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserFreeTextSearchPaginateQuery, UserFreeTextSearchPaginateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserFreeTextSearchPaginateQuery, UserFreeTextSearchPaginateQueryVariables>(UserFreeTextSearchPaginateDocument, options);
        }
export type UserFreeTextSearchPaginateQueryHookResult = ReturnType<typeof useUserFreeTextSearchPaginateQuery>;
export type UserFreeTextSearchPaginateLazyQueryHookResult = ReturnType<typeof useUserFreeTextSearchPaginateLazyQuery>;
export type UserFreeTextSearchPaginateQueryResult = Apollo.QueryResult<UserFreeTextSearchPaginateQuery, UserFreeTextSearchPaginateQueryVariables>;
export const MeDocument = gql`
    query me {
  me {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const ValidateContentDocument = gql`
    query ValidateContent($softSkillIds: [Int!], $traitIds: [Int!], $take: Int!, $page: Int!) {
  validateContent: ValidateContent(
    softSkillIds: $softSkillIds
    traitIds: $traitIds
    take: $take
    page: $page
  ) {
    content {
      ...ContentValidatiorPieceModel
    }
    count
  }
}
    ${ContentValidatiorPieceModelFragmentDoc}`;

/**
 * __useValidateContentQuery__
 *
 * To run a query within a React component, call `useValidateContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateContentQuery({
 *   variables: {
 *      softSkillIds: // value for 'softSkillIds'
 *      traitIds: // value for 'traitIds'
 *      take: // value for 'take'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useValidateContentQuery(baseOptions: Apollo.QueryHookOptions<ValidateContentQuery, ValidateContentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateContentQuery, ValidateContentQueryVariables>(ValidateContentDocument, options);
      }
export function useValidateContentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateContentQuery, ValidateContentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateContentQuery, ValidateContentQueryVariables>(ValidateContentDocument, options);
        }
export type ValidateContentQueryHookResult = ReturnType<typeof useValidateContentQuery>;
export type ValidateContentLazyQueryHookResult = ReturnType<typeof useValidateContentLazyQuery>;
export type ValidateContentQueryResult = Apollo.QueryResult<ValidateContentQuery, ValidateContentQueryVariables>;
export const ValidateContentPieceDocument = gql`
    query ValidateContentPiece($softSkillId: Int!, $traitIds: [Int!]) {
  validateContent: ValidateContentPiece(
    softSkillId: $softSkillId
    traitIds: $traitIds
  ) {
    ...ContentValidatiorPieceModel
  }
}
    ${ContentValidatiorPieceModelFragmentDoc}`;

/**
 * __useValidateContentPieceQuery__
 *
 * To run a query within a React component, call `useValidateContentPieceQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateContentPieceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateContentPieceQuery({
 *   variables: {
 *      softSkillId: // value for 'softSkillId'
 *      traitIds: // value for 'traitIds'
 *   },
 * });
 */
export function useValidateContentPieceQuery(baseOptions: Apollo.QueryHookOptions<ValidateContentPieceQuery, ValidateContentPieceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateContentPieceQuery, ValidateContentPieceQueryVariables>(ValidateContentPieceDocument, options);
      }
export function useValidateContentPieceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateContentPieceQuery, ValidateContentPieceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateContentPieceQuery, ValidateContentPieceQueryVariables>(ValidateContentPieceDocument, options);
        }
export type ValidateContentPieceQueryHookResult = ReturnType<typeof useValidateContentPieceQuery>;
export type ValidateContentPieceLazyQueryHookResult = ReturnType<typeof useValidateContentPieceLazyQuery>;
export type ValidateContentPieceQueryResult = Apollo.QueryResult<ValidateContentPieceQuery, ValidateContentPieceQueryVariables>;
export const FixContentPieceDocument = gql`
    query FixContentPiece($softSkillId: Int!, $traitIds: [Int!]) {
  validateContent: FixContentPiece(softSkillId: $softSkillId, traitIds: $traitIds) {
    ...ContentValidatiorPieceModel
  }
}
    ${ContentValidatiorPieceModelFragmentDoc}`;

/**
 * __useFixContentPieceQuery__
 *
 * To run a query within a React component, call `useFixContentPieceQuery` and pass it any options that fit your needs.
 * When your component renders, `useFixContentPieceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFixContentPieceQuery({
 *   variables: {
 *      softSkillId: // value for 'softSkillId'
 *      traitIds: // value for 'traitIds'
 *   },
 * });
 */
export function useFixContentPieceQuery(baseOptions: Apollo.QueryHookOptions<FixContentPieceQuery, FixContentPieceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FixContentPieceQuery, FixContentPieceQueryVariables>(FixContentPieceDocument, options);
      }
export function useFixContentPieceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FixContentPieceQuery, FixContentPieceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FixContentPieceQuery, FixContentPieceQueryVariables>(FixContentPieceDocument, options);
        }
export type FixContentPieceQueryHookResult = ReturnType<typeof useFixContentPieceQuery>;
export type FixContentPieceLazyQueryHookResult = ReturnType<typeof useFixContentPieceLazyQuery>;
export type FixContentPieceQueryResult = Apollo.QueryResult<FixContentPieceQuery, FixContentPieceQueryVariables>;
export const WidgetsDocument = gql`
    query Widgets {
  Widgets {
    ...Widget
  }
}
    ${WidgetFragmentDoc}`;

/**
 * __useWidgetsQuery__
 *
 * To run a query within a React component, call `useWidgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useWidgetsQuery(baseOptions?: Apollo.QueryHookOptions<WidgetsQuery, WidgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, options);
      }
export function useWidgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetsQuery, WidgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetsQuery, WidgetsQueryVariables>(WidgetsDocument, options);
        }
export type WidgetsQueryHookResult = ReturnType<typeof useWidgetsQuery>;
export type WidgetsLazyQueryHookResult = ReturnType<typeof useWidgetsLazyQuery>;
export type WidgetsQueryResult = Apollo.QueryResult<WidgetsQuery, WidgetsQueryVariables>;
export const WidgetDocument = gql`
    query Widget($id: Int!) {
  Widget: Widget(id: $id) {
    id
    name
    type
    screenShotBlobId
    screenShotBlob {
      url
      id
    }
    isArchived
    widgetOptions {
      id
      name
      fieldType
      question
      defaultValue
      isArchived
    }
    reportConfigs {
      id
      reportId
      newPageBefore
      report {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useWidgetQuery__
 *
 * To run a query within a React component, call `useWidgetQuery` and pass it any options that fit your needs.
 * When your component renders, `useWidgetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWidgetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWidgetQuery(baseOptions: Apollo.QueryHookOptions<WidgetQuery, WidgetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WidgetQuery, WidgetQueryVariables>(WidgetDocument, options);
      }
export function useWidgetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WidgetQuery, WidgetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WidgetQuery, WidgetQueryVariables>(WidgetDocument, options);
        }
export type WidgetQueryHookResult = ReturnType<typeof useWidgetQuery>;
export type WidgetLazyQueryHookResult = ReturnType<typeof useWidgetLazyQuery>;
export type WidgetQueryResult = Apollo.QueryResult<WidgetQuery, WidgetQueryVariables>;
export const GetWidgetTypeEnumDocument = gql`
    query GetWidgetTypeEnum {
  __type(name: "WidgetType") {
    enumValues {
      name
      description
    }
  }
}
    `;

/**
 * __useGetWidgetTypeEnumQuery__
 *
 * To run a query within a React component, call `useGetWidgetTypeEnumQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWidgetTypeEnumQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWidgetTypeEnumQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWidgetTypeEnumQuery(baseOptions?: Apollo.QueryHookOptions<GetWidgetTypeEnumQuery, GetWidgetTypeEnumQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWidgetTypeEnumQuery, GetWidgetTypeEnumQueryVariables>(GetWidgetTypeEnumDocument, options);
      }
export function useGetWidgetTypeEnumLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWidgetTypeEnumQuery, GetWidgetTypeEnumQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWidgetTypeEnumQuery, GetWidgetTypeEnumQueryVariables>(GetWidgetTypeEnumDocument, options);
        }
export type GetWidgetTypeEnumQueryHookResult = ReturnType<typeof useGetWidgetTypeEnumQuery>;
export type GetWidgetTypeEnumLazyQueryHookResult = ReturnType<typeof useGetWidgetTypeEnumLazyQuery>;
export type GetWidgetTypeEnumQueryResult = Apollo.QueryResult<GetWidgetTypeEnumQuery, GetWidgetTypeEnumQueryVariables>;