import { ModalTypes } from 'enums/modalTypes';
import { useStageFindManyQuery } from 'generated/graphql';
import { closeModal } from 'graphql/modals';
import { useMemo } from 'react';
import { Modal, ModalSize } from '@spotted-zebra-uk/ui-components';
import { getPrefilledDeiFields } from './DeiActionForm/dei.helpers';
import DeiActionForm from './DeiActionForm/DeiActionForm';

export interface IEditDeiActionModal {
  projectId: number;
  stageId: number;
  completedCount: number;
}

const EditDeiActionModal = ({
  projectId,
  stageId,
  completedCount,
}: IEditDeiActionModal) => {
  const { data: stageData } = useStageFindManyQuery({
    variables: { projectId },
  });

  const prefilledData = useMemo(() => {
    const deiStageAction = stageData?.Stages?.[0].deiStageAction;
    return deiStageAction ? getPrefilledDeiFields(deiStageAction?.fields) : [];
  }, [stageData]);

  const handleCloseModal = () => {
    closeModal(ModalTypes.EDIT_DEI_ACTION);
  };

  return (
    <Modal
      onClose={handleCloseModal}
      isOpen
      modalSize={ModalSize.MEDIUM}
      className="edit-dei-action-modal"
      header="Edit Diversity, equity & inclusion"
    >
      <DeiActionForm
        stageId={stageId}
        onClose={handleCloseModal}
        type="edit"
        initialValues={{ deiValues: prefilledData }}
        completedCount={completedCount}
        projectId={projectId}
      />
    </Modal>
  );
};

export default EditDeiActionModal;
